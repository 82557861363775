import * as React from 'react';

import SideBarLink from './SidebarLink';

class SidebarViewGod extends React.Component {

    render(){
        return(
            <div className="sidebar-view">
                 <SideBarLink path='home' iconName='Home' description='Ukeplan lærer' color="#01b3ef" fullWidth={true}   />
                 <SideBarLink path='/godmode' iconName='Rocket' description='Klon'  />
                <SideBarLink path='/ukeplan' iconName='Calendar' description='Ukeplan lærer'  />
                <SideBarLink path='/innleveringer' iconName='CompletedSolid' description='Innlevering lærer'  />
                <SideBarLink path='/timeplan' iconName='ViewAll' description='Timeplan lærer'   />
                <SideBarLink path='/quiz' iconName='PageListSolid' description='Prøver'  />
                <SideBarLink path='/portefolje' iconName='FabricOpenFolderHorizontal' description='Portefølje for lærer'  />
                <SideBarLink path='/meldinger' iconName='Message' description='Dashboard for meldinger for lærer'  />
                <SideBarLink path='/iup' iconName='Transition' description='IUP'  />
                <SideBarLink path='/admin' iconName='Settings' description='Administrasjon for lærer'  />
                <SideBarLink path='home' iconName='Home' description='Ukeplan elev' color="#01b3ef" fullWidth={true}   />
                <SideBarLink path='/ukeplanstudent' iconName='Calendar' description='Ukeplan elev'  />
                <SideBarLink path='/innleveringerstudent' iconName='CompletedSolid' description='Innlevering elev'  />
                <SideBarLink path='/timeplanstudent' iconName='ViewAll' description='Timeplan elev'   />
                <SideBarLink path='/portefoljestudent' iconName='FabricOpenFolderHorizontal' description='Portefølje for elev'  />
                <SideBarLink path='/quizstudent' iconName='PageListSolid' description='Prøver'  />
            </div>
        )
    }
}

export default SidebarViewGod;