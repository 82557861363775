import * as React from "react";
import * as moment from "moment";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
moment.locale("nb");
import {
  processNodes,
  convertNodeToElement,
  htmlparser2,
} from "react-html-parser";
import { ActionButton, FontIcon, IIconProps, Icon, IconButton, Label, Link, Modal } from "office-ui-fabric-react";
import ImmersiveReader from "../shared/ImmersiveReader";
import FilesModal from "./FilesModal";
import { capitalizeFirstLetter } from "../../utils/HelperFunctions";
import { getAssignment } from "../../utils/GraphEduService";
import ReactTooltip from 'react-tooltip';
import UkeplanElementModal from "./UkeplanElementModal";
import { Course } from "../../models/Course";


export interface ITaskElementViewProps {
  course: Course;
  taskelement: any;
  history: any;
  compactCardView: boolean;
}
export interface ITaskElementViewState {
  myhtml: any;
  showfilesmodal: boolean;
  showContentModal: boolean;
}

class TaskElementView extends React.Component<
  ITaskElementViewProps,
  ITaskElementViewState
> {
  constructor(props) {
    super(props);
    this.state = { 
      myhtml: null, 
      showfilesmodal: false,
      showContentModal: false
    };
  }
  // const ting: IIconProps = {

  // }
  render() {
    console.log('yyyyxx',this.props.taskelement);
    return (
      <li
        className="list-group-item"
        ref={(input) => {
          if (!this.state.myhtml) {
            this.setState({ myhtml: input });
          }
        }}
      >
        <div className="plan-kort-header">
          <h3 style={{marginBottom: "0px"}}>
            <i
              className="ms-Icon ms-Icon--CompletedSolid"
              aria-hidden="true"
            ></i>
            {this.props.taskelement.Name}
          </h3>
          <div className="plan-kort-header--flex-item">
            {(this.props.taskelement.TeamsTask || this.props.taskelement.TeamsDeepLink) && <div>
              <Icon data-tip data-for={`teamstask-${this.props.taskelement.Id}`} iconName="TeamsLogo" style={{fontSize: 20, marginRight: "10px", color: "#4E5FBF", cursor: "default"}}/>
              <ReactTooltip id={`teamstask-${this.props.taskelement.Id}`}>Denne oppgaven er knyttet til Teams</ReactTooltip>
              </div>
            }

            {this.props.taskelement.AssignmentGroup && (
              <div
                style={{ fontWeight: "bolder", fontSize: 16, marginRight: 10 }}
              >
                <i
                  className="ms-Icon ms-Icon--Group"
                  style={{
                    marginRight: 5,
                    verticalAlign: "middle",
                    fontSize: 20,
                  }}
                  title="Tilretteleggingsgruppe"
                ></i>
                {this.props.taskelement.AssignmentGroup.Name}
              </div>
            )}

            {this.state.myhtml && (
                <ImmersiveReader
                  htmlcontent={this.state.myhtml}
                ></ImmersiveReader>
            )}
            
            <div>
              <IconButton 
                data-tip
                data-for={`tooltip-task-${this.props.taskelement.Id}`}
                iconProps={{ iconName: "MiniExpand", styles: {root: {fontSize: 18}}}} 
                // style={{fontSize: 20, margin: "0 10px", cursor: "pointer"}}
                onClick={() => this.setState({showContentModal: true})}
                style={{margin: "0 5px", alignItems: "start"}}
              ></IconButton>
              <ReactTooltip id={`tooltip-task-${this.props.taskelement.Id}`}>
                Åpne i forstørret vindu
              </ReactTooltip>

              <UkeplanElementModal showModal={this.state.showContentModal} onDismiss={() => this.setState({showContentModal: false})} task={this.props.taskelement} course={this.props.course}></UkeplanElementModal>
            </div>
          </div>
        </div>
        <dl>
          {/* <Label className="list-group-item--label">
            Tilretteleggingsgruppe:
          </Label> */}

          {this.props.taskelement.GrepCodeList.length > 0 && (
            <div>
              <Label className="list-group-item--label">Kompetansemål</Label>
              <dd>
                {/* <ul id="kmp-2-@this.props.taskelement.Id" className={`grep-list ${this.props.compactCardView ? "hideOverflow" : ""}`}> */}
                {this.props.taskelement.GrepCodeList.length > 1 && this.props.compactCardView ? <span>{this.props.taskelement.GrepCodeList.length} Kompetansemål</span> : 
                <ul id="kmp-2-@this.props.taskelement.Id" className="grep-list">
                  {this.props.taskelement.GrepCodeList.map((element) => {
                    return (
                      <li>
                        <a href={element.DetailUrl} target="_blank">
                          {element.GrepTekst}
                        </a>
                      </li>
                    );
                  })}
                </ul>}
              </dd>
            </div>
          )}
          {this.props.taskelement.SkolonResources && this.props.taskelement.SkolonResources.length > 0 && (
            <>
            <div>
              <Label className="list-group-item--label"> <img width='40px' src={require('../../images/SkolonIcon.svg')} /></Label>
            </div>
             <dd>
             <ul className="grep-list">
               {this.props.taskelement.SkolonResources.map((element) => {
                 return (
                   <li>
                     <a href={element.url} target="_blank">
                       {element.name}
                     </a>
                   </li>
                 );
               })}
             </ul>
           </dd>
           </>
          )}

         
          {this.props.taskelement.Goal.length > 0 && (
            <div>
              <Label className="list-group-item--label">
                Egendefinerte mål:
              </Label>
              <dd>
                <span
                  className={`remove-para-margin list-group-unordered ${this.props.compactCardView ? "hideOverflow" : ""}`}
                  style={{ backgroundColor: "transparent" }}
                  dangerouslySetInnerHTML={{
                    __html: this.props.taskelement.Goal,
                  }}
                ></span>
                {/* {this.props.taskelement.Goal} */}
              </dd>
            </div>
          )}
          {this.props.taskelement.EvaluationText.Length > 0 ||
            (this.props.taskelement.EvaluationTextOther.Length > 0 && (
              <div>
                <Label className="list-group-item--label">Vurdering:</Label>
                <dd>
                  <ul>
                    {this.props.taskelement.EvalElements.map((element) => {
                      <li>element</li>;
                    })}
                  </ul>
                </dd>
              </div>
            ))}

          {this.props.taskelement.Text.length > 0 && (
            <div>
              <Label className="list-group-item--label">Beskrivelse:</Label>
              <dd>
                <span
                  className={`remove-para-margin list-group-unordered ${this.props.compactCardView ? "hideOverflow" : ""}`}
                  style={{ backgroundColor: "transparent" }}
                  dangerouslySetInnerHTML={{
                    __html: this.props.taskelement.Text,
                  }}
                ></span>
                {/* {this.props.taskelement.Text} */}
              </dd>
            </div>
          )}

          {this.props.taskelement.Link.length > 0 && (
            <div>
              <Label className="list-group-item--label">Lenke</Label>
              <dd className="planLenke">
                <a target="_blank" href={this.props.taskelement.Link}>
                  {this.props.taskelement.Link}
                </a>
              </dd>
            </div>
          )}

          {this.props.taskelement.Starttime && 
            <div>
              <Label className="list-group-item--label">Starttidspunkt:</Label>
              <dd>{capitalizeFirstLetter(moment(this.props.taskelement.Starttime).format("dddd").toString())} {moment(this.props.taskelement.Starttime).format("DD.MM.YYYY HH:mm")}</dd>
            </div>
          }

          <Label className="list-group-item--label">Frist</Label>
          <dd>{capitalizeFirstLetter(moment(this.props.taskelement.Deadline).format("dddd").toString())} {this.props.taskelement.Deadlinetekst}</dd>
        </dl>
        <div className="ms-Grid-row">
          {
            !(this.props.taskelement.TeamsTask || this.props.taskelement.TeamsDeepLink) && (
              <div className="oppgaveKnapper">
                <div className="flex-button">

                  {this.props.taskelement.HasFiles && (
                    <ActionButton
                      iconProps={{ iconName: "Attach" }}
                      onClick={() => {
                        this.setState({ showfilesmodal: true });
                      }}
                      style={{ flex: 1 }}
                    >
                      Se filer
                    </ActionButton>
                  )}
                  {
                    this.state.showfilesmodal && (
                      <FilesModal
                        mode={2}
                        id={this.props.taskelement.Id}
                        closemodal={() => {
                          this.setState({ showfilesmodal: false });
                        }}
                      />
                    )
                    // <FilesModal  />
                  }

                  {/* TODO: Re-add when view for evaluating teams tasks is done */}
                  {!this.props.taskelement.TeamsTask && <ActionButton
                      id="checkstatus"
                      text="Sjekk status"
                      iconProps={{ iconName: "CheckList" }}
                      onClick={() => {
                        if (this.props.taskelement.TeamsTask) {
                          this.props.history.push(
                            "/innleveringerteams/" + this.props.taskelement.Id
                          );
                        }
                        else {
                          this.props.history.push(
                            "/innleveringer/" + this.props.taskelement.Id
                          );
                        }
                      }}
                    />
                  }
                  { !this.props.taskelement.HasFiles && (
                    <ActionButton
                      text="Kopier til ny gruppe"
                      style={{ textAlign: "left" }}
                      iconProps={{ iconName: "Copy" }}
                      onClick={() => {
                        this.props.history.push(
                          `/task/${this.props.taskelement.Id}/c`
                        );
                      }}
                    />
                  )}
                </div>
                <div className="flex-button align-right">
                  {!this.props.taskelement.TeamsTask &&
                    <ActionButton
                      id="edit"
                      iconProps={{ iconName: "Edit" }}
                      text="Rediger"
                      onClick={() => {
                        // this.props.history.push('/task/'+this.props.taskelement.Id+'/e');
                        this.onEditPlan(this.props.taskelement);
                      }}
                    />
                  }
                  {this.props.taskelement.HasFiles && (
                    <ActionButton
                      text="Kopier til ny gruppe"
                      iconProps={{ iconName: "Copy" }}
                      onClick={() => {
                        this.props.history.push(
                          `/task/${this.props.taskelement.Id}/c`
                        );
                      }}
                    />
                  )}
                </div>
              </div>
            
          )}
          {(this.props.taskelement.TeamsTask || this.props.taskelement.TeamsDeepLink) && (
            <div className="oppgaveKnapper">
              <div className="flex-button">
                <ActionButton
                  text="Åpne i Teams"
                  style={{ textAlign: "left" }}
                  iconProps={{ iconName: "OpenInNewTab" }}
                  onClick={async () => {

                    if (this.props.taskelement.TeamsDeepLink) {
                      window.open(this.props.taskelement.TeamsDeepLink, "_blank", "noreferrer");
                    }
                    else if (this.props.taskelement.TeamsTask) {
                      let classId = this.props.taskelement.TeamsTask.split("|")[0];
                      let assignmentId = this.props.taskelement.TeamsTask.split("|")[1];

                      let assignment = await getAssignment(classId, assignmentId);
                      window.open(assignment.webUrl, "_blank", "noreferrer");
                    }
                  }}
                />
              </div>
            </div>
          )}
        </div>
      </li>
    );
  }
  onEditPlan(pe: any) {
    this.props.history.push("/task/" + pe.Id + "/e");
  }
}

function mapStateToProps(state) {
  return {
    compactCardView: state.ukeplanelements.CompactCardView
  };
}
export default connect(mapStateToProps, {})(withRouter(TaskElementView));
