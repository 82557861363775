import * as moment from 'moment';
import {Moment} from "moment";

export const DATE_TIME_FORMAT:string = "DD.MM.YYYY HH:mm";

export const CALENDAR_MIN = new Date();
CALENDAR_MIN.setHours(8);
CALENDAR_MIN.setMinutes(0, 0, 0);

export const CALENDAR_MAX = new Date();
CALENDAR_MAX.setHours(17);
CALENDAR_MAX.setMinutes(0, 0, 0);

export default class DateUtils {

    public dateTimeStringToDate(date:string){
        return date ? new Date(parseInt(date.substring(6, 19))) : null;
    }

    public dateToDateTimeString(date:Date){
        return `/Date(${date.getTime()})/`
    }
}

export const dateStringToMoment = (dateString : string | undefined, dateStringFormat:string = DATE_TIME_FORMAT) : Moment | null => {

    if (!dateString){
        return null;
    }

    return moment(dateString, dateStringFormat);
};

export const isDateStringBeforeToday = (dateString : string | undefined, dateStringFormat:string = DATE_TIME_FORMAT): boolean => {

    if (!dateString){
        return false;
    }

    return moment(dateString, dateStringFormat) < moment().startOf("day");
};

// Conversion for dates on format "/Date(1580425200000)/" to Moment
export const sharePointDateStringToMoment = (sharePointDateString : any) : Moment =>(
    moment.unix((sharePointDateString.match(/\d+/)[0] as number) / 1000)
);