import axios from 'axios';
import * as _ from 'lodash';
import { SP_HOST_URL, ROOT_URL } from './constants';

export const GET_CLASS_IUPS = 'GET_CLASS_IUPS';
export const GET_COURSE_IUPS = 'GET_COURSE_IUPS';
export const GET_CLASSREPORT_IUPS = 'GET_CLASSREPORT_IUPS';
export const GET_CLASSREPORT_IUPS_DRAFT = 'GET_CLASSREPORT_IUPS_DRAFT';
export const GET_COURSEEPORT_IUPS = 'GET_COURSEEPORT_IUPS';
export const GET_COURSEEPORT_STUDENT = 'GET_COURSEEPORT_STUDENT';
export const SAVE_COURSEEPORT_STUDENT = 'SAVE_COURSEEPORT_STUDENT';
export const PUBLISHALL_COURSEEPORT_STUDENT = 'PUBLISHALL_COURSEEPORT_STUDENT';
export const SAVE_CLASSEPORT_STUDENT = 'SAVE_CLASSEPORT_STUDENT';
export const CREATE_IUP = 'CREATE_IUP';
export const GENERATE_PDF_STUDENT = 'GENERATE_PDF_STUDENT';
export const GET_COURSEEPORTS_PDF = 'GET_COURSEEPORTS_PDF';

export function getIupForClass(schoolclass:any) {
   
    const request = axios.post(`${ROOT_URL}/GetIupClass${SP_HOST_URL}`, schoolclass, {
        headers: {
            Authorization: localStorage.getItem('id_token')
        }
    });

    return {
        type: GET_CLASS_IUPS,
        payload: request
    }
}
export function getIupForCourse(course:any) {
   
    const request = axios.post(`${ROOT_URL}/GetIupCourse${SP_HOST_URL}`, course, {
        headers: {
            Authorization: localStorage.getItem('id_token')
        }
    });

    return {
        type: GET_COURSE_IUPS,
        payload: request
    }
}
export function getIupReportForClass(iup:any) {
   
    const request = axios.post(`${ROOT_URL}/GetIupReportClass${SP_HOST_URL}`, iup, {
        headers: {
            Authorization: localStorage.getItem('id_token')
        }
    });

    return {
        type: GET_CLASSREPORT_IUPS,
        payload: request
    }
}

export function getIupReportForClassDraft(iupId:number, includeDraft:boolean = false) {
    const data: FormData = new FormData();
    
    data.set("IupId", iupId.toString());
    data.set("IncludeDraft", includeDraft.toString());
    const request = axios.post(`${ROOT_URL}/GetIupReportClassDraft${SP_HOST_URL}`, data,{
        headers: {
            Authorization: localStorage.getItem('id_token')
        }
    });

    return {
        type: GET_CLASSREPORT_IUPS_DRAFT,
        payload: request
    }
}

export function getIupReportForCourse(iup:any) {
   
    const request = axios.post(`${ROOT_URL}/GetIupReportCourse${SP_HOST_URL}`, iup, {
        headers: {
            Authorization: localStorage.getItem('id_token')
        }
    });

    return {
        type: GET_COURSEEPORT_IUPS,
        payload: request
    }
}
export function getIupReportStudentForCourse(iup:any) {
   
    const request = axios.post(`${ROOT_URL}/GetIupReportStudentCourse${SP_HOST_URL}`, iup, {
        headers: {
            Authorization: localStorage.getItem('id_token')
        }
    });

    return {
        type: GET_COURSEEPORT_STUDENT,
        payload: request
    }
}
export function createZippedPDF(iups:any) {
   
    const request = axios.post(`${ROOT_URL}/GetIupReportsZipped${SP_HOST_URL}`, iups, {
        headers: {
            Authorization: localStorage.getItem('id_token')
        }
    });

    return {
        type: GET_COURSEEPORTS_PDF,
        payload: request
    }
}
export function saveIupReportStudentForCourse(iup:any) {
   
    const request = axios.post(`${ROOT_URL}/SaveIupReportStudentCourse${SP_HOST_URL}`, iup, {
        headers: {
            Authorization: localStorage.getItem('id_token')
        }
    });

    return {
        type: SAVE_COURSEEPORT_STUDENT,
        payload: request
    }
}
export function publishAllStudentForCourse(iup:any) {
   
    const request = axios.post(`${ROOT_URL}/publishAllStudentForCourse${SP_HOST_URL}`, iup, {
        headers: {
            Authorization: localStorage.getItem('id_token')
        }
    });

    return {
        type: PUBLISHALL_COURSEEPORT_STUDENT,
        payload: request
    }
}
export function saveIupReportStudentForClass(iup:any) {
   
    const request = axios.post(`${ROOT_URL}/SaveIupReportStudentClass${SP_HOST_URL}`, iup, {
        headers: {
            Authorization: localStorage.getItem('id_token')
        }
    });

    return {
        type: SAVE_CLASSEPORT_STUDENT,
        payload: request
    }
}
export function createIUP(iup:any) {
   
    const request = axios.post(`${ROOT_URL}/CreateIUP${SP_HOST_URL}`, iup, {
        headers: {
            Authorization: localStorage.getItem('id_token')
        }
    });

    return {
        type: CREATE_IUP,
        payload: request
    }
}
export function deleteIUP(iup:any) {
   
    const request = axios.post(`${ROOT_URL}/DeleteIUP${SP_HOST_URL}`, iup, {
        headers: {
            Authorization: localStorage.getItem('id_token')
        }
    });

    return {
        type: CREATE_IUP,
        payload: request
    }
}
export function generatePDF(pdfcontents:any) {
   
    const request = axios.post(`https://enablepdfgenerator.azurewebsites.net/home/genZokrates`, pdfcontents, {
        headers: {
            Authorization: localStorage.getItem('id_token')
        }
    });

    return {
        type: GENERATE_PDF_STUDENT,
        payload: request
    }
}



