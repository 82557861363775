import * as React from "react";
import { connect } from "react-redux";
import { getRecipients, getTeacherState } from "../../actions/Action_Teacher";
import { getNewsElement } from "../../actions/Action_Ukeplan";
import HeaderView from "../view_containers/HeaderView";
import MessageForm from "./MessageForm";
import * as moment from "moment";
export const LOCALE = "nb";
export const DATE_FORMAT = "DD.MM.YYYY";
export const TIME_FORMAT = "LT";
function mapStateToProps(state) {
  return {
    teacherstate: state.teacher,
    message:state.ukeplanelements.newselement
  };
}
export interface IMessageEditViewProps {
  mode: string;
  teacherstate: any;
  recipients: {};
  initialValues: any;
  initialize: any;
  match: any;
  history: any;
  message:any;
  getRecipients(statedata: any);
  getNewsElement(Id: number);
}

export interface IMessageEditViewState {
  messageForm: {};
  rcpstudent: any[];
}
class MessageEditView extends React.Component<
  IMessageEditViewProps,
  IMessageEditViewState
> {
  getInitialValuesFromObject(se: any) {
    let r = [];
    se.AllRcps.split(";").forEach((element) => {
      r.push(element);
    });
    const { id, mode } = this.props.match.params;
    if (mode == "c") {
      se.Id = 0;
    }
    console.log('returning',se);
    return {
      Id: se.Id,

      Title: se.Title,
      ShowKmpGoals: se.ShowKmpGoals,
      Recipients: se.AllRcps +";"+ se.Courses,
      Foresattportal: se.Foresattportal,
      Kvittering: se.Kvittering,
      HideBeforeStart: se.HideBeforeStart ? se.HideBeforeStart : false,

      Text: se.Text,
      StartsideElev: se.StartsideElev,
      Ukeplan: se.Ukeplan,
      AllRcps: se.AllRcps + ";" + se.Courses,

      NewsFiles: se.NewsFiles,
      NewsDocuments: se.NewsDocuments,

      DateFrom: moment(se.DateFrom).locale(LOCALE).format(DATE_FORMAT),
      Date: moment(se.Date).locale(LOCALE).format(DATE_FORMAT),
      //mytest:this.props.teacherstate
    };
  }
  componentWillReceiveProps(props)
  {
      console.log('editmounted2',props);
      
    const { id, mode } = props.match.params;
    
    if(mode=='c'&& props.message)
    {
        console.log('editmounted4');
        let copy = JSON.parse(JSON.stringify(props.message));
        copy.Id = 0;
            let msgform = this.getInitialValuesFromObject(
              copy
            );
            console.log('editmounted3',msgform);
            this.setState({
              messageForm: msgform,
            });
    }
    else
    {
        // console.log('editmounted5',mode);
        // console.log('editmounted5',mode);
        console.log('editmounted5',props.message);
    }
    // this.props.getRecipients(this.props.teacherstate).then((resp) => {
    //   if (!isNaN(id)) {
    //     this.props
    //       .getNewsElement(id)
    //       .then((response) => {
    //         let msgform = this.getInitialValuesFromObject(
    //           response.payload.data
    //         );
    //         this.setState({
    //           messageForm: msgform,
    //           rcpstudent: resp.payload.data,
    //         });
    //       })
    //       .catch((error) => {
    //         // Returns user to TimeplanView if user lacks permission or element for id doesn't exist
    //         //this.props.history.push('/ukeplan');
    //       });
    //   } else {
    //     // If not, we initialize an empty form
    //     this.setState({
    //       messageForm: this.getDefaultInitialValues(),
    //       rcpstudent: resp.payload.data,
    //     });
    //   }
    // });
  }
  componentDidMount() {
    console.log("editmounted1",this.props.match);
    const { id, mode } = this.props.match.params;
    this.props.getRecipients(this.props.teacherstate).then((resp) => {
      if (!isNaN(id)) {
        this.props
          .getNewsElement(id)
          .then((response) => {
            let msgform = this.getInitialValuesFromObject(
              response.payload.data
            );
            this.setState({
              messageForm: msgform,
              rcpstudent: resp.payload.data,
            });
          })
          .catch((error) => {
            // Returns user to TimeplanView if user lacks permission or element for id doesn't exist
            //this.props.history.push('/ukeplan');
          });
      } else {
        // If not, we initialize an empty form
        this.setState({
          messageForm: this.getDefaultInitialValues(),
          rcpstudent: resp.payload.data,
        });
      }
    });
  }
  getDefaultInitialValues() {
    return {
      Id: "0",
      rcps: this.props.recipients,
    };
  }
  render() {
    const { id, mode } = this.props.match.params;
    if (!this.state || !this.state.messageForm || !this.state.rcpstudent) {
      return <div>Laster...</div>;
    }
let header = 'Melding';
if(mode=='c')
{
    header = 'Kopier Melding';
}
    return (
      <div>
        <div className="ms-Grid main container">
          <HeaderView title={header} iconName="ViewAll" />
          <MessageForm
            initialValues={this.state.messageForm}
            rcpstudent={this.state.rcpstudent}
            mode={this.props.match.params.mode}
          />
        </div>
      </div>
    );
  }
}

export default connect(mapStateToProps, {
  getRecipients,
  getNewsElement,
  getTeacherState,
})(MessageEditView);
