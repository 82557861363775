import * as React from "react";
import {
  DefaultButton,
  Dialog,
  DialogFooter,
  DialogType,
  ActionButton
} from "office-ui-fabric-react";
//   getPlanElementFiles(id: number);
import { getPlanElementFiles,getTaskElementFiles } from "../../actions/Action_Ukeplan";
import { connect } from "react-redux";
export interface IFilesModalProps {
  closemodal();
  id: number;
  getPlanElementFiles(id: number);
  getTaskElementFiles(Id:number)
  files: any[];
  mode:number;
}

class FilesModal extends React.Component<IFilesModalProps> {
  componentDidMount() {
    if(this.props.mode===1)
    {
      this.props.getPlanElementFiles(this.props.id);
    }
    if(this.props.mode===2)
    {
      this.props.getTaskElementFiles(this.props.id);
    }
  }

  public render() {
    return (
      <Dialog
        hidden={false}
        onDismiss={() => this.setState({ showCopyScheduleModal: false })}
        dialogContentProps={{
          type: DialogType.normal,
          title: "Filer",
          subText: ""
        }}
        styles={{
          main: [
            {
              selectors: {
                ["@media (min-width: 480px)"]: {
                  maxWidth: "600px",
                  minWidth: "480px"
                }
              }
            }
          ]
        }}
        modalProps={{
          titleAriaId: "myLabelId",
          subtitleAriaId: "mySubTextId",
          isBlocking: true,
          containerClassName: "ms-dialogMainOverride"
        }}
      >
        <div>
          <ul>
            {this.props.files &&
              this.props.files.map((f) => {
                return(<li>
                  <a target='_blank' href={f.FileUrl}>
                  {f.FileName}
                  </a>
                </li>)
              })}
          </ul>
        </div>

        <DialogFooter>
          <DefaultButton
            onClick={() => {
              this.props.closemodal();
            }}
            text="Lukk"
          />
        </DialogFooter>
      </Dialog>
    );
  }
}
function mapStateToProps(state) {
  return {
    files: state.ukeplanelements.Filelist
  };
}
export default connect(mapStateToProps, { getPlanElementFiles,getTaskElementFiles })(FilesModal);
