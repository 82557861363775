import * as React from "react";
import * as moment from "moment";
import { getStudentElements, getGradeReport, getActorElements, getCourseElements, checkStudentPortfolio, setPlanversjon, checkCoursePortfolio  } from "../../actions/Action_Portfolio";
import { getLPFagfornyelsen, getLPDetailsFagfornyelsen, getKMSet, getLPDetailsKunnskapsLoftet } from "../../actions/Action_Grep";
import { PortfolioListView } from "./PortfolioListView";
import HeaderView from "../view_containers/HeaderView";
import { Label, Panel, PanelType, Pivot, PivotItem, Stack, StackItem, TextField } from "office-ui-fabric-react";
import SpinLoader from "../loading/SpinLoader";
import { DropdownMenuItemType } from "office-ui-fabric-react/lib/Dropdown";
import { ActionButton, DefaultButton, PrimaryButton } from "office-ui-fabric-react/lib/Button";
import { connect } from "react-redux";
import DatePicker from "react-datepicker";
import {isEmpty}  from 'lodash';
import PortfolioLPView from "./PortfolioLPView";
import GradeReport from "./GradeReport";
import "./portfolio.scss";
import Select from "react-select";
import { setPortFilterValues } from "../../actions/Action_Filtervalues";
import { stringify } from "querystring";
import axios from "axios";
import { LoadingDialog } from "../loading/LoadingDialog";
import PortfolioLPFF from "./PortfolioLPFF";
import PortfolioLPKL from "./PortfolioLPKL";

export interface IPortfolioViewProps {
  getActorElements(teacherState: any);
  getStudentElements(student: string, lp: string, from: any, to: any);
  checkStudentPortfolio(student: string, lpKoder: string, from: any, to: any);
  checkCoursePortfolio(student: string, lpKoder: string, from: any, to: any);
  getCourseElements(student: string, lp: string, from: any, to: any);
  getGradeReport(course: any);
  setPortFilterValues(filterValues: any);
  getLPFagfornyelsen();
  getLPDetailsFagfornyelsen(lp: string);
  getLPDetailsKunnskapsLoftet(lp: string);
  getKMSet(km: string);
  setPlanversjon(pv: string);
  options: any[];
  optionslp: any[];
  optionsLpFF: any[];
  optionsLpKL: any[];
  optionsselect: any[];
  selitem: string;
  selitemlp: string;
  items: any[];
  listViewItems: any[];
  lp: ILaeringsplan;
  courseLp: any;
  teacherstate: any;
  gradereport: any;
  history: any;
  selItemFiltered: string[];
  selItemLpFiltered: string;
  fromDateFiltered: any;
  toDateFiltered: any;
  portButtonPressed: string;
  type: string;
  planversjon: string;
  portfolioItems: any[];
}

export interface IPorfolioViewState {
  selitem: string;
  selitemlp: string;
  result: any[];
  fromdate: any;
  todate: any;
  loading: boolean;
  showlp: boolean;
  showport: boolean;
  showgrades: boolean;
  kmsdetails: {};
  lpKoder: string[];
  lokalPlanversjon: string;
  hasBackendResponse: boolean;
  type: string;
  showSelectLpPanel: boolean;
  //
  selItemLabel: string;
  selItemLpLabel: string;
}

export interface IPortFilterValues {
  selItem: string[];
  selItemLp: string[];
  from: any;
  to: any;
  portButtonPressed: string;
  type: string;
}
//----------------------INTERFACE TIL KOMPETANSEMÅL-----------------------------//
//-------------------------------------------------------//
export interface ILaeringsplan {
  kompetansemaal_kapittel: {
    kompetansemaalsett: IKompetansemaalsettItem[];
  }
}
 
interface IKompetansemaalsettItem {
  kompetansemaal: IKompetanseMaal[];
  kode: string;
  etter_fag: Object[];
  etter_aarstrinn: Object[];
}

//Denne kan egentlig skrotes
export interface ILaeringsplan {
  kompetansemaal_kapittel: {
    kompetansemaalsett: IKompetansemaalsettItem[];
  }
}
 
interface IKompetansemaalsettItem {
  kompetansemaal: IKompetanseMaal[];
  kode: string;
  etter_aarstrinn: Object[];
  etter_fag: Object[];
}
 
interface IHovedOmraadeVerdiItem {
  kode: string;
  tittel: string[];
}
interface IKompetanseMaal {
  tittel: string;
  kode: string;
}

class PortfolioView extends React.Component<
  IPortfolioViewProps,
  IPorfolioViewState
> {
  constructor(props) {
    super(props);
    //   this.setState({
    //     fromdate: moment()
    // });

    let today = moment();
    var startDate   = moment("01/01/", "DD/MM/");
    var endDate     = moment("01/07/", "DD/MM/YYYY");
    let schoolstart;

    if (today.isBetween(startDate, endDate, 'days', null)) {
      schoolstart = moment().subtract(1, "year").week(33).startOf("week");
    }
    else {
      schoolstart = moment().week(33).startOf("week");
    }

    this.state = {
      fromdate: (schoolstart.date() < 16) ? schoolstart.add("week", 1) : schoolstart,
      todate: moment(),
      selitem: "",
      selitemlp: "",
      result: [],
      loading: false,
      showgrades: false,
      showlp: false,
      showport: false,
      lpKoder: [],
      lokalPlanversjon: "",
      kmsdetails: {},
      hasBackendResponse: false,
      type: "",
      showSelectLpPanel: false,
      //For retaining state
      selItemLabel: "",
      selItemLpLabel: "",
    };
  }
  /****************************
   Lifecycle hooks
   ****************************/
  componentWillMount(){
    this.props.getActorElements(this.props.teacherstate);
    this.props.getLPFagfornyelsen();
    this.setInitialPortValues();
    if (this.props.portButtonPressed) {
      this.renderFraFiltermetode();
    }
    if(this.props.planversjon && !this.state.lokalPlanversjon){
      this.setState({
        lokalPlanversjon: this.props.planversjon
      })
    }
  }

  componentDidMount() {
    if (this.state.showlp && this.props.planversjon === "Fagfornyelsen"){
        this.hentLPFagfornyelsen();
    }
  }
  

  /****************************
            Event Handlers
     ****************************/

  /****************************
            Helper methods
     ****************************/
  
  toggleLoading = () =>{
    this.setState(prevState =>({
      loading: !prevState.loading
    }))
  }
  
  hentLpPKunnskapsloftet() {
    this.props.getLPDetailsKunnskapsLoftet(this.state.selitemlp).then(() => {
      let type = this.state.selitem.split("|");
      let tmpArray = [];

      this.props.lp.kompetansemaal_kapittel.kompetansemaalsett.forEach(element => {
        element.kompetansemaal.forEach(item => {
          tmpArray.push(item.kode);
        });
      });

    this.setState({
      lpKoder: tmpArray
    })
    if (this.state.type === "e"){
      this.props
        .checkStudentPortfolio(
          type[0],
          JSON.stringify(this.state.lpKoder),
          this.state.fromdate.format(),
          this.state.todate.format()
        ).then(() => {
          this.setState({ loading: false });
        });
    }
    else if (this.state.type === "c") {
      this.props
        .checkCoursePortfolio(
          type[0],
          JSON.stringify(this.state.lpKoder),
          this.state.fromdate.format(),
          this.state.todate.format()
        ).then(() => {
          this.setState({ loading: false })
        })
      }
    }) 
  }

  hentLPFagfornyelsen() {
    let type = this.state.selitem.split("|");
    this.setState({kmsdetails: {}})
     this.props.getLPDetailsFagfornyelsen(this.state.selitemlp).then((km) => {
       let promarray = [];
       km.payload.data[
         "kompetansemaal-kapittel"
       ].kompetansemaalsett.forEach((element) => {
         
         promarray.push(this.props.getKMSet(element.kode));
       });
       
       Promise.all(promarray).then((results) => {
         let tmp = JSON.parse(
           JSON.stringify(this.state.kmsdetails)
         );
         results.forEach((element) => {
           tmp[element.payload.data.kode] = element.payload.data;
         });
         this.setState({ kmsdetails: tmp }); 
         
         let tmpKodeArray = [];
         Object.values(this.state.kmsdetails).forEach(element => {
           element['kompetansemaal'].forEach(item => {
             tmpKodeArray.push(item.kode);
           });
         });
         
         this.setState({
           lpKoder: tmpKodeArray
         })

       }).then(res => {
         if(this.state.type === "e"){
           this.props
           .checkStudentPortfolio(
             type[0],
             JSON.stringify(this.state.lpKoder),
             this.state.fromdate.format(),
             this.state.todate.format()
           ).then(() => {
             this.setState({
               hasBackendResponse: true
             })
           }
           );
        }
        else if(this.state.type === "c"){
          this.props
           .checkCoursePortfolio(
             type[0],
             JSON.stringify(this.state.lpKoder),
             this.state.fromdate.format(),
             this.state.todate.format()
           ).then(() => {
             this.setState({
               hasBackendResponse: true
             })
           }
          );
        }
       });
     }) 
  }

  setInitialPortValues() {
    this.props.selItemFiltered
      ? this.setState({
          selitem: this.props.selItemFiltered[0],
          selItemLabel: this.props.selItemFiltered[1],
        })
      : "";
    this.props.selItemLpFiltered
      ? this.setState({
          selitemlp: this.props.selItemLpFiltered[0],
          selItemLpLabel: this.props.selItemLpFiltered[1],
        })
      : "";
    this.props.toDateFiltered
      ? this.setState({
          todate: this.props.toDateFiltered,
        })
      : "";
    this.props.fromDateFiltered
      ? this.setState({
          fromdate: this.props.fromDateFiltered,
        })
      : "";
    this.props.type 
      ? this.setState({
          type: this.props.type
      })
      : ""
  }


  renderFraFiltermetode() {
    let type = this.state.selitem.split("|");
    if (this.props.portButtonPressed === "hent") {
      if (this.props.selItemLpFiltered[0] !== "") {
        this.setState({
          showgrades: false,
          showlp: true,
          showport: false,
        });
      } else {
        this.setState({
          showgrades: false,
          showlp: false,
          showport: true,
        });
      }
    } else {
      // karakterknapp
      this.setState({
        showgrades: true,
        showlp: false,
        showport: false,
      });
    }
  }
  sortStudCourseDropdown = () : any[] =>{
    let studArray = [];
    let courseArray = [];
    let headerArray = [];
    this.props.options.forEach(elem =>{
      let item = elem.key.split("|");
      item[1] === "e" ? studArray.push(elem) : item[1] === "c" ? courseArray.push(elem) : headerArray.push(elem);
    })
    studArray.sort((a,b) =>{
      return a.text > b.text ? 1 : b.text > a.text ? -1 : 0;
    });
    courseArray.sort((a,b) =>{
      return a.text > b.text ? 1 : b.text > a.text ? -1 : 0;
    });
    let final = [headerArray[0], ...studArray, headerArray[1], ...courseArray];
    return final;
  }
  /****************************
            Render methods
     ****************************/
  private _onRenderFooterContent = () => {
    return (
      <div>
        <PrimaryButton
          text='Lukk'
          onClick={() => {
            this.setState({ showSelectLpPanel: false });
          }}></PrimaryButton>
      </div>
    );
  };
  
  render() {
    let sortedDropdown = this.sortStudCourseDropdown();
    return (
      <div className="ms-Grid" id="portfolio-teacher-view">
        <div className="container main">
          <HeaderView
            title="Portefølje"
            description="Finn læremål og status for dine elever/fag"
            iconName="FabricOpenFolderHorizontal"
          />
          <div className="ms-Grid-row ms-bgColor-neutralLight topTool">
            <div className="ms-Grid-row">
                {sortedDropdown.length > 2 ? (
                  <React.Fragment>
              <div className="ms-Grid-col ms-sm12 ms-lg6">
                  <Select
                    defaultValue={
                      this.props.selItemFiltered
                        ? {
                            label: this.props.selItemFiltered[1],
                            value: this.props.selItemFiltered[0],
                          }
                        : {}
                    }
                    placeholder="Finn elev eller fag"
                    onChange={(item) => {
                      if (item) {
                        this.setState({
                          selitem: item.key.toString(),
                          selItemLabel: item.label.toString(),
                        });
                      } else {
                        this.setState({
                          selitem: "",
                          selItemLabel: "",
                        });
                      }
                    }}
                    options={sortedDropdown}
                    isClearable={true}
                  />
                

                {/* <Dropdown
                                    placeHolder="Finn elev eller fag"
                                    label="Elev/Fag"

                                    onChanged={(item) => {
                                        this.setState({
                                            selitem: item.key.toString()
                                        });
                                    }}
                                    //onFocus={this._log('onFocus called')}
                                    //onBlur={this._log('onBlur called')}

                                    options={this.props.options}
                                /> */}
                {/* <Select2
                                        multiple
                                        data={this.props.optionsselect}
                                        options={{
                                            placeholder: 'search by tags',
                                        }}
                                    /> */}
              </div>
              <div className="ms-Grid-col ms-sm12 ms-lg6">
                <Stack
                  horizontal
                  >
                  <Stack.Item grow={0}>
                    <ActionButton
                      style={{flexGrow: 0}}
                      iconProps={{ iconName: "Add" }}
                      onClick={() => {
                        this.setState({ showSelectLpPanel: true })
                      }}>
                        Velg Læringsplan
                    </ActionButton>
                  </Stack.Item>
                  <Stack.Item>
                  </Stack.Item>
                  <Stack.Item grow={1}>
                    <Stack style={{height: "100%"}} verticalAlign="center">
                      <TextField readOnly disabled={!this.state.selitemlp} defaultValue={
                              this.state.selitemlp && this.state.selItemLpLabel ? 
                                this.state.selItemLpLabel :
                              this.props.selItemLpFiltered && this.state.selitemlp != ""
                                ?  this.props.selItemLpFiltered[1]
                                : "Valgt Læringsplan"
                            }
                            />
                    </Stack>
                  </Stack.Item>
                  <Stack.Item grow={0}>
                    <Stack style={{height: "100%"}} verticalAlign="center">
                      <DefaultButton
                      primary={true}
                      data-automation-id="test"
                      disabled={isEmpty(this.state.selitemlp)}
                      text="Fjern"
                      allowDisabledFocus={true}
                      onClick={() => {
                        this.setState({
                          selitemlp: "",
                          selItemLpLabel: "",
                          lokalPlanversjon: ""
                        });
                      }}
                      ></DefaultButton>
                    </Stack>
                  </Stack.Item>
                </Stack>
                <Panel
                  isLightDismiss
                  isOpen={this.state.showSelectLpPanel}
                  type={PanelType.medium}
                  onDismiss={() => {
                    this.setState({ showSelectLpPanel: false });
                  }}
                  headerText='Velg Læringsplan'
                  closeButtonAriaLabel='Lukk'
                  onRenderFooterContent={this._onRenderFooterContent}
                >
                  <div style={{ height: "80vh" }}>
                    <Pivot>
                      <PivotItem headerText="Fagfornyelsen">
                        <Select
                          maxMenuHeight={600}
                          menuPlacement="auto"
                          placeholder="Finn en læreplan fra Fagfornyelsen"
                          defaultValue={
                            this.state.lokalPlanversjon === "Fagfornyelsen" && this.state.selitemlp && this.state.selItemLpLabel ? {
                              value: this.state.selitemlp,
                              label: this.state.selItemLpLabel,
                            } :
                            this.props.selItemLpFiltered && this.props.planversjon === "Fagfornyelsen" && this.state.lokalPlanversjon != "Kunnskapsloftet" && this.state.selitemlp != ""
                              ? {
                                  label: this.props.selItemLpFiltered[1],
                                  value: this.props.selItemLpFiltered[0],
                                }
                              : ""
                          }
                          onChange={(item) => {
                            if (item) {
                              this.setState({
                                selitemlp: item.key.toString(),
                                selItemLpLabel: item.label.toString(),       
                                lokalPlanversjon: item.planVersjon                 
                              });
                            } else {
                              this.setState({
                                selitemlp: "",
                                selItemLpLabel: "",
                                lokalPlanversjon: ""
                              });
                            }
                          }}
                          options={this.props.optionsLpFF}
                          isClearable={true}
                        />
                      </PivotItem>
                    
                      <PivotItem headerText="Kunnskapsløftet">
                        <Select
                          maxMenuHeight={600}
                          placeholder="Finn en læreplan fra Kunnskapsløftet"
                          defaultValue={
                            this.state.lokalPlanversjon === "Kunnskapsloftet" && this.state.selitemlp && this.state.selItemLpLabel ? {
                              value: this.state.selitemlp,
                              label: this.state.selItemLpLabel,
                            } :
                            this.props.selItemLpFiltered && this.props.planversjon === "Kunnskapsloftet" && this.state.lokalPlanversjon != "Fagfornyelsen" && this.state.selitemlp != ""
                              ? {
                                  label: this.props.selItemLpFiltered[1],
                                  value: this.props.selItemLpFiltered[0],
                                }
                              : ""
                          }
                          onChange={(item) => {
                            if (item) {
                              this.setState({
                                selitemlp: item.key.toString(),
                                selItemLpLabel: item.label.toString(),       
                                lokalPlanversjon: item.planVersjon                 
                              });
                            } else {
                              this.setState({
                                selitemlp: "",
                                selItemLpLabel: "",
                                lokalPlanversjon: ""
                              });
                            }
                          }}
                          options={this.props.optionsLpKL}
                          isClearable={true}
                        />
                      </PivotItem>
                    </Pivot>
                  </div>
                </Panel>
              </div>
              </React.Fragment>)
              : <SpinLoader text="Laster elever og læringsplaner" />
              }
            </div>
            <div className="ms-Grid-row">
              <div className="mt-10">
                <span className="ms-Grid-col ms-sm4 ms-lg2 ms-xl1 ms-xxl1">
                  <Label className="date-label">Fra:</Label>
                </span>
                <div className="ms-Grid-col ms-sm8 ms-lg4 ms-xl3 ms-xxl2">
                  <DatePicker
                    selected={
                      (this.props.fromDateFiltered && !this.state.fromdate)
                        ? this.props.fromDateFiltered
                        : this.state.fromdate
                    }
                    locale="nb-NO"
                    onChange={(date) => {
                      this.setState({
                        fromdate: date,
                      });
                    }}
                    autocomplete="off"
                    onBlur={() => {}}
                    showWeekNumbers
                  />
                </div>
                <span className="ms-Grid-col ms-sm4 ms-lg2 ms-xl1 ms-xxl1">
                  <Label className="date-label">Til:</Label>
                </span>
                <div className="ms-Grid-col ms-sm8 ms-lg4 ms-xl3 ms-xxl2">
                  <DatePicker
                    selected={
                      (this.props.toDateFiltered && !this.state.todate)
                        ? this.props.toDateFiltered
                        : this.state.todate
                    }
                    locale="nb-NO"
                    onChange={(date) => {
                      this.setState({
                        todate: date,
                      });
                    }}
                    autocomplete="off"
                    onBlur={() => {}}
                    showWeekNumbers
                  />
                </div>
                <div className="ms-Grid-col ms-sm12 ms-lg12 ms-xl4 ms-xxl6">
                  <DefaultButton
                    primary={true}
                    data-automation-id="test"
                    disabled={!this.state.selitem}
                    text="Hent"
                    onClick={() => {
                     this.setState({
                       hasBackendResponse: false
                     });
                     let type = this.state.selitem.split("|");
                      let portFilterValues: IPortFilterValues = {
                        selItem: [this.state.selitem, this.state.selItemLabel],
                        selItemLp: [
                          this.state.selitemlp,
                          this.state.selItemLpLabel,
                        ],
                        to: this.state.todate,
                        from: this.state.fromdate,
                        portButtonPressed: "hent",
                        type: type[1]
                      };
                      this.props.setPortFilterValues(portFilterValues);
                      this.setState({ loading: true});
                      
                      this.props.setPlanversjon(this.state.lokalPlanversjon);
                      
                      if (this.state.selitemlp.length > 0) {
                        this.setState({
                          showgrades: false,
                          showlp: true,
                          showport: false,
                          type: type[1]
                        });
                      } else {
                        this.setState({
                          showgrades: false,
                          showlp: false,
                          showport: true,
                          type: type[1]
                        });
                      }
                      if (type[1] == "e") {
                        if(this.state.lokalPlanversjon === "Kunnskapsloftet" && this.state.selitemlp.length > 0){
                          this.hentLpPKunnskapsloftet();
                        }
                        else if(this.state.lokalPlanversjon === "Fagfornyelsen" && this.state.selitemlp.length > 0){
                          this.hentLPFagfornyelsen();
                        }
                        else if(this.state.selitemlp.length < 1){
                          this.props
                          .getStudentElements(
                            type[0],
                            this.state.selitemlp,
                            this.state.fromdate.format(),
                            this.state.todate.format()
                          )
                          .then(() => {
                            this.setState({ loading: false });
                          });
                        }         
                      } else {
                        if(this.state.lokalPlanversjon === "Kunnskapsloftet"){
                          this.hentLpPKunnskapsloftet();
                        }
                        else if (this.state.lokalPlanversjon === "Fagfornyelsen"){
                          this.hentLPFagfornyelsen();
                        }
                        else{
                          this.props
                            .getCourseElements(
                              type[0],
                              this.state.selitemlp,
                              this.state.fromdate.format(),
                              this.state.todate.format()
                            )
                            .then(() => {
                              this.setState({ loading: false });
                            });
                        }
                      }
                    }}
                    allowDisabledFocus={true}
                  />
                  <DefaultButton
                    primary={true}
                    data-automation-id="test"
                    disabled={!this.state.selitem}
                    text="Karakteroversikt"
                    onClick={() => {
                      let portFilterValues: IPortFilterValues = {
                        selItem: [this.state.selitem, this.state.selItemLabel],
                        selItemLp: [
                          this.state.selitemlp,
                          this.state.selItemLpLabel,
                        ],
                        to: this.state.todate,
                        from: this.state.fromdate,
                        portButtonPressed: "karakteroversikt",
                        type: ""
                      };
                      this.props.setPortFilterValues(portFilterValues);
                      this.setState({
                        showgrades: true,
                        showlp: false,
                        showport: false,
                      });
                      this.props.getGradeReport({
                        CourseID: this.state.selitem,
                      });
                    }}
                    allowDisabledFocus={true}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="ms-Grid-row">
            <div className="ms-Grid-col ms-sm12">
              <div id="detailView">
                {
                  // Renders SpinLoader while loading courses if it is not fetched from an earlier request
                  this.state.loading && <SpinLoader text="Laster portefølje" />
                }
                {this.props.lp  && this.state.showlp && (!this.state.loading || (this.props.planversjon === "Fagfornyelsen" && this.state.hasBackendResponse)) && this.props.portfolioItems && (!isEmpty(this.state.kmsdetails) || this.props.planversjon === "Kunnskapsloftet") && (
                //this.props.lp && this.state.type === "e" && this.state.showlp && (!this.state.loading || (this.props.planversjon === "Fagfornyelsen" && this.state.hasBackendResponse)) && this.props.portfolioItems && (!isEmpty(this.state.kmsdetails) || this.props.planversjon === "Kunnskapsloftet") && (
                  <div> 
                    {this.props.planversjon === "Fagfornyelsen" ? 
                        <PortfolioLPFF
                          history={this.props.history}
                          item={this.props.lp}
                          items={this.props.items}
                          kmsdetails={this.state.kmsdetails}
                          portfolioItems= {this.props.portfolioItems}
                          toggleLoading={this.toggleLoading}
                        ></PortfolioLPFF>
                      :  <PortfolioLPKL
                          history={this.props.history}
                          item={this.props.lp}
                          items={this.props.items}
                        ></PortfolioLPKL> 
                      }
                  </div>
                )}

                {this.state.showport && !this.state.loading && this.props.listViewItems.length > 0 &&(
                  <PortfolioListView
                    items={this.props.listViewItems}
                    history={this.props.history}
                  ></PortfolioListView>
                )}

                {this.props.gradereport && this.state.showgrades && (
                  <GradeReport
                    history={this.props.history}
                    gradereport={this.props.gradereport}
                  ></GradeReport>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}


function mapStateToProps(state) {
  let drp = [];
  let main = [];
  let studlist = [];
  let sel = [];


  if (state.actorelements.Students) {
    drp.push({
      key: "Header1",
      text: "Elever",
      itemType: DropdownMenuItemType.Header,
    }) 
      state.actorelements.Students.forEach((element) => {
        drp.push({
          key: element.LoginName + "|e",
          text: element.Name,
          label: element.Name,
          value: element.LoginName,
        });
        studlist.push({ text: element.Name, id: element.LoginName });
        
      });
    main.push({
      text: "Elever",
      children: studlist,
    });
  }
  if (state.actorelements.Courses) {
    drp.push({
      key: "Header2",
      text: "Fag",
      itemType: DropdownMenuItemType.Header,
    }) 
      state.actorelements.Courses.forEach((element) => {
        drp.push({
          key: element.CourseID + "|c",
          text: element.Title,
          label: element.Title,
          value: element.CourseID,
        });
      });
  }
  let drplp = [];
  let drpLpFF = [];
  let drpLpKL = [];

  if (state.actorelements.Lplist) {
    drpLpKL.push({
          key: "Header2",
          text: "Læreplan",
          itemType: DropdownMenuItemType.Header,
    });

    state.actorelements.Lplist.forEach((l) => {
      l.planVersjon = "Kunnskapsloftet";
      let tit = "";
      l.title.forEach((element) => {
        if (element.spraak === "default") {
          tit = element.verdi;
        }
      });
      drpLpKL.push({
        planVersjon: l.planVersjon,
        key: l.kode,
        text: l.title[0].verdi,
        value: l.kode,
        label: tit + " (" + l.kode + ")",
      });
    });
  }

  if (state.GrepReducer.lpFagfornyelsen) {
    drpLpFF.push({
      key: "Header2",
      text: "Læreplan",
      itemType: DropdownMenuItemType.Header,
    }) 

    state.GrepReducer.lpFagfornyelsen.forEach((kompMal) => {
      kompMal.planVersjon = "Fagfornyelsen";
      let tit = "";
      kompMal.tittel.forEach((element) => {
        if (element.spraak === "default"){
          tit = element.verdi;
        }
      });
      drpLpFF.push({
        planVersjon: kompMal.planVersjon,
        key: kompMal.kode,
        text: kompMal.tittel[0].verdi,
        value: kompMal.kode,
        label: tit + " (" + kompMal.kode + ")",
      });
    });
  }
  
  let portFilterValues: IPortFilterValues = {
    selItem: ["", ""],
    selItemLp: ["", ""],
    to: moment().add("years", -1),
    from: moment(),
    portButtonPressed: "",
    type: ""
  };
  if (state.FilterReducer.portFilterValues) {
    portFilterValues = state.FilterReducer.portFilterValues;
  }
  let items = [];
  let listViewItems = [];
  let lp = null;
  let courseLp = null;

  if (state.actorelements.portfolio) {
    listViewItems = state.actorelements.portfolio.PortItems;
    courseLp = state.actorelements.portfolio.Lp;
  }

  if(state.GrepReducer.lpdetails){
    lp = lpMapper(state.GrepReducer.lpdetails);
  }

  let pfItems = null;

  if(state.actorelements.studentPortfolioTasks){
    items = state.actorelements.studentPortfolioTasks.PortItems;

    if(state.actorelements.studentPortfolioTasks.PortfolioItems){
      let portfolioToMap = state.actorelements.studentPortfolioTasks.PortfolioItems;
      lp.kompetansemaal_kapittel.kompetansemaalsett.forEach((element) => {
        element.kompetansemaal.forEach(kompMal => {
          portfolioToMap.forEach((portfolioItem) => {
            if(kompMal.kode == portfolioItem.KompetansemaalKode && ( portfolioItem.Tasks.length > 0 || 
              portfolioItem.Texts.length > 0 || portfolioItem.Wstasks.length > 0 || 
              (portfolioItem.QuizAttempts && portfolioItem.QuizAttempts.length > 0) || 
              (portfolioItem.Quizzes && portfolioItem.Quizzes.length > 0))){
              kompMal.portfolioitems = portfolioItem;
            }
          });
        });
      });
      pfItems = state.actorelements.studentPortfolioTasks.PortfolioItems;
    }
  }

  function lpMapper(lpToMap: any): ILaeringsplan {
    if(state.GrepReducer.lpdetails["grep-type"] === "http://psi.udir.no/ontologi/kl06/laereplan"){

      return {
        kompetansemaal_kapittel : {
          kompetansemaalsett: lpToMap["kompetansemaal-kapittel"].kompetansemaalsett.map(kompMalSett => {
            return {
              etter_aarstrinn: kompMalSett["etter-aarstrinn"],
              etter_fag: kompMalSett["etter-fag"],
              hovedomraader_i_kontekst_av_kompetansemaalsett: kompMalSett["hovedomraader-i-kontekst-av-kompetansemaalsett"].map(hovedOmraade => {
                
                return {
                  kode: hovedOmraade.kode,
                  hovedomraadeverdier_under_kompetansemaalsett: hovedOmraade["hovedomraadeverdier-under-kompetansemaalsett"]
                }
              }),
              kompetansemaal: kompMalSett.kompetansemaal.map(kompMal => {
                return {
                  tilhoerer_hovedomraade: {
                    kode:  kompMal["tilhoerer-hovedomraade"] ? kompMal["tilhoerer-hovedomraade"].kode : null
                  },
                  tittel: kompMal.tittel,
                  portfolioitems: {
                    Tasks: [],
                    Texts: [],
                    Wstasks: [],
                    QuizAttempts: [],
                    Quizzes: [],
                  },
                  kode: kompMal.kode
                }
              }),
              kode: kompMalSett.kode,
             
            }
          })
        }
      } as ILaeringsplan;
    }
    else{
      return {
        kompetansemaal_kapittel : {
          kompetansemaalsett: lpToMap["kompetansemaal-kapittel"].kompetansemaalsett.map(kompMalSett => {
            return {
              kompetansemaal:[],
              kode: kompMalSett.kode,
            }
          })
        }
      } as ILaeringsplan;
    }
  }

  
  

  return {
    planversjon: state.actorelements.planversjon,
    selItemFiltered: portFilterValues.selItem,
    selItemLpFiltered: portFilterValues.selItemLp,
    toDateFiltered: portFilterValues.to,
    fromDateFiltered: portFilterValues.from,
    portButtonPressed: portFilterValues.portButtonPressed,
    type: portFilterValues.type,
    options: drp,
    items: items,
    listViewItems: listViewItems,
    optionslp: drplp,
    optionsLpFF: drpLpFF,
    optionsLpKL: drpLpKL,
    optionsselect: main,
    teacherstate: state.teacher,
    lp: lp,
    gradereport: state.actorelements.gradereport,
    portfolioItems: pfItems,
    courseLp: courseLp
  };
}

export default connect(mapStateToProps, {
  getActorElements,
  getStudentElements,
  getGradeReport,
  getCourseElements,
  setPortFilterValues,
  setPlanversjon,
  getLPFagfornyelsen,
  getLPDetailsFagfornyelsen,
  getLPDetailsKunnskapsLoftet,
  checkStudentPortfolio,
  checkCoursePortfolio,
  getKMSet
})(PortfolioView);
