import * as React from 'react';

import { withRouter, Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { Field, reduxForm, change, getFormValues } from 'redux-form';
import { DATE_FORMAT, LOCALE, TIME_FORMAT, planUtils } from './PlanElementEditView';
import {
	Dropdown,
	TextField,
	Checkbox,
	PrimaryButton,
	ActionButton,
	DefaultButton,
	Label,
	IconButton,
	Icon,
	Spinner,
	SpinnerSize,
	Stack,
	DialogType,
	Dialog,
	DialogFooter
} from 'office-ui-fabric-react';
import DatePicker from 'react-datepicker';
import * as moment from 'moment';
import ReactQuill from 'react-quill'; // Typescript
import { updatePlanElement, deletePlanElement, getAssignmentGroup } from '../../actions/Action_Ukeplan';
import KmpgoalPicker from '../shared/KmpgoalPicker';
import SkolonPicker from '../shared/SkolonPicker';
import OneDrivePicker from '../shared/OneDrivePicker';
import '../../../../src/app/formelements.scss';
import { store } from 'react-notifications-component';
import { PlanElement } from '../../models/PlanElement';
import { dateStringToMoment, isDateStringBeforeToday } from '../../utils/DateUtils';
import SelectField from '../form_fields/SelectField';
import ReactTooltip from 'react-tooltip';
import CheckboxField from '../form_fields/CheckboxField';
import { isSameGroupAsBefore } from '../../guards/CopyPlanGuard';
export interface IPlanElementFormProps {
	formValues: PlanElement;

	planElements: {};
	courses: any;
	groups: any[];
	schoolClasses: any;

	initialValues: any;
	handleSubmit: any;
	history: any;
	match: any;
	deletePlanElement(se, callback);
	getAssignmentGroup(course: any);
	selectedfiles: any[];
	selected: any[];
	skolonpicks:any[];
	touch: any;
	change: (key: string, value: any) => void;
	mode: string;
	showskolon: boolean;
}
export interface IPlanElementFormState {
	showkmp: boolean;
	selected: any[];
	showonedrive: boolean;
	selectedfiles: any[];
	saving: boolean;
	showCopyPlanDialog: boolean;
	skolonpicks: any[];
}


class PlanElementForm extends React.Component<IPlanElementFormProps, IPlanElementFormState> {
	constructor(props) {
		super(props);
		this.state = {
			showkmp: false,
			selected: null,
			showonedrive: false,
			selectedfiles: null,
			saving: false,
			showCopyPlanDialog: false,
			skolonpicks: []
		};

		this.onFormSubmit = this.onFormSubmit.bind(this);
	}


	/****************************
			Event handlers
	 ****************************/
	componentWillReceiveProps(nextProps: IPlanElementFormProps) {
		if (!this.state.selectedfiles) {
			this.setState({
				selectedfiles: this.props.selectedfiles,
				selected: this.props.selected,
				skolonpicks:this.props.skolonpicks
			});
		}

		// Setter tilretteleggingsgruppe til 'Alle' dersom fag har blitt valgt for første gang
		if (nextProps.formValues.CourseID && !this.props.formValues.CourseID) {
			this.props.change('GroupID', 0);
		}
	}

	onFormSubmit(values) {
		const requestValues = this.formatFormValueDatesToDateString(values);
		let tmp = requestValues;

		tmp = JSON.stringify(tmp);
		tmp = JSON.parse(tmp)

		if (tmp.Goal) {
			if (tmp.Goal == '<p><br></p>' || tmp.Goal.replace(/\s/g, "") == "<p></p>") {
				tmp.Goal = '';
			}
		}
		if (tmp.Text) {
			if (tmp.Text == '<p><br></p>' || tmp.Text.replace(/\s/g, "") == "<p></p>") {
				tmp.Text = '';
			}
		}
		if (tmp.TextOnPrem) {
			if (tmp.TextOnPrem == '<p><br></p>' || tmp.TextOnPrem.replace(/\s/g, "") == "<p></p>") {
				tmp.TextOnPrem = '';
			}
		}
		tmp.SkolonResources = this.state.skolonpicks;
		tmp.GrepCodeList = this.state.selected;
		tmp.TaskFiles = this.state.selectedfiles;
		tmp.TaskDocument = '';
		this.state.selectedfiles.forEach((element) => {
			if (tmp.TaskDocument.length > 0) {
				tmp.TaskDocument += ';';
			}
			tmp.TaskDocument += element.OriginalDocumentID + '|' + element.FileName;
		});

		tmp.Grep = '';
		this.state.selected.forEach((element) => {
			if (tmp.Grep.length > 0) {
				tmp.Grep += ';';
			}
			tmp.Grep += element.KMID + '|' + element.LPID + '|' + element.GrepTekst;
		});
		let evals = [];
		if (requestValues.CheckInnlevering) {
			evals.push('Innlevering');
		}
		if (requestValues.CheckPresentasjon) {
			evals.push('Presentasjon');
		}
		if (requestValues.CheckProve) {
			evals.push('Prøve');
		}
		if (requestValues.CheckTest) {
			evals.push('Test');
		}
		if (requestValues.CheckLeksesjekk) {
			evals.push('Leksesjekk');
		}
		tmp.EvalElements = evals;
		updatePlanElement(tmp, (ret) => {
			this.props.history.push('/ukeplan/' + ret.Id + '/v');
			//this.props.history.push("/ukeplan");
		}, () => {
			this.setState({
				saving: false,
				showCopyPlanDialog: false
			});
			store.addNotification({
				title: "Noe gikk galt!",
				message: 'Kunne ikke lagre endringene, vennligst prøv igjen senere.',
				type: "danger",
				insert: "top",
				container: "top-right",
				animationIn: ["animated", "fadeIn"],
				animationOut: ["animated", "fadeOut"],
				dismiss: {
					duration: 5000,
					onScreen: true,
				}
			});
		});

	}

	onDeleteClick() {
		const { id } = this.props.match.params;

		if (isNaN(id)) {
			return;
		}

		if (confirm('Vil du virkelig slette dette ukeplanelementet?')) {
			this.props.deletePlanElement(this.props.planElements[id], () => {
				this.props.history.push('/ukeplan');
			});
		}
	}

	/****************************
			Helper methods
	 ****************************/

	formatFormValueDatesToDateString(values) {
		return values;
	}
	focusTopRef = () => {
		document.querySelector('#root > div > div > div:nth-child(3) > div:nth-child(3)').scrollTo(0, 0);
	};

	getGroupFieldErrorMessage = (touched: any, error: any) => {
		if (this.props.formValues.CourseID == this.props.initialValues.CourseID && this.props.formValues.GroupID == this.props.initialValues.GroupID) {
			if (this.props.mode != "n" && planUtils.isGroupDeleted(this.props.groups, this.props.formValues.GroupID)) {
				return "Tilretteleggingsgruppen har blitt slettet og er ikke lenger gyldig";
			}
		}

		return touched ? error : "";
	}

	formIsValid = (): boolean => {
		if (planUtils.isGroupDeleted(this.props.groups, this.props.formValues.GroupID)) {
			return false;
		}
		return true;
	}
	hasErrorCopying = (error: any) => {
		return (error && (this.props && this.props.mode && (this.props.mode == "c" || this.props.mode == "e")));
	}
	/****************************
			Render methods
	 ****************************/

	renderDateTimeField = (field) => {
		const {
			input,
			required,
			meta: { touched, error }
		} = field;
		const selectedDate = input.value ? moment(input.value, DATE_FORMAT) : null;

		return (
			<div className="ms-Grid-row">
				<div className={`z-form-group ${touched && error ? 'error' : ''}`}>
					<div className="ms-Grid-col ms-sm12 ms-md4">
						<Label required={required} className="z-form-label">
							{field.label}
						</Label>
					</div>
					<div className="ms-Grid-col ms-sm12 ms-md8">
						<DatePicker
							{...input}
							onChange={(date) => {
								input.onChange(date.locale(LOCALE).format(DATE_FORMAT));
							}}
							timeFormat="HH:mm"
							timeIntervals={5}
							minTime={moment().hours(8).minutes(0)} // ScheduleElements cannot start before 8:00AM
							maxTime={moment().hours(16).minutes(0)} // ScheduleElements cannot end after 16:00AM
							// filterDate={(date) => { return date.day() !== 0 && date.day() !== 6; }}   // Weekends are disabled
							locale="nb-NO"
							dateFormat={DATE_FORMAT}
							timeCaption="Tid"
							errorMessage={error ? error : ''}
							required={required}
							showWeekNumbers
							onBlur={() => {
								console.log('bluring');
							}}
							autocomplete="off"
						/>
						{(touched && error) || this.hasErrorCopying(error) && <span className="ms-TextField-errorMessage formErrorMessage">{error}</span>}
					</div>
				</div>
			</div>
		);
	}

	renderRichTextField(field) {
		const {
			input,
			meta: { touched, error },
			children
		} = field;

		return (
			<div className="ms-Grid-row">
				<div className="z-form-group">
					<div className="ms-Grid-col ms-sm12 ms-md4">
						<Label className="z-form-label">
							{field.label}
							<span>{error ? '*' : ''}</span>
						</Label>
					</div>
					<div className="ms-Grid-col ms-sm12 ms-md8">
						<ReactQuill
							value={input.value}
							onChange={(html) => {
								input.onChange(html);
							}}
						/>
					</div>
				</div>
			</div>
		);
	}
	renderTextField(field) {
		const {
			meta: { touched, error }
		} = field;

		return (
			<div className="ms-Grid-row">
				<div className="z-form-group">
					<div className="ms-Grid-col ms-sm12 ms-md4">
						<Label className="z-form-label">
							{field.label}
							<span>{error ? '*' : ''}</span>
						</Label>
					</div>
					<div className="ms-Grid-col ms-sm12 ms-md8">
						<TextField errorMessage={touched ? error : ''} {...field.input} />
					</div>
				</div>
			</div>
		);
	}
	renderTextFieldRequired(field) {
		const {
			meta: { touched, error }
		} = field;

		return (
			<div className="ms-Grid-row">
				<div className="z-form-group">
					<div className="ms-Grid-col ms-sm12 ms-md4">
						<Label required={true} className="z-form-label">
							{field.label}
						</Label>
					</div>
					<div className="ms-Grid-col ms-sm12 ms-md8">
						<TextField errorMessage={touched ? error : ''} {...field.input} />
					</div>
				</div>
			</div>
		);
	}
	renderFilesField(field) {
		const {
			meta: { touched, error }
		} = field;

		return (
			<div className="ms-Grid-row">
				<div className="z-form-group">
					<div className="ms-Grid-col ms-sm12 ms-md4">
						<Label className="z-form-label"></Label>
					</div>
					<div className="ms-Grid-col ms-sm12 ms-md8"></div>
				</div>
			</div>
		);
	}

	renderSelectField(field) {
		const {
			input,
			meta: { touched, error },
			children,
			required,
			disabled
		} = field;

		let options = children.props.children;
		const alleOption = options[0];
		const rest = options.filter((elem) => {
			return elem.key !== 0;
		});
		rest.sort((a, b) => {
			return a.text > b.text ? 1 : b.text > a.text ? -1 : 0;
		});
		options = [alleOption, ...rest];
		return (
			<div className="ms-Grid-row">
				<div className="z-form-group">
					<div className="ms-Grid-col ms-sm12 ms-md4">
						<Label required={required} className="z-form-label">
							{field.label}
						</Label>
					</div>
					<div className="ms-Grid-col ms-sm12 ms-md8">
						<Dropdown
							{...input}
							options={options}
							selectedKey={input.value}
							required={required}
							disabled={disabled}
							errorMessage={touched ? error : ''}
							onChanged={(item) => {
								input.onChange(item.key);
							}}
						/>
					</div>
				</div>
			</div>
		);
	}

	renderGroupSelectField = (field) => {
		const {
			input,
			meta: { touched, error },
			children,
			required,
			disabled
		} = field;

		
		let options = this.props.formValues.CourseID == this.props.initialValues.CourseID && this.props.formValues.GroupID == this.props.initialValues.GroupID ?
			planUtils.mapGroupElementsToKeysExperimental(this.props.groups, this.props.formValues ? this.props.formValues.GroupID : this.props.initialValues.GroupID)
			: planUtils.mapGroupElementsToKeys(this.props.groups);

		return (
			<div className="ms-Grid-row">
				<div className="z-form-group">
					<div className="ms-Grid-col ms-sm12 ms-md4">
						<Label required={required} className="z-form-label">
							{field.label}
						</Label>
					</div>
					<div className="ms-Grid-col ms-sm12 ms-md8">
						<Dropdown
							{...input}
							options={options}
							selectedKey={input.value}
							required={required}
							disabled={disabled}
							errorMessage={this.getGroupFieldErrorMessage(touched, error)}
							onChanged={(item) => {
								input.onChange(item.key);
							}}
						/>
					</div>
				</div>
			</div>
		);
	}

	renderCheckField(field) {
		const {
			input,
			meta: { touched, error },
			children
		} = field;

		return (
			<div className="ms-Grid-col ms-sm12 ms-md6">
				<Checkbox
					checked={input.value}
					label={field.label}
					errorMessage={touched ? error : ''}
					{...field.input}
					onChange={(event, u) => {
						input.onChange(u);
					}}
				/>
				<label>
					<span>{error ? '*' : ''}</span>
				</label>
			</div>
		);
	}
	onCourseChanged = (CourseID: number) => {
		this.props.getAssignmentGroup({ CourseID });
		this.props.change("GroupID", 0);
	};

	renderCourseLabel = (courses: any) => {
		if (courses && Object.keys(courses).length > 0) {
			return <label>Fag</label>
		}
		else {

			return <label><span style={{ display: "flex", alignItems: "center" }}> Fag <Icon data-tip data-for='courseTooltip' iconName="AlertSolid" style={{ marginLeft: 4, fontSize: 20, color: "#b30000" }} />
				<ReactTooltip id="courseTooltip">
					<p style={{ fontSize: 15, wordBreak: "normal", wordWrap: "normal", textTransform: "none" }}>Ingen fag er valgt. Vennligst velg minst ett fag fra administrasjonssenteret før du oppretter et nytt timeplanelement</p>
				</ReactTooltip></span></label>
		}

	}

	render() {
		const { handleSubmit } = this.props;
		return (
			<form id="PlanElementForm" onSubmit={handleSubmit(this.onFormSubmit.bind(this))}>
				<div className="form-content">
					<Field
						name="CourseID"
						// label="Fag"
						label={this.renderCourseLabel(this.props.courses)}
						// component={this.renderSelectField}
						component={SelectField}
						required={true}
						onSelect={this.onCourseChanged}
						disabled={!this.props.courses || (this.props.courses && Object.keys(this.props.courses).length == 0)}
					// onChange={field => {
					//   var a = "";
					//   Object.keys(field).forEach(k => {
					//     if (field[k].toString().indexOf("function") < 0) {
					//       a += field[k];
					//     }
					//   });

					//   this.props.getAssignmentGroup({ CourseID: a });
					// }}
					>
						{this.props.courses && Object.keys(this.props.courses).length > 0 && <option>{planUtils.mapPlanElementsToKeys(this.props.courses)}</option>}
					</Field>
					<Field
						name="GroupID"
						label="Tilretteleggingsgruppe"
						component={this.renderGroupSelectField}
						required={true}
						disabled={!this.props.formValues.CourseID}
						onChange={(field) => { }}
					>
					</Field>
					<Field
						name="Theme"
						label="Tema" // Custom fields variables can be fetched in component (i.e. field.label)
						required={true}
						component={this.renderTextFieldRequired}
					/>

					<div className="ms-Grid-row">
						<div className="z-form-group">
							<div className="ms-Grid-col ms-sm12 ms-md4">
								<label className="z-form-label ms-Label">Kompetansemål</label>
							</div>

							<div className="ms-Grid-col ms-sm12 ms-md8">
								<Field
									name="ShowKmpGoals"
									label="Vis kompetansemål på ukeplan for elever" // Custom fields variables can be fetched in component (i.e. field.label)
									component={this.renderCheckField}
								/>
								<div className="ms-Grid-col ms-sm12">
									{this.state.selected && (
										<KmpgoalPicker
											selected={this.state.selected}
											onClose={(selected) => {
												console.log('selected', selected);
												this.setState({ selected: selected });
											}}
										></KmpgoalPicker>
									)}
									<ul className="no-type-list kmp-maal-list">
										{this.state.selected &&
											this.state.selected.map((grp) => {
												return (
													<li className="ms-Grid-row">
														<div className="ms-Grid-col ms-sm1">
															<IconButton
																className="vertical-align-middle green"
																iconProps={{ iconName: 'BoxSubtractSolid' }}
																title="Fjern kompetansemål"
																onClick={() => {
																	let tmp = [];

																	this.state.selected.forEach((element) => {
																		if (grp.KMID != element.KMID) {
																			tmp.push(element);
																		}
																	});
																	this.setState({ selected: tmp });
																}}
															/>
														</div>
														<div className="ms-Grid-col ms-sm11 pt-5">
															<span>
																{grp.KMID} - {grp.LPID}: {grp.GrepTekst}
															</span>
														</div>
													</li>
												);
											})}
									</ul>
								</div>
							</div>
						</div>
					</div>
					{
						this.props.showskolon &&
						<div className="ms-Grid-row">
							<div className="z-form-group">
								<div className="ms-Grid-col ms-sm12 ms-md4">
									<label className="z-form-label ms-Label"><img width='40px' src={require('../../images/SkolonIcon.svg')} /></label>
								</div>

								<div className="ms-Grid-col ms-sm12 ms-md8">

									<div className="ms-Grid-col ms-sm12">
										{this.state.selected && (
											<SkolonPicker onClose={(pick) => {
												console.log('picks', pick);
												let tmp = JSON.parse(JSON.stringify(this.state.skolonpicks));
												var index = tmp.findIndex(x => x.id == pick.id);
												if (index < 0) {
													tmp.push(pick);
													this.setState({ skolonpicks: tmp });
												}
											}} />
										)}
										<ul className="no-type-list kmp-maal-list">
											{this.state.skolonpicks &&
												this.state.skolonpicks.map((grp) => {
													return (
														<li className="ms-Grid-row">
															<div className="ms-Grid-col ms-sm1">
																<IconButton
																	className="vertical-align-middle green"
																	iconProps={{ iconName: 'BoxSubtractSolid' }}
																	title="Fjern kompetansemål"
																	onClick={() => {
																		let tmp = [];

																		this.state.skolonpicks.forEach((element) => {
																			if (grp.id != element.id) {
																				tmp.push(element);
																			}
																		});
																		this.setState({ skolonpicks: tmp });
																	}}
																/>
															</div>
															<div className="ms-Grid-col ms-sm11 pt-5">
																<span>

																	{grp.name}
																</span>
															</div>
														</li>
													);
												})}
										</ul>
									</div>
								</div>
							</div>
						</div>
					}

					<Field
						name="Goal"
						label="Egendefinerte mål" // Custom fields variables can be fetched in component (i.e. field.label)
						component={this.renderRichTextField}
					/>

					<div className="ms-Grid-row">
						<div className="z-form-group">
							<div className="ms-Grid-col ms-sm12 ms-md4">
								<Label className="z-form-label">Type vurdering</Label>
							</div>
							<div className="ms-Grid-col ms-sm12 ms-md8">
								<Label> Velg en eller flere: </Label>
								<Field
									name="CheckInnlevering"
									label="Innlevering" // Custom fields variables can be fetched in component (i.e. field.label)
									component={this.renderCheckField}
								/>
								<Field
									name="CheckPresentasjon"
									label="Presentasjon" // Custom fields variables can be fetched in component (i.e. field.label)
									component={this.renderCheckField}
								/>
								<Field
									name="CheckProve"
									label="Prøve" // Custom fields variables can be fetched in component (i.e. field.label)
									component={this.renderCheckField}
								/>
								<Field
									name="CheckTest"
									label="Test" // Custom fields variables can be fetched in component (i.e. field.label)
									component={this.renderCheckField}
								/>
								<Field
									name="CheckLeksesjekk"
									label="Leksesjekk" // Custom fields variables can be fetched in component (i.e. field.label)
									component={this.renderCheckField}
								/>
							</div>

							<Field
								name="EvaluationTextOther"
								label="Annen vurdering" // Custom fields variables can be fetched in component (i.e. field.label)
								component={this.renderTextField}
							/>
							<Field
								name="TextOnPrem"
								label="Arbeid på skolen" // Custom fields variables can be fetched in component (i.e. field.label)
								component={this.renderRichTextField}
							/>
							<Field
								name="Text"
								label="Arbeid hjemme" // Custom fields variables can be fetched in component (i.e. field.label)
								component={this.renderRichTextField}
							/>
							<Field
								name="Link"
								label="Lenke" // Custom fields variables can be fetched in component (i.e. field.label)
								component={this.renderTextField}
							/>
							<Field
								name="DateFrom"
								label="Vises fra" // Custom fields variables can be fetched in component (i.e. field.label)
								required={true}
								component={this.renderDateTimeField}
							/>
							<Field
								name="Date"
								label="Frist" // Custom fields variables can be fetched in component (i.e. field.label)
								required={true}
								component={this.renderDateTimeField}
							/>
							{/* <Field
								name="HideDeadline"
								label="Skjul frist i ukeplanen"
								component={CheckboxField}
							/> */}

							<Field
								name="HideDeadline"
								label="Skjul frist i ukeplanen"
								component={CheckboxField}
							/>


							<Field
								name="TaskFiles"
								label="Filer" // Custom fields variables can be fetched in component (i.e. field.label)
								component={this.renderFilesField}
							/>
						</div>
					</div>

					<div className="ms-Grid-row">
						<div className="z-form-group">
							<div className="ms-Grid">
								<div className="ms-Grid-col ms-sm12 ms-md4">
									<label className="z-form-label"></label>
								</div>
								<div className="ms-Grid-col ms-sm12 ms-md8">
									<OneDrivePicker
										onClose={(selected) => {
											let tmp = this.state.selectedfiles;
											tmp.push(selected);
											this.setState({ selectedfiles: tmp });
										}}
									/>
									<ul className="no-type-list">
										{this.state.selectedfiles &&
											this.state.selectedfiles.map((file) => {
												return (
													<li className="taskFileItem" id="@t.OriginalDocumentID">
														<Icon
															style={{ verticalAlign: 'textBottom' }}
															iconName="Attach"
														></Icon>
														{file.FileName}
														<IconButton
															className="vertical-align-middle green"
															title="Fjern dokument"
															iconProps={{ iconName: 'BoxSubtractSolid' }}
															onClick={() => {
																let tmp = this.state.selectedfiles;
																for (var i = 0; i < tmp.length; i++) {
																	if (
																		tmp[i].OriginalDocumentID ===
																		file.OriginalDocumentID
																	) {
																		tmp.splice(i, 1);
																	}
																}
																this.setState({ selectedfiles: tmp });
															}}
														/>
													</li>
												);
											})}
									</ul>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className="form-footer">
					<div className="form-footer-left">
						{this.props.initialValues.Id != '0' && this.props.mode !== 'c' && (
							<span>
								<ActionButton iconProps={{ iconName: 'Delete' }} onClick={this.onDeleteClick.bind(this)}>
									Slett
								</ActionButton>
								<ActionButton
									text="Kopier til ny gruppe"
									iconProps={{ iconName: 'Copy' }}
									onClick={() => {
										this.props.getAssignmentGroup({ CourseID: this.props.initialValues.CourseID });
										this.props.change("GroupID", this.props.initialValues.GroupID);
										this.props.change("CourseID", this.props.initialValues.CourseID);

										this.focusTopRef();
										this.props.history.push(`/ukeplan/${this.props.formValues.Id}/c`);
									}}
								/>
							</span>
						)}
					</div>

					<div className="form-footer-right">
						{/* <DefaultButton
              onClick={() => {
                this.props.history.push("/ukeplan");
              }}
            >
              Avbryt
            </DefaultButton> */}
						<DefaultButton
							style={{ marginRight: 5 }}
							onClick={() => {
								this.props.getAssignmentGroup({ CourseID: this.props.initialValues.CourseID });
								this.props.change("GroupID", this.props.initialValues.GroupID);
								this.props.change("CourseID", this.props.initialValues.CourseID);

								this.props.history.goBack()
								this.focusTopRef();
							}}
						>Avbryt</DefaultButton>
						<PrimaryButton
							disabled={!this.formIsValid()}
							onClick={() => {
								if (!this.state.saving && this.props.mode == "c" && isSameGroupAsBefore({ courseId: this.props.formValues.CourseID, groupId: this.props.formValues.GroupID }, { courseId: this.props.initialValues.CourseID, groupId: this.props.initialValues.GroupID })) {
									this.setState({ showCopyPlanDialog: true });
								}
								else {
									if (!this.state.saving && Object.keys(validate(this.props.formValues)).length == 0) {
										this.setState({
											saving: true
										}, handleSubmit(this.onFormSubmit.bind(this)));
									}
								}
							}
							}
						>
							{!this.state.saving ? "Del ut" :
								<Spinner
									size={SpinnerSize.small}
								></Spinner>}
						</PrimaryButton>
					</div>
				</div>

				<Dialog
					hidden={!this.state.showCopyPlanDialog}
					onDismiss={() => {
						this.setState({ saving: false, showCopyPlanDialog: false })
					}}
					dialogContentProps={{ title: "Obs!", styles: { subText: { fontSize: "15px" } }, subText: "Du er ferd med å opprette et nytt planelement uten å endre gruppe. Er du sikker på at du vil lagre?", type: DialogType.normal }}
				>
					<DialogFooter>
						<PrimaryButton onClick={() => {
							this.setState({ showCopyPlanDialog: false, saving: true });
							handleSubmit(this.onFormSubmit.bind(this));
							this.onFormSubmit(this.props.formValues);
						}}
							text="Lagre" />
						<DefaultButton onClick={() => {
							this.setState({ saving: false, showCopyPlanDialog: false })
						}} text="Avbryt" />
					</DialogFooter>
				</Dialog>
			</form>
		);
	}
}

function isStartDateBeforeEndDate(start, end): boolean {
	return moment(start).format(TIME_FORMAT) < moment(end).format(TIME_FORMAT);
}

function validate(values: PlanElement) {
	const errors: any = {};

	// Fag er påkrevd
	if (!values.CourseID) {
		errors.CourseID = 'Dette feltet er påkrevd.';
	}

	// Tilretteleggingsgruppe er påkrevd
	if (values.CourseID && values.GroupID == null) {
		errors.GroupID = 'Dette feltet er påkrevd.';
	}

	//  Tema er påkrevd
	if (!values.Theme) {
		errors.Theme = 'Dette feltet er påkrevd.';
	}

	// Vises fra er påkrevd
	if (!values.DateFrom) {
		errors.DateFrom = 'Dette feltet er påkrevd.';
	}

	// Frist er påkrevd
	if (!values.Date) {
		errors.Date = 'Dette feltet er påkrevd.';
	}

	// Hvis frist er satt til tidligere enn i dag
	if (isDateStringBeforeToday(values.Date)) {
		errors.Date = 'Fristen kan ikke være tidligere enn i dag.';
	}

	// Hvis begge datoene er satt, men fristen er før visningsdato
	if (values.Date && values.DateFrom && dateStringToMoment(values.Date) < dateStringToMoment(values.DateFrom)) {
		errors.Date = 'Fristen kan ikke være tidligere enn visningsdato.';
	}

	return errors;
}

const mapStateToProps = (state, ownProps) => {
	console.log('planformstate', state);
	let g = [];
	if (state.ukeplanelements.ag) {
		g = state.ukeplanelements.ag;
	} else if (state.form.PlanElementForm.initial.Course) {
		g = state.form.PlanElementForm.initial.Course.Groups;
	}
	let tmp = [];
	if (state.form.PlanElementForm.initial.TaskDocument) {
		state.form.PlanElementForm.initial.TaskDocument.split(';').forEach((element) => {
			if (element.indexOf('|') > 0) {
				tmp.push({
					OriginalDocumentID: element.split('|')[0],
					FileName: element.split('|')[1]
				});
			}
		});
	}
	let gr = [];
	if (state.form.PlanElementForm.initial.Grep) {
		state.form.PlanElementForm.initial.Grep.split(';').forEach((element) => {
			if (element.indexOf('|') > 0) {
				gr.push({
					KMID: element.split('|')[0],
					LPID: element.split('|')[1],
					GrepTekst: element.split('|')[2]
				});
			}
		});
	}

	const formValues = getFormValues('PlanElementForm')(state);

	return {
		courses: state.teacher.subscribedCourses,
		planElements: state.ukeplanelements.planelements,
		groups: g,
		selectedfiles: tmp,
		selected: gr,
		formValues,
		showskolon: state.teacher.user.skolon,
		skolonpicks:state.form.PlanElementForm.initial.SkolonResources ? state.form.PlanElementForm.initial.SkolonResources:[]
	};
};

export default reduxForm({
	form: 'PlanElementForm',
	validate,
	enableReinitialize: true,
	onSubmitFail: () => {
		document.getElementById('content-view').scrollIntoView();
	}
})(
	connect(mapStateToProps, {
		updatePlanElement,
		deletePlanElement,
		getAssignmentGroup
	})(withRouter(PlanElementForm))
);
