import * as React from "react";
import { connect } from "react-redux";
import HeaderView from "../view_containers/HeaderView";
import CourseContainer from "./CourseContainer";
import {
  ActionButton,
  PrimaryButton,
  DefaultButton,
  Icon,
  Label,
  textAreaProperties,
  IChoiceGroupOption,
  ChoiceGroup,
  Modal,
  TeachingBubble,
  IStyleFunctionOrObject,
  ITeachingBubbleStyles,
  ITeachingBubbleStyleProps,
  Spinner,
  SpinnerSize
} from "office-ui-fabric-react";
import {
  Dropdown,
  DropdownMenuItemType,
  IDropdownOption,
} from "office-ui-fabric-react/lib/Dropdown";
import { getTeacherState } from "../../actions/Action_Teacher";
import {
  getStudentTaskElement,
  plagcheckFile,
  addDeliveryFileTeacher,
  submitTask,
  checkStatusTaskTeacher,
  initStatusTask,
  getTaskElement,
  deleteStudentTask,
  removeDeliveryFileTeacher
} from "../../actions/Action_Innleveringer";
import EvalForm from "./EvalForm";
import DeadlineForm from "./DeadlineForm";
import Select from "react-select";
import "../../../../src/app/formelements.scss";
import PlagStatus from "./PlagStatus";
import EvalFormRebuild from "./EvalFormRebuild";
import ReactDOM = require("react-dom");
import SubmitForStudent from "./SubmitForStudent";
import "../QuillStyles.scss";
import 'react-quill/dist/quill.core.css';
import 'react-quill/dist/quill.bubble.css';
import 'react-quill/dist/quill.snow.css'
import axios from "axios";
import { ROOT_URL, SP_HOST_URL } from "../../actions/constants";
import ReactTooltip from 'react-tooltip';
import moment = require("moment");
import EvaluationFiles from "./EvaluationFiles";
import { ITaskEvaluationFileInfo } from "../../models/interfaces/ITaskEvaluationFile";
export interface IStudentTaskViewProps {
  events: any;
  schoolClasses: any;
  teacherstate: any;
  tasks: any;
  getStudentTaskElement(teacherState: any);
  plagcheckFile(data: any);
  getCurrentTaskElements(teacherstate: any);
  getTaskElement(teacherState: any);
  deleteStudentTask(id: any);
  removeDeliveryFileTeacher(file: any);
  studentWsTasks: any;
  studenttask: any;
  displayTasks: any;
  history: any;
  match: any;
  activefileurl: any;
  activefile: any;
  location: any;
}
export interface IStudentTaskViewState {
  showevaledit: boolean;
  showevaltext: boolean;
  studenttask: any;
  showeval: boolean;
  confirmDelete: boolean;
  showdeadline: boolean;
  activefile: any;
  loading: boolean;
  activefileurl: any;
  studentArray: any[];
  delivering: boolean;
  switching:boolean;
  fileBeingDeleted?: number;

  // Used for EvaluationFiles component because React doesnt work
  isLoadingFiles: boolean;
	filesToUpload: any[];
	evaluationFiles: ITaskEvaluationFileInfo[];
	filesLoading: string[];
  errorFetchingFiles: boolean;
}
const evalOptions: IChoiceGroupOption[] = [
  { key: 'A', text: 'Evaluering', },
  { key: 'B', text: 'Ny frist', styles: { field: { marginLeft: "15px" } } }
]


class StudentTaskView extends React.Component<
  IStudentTaskViewProps,
  IStudentTaskViewState
> {
  topRef = React.createRef<HTMLDivElement>();
  constructor(props) {
    super(props);
    this.state = {
      showevaledit: false,
      showevaltext: true,
      studenttask: {},
      showdeadline: false,
      confirmDelete: false,
      showeval: true,
      activefile: this.props.activefile,
      loading: true,
      switching: true,
      activefileurl: "",
      studentArray: [],
      delivering: false,
      fileBeingDeleted: null,

      // EVALUATION FILES
      isLoadingFiles: false,
      filesToUpload: [],
      evaluationFiles: [],
      filesLoading: [],
      errorFetchingFiles: false
    };
  }
  componentDidMount() {
    console.log('008mount');
    const { id } = this.props.match.params;
    this.props
      .getStudentTaskElement(id)
      .then((ret) => {
        let thetask = ret.payload.data;
        console.log("done", thetask);
        thetask.DeliveryFiles.forEach((element) => {
          let veropt = [];
          element.VersionFiles.forEach((element) => {
            veropt.push({ value: element.WopiUrl, label: element.Name });
          });
          element.opts = veropt;
        });
        let afurl = "";
        let af = undefined;
        if (thetask.DeliveryFiles[0]) {
          afurl = thetask.DeliveryFiles[0].WopiUrl;
          af = thetask.DeliveryFiles[0];
        }
        console.log("afurl", afurl);
        console.log("af", af);
        this.setState({
          loading: false,
          switching:false,
          activefile: af,
          activefileurl: afurl,
          studenttask: thetask,
        });
      })
      .then(() => {
        console.log(this.props);
        let wsArray;
        let studentArray = [];
        for (let i = 0; i < this.props.studentWsTasks.length; i++) {
          if (
            this.props.studentWsTasks[i].Id == this.state.studenttask.WsTaskID
          ) {
            wsArray = this.props.studentWsTasks[i].StudentTasks;
          }
        }
        // console.log(wsArray);
        if (wsArray) {
          wsArray.forEach((elem) => {
            studentArray.push({ value: elem.Id, label: elem.StudentName });
          });
          console.log(studentArray);
          this.setState({
            studentArray: studentArray.sort((a, b) => {
              return a.label > b.label ? 1 : b.label > a.label ? -1 : 0;
            }),
          });
        } else {
          this.props
            .getTaskElement(this.props.studenttask.WsTaskID)
            .then(() => {
              wsArray = this.props.displayTasks.StudentTasks;
              console.log(wsArray);
              for (let i = 0; i < wsArray.length; i++) {
                studentArray.push({
                  value: wsArray[i].Id,
                  label: wsArray[i].StudentName,
                });
              }
              this.setState({
                studentArray: studentArray.sort((a, b) => {
                  return a.label > b.label ? 1 : b.label > a.label ? -1 : 0;
                }),
              });
            });
        }
      });
  }

  componentDidUpdate(prevProps) {
   
    const locationChanged = this.props.location !== prevProps.location;
    if (locationChanged) {
      console.log('008update');
      this.setState({switching:true});
      const { id } = this.props.match.params;
      this.props.getStudentTaskElement(id).then((ret) => {
        console.log('008updatedone');
        let thetask = ret.payload.data;
        console.log("done", thetask);
        thetask.DeliveryFiles.forEach((element) => {
          let veropt = [];
          element.VersionFiles.forEach((element) => {
            veropt.push({ value: element.WopiUrl, label: element.Name });
          });
          element.opts = veropt;
        });
        let afurl = "";
        let af = undefined;
        if (thetask.DeliveryFiles[0]) {
          afurl = thetask.DeliveryFiles[0].WopiUrl;
          af = thetask.DeliveryFiles[0];
        }
        console.log("afurl", afurl);
        console.log("af", af);
        this.setState({
          loading: false,
          switching:false,
          activefile: af,
          activefileurl: afurl,
          studenttask: thetask,
          showevaledit: false,
          showevaltext: true,
        });
      });
    }
  }


  /// EVALUATION FILES ///
  fetchEvaluationFiles = async (task) => {
    try {
        this.setState({isLoadingFiles: true, errorFetchingFiles: false});
        const res = await axios.get(`${ROOT_URL}/GetTaskEvaluationFiles${SP_HOST_URL}&taskId=${task.WsTaskID}&studentLoginName=${encodeURIComponent(task.LoginName)}`, {
            headers: {
                Authorization: localStorage.getItem('id_token')
            }
        })
        this.setState({evaluationFiles: res.data as ITaskEvaluationFileInfo[], isLoadingFiles: false});
    }
    catch (error) {
        this.setState({errorFetchingFiles: true, isLoadingFiles: false});
    }
}



  changeStudent = (selectedStudent) => {
    this.props.history.push("/innleveringer/student/" + selectedStudent.value);
  };

  wasDeliveredOnTime = () => {
    try {

      if (this.props.studenttask.DateDelivered) {
        if (moment(this.props.studenttask.Deadline).isAfter(moment(this.props.studenttask.DateDelivered))) {
          return true;
        }
        else {
          return false;
        }
      }
      return true;
    }
    catch (e) {
      return true;
    }
  }

  fileIsBeingDeleted = (id: number): boolean => {
    if (this.state.fileBeingDeleted && this.state.fileBeingDeleted === id) {
      return true;
    }
    return false;
  }

  focusTopRef = () => {
    document
      .querySelector("#root > div > div > div:nth-child(3) > div:nth-child(3)")
      .scrollTo(0, 0);
  };

  onClickDelete = async (id: number) => {
    await axios.post(`${ROOT_URL}/DeleteStudentTask/${id}/${SP_HOST_URL}`, null, {
      headers: {
        Authorization: localStorage.getItem("id_token"),
      },
    })

    history.back();
  }

  onEvalChange = (ev: React.FormEvent<HTMLInputElement>, option: IChoiceGroupOption) => {
    if (option.text === 'Evaluering') {
      console.log("1");
      this.setState({
        showeval: true,
        showdeadline: false
      });
    } else if (option.text === 'Ny frist') {
      console.log("2")
      this.setState({
        showeval: false,
        showdeadline: true
      });
    }

  }

  renderDeleteDeliverFileButton = (file: any, loading: boolean) => {
    return !loading ? <ActionButton
      iconProps={{ iconName: "Delete" }}
      style={{height: '75%'}}
      onClick={async () => {
        if (!this.state.fileBeingDeleted) {
          try {
            this.setState({fileBeingDeleted: file.Id})
            const resp = await this.props.removeDeliveryFileTeacher(file);
              
            const ret = await this.props.getStudentTaskElement(this.state.studenttask.Id);
            
            let studenttask = ret.payload.data;
            let afurl = "";
            let af = undefined;
            if (studenttask.DeliveryFiles[0]) {
              afurl = studenttask.DeliveryFiles[0].WopiUrl;
              af = studenttask.DeliveryFiles[0];
            }
            this.setState({
              studenttask: studenttask,
              activefile: af,
              activefileurl: afurl,
            });
          }
          catch (error) {
  
          }
          finally {
            this.setState({fileBeingDeleted: null})
          }
        }
      }}
    >
      Slett fil
    </ActionButton> :
    <Spinner style={{marginLeft: "1.5em"}}
      size={SpinnerSize.medium}
    ></Spinner>
  }

  showFileDeleteButton = (file: any): boolean => {
    if (file.LagtTilAvLarer && file.LagtTilAvLarer === true && this.state.studenttask.GradingStatus === 1) {
      return true;
    }
    return false;
  }

  renderAddedByTeacher = (file: any) => {
    const tooltipId: string = `addedByTeacherIcon-${file.Id}`;

    return <span style={{marginLeft: "0.5em"}}>
      <Icon data-tip data-for={tooltipId} iconName="UserOptional" className="fileAddedByTeacherIcon" />
      <ReactTooltip id={tooltipId}>
        Denne filen har blitt lagt til av lærer
      </ReactTooltip>
    </span>
  }

  render() {
    const { id } = this.props.match.params;
    // if (this.props.activefile && !this.state.activefile) {
    //   this.setState({ activefile: this.props.activefile });
    // }
    if (!this.state.loading) {
      return (
        <div className="ms-Grid">
          <div className="main">
            {this.state.studenttask && (
              <div>
                <div className="ms-Grid-row">
                  <div className="ms-Grid-col ms-lg5">
                    <div className="header">
                      <div className="ms-Grid-row">
                        <div className="ms-Grid-col">
                          <h1 className="ms-fontColor-orangeLight">
                            {" "}
                            <i className="ms-Icon ms-Icon--CompletedSolid"></i>{" "}
                            Innlevering
                          </h1>
                          <h2 className="ms-fontColor-orangeLight zSubTitle ms-font-l">
                            <b>Oppgave:</b> {this.state.studenttask.WsTask.Name}
                          </h2>
                          <h2 className="ms-fontColor-orangeLight zSubTitle ms-font-l">
                            <b>Frist:</b>{" "}
                            {this.state.studenttask.WsTask.Deadlinetekst}
                          </h2>

                          <h2 className="ms-fontColor-orangeLight zSubTitle ms-font-l">
                            <b>Tilretteleggingsgruppe:</b>{" "}
                            {this.state.studenttask.WsTask.GroupName}
                          </h2>
                          <h2 className="ms-fontColor-orangeLight zSubTitle ms-font-l">
                            <b>FagID:</b>{" "}
                            {this.state.studenttask.WsTask.CourseID}
                          </h2>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="ms-Grid-col ms-lg7">
                    <div className="ms-Grid-col ms-sm12 ms-xxl6 text-left ms-fontColor-black studentTaskTopCol">
                      {this.props.studenttask.WsTask.Text &&
                        this.props.studenttask.WsTask.Text.length > 0 && (
                          <h4 className=" ms-fontColor-black">Oppgavetekst</h4>

                        )}

                      <p>
                        <span
                          className="remove-para-margin"
                          dangerouslySetInnerHTML={{
                            __html: this.props.studenttask.WsTask.Text,
                          }}
                        ></span>
                      </p>
                      <p>
                        <ul>
                          {this.props.studenttask.WsTask.TaskFiles &&
                            this.props.studenttask.WsTask.TaskFiles.map(
                              (taskF) => {
                                return (
                                  <li>
                                    <a href={taskF.FileUrl} target="_blank">
                                      {taskF.FileName}
                                    </a>
                                  </li>
                                );
                              }
                            )}
                        </ul>
                      </p>
                    </div>
                    {this.props.studenttask.WsTask.GrepCodeList.length > 0 && (
                      <div className="ms-Grid-col ms-sm12 ms-xxl6 ms-fontColor-black studentTaskTopCol">
                        <h4 className=" ms-fontColor-black">Kompetansemål</h4>
                        <p>
                          <ul className="grep-list">
                            {this.props.studenttask.WsTask.GrepCodeList.map(
                              (grp) => {
                                return (
                                  <li>
                                    <a href={grp.DetailUrl} target="_blank">
                                      {grp.GrepTekst}
                                    </a>
                                  </li>
                                );
                              }
                            )}
                          </ul>
                        </p>
                      </div>
                    )}
                  </div>
                </div>
                <div className="ms-Grid-row">
                  <div className="ms-Grid-col ms-sm12">
                    <div className="ms-Grid-row ms-bgColor-neutralLight topTool">
                      {this.state.studentArray.length > 0 && (
                        <span style={{ textAlign: 'left', paddingBottom: '100px' }}>
                          <Label>
                            <b>Hopp til annen student:</b>
                          </Label>
                          <Select
                            onChange={(selected) => {
                              this.changeStudent(selected);
                            }}
                            placeholder={'Velg Student'}
                            options={this.state.studentArray}
                          />
                        </span>
                      )}
                      <hr />
                      {!this.wasDeliveredOnTime() && <div className="warning-container" >
                        <div className="warning-item"><Icon iconName="Clock" style={{ fontSize: 16, marginRight: 3, color: "red" }} /> Denne besvarelsen ble levert etter fristen, {moment(this.props.studenttask.DateDelivered).format("HH:mm DD.MM.yyyy")}</div>
                      </div>}
                      <div className="ms-Grid-col ms-sm12 ms-md6 ms-lg7 text-left mb-10">
                        <h2>Du vurderer nå: {this.props.studenttask.StudentName}</h2>
                        <ChoiceGroup
                          defaultSelectedKey="A"
                          options={evalOptions}
                          onChange={this.onEvalChange}
                          styles={{ flexContainer: { display: "flex" } }}
                        />
                      </div>
                      <div className="ms-Grid-col ms-sm12 ms-md6 ms-lg5 text-right topToolRight">
                        <span className="ms-font-l ms-fontWeight-semibold">
                          Status: {this.state.studenttask.DeliveryStatusText}
                        </span>
                      </div>
                      <div className="ms-Grid-row">
                        {this.state.showeval && (
                          <div>
                            {this.state.showevaltext &&
                              this.state.studenttask.DeliveryStatus != "0" &&
                              this.state.studenttask.DeliveryStatus != "4" &&
                              this.state.studenttask && (
                                <div id="gradedBlock">
                                  <div className="ms-Grid-col ms-sm12 ms-lg7 text-left">
                                    <hr />
                                    <div className="ms-Grid-col ms-sm6">
                                      <Label>
                                        <b>Vurdering</b>
                                      </Label>
                                      <span className="remove-para-margin ql-editor" style={{ backgroundColor: "transparent" }}
                                        dangerouslySetInnerHTML={{
                                          __html: this.state.studenttask
                                            .GradingCommentDisplay,
                                        }}
                                      ></span>
                                    </div>
                                    <div className="ms-Grid-col ms-sm6">
                                      <Label>
                                        <b>Karakter</b>
                                      </Label>
                                      {this.state.studenttask.Grade}{" "}
                                      {this.state.studenttask.GradeModifier}
                                    </div>
                                    <hr />
                                    <div className="ms-Grid-row">
                                      <div className="ms-Grid-col ms-sm12">
                                        <div className="uip-row">
                                          <Label>
                                            <b>Grad av måloppnåelse</b>
                                          </Label>
                                          <div className="table-responsive">
                                            <table className="table table-bordered">
                                              <thead>
                                                <tr>
                                                  <th className="iup-table-header-first">
                                                    Kompetansemål
                                                  </th>
                                                  <th className="iup-table-header-second">
                                                    Måloppnåelse
                                                  </th>
                                                </tr>
                                              </thead>
                                              <tbody>
                                                {this.state.studenttask
                                                  .Uipkms &&
                                                  this.state.studenttask.Uipkms.map(
                                                    (uip) => {
                                                      return (
                                                        <tr className="kmrow uipitem">
                                                          <td
                                                            data-lpid="@uip.Lpid"
                                                            data-kmid="@uip.Kmid"
                                                            className="kmtext"
                                                          >
                                                            {uip.Kmtext}
                                                          </td>
                                                          <td>
                                                            <div className="ms-ChoiceFieldGroup">
                                                              {uip.Eval ==
                                                                "1" && (
                                                                  <div className="ms-ChoiceField">
                                                                    <i
                                                                      className="ms-Icon ms-Icon--TestBeaker"
                                                                      aria-hidden="true"
                                                                    ></i>{" "}
                                                                    Lav
                                                                  </div>
                                                                )}
                                                              {uip.Eval ==
                                                                "2" && (
                                                                  <div className="ms-ChoiceField">
                                                                    <i
                                                                      className="ms-Icon ms-Icon--TestBeakerSolid"
                                                                      aria-hidden="true"
                                                                    ></i>{" "}
                                                                    Middels
                                                                  </div>
                                                                )}
                                                              {uip.Eval ==
                                                                "3" && (
                                                                  <div className="ms-ChoiceField">
                                                                    <i
                                                                      className="ms-Icon ms-Icon--TestCase"
                                                                      aria-hidden="true"
                                                                    ></i>{" "}
                                                                    Høy
                                                                  </div>
                                                                )}
                                                            </div>
                                                          </td>
                                                        </tr>
                                                      );
                                                    }
                                                  )}
                                              </tbody>
                                            </table>
                                          </div>
                                        </div>
                                        <EvaluationFiles
                                          Task={this.props.studenttask}
                                          CanBeEdited={false}
                                          isLoadingFiles={this.state.isLoadingFiles}
                                          filesToUpload={this.state.filesToUpload}
                                          evaluationFiles={this.state.evaluationFiles}
                                          filesLoading={this.state.filesLoading}
                                          errorFetchingFiles={this.state.errorFetchingFiles}
                                          fetchEvaluationFiles={this.fetchEvaluationFiles}
                                        />
                                      </div>
                                    </div>
                                    
                                  </div>
                                  <div className="ms-Grid-col ms-sm12 ms-lg5 topToolRight">
                                    <div className="ms-Grid-row">
                                      <div className="ms-Grid-col">
                                        {
                                          this.state.studenttask.DeliveryStatus != "1" &&
                                          <ActionButton
                                            text="Endre vurdering"
                                            iconProps={{ iconName: "Edit" }}
                                            onClick={() => {
                                              this.setState({
                                                showevaltext: false,
                                                showevaledit: true,
                                              });
                                            }}
                                          ></ActionButton>
                                        }
                                        <ActionButton
                                          text="Tilbake til innleveringsoversikt"
                                          iconProps={{ iconName: "Back" }}
                                          onClick={() => {
                                            this.props.history.push(
                                              "/innleveringer/" +
                                              this.state.studenttask.WsTaskID
                                            );
                                          }}
                                        /><br />
                                        <DefaultButton
                                          id="slettKnapp-innlevering"
                                          text="Slett innlevering for elev"
                                          iconProps={{ iconName: "Delete" }}
                                          onClick={() => {
                                            this.setState({
                                              confirmDelete: true
                                            })
                                          }}
                                        />
                                        {this.state.confirmDelete && (
                                          <TeachingBubble
                                            target="#slettKnapp-innlevering"
                                            primaryButtonProps={{ text: "Ja", onClick: () => { this.onClickDelete(id) } }}
                                            secondaryButtonProps={{ style: { color: "black !important" }, text: "Nei", onClick: () => { this.setState({ confirmDelete: false }) } }}
                                            styles={teachBubstyles}
                                          >
                                            Er du sikker på at du vil slette innleveringen?
                                          </TeachingBubble>
                                        )}
                                        {this.state.studentArray.length > 0 && (
                                          <span
                                            style={{ textAlign: "left", paddingBottom: "100px" }}
                                          >
                                            <Label>
                                              <b>Velg neste besvarelse du ønsker å vurdere</b>
                                            </Label>
                                            <Select
                                              onChange={(selected) => {
                                                this.changeStudent(selected);
                                              }}
                                              placeholder={"Velg elev"}
                                              options={this.state.studentArray}
                                            />
                                          </span>
                                        )}
                                        {/* {this.state.studentArray.length > 0 && 
                                      (
                                        <span style={{textAlign: 'left'}}> 
                                          <Label ><b>Hopp til annen student:</b></Label>
                                          <Select 
                                           onChange={(selected) => {
                                             this.changeStudent(selected);
                                           }}
                                          placeholder={"Velg Student"}
                                          options={this.state.studentArray}
                                          />
                                        </span>
                                      )
                                      } */}
                                      </div>
                                    </div>
                                    <div className="ms-Grid-row" style={{ paddingTop: 20 }}>
                                      <div className="ms-Grid-col">
                                        <Label><b>Lever for eleven</b></Label>
                                        <SubmitForStudent
                                          studenttask={this.state.studenttask}
                                          deliveryfinished={() => {
                                            console.log("finished");
                                            let task = this.state.studenttask;
                                            task.DeliveryStatus = 0;
                                            task.DeliveryStatusText = "Levert";
                                            this.setState({
                                              studenttask: task,
                                            });
                                          }}
                                          history={this.props.history}
                                          addfinished={(ret) => {
                                            console.log("returnvalue", ret);
                                            let tmp = JSON.parse(
                                              JSON.stringify(this.state.studenttask)
                                            );
                                            tmp.DeliveryFiles.push(ret.payload.data);
                                            this.setState({
                                              studenttask: tmp,
                                              activefile: tmp.DeliveryFiles[0],
                                            });
                                          }}
                                        ></SubmitForStudent>
                                      </div>
                                    </div>

                                  </div>
                                </div>
                              )}
                            {(this.state.showevaledit ||
                              this.state.studenttask.DeliveryStatus == "0" ||
                              this.state.studenttask.DeliveryStatus == "4") && (
                                <div id="gradedBlock">
                                  <div className="ms-Grid-col ms-sm12 ms-lg7 text-left">

                                    {/* <EvalFormRebuild
                                    form={
                                      "taskformsingle-" +
                                      this.state.studenttask.Id
                                    }
                                    initialValues={this.state.studenttask}
                                    singleview={true}
                                    history={this.props.history}
                                    studentArray={this.state.studentArray}
                                    focusTopRef={this.focusTopRef}
                                  ></EvalFormRebuild> */}
                                  {
                                    !this.state.switching &&
                                    <EvalFormRebuild
                                    form={
                                      "taskformsingleu-" +
                                      this.state.studenttask.Id
                                    }
                                    initialValues={this.state.studenttask}
                                    singleview={true}
                                    history={this.props.history}
                                    studentArray={this.state.studentArray}
                                    focusTopRef={this.focusTopRef}
                                  ></EvalFormRebuild>
                                  }
                                  

                                    
                                    {/* <EvalForm
                                form={"taskformsingle-" + this.state.studenttask.Id}
                                  fromprops={this.state.studenttask}
                                  singleview={true}
                                ></EvalForm> */}
                                  </div>
                                  <div className="ms-Grid-col ms-sm12 ms-lg5 topToolRight">
                                    {this.state.studenttask.GradingStatus !=
                                      1 && (
                                        <ActionButton
                                          onClick={() => {
                                            this.setState({
                                              showevaltext: true,
                                              showevaledit: false,
                                            });
                                          }}
                                          text="Avbryt"
                                          iconProps={{ iconName: "Cancel" }}
                                        />
                                      )}

                                    <ActionButton
                                      text="Tilbake til innleveringsoversikt"
                                      iconProps={{ iconName: "Back" }}
                                      onClick={() => {
                                        this.props.history.push(
                                          "/innleveringer/" +
                                          this.state.studenttask.WsTaskID
                                        );
                                      }}
                                    />
                                  </div>
                                </div>
                              )}
                          </div>
                        )}
                        {this.state.showdeadline && (
                          <DeadlineForm
                            fromprops={this.state.studenttask}
                          // onclose={()=>{
                          //   this.props.history.push(
                          //     "/innleveringer/" +
                          //       this.state.studenttask.WsTaskID
                          //   );
                          // }}
                          ></DeadlineForm>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="ms-Grid-row">
                <div
                    className="ms-Grid-col ms-sm12 ">
                    {this.state.activefile && (
                      <Label>
                        <b>Filer til innlevering</b>
                      </Label>
                    )}
                    {this.state.activefile &&
                      this.state.studenttask.DeliveryFiles.map((item) => {
                        return (
                          // <div className="ms-ChoiceField">
                          <div className="ms-ChoiceField" style={{height: 40, display: "flex", flexDirection: "row", alignItems: "center", textAlign: "center"}}>
                            <input
                              onChange={() => {
                                this.setState({ activefile: item });
                              }}
                              data-wopiurl="@item.WopiUrl"
                              data-delid="@item.Id"
                              id="radio10-@item.Id"
                              className="ms-ChoiceField-input"
                              type="radio"
                              name="radio10"
                              checked={item.Id == this.state.activefile.Id}
                            />

                            {item.LagtTilAvLarer && item.LagtTilAvLarer === true && this.renderAddedByTeacher(item)}

                            
                            <span className="ms-Label ml-5">
                              {item.FileNameDraft}
                            </span>
                            
                            <a
                              target="_blank"
                              href={item.FileUrlDelivery}
                              title="Åpne fil"
                            >
                              <i className="ms-Icon ms-Icon--OpenFile ml-10"></i>
                              Åpne fil
                            </a>
                            {this.showFileDeleteButton(item) && this.renderDeleteDeliverFileButton(item, this.fileIsBeingDeleted(item.Id))}
                          </div>
                        );
                      })}

                    {this.state.studenttask.LinksCollection &&
                      this.state.studenttask.LinksCollection.length > 0 && (
                        <div>
                          <Label style={{ fontWeight: "bold" }}>
                            Innleverte lenker
                          </Label>
                          <ul id="addedlinks">
                            {this.state.studenttask.LinksCollection.map(
                              (link) => {
                                return (
                                  <li className="addedlink">
                                    <a target="_blank" href={link}>
                                      {link}
                                    </a>
                                  </li>
                                );
                              }
                            )}
                          </ul>
                        </div>
                      )}

                    <div
                      className="ms-Grid-col ms-md12 ms-xxl4"
                      style={{ paddingRight: 0, paddingLeft: 0 }}
                    >
                      {this.state.activefile && (
                        <Label>
                          <b>Tidligere versjoner</b>
                        </Label>
                      )}
                      {this.state.activefile && (
                        <Select
                          placeholder={"Versjoner"}
                          onChange={(selected) => {
                            window.open(selected.value, "_blank");
                          }}
                          options={this.state.activefile.opts}
                        />
                      )}
                    </div>
                    <div
                      className="ms-Grid-col ms-sm12"
                      style={{ paddingRight: 0, paddingLeft: 0 }}
                    >
                      <ul>
                        {this.state.studenttask.Comments.length > 0 && (
                          <Label>
                            <b>Kommentarer fra lærer: </b>
                          </Label>
                        )}
                        {this.state.studenttask.Comments.map((item) => {
                          return (
                            <li>
                              <span>
                                {" "}
                                <b>{item.TeacherName}</b>
                              </span>{" "}
                              la inn kommentar den <b>{item.Datefriendly}</b>:
                              <div className="commentTxt">
                                <span
                                  dangerouslySetInnerHTML={{
                                    __html: item.CommentText,
                                  }}
                                ></span>
                              </div>
                            </li>
                          );
                        })}
                      </ul>
                    </div>
                  </div>
                  </div>
                <div className="ms-Grid-row">
                  <h2>Du vurderer nå : {this.props.studenttask.StudentName}</h2>
                  <div className="ms-Grid-col ms-sm12  text-left">
                    {this.state.activefile && (
                      <div>
                        {console.log(this.state.activefile.WopiUrl, "WopiURLR")}
                        <iframe
                          height="800px"
                          className="oppgavePreview"
                          id="wopiFrame"
                          src={this.state.activefile.WopiUrl}
                        ></iframe>
                        <div className="block text-right">
                          {this.state.activefile.UrkundState == "NULL" && (
                            <button
                              data-fileid="@item.Id"
                              className="ms-Button z-Button z-Button-verify plgBtn"
                              onClick={() => {
                                this.props
                                  .plagcheckFile(this.state.activefile)
                                  .then((response) => {
                                    response.payload.data.WopiUrl = this.state.activefile.WopiUrl;
                                    this.setState({
                                      activefile: response.payload.data,
                                    });
                                  });
                              }}
                            >
                              <span className="ms-Button-icon"></span>
                              <span className="ms-Button-label">
                                Send til plagiatkontroll
                              </span>
                              <span className="ms-Button-description"></span>
                            </button>
                          )}
                          {this.state.activefile.UrkundState != "NULL" && (
                            <div className="plgImg" data-plagblock="@item.Id">
                              {this.state.activefile.UrkundState ==
                                "Analyzed" && (
                                  <div>
                                    <PlagStatus
                                      reporturl={
                                        this.state.activefile.UrkundReportUrl
                                      }
                                      significance={
                                        this.state.activefile.UrkundSignUrl
                                      }
                                    ></PlagStatus>
                                  </div>
                                )}
                              {this.state.activefile.UrkundState ==
                                "Accepted" ||
                                (this.state.activefile.UrkundState ==
                                  "Submitted" && (
                                    <img
                                      src={require("../../images/processing.gif")}
                                    />
                                  ))}
                              {this.state.activefile.UrkundState !=
                                "Analyzed" && (
                                  <span>{this.state.activefile.UrkundState}</span>
                                )}
                            </div>
                          )}
                        </div>
                      </div>
                    )}
                  </div>

                  
                </div>
              </div>
            )}
          </div>
        </div>
      );
    }
    return <div>laster...</div>;
  }
}

function mapStateToProps(state) {
  let afurl = "";
  let af = undefined;
  if (state.tasks.studenttask) {
    state.tasks.studenttask.DeliveryFiles.forEach((element) => {
      let veropt = [];
      element.VersionFiles.forEach((element) => {
        veropt.push({ value: element.WopiUrl, label: element.Name });
      });
      element.opts = veropt;
    });
    if (state.tasks.studenttask.DeliveryFiles[0]) {
      afurl = state.tasks.studenttask.DeliveryFiles[0].WopiUrl;
      af = state.tasks.studenttask.DeliveryFiles[0];
    }
  }

  return {
    teacherstate: state.teacher,
    studenttask: state.tasks.studenttask,
    studentWsTasks: state.tasks.Tasks,
    displayTasks: state.tasks.task,
    //activefileurl: afurl,
    //activefile: af,
  };
}

export default connect(mapStateToProps, {
  getStudentTaskElement,
  plagcheckFile,
  addDeliveryFileTeacher,
  submitTask,
  checkStatusTaskTeacher,
  initStatusTask,
  getTaskElement,
  deleteStudentTask,
  removeDeliveryFileTeacher
})(StudentTaskView);
const teachBubstyles: IStyleFunctionOrObject<ITeachingBubbleStyleProps, ITeachingBubbleStyles> = {
  content: {
    backgroundColor: "rgb(54,59,89)",
    color: "rgb(131,136,164)"
  },
  footer: {
    justifyContent: "space-evenly",
    paddingLeft: "0px !important",
    paddingRight: "0px !important",
    textAlign: "center"
  },
  subText: {
    textAlign: "center",
    color: "rgb(231,232,237)",
    fontSize: "1.3rem"
  },
  primaryButton: {
    backgroundColor: "rgb(237,94,104)",
    color: "rgb(231,232,237) !important",
    border: "none",
    borderRadius: "5px",
    fontSize: "1.3rem"
  },
  secondaryButton: {
    backgroundColor: "rgb(21,122,237) !important",
    // backgroundColor: "rgb(231,232,237) !important",
    color: "rgb(54,59,89) !important",
    border: "none",
    borderRadius: "5px",
    fontSize: "1.3rem"
  }
}