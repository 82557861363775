import { SP_HOST_URL, ROOT_URL } from "./constants";

export const SET_FILTER_TASKS = "SET_FILTER_TASKS";
export const SET_SCHEDULE_CLASS = "SET_SCHEDULE_CLASS";
export const SET_SCHEDULE_WEEK = "SET_SCHEDULE_WEEK";
export const SET_SCHEDULE_YEAR = "SET_SCHEDULE_YEAR";
export const SET_PORT_FILTER_VALUES = "SET_PORT_FILTER_VALUES";
export const RESET_SELECTED_WEEK_AND_YEAR = "RESET_SELECTED_WEEK_AND_YEAR";
export const SET_SCHEDULE_CALENDAR_CONTEXT = "SET_SCHEDULE_CALENDAR_CONTEXT";

export interface ISetSelectedClassAction {
    type: string,
    payload: string
}

export interface ISetSelectedWeekAction {
    type: string,
    week: number
}

export interface ISetSelectedYearAction {
    type: string,
    year: number
}

export interface IResetSelectedWeekAndYearAction {
    type: string
}

export interface ISetScheduleCalendarContextAction {
    type: string,
    selectedclass: string,
    date: Date
}

export const setPortFilterValues = (portFilterValues: any) => ({
    type: SET_PORT_FILTER_VALUES,
    payload: portFilterValues,
});

export const setFilterTasks = (filtervalues: any) => ({
    type: SET_FILTER_TASKS,
    payload: filtervalues,
});

export const setSelectedClass = (classId: string): ISetSelectedClassAction => ({
    type: SET_SCHEDULE_CLASS,
    payload: classId,
});

export const setSelectedWeek = (week: number): ISetSelectedWeekAction =>({
    type: SET_SCHEDULE_WEEK,
    week
});

export const setSelectedYear = (year: number): ISetSelectedYearAction =>({
    type: SET_SCHEDULE_YEAR,
    year
});

export const resetSelectedWeekAndYear = (): IResetSelectedWeekAndYearAction =>({
    type: RESET_SELECTED_WEEK_AND_YEAR
});

export const setScheduleCalendarContext = (selectedclass: string, date: Date): ISetScheduleCalendarContextAction =>({
    type: SET_SCHEDULE_CALENDAR_CONTEXT,
    selectedclass,
    date
});