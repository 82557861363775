import * as React from 'react';
import { Providers, TeamsHelper, TeamsProvider, MsalProvider } from '@microsoft/mgt';
// import { Login, Agenda, Person} from '@microsoft/mgt-react';
import ErrorBoundary from '../shared/ErrorBoundary';
import DashboardView from '../dashboard/DashboardView';

export class SplashScreenView extends React.Component{


    render(){
        console.log('splashsplash');
        console.log('splash',localStorage.getItem('msal.idtoken'));
        // if(localStorage.getItem('msal.idtoken'))
        // {
        //     localStorage.setItem('adal.idtoken',localStorage.getItem('msal.idtoken'));
        //     return (
        //         <ErrorBoundary>
        //           <DashboardView />
        //         </ErrorBoundary>
        //       );
        // }
        return (
            <div className="splash-screen" onClick={()=>{
                console.log('trykk');
                Providers.globalProvider.login();
            }}>
                <div>
                    {/* <Login></Login> */}
                {/* <Login loginCompleted={()=> alert('login completed')}/>  */}
                {/* <Login loginCompleted={(e) => console.log('Logged in')} /> */}
                </div>
            </div>
        )
    }
}