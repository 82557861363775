import * as React from 'react';
import { connect } from 'react-redux';
import { startQuiz } from '../../actions/Action_Quiz';
import TakeQuizForm from './TakeQuizForm';
import HeaderView from "../view_containers/HeaderView";
import {MessageBar, MessageBarType, PrimaryButton} from 'office-ui-fabric-react';
export interface ITakeQuizViewProps {
    startQuiz(quiz: any);
    match: any;
    quizattempt: any;
    history: any;
}
export interface ITakeQuizViewState {
    takeQuizForm: {};
}
class TakeQuizView extends React.Component<ITakeQuizViewProps, ITakeQuizViewState> {
    componentWillMount() {
        const { id, mode } = this.props.match.params;
        this.props.startQuiz({ Id: id })
            .then(response => {
                this.setState({ takeQuizForm: response.payload.data });

            }).catch(error => {

            });
    }
    componentWillReceiveProps()
    {
       
    }
    public render() {
        return (
            <div className="ms-Grid">
                <div className="main">
                    <div className="ms-Grid-row">
                        <div className="ms-Grid-col ms-sm12">
                            <MessageBar
                                messageBarType={MessageBarType.warning}
                                className="mb-10"
                            >
                             Dette er en forhåndsvisning av prøven       
                            </MessageBar>
                            { this.props.quizattempt &&
                                <HeaderView
                                    title= {this.props.quizattempt.Quiz.Title}
                                    iconName="PageListSolid"
                                />
                            }
                        </div>
                    </div>
                    <div className="ms-Grid-rowquestionRow">
                        <div className="ms-Grid-row">
                            <ul className="no-type-list">
                            <link rel="stylesheet" href="//cdn.quilljs.com/1.2.6/quill.snow.css"></link>
                                {
                                    this.state &&
                                    <TakeQuizForm initialValues={this.state.takeQuizForm}></TakeQuizForm>
                                }

                            </ul>
                        </div>
                        <div className="ms-Grid-row">
                            <PrimaryButton 
                                text='Avslutt forhåndsvisning'
                                onClick={() => this.props.history.push("/quiz")}
                            />
                        </div>
                    </div>
                 </div>               
            </div>
        );
    }
}
function mapStateToProps(state, ownProps) {

   
    // if(this.state.Quiz)
    // {
    return {
        quizattempt: state.Quiz.takequiz
    };

}

export default connect(mapStateToProps, { startQuiz })(TakeQuizView);
