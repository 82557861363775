import * as React from "react";
import { getTeacherState } from "../../actions/Action_Teacher";
import * as _ from "lodash";
import { connect } from "react-redux";
import * as microsoftTeams from "@microsoft/teams-js";
import { Dropdown, Label, PrimaryButton } from "office-ui-fabric-react";
import { planUtils } from "../ukeplan/PlanElementEditView";
import { Providers } from "@microsoft/mgt";
import * as MicrosoftTeams from "@microsoft/teams-js";
export interface IAuthProps {
}

class Auth extends React.Component<IAuthProps> {

  componentDidMount() {
   // mgt.TeamsProvider.handleAuth();
   
  }

  public render() {
    return (
      <div>
    
      </div>
    );
  }
}
function mapStateToProps(state) {
  return {
  };
}

export default connect(mapStateToProps, {  })(Auth);
