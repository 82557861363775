import * as React from 'react';
import { withRouter, Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { Field, reduxForm, change } from 'redux-form';
import { Dropdown, TextField, autobind, ActionButton, DefaultButton } from 'office-ui-fabric-react';
import DatePicker from 'react-datepicker';
import * as moment from 'moment';
import ReactQuill from 'react-quill'; // Typescript
import { saveStudentDeadline } from '../../actions/Action_Innleveringer';
export const LOCALE = 'nb';
export const TIME_FORMAT = 'LT';
export const DATE_FORMAT = 'DD.MM.YYYY HH:mm';
export interface IDeadlineFormProps {
	studenttask: any;
	history: any;
	match: any;
	handleSubmit: any;
	fromprops: any;
	saveStudentDeadlinel(studenttask: any);
	// onsave();
}
export interface IDeadlineFormState {
	Deadline: any;
	Task: any;
	Returned: boolean;
}
let comment = '';
class DeadlineForm extends React.Component<IDeadlineFormProps, IDeadlineFormState> {
	constructor(props) {
		super(props);
		//comment = this.props.studenttask.GradingComment;
		this.state = { Task: this.props.fromprops, Deadline: this.props.fromprops.Deadline, Returned: false };
	}

	/****************************
            Event handlers
     ****************************/

	onFormSubmit(values) {
		const requestValues = this.formatFormValueDatesToDateString(values);
		saveStudentDeadline(
			{ Deadline: values.Deadline, Id: this.props.studenttask.Id, DeadlineComment: values.DeadlineComment },
			() => {
				this.setState({ Returned: true });
				this.props.history.push('/innleveringer/' + this.props.studenttask.WsTaskID);
			}
		);
	}

	handleChange(event) {}
	handleChangeQuill(html) {
		comment = html;
	}
	onDeleteClick() {
		const { id } = this.props.match.params;

		if (isNaN(id)) {
			return;
		}

		if (confirm('Vil du virkelig slette dette timeplanelementet?')) {
			// this.props.deletePlanElement(this.props.planElements[id], () => {
			//     this.props.history.push('/timeplan');
			// });
		}
	}

	/****************************   
            Helper methods
     ****************************/

	formatFormValueDatesToDateString(values) {
		return values;
	}
	renderRichTextField(field) {
		const {
			input,
			meta: { touched, error },
			children
		} = field;

		return (
			<ReactQuill
				value={input.value}
				onChange={(html) => {
					input.onChange(html);
				}}
			/>
		);
	}
	renderDateTimeField(field) {
		const {
			input,
			required,
			meta: { touched, error }
		} = field;
		const selectedDate = input.value ? moment(input.value, DATE_FORMAT) : null;

		return (
			<DatePicker
				{...input}
				selected={selectedDate}
				onChange={(date) => {
					input.onChange(date.locale(LOCALE).format(DATE_FORMAT));
				}}
				timeFormat="HH:mm"
				timeIntervals={5}
				minTime={moment().hours(8).minutes(0)} // ScheduleElements cannot start before 8:00AM
				maxTime={moment().hours(23).minutes(59)} // ScheduleElements cannot end after 16:00AM
				// filterDate={(date) => { return date.day() !== 0 && date.day() !== 6; }}   // Weekends are disabled
				locale="nb-NO"
				dateFormat={DATE_FORMAT}
				showTimeSelect
				timeCaption="Tid"
				errorMessage={error ? error : ''}
				required={required}
				showWeekNumbers
				autocomplete="off"
				placeholderText="Velg dato og klokkeslett"
				onBlur={() => {}}
			/>
		);
	}

	/****************************
            Render methods
     ****************************/

	render() {
		const { handleSubmit } = this.props;
		if (this.state.Returned) {
			return (
				<div id="deadlineBlock">
					<div className="ms-Grid-col ms-sm12 ms-lg7 text-left">Sendt tilbake med ny frist</div>
					<div className="ms-Grid-col ms-sm12 ms-lg5 topToolRight">
						<ActionButton
							onClick={() => {
								this.props.history.push('/innleveringer/' + this.state.Task.WsTaskID);
							}}
							text="Tilbake"
							iconProps={{ iconName: 'RevToggleKey' }}
						/>
					</div>
				</div>
			);
		}
		return (
			<form id="evaluering-form" onSubmit={handleSubmit(this.onFormSubmit.bind(this))}>
				<div id="deadlineBlock">
					<div className="ms-Grid-col ms-sm12 ms-lg7 text-left">
						<div className="ms-Grid-row">
							<div className="ms-Grid-col ms-sm12">
								<div id="quillcomment2">
									<Field
										name="DeadlineComment"
										label="Ny frist" // Custom fields variables can be fetched in component (i.e. field.label)
										required={true}
										component={this.renderRichTextField}
									/>
									{/* <ReactQuill defaultValue={""} onChange={this.handleChangeQuill} /> */}
								</div>
							</div>
						</div>
						<div className="ms-Grid-row">
							<div className="ms-Grid-col ms-sm12">
								<div className="ms-DatePicker">
									<div className="ms-TextField">
										<Field
											name="Deadline"
											label="Ny frist" // Custom fields variables can be fetched in component (i.e. field.label)
											required={true}
											component={this.renderDateTimeField}
										/>
										{/* <DatePicker
                                            selected={moment(this.state.Deadline)}
                                            //selected={this.state.Deadline}
                                            onChange={(date) => { this.setState({ Deadline: date.locale(LOCALE).format(DATE_FORMAT) }) }}
                                            timeFormat="HH:mm"
                                            timeIntervals={5}
                                            minTime={moment().hours(8).minutes(0)}  // ScheduleElements cannot start before 8:00AM
                                            maxTime={moment().hours(16).minutes(0)} // ScheduleElements cannot end after 16:00AM
                                            filterDate={(date) => { return date.day() !== 0 && date.day() !== 6; }}   // Weekends are disabled
                                            locale="nb-NO"
                                            dateFormat={DATE_FORMAT}
                                            timeCaption="Tid"
                                            showTimeSelect
                                            showWeekNumbers
                                        /> */}
									</div>
								</div>
							</div>
						</div>
					</div>
					<div className="ms-Grid-col ms-sm12 ms-lg5 topToolRight">
						{/* <button type="button" className="ms-Button ms-Button--primary" data-dismiss="modal" >Lagre kommentar</button> */}
						<ActionButton
							onClick={handleSubmit(this.onFormSubmit.bind(this))}
							text="Send tilbake til elev"
							iconProps={{ iconName: 'RevToggleKey' }}
						/><br/>
						<DefaultButton 
                            text="Slett innlevering for elev"
                            iconProps={{iconName: "Delete"}}
                            onClick={() =>{
                            
                        	}}
                        />
					</div>
				</div>
			</form>
		);
	}
}

function validate(values) {
	const errors: any = {};

	// // Validate inputs from 'values'
	// if (!values.ClassID){
	//     errors.ClassID = 'Dette feltet er påkrevd.';
	// }

	// if (!values.Title){
	//     errors.Title = 'Dette feltet er påkrevd.';
	// }

	// if (!values.Start){
	//     errors.Start = 'Dette feltet er påkrevd.';
	// }

	// if (!values.End){
	//     errors.End = 'Dette feltet er påkrevd.';
	// }

	// if (values.Start.substring(11) > values.End){
	//     errors.End = 'Slutt kan ikke være før start.';
	// }

	// if (!values.Color){
	//     errors.Color = 'Dette feltet er påkrevd.';
	// }

	// If errors is an empty object, the form can be submitted
	// If errors has *any* properties, redux forms assumes the form is invalid
	return errors;
}

const mapStateToProps = (state, ownProps) => {
	return {
		studenttask: state.tasks.studenttask
	};
};

export default reduxForm({
	form: 'DeadlineForm',
	validate,
	enableReinitialize: true
})(connect(mapStateToProps, { saveStudentDeadline })(withRouter(DeadlineForm)));
