import * as _ from "lodash";
import { default as DateUtils } from "./../utils/DateUtils";
import {
    GET_LP_FAGFORNYELSEN,
    GET_LPDETAILS_FAGFORNYELSEN,
    GET_LPDETAILS_KUNNSKAPSLOFTET,
} from "../actions/Action_Grep";
export default function (state = {}, action) {
    switch (action.type) {
      case GET_LP_FAGFORNYELSEN:
        const lpFagfornyelsen = action.payload.data;
        
        return { ...state, lpFagfornyelsen: lpFagfornyelsen };

      case GET_LPDETAILS_FAGFORNYELSEN:
        const lpdetailsff = action.payload.data;
        
        return { ...state, lpdetails: lpdetailsff };

      case GET_LPDETAILS_KUNNSKAPSLOFTET:
        const lpdetailskl = action.payload.data;
        
        return {...state, lpdetails: lpdetailskl}
      default:
        return state;
    }
  }
  