import * as React from 'react';
import * as moment from "moment/moment";
import {Icon, ActionButton} from "office-ui-fabric-react";

interface ICustomToolbarProps {
    toolbar: any;
    week: any;
    year: any;
}

export const CustomToolbar = ({toolbar, week, year}: ICustomToolbarProps) => {

    if (week && year) {
        let newDate = moment().year(year);
        newDate.week(week).weekday(0);
                
        toolbar.date.setYear(newDate.year().valueOf());
        toolbar.date.setMonth(newDate.month().valueOf());
        toolbar.date.setDate(newDate.date());
    }

    const goToBack = () => {
        toolbar.date.setDate(toolbar.date.getDate() - 7);
        toolbar.onNavigate('prev');
    };

    const goToNext = () => {
        toolbar.date.setDate(toolbar.date.getDate() + 7);
        toolbar.onNavigate('next');
    };

    const goToCurrent = () => {
        const now = new Date();
        toolbar.date.setMonth(now.getMonth());
        toolbar.date.setYear(now.getFullYear());
        toolbar.onNavigate('current');
    };

    const label = () => {
        const date = moment(toolbar.date);
        return (
            <h3><b>{date.format('MMMM')}</b><span> {date.format('YYYY')}</span></h3>
        );
    };

    return (
        <div className="ms-Grid-row ms-bgColor-neutralLight topTool">
            <div className="ms-Grid-col ms-sm2 ms-md3 ms-lg4 prevWeek">
            <ActionButton  onClick={goToBack} className="ms-font-xl" style={{height: "inherit"}}>
                <i className="ms-Icon ms-Icon--PageLeft" aria-hidden="true"></i> <span> Forrige </span>
            </ActionButton>
            </div>
            <div className="ms-Grid-col ms-sm8 ms-md6 ms-lg4 text-center pt-5">
                <span className="ms-font-xl ms-fontWeight-semibold week-number" id="weekText">Uke {moment(toolbar.date).week()}</span>
            </div>
            <div className="ms-Grid-col ms-sm2 ms-md3 ms-lg4 nextWeek">
                <ActionButton  onClick={goToNext} className="ms-font-xl nextWeek" style={{height: "inherit"}}>
                    <span className="ms-font-xl">Neste</span> <i className="ms-Icon ms-Icon--PageRight" aria-hidden="true"> </i>
                </ActionButton>
            </div>             
        </div>

        
    );
};



