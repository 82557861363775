import * as React from "react";
import { connect } from "react-redux";
import { Field, reduxForm, change, getFormValues } from "redux-form";
import { IStudentQuizAttempt } from "../../../models/student/IStudentQuizAttempt";
import { IStudentQuizQuestion } from "../../../models/student/IStudentQuizQuestion";
import { QuestionType } from "../../../models/common/enums/QuestionType";
import FabricCheckboxField from "../../form_fields/fabric/FabricCheckboxField";
import FabricRadiobuttonField from "../../form_fields/fabric/FabricRadiobuttonField";
import "./StudentQuiz.scss";
import { IStudentQuizQuestionOption } from "../../../models/student/IStudentQuizQuestionOption";
import FabricRichTextField from "../../form_fields/fabric/FabricRichTextField";
import {
  ChoiceGroup,
  IChoiceGroupOption,
  PrimaryButton,
} from "office-ui-fabric-react";
import { IStudentQuizAttemptDto } from "../../../models/student/Dtos/IStudentQuizAttemptDto";
import { saveQuizAnswer } from "../../../actions/Action_Student";
import * as _ from "lodash";
import moment = require("moment");
import Timer from "./Timer";
interface IStudentQuizAttemptFormProps {
  initialValues: IStudentQuizAttempt;
  history: any;
  // Redux form
  handleSubmit: any;
  change: (name, value) => void;
  currentvalues: any;
}

class StudentQuizAttemptForm extends React.Component<
  IStudentQuizAttemptFormProps
> {
  componentWillReceiveProps(props) {
    console.log("oldprops", this.props);
    console.log("newpros", props);
  }
  onFormSubmit(values) {
    console.log("submit", values);
    saveQuizAnswer(values);
    this.props.history.push("/quizstudent");
  }

  onRadioOptionSelected = (questionIndex: number, QID: string) => {
    console.log(questionIndex, QID);

    // TODO: deselect all and select index where QID matches
  };

  onTextQuestionChanged = (
    question: IStudentQuizQuestion,
    updatedValue: string
  ) => {
    const attemptId = this.props.initialValues.Id;

    const saveAnswerDto: IStudentQuizAttemptDto = {
      Id: attemptId.toString(),
      Qid: question.QuizID.toString(),
      Answers: [
        {
          Answer: updatedValue,
          QuestionID: question.Id.toString(),
        },
      ],
    };

    saveQuizAnswer(saveAnswerDto);

    console.log("saveAnswerDto", saveAnswerDto);
  };
  onOptionChanged = (
    question: IStudentQuizQuestion,
    option: IStudentQuizQuestionOption,
    updatedValue: string
  ) => {
    const attemptId = this.props.initialValues.Id;

    let answerkeys = "";
    this.props.currentvalues.Quiz.Questions.forEach((que) => {
      if (question.Id == que.Id) {
        que.Options.forEach((op) => {
          if (op.QID == option.QID) {
            if (updatedValue) {
              if (answerkeys.length > 0) {
                answerkeys += ";";
              }
              answerkeys += op.QID;
            }
          } else if (op.Selected) {
            if (answerkeys.length > 0) {
              answerkeys += ";";
            }
            answerkeys += op.QID;
          }
        });
      }
    });
    const saveAnswerDto: IStudentQuizAttemptDto = {
      Id: attemptId.toString(),
      Qid: question.QuizID.toString(),
      Answers: [
        {
          Answer: answerkeys,
          QuestionID: question.Id.toString(),
        },
      ],
    };

    saveQuizAnswer(saveAnswerDto);

    console.log("saveAnswerDto", saveAnswerDto);
  };
  onOptionChangedRadio = (
    question: IStudentQuizQuestion,
    updatedValue: any
  ) => {
    const attemptId = this.props.initialValues.Id;
    let answer = "";
    updatedValue.Options.forEach((opt) => {
      if (opt.Selected) {
        answer = opt.QID;
      }
    });
    const saveAnswerDto: IStudentQuizAttemptDto = {
      Id: attemptId.toString(),
      Qid: question.QuizID.toString(),
      Answers: [
        {
          Answer: answer,
          QuestionID: question.Id.toString(),
        },
      ],
    };
    saveQuizAnswer(saveAnswerDto);
    console.log("saveAnswerDto", saveAnswerDto);

    //console.log("saveAnswerDto", saveAnswerDto);
  };
  debouncedFunction = _.debounce((question, value) => {
    console.log("debounced", value);
    this.onTextQuestionChanged(question, value);
  }, 3000);
  renderTextQuestion = (
    question: IStudentQuizQuestion,
    questionIndex: number
  ) => {
    console.log(question, questionIndex);

    return (
      <Field
        label="Svar"
        name={`Quiz.Questions[${questionIndex}].QuizAttemptAnswer.Answer`}
        component={FabricRichTextField}
        // onChange={(e, value) => {

        //   console.log('hu');
        //   this.onTextQuestionChanged(question, value);
        // }}
        // onChange={_.throttle(
        //   (e, value) => this.onTextQuestionChanged(question, value),
        //   10000
        // )}
        // onChange={(e, value)=>{
        //   console.log('************************');
        //   _.debounce(
        //     this.onTextQuestionChanged(question, value),
        //     2000
        //   )
        // }}
        onChange={(e, value) => {
          console.log("************************", value);
          this.debouncedFunction(question, value);
        }}
      />
    );
  };

  //   renderRadioQuestion = (
  //     question: IStudentQuizQuestion,
  //     questionIndex: number
  //   ) => {
  //     const selectedIndex: number = question.Options.findIndex(
  //       (option) => option.Selected
  //     );
  //     const options: IChoiceGroupOption[] = question.Options.map((option) => ({
  //       key: option.QID,
  //       text: option.Text,
  //       styles: { root: { padding: "0 0 10px 10px" } },
  //     }));

  //     return (
  //       <div className="ms-Grid-col ms-sm12">
  //           <label><Field  name={`Quiz.Questions[${questionIndex}].Options[${optionIndex}].Selected`} component={FabricRadiobuttonField} type="radio" value="male"/> Male</label>
  //           <label><Field name="sex" component={FabricRadiobuttonField} type="radio" value="female"/> Female</label>
  //         <ChoiceGroup
  //           options={options}
  //           selectedKey={selectedIndex}
  //           onChange={(e, value) =>
  //             this.onRadioOptionSelected(questionIndex, value.key)
  //           }
  //         />
  //       </div>
  //     );
  //   };

  renderRadioQuestioN = (
    question: IStudentQuizQuestion,
    questionIndex: number
  ) => (
    //     <Field
    //     name={`Quiz.Questions[${questionIndex}]`}
    //     label={'hu'}
    //     component={FabricCheckboxField}
    //     onChange={console.log('waschanged')}
    // />
    <Field
      name={`Quiz.Questions[${questionIndex}]`}
      label={""}
      component={FabricRadiobuttonField}
      onChange={(e, value) => this.onOptionChangedRadio(question, value)}
    />
  );
  // question.Options.map(
  //   (option: IStudentQuizQuestionOption, optionIndex: number) => (
  //     <Field
  //       name={`Quiz.Questions[${questionIndex}].Options[${optionIndex}].Selected`}
  //       label={option.Text}
  //       component={FabricRadiobuttonField}
  //       onChange={(e, value) => this.onOptionChanged(question, option, value)}
  //     />
  //   )
  // );

  renderCheckboxQuestion = (
    question: IStudentQuizQuestion,
    questionIndex: number
  ) =>
    //     <Field
    //     name={`Quiz.Questions[${questionIndex}]`}
    //     label={'hu'}
    //     component={FabricCheckboxField}
    //     onChange={console.log('waschanged')}
    // />
    question.Options.map(
      (option: IStudentQuizQuestionOption, optionIndex: number) => (
        <Field
          name={`Quiz.Questions[${questionIndex}].Options[${optionIndex}].Selected`}
          label={option.Text}
          component={FabricCheckboxField}
          onChange={(e, value) => this.onOptionChanged(question, option, value)}
        />
      )
    );

  renderQuestionContentByType = (
    question: IStudentQuizQuestion,
    index: number
  ) => {
    switch (question.QType) {
      case QuestionType.TextAnswer:
        return this.renderTextQuestion(question, index);
      case QuestionType.CheckBoxes:
        return this.renderCheckboxQuestion(question, index);
      case QuestionType.RadioButtons:
        return this.renderRadioQuestioN(question, index);
      default:
        return null;
    }
  };

  renderQuestion = (question: IStudentQuizQuestion, index: number) => (
    <div className="question-grid-container ms-Grid-row ms-sm12">
      <div className="question-container">
        <div className="question-title">
          <span>{`${index + 1}. ${question.QText}`}</span>
          {question.QMandatory && (
            <span
              className="error-message"
              style={{
                paddingTop: 0,
                margin: "0 5px",
                fontSize: 14,
                fontWeight: 600,
              }}
            >
              *
            </span>
          )}
        </div>
        <div className="question-answer">
          {this.renderQuestionContentByType(question, index)}
        </div>
      </div>
    </div>
  );

  render() {
    const { initialValues, handleSubmit } = this.props;

    let questionList = getSelectedQuestionsList(initialValues.Quiz.Questions, initialValues.QuestionSelection);

    console.log(
      "mom",
      moment.duration(moment().diff(initialValues.Started)).asMinutes()
    );
    let used = parseInt(
      moment
        .duration(moment().diff(initialValues.Started))
        .asMinutes()
        .toString()
    );
    let left = initialValues.Quiz.Duration - used;
    console.log(used);
    return (
      <form
        id="PlanElementForm"
        onSubmit={handleSubmit(this.onFormSubmit.bind(this))}
      >
        {/* <Timer minutes={left} timesup={()=>{
              console.log('tiden er ute');
              handleSubmit(this.onFormSubmit.bind(this))
            }}></Timer> */}

        <Timer
          minutes={left}
          timesup={handleSubmit(this.onFormSubmit.bind(this))}
        ></Timer>
        <div className="ms-Grid">
          {questionList.map((question, index) =>
            this.renderQuestion(question, index)
          )}
          <PrimaryButton onClick={handleSubmit(this.onFormSubmit.bind(this))}>
            Send inn
          </PrimaryButton>
        </div>
      </form>
    );
  }
}

const validate = (values: any) => {
  const errors = {};

  console.log(values);

  return errors;
};

const mapStateToProps = (state, ownProps) => {
  const formData = getFormValues("StudentQuizAttemptForm")(state);
  console.log("whatisthevalues", formData);
  return {
    currentvalues: formData,
  };
};

const getSelectedQuestionsList = (allQuestions: any[], selectedQuestions: string): any[] => {
  let questionsToReturn: any[];
  
  if(selectedQuestions && selectedQuestions.length > 0) {
    let questions: string[]  = selectedQuestions.split(";");
    questionsToReturn = allQuestions.filter(q => questions.indexOf(q.Id.toString()) !== -1); 
  }
  else {
    questionsToReturn = allQuestions;
  }
  
  return questionsToReturn;
}

export default reduxForm({
  form: "StudentQuizAttemptForm",
  validate,
  enableReinitialize: true,
  onSubmitFail: () => {
    document.getElementById("content-view").scrollIntoView();
  },
})(connect(mapStateToProps, { saveQuizAnswer })(StudentQuizAttemptForm));
