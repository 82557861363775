import * as React from 'react';
import {Link} from "react-router-dom";
import {PrimaryButton, ActionButton, Panel,PanelType, Icon} from "office-ui-fabric-react";
import CourseContainerTeamsItem from "./CourseContainerTeamsItem";
import InnleveringStatusViewTeams from './InnleveringStatusViewTeams';
import { getAssignment } from '../../utils/GraphEduService';
import ReactTooltip from 'react-tooltip';

export interface ICourseContainerTeamsProps {
    task: any;
    history:any;
}
export interface ICourseContainerTeamsState {
    show:boolean;
}
class CourseContainerTeams extends React.Component<ICourseContainerTeamsProps, ICourseContainerTeamsState> {
    constructor(props) {
        super(props);
    }

    renderTaskTitle = () => {
        if (this.props.task.TeamsDeepLink) {
            return <a href={this.props.task.TeamsDeepLink} target="_blank">
                <h3 style={{display: "inline"}}>{this.props.task.CourseName}: {this.props.task.Name} {this.props.task.Deadlinetekst} </h3>
            </a>
        }
        else if (this.props.task.TeamsTask) {
            return <ActionButton onClick={async () => {
                if (this.props.task.TeamsDeepLink) {
                    window.open(this.props.task.TeamsDeepLink, "_blank", "noreferrer");
                }
                else if (this.props.task.TeamsTask) {
                  let classId = this.props.task.TeamsTask.split("|")[0];
                  let assignmentId = this.props.task.TeamsTask.split("|")[1];

                let assignment = await getAssignment(classId, assignmentId);
                //this.props.history.push(assignment.webUrl);
                window.open(assignment.webUrl, "_blank", "noreferrer");
              }
            }}>
                <h3 style={{display: "inline"}}>{this.props.task.CourseName}: {this.props.task.Name} {this.props.task.Deadlinetekst} </h3>
            </ActionButton>
        }
        else {
            return <h3 style={{display: "inline"}}>{this.props.task.CourseName}: {this.props.task.Name} {this.props.task.Deadlinetekst} </h3>;
        }
    }

    render(){
        
        let url = 'innleveringer/'+this.props.task.Id;
        if (this.props.task.TeamsDeepLink) {
            url = this.props.task.TeamsDeepLink;
        }
        else if (this.props.task.TeamsTask) {
            url = this.props.task.TeamsTask;
        }
        return(
            <div className={'course-container panel-default'} id={this.props.task.Course.CourseCategory}>
                {
                    this.state &&
                    <Panel
                    isOpen={this.state.show}
                    type={PanelType.extraLarge}
                    onDismiss={() => {
                        this.setState({ show: false });
                    }}
                    headerText="Teamsoppgave"
                    closeButtonAriaLabel="Lukk"
                    // onRenderFooterContent={this._onRenderFooterContent}
                    >
                       
                      <InnleveringStatusViewTeams tid={this.props.task.Id}>

                      </InnleveringStatusViewTeams>
                  
                </Panel>
                }
              
               {/* <div className="panel-heading"> <Link to={url}><h3>{this.props.task.CourseName}: {this.props.task.Name} {this.props.task.Deadlinetekst}</h3></Link></div> */}
                <div className="panel-heading"> 
                    {this.renderTaskTitle()}

                    {(this.props.task.TeamsTask || this.props.task.TeamsDeepLink) && 
                        <span>
                            <Icon data-tip data-for={`teamstask-${this.props.task.Id}`} iconName="TeamsLogo" style={{fontSize: 20, marginRight: "10px", color: "#4E5FBF", cursor: "default"}}/>
                            <ReactTooltip id={`teamstask-${this.props.task.Id}`}>Denne oppgaven er knyttet til Teams</ReactTooltip>
                        </span>
                    }
                </div>
               
                <div className="panel-body">
                    <div className="ms-Grid-row">
                        <CourseContainerTeamsItem history={this.props.history} color="#D9534F" statusText="Ikke levert" iconName="IncidentTriangle" studentTasks={this.props.task.StudentTaskOpen}/>
                        <CourseContainerTeamsItem history={this.props.history} color="#F0AD4E" statusText="Klar for vurdering" iconName="InboxCheck" studentTasks={this.props.task.StudentTasksDelivered}/>
                        <CourseContainerTeamsItem history={this.props.history} color="#5CB85C" statusText="Vurdert" iconName="CheckMark" studentTasks={this.props.task.StudentTaskGraded}/>
                    </div>
                    <div className="course-container-footer ms-Grid-row ms-sm12">
                        <span></span>
                        <ActionButton
							text="Åpne i Teams"
							style={{ textAlign: "left" }}
							iconProps={{ iconName: "OpenInNewTab" }}
							onClick={async () => {
							let tid = this.props.task.TeamsTask.split("|")[0];
							let assid = this.props.task.TeamsTask.split("|")[1];
			
							let assignment = await getAssignment(tid, assid);
							//this.props.history.push(assignment.webUrl);
							window.open(assignment.webUrl, "_blank", "noreferrer");
							}}
						/>
                    </div>
                </div>

               
            </div>
        )
    }
    onEditPlan(pe:any) {
        this.props.history.push('/task/'+pe.Id+'/e');
    }
    onViewStatusTask(pe:any) {
        this.props.history.push('/innleveringer/'+pe);
    }
}

export default CourseContainerTeams;