import {Student} from "./Student";

export class AssignmentGroup {
    Id:any;                 // Can be string for new groups.
    Name:string;
    CourseID:string;
    Course:number;          // Course contains number id to parent course
    Students:Student[];

    constructor(Id: any, Name: string, CourseID: string, Course: number, Students: Student[]) {
        this.Id = Id;
        this.Name = Name;
        this.CourseID = CourseID;
        this.Course = Course;
        this.Students = Students;
    }
}