import * as React from "react";
import { Calendar, momentLocalizer } from 'react-big-calendar'
import * as moment from "moment";

import * as _ from "lodash";
import { connect } from "react-redux";

import HeaderView from "../../view_containers/HeaderView";
import {
  getScheduleElements,
  getStudentState,
} from "../../../actions/Action_Student";
import { CustomToolbar } from "../../timeplan/TimeplanToolbar";
import {
  CompoundButton,
  CommandBarButton,
  DefaultButton,
  Dialog,
  DialogFooter,
  DialogType,
  Dropdown,
  Icon,
  PrimaryButton,
  Stack,
  Toggle,
} from "office-ui-fabric-react";
import ReactTooltip from 'react-tooltip';
import { SchoolClass } from "../../../models/SchoolClass";
import { ScheduleElement } from "../../../models/ScheduleElement";
import StyleUtils from "../../../utils/StyleUtils";
import { CALENDAR_MAX, CALENDAR_MIN } from "../../../utils/DateUtils";
import SidebarActionButtonList from "../../sidebar/SidebarActionButtonList";

import { LoadingDialog } from "../../loading/LoadingDialog";
import "../../timeplan/timeplan.scss";
import { setSelectedWeek, setSelectedYear } from "../../../actions/Action_Filtervalues";
moment.locale("nb");

export interface ITimeplanStudentViewProps {
  scheduleElements: {};
  subscribedSchoolClasses: {};
  history: any;
  student: any;
  getScheduleElements(schoolClass: string, week: number, year: number);
  setSelectedYear(year: number);
  setSelectedWeek(week: number);
  selectedWeek?: number;
  selectedYear?: number;
}

export interface ITimeplanStudentViewState {
  // Big Calendar
  selectedDate: any;
  selectedWeek: number;
  selectedYear: number;
  // selectedClass:SchoolClass;

  // Modals
  // showDateSelectModal:boolean;
  // showCopyScheduleModal:boolean;
  // showCopyScheduleLoadingModal:boolean;

  // Dropdown
  // dropdownSelectedWeek:any;
  // dropdownSelectedYear:any;
  // dropdownSelectedClass:any;
  // dropdownWeekOptions:any;
  // dropdownYearOptions:any;
  overlappingElementer: boolean;


  //Link Modal
  showLinkModal: boolean;
  selectedLinks: string;
}

class TimeplanStudentView extends React.Component<
  ITimeplanStudentViewProps,
  ITimeplanStudentViewState
> {
  constructor(props) {
    super(props);

    // this.onSelectEvent = this.onSelectEvent.bind(this);
    // this.onSelectedSchoolClass = this.onSelectedSchoolClass.bind(this);
    // this.renderSchoolClassDropdown = this.renderSchoolClassDropdown.bind(this);
    // this.filterEventsBySelectedSchoolClass = this.filterEventsBySelectedSchoolClass.bind(this);
  }

  /****************************
            Lifecycle hooks
     ****************************/

  componentDidMount() {
    const { selectedWeek, selectedYear } = this.props;

    const date = getInitialDate(selectedWeek, selectedYear);
    const week = selectedWeek || getCurrentWeek();
    const year = selectedYear || getCurrentYear();

    const weekOptions = [];
    const yearOptions = [];
    for (let i = 1; i <= 52; i++) {
      weekOptions.push({ key: i, text: i.toString() });
    }
    for (let i = -1; i <= 1; i++) {
      yearOptions.push({
        key: moment(new Date()).year() + i,
        text: (moment(new Date()).year() + i).toString(),
      });
    }

    this.setState(
      {
        // Dropdown
        // dropdownSelectedWeek: week,
        // dropdownSelectedYear: year,
        // dropdownSelectedClass: schoolClass.ClassID,
        // dropdownWeekOptions: weekOptions,
        // dropdownYearOptions: yearOptions,

        // Modals
        // showDateSelectModal: false,
        // showCopyScheduleLoadingModal:false,
        // showCopyScheduleModal: false,

        // Calendar state
        selectedDate: date,
        selectedWeek: week,
        selectedYear: year,
        // selectedClass: schoolClass,
        overlappingElementer: true,
      },
      () => {
        this.props.getScheduleElements(this.props.student, week, year);
      }
    );
  }

  /****************************
            Event Handlers
     ****************************/

  onSelectEvent(se: ScheduleElement) {
    this.props.history.push("/timeplan/" + se.Id);
  }

  // onSelectedSchoolClass(value){

  //     const {key} = value;

  //     const selectedClass = this.props.subscribedSchoolClasses[key];

  //     this.setState({
  //         selectedClass: selectedClass,
  //     }, ()=> {
  //         this.props.getScheduleElements(selectedClass.ClassID, this.state.selectedWeek, this.state.selectedYear);
  //     });
  // }

  onWeekChanged(date) {
    const week = moment(date).week();
    const year = moment(date).year();

    this.setState(
      {
        selectedWeek: week,
        selectedYear: year,
      },
      () => {
        this.props.setSelectedWeek(week);
        this.props.setSelectedYear(year);
        this.props.getScheduleElements(this.props.student, week, year);
      }
    );
  }

  //   onCalendarDateSet() {
  //     const newDate = moment(new Date())
  //       .year(this.state.dropdownSelectedYear)
  //       .week(this.state.dropdownSelectedWeek)
  //       .toDate();

  //     this.setState(
  //       {
  //         selectedDate: newDate,
  //         showDateSelectModal: false
  //       },
  //       () => {
  //         this.props.getScheduleElements(
  //           this.state.selectedClass.ClassID,
  //           this.state.dropdownSelectedWeek,
  //           this.state.dropdownSelectedYear
  //         );
  //       }
  //     );
  //   }

  /****************************
            Helper methods
     ****************************/

  mapSchoolClassesToKeys() {
    const schoolClassesList: SchoolClass[] = _.values(
      this.props.subscribedSchoolClasses
    );
    const keyList = [];

    schoolClassesList.forEach((se) => {
      keyList.push({ key: se.ClassID, text: se.ClassName });
    });

    return keyList;
  }

  filterEvents() {
    // Returns an empty array if there are ScheduleElements yet
    if (_.isEmpty(this.props.scheduleElements)) {
      return [];
    }

    let values = _.values(this.props.scheduleElements).sort((a, b) => {
      if(a.Title < b.Title) { return -1; }
      if(a.Title > b.Title) { return 1; }
      return 0;
    });

    if (this.state && !this.state.overlappingElementer) {
      // Because of weird behaviour when overlap is turned off in react big calendar, the order of elements needs to be reversed
      values = values.reverse();
    }
    return values;
  }

  //   filterEventsBySelectedSchoolClass() {
  //     // Returns an empty array if there are ScheduleElements yet
  //     if (_.isEmpty(this.props.scheduleElements)) {
  //       return [];
  //     }

  //     // Returns an empty array if teacher has no SchoolClasses or state for selectedClass is not set
  //     if (
  //       _.isEmpty(this.props.subscribedSchoolClasses) ||
  //       !this.state ||
  //       !this.state.selectedClass
  //     ) {
  //       return [];
  //     }

  //     // Maps object to array and filter ScheduleElements by selectedClass' ClassID in calendar.
  //     return _.filter(
  //       _.values(this.props.scheduleElements),
  //       (i: ScheduleElement) => {
  //         return i.ClassID === this.state.selectedClass.ClassID;
  //       }
  //     );
  //   }

  eventStyleGetter(se: ScheduleElement) {
    return { style: new StyleUtils().getCalendarScheduleStyle(se.Color) };
  }

  /****************************
            Render methods
     ****************************/

    renderDateDropdown = () => {
      let today = moment();
      let schoolYear = (today.week().valueOf() > 29) ? today.year().valueOf() + 1 :  today.year().valueOf()
      let yearList = [];
      let weekList = [];
  
      if (this.state) {
    
        for (let index = 1; index <= moment().year(this.state.selectedYear).weeksInYear(); index++) {
          weekList.push({key: index, text: index.toString()});
          
        }
        for (let index = schoolYear; index >= schoolYear-2; index--) {
          yearList.push({key: index, text: index.toString()})
        }
      }
  
      return (
        <Stack horizontal>
          <div style={{minWidth: "100px"}}>
            <Dropdown
            placeHolder="Velg uke"
            label="Velg uke"
            options={weekList}
            selectedKey={this.state && this.state.selectedWeek}
            onChange={(status, weekSelected) => {
              let week: number;
  
              week = parseInt(weekSelected.text);

              this.onWeekChanged(moment().year(this.state.selectedYear).week(week).toDate())
            }}
            >
            </Dropdown>
          </div>
          <div style={{minWidth: "100px", marginLeft: "7px"}}>
            <Dropdown
            placeHolder="Velg år"
            label="Velg år"
            options={yearList}
            selectedKey={this.state && this.state.selectedYear}
            onChange={(status, yearSelected) => {
              this.onWeekChanged(moment().year(parseInt(yearSelected.text)).week(this.state.selectedWeek).toDate());
            }}
            >
            </Dropdown>
          </div>
        </Stack>
      )
    }

    renderEditBar = () => {
      return (
        <div>
          {this.props.scheduleElements && this.state && (
            <div>
              <Stack horizontal style={{alignItems: "center"}}>
                <Toggle 
                  defaultChecked
                  style={{marginTop: 6, marginLeft: 25}} 
                  label={
                    <span style={{display: "flex", alignItems: "center", marginTop: 8}}>Overlapping <Icon data-tip data-for='overlapTooltip' iconName="Info" style={{marginTop: 6, marginLeft: 4}}/>
                      <ReactTooltip id="overlapTooltip">
                        <p style={{fontSize: 15}}>Tillater timeplanelementer til å overlappe hverandre under visning</p>
                      </ReactTooltip>
                    </span>}
                  onChange={() => {
                    this.setState({overlappingElementer: !this.state.overlappingElementer})
                  }}
                  inlineLabel
                />
              </Stack>
            </div>
          )}
        </div>
      );
    }
    renderCustomEvent = (scheduleElement: ScheduleElement) => {
      return <div className="custom-event-container" onClick={() => {
      }}>
        <div className="custom-event-label">
          <span className="custom-event-label-time">
            {scheduleElement.Start ? moment(scheduleElement.Start).format("HH:mm") : ""} - {scheduleElement.End ? moment(scheduleElement.End).format("HH:mm") : ""}
          </span>
          {(scheduleElement.Links && scheduleElement.Links.length > 0) && <span>
            <Icon data-tip data-for={`link-tooltip-${scheduleElement.Id}`} 
              className={"custom-event-icon"}
              iconName="Link" 
              onClick={() => {
                  this.setState({showLinkModal: true, selectedLinks: scheduleElement.Links})
              }}
            />
            <ReactTooltip id={`link-tooltip-${scheduleElement.Id}`}>
              <span style={{textAlign: "center"}}>
                  <div>
                    Dette timeplanelementet har {scheduleElement.Links ? scheduleElement.Links.split("|").length : 0} lenker
                  </div>
                  <p>Trykk for å se lenker</p>
              </span>
            </ReactTooltip>
          </span>}
  
        </div>
        <div style={{flexGrow: 1}}>
          {scheduleElement.Title}
        </div>
      </div>
    }

    renderLinkModal = () => {
      let linkList: string[] = this.state.selectedLinks.split("|");
      console.log("Selected Links:", this.state.selectedLinks, linkList);
  
      return (<Dialog
        hidden={false}
        dialogContentProps={{
          type: DialogType.normal,
          title: "Tilknyttede lenker"
        }}
        modalProps={{isBlocking: false}}
        onDismiss={() => {
          this.setState({showLinkModal: false});
        }}
      >
          <ul style={{listStyleType: "circle"}}>
            {linkList.map((link) => {
              return <li className="addedlink" style={{marginLeft: 20}}>
                <span className="labelText">
                  <a target="_blank" href={link}>
                    {link}
                  </a>
                </span>
              </li>;
            })}
          </ul>
        <DialogFooter>
          <DefaultButton
            onClick={() => {
              this.setState({showLinkModal: false});
            }}
            text="Lukk"
          ></DefaultButton>
        </DialogFooter>
      </Dialog>)
    }
  
  
  render() {
    const localizer = momentLocalizer(moment);
    return (
      <div className="ms-Grid container main">
        <SidebarActionButtonList>
          {/* <CommandBarButton 
                        onClick={()=>this.props.history.push('/timeplan/nytt_innslag')} 
                        className="sidenav-buttons" 
                        iconProps={{ iconName: 'Add'}} 
                        text="Nytt innslag" 
                    />
                   
                    <CommandBarButton 
                        onClick={()=>this.setState({showCopyScheduleModal: true})} 
                        className="sidenav-buttons"
                        iconProps={{ iconName: 'Copy'}} 
                        text="Kopier timeplanen"
                    />
                    
                    <CommandBarButton
                        onClick={()=> this.setState({showDateSelectModal: true})}
                        className="sidenav-buttons"
                        iconProps={{ iconName: 'Forward'}} 
                        text="Gå direkte til uke"
                    /> */}
        </SidebarActionButtonList>

        <HeaderView
          title={`Timeplan ${
            this.state ? moment(this.state.selectedDate).format("YYYY") : ""
          }`}
          description="Her finner du din timeplan"
          iconName="ViewAll"
        />
        <div style={{marginBottom: 20}}>
          {this.renderDateDropdown()}
        </div>
        
        {this.renderEditBar()}

        <div>
          {this.props.scheduleElements && (
            <Calendar
              localizer={localizer}
              events={this.filterEvents()}
              min={CALENDAR_MIN}
              max={CALENDAR_MAX}
              defaultView={"work_week"}
              eventPropGetter={this.eventStyleGetter}
              views={["day", "work_week"]}
              // onSelectEvent={this.onSelectEvent}
              dayLayoutAlgorithm={this.state && !this.state.overlappingElementer ? "no-overlap" : "overlap"}
              titleAccessor="Title"
              startAccessor="Start"
              endAccessor="End"
              components={{
                toolbar: (props) =>  <CustomToolbar toolbar={props} week= {this.state ? this.state.selectedWeek : 1} year= {this.state ? this.state.selectedYear : moment().year().valueOf()}></CustomToolbar>,
                event: ({event}) => (this.renderCustomEvent(event))
              }}
              onNavigate={(date) => {
                // this.setState({
                  
                //   selectedDate:date
                // });
                this.onWeekChanged(date);
              }}
              date={this.state ? this.state.selectedDate : new Date()}
            />
          )}
        </div>
        {this.state && this.state.showLinkModal && this.renderLinkModal()}
      </div>
    );
  }
}

const getCurrentWeek = (): number => moment(new Date()).week();

const getCurrentYear = (): number => moment(new Date()).year();

const getInitialDate = (selectedWeek?: number, selectedYear?: number): Date =>
  selectedWeek && selectedYear
    ? moment(new Date()).year(selectedYear).week(selectedWeek).day(1).toDate()
    : new Date();

function mapStateToProps(state) {
  console.log(
    "state.StudentReducer.scheduleElements",
    state.StudentReducer.scheduleElements
  );
  const { selectedWeek, selectedYear } = state.FilterReducer;

  return {
    scheduleElements: state.StudentReducer.scheduleElements,
    student: state.StudentReducer.student,
    selectedWeek,
    selectedYear
  };
}

export default connect(mapStateToProps, {
  getScheduleElements,
  getStudentState,
  setSelectedYear,
  setSelectedWeek,
})(TimeplanStudentView);
