import * as React from 'react';
import { Icon, Panel, PanelType } from "office-ui-fabric-react";
import {
    Accordion,
    AccordionItem,
    AccordionItemTitle,
    AccordionItemBody,
} from 'react-accessible-accordion';
import 'react-accessible-accordion/dist/fancy-example.css';
import Teamssubmission from '../graph/Teamssubmission';

export interface ICourseContainerItemProps {
    color: string;
    iconName: string;
    statusText: string;
    students: any[];
    history: any;
    task:any;
}

export interface ICourseContainerItemState {
    show: boolean;
}
class CourseContainerItem extends React.Component<ICourseContainerItemProps, ICourseContainerItemState>{

    constructor(props) {
        super(props);
    }

    render() {

        const { color, iconName, statusText } = this.props;

        const iconStyle = { color: color };
        const containerStyle = { borderTop: '5px solid', borderTopColor: color };
        if (!this.props.students!) {
            console.log('students', this.props);
        }
        return (
            <div className="ms-Grid-col ms-sm12 ms-xl4">
                <div className="course-container-item">
                    <div className="ms-Grid-col ms-sm6 ms-font-su" style={iconStyle}>
                        <Icon iconName={iconName}></Icon>
                    </div>
                    <div className="ms-Grid-col ms-sm6 ms-textAlignRight">
                        <h4>{this.props.students && this.props.students.length}</h4>
                        <span>{statusText}</span>
                    </div>
                    <div className="course-container-item-footer ms-Grid-col ms-sm12" style={containerStyle}>

                        <Accordion>
                            <AccordionItem>
                                <AccordionItemTitle>
                                    <Icon iconName="DrillDownSolid" /> <span className="ms-fontWeight-semibold">Vis detaljer</span>
                                </AccordionItemTitle>
                                <AccordionItemBody>
                                    <ul className="no-type-list">
                                        {
                                            this.props.students &&
                                            this.props.students.map((s) => {
                                                console.log('student', s);
                                                if (!s.Teamssubmissionid) {
                                                    return (
                                                        <li className="pointer" onClick={() => this.onEditStudentTask(s)}>
                                                            {s.StudentName}
                                                        </li>
                                                    )
                                                }
                                                if (s.Teamssubmissionid) {
                                                    return (
                                                        <>
                                                            <li className="pointer" onClick={() => {
                                                                console.log('sjekk plagiat');
                                                                this.setState({ show: true });
                                                            }}>
                                                                {s.StudentName}
                                                            </li>
                                                            {
                                                                this.state &&
                                                                <Panel

                                                                    isOpen={this.state.show}
                                                                    type={PanelType.large}
                                                                    onDismiss={() => {
                                                                        this.setState({ show: false });
                                                                    }}
                                                                    headerText="Teamsoppgave"
                                                                    closeButtonAriaLabel="Lukk"
                                                                // onRenderFooterContent={this._onRenderFooterContent}
                                                                >

                                                                    <Teamssubmission studenttask={s} task={this.props.task}></Teamssubmission>
                                                                </Panel>
                                                            }
                                                        </>
                                                    )
                                                }
                                            })
                                        }

                                    </ul>
                                </AccordionItemBody>
                            </AccordionItem>

                        </Accordion>

                    </div>
                </div>
            </div>
        )
    }
    onEditStudentTask(pe: any) {
        this.props.history.push('/innleveringer/student/' + pe.Id);
    }

}

export default CourseContainerItem;