import * as React from 'react';
import  {getImmersiveToken}  from "../../actions/Action_Ukeplan";
import { ActionButton, IconButton } from 'office-ui-fabric-react';
import { connect } from "react-redux";
import ReactTooltip from 'react-tooltip';
// import {ImmersiveReader} from '@microsoft/immersive-reader-sdk';
export interface IImmersivereaderProps {
  getImmersiveToken();
  htmlcontent:any;
}


const ir =  (window as any).ImmersiveReader;
class ImmersiveReader extends React.Component<IImmersivereaderProps> {
  public render() {
    return (
      <div style={{marginTop: "7px"}}>
        <IconButton
            iconProps={{iconName: 'ReadOutLoud'}}
            onClick={() => this._launch()}
            className={"immersiveReaderIcon"}
            data-tip 
            data-for="ImmersiveReader"
            aria-label='Få dette kortet opplest'
            
        />
        <ReactTooltip  id="ImmersiveReader">Få dette kortet opplest</ReactTooltip>
      </div>
    );
  }
  
  _launch()
  {
    this.props.getImmersiveToken().then((resp)=>{
      const content = {
        title: 'Immersive Reader',
        chunks: [ {
            content:this.props.htmlcontent.innerText 
        } ]
    };
    
    ir.launchAsync(resp.payload.data, 'zdev', content, { uiZIndex: 9999999 });
    });
    
  }
}
function mapStateToProps(state, ownProps) {

  // if(this.state.Quiz)
  // {
  return {
    quiz: state.Quiz.gradequizf
  };
}

export default connect(mapStateToProps, { getImmersiveToken })(ImmersiveReader);