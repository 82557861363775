import * as React from "react";
import {
  ChoiceGroup,
  IChoiceGroupOption,
  PrimaryButton,
} from "office-ui-fabric-react";
interface IRadiobuttonFieldProps {
  input: any;
  label: string;
  required?: boolean;
}

const FabricRadiobuttonField = ({
  input,
  label,
  required,
}: IRadiobuttonFieldProps) => {
  console.log("radioinput", input);
  const selectedIndex: number = input.value.Options.findIndex(
    (option) => option.Selected
  );
  let sel = "";
  input.value.Options.forEach((element) => {
    if (element.Selected) {
      sel = element.QID;
    }
  });
  console.log("selectedindex", selectedIndex);
  const options: IChoiceGroupOption[] = input.value.Options.map((option) => ({
    key: option.QID,
    text: option.Text,
    styles: { root: { padding: "0 0 10px 10px" } },
  }));
  return (
    <div className="ms-Grid-row">
      <div className="z-form-group vertical-align">
        <div className="ms-Grid-col ms-sm12">
          <ChoiceGroup
            options={options}
            selectedKey={sel}
            onChange={(e, value) => {
              console.log("e", e);
              console.log("value", value);
              input.value.Options.forEach((element) => {
                if (element.QID == value.key) {
                  element.Selected = true;
                } else {
                  element.Selected = false;
                }
              });
              console.log("newval", input.value);
              input.onChange(input.value);
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default FabricRadiobuttonField;
