import * as React from "react";
import { render } from "react-dom";
import { AppContainer } from "react-hot-loader";
import App from "./app/App";
import * as MSAL from "msal";
import * as msal from "@azure/msal-browser";
import {
  MSAL_CONFIG,
  getTokenSilent,
  MSAL_LOGIN_REQUEST,
  multiAuth,
  MSAL_CONFIGNEW,
  TEAMS_CONFIG,
  MSAL_CONFIGOD,
  MSAL_CONFIG_BROWSER,
  getTokenSilentmsal,
} from "./msalConfig";
import { applyMiddleware, compose, createStore } from "redux";
import { default as rootReducer } from "./app/reducers/reducers";
import promise from "redux-promise";
import { Fabric } from "office-ui-fabric-react";
import { Provider } from "react-redux";
import { initializeIcons } from "@uifabric/icons";
import "react-notifications-component/dist/theme.css";
import ReactNotification from "react-notifications-component";
// import { Providers, MsalProvider, TeamsProvider } from "@microsoft/mgt";
// import { Login, Agenda, Person } from "@microsoft/mgt-react";
import * as MicrosoftTeams from "@microsoft/teams-js";
import {
  Providers,
  TeamsHelper,
  TeamsProvider,
  MsalProvider,
  ProviderState,
} from "@microsoft/mgt";
import { Msal2Provider, PromptType } from "@microsoft/mgt-msal2-provider";
const rootElement = document.getElementById("root");
console.log("indexindex");
initializeIcons(undefined, { disableWarnings: true });
console.log(window.location);
const renderApp = () => {
  document.getElementById('errorlog').innerHTML += '<br>renderapp';
  const store = createStore(
    rootReducer,
    {} as any,
    compose(
      applyMiddleware(promise),
      (window as any).__REDUX_DEVTOOLS_EXTENSION__
        ? (window as any).__REDUX_DEVTOOLS_EXTENSION__()
        : (f) => f
    )
  );

  render(
    <Provider store={store}>
      <Fabric>
        <AppContainer>
          <div>
            <ReactNotification></ReactNotification>
            <App />
          </div>
        </AppContainer>
      </Fabric>
    </Provider>,
    rootElement
  );
};
if (window.location.href.indexOf("=") > 1) {
  localStorage.setItem("initurl", window.location.href.split("=")[1]);
} else {
  localStorage.setItem("initurl", "");
}
const providerStateChanged = () => {
  document.getElementById('errorlog').innerHTML += '<br>providerchanged';
  console.log("somethingchanged", Providers.globalProvider.state);
  if (Providers.globalProvider.state === ProviderState.SignedIn) {
    document.getElementById('errorlog').innerHTML += '<br>signedin';
    // user is now signed in
    console.log("issignedin");
    Providers.globalProvider
      .getAccessToken({ scopes: [".default"] })
      .then((token) => {
        console.log("gtkstate", Providers.globalProvider.state);
        console.log("gtkloggedina", token);
        localStorage.setItem("adal.idtoken", token);
        document.getElementById('errorlog').innerHTML += '<br>gottoken';
        renderApp();
      });
  }
  else{
    console.log("signingin",Providers.globalProvider.state);
    document.getElementById('errorlog').innerHTML += '<br>else'+Providers.globalProvider.state;
    Providers.globalProvider
      .getAccessToken({ scopes: [".default"] })
      .then((token) => {
        console.log("gtkstate", Providers.globalProvider.state);
        console.log("gtkloggedina", token);
        localStorage.setItem("adal.idtoken", token);
        document.getElementById('errorlog').innerHTML += '<br>gottoken';
        renderApp();
      });
    //Providers.globalProvider.login();
  }
};
Providers.onProviderUpdated(providerStateChanged);
TeamsProvider.microsoftTeamsLib = microsoftTeams;
if (TeamsProvider.isAvailable) {
  console.log("gtk", "inteams");
  document.getElementById('errorlog').innerHTML += '<br>inteams';
  Providers.globalProvider = new TeamsProvider({
    clientId: "1c61bbe0-ab07-4fd4-9da9-337f1cb05a7b",
    authPopupUrl: "/auth.html",
  });
} else {
  document.getElementById('errorlog').innerHTML += '<br>notinteams';
  Providers.globalProvider = new Msal2Provider({
    clientId: "1c61bbe0-ab07-4fd4-9da9-337f1cb05a7b",
    scopes: [".default"],
  });
  console.log("gtk", "notinteams");
  console.log("gtkstate", Providers.globalProvider.state);

}

