import * as React from 'react';

import SideBarLink from './SidebarLink';

class SidebarView extends React.Component {

    render(){
        return(
            <div className="sidebar-view">
                <SideBarLink path='home' iconName='Home' description='Ukeplan lærer' color="#01b3ef" fullWidth={true}   />
                <SideBarLink path='/ukeplan' iconName='Calendar' description='Ukeplan lærer'  />
                <SideBarLink path='/innleveringer' iconName='CompletedSolid' description='Innlevering lærer'  />
                <SideBarLink path='/timeplan' iconName='ViewAll' description='Timeplan lærer'   />
                <SideBarLink path='/quiz' iconName='PageListSolid' description='Prøver'  />
                <SideBarLink path='/portefolje' iconName='FabricOpenFolderHorizontal' description='Portefølje for lærer'  />
                <SideBarLink path='/meldinger' iconName='Message' description='Dashboard for meldinger for lærer'  />
                <SideBarLink path='/iup' iconName='Transition' description='IUP'  />
                <SideBarLink path='/admin' iconName='Settings' description='Administrasjon for lærer'  />
                <SideBarLink path='/discovery' iconName='Search' description='Søkesenter'  />
            </div>
        )
    }
}

export default SidebarView;