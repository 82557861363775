import * as _ from "lodash";
import {
  DELETE_GROUP,
  GET_AUTH,
  GET_AVAILABLE_COURSES,
  GET_AVAILABLE_SCHOOL_CLASSES,
  GET_GROUPS,
  GET_TEACHER_STATE,
  SAVE_GROUPS,
  SAVE_TEACHER_CLASSES,
  SAVE_TEACHER_COURSES,
  UPDATE_COURSE_STUDENTS,
  GET_AVAILABLE_RECIPIENTS,
  SET_STATUS,
  GET_STUDENTS_FROM_COURSE,
} from "../actions/Action_Teacher";
import { CourseCheckbox } from "../models/CourseCheckbox";
import { Course } from "../models/Course";
import { AssignmentGroup } from "../models/AssignmentGroup";
import { SchoolClass } from "../models/SchoolClass";
import { mapCoursesToCourseCheckboxes } from "../components/admin/courses/AdminCoursesView";
import { SchoolClassCheckbox } from "../models/SchoolClassCheckbox";

const jwtDecode = require("jwt-decode");

const defaultState = {
  availableCourses: {},
  availableSchoolClasses: {},
  subscribedCourses: {},
  subscribedSchoolClasses: {},
  schools: {},
  user: {},
};

const setAuth = (state, action) => {
  const token = action.payload.data;

  const authentication = {
    user: jwtDecode(token),
  };

  return _.assign({}, state, authentication);
};

const setAuthState = (state, action) => {
  if (!action.payload) {
    return state;
  }
  let teamscourses = {};
  const schoolClasses = action.payload.data[0];
  const courses = action.payload.data[1];
  const schools = action.payload.data[2];
  if (action.payload.data.length > 3) {
    teamscourses = action.payload.data[3];
  } else {
    teamscourses = { a: "285ed897-fca2-42c4-986a-e9fa265c7ec5-" };
  }
  const newState = {
    subscribedSchoolClasses: _.mapKeys(schoolClasses, "ClassID"),
    subscribedCourses: _.mapKeys(courses, "CourseID"),
    schools: _.mapKeys(schools, "SchoolId"),
    teamscourses: teamscourses,
  };

  return _.assign({}, state, newState);
};

const setAvailableCourses = (state, action) => {
  const courses: Course[] = action.payload.data.AvailablecoursesListed || [];

  const availableCoursesList: CourseCheckbox[] = courses.map(
    (c) => new CourseCheckbox(c.Title, c.CourseID, false, c.SchoolID)
  );

  const availableCoursesState = {
    availableCourses: _.mapKeys(availableCoursesList, "CourseID"),
  };

  return _.assign({}, state, availableCoursesState);
};

const setSavedTeacherCourses = (state, action) => {
  const updatedSavedCourses: Course[] = action.payload.data || [];

  const previousAvailableCourses: CourseCheckbox[] = _.values(
    state.availableCourses
  );
  const previousSubscribedCourses: CourseCheckbox[] = mapCoursesToCourseCheckboxes(
    _.values(state.subscribedCourses),
    false
  );

  const updatedAvailableCourses: CourseCheckbox[] = previousAvailableCourses
    .concat(previousSubscribedCourses)
    .filter(
      (availableCourse) =>
        !updatedSavedCourses.find(
          (savedCourse) => savedCourse.CourseID === availableCourse.CourseID
        )
    );

  const updatedCoursesState = {
    subscribedCourses: _.mapKeys(updatedSavedCourses, "CourseID"),
    availableCourses: _.mapKeys(updatedAvailableCourses, "CourseID"),
  };

  return _.assign({}, state, updatedCoursesState);
};

const setUpdatedCourseStudents = (state, action) => {
  const courseWithUpdatedStudents = action.payload;

  const updatedSubscribedCourses = state.subscribedCourses;

  updatedSubscribedCourses[courseWithUpdatedStudents.CourseID].Students =
    courseWithUpdatedStudents.Students;

  const updatedCourseStudentList = {
    subscribedCourses: updatedSubscribedCourses,
  };

  return _.assign({}, state, updatedCourseStudentList);
};

const setGroups = (state, action) => {
  const { CourseID, Students, Groups } = action.payload.data;

  const courseWithGroups: Course = {
    ...state.subscribedCourses[CourseID],
    Groups,
    Students,
  };

  return {
    ...state,
    subscribedCourses: {
      ...state.subscribedCourses,
      [CourseID]: courseWithGroups,
    },
  };
};

const setSavedGroups = (state, action) => {
  const responseAssignmentGroups = action.payload.data;

  if (!responseAssignmentGroups || !responseAssignmentGroups.length) {
    return state;
  }

  const courseId = responseAssignmentGroups[0].CourseID;
  const classId = responseAssignmentGroups[0].ClassId;
  if (courseId) {
    const course = state.subscribedCourses[courseId];
  
    const updatedGroups = _.mapKeys(course.Groups, "Id");
  
    responseAssignmentGroups.forEach((updatedGroup) => {
      updatedGroups[updatedGroup.Id] = updatedGroup;
    });
  
    const subscribedCourses = {
      ...state.subscribedCourses,
      [courseId]: {
        ...course,
        Groups: _.values(updatedGroups),
      },
    };
  
    return {
      ...state,
      subscribedCourses,
    };
  }
  else if (classId) {
    const schoolClass = state.subscribedSchoolClasses[classId];
  
    const updatedGroups = _.mapKeys(schoolClass.Groups, "Id");
  
    responseAssignmentGroups.forEach((updatedGroup) => {
      updatedGroups[updatedGroup.Id] = updatedGroup;
    });
  
    const subscribedSchoolClasses = {
      ...state.subscribedSchoolClasses,
      [classId]: {
        ...schoolClass,
        Groups: _.values(updatedGroups),
      },
    };
  
    return {
      ...state,
      subscribedSchoolClasses
    };
  }

};

const deleteGroup = (state, action) => {
  const deletedGroup = action.payload;
  
  if (deletedGroup.CourseID) {
    const groupCourses = state.subscribedCourses;
    groupCourses[deletedGroup.CourseID].Groups = groupCourses[
      deletedGroup.CourseID
    ].Groups.filter((g) => g.Id !== deletedGroup.Id);
  
    return {
      ...state,
      subscribedCourses: groupCourses,
    };

  }
  else if (deletedGroup.ClassId) {
    const groupClasses = state.subscribedSchoolClasses;
    groupClasses[deletedGroup.ClassId].Groups = groupClasses[
      deletedGroup.ClassId
    ].Groups.filter((g) => g.Id !== deletedGroup.Id);
  
    return {
      ...state,
      subscribedSchoolClasses: groupClasses,
    };
  }

};

const setAvailableSchoolClasses = (state, action) => {
  const availableSchoolClassesList: SchoolClass[] =
    action.payload.data.AvailablecoursesListed || [];

  const availableSchoolClasses: SchoolClassCheckbox[] = availableSchoolClassesList.map(
    (s) => new SchoolClassCheckbox(s.ClassName, s.ClassID, s.SchoolID, false)
  );

  const availableSchoolClassesState = {
    availableSchoolClasses: _.mapKeys(availableSchoolClasses, "ClassID"),
  };

  return _.assign({}, state, availableSchoolClassesState);
};

const setSavedTeacherClasses = (state, action) => {
  const savedClasses = action.payload.data;

  const availableSchoolClasses: SchoolClass[] = _.values(
    state.availableSchoolClasses
  )
    .concat(_.values(state.subscribedSchoolClasses))
    .filter(
      (schoolClass) => !_.find(savedClasses, { ClassID: schoolClass.ClassID })
    )
    .sort((a, b) => a.ClassName.localeCompare(b.ClassName));

  const updatedClassesState = {
    subscribedSchoolClasses: _.mapKeys(savedClasses, "ClassID"),
    availableSchoolClasses: _.mapKeys(availableSchoolClasses, "ClassID"),
  };

  return _.assign({}, state, updatedClassesState);
};

const setAvailableRecipients = (state, action) => {
  const rcps = action.payload.data;

  return { ...state, rcps };
};

const setStudentsFromCourse = (state, action) => {
  const studentsFromCourse = action.payload.data;
  return {...state, studentsFromCourse};
}
const setstatus = (state, action) => {
  const status = action.payload;

  return { ...state, status };
};

export default function (state = defaultState, action) {
  switch (action.type) {
    /****************************
                Authentication
         ****************************/
    case GET_AUTH:
      return setAuth(state, action);
    case GET_TEACHER_STATE:
      return setAuthState(state, action);

    /****************************
                    Courses
         ****************************/
    case GET_AVAILABLE_COURSES:
      return setAvailableCourses(state, action);
    case SAVE_TEACHER_COURSES:
      return setSavedTeacherCourses(state, action);
    case UPDATE_COURSE_STUDENTS:
      return setUpdatedCourseStudents(state, action);

    /****************************
                    Groups
         ****************************/
    case GET_GROUPS:
      return setGroups(state, action);
    case SAVE_GROUPS:
      return setSavedGroups(state, action);
    case DELETE_GROUP:
      return deleteGroup(state, action);

    /****************************
                SchoolClasses
         ****************************/
    case GET_AVAILABLE_SCHOOL_CLASSES:
      return setAvailableSchoolClasses(state, action);
    case SAVE_TEACHER_CLASSES:
      return setSavedTeacherClasses(state, action);

    /****************************
                Recipients
         ****************************/
    case GET_AVAILABLE_RECIPIENTS:
      return setAvailableRecipients(state, action);
    case SET_STATUS:
      return setstatus(state, action);
    case GET_STUDENTS_FROM_COURSE:
      return setStudentsFromCourse(state, action);
    default:
      return state;
  }
}
