import * as React from "react";
import { connect } from "react-redux";
import HeaderView from "../../view_containers/HeaderView";
import { Dropdown } from "office-ui-fabric-react";
import {
  getCurrentTaskElements,
  getLastTaskElements,
  getSavedTaskElements
} from "../../../actions/Action_Student";
import { getStudentState } from "../../../actions/Action_Student";
import "../../innleveringer/innlevering.scss";
import { CommandBarButton } from "office-ui-fabric-react";
import SidebarActionButtonList from "../../sidebar/SidebarActionButtonList";
import Taskitem from "./Taskitem";

export interface IInnleveringerStudentViewProps {
  student: any;
  tasks: any;
  getCurrentTaskElements(teacherState: any);
  getLastTaskElements(teacherState: any);
  getSavedTaskElements(teacherState: any);
  history: any;
}
export interface IInnleveringerStudentViewState {
  coursefilter: string;
  selectedStatus: number;
}

class InnleveringerStudentView extends React.Component<
  IInnleveringerStudentViewProps,
  IInnleveringerStudentViewState
> {
  componentDidMount() {
    this.props.getCurrentTaskElements(this.props.student);
  }
  constructor(props) {
    super(props);
    this.state = { coursefilter: "0", selectedStatus: 1 };
  }

  onSelectStatus(status) {
    const { key } = status;
    switch (key) {
      case 1:
        this.props.getCurrentTaskElements(this.props.student);
        break;
      case 2:
        this.props.getLastTaskElements(this.props.student);
        break;
      case 3:
        this.props.getSavedTaskElements(this.props.student);
        break;
      default:
    }
  }

  onSelectCourse = (course, val) => {
    const { key } = course;
    this.setState({ coursefilter: course.key });
  };

  render() {
    let fagoptions = [{ key: "0", text: "Alle" }];
    let dict = {};
    this.props.tasks.forEach(element => {
      if (element && element.WsTask && element.WsTask.CourseID && !dict[element.WsTask.CourseID]) {
        dict[element.WsTask.CourseID] = element;
        fagoptions.push({ key: element.WsTask.CourseID, text: element.WsTask.CourseName });
      }
    });
    return (
      <div>
        <SidebarActionButtonList>
          {/* <CommandBarButton onClick={() => this.props.history.push('/task/ny_innlevering/n')} className="sidenav-buttons" iconProps={{ iconName: 'Add' }} text="Lag ny innlevering">
                    </CommandBarButton> */}
        </SidebarActionButtonList>
        <div className="ms-Grid" id="TeacherTasks">
          <div className="container main">
            <HeaderView
              title="Innleveringer"
              description="Her finner du en oversikt over dine innleveringer"
              iconName="CompletedSolid"
            />
            <div className="ms-Grid-row topTool ms-bgColor-neutralLight">
              <div className="ms-Grid-col ms-sm12 ms-md4">
                <Dropdown
                  defaultSelectedKey={this.state.selectedStatus ? this.state.selectedStatus : 1}
                  placeHolder="Velg status"
                  label="Status"
                  id="selCourseStatus"
                  onChanged={status => {
                    const { key } = status;
                    this.setState({selectedStatus: +status.key});

                    switch (key) {
                      case 1:
                        this.props.getCurrentTaskElements(this.props.student);
                        break;
                      case 2:
                        this.props.getLastTaskElements(this.props.student);
                        break;
                      case 3:
                        this.props.getSavedTaskElements(this.props.student);
                        break;
                      default:
                    }
                  }}
                  options={[
                    { key: 0, text: "Velg status" },
                    { key: 1, text: "Frist fram i tid" },
                    { key: 2, text: "Frist siste 30 dager" },
                    { key: 3, text: "Mine lagrede" }
                  ]}
                />
              </div>
              <div className="ms-Grid-col ms-sm12 ms-md4">
                <Dropdown
                  placeHolder="Alle"
                  label="Fag"
                  id="selCourseSubject"
                  onChanged={this.onSelectCourse}
                  options={fagoptions}
                />
              </div>
            </div>

            <div className="ms-Grid-row main">
                {this.props.tasks.map(t => {
                  if (
                    t && t.WsTask && t.WsTask.CourseID && 
                    (this.state.coursefilter === "0" ||
                    this.state.coursefilter === t.WsTask.CourseID)
                  ) {
                    return (
                      <Taskitem
                        history={this.props.history}
                        task={t}
                      ></Taskitem>
                    );
                  }
                })}
                {/* <CourseContainer/>
                            <CourseContainer/>
                            <CourseContainer/> */}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  let t = [];
  if (state.StudentReducer.tasks) {
    t = state.StudentReducer.tasks;
  }
  return {
    student: state.StudentReducer.student,
    tasks: t
  };
}

export default connect(mapStateToProps, {
  getCurrentTaskElements,
  getLastTaskElements,
  getSavedTaskElements,
  getStudentState
})(InnleveringerStudentView);
