import * as _ from 'lodash';
import {
	GET_TEACHER_MYQUIZZES,
	GET_QUIZ_ELEMENT,
	GET_KMP_ELEMENTS,
	GET_KM_ELEMENTS,
	GET_QUIZ_QUESTIONS,
	SAVE_QUIZ_QUESTION,
	GET_COURSE_ASSIGNMENTGROUPS,
	GET_START_QUIZ,
	GET_GRADE_QUIZ,
	COPY_QUIZ_QUESTIONS,
	GET_GRADE_QUIZF,
	SAVE_ANSWERGRADING_QUIZ,
	SAVE_GRADING_QUIZ
} from '../actions/Action_Quiz';
import { merge } from 'lodash';
import { default as DateUtils } from './../utils/DateUtils';
import { convertArrayToObject } from '../utils/StudentQuizUtils';
const initialQuizState = {
	gradequiz: undefined,
	gradequizf: undefined,
	Quiz: undefined
};

export default function (state = initialQuizState, action) {
	const dateUtils = new DateUtils();

	switch (action.type) {
		case GET_TEACHER_MYQUIZZES:
			const myquizzes = action.payload.data;

			//return _.assign({}, portfolio);
			return { ...state, myquizzes: myquizzes };
		case GET_QUIZ_ELEMENT:
			const quizz = action.payload.data;

			//return _.assign({}, portfolio);
			return { ...state, quiz: quizz };

		case GET_TEACHER_MYQUIZZES:
			const quiz = action.payload.data;

			//return _.assign({}, portfolio);
			return { ...state, myquizzes: myquizzes };
		case GET_QUIZ_QUESTIONS:
			const quizquestions = action.payload.data;

			//return _.assign({}, portfolio);
			return { ...state, questions: quizquestions };
		case COPY_QUIZ_QUESTIONS:
			const quizquestionscopy = action.payload.data;

			//return _.assign({}, portfolio);
			return { ...state, questions: quizquestionscopy };
		case GET_KM_ELEMENTS:
			const kms = action.payload.data;

			return { ...state, kms };
		case GET_KMP_ELEMENTS:
			const lp = action.payload.data;

			return { ...state, lp };
		case GET_COURSE_ASSIGNMENTGROUPS:
			const ag = action.payload.data;

			return { ...state, ag };
		case GET_START_QUIZ:
			const takequiz = action.payload.data;

			return { ...state, takequiz };

		case GET_GRADE_QUIZ:
			const gradequiz = action.payload.data;
			let tempAtt = gradequiz.Attempts;
			tempAtt.forEach((att) => {
				delete att.Quiz;
			});
			const attempts = convertArrayToObject(tempAtt, 'Id');
			let tempQues = gradequiz.Questions;
			const questions = convertArrayToObject(tempQues, 'Id');

			const answerArr = [];
			gradequiz.Attempts.forEach((attempt) => {
				attempt.Answers.forEach((answer) => {
					answerArr.push(answer);
				});
			});
			const answers = convertArrayToObject(answerArr, 'Id');
			let Quiz = {
				...gradequiz,
				TotalMaxPoints: gradequiz.TotalMaxPoints,
				Attempts: attempts,
				Questions: questions,
				Answers: answers
			};
			return { ...state, Quiz };

		case GET_GRADE_QUIZF:
			const gradequizf = action.payload.data;

			return { ...state, gradequizf };

		case SAVE_ANSWERGRADING_QUIZ:
			if (!action.quizAttemptAnswer || !state.Quiz) return state;
			let answer: any = Object.values(state.Quiz.Answers).find((ans: any) => {
				return ans.Id === action.quizAttemptAnswer.Id;
			});
			answer.Points = action.points;

			const updateAnsPoints = {
				...state.Quiz
			};
			return { ...state, Quiz: updateAnsPoints };

		// case SAVE_ANSWERGRADING_QUIZ:
		// if (!action.quizAttemptAnswer || !state.gradequiz) return state;
		// //OPPDATER I GLOBAL STATE MER EFFEKTIVT
		// const updatedAnswerPoints = {
		// 	...state.gradequiz,
		// 	Attempts: state.gradequiz.Attempts.map((attempt) => {
		// 		if (attempt.Id === Number(action.quizAttemptAnswer.QAid)) {
		// 			return {
		// 				...attempt,
		// 				Answers: attempt.Answers.map((answer) => {
		// 					if (answer.Id === action.quizAttemptAnswer.Id) {
		// 						return { ...answer, Points: action.points };
		// 					}
		// 					return answer;
		// 				}),
		// 				Quiz: {
		// 					...attempt.Quiz,
		// 					Questions: attempt.Quiz.Questions.map((question) => {
		// 						if (question.Id === Number(action.quizAttemptAnswer.QuestionID)) {
		// 							return {
		// 								...question,
		// 								QuizAttemptAnswer: {
		// 									...question.QuizAttemptAnswer,
		// 									Points: action.points
		// 								}
		// 							};
		// 						}
		// 						return question;
		// 					})
		// 				}
		// 			};
		// 		}
		// 		return attempt;
		// 	})
		// };

		// return { ...state, gradequiz: updatedAnswerPoints };

		case SAVE_GRADING_QUIZ:
			const updatedGradeQuizAttempt = action.payload.data;

			if (!updatedGradeQuizAttempt || !state.Quiz) return state;
			console.log(Object.values(state.Quiz.Attempts));
			const updatedGradeQuiz = {
				...state.Quiz,
				Attempts: convertArrayToObject(
					Object.values(state.Quiz.Attempts).map((attempt: any) => {
						if (attempt.Id === updatedGradeQuizAttempt.Id) {
							return updatedGradeQuizAttempt;
						}
						return attempt;
					}),
					'Id'
				)
			};
			// const updated = convertArrayToObject(tempQues, 'Id');
			return { ...state, Quiz: updatedGradeQuiz };
		// case SAVE_GRADING_QUIZ:
		// 	console.log(action);
		// 	const updatedGradeQuizAttempt = action.payload.data;
		// 	if (!updatedGradeQuizAttempt || !state.gradequiz) return state;
		// 	const updatedGradeQuiz = {
		// 		...state.gradequiz,
		// 		Attempts: state.gradequiz.Attempts.map((attempt) => {
		// 			if (attempt.Id === updatedGradeQuizAttempt.Id) {
		// 				return updatedGradeQuizAttempt;
		// 			}
		// 			return attempt;
		// 		})
		// 	};

		// 	return { ...state, gradequiz: updatedGradeQuiz };
		default:
			// const alp = 'hu'
			// return { ...state, alp };
			return state;
	}
}
