import * as React from 'react';
import { connect } from 'react-redux';
import axios from 'axios';
import Select from 'react-select';
function mapStateToProps(state) {
    return {

    };
}
export interface ITestingProps {

}
export interface ITestingState {
    classes: any[];
    options: any[];
    assignments: any[];
    selectedclass: any;
    submissions: any[];
    assignment: any;
    members:any;
}
let mapping = {};
class testing extends React.Component<ITestingProps, ITestingState> {
    constructor(props) {
        super(props);
        this.state = { classes: [], options: [], assignments: [], selectedclass: null, submissions: [], assignment: null,members:{} };
    }
    componentDidMount() {
        const request = axios.get(`${'https://graph.microsoft.com/beta/education/classes'}`, {
            headers: {
                Authorization: localStorage.getItem('adal.access.token.keyhttps://graph.microsoft.com'),
            }
        }).then((response) => {
            const options = [];

            response.data.value.forEach((l) => {
                options.push({ value: l.id, label: l.displayName });
            });


            this.setState({ classes: response.data.value, options: options });
        });
    }
    render() {
        return (
            <div>
                <input type='button' onClick={() => {
                    let rcps = [];
                    rcps.push("698f9c7a-06de-49a0-8dd3-78f838262351");
                    let assign = {
                        "@odata.type": "#microsoft.education.assignments.api.educationAssignmentIndividualRecipient",
                        recipients: rcps
                    }
                    let newass = {
                        dueDateTime: "2014-02-01T00:00:00Z",
                        displayName: "Midterm 1",
                        instructions: {
                            contentType: "text",
                            content: "Read chapters 1 through 5"
                        },
                        grading: {

                            maxPoints: 100
                        },
                        assignTo: assign,
                        status: "draft",
                        allowStudentsToAddResourcesToSubmission: true
                    }
                    newass.grading['@odata.type'] = "#microsoft.education.assignments.api.educationAssignmentPointsGradeType";
                    newass.assignTo['@odata.type'] = "#microsoft.education.assignments.api.educationAssignmentClassRecipient";
                    const f = axios.post('https://graph.microsoft.com/beta/education/classes/' + this.state.selectedclass.value + '/assignments', newass, {
                        headers: {
                            Authorization: localStorage.getItem('adal.access.token.keyhttps://graph.microsoft.com'),
                        }
                    }).then((result) => {

                    });
                }} />

                {
                    this.state.classes &&

                    <Select

                        onChange={(selected) => {
                            const request = axios.get(`${'https://graph.microsoft.com/beta/education/classes/' + selected.value + '/assignments'}`, {
                                headers: {
                                    Authorization: localStorage.getItem('adal.access.token.keyhttps://graph.microsoft.com'),
                                }
                            }).then((response) => {
                                this.setState({ assignments: response.data.value, selectedclass: selected });
                                const re = axios.get(`${'https://graph.microsoft.com/beta/education/classes/' + selected.value + '/members'}`, {
                                    headers: {
                                        Authorization: localStorage.getItem('adal.access.token.keyhttps://graph.microsoft.com'),
                                    }
                                }).then((response) => {
                                   
                                    let a = {};
                                    response.data.value.forEach(user => {
                                        a[user.userPrincipalName] = user;
                                    });
                                    this.setState({ members:a});
                                });
                            });
                        }}
                        options={this.state.options}
                    />
                    

                }
                <ul>
                    {
                        this.state.assignments.map((c) => {
                            return (
                                <li onClick={() => {
                                    this.setState({ assignment: c });
                                    const request = axios.get(`${'https://graph.microsoft.com/beta/education/classes/' + this.state.selectedclass.value + '/assignments/' + c.id + '/submissions'}`, {
                                        headers: {
                                            Authorization: localStorage.getItem('adal.access.token.keyhttps://graph.microsoft.com'),
                                        }
                                    }).then((response) => {
                                        response.data.value.forEach(element => {
                                            const r = axios.get(`${'https://graph.microsoft.com/beta/education/users/' + element.submittedBy.user.id}`, {
                                                headers: {
                                                    Authorization: localStorage.getItem('adal.access.token.keyhttps://graph.microsoft.com'),
                                                }
                                            }).then((r) => {
                                                let tmp = this.state.submissions;
                                                tmp.push({ userdisplayname: r.data.displayName, status: element.status, resources: element.resources, parent: c.id, urkundstate: '-', id: r.data.id })
                                                this.setState({ submissions: tmp });

                                            });
                                        });
                                    });

                                }}>

                                    {c.description}
                                    {c.displayName}
                                    {c.id}
                                    {c.status}

                                </li>
                            )
                        })
                    }
                </ul>
                {
                    this.state.assignment &&
                    <div>
                        aktiv er {this.state.assignment.displayName} - {this.state.assignment.status}
                        {
                            this.state.assignment.status == 'draft' &&
                            <input type='button' value='publish' onClick={() => {
                                let ind = {};
                                const f = axios.post(`${'https://graph.microsoft.com/beta/education/classes/' + this.state.selectedclass.value + '/assignments/' + this.state.assignment.id + '/publish'}`, ind, {
                                    headers: {
                                        Authorization: localStorage.getItem('adal.access.token.keyhttps://graph.microsoft.com'),
                                    }
                                }).then((result) => {

                                })
                            }}></input>
                        }
                    </div>
                }
                <input type='button' value='Update' onClick={() => {
                    let ind = { Delivery: '1b5039f5-f0dc-4a5a-b5e3-6b1c0048a1a8' };

                    const f = axios.post(`https://localhost:44312/API/graphPlagCheck`, ind, {
                        headers: {
                            Authorization: localStorage.getItem('id_token'),
                        }
                    }).then((result) => {
                        let n = [];
                        this.state.submissions.forEach(sub => {
                            result.data.forEach(res => {
                                if (sub.id == res.UrkundRecipientId) {
                                    let a = sub;
                                    a.urkundstate = res.UrkundReportUrl;
                                    n.push(a);
                                }
                            });
                        });
                        this.setState({ submissions: n });

                    });
                }}></input>
                <ul>
                    {this.state.submissions.map((sub) => {
                        return (
                            <li>
                                {sub.userdisplayname}
                                {sub.status}
                                <a href={sub.urkundstate}>Rapport</a>
                                <ul>
                                    {
                                        sub.resources.map((r) => {

                                            return (
                                                <li >
                                                    {r.resource.displayName}
                                                    <input type='button' value='send inn' onClick={() => {
                                                        const ru = axios.get(`${r.resource.fileUrl}`, {
                                                            headers: {
                                                                Authorization: localStorage.getItem('adal.access.token.keyhttps://graph.microsoft.com'),
                                                            }
                                                        }).then((item) => {
                                                            let d = { WopiUrl: item.data['@microsoft.graph.downloadUrl'], FileUrlDelivery: item.data.id, FileNameDraft: item.data.name, Delivery: sub.parent, CssClass: sub.id };
                                                            const f = axios.post(`https://localhost:44312/API/graphSubmit`, d, {
                                                                headers: {
                                                                    Authorization: sessionStorage.getItem('adal.access.token.keyhttps://graph.microsoft.com'),
                                                                }
                                                            }).then((file) => {

                                                            });
                                                        });
                                                    }}></input>
                                                </li>
                                            )
                                        })
                                    }
                                </ul>
                            </li>
                        )
                    })}
                </ul>
            </div>
        );
    }
}

export default connect(
    mapStateToProps,
)(testing);