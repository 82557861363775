import axios from 'axios';
import {Message} from "../models/Message";
import { SP_HOST_URL, ROOT_URL } from './constants';
import * as _ from 'lodash';

export const GET_MESSAGES = 'GET_MESSAGES';
export const ARCHIVE_MESSAGE = 'ARCHIVE_MESSAGE';
export const DEARCHIVE_MESSAGE = "DEARCHIVE_MESSAGE";
export const CHECK_KVITTERING_MESSAGES = 'CHECK_KVITTERING_MESSAGES';

export function getMessages(statedata:any,) {
    const data:FormData = new FormData();
    data.set('classes', JSON.stringify(_.values(statedata.subscribedSchoolClasses)));
    data.set('schools', JSON.stringify(_.values(statedata.schools)));
    data.set('courses', JSON.stringify(_.values(statedata.subscribedCourses)));
    //TODO: change API url
    const request = axios.post(`${ROOT_URL}/GetMessages/${SP_HOST_URL}`, data,{
        headers: {
            Authorization: localStorage.getItem('id_token')
        }
    });

    return{
        type: GET_MESSAGES,
        payload: request
    }
}

export function archiveMessage(message:Message, callback:()=>void) {

    const request = axios.post(`${ROOT_URL}/V2ArchiveMessage/${SP_HOST_URL}`, message,{
        headers: {
            Authorization: localStorage.getItem('id_token')
        }
    });

    request.then(()=>callback());

    return {
        type: ARCHIVE_MESSAGE,
        payload: message
    }
}

export function dearchiveMessage(message: Message, callback: () => void) {
    const request = axios.post(`${ROOT_URL}/DearchiveMessage/${SP_HOST_URL}`, message, {
        headers: {
            Authorization: localStorage.getItem("id_token")
        }
    });

    request.then(() => callback());

    return {
        type: DEARCHIVE_MESSAGE,
        payload: message
    }
}

export function checkKvitteringMessages(messages: Message[]) {
    let kopi = JSON.parse(JSON.stringify(messages));
    kopi.forEach( message => {
        message.Text = "";
        message.TextRaw = "";
        message.Recipients = "";
        if(message.Recipients.includes("<i class='fa fa-users' aria-hidden='true'></i>")){
            message.Recipients = message.Recipients.replace("<i class='fa fa-users' aria-hidden='true'></i>", "");
        }
    })

    let melding = JSON.stringify(kopi);
    const data: FormData = new FormData();
    data.set("Messages", melding);

    const request = axios.post(`${ROOT_URL}/PostKvitteringStatus/${SP_HOST_URL}`, data, {
        headers: {
            Authorization: localStorage.getItem('id_token'),
            //'Content-Type': 'application/json',
        }
    });

    return {
        type: CHECK_KVITTERING_MESSAGES,
        payload: request
    }
}