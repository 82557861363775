import * as React from "react";
import {Dropdown, Label} from "office-ui-fabric-react";


interface ISelectFieldProps{
    label: string;
    input: any;
    options?:any[]
    meta: {
        touched: boolean;
        error: string;
    },
    placeholder?:string;
    defaultValue?:any;
    required?:boolean;
    disabled?:boolean;
    children:any;
    onSelect?:(key:any)=>void;
}


const SelectFieldNoStyling = (props:ISelectFieldProps) =>{

    const {
        label,
        input,
        required,
        disabled,
        onSelect,
        defaultValue,
        placeholder,
        options,
        meta: {
            touched,
            error
        },
        children
    } = props;

    const dropdownOptions = options || children.props.children;

    const handleChange = (item) =>{
        input.onChange(item.key, item.value);

        if (onSelect){
            onSelect(item.key);
        }
    };

    return(
       
                    <Dropdown
                        {...input}
                        options={dropdownOptions}
                        selectedKey={input.value}
                        placeHolder={placeholder}
                        defaultValue={defaultValue || null}
                        required={required || false}
                        disabled={disabled || false}
                        errorMessage={touched ? error : ''}
                        onChanged={(item) => handleChange(item)}
                    />
              
    )
};

export default SelectFieldNoStyling;