import * as React from "react";
import IupClassReportItemView from "./IupClassReportItemView";
import IupCourseReportItemView from "./IupCourseReportItemView";
import { ActionButton, Spinner,SpinnerSize } from "office-ui-fabric-react";
import * as moment from "moment";
export const LOCALE = "nb";
export const DATE_FORMAT = "DD.MM.YYYY";
export const TIME_FORMAT = "LT";
import {
  getIupReportStudentForCourse,
  getIupReportForCourse,
  publishAllStudentForCourse
} from "../../actions/Action_Iup";
import { connect } from "react-redux";
export interface IIupCourseReportViewProps {
  iupCourseReport: any;
  studentreport: any;
  getIupReportStudentForCourse(iup: any);
  publishAllStudentForCourse(iup: any);
  getIupReportForCourse(iup: any);
  setIupFormStatus(isDirty: boolean);
}
export interface IIupCourseReportViewState {
  activestudent: any;
  loadingstudent: string;
  formIsDirty: boolean;
}
class IupCourseReportView extends React.Component<
  IIupCourseReportViewProps,
  IIupCourseReportViewState
> {
  constructor(props) {
    super(props);
    this.state = { activestudent: null, loadingstudent: "", formIsDirty: false };
  }

  setFormStatus = (isDirty: boolean) => {
    this.setState({formIsDirty: isDirty}, () => {
      this.props.setIupFormStatus(this.state.formIsDirty);
    });
  }

  componentWillReceiveProps (nextprops: IIupCourseReportViewProps) {
    if (nextprops.iupCourseReport.Id != this.props.iupCourseReport.Id) {
      this.setState({formIsDirty: false});
    }
  }

  public render() {
    return (
      <div className="ms-Grid-row" id="iup-student-info">
        <div className="ms-Grid-row">
          <h4>
            {this.props.iupCourseReport.Name} |{" "}
            <span className="text-muted">
              {" "}
              Fra:{" "}
              {moment(this.props.iupCourseReport.From)
                .locale(LOCALE)
                .format(DATE_FORMAT)}{" "}
              Til:{" "}
              {moment(this.props.iupCourseReport.To)
                .locale(LOCALE)
                .format(DATE_FORMAT)}{" "}
            </span>
          </h4>
        </div>

        <div className="ms-Grid-row content-row">
          <div className="ms-Grid-col ms-sm12 ms-lg4 students-column">
            <h4>Elever</h4>
            <ul className="iup-students">
              {this.props.iupCourseReport &&
                this.props.iupCourseReport.Students.map(student => {
                  let classn = "";
                  if (
                    this.state.activestudent &&
                    this.state.activestudent.Id === student.Id &&
                    this.props.iupCourseReport.Id.toString() ===
                      this.state.activestudent.CourseReport.Iupheaderid
                  ) {
                    classn = "selected";
                  }

                  if (this.state.activestudent) {

                  }

                  return (
                    <li
                      className={`load-student-report ${classn}`}
                      onClick={() => {
                        let saveCourseConfirmation = this.state.formIsDirty ? confirm("Du har ulagrede endringer, er du sikker på at du vil bytte elev?") : true;
                        if (saveCourseConfirmation) {
                          this.setState({
                            activestudent: student,
                            loadingstudent: student.LoginName
                          });
                          this.props
                            .getIupReportStudentForCourse(student.CourseReport)
                            .then(() => {
                              this.setState({
                                loadingstudent: ''
                              });
                            });
                        }

                      }}
                    >
                     
                      {student.HasPublishedReport && (
                        <div className="iup-student-haspublished-report pubicon-@student.LoginName.Split('@')[0]">
                          <i
                            className="ms-Icon ms-Icon--BoxCheckmarkSolid green"
                            aria-hidden="true"
                          ></i>
                          <span className="iup-student-name">
                            {student.Name}
                          </span>
                          <div>Rapport er publisert</div>
                        </div>
                      )}
                      {!student.HasPublishedReport && student.Hasreport && (
                        <div className="iup-student-hasnotpublished-report pubicon-@student.LoginName.Split('@')[0]">
                          <i className="fas fa-pen-square blue"></i>
                          <span className="iup-student-name">
                            {student.Name}
                          </span>
                          <div>Rapport er ikke publisert </div>
                        </div>
                      )}
                      {!student.HasPublishedReport && !student.Hasreport && (
                        <div className="iup-student-hasnotpublished-report pubicon-@student.LoginName.Split('@')[0]">
                          <i className="fas fa-pen-square orange"></i>
                          <span className="iup-student-name">
                            {student.Name}
                          </span>
                          <div>Rapport er ikke påstartet</div>
                        </div>
                      )}
                       {this.state.loadingstudent === student.LoginName && (
                        <Spinner size={SpinnerSize.large} ariaLive="assertive" />
                      )}
                    </li>
                  );
                })}
            </ul>
            <ActionButton
              onClick={() => {
                this.props
                  .publishAllStudentForCourse({
                    Id: this.props.iupCourseReport.Id
                  })
                  .then(() => {
                    this.props.getIupReportForCourse({
                      Id: this.props.iupCourseReport.Id
                    });
                  });
              }}
              iconProps={{ iconName: "WebPublish" }}
              text="Publiser alle rapporter"
              styles={{
                root: {
                  float: "right"
                }
              }}
            />

            <div id="publishspinner"></div>
          </div>
          <div className="ms-Grid-col ms-sm12 ms-lg8">
            <div id="studentreport">
              {this.props.studentreport && (
                <div>
                  <IupCourseReportItemView
                    item={this.props.studentreport}
                    setFormStatus={this.setFormStatus}
                  ></IupCourseReportItemView>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}
function mapStateToProps(state) {
  return {
    studentreport: state.Iup.iupstudentcoursereport
  };
}

export default connect(mapStateToProps, {
  getIupReportStudentForCourse,
  getIupReportForCourse,
  publishAllStudentForCourse
})(IupCourseReportView);
