
export interface IGroupComparison {
    courseId: string;
    groupId: string
}

export interface IRecipient {
    label: string;
    value: string;
}

export const isSameGroupAsBefore = (current: IGroupComparison, initial: IGroupComparison): boolean => {
    if(current.courseId == initial.courseId && current.groupId == initial.groupId) {
        return true;
    }
    return false;
}

export const isSameRecipientsAsBefore = (recipients: string | IRecipient[], initialRecipients: string | IRecipient[]): boolean => {
    if(isString(recipients)) {
        if(isString(initialRecipients)) {
            return recipients === initialRecipients;
        }
        else if (initialRecipients.length === 1) {
            initialRecipients.forEach(initRecipient => {
                if (initRecipient.value.indexOf(recipients.replace(";", "")) > -1) {
                    return true;
                }
            });
            return false;
        }
        else {
            return false
        }
    }
    else {
        if(isString(initialRecipients)) {
            let initialRcpList = initialRecipients.trim().split(";").filter(i => i);
            if (recipients.length != initialRecipients.length) {
                return false
            }

            initialRcpList.forEach(rcp => {
                let hasRcp = false;

                recipients.forEach(recipient => {
                    if (recipient.value.indexOf(rcp) > -1) {
                        hasRcp = true;
                    }
                });

                if (hasRcp == false) {
                    return false;
                }
            })
            return true;
        }
        else {
            if (recipients.length === initialRecipients.length) {
                recipients.forEach(recipient => {
                    if(!initialRecipients.find(initRecipient => initRecipient.value == recipient.value)) {
                        return false;
                    }
                    return true;
                });
            }
            else {
                return false;
            }
        }
    }
}

function isString(value): value is string {
	return typeof value === 'string' || value instanceof String;
}
