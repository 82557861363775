import * as React from 'react';


export default class ButtonRow extends React.Component {

    constructor(props){
        super(props);
    }

    render(){
        return(
            <div className="ms-Grid button-row">
                <div className="ms-Grid-row">
                    {this.props.children}
                </div>
            </div>
        )
    }
}