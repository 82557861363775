import * as _ from 'lodash';
import {
    GET_CURRENT_TASKS,
    GET_LAST_TASKS,
    GET_STUDENT_TASK,
    GET_TASK,
    GET_SAVED_TASKS,
    GET_STUDENT_FILES,
    SAVE_STUDENT_EVAL,
    SAVE_STUDENT_DEADLINE,
    PLAGCHECK_TASK,
    TEACHERSUBMIT_STUDENT_TASK,
    INIT_SUBMISSIONTEACHER_STATUS,
    CHECK_SUBMISSIONTEACHER_STATUS,
    DELETE_STUDENT_TASK,
    GET_TASKS_FOR_GRADING
} from "../actions/Action_Innleveringer";
const statestructure = {
    Tasks: {}
}
export default function (state = statestructure, action) {

    switch (action.type) {

        case GET_CURRENT_TASKS:

            const tasks = action.payload.data;


            // const newState = {
            //     Tasks: tasks,
            //     mode:1
            // }

            return {...state, Tasks: tasks, mode: 1}

        case GET_TASKS_FOR_GRADING:
            return _.assign({}, {Tasks: action.payload.data, mode: 4})
        case GET_SAVED_TASKS:
            const taskssaved = action.payload.data;
            const newStatesaved = {
                Tasks: taskssaved,
                mode:3
            }

            return _.assign({}, newStatesaved);
        case GET_LAST_TASKS:
            const taskslast = action.payload.data;
            const newStatelast = {
                Tasks: taskslast,
                mode:2
            }

            return _.assign({}, newStatelast);
        case GET_STUDENT_TASK:
            const studenttask = action.payload.data;

            

            return {...state, studenttask}
        case GET_STUDENT_FILES:
            const studentfiles = action.payload.data;
            const newStatestudentfiles = {
                studentfiles: studentfiles
            }
            // return _.assign({}, newStatestudentfiles);
            return { ...state, newStatestudentfiles };
        case SAVE_STUDENT_EVAL:
            const savestatus = action.payload.data;
            const newStatesavestatud = {
                savestatus: savestatus
            }
            return _.assign({}, newStatesavestatud);
            case SAVE_STUDENT_DEADLINE:
            const savedeadline = action.payload.data;
            const newStatesavedeadline = {
                savedeadline: savedeadline
            }
           
                return _.assign({}, newStatesavedeadline);
        case GET_TASK:
            const task = action.payload.data;
            const newStatetask = {
                task: task
            }
            return _.assign({...state}, newStatetask);
           // return _.assign({}, newStatetask);
        case PLAGCHECK_TASK:
                const plagcheck = action.payload.data;
                case CHECK_SUBMISSIONTEACHER_STATUS:
                    const progress = action.payload.data;
                    return { ...state, progress: progress };
                  case INIT_SUBMISSIONTEACHER_STATUS:
                    const initprogress = action.payload;
                    return { ...state, progress: initprogress };
                  case TEACHERSUBMIT_STUDENT_TASK:
                    const studenttasksubmitted = action.payload.data;
                    return { ...state, studenttasksubmitted: studenttasksubmitted };
                
      return { ...state, plagcheck: plagcheck };
      case DELETE_STUDENT_TASK:
          const deletedTask = action.payload.data;

          return state;
            
        default:
            return state;
    }
}