import {
  GET_STUDENT_STATE,
  GET_UKEPLAN_ELEMENTS,
  GET_STUDENTCURRENT_TASKS,
  GET_STUDENTLAST_TASKS,
  GET_STUDENTSAVED_TASKS,
  GET_STUDENTSCHEDULE_ELEMENTS,
  GET_STUDENT_PORTFOLIOSTUDENT,
  GET_STUDENT_TASKDELIVER,
  SUBMIT_STUDENT_TASK,
  GET_STUDENT_QUIZ_ATTEMPT,
  SAVE_QUIZ_ANSWER,
  GET_STUDENT_QUIZZES,
  INIT_SUBMISSION_STATUS,
  CHECK_SUBMISSION_STATUS,
  GET_UKEPLAN_CHECKS,
  GET_STUDENT_IUP,
  GET_STUDENT_IUPLIST,
  GET_STUDENTNEWS_FILES,
  GET_STUDENT_QUIZ_ATTEMPTGRADE
} from "../actions/Action_Student";
import { IStudent } from "../models/student/IStudent";
import {
  mapStudentQuizAttemptDtoToObject,
  mapStudentQuizzesDtoToObject,
} from "../utils/StudentQuizUtils";
import { IStudentQuizAttempt } from "../models/student/IStudentQuizAttempt";
import DateUtils from "../utils/DateUtils";

interface IStudentState {
  student: IStudent;
  studenttask: {};
}

const defaultState: IStudentState = {
  student: {
    student: {},
    ukeplan: {},
    tasks: {},
    scheduleElements: {},
    portfolioitems: {},
    studenttask: {},
    quizzes: [],
    quizAttempts: [],
    quizAttemptGrades:[],
  },
  studenttask: {},
};

const setStudentQuizzes = (state: IStudentState, action): IStudentState => {
  const quizzes = action.payload.data;

  if (!quizzes) {
    return state;
  }

  return {
    ...state,
    student: {
      ...state.student,
      quizzes: mapStudentQuizzesDtoToObject(quizzes),
    },
  };
};

const setStudentQuizAttempt = (state: IStudentState, action): IStudentState => {
  if (action.payload.data) {
    const quizAttempt: IStudentQuizAttempt = mapStudentQuizAttemptDtoToObject(
      action.payload.data
    );

    let quizAttempts: IStudentQuizAttempt[] = state.student.quizAttempts;
    if (!quizAttempts) {
      quizAttempts = [];
    }
    quizAttempts = [];
    quizAttempts.push(quizAttempt);

    return {
      ...state,
      student: {
        ...state.student,
        quizAttempts,
      },
    };
  } else {
    return state;
  }
};
const setStudentQuizAttemptGrade = (state: IStudentState, action): IStudentState => {
  if (action.payload.data) {
    

    let quizAttemptGrades: any = action.payload.data;
   

    return {
      ...state,
      student: {
        ...state.student,
        quizAttemptGrades,
      },
    };
  } else {
    return state;
  }
};

const saveStudentQuizAnswer = (state: IStudentState, action): IStudentState => {
  console.log("ACTION", action);

  return state;
};

const getStudentScheduleElements = (state, action) => {
  const dateUtils = new DateUtils();

  const scheduleElements = action.payload.data;

  // If there are no ScheduleElements in response, we return the current state
  if (!action.payload.data.length) {
    return {
      ...state,
      scheduleElements: [],
    };
  }

  // Converting DateTime strings to Date objects for fetched ScheduleElements
  scheduleElements.forEach((se) => {
    se.Start = dateUtils.dateTimeStringToDate(se.Start);
    se.End = dateUtils.dateTimeStringToDate(se.End);
  });

  // Mapping list of objects by key for scalability and faster lookups in detail view.
  // State collects all fetched ScheduleElements from all previous requests.
  return { ...state, scheduleElements };
};

export default function (state = defaultState, action) {
  switch (action.type) {
    case GET_STUDENT_STATE:
      const studentstate = action.payload.data;
      return { ...state, student: studentstate };
    case GET_UKEPLAN_ELEMENTS:
      const ukeplan = action.payload.data;
      return { ...state, ukeplan: ukeplan };
    case GET_STUDENTCURRENT_TASKS:
      const tasks = action.payload.data;
      return { ...state, tasks: tasks };
    case GET_STUDENTLAST_TASKS:
      const taskslast = action.payload.data;
      return { ...state, tasks: taskslast };
    case GET_STUDENTSAVED_TASKS:
      const taskssaved = action.payload.data;
      return { ...state, tasks: taskssaved };
    case GET_STUDENTSCHEDULE_ELEMENTS:
      return getStudentScheduleElements(state, action);
    case GET_STUDENT_PORTFOLIOSTUDENT:
      const portfolioitems = action.payload.data;
      return { ...state, portfolioitems: portfolioitems.PortItems };
    case GET_STUDENT_TASKDELIVER:
      const studenttask = action.payload.data;
      return { ...state, studenttask: studenttask };
    // case SAVE_STUDENT_LINK:
    //   const studenttasklinks = action.payload.data;
    //   return { ...state.studenttask, Links: studenttasklinks.Links };

    case SUBMIT_STUDENT_TASK:
      const studenttasksubmitted = action.payload.data;
      return { ...state, studenttasksubmitted: studenttasksubmitted };
    case GET_STUDENT_IUPLIST:
      const iup = action.payload.data;
      return { ...state, iup: iup };
    case GET_STUDENT_IUP:
      const iupreport = action.payload.data;
      return { ...state, iupreport: iupreport };
    case GET_UKEPLAN_CHECKS:
      const checks = action.payload.data;
      return { ...state, checks: checks };
    case CHECK_SUBMISSION_STATUS:
      const progress = action.payload.data;
      return { ...state, progress: progress };
    case INIT_SUBMISSION_STATUS:
      const initprogress = action.payload;
      return { ...state, progress: initprogress };
    case GET_STUDENT_QUIZZES:
      return setStudentQuizzes(state, action);
    case GET_STUDENT_QUIZ_ATTEMPT:
      return setStudentQuizAttempt(state, action);
      case GET_STUDENT_QUIZ_ATTEMPTGRADE:
        return setStudentQuizAttemptGrade(state, action);
      
    case SAVE_QUIZ_ANSWER:
      return saveStudentQuizAnswer(state, action);
    case GET_STUDENTNEWS_FILES:
      const NewsFiles = action.payload.data;

      return { ...state, NewsFiles: NewsFiles };
    default:
      return state;
  }
}
