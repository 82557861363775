import * as React from 'react'
import {
    Accordion,
    AccordionItem,
    AccordionItemTitle,
    AccordionItemBody
} from "react-accessible-accordion";
import { DefaultButton, Panel, PanelType, PrimaryButton, Stack } from 'office-ui-fabric-react';
import MyElementsListItem from './MyElementsListItem';
import { WsElement } from '../../models/WsElement';
import "../QuillStyles.scss";
import "./MyElements.scss"

interface IMyElementsListProps {
    elementDict: any;
    history: any;
}

interface IMyElementsListState {
    showElementPanel: boolean;
    selectedElement: WsElement;
}

class MyElementsList extends React.Component<IMyElementsListProps, IMyElementsListState> {
    constructor(props){
        super(props);

        this.state = {
            showElementPanel: false,
            selectedElement: null
        };
    }

    /****************************
            Helper methods
     ****************************/ 
    
    toggleElementPanel= (elementToSet = null) => {
        if(elementToSet != null){
            this.setState({
                showElementPanel: !this.state.showElementPanel,
                selectedElement: elementToSet
            })
        }
        else{
            this.setState({
                showElementPanel: !this.state.showElementPanel
            });
        }
    }

    elementListSchoolYearSorter(a, b) {
        if(a.schoolYear < b.schoolYear){
            return -1;
        }
        if(a.schoolYear > b.schoolYear){
            return 1;
        }
        return 0;
    }

    quillRemover(textToCleanse: string) {
        while(textToCleanse.includes("<p>") || textToCleanse.includes("</p>") || textToCleanse.includes("<br>")){
            textToCleanse = textToCleanse.replace("<p>", "");
            textToCleanse = textToCleanse.replace("</p>", "");
            textToCleanse = textToCleanse.replace("<br>", "");
        }
        return textToCleanse;
    }

    /****************************
            Render methods
     ****************************/

    getPanelContent = (elementType: string) => {
        switch (elementType) {
            case "task":
                return this.renderTaskElementPanel();
            case "text":
                return this.renderTextElementPanel();
            case "quiz":
                return this.renderQuizElementPanel();
            default:
                return "";
        }
    }

    onRenderFooterContent = () => {
        return (
            <div>
                <DefaultButton
                    style={{marginRight: "10px"}}
                    text='Lukk'
                    onClick={() => {
                      this.toggleElementPanel();
                    }}>
                </DefaultButton>
                {this.renderCopyButton()}
            </div>
          );
    }

    renderCopyButton = () => {
        switch (this.state.selectedElement.ElementType) {
            case "task":
                
                return <PrimaryButton
                            text='Kopier til ny innlevering'
                            onClick={() => {
                                this.props.history.push(`task/${this.state.selectedElement.Id}/c`)
                            }}>
                        </PrimaryButton>
            case "text":
                return <PrimaryButton
                            text='Kopier til ny plan'
                            onClick={() => {
                                this.props.history.push(`ukeplan/${this.state.selectedElement.Id}/c`)
                            }}>
                        </PrimaryButton>
            case "quiz": 
                return <span>
                        <PrimaryButton
                                style={{marginRight: "10px"}}
                                text='Gå til prøve'
                                onClick={() => {
                                    this.props.history.push(`quiz/${this.state.selectedElement.Id}`)
                                }}>
                        </PrimaryButton>
                        <PrimaryButton
                                text='Kopier til ny prøve'
                                onClick={() => {
                                    this.props.history.push(`quiz/${this.state.selectedElement.Id}/kopier`)
                                }}>
                            </PrimaryButton>
                    </span>

            default:
                return <span></span>;
        }
    }

    renderTaskElementPanel() {
        let elementToRender = this.state.selectedElement;
        return (
            <div>
                <div className="panel-list-item">
                    <span className="panel-list-title">Fag: </span>
                    <span>{elementToRender.CourseName}</span>
                </div>

                <div className="panel-list-item">
                    <span className="panel-list-title">Uke: </span>
                    <span>{elementToRender.Week}</span>
                </div>

                <div className="panel-list-item">
                    <span className="panel-list-title">År: </span>
                    <span>{elementToRender.Year}</span>
                </div>

                <div className="panel-list-item">
                    <span className="panel-list-title">Original Frist: </span>
                    <span>{elementToRender.Deadlinetekst}</span>
                </div>

                <div className="panel-list-item">
                    <span className="panel-list-title">Målsetning: </span>
                    <span className="remove-para-margin ql-editor" style={{backgroundColor: "transparent"}}
                        dangerouslySetInnerHTML={{__html: elementToRender.Goal}}>
                    </span>
                </div>

                <div className="panel-list-item">    
                    <span className="panel-list-title">Beskrivelse: </span>
                    <span className="remove-para-margin ql-editor" style={{backgroundColor: "transparent"}}
                        dangerouslySetInnerHTML={{__html: elementToRender.Text}}>
                    </span>
                </div>
                <div className="panel-list-item">
                    <span className="panel-list-title">Kompetansemål:</span>
                    <ul>
                        {elementToRender.GrepCodeList.map((grepCode) => {
                            return <li className="kmp-list-item">{`${grepCode.LPID} ${grepCode.KMID} ${grepCode.GrepTekst}`}</li>
                        })}
                    </ul>
                </div>
            </div>
        )
    }

    renderTextElementPanel() {
        let elementToRender = this.state.selectedElement;

        return (
            <div>
                <div className="panel-list-item">
                    <span className="panel-list-title">Fag: </span>
                    <span>{elementToRender.CourseName}</span>
                </div>

                <div className="panel-list-item">
                    <span className="panel-list-title">Uke: </span>
                    <span>{elementToRender.Week}</span>
                </div>

                <div className="panel-list-item">
                    <span className="panel-list-title">År: </span>
                    <span>{elementToRender.Year}</span>
                </div>
                <div className="panel-list-item">
                    <span className="panel-list-title">Vist fram til: </span>
                    <span>{elementToRender.DateText}</span>
                </div>

                <div className="panel-list-item">
                    <span className="panel-list-title">Målsetning: </span>
                    <span className="remove-para-margin ql-editor" style={{backgroundColor: "transparent"}}
                        dangerouslySetInnerHTML={{__html: elementToRender.Goal}}>
                    </span>
                </div>

                <div className="panel-list-item">    
                    <span className="panel-list-title">Beskrivelse: </span>
                    <span className="remove-para-margin ql-editor" style={{backgroundColor: "transparent"}}
                        dangerouslySetInnerHTML={{__html: elementToRender.Text}}>
                    </span>
                </div>
                <div className="panel-list-item">
                    <span className="panel-list-title">Kompetansemål:</span>
                    <ul>
                        {elementToRender.GrepCodeList.map((grepCode) => {
                            return <li className="kmp-list-item">{`${grepCode.LPID} ${grepCode.KMID} ${grepCode.GrepTekst}`}</li>
                        })}
                    </ul>
                </div>
            </div>
        )
    }

    renderQuizElementPanel() {
        let elementToRender = this.state.selectedElement;

        return (
            <div>
                <div className="panel-list-item">
                    <span className="panel-list-title">Fag: </span>
                    <span>{elementToRender.CourseName}</span>
                </div>

                <div className="panel-list-item">
                    <span className="panel-list-title">Uke: </span>
                    <span>{elementToRender.Week}</span>
                </div>

                <div className="panel-list-item">
                    <span className="panel-list-title">År: </span>
                    <span>{elementToRender.Year}</span>
                </div>
                <div className="panel-list-item">
                    <span className="panel-list-title">Frist: </span>
                    <span>{elementToRender.Deadlinetekst}</span>
                </div>
                <div className="panel-list-item">
                    <span className="panel-list-title">Kompetansemål:</span>
                    <ul>
                        {elementToRender.GrepCodeList.map((grepCode) => {
                            return <li className="kmp-list-item">{`${grepCode.LPID} ${grepCode.KMID} ${grepCode.GrepTekst}`}</li>
                        })}
                    </ul>
                </div>
            </div>
        )
    }

    render() {

        let elementArray = []

        for(var key in this.props.elementDict){
            let elementObj = {
                listOfElements: this.props.elementDict[key],
                schoolYear: key 
            }
            elementArray.push(elementObj);
        }

        elementArray = elementArray.sort(this.elementListSchoolYearSorter);

        return (
            <div>
                <Accordion>
                    {(elementArray && elementArray.length > 0) && 
                        <div>
                            
                            {elementArray.map(listElement => {
                                
                                return (
                                    <AccordionItem>
                                        <AccordionItemTitle>
                                            <div>{listElement.schoolYear}</div>
                                        </AccordionItemTitle>
                                        <AccordionItemBody>
                                            <Stack horizontal wrap horizontalAlign="start">
                                                {listElement.listOfElements.map((element) => {
                                                    return (
                                                        <div onClick={() => {this.toggleElementPanel(element)}}>
                                                            <MyElementsListItem teacherElement={element}></MyElementsListItem>
                                                        </div>
                                                    )
                                                })}
                                            </Stack>
                                        </AccordionItemBody>
                                    </AccordionItem>
                                )
                            })}
                        </div>
                    }
                </Accordion>
                <Panel
                    isLightDismiss
                    isOpen={this.state.showElementPanel}
                    type={PanelType.medium}
                    onDismiss={() => {
                      this.setState({ showElementPanel: false });
                    }}
                    headerText={this.state.selectedElement ? this.state.selectedElement.Name : ""}
                    closeButtonAriaLabel='Lukk'
                    onRenderFooterContent={this.onRenderFooterContent}
                >
                   {this.state.selectedElement && 
                        <div>
                            {/* {this.state.selectedElement.ElementType === "task" && 
                            <div>
                                {this.renderTaskElementPanel()}
                            </div>}
                            {this.state.selectedElement.ElementType === "text" && 
                            <div>
                                {this.renderTextElementPanel()}
                            </div>} */}
                            {this.getPanelContent(this.state.selectedElement.ElementType)}

                        </div>
                    }
                </Panel>
            </div>
        )
    }
}


export default MyElementsList;
