import * as React from "react";
import { Icon, ActionButton } from "office-ui-fabric-react";
import ReactTooltip from 'react-tooltip';
import * as moment from "moment";
import "react-accessible-accordion/dist/fancy-example.css";
import { getAssignment } from "../../../utils/GraphEduService";
moment.locale("nb");
export interface ITaskitemVIewProps {
  task: any;
  history: any;
}

class Taskitem extends React.Component<ITaskitemVIewProps> {
  constructor(props) {
    super(props);
  }


  getEditButtonStatus = (): string => {
    if (this.props.task.DeliveryStatus == 1 && (moment().isBefore(moment(this.props.task.Deadline)) || this.props.task.WsTask.DeliverAfterDeadline)) {
      return "Besvar oppgave"
    }
    else if (this.props.task.WsTask.KeepOpen && this.props.task.DeliveryStatus != 1 && this.props.task.DeliveryStatus != 2 && (moment().isBefore(moment(this.props.task.Deadline)))) {
      return "Lever ny versjon"
    }
    else {
      return "Se oppgave"
    }
  }

  hasTaskStarted = () => {
    return !this.props.task.WsTask.Starttime || (this.props.task.WsTask.Starttime && moment(this.props.task.WsTask.Starttime).valueOf() <= moment().valueOf());
  }

  render() {
    // if(this.props.task.WsTask.TeamsTask && this.props.task.WsTask.TeamsTask.length>0)
    // {
    //   return(<></>);
    // }
    return (
      <div
        className="course-container panel-default oppgaveStatus mineOppgaver @Html.DisplayFor(modelItem => item.WsTask.Course.CourseCategory)"
        data-courseid="@item.WsTask.Course.Id"
        data-coursename="@item.WsTask.Course.Title"
        id={this.props.task.WsTask.Course.CourseCategory}
      >
        <div className="panel-body bg-rod">
          <div className="ms-Grid-row">
            <div className="ms-Grid-col ms-sm5 ms-xxl5">
              <h2 className="courseName">
                <span style={{ fontWeight: 'bolder' }}> {this.props.task.WsTask.CourseName}: </span>
                <span style={{ marginLeft: 5 }}> {this.props.task.WsTask.Name}</span>
              </h2>
              {this.props.task.WsTask.Starttime && <span className="mineOppgaverFrist" style={{fontSize: "18px"}}>
                <b>Start:</b> {moment(this.props.task.WsTask.Starttime).format("DD.MM.YYYY HH:mm")}
                <br/>
              </span>}
              <span className="mineOppgaverFrist" style={{fontSize: "18px"}}>
                <b>Frist:</b> {this.props.task.Deadlinetekst}
              </span>
            </div>
            <div className="ms-Grid-col ms-sm1 ms-xxl1">
              {(this.props.task.WsTask.TeamsTask || this.props.task.WsTask.TeamsDeepLink) && 
                  <span>
                      <Icon data-tip data-for={`teamstask-${this.props.task.WsTask.Id}`} iconName="TeamsLogo" style={{fontSize: 20, marginLeft: 15, color: "#4E5FBF", cursor: "default"}}/>
                      <ReactTooltip id={`teamstask-${this.props.task.WsTask.Id}`}>Denne oppgaven er knyttet til Teams</ReactTooltip>
                  </span>
              }
              
              {this.props.task.WsTask.KeepOpen && <span>
                <Icon data-tip data-for="MultipleAttempts" iconName="RepeatAll" style={{ fontSize: 18, marginLeft: 15, color: "rgb(43, 175, 43)", cursor: "default" }}></Icon>
                <ReactTooltip id="MultipleAttempts">Ingen begrensninger på antall ganger man kan levere</ReactTooltip>
              </span>
              }
              {(this.props.task.WsTask.DeliverAfterDeadline) && <span>
                <Icon data-tip data-for="DeliverAfterDeadline" iconName="TimeEntry" style={{ fontSize: 18, marginLeft: 15, color: "rgb(43, 175, 43)", cursor: "default" }}></Icon>
                <ReactTooltip id="DeliverAfterDeadline">
                  <span style={{ textAlign: "center" }}>
                    <p>Åpen for leveranser etter fristen.</p>
                    <p>Besvarelser levert etter fristen vil bli markert som forsinket</p>
                  </span>
                </ReactTooltip>
              </span>
              }
            </div>
            <div className="ms-Grid-col ms-sm6 ms-xxl6  statusBokser">
              {/* {
                this.props.task.WsTask.TeamsDeepLink &&
                <>
                  <ActionButton
                    text="Åpne i Teams"
                    iconProps={{ iconName: "RedEye" }}
                    className="ms-font-l"
                    onClick={() =>
                      window.open(this.props.task.WsTask.TeamsDeepLink)
                    }
                  />
                </>
              } */}
              {
                (!this.props.task.WsTask.TeamsTask && !this.props.task.WsTask.TeamsDeepLink) &&
                <>
                  <div className="ms-Grid-col ms-sm12 ms-xl4">
                    <div className="statusInnlevering">
                      <div className="statusLabel ms-Label">
                        {/* @LangResources.Resources.StudentTaskDelivery */}
                        Innlevering
                      </div>
                      {this.props.task.DeliveryStatus == 1 && (
                        <span>
                          {(!this.hasTaskStarted()) ? <div>
                              <i className="ms-Icon ms-Icon--Lock fa-2x"></i>
                              <div className="statusVerdi ms-Label">
                                Låst
                              </div>
                            </div> : <div>
                              <i className="ms-Icon ms-Icon--Clock fa-2x"></i>
                              <div className="statusVerdi ms-Label">
                                Utlevert
                              </div>
                            </div>
                          }
                        </span>
                      )}
                      {this.props.task.DeliveryStatus != 1 && (
                        <div>
                          <i className="ms-Icon ms-Icon--CheckMark fa-2x"></i>
                          <div className="statusVerdi ms-Label">OK</div>
                        </div>
                      )}
                    </div>
                  </div>

                  <div className="ms-Grid-col ms-sm12 ms-xl4">
                    <div className="statusVurdering">
                      <div className="statusLabel ms-Label">
                        {/* @LangResources.Resources.StudentTaskEvalTitle */}
                        Vurdering

                      </div>
                      {this.props.task.DeliveryStatus == 0 && (
                        <div>
                          <i className="ms-Icon ms-Icon--RedEye fa-2x"></i>
                          <div className="statusVerdi ms-Label">
                            Til vurdering
                          </div>
                        </div>
                      )}
                      {this.props.task.DeliveryStatus == 2 &&
                        this.props.task.ShowEval && (
                          <div>
                            <i className="ms-Icon ms-Icon--CheckMark fa-2x"></i>
                            <div className="statusVerdi ms-Label">
                              Vurdert
                            </div>
                          </div>
                        )}
                      {this.props.task.DeliveryStatus == 2 &&
                        !this.props.task.ShowEval && (
                          <div>
                            <i className="ms-Icon ms-Icon--RedEye fa-2x"></i>
                            <div className="statusVerdi ms-Label">
                              Til vurdering
                            </div>
                          </div>
                        )}

                    </div>
                  </div>


                  <div className="ms-Grid-col ms-sm12 ms-xl4">
                    <div className="statusRediger">
                      {this.hasTaskStarted() && this.getEditButtonStatus() != null && (
                        <ActionButton
                          onClick={() => this.props.history.push(`/taskstudent/${this.props.task.Id}`)}
                          iconProps={{ iconName: this.getEditButtonStatus() != "Se oppgave" ? "Edit" : "RedEye" }}
                          text={this.getEditButtonStatus()}
                        />
                      )}
                      
                    </div>
                  </div>
                </>
              }


              {(this.props.task.WsTask.TeamsTask || this.props.task.WsTask.TeamsDeepLink) &&

                <div className="ms-Grid-col ms-sm12 ms-xl12" style={{float: "right"}}>
                <div className="statusRediger">
                  <ActionButton
                    text="Åpne i Teams"
                    style={{ textAlign: "right" }}
                    iconProps={{ iconName: "OpenInNewTab" }}
                    onClick={async () => {
                      if (this.props.task.WsTask.TeamsDeepLink) {
                        window.open(this.props.task.WsTask.TeamsDeepLink, "_blank", "noreferrer");
                      }
                      else if (this.props.task.WsTask.TeamsTask) {
                        let classId = this.props.task.WsTask.TeamsTask.split("|")[0];
                        let assignmentId = this.props.task.WsTask.TeamsTask.split("|")[1];

                        let assignment = await getAssignment(classId, assignmentId);
                        window.open(assignment.webUrl, "_blank", "noreferrer");
                      }
                    }}
                  />
                </div>
                </div>
                  
              }
              {/* @if (item.DeliveryStatus.ToString().Equals("Utlevert"))
                        {
                            <div className="ms-Grid-col ms-sm12 ms-xl4">
                                <div className="statusRediger">
                                    <button className="ms-Button ms-Button--hero zGoToAssignment" onclick="loadMainWindow('/v2elev/v2studenttaskdetail/@item.Id'); loadCustomNav('/v2customnav/v2studenttaskdetailnav');">
                                        @*@LangResources.Resources.StudentTaskNav*@
                                        @if (item.Deadline < DateTime.Now)
                                        {
                                            @LangResources.Resources.WeekView
                                        }
                                        else
                                        {
                                            @LangResources.Resources.WeekDeliver

                                        }

                                    </button>
                                </div>
                            </div>
                        }
                        else
                        {
                            <div className="ms-Grid-col ms-sm12 ms-xl4">
                                <div className="statusRediger">
                                    <button className="ms-Button ms-Button--hero zGoToAssignment" onclick="loadMainWindow('/v2elev/v2studenttaskdetail/@item.Id'); loadCustomNav('/v2customnav/v2studenttaskdetailnav');">
                                        @*@LangResources.Resources.StudentTaskNavDelivered*@
                                        Se oppgave
                                    </button>
                                </div>
                            </div>
                        } */}
            </div>
          </div>
        </div>
      </div>
    );
  }
  onEditStudentTask(pe: any) {
    this.props.history.push("/innleveringer/student/" + pe.Id);
  }
}

export default Taskitem;
