import * as React from "react";
import { connect } from "react-redux";
import HeaderView from "../view_containers/HeaderView";
import CourseContainerTeams from "./CourseContainerTeams";
import { Dropdown, Panel,PanelType } from "office-ui-fabric-react";
import { getTeacherState } from "../../actions/Action_Teacher";
import {
  getCurrentCourseTaskElements,
  getLastCourseTaskElements,
  getSavedCourseTaskElements
} from "../../actions/Action_Innleveringer";
import {setFilterTasks} from '../../actions/Action_Filtervalues';
import {getAllClasses,getAllClassAssignments} from '../../actions/Action_Graph';
import { getAssignmentGroup } from "../../actions/Action_Ukeplan";
import "./innlevering.scss";
import { CommandBarButton } from "office-ui-fabric-react";
import SidebarActionButtonList from "../sidebar/SidebarActionButtonList";
import TaskElementEditView from "../ukeplan/TaskElementEditView";

export interface IInnleveringerCourseViewProps {
  events: any;
  schoolClasses: any;
  teacherstate: any;
  tasks: any;
  getCurrentCourseTaskElements(course:any);
  getLastCourseTaskElements(course:any);
  getSavedCourseTaskElements(course:any) 
 
  getAssignmentGroup(course: any);
  setFilterTasks(filtervalues:any);
  getAllClasses();
  getAllClassAssignments(classid:string)
  history: any;
  groups: any[];
  defaultkey:number;
  coursefilter:any;
  graphClasses:any[];
  match: any;
}
export interface IInnleveringerCourseViewState {
  coursefilter: string;
  groupfilter: number;
  shownewform:boolean;
}

class InnleveringerCourseView extends React.Component<
IInnleveringerCourseViewProps,
  IInnleveringerCourseViewState
> {
  componentDidMount() {
    const { id } = this.props.match.params;
    switch (this.props.defaultkey) {
      case 1:
        this.props.getCurrentCourseTaskElements({CourseID:id});
        break;
      case 2:
        this.props.getLastCourseTaskElements({CourseID:id});
        break;
      case 3:
        this.props.getSavedCourseTaskElements({CourseID:id});
        break;
      default:
    }
    this.props.getAssignmentGroup({ CourseID: id });
    
  }
  constructor(props) {
    super(props);
    this.state = { coursefilter: "0",groupfilter:0 ,shownewform:false};
  }

  onSelectStatus(status) {
    const { id } = this.props.match.params;
    const { key } = status;
    switch (key) {
      case 1:
        //this.props.getCurrentTaskElements(this.props.teacherState);
        break;
      case 2:
        this.props.getLastCourseTaskElements({CourseID:id});
        break;
      case 3:
        this.props.getSavedCourseTaskElements({CourseID:id});
        break;
      default:
    }
  }

  onSelectCourse = (course, val) => {
    const { key } = course;
    this.setState({ coursefilter: course.key });
    this.props.getAssignmentGroup({ CourseID: course.key });
    this.props.setFilterTasks({selectedcourse:course.key});
  };

  onSelectFilter =(filter,val)=> {
    const { key } = filter;
    this.setState({ groupfilter: filter.key });
  }

  render() {
  let selectedprovider = localStorage.getItem('theprovider');
    let fagoptions = [{ key: "0", text: "Alle" }];
    let dict = {};
    this.props.tasks.forEach(element => {
      if (!dict[element.CourseID]) {
        dict[element.CourseID] = element;
        fagoptions.push({ key: element.CourseID, text: element.CourseName });
      }
    });
    return (
      <div>
        <SidebarActionButtonList>
          
        </SidebarActionButtonList>
        <div className="ms-Grid" id="TeacherTasks">
          <div className="container main">
            <HeaderView
              title="Innleveringer"
              description="Her finner du en oversikt over innleveringer du har delt ut"
              iconName="CompletedSolid"
            />
            <div className="ms-Grid-row topTool ms-bgColor-neutralLight">
              <div className="ms-Grid-col ms-sm12 ms-md4">

                <Dropdown
                  defaultSelectedKey={this.props.defaultkey}
                  placeHolder="Velg status"
                  label="Status"
                  id="selCourseStatus"
                  onChanged={status => {
                    const { id } = this.props.match.params;
                    const { key } = status;
                    switch (key) {
                      case 1:
                        this.props.getCurrentCourseTaskElements(
                            {CourseID:id}
                        );
                        break;
                      case 2:
                        this.props.getLastCourseTaskElements({CourseID:id});
                        break;
                      case 3:
                        this.props.getSavedCourseTaskElements(
                            {CourseID:id}
                        );
                        break;
                      default:
                    }
                  }}
                  options={[
                    { key: 1, text: "Frist fram i tid" },
                    { key: 2, text: "Frist siste 30 dager" },
                    { key: 3, text: "Mine lagrede" }
                  ]}
                />
              </div>
            
              <div className="ms-Grid-col ms-sm12 ms-md4">
                <Dropdown
                  placeHolder="Alle"
                  label="Tilretteleggingsgruppe"
                  id="selCourseFilter"
                  onChanged={this.onSelectFilter}
                  options={this.props.groups}
                />
              </div>
              <CommandBarButton
            onClick={() => this.setState({shownewform:true})}
            className="sidenav-buttons"
            iconProps={{ iconName: "Add" }}
            text="Lag ny innlevering"
          ></CommandBarButton>
          <Panel isOpen={this.state.shownewform}
                    type={PanelType.extraLarge}
                    onDismiss={() => {
                        this.setState({ shownewform: false });
                    }}
                    headerText="Ny innlevering"
                    closeButtonAriaLabel="Lukk">
<TaskElementEditView match={{params:{id:'n',mode:'n'}}} onsave={()=>{
//  console.log('2');
  this.setState({shownewform:false});
}}>

</TaskElementEditView>
          </Panel>
            </div>

            <div className="ms-Grid-row">
              <div className="ms-Grid-col ms-sm12">
                {this.props.tasks.map(t => {
                //  console.log('t',t);
                  if (
                   (this.state.coursefilter === "0" ||
                   this.state.coursefilter === t.CourseID) 
                   &&
                   (this.state.groupfilter === 0 ||
                   this.state.groupfilter === t.GroupID) 
                  ) {
                    return (
                      <CourseContainerTeams
                        history={this.props.history}
                        task={t}
                      ></CourseContainerTeams>
                    );
                  }
                })}
                {/* <CourseContainer/>
                            <CourseContainer/>
                            <CourseContainer/> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  let t = [];
  let g = [];
  g.push({ key: 0, text: 'Alle' })
  if (state.ukeplanelements.ag) {
    state.ukeplanelements.ag.forEach(element => {
        g.push({ key: element.Id, text: element.Name });
    });
  }
  if (state.tasks.Tasks) {
    if (state.tasks.Tasks.length) {
      state.tasks.Tasks.forEach(element => {
        t.push(element);
      });
    }
  }
let defaultmode =1;
if(state.tasks.mode)
{
defaultmode = state.tasks.mode;
}
let selectedcourse ='';
if(state.FilterReducer.TaskFilters&&state.FilterReducer.TaskFilters.selectedcourse)
{
selectedcourse = state.FilterReducer.TaskFilters.selectedcourse;
}
else{
}
  return {
    teacherstate: state.teacher,
    tasks: t,
    groups: g,
    defaultkey:defaultmode,
    coursefilter:selectedcourse,
    graphClasses:state.GraphReducer.allclasses
  };
}

export default connect(mapStateToProps, {
  
  getAssignmentGroup,
  setFilterTasks,
  getAllClasses,
  getAllClassAssignments,
  getCurrentCourseTaskElements,
  getSavedCourseTaskElements,
  getLastCourseTaskElements
})(InnleveringerCourseView);
