import * as React from "react";
import * as moment from "moment";
import { connect } from "react-redux";
moment.locale("nb");
import ReactHtmlParser, {
  processNodes,
  convertNodeToElement,
  htmlparser2
} from "react-html-parser";
import { withRouter } from "react-router-dom";
import { PlanElement } from "../../../models/PlanElement";
import { Checkbox } from "office-ui-fabric-react/lib/Checkbox";
import FilesModal from "../../ukeplan/FilesModal";
import { ActionButton, IconButton } from "office-ui-fabric-react";
import {setUkeplanChecks} from '../../../actions/Action_Student';
import ImmersiveReader from "../../shared/ImmersiveReader";
import { capitalizeFirstLetter } from "../../../utils/HelperFunctions";
import { Course } from "../../../models/Course";
import ReactTooltip from 'react-tooltip';
import UkeplanElementModal from "../../ukeplan/UkeplanElementModal";
export interface IStudentPlanElementViewProps {
  planelement: PlanElement;
  history: any;
  match: any;
  setUkeplanChecks(check:any);
  compactCardView: any;
  course: Course;
}
export interface IStudentPlanElementViewState {
  showfilesmodal:boolean;
  ischecked:boolean;
  myhtml:any;
  showContentModal: boolean;
}

class StudentPlanElementView extends React.Component<
  IStudentPlanElementViewProps,
  IStudentPlanElementViewState
> {
  constructor(props) {
    super(props);
    this.state = {showfilesmodal:false, ischecked: this.props.planelement.IsChecked, myhtml: null, showContentModal: false};
  }
  render() {
    if (!this.props) {
      return <div>tom</div>;
    }
    let classname = "";
    if (this.state.ischecked) {
      classname = "dimmed";
    }
    return (
      <li  className={`list-group-item ${classname}`} ref={(input)=> {
        if(!this.state.myhtml)
        {
          this.setState({myhtml:input});
        }
      
      }}>

          <div className="plan-kort-header">
          {this.props.planelement.Theme.length && (
            <h3 style={{flex:1}}>
              <i
                className="ms-Icon ms-Icon--CalendarWeek"
                aria-hidden="true"
              ></i>{" "}
              {this.props.planelement.Theme}
            </h3>
          )}

          {/* {this.props.planelement.IsChecked && ( */}
              <div style={{marginRight: 10}}>
                <Checkbox
                  checked={this.state.ischecked}
                  label="Ferdig"
                  onChange={(val,b)=>{
                    console.log(val);
                    console.log(b);
                    this.setState(prevState =>({
                      ischecked: !prevState.ischecked
                    }))
                    this.props.setUkeplanChecks({WsTextId:this.props.planelement.Id, IsChecked:b});
                  }}
                />
              </div>
            {/* )} */}
            {/* {!this.props.planelement.IsChecked && (
              <div style={{marginRight: 10}}>
                <Checkbox
                  checked={this.state.ischecked}
                  label="Lekse ikke gjort"
                  onChange={(val,b)=>{
                    console.log(val);
                    console.log(b);
                    this.setState({ischecked:b});
                    this.props.setUkeplanChecks({WsTextId:this.props.planelement.Id,IsChecked:b});
                  }}
                />
              </div>
            )} */}
          {
            this.state.myhtml && <ImmersiveReader htmlcontent={this.state.myhtml}></ImmersiveReader>
          }
          {<div>
            <IconButton
              data-tip
              data-for={`tooltip-task-${this.props.planelement.Id}`}
              iconProps={{ iconName: "MiniExpand", styles: {root: {fontSize: 18}}}} 
              // style={{fontSize: 20, margin: "0 10px", cursor: "pointer"}}
              onClick={() => this.setState({showContentModal: true})}
              style={{margin: "0 5px", alignItems: "start"}}
            ></IconButton>
            <ReactTooltip id={`tooltip-task-${this.props.planelement.Id}`}>
              Åpne i forstørret vindu
            </ReactTooltip>
            <UkeplanElementModal showModal={this.state.showContentModal} onDismiss={() => this.setState({showContentModal: false})} planElement={this.props.planelement} course={this.props.course}></UkeplanElementModal>
          </div>}
        </div>

            

       
          {this.props.planelement.GrepCodeList.length > 1 && this.props.compactCardView ? 
            <span>{this.props.planelement.GrepCodeList.length} Kompetansemål</span> 
            :
            <ul className="grep-list">
              {this.props.planelement.GrepCodeList.map((element) => {
                return (
                  <li>
                    <a href={element.DetailUrl} target="_blank">
                      {element.GrepTekst}
                    </a>
                </li>
                );
              })}
            </ul>}
          
          {this.props.planelement.ShowKmpGoals && (
            <dd>
              <ul className="grep-list">
                {this.props.planelement.GrepCodeList.map(element => {
                  return (
                    <li>
                      <a href={element.GrepTekst} target="_blank">
                        {element.GrepTekst}
                      </a>
                    </li>
                  );
                })}
              </ul>
            </dd>
          )}

          {this.props.planelement.Goal.length > 0 && (
            <div>
              <dt>Egendefinerte mål:</dt>
              <dd>
                <span className={`remove-para-margin list-group-unordered ${this.props.compactCardView ? "hideOverflow" : ""}`} style={{backgroundColor: "transparent"}}
                  dangerouslySetInnerHTML={{
                    __html: this.props.planelement.Goal
                  }}
                ></span>
              </dd>
            </div>
          )}

          {// this.props.planelement.EvalElements || this.props.planelement.EvaluationTextOther &&
          this.props.planelement.EvalElements.length > 0 && (
            <div>
              <dt>Vurdering:</dt>
              <dd className="scheduleEvaluation">
                <ul>
                  {this.props.planelement.EvalElements.map(element => {
                    return <li className={`${this.props.compactCardView ? "hideOverflow" : ""}`}>{element} </li>;
                  })}
                </ul>
              </dd>
            </div>
          )}

          {/* {
                        this.props.planelement.EvaluationText.length > 0 || this.props.planelement.EvaluationTextOther.length > 0 &&
                        <dl>
                            <dt>Evaluering:</dt>
                            <dd className="scheduleEvaluation">
                                <ul>
                                    {
                                        this.props.planelement.GrepCodeList.map(g => {
                                            <li>{g}</li>
                                        })


                                    }

                                </ul>
                            </dd>
                        </dl>
                    } */}

          {this.props.planelement.TextOnPrem.length > 0 && (
            <div>
              <dt>Arbeid på skolen:</dt>
              <dd>
                <span 
                  className={`remove-para-margin list-group-unordered ${this.props.compactCardView ? "hideOverflow" : ""}`}
                  style={{backgroundColor: "transparent"}}
                  dangerouslySetInnerHTML={{
                    __html: this.props.planelement.TextonPremRaw
                  }}
                ></span>
                {/* {this.props.planelement.TextOnPremRaw} */}
              </dd>
            </div>
          )}

          {this.props.planelement.Text.length > 0 && (
            <div>
              <dt>Arbeid hjemme:</dt>
              <dd>
                <span 
                  className={`remove-para-margin list-group-unordered ${this.props.compactCardView ? "hideOverflow" : ""}`}
                  style={{backgroundColor: "transparent"}}
                  dangerouslySetInnerHTML={{
                    __html: this.props.planelement.TextRaw
                  }}
                ></span>
                {/* {this.props.planelement.TextRaw} */}
              </dd>
            </div>
          )}
          {this.props.planelement.Link.length > 0 && (
            <div>
              <dt>Lenke</dt>
              <dd className="planLenke">
                <a target="_blank" href={this.props.planelement.Link}>
                  {this.props.planelement.Link}
                </a>
              </dd>
            </div>
          )}
          {!(this.props.planelement.HideDeadline == true) && <span>
              <dt>Frist:</dt>
              <dd>{capitalizeFirstLetter(moment(this.props.planelement.Date).format("dddd").toString())} {this.props.planelement.DateText}</dd>
            </span>} 
    
        <div className="ms-Grid-row">
          <div className="oppgaveKnapper">
            {this.props.planelement.HasFiles && (
            <ActionButton iconProps={{ iconName: "Attach" }} style={{flex:1}} onClick={()=>{
              // getPlanElementFiles(this.props.planelement.Id);
              this.setState({ showfilesmodal: true });
          }}>
            Se filer
          </ActionButton>
          )}
          </div>
        </div>
         {
            this.state.showfilesmodal &&
            <FilesModal mode={1} id={this.props.planelement.Id}  closemodal={()=>{
                this.setState({ showfilesmodal: false });
            }}/>
            // <FilesModal  />
        }
      </li>
    );
  }
  onEditPlan(pe: any) {
    this.props.history.push("/ukeplan/" + pe.Id + "/e");
  }
}
function mapStateToProps(state) {
  return {
    compactCardView: state.ukeplanelements.CompactCardView
  };
}
 export default connect(mapStateToProps, {setUkeplanChecks})(StudentPlanElementView);
//export default withRouter(StudentPlanElementView);

