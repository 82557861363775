export function capitalizeFirstLetter(word: string) {
    return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
}


export const getValuesFromForm = (values: any) => {
    let tmp = values;
    tmp = JSON.stringify(tmp);
	tmp = JSON.parse(tmp)

    return tmp;
}

export const getQueryParam = (key: string) => {
    let hash = window.location.hash;
    let paramString = hash.split("?");
    
    if (paramString.length > 0) {
        let queryParams = paramString[1];

        const params = new URLSearchParams(queryParams); 
        const queryParam = params.get(key);
        
        return queryParam;
    }
    return undefined;
}