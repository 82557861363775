import * as React from 'react';
import * as moment from 'moment';
import { connect } from 'react-redux';
moment.locale('nb');
import { processNodes, convertNodeToElement, htmlparser2 } from 'react-html-parser';
import NewsElementView from './NewsElementView';
import PlanElementView from "./PlanElementView";
import TaskElementView from "./TaskElementView";
import ImmersiveReader from '../shared/ImmersiveReader';
export interface ICourselementViewViewProps {
    teacherassignment: any;
    history:any;
}
export interface ICourselementViewViewState {

}


class CourselementView extends React.Component<ICourselementViewViewProps, ICourselementViewViewState>{
    constructor(props) {
        super(props);
    }
    render() {
        console.log('thetask',this.props.teacherassignment.Tasks);
        return (
            <div className="item courseitem">
                <div className="panel panel-default" id={this.props.teacherassignment.Course.CourseCategory}>
                    <div className="panel-heading">
                        <h2 className="week-course-name">{this.props.teacherassignment.Course.Title}</h2>
                    </div>
                    <div className="panel-body">
                        <ul className="list-group">
                           
                            {this.props.teacherassignment.News.map((news) => {
                                return (
                                    <NewsElementView newselement={news} history={this.props.history}/>
                                        
                                )
                            })
                            }
                            {this.props.teacherassignment.TextElements.map((text) => {
                                return (

                                    <PlanElementView planelement={text} course={this.props.teacherassignment.Course}/>

                                )
                            }
                            )}
                            {this.props.teacherassignment.Tasks.map((task) => {
                                console.log('atask',task);
                                return (
                                    <TaskElementView taskelement={task} />
                                )
                            })}


                        </ul>
                    </div>
                </div>

            </div>

        )
    }

}
function mapStateToProps(state) {

    return {}
}
export default connect(mapStateToProps, {})(CourselementView);