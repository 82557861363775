import * as React from "react";
import * as moment from "moment";

import { connect } from "react-redux";
import HeaderView from "../view_containers/HeaderView";
import { Link } from "react-router-dom";
import { Field, reduxForm, change } from "redux-form";
import { getPlanElement } from "../../actions/Action_Ukeplan";
export const LOCALE = "nb";
export const DATE_FORMAT = "DD.MM.YYYY";
export const TIME_FORMAT = "LT";
import PlanUtils from "../../utils/PlanUtils";
import { PlanElement } from "../../models/PlanElement";
import PlanElementForm from "./PlaneElementForm";
import PlaneditConfirmView from "./PlaneditConfirmView";
import * as _ from 'lodash';
export const planUtils = new PlanUtils();

export interface IPlanElementEditViewProps {
  mode: string;
  planElements: {};
  teacherState: {
    courses: {};
    schoolClasses: {};
  };
  initialValues: any;
  initialize: any;
  match: any;
  history: any;

  handleSubmit: any;
  getPlanElement(id: number);
}

export interface IPlanElementEditViewState {
  planElementForm: {};
  viewelement: any;
}

class PlanElementEditView extends React.Component<
  IPlanElementEditViewProps,
  IPlanElementEditViewState
> {
  constructor(props) {
    super(props);
  }
  /****************************
           helper methods
    ****************************/
  componentDidUpdate(prevProps, prevState) {
    const { id, mode } = this.props.match.params;
    if(!_.isEqual(prevProps, this.props)){
      this.props.getPlanElement(id).then(response => {
          this.setState({ 
            viewelement: response.payload.data
          })
     })
     .then(response => {
      let p = this.getInitialValuesFromObject(
        this.props.planElements[id]
      );
       this.setState({ planElementForm: p });
     })
    }
    console.log(this.props.match.params.mode)
  }
  getInitialValuesFromObject(se: PlanElement) {
    let ev = {
      Innlevering: false,
      Presentasjon: false,
      Prøve: false,
      Test: false,
      Leksesjekk: false
    };
    se.EvalElements.forEach(element => {
      ev[element] = true;
    });
    return {
      Id: se.Id,
      CourseID: se.CourseID,
      GroupID: se.GroupID,
      Theme: se.Theme,
      ShowKmpGoals: se.ShowKmpGoals,
      HideDeadline: se.HideDeadline,
      Goal: se.Goal,
      EvalElements: se.EvalElements,
      EvaluationTextOther: se.EvaluationTextOther,
      TextOnPrem: se.TextOnPrem,
      TaskDocument: se.TaskDocument,
      Text: se.Text,
      Link: se.Link,
      TaskFile: se.TaskFiles,
      Date: moment(se.Date)
        .locale(LOCALE)
        .format(DATE_FORMAT),
      DateFrom: moment(se.DateFrom)
        .locale(LOCALE)
        .format(DATE_FORMAT),
      Course: se.Course,
      CheckInnlevering: ev["Innlevering"],
      CheckPresentasjon: ev["Presentasjon"],
      CheckProve: ev["Prøve"],
      CheckTest: ev["Test"],
      CheckLeksesjekk: ev["Leksesjekk"],
      Grep: se.Grep,
      SkolonResources:se.SkolonResources
      // ClassID: se.ClassID,
      // Title: se.Title,
      // Color: se.Color,
      // Start: moment(se.Start).locale(LOCALE).format(DATE_FORMAT),
      // End: moment(se.End).locale(LOCALE).format(TIME_FORMAT),
      // DisplayLength: 'one-hour'
    };
  }

  getDefaultInitialValues() {
    return {
      Id: "0",
      EvalElements: []
      // ClassID: !_.isEmpty(this.props.teacherState.schoolClasses) ? Object.keys(this.props.teacherState.schoolClasses)[0] : '',
      // Title: '',
      // Color: 'zSheduleColorBlue',
      // Start: moment().hours(8).minutes(0).locale(LOCALE).format(DATE_FORMAT),
      // End: moment().hours(9).minutes(0).locale(LOCALE).format(TIME_FORMAT),
      // DisplayLength: 'one-hour'
    };
  }
  componentWillMount() {
    
    const { id, mode } = this.props.match.params;
    if (!isNaN(id)) {
      // If the params are a number, this component assumes it's an ID reference to a ScheduleElement
      //if (this.props.planElements[id]) {
      if (false) {
        this.setState({
          planElementForm: this.getInitialValuesFromObject(
            this.props.planElements[id]
          )
        });
      } else {
        this.props
          .getPlanElement(id)
          .then(response => {
            let p = this.getInitialValuesFromObject(
              this.props.planElements[id]
            );
            if (mode === "c") {
              p.Id = 0;
            }
            this.setState({ planElementForm: p });
            //this.setState({ planElementForm: response.payload.data });
            //this.setState({ planElementForm: this.getDefaultInitialValues() });
          })
          .catch(error => {
            // Returns user to TimeplanView if user lacks permission or element for id doesn't exist
            //this.props.history.push('/ukeplan');
          });
      }
    } else {
      // If not, we initialize an empty form
      this.setState({ planElementForm: this.getDefaultInitialValues() });
    }
  }

  /****************************
            Render methods
     ****************************/

  render() {
    const { id, mode } = this.props.match.params;
    if (!this.state || !this.state.planElementForm) {
      return <div>Laster...</div>;
    }
    let headertekst = "Endre planelement";
    if (mode == "c") {
      headertekst = "Kopier planelement";

      let tmp = this.getInitialValuesFromObject(this.props.planElements[id]);
      tmp.Id = 0;
      return (
        <div>
          <div className="ms-Grid main container">
            <HeaderView title={headertekst} iconName="ViewAll" />
            <PlanElementForm initialValues={tmp} mode={mode}/>
          </div>
        </div>
      );
    } else if (mode == "v") {
      if (this.props.planElements[id]) {
        return (
          <PlaneditConfirmView
            history={this.props.history}
            planelement={this.props.planElements[id]}
          ></PlaneditConfirmView>
        );
      } else {
        this.props.getPlanElement(id).then(response => {
          this.setState({ viewelement: this.props.planElements[id] });
        });
        if (this.state.viewelement) {
          return (
            <PlaneditConfirmView
              history={this.props.history}
              planelement={this.state.viewelement}
            ></PlaneditConfirmView>
          );
        }
      }
    }

    if (mode == "n") {
      headertekst = "Lag nytt planelement";
    }

    return (
      <div>
        <div className="ms-Grid main container">
          <HeaderView title={headertekst} iconName="ViewAll" />
          <PlanElementForm initialValues={this.state.planElementForm} 
          mode={mode}/>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    planElements: state.ukeplanelements.planelements
  };
};

export default connect(
  mapStateToProps,
  { getPlanElement }
)(PlanElementEditView);
