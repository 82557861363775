import * as React from "react";
import ReactQuill from "react-quill";

const FabricRichTextField = (props) => {

    const {
        input,
        label,
        required,
        meta: { touched, error },
        children
    } = props;
console.log('rendering',props);
    return (
        <div className="ms-Grid-row">
            <div className="z-form-group">
                <div className="ms-Grid-col ms-sm12">
                    <div className="ms-TextField">
                        <div className="ms-TextField-wrapper">
                            <label htmlFor="TextField26" className="ms-Label root-124">{label}</label>
                        </div>
                    </div>
                    <ReactQuill value={input.value} onChange={(html) => {
                        input.onChange(html);
                    }}
                    />
                    {touched && error && <span className="error-message">{error}</span>}
                </div>
            </div>
        </div>
    );
};

export default FabricRichTextField;