// Replaced during DevOps deployment with environment specific values
let APP_CLIENT_ID = '#{AppClientId}';
let ROOT_URL = "#{BackendUrl}";
let SP_HOST_URL = "#{SharePointHostUrl}";

// Application Client ID if running webapp locally
if (APP_CLIENT_ID.startsWith('#')) {
    //APP_CLIENT_ID = 'ff467b9c-3f5f-4b28-944c-6b06215eaca9'

    //APP_CLIENT_ID='1c61bbe0-ab07-4fd4-9da9-337f1cb05a7b'
    //APP_CLIENT_ID = '65b8d8d2-9673-4161-a4e6-2b0ea50f6d00'
    
    //APP_CLIENT_ID = '8c754382-0b45-43fd-9e77-eb140af33090'
    
    APP_CLIENT_ID = '1c61bbe0-ab07-4fd4-9da9-337f1cb05a7b'
    
   
}

// Backend URL for if running webapp locally
if (ROOT_URL.startsWith('#')){
  
    ROOT_URL = 'https://zokratesstage.azurewebsites.net/API';
   // ROOT_URL = 'https://zdebug.azurewebsites.net/API';
  // ROOT_URL = 'https://localhost:44342//API';
 // ROOT_URL = 'https://zokratesapi.azurewebsites.net/API';
}

// SharePoint Host URL if running webapp locally
if (SP_HOST_URL.startsWith('#')){
    SP_HOST_URL = '?SPHostUrl=https://enablestage.sharepoint.com';
}
export {APP_CLIENT_ID, ROOT_URL, SP_HOST_URL};