import * as React from "react";
import * as _ from 'lodash';
import {connect} from 'react-redux';
import {DefaultButton, Dropdown, IDropdownOption, TextField, Label} from "office-ui-fabric-react";
import CheckboxContainer       from "../CheckboxContainer";
import HeaderView           from "../../view_containers/HeaderView";
import HeaderTaskBar        from "../../view_containers/HeaderTaskBar";
import MainContent          from "../../view_containers/MainContent";
import {School}             from "../../../models/School";
import {saveTeacherCourses, getAvailableCourses} from "../../../actions/Action_Teacher";
import ButtonRow from "../../view_containers/ButtonRow";
import {CourseCheckbox} from "../../../models/CourseCheckbox";
import {TeacherCourse} from "../../../models/TeacherCourse";
import {LoadingDialog} from "../../loading/LoadingDialog";
import {Course} from "../../../models/Course";
import SpinLoader from "../../loading/SpinLoader";
import NoContent from "../../view_containers/NoContent";
import {User} from "../../../models/User";


export interface IAdminCoursesViewProps {
    subscribedCourses:CourseCheckbox[];
    availableCourses:CourseCheckbox[];
    schools:IDropdownOption[];
    user:User;
    teacherState: any;
    getAvailableCourses(teacherState: any);
    saveTeacherCourses(courses:TeacherCourse[], callback:(courses:Course[])=>void);
}

export interface IAdminCoursesViewState {
    awaitingResponse:boolean;       // Set to true while awaiting a response for requests
    loadingCourses:boolean;

    selectedSchoolKey:string | number;
    searchInput:string;

    subscribedCourses:CourseCheckbox[];
    availableCourses:CourseCheckbox[];
}


class AdminCoursesView extends React.Component<IAdminCoursesViewProps, IAdminCoursesViewState>{

    constructor(props){
        super(props);

        const {subscribedCourses, availableCourses} = this.props;

        this.state = {
            awaitingResponse: false,
            loadingCourses: true,
            searchInput: '',
            selectedSchoolKey: 0,
            subscribedCourses,
            availableCourses,
        };

        this.onSearchInputChanged = this.onSearchInputChanged.bind(this);
        this.onSelectedSchoolChanged = this.onSelectedSchoolChanged.bind(this);
    }

    /****************************
            Lifecycle hooks
     ****************************/

    componentWillMount(){
        this.props.getAvailableCourses(this.props.teacherState).then(response =>{
            this.setState({loadingCourses: false})
        })
    }

    componentWillReceiveProps(nextProps:IAdminCoursesViewProps){

        const {availableCourses, subscribedCourses} = this.props;

        // If state for available courses has changed
        if (!_.isEqual(availableCourses, nextProps.availableCourses)){
            this.setState({
                availableCourses: nextProps.availableCourses.map(a => ({...a, Selected: false}))
            })
        }

        // If state for subscribed courses has changed
        if (!_.isEqual(subscribedCourses, nextProps.subscribedCourses)){
            this.setState({
                subscribedCourses:  nextProps.subscribedCourses.map(a => ({...a, Selected: true}))
            })
        }
    }
    /****************************
            Event handlers
     ****************************/

    onSearchInputChanged(event){
        this.setState({searchInput: event.target.value});
    }

    onSelectedSchoolChanged(selectedSchool : IDropdownOption){

        const selectedSchoolKey = selectedSchool.key;

        this.setState({selectedSchoolKey});
    }

    onSubscribedCheckboxClicked(course:CourseCheckbox) {

        // Toggles 'Selected' property for clicked schoolClass
        const subscribedCourses = this.state.subscribedCourses.map(sc =>(
            sc.CourseID === course.CourseID
                ? {...course, Selected: !course.Selected}
                : sc
        ));

        this.setState({subscribedCourses});
    }

    onFilteredCheckboxClicked(course:CourseCheckbox) {

        // Toggles 'Selected' property for clicked schoolClass
        const availableCourses = this.state.availableCourses.map(sc =>(
            sc.CourseID === course.CourseID
                ? {...course, Selected: !course.Selected}
                : sc
        ));

        this.setState({availableCourses});
    }

    onSubmit(){

        const {login} = this.props.user;
        const {subscribedCourses, availableCourses} = this.state;

        // We merge both the selected courses and available courses
        const allCourses : CourseCheckbox[] = subscribedCourses.concat(availableCourses);

        // We only send the selected courses in the request
        let selectedCourses : CourseCheckbox[] = allCourses.filter(c => c.Selected);

        const requestData : TeacherCourse[] = selectedCourses.map(c => new TeacherCourse(login, c.CourseID));

        this.setState({awaitingResponse: true});

        this.props.saveTeacherCourses(requestData, (savedCourses:Course[])=>{
            this.setState({awaitingResponse: false});
        });
    }


    /****************************
            Filter methods
     ****************************/

    filterCourseCheckboxBySearchInput = (checkbox : CourseCheckbox) : boolean =>{

        const searchInput = this.state.searchInput.toLowerCase();
        return checkbox.Title.toLowerCase().includes(searchInput) || checkbox.CourseID.toLowerCase().includes(searchInput)
    };

    filterCourseCheckboxBySelectedSchool = (checkbox : CourseCheckbox) : boolean =>{

        const {selectedSchoolKey} = this.state;

        // If all schools are selected
        if (selectedSchoolKey === 0){
            return true;
        }

        const selectedSchool : IDropdownOption | undefined = this.props.schools
            .find(s => s.key === selectedSchoolKey);

        if (!selectedSchool){
            return false;
        }

        return selectedSchool.title === checkbox.SchoolID;
    };

    getFilteredCourseCheckboxes = (checkboxes : CourseCheckbox[]) : CourseCheckbox[] =>(
        checkboxes
            .filter(c => this.filterCourseCheckboxBySearchInput(c))
            .filter(c => this.filterCourseCheckboxBySelectedSchool(c))
            .sort((a, b) => a.Title.localeCompare(b.Title))
    );

    hasAppliedAnyFilters = () : boolean =>{

        const {searchInput, selectedSchoolKey} = this.state;

        return selectedSchoolKey !== 0 || !!searchInput
    };

    /****************************
            Render methods
     ****************************/

    renderHeader = () =>{

        return(
            <HeaderView
                title="Velg dine fag"
                description="Her velger du fag som du skal ha ansvar for, eller ønsker å være knyttet til"
                iconName="Checklist"
            >
                <HeaderTaskBar>
                    <div className="ms-Grid-col ms-sm6 ms-lg3">
                        <Label>Filtrer tilgjengelige fag utfra skole</Label>
                        <Dropdown
                            id="selSchool"
                            //label="Filtrer tilgjengelige fag utfra skole"
                            selectedKey={this.state.selectedSchoolKey}
                            onChanged={this.onSelectedSchoolChanged}
                            options={this.props.schools}
                        />
                    </div>
                    <div className="ms-Grid-col ms-sm6 ms-lg3">
                        <Label>Søk på fag</Label>
                        <TextField
                            //label="Søk på fag"
                            placeholder="Søk ved å begynne å skrive navnet på faget"
                            ariaLabel="Søk på fag"
                            value={this.state.searchInput}
                            onChange={this.onSearchInputChanged}
                        />
                    </div>
                </HeaderTaskBar>
            </HeaderView>
        )
    };

    renderSubscribedCourses = () =>{

        const {subscribedCourses, loadingCourses} = this.state;

        const filteredSubscribedCourses : CourseCheckbox[] = this.getFilteredCourseCheckboxes(subscribedCourses);
        const isLoadingSubscribedCourses : boolean = loadingCourses && !subscribedCourses.length;
        const hasNoSubscribedCourses : boolean = !subscribedCourses.length && !loadingCourses;

        return(
            <>
                <h3 className="mt-10">Mine fag</h3>

                {isLoadingSubscribedCourses &&
                    <SpinLoader text="Laster dine fag"/>
                }

                {hasNoSubscribedCourses &&
                    <NoContent text="Du har ingen fag"/>
                }

                {this.hasAppliedAnyFilters() && !filteredSubscribedCourses.length &&
                    <NoContent text="Ingen fag for valgte filter"/>
                }

                <div className="ms-Grid-row">
                    {filteredSubscribedCourses.map((course:CourseCheckbox) => {
                        return (
                            <div onClick={()=> this.onSubscribedCheckboxClicked(course)}>
                                <CheckboxContainer
                                    Id={course.CourseID}
                                    Name={course.Title}
                                    Checked={course.Selected}
                                />
                            </div>
                        )
                    })}
                </div>
            </>
        );
    };

    renderAvailableCourses = () =>{

        const {availableCourses, loadingCourses} = this.state;

        const filteredAvailableCourses : CourseCheckbox[] = this.getFilteredCourseCheckboxes(availableCourses);
        const isLoadingAvailableCourses : boolean = loadingCourses && !availableCourses.length;
        const hasNoAvailableCourses : boolean = !loadingCourses && !availableCourses.length;
        const hasAppliedAnyFilters : boolean = this.hasAppliedAnyFilters();

        return(
            <>
                <div className="ms-Grid-row position-relative">
                    <h3 className="mt-10">Tilgjengelige fag</h3>

                    {isLoadingAvailableCourses &&
                        <SpinLoader text="Laster tilgjengelige fag"/>
                    }

                    {hasNoAvailableCourses &&
                    <NoContent text="Ingen tilgjengelige fag"/>
                    }

                    {hasAppliedAnyFilters && !filteredAvailableCourses.length &&
                    <NoContent text="Ingen tilgjengelige fag for valgte filter"/>
                    }

                </div>
                <div className="ms-Grid-row">
                    {filteredAvailableCourses.map((course:CourseCheckbox) => {
                        return (
                            <div onClick={()=> this.onFilteredCheckboxClicked(course)}>
                                <CheckboxContainer
                                    Id={course.CourseID}
                                    Name={course.Title}
                                    Checked={course.Selected}
                                />
                            </div>
                        )
                    })}
                </div>
            </>
        );
    };

    render(){

        const {awaitingResponse} = this.state;

        return(
            <div className="ms-Grid main">
                {awaitingResponse &&
                    <LoadingDialog title="Oppdaterer dine fag" description="Vennligst vent"/>
                }

                {this.renderHeader()}

                <MainContent>
                <ButtonRow>
                        <DefaultButton
                            primary={true}
                            text="Lagre"
                            onClick={this.onSubmit.bind(this)}
                            style={{float: 'right'}}
                        />
                    </ButtonRow>
                    {this.renderSubscribedCourses()}
                    {this.renderAvailableCourses()}

                    <ButtonRow>
                        <DefaultButton
                            primary={true}
                            text="Lagre"
                            onClick={this.onSubmit.bind(this)}
                            style={{float: 'right'}}
                        />
                    </ButtonRow>
                </MainContent>
            </div>
        )
    }
}

export const mapCoursesToCourseCheckboxes = (courses : Course[], isSelected : boolean) : CourseCheckbox[] =>(
    courses.map(c => new CourseCheckbox(c.Title, c.CourseID, isSelected, c.SchoolID))
);

const mapSchoolsToSelectOptions = (schools : School[]) : IDropdownOption[] => (
    [{key: 0, title: "Alle", text: 'Alle'}].concat(schools.map((s, i) =>({key: i+1, title: s.SchoolId, text: s.Name})))
);

const mapStateToProps = (state) => {

    const {availableCourses, subscribedCourses, schools, user} = state.teacher;

    return {
        teacherState: state.teacher,
        user,
        schools: mapSchoolsToSelectOptions(_.values(schools)),
        subscribedCourses: mapCoursesToCourseCheckboxes(_.values(subscribedCourses), true),
        availableCourses: mapCoursesToCourseCheckboxes(_.values(availableCourses), false),
    };
};

export default connect(mapStateToProps, {saveTeacherCourses, getAvailableCourses})(AdminCoursesView);