import * as _ from "lodash";
import {
    GET_STUDENT_IUPLISTTEACHER,
    GET_STUDENT_IUPLDISCOVERY,
    GET_STUDENT_PORTFOLIODISCOVERY, GET_ALL_ELEMENTS_TEACHER
} from "../actions/Action_Discovery";
const statestructure = {
};
export default function (state = statestructure, action) {
  switch (action.type) {
    case GET_STUDENT_IUPLISTTEACHER:
      const newState = {
        StudentIUPS: action.payload.data,
      };

      return _.assign({ ...state }, newState);
      case GET_STUDENT_IUPLDISCOVERY:
        const newStateIUP = {
          StudentIUP: action.payload.data,
        };
  
        return _.assign({ ...state }, newStateIUP);
        case GET_STUDENT_PORTFOLIODISCOVERY:
          const portfolio = action.payload.data;
          //return _.assign({}, portfolio);
          return { ...state, portfolio: portfolio };
        case GET_ALL_ELEMENTS_TEACHER: 
        const teacherElements = action.payload.data;
        return {...state, teacherElements};
    default:
      return state;
  }
}