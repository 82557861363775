import * as _ from "lodash";
import {
  GET_UKEPLAN_ELEMENT,
  GET_UKEPLAN_ELEMENTS,
  UPDATE_UKEPLAN_ELEMENT,
  DELETE_UKEPLAN_ELEMENT,
  GET_STUDENTS,
  GET_UKEPLANSTUDENT_ELEMENTS,
  GET_COURSE_ASSIGNMENTGROUPS,
  GET_TASK_ELEMENT,
  GET_TASK_ELEMENTEXT,
  DISTRIBUTE_TASK,
  GET_NEWS_ELEMENT,
  GET_UKEPLAN_FILES,
  GET_NEWS_FILES,
  SET_UKEPLAN_SELECTED_DATE,
  SET_COMPACT_CARD_VIEW_TOGGLE
} from "../actions/Action_Ukeplan";

import { default as DateUtils } from "./../utils/DateUtils";
import moment = require("moment");
const statestructure = {
  AllTAs: {},
  planelements: {},
  students: [],
  StudentWeek: {},
  SelectedDate: moment().toDate(),
  CompactCardView: false
};
export default function(state = statestructure, action) {
  const dateUtils = new DateUtils();
  switch (action.type) {
    case GET_UKEPLAN_ELEMENTS:
      const ukeplanelements = action.payload.data;
      const planelements = action.payload.data.Wstexts;
      const selectedDate = state.SelectedDate; 

      const newState = {
        ...state,
        AllTAs: ukeplanelements,
        planelements: _.mapKeys(planelements, "Id"),
        SelectedDate: selectedDate
      };

      return _.assign({}, newState);
    case GET_UKEPLANSTUDENT_ELEMENTS:
      const ukeplanelementsstudent = action.payload.data;

      const newStateStudent = {
        StudentWeek: ukeplanelementsstudent
      };

      return { ...state, ukeplanelementsstudent };
    //return _.assign({}, state, _.mapKeys(ukeplanelements, 'Id'));
    case GET_UKEPLAN_ELEMENT:
      // ES6: gets state and sets object value by key.
      const planElement = action.payload.data;

      const elements = state.planelements;
      elements[planElement.Id] = planElement;

      return { ...state, elements };
    case GET_TASK_ELEMENT:
      // ES6: gets state and sets object value by key.
      const taskElement = action.payload.data;

      return { ...state, taskElement: taskElement };
    case GET_TASK_ELEMENTEXT:
      // ES6: gets state and sets object value by key.
      const taskElementext = action.payload.data;

      return { ...state, taskElement: taskElementext };
    case GET_NEWS_ELEMENT:
      // ES6: gets state and sets object value by key.
      const newselement = action.payload.data;

      return { ...state, newselement: newselement };

    case DISTRIBUTE_TASK:
      // ES6: gets state and sets object value by key.
      const taskElemendist = action.payload.data;

      return { ...state, taskElement: taskElemendist };

    case GET_STUDENTS:
      const students = action.payload.data;
      const newStatestudents = {
        students: students
      };

      return _.assign({}, newStatestudents);
    case GET_COURSE_ASSIGNMENTGROUPS:
      const ag = action.payload.data;

      return { ...state, ag };
    case GET_UKEPLAN_FILES:
      const Filelist = action.payload.data;

      return { ...state, Filelist: Filelist };
    case GET_NEWS_FILES:
      const NewsFiles = action.payload.data;

      return { ...state, NewsFiles: NewsFiles };
    case SET_UKEPLAN_SELECTED_DATE:
      const ukeplanDate = action.payload;
      return {...state, SelectedDate: ukeplanDate}
    case SET_COMPACT_CARD_VIEW_TOGGLE:
      return {...state, CompactCardView: action.payload}
    default:
      return state;
  }
}
