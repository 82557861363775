import * as React from "react";
import * as moment from "moment";
import * as _ from "lodash";
import { connect } from "react-redux";
import {
  Accordion,
  AccordionItem,
  AccordionItemTitle,
  AccordionItemBody
} from "react-accessible-accordion";
import "react-accessible-accordion/dist/fancy-example.css";
import { Label, Icon } from "office-ui-fabric-react";
import Reducer_Grep from "../../reducers/Reducer_Grep";
moment.locale("nb");

export interface IPortfolioLPKLProps {
    item: any;
    items: any[];
    history: any;
  }
  export interface IPortfolioLPKLState {
    item: any;
  }

export default class PortfolioLPKL extends React.Component<
    IPortfolioLPKLProps,
    IPortfolioLPKLState
> {
  constructor(props) {
    super(props);

    this.state = {
      item: {}
    }
  }
  componentWillMount() {
    this.setState({item: this.props.item});
  }

    render() {
        return (
            <div>
        {/* --------------HELE LISTEN ---------*/}
        <Accordion>
          {this.state.item.kompetansemaal_kapittel.kompetansemaalsett.map(
            loopitem => {
             
              let totalset = 0;
              let totalhitcount = 0;
              let count = 0;
              let subs = 0;

              let s = 0;
              let co = 0;
              let h = false;
              loopitem.hovedomraader_i_kontekst_av_kompetansemaalsett.forEach(i => {
                subs++;
                let hit = false;
                loopitem.kompetansemaal.forEach(k => {
                  let totalhit = false;
                  if (k.tilhoerer_hovedomraade.kode == i.kode) {
                    totalset++;
                    if(k.portfolioitems.Texts.length>0)
                    {
                        console.log('treff');
                    }
                    if(k.portfolioitems.Tasks.length>0)
                    {
                        console.log('treff2');
                    }
                    if(k.portfolioitems.Wstasks && k.portfolioitems.Wstasks.length>0)
                    {
                        console.log('treff3');
                    }
                    if (
                      k.portfolioitems.Tasks.length > 0 ||
                      k.portfolioitems.Texts.length > 0 ||
                      k.portfolioitems.Wstasks.length > 0 ||
                      (k.portfolioitems.QuizAttempts && k.portfolioitems.QuizAttempts.length > 0) ||
                      (k.portfolioitems.Quizzes && k.portfolioitems.Quizzes.length > 0)
                    ) {
                      hit = true;
                      totalhit = true;
                    }
                  }
                  if (totalhit) {
                    totalhitcount++;
                  }
                });
                if (hit) {
                  count++;
                }
              });
              return (
                <AccordionItem>
                  <AccordionItemTitle>
                    <div className="ms-Grid-row">
                      <div className="ms-Grid-col ms-sm2">
                        {/* logikk for ikon må inn her */}
                        {totalhitcount / totalset < 0.3 && (
                          <Icon
                            iconName="StatusErrorFull"
                            className="red-color font-size-l"
                          />
                        )}
                        {totalhitcount / totalset >= 0.3 &&
                          totalhitcount / totalset < 0.7 && (
                            <Icon
                              iconName="IncidentTriangle"
                              className="yellow-color font-size-l"
                            />
                          )}
                        {totalhitcount / totalset >= 0.7 && (
                          <Icon
                            iconName="CheckboxCompositeReversed"
                            className="green-color font-size-l"
                          />
                        )}

                        <span className="ml-5">
                          {totalhitcount} av {totalset}
                        </span>
                      </div>
                      <div className="ms-Grid-col ms-sm3">
                        <span>
                        {loopitem.kode}
                        {(loopitem.etter_aarstrinn && loopitem.etter_aarstrinn[0]) && ` - ${loopitem.etter_aarstrinn[0].tittel}`}
                        </span>
                      </div>
                      <div className="ms-Grid-col ms-sm6">
                        <span>{(loopitem && loopitem.etter_fag && loopitem.etter_fag[0]) && loopitem.etter_fag[0].tittel}</span>
                      </div>
                    </div>
                  </AccordionItemTitle>
                  
                  <AccordionItemBody>
                      <Accordion>
                        {loopitem.hovedomraader_i_kontekst_av_kompetansemaalsett.map(
                          i => {
                            {
                              s = 0;
                              co = 0;
                              h = false;
                              loopitem.kompetansemaal.forEach(k => {
                                if (k.tilhoerer_hovedomraade.kode == i.kode) {
                                  s++;

                                  if (
                                    k.portfolioitems.Tasks.length > 0 ||
                                    k.portfolioitems.Texts.length > 0 ||
                                    k.portfolioitems.Wstasks.length > 0 ||
                                    (k.portfolioitems.QuizAttempts && k.portfolioitems.QuizAttempts.length > 0) ||
                                    (k.portfolioitems.Quizzes && k.portfolioitems.Quizzes.length > 0)
                                  ) {
                                    h = true;
                                    co++;
                                  }
                                }
                              });
                            }
                            return (
                              <AccordionItem>
                                <AccordionItemTitle>
                                  <div className="ms-Grid-row">
                                    <div className="ms-Grid-col ms-sm2">
                                      {/* logikk for ikon må inn her */}
                                      {co / s < 0.3 && (
                                        <Icon
                                          iconName="StatusErrorFull"
                                          className="red-color font-size-l"
                                        />
                                      )}
                                      {co / s >= 0.3 && co / s < 0.7 && (
                                        <Icon
                                          iconName="IncidentTriangle"
                                          className="yellow-color font-size-l"
                                        />
                                      )}
                                      {co / s >= 0.7 && (
                                        <Icon
                                          iconName="CheckboxCompositeReversed"
                                          className="green-color font-size-l"
                                        />
                                      )}

                                      <span>
                                        {" "}
                                        {co} av {s}
                                      </span>
                                    </div>
                                    <div className="ms-Grid-col ms-sm10">
                                      {i
                                        .hovedomraadeverdier_under_kompetansemaalsett
                                        .tittel ? 
                                        i
                                        .hovedomraadeverdier_under_kompetansemaalsett
                                        .tittel.map((tit)=>{
                                          if(tit.spraak=='default')
                                          {
                                            return(
                                              tit.verdi
                                            )
                                          }
                                        }) : loopitem.kode
                                        // i
                                        //   .hovedomraadeverdier_under_kompetansemaalsett
                                        //   .tittel[0].verdi
                                      }
                                    </div>
                                  </div>
                                </AccordionItemTitle>
                                <AccordionItemBody>
                                  <div className="hArea panel">
                                    <div className="ms-Grid-row">
                                      <div className="ms-Grid-col ms-sm12">
                                        <ul className="list-group no-type-list">
                                          {loopitem.kompetansemaal.map(k => {
                                            if (
                                              k.tilhoerer_hovedomraade.kode ==
                                              i.kode
                                            ) {
                                              let classn = "redBG";
                                              let iconName = "StatusErrorFull";
                                              let classColor = "red-color";
                                              if (
                                                k.portfolioitems.Tasks.length >
                                                  0 ||
                                                k.portfolioitems.Texts.length >
                                                  0
                                                  ||
                                                k.portfolioitems.Wstasks.length >
                                                  0
                                                  ||
                                                (k.portfolioitems.QuizAttempts && k.portfolioitems.QuizAttempts.length > 0) ||
                                                (k.portfolioitems.Quizzes && k.portfolioitems.Quizzes.length > 0)
                                              ) {
                                                classn = "hasitems greenBG";
                                                iconName =
                                                  "CheckboxCompositeReversed";
                                                classColor = "green-color";
                                              }

                                              //-------ET LISTEELEMENT TIL KOMPETANSEMÅL--------//
                                              return (
                                                
                                                <li
                                                className={`${classn +
                                                  " list-group-item"}`}
                                                  >
                                                  <div className="ms-Grid-row">
                                                    <div className="ms-Grid-col ms-sm1">
                                                      <div className="statusIcon">
                                                        <Icon
                                                          iconName={`${iconName}`}
                                                          className={`${classColor +
                                                            " font-size-l"}`}
                                                        />
                                                        
                                                      </div>
                                                    </div>
                                                    <div className="ms-Grid-col ms-sm11">
                                                      {k && k.tittel}

                                                      <div className="portItms">
                                                        <div className="ms-Grid-row">
                                                          <div className="ms-Grid-col ms-sm4">
                                                            <b>Innleveringer</b>
                                                            <ul className="task-@k.kode no-type-list">
                                                              {k.portfolioitems.Tasks.map(
                                                                t => {
                                                                  return (
                                                                    <li className="workItem"  >
                                                                      <a
                                                                        className="pointer"
                                                                        onClick={() => {
                                                                          this.props.history.push(
                                                                            "/innleveringer/student/" +
                                                                              t.Id
                                                                          );
                                                                        }}
                                                                      >
                                                                        {
                                                                          t
                                                                            .WsTask
                                                                            .Name
                                                                        }
                                                                      </a>
                                                                    </li>
                                                                  );
                                                                }
                                                              )}
                                                              {k.portfolioitems.Wstasks.map(
                                                                t => {
                                                                  return (
                                                                    <li className="workItem"  >
                                                                      <a
                                                                        className="pointer"
                                                                        onClick={() => {
                                                                          this.props.history.push(
                                                                            "/innleveringer/" +
                                                                              t.Id
                                                                          );
                                                                        }}
                                                                      >
                                                                        {
                                                                          t
                                                                            .Name
                                                                        }
                                                                      </a>
                                                                    </li>
                                                                  );
                                                                }
                                                              )}
                                                            </ul>
                                                          </div>
                                                          <div className="ms-Grid-col ms-sm4">
                                                            <b>Planelementer</b>
                                                            <ul className="plan-@k.kode">
                                                              {k.portfolioitems.Texts.map(
                                                                t => {
                                                                  return (
                                                                    <li>
                                                                       <a
                                                                        className="pointer"
                                                                        onClick={() => {
                                                                          this.props.history.push("/ukeplan/" + t.Id + "/e");
                                                                        }}
                                                                      >
                                                                        {
                                                                          t.Theme
                                                                        }
                                                                      </a>
                                                                     
                                                                    </li>
                                                                  );
                                                                }
                                                              )}
                                                            </ul>
                                                          </div>
                                                          <div className="ms-Grid-col ms-sm4">
                                                            <b>Prøver</b>
                                                            <ul className="task-@k.kode no-type-list">
                                                             {k.portfolioitems.QuizAttempts && <div>{k.portfolioitems.QuizAttempts.map(
                                                                quizAttempt => {
                                                                  return (
                                                                    <li className="workItem"  >
                                                                      <a
                                                                        className="pointer"
                                                                        onClick={() => {
                                                                          this.props.history.push(
                                                                            "/quiz/" +
                                                                              quizAttempt.Qid
                                                                          );
                                                                        }}
                                                                      >
                                                                        {
                                                                          quizAttempt.Quiz.Title
                                                                        }
                                                                      </a>
                                                                    </li>
                                                                  );
                                                                }
                                                              )}</div>}
                                                              {k.portfolioitems.Quizzes && <div>{k.portfolioitems.Quizzes.map(
                                                                quiz => {
                                                                  return (
                                                                    <li className="workItem"  >
                                                                      <a
                                                                        className="pointer"
                                                                        onClick={() => {
                                                                          this.props.history.push(
                                                                            "/quiz/" +
                                                                              quiz.Id
                                                                          );
                                                                        }}
                                                                      >
                                                                        {
                                                                          quiz.Title
                                                                        }
                                                                      </a>
                                                                    </li>
                                                                  );
                                                                }
                                                              )}</div>}
                                                            </ul>
                                                          </div>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </li>
                                              );
                                            }
                                          })}
                                        </ul>
                                      </div>
                                    </div>
                                  </div>
                                </AccordionItemBody>
                              </AccordionItem>  
                            )
                          }
                        )}
                      </Accordion>
                  </AccordionItemBody>
                </AccordionItem>
              );
            }
          )}
        </Accordion>
      </div>
        )
    }
}
