import * as React from "react";
import * as moment from "moment";
import * as _ from "lodash";
import { withRouter, refresh } from "react-router-dom";
import { connect } from "react-redux";
moment.locale("nb");
import ReactHtmlParser, {
  processNodes,
  convertNodeToElement,
  htmlparser2
} from "react-html-parser";
import { getNewsFiles, deleteNewsElement } from "../../actions/Action_Ukeplan";
import {getTeacherState, getAvailableSchoolClasses} from '../../actions/Action_Teacher'
import { ActionButton, Dialog, DialogFooter, Shimmer } from "office-ui-fabric-react";
import ImmersiveReader from "../shared/ImmersiveReader";
import '../QuillStyles.scss';
export interface INewsElementViewProps {
  newselement: any;
  history: any;
  teacherState: any;
  newsfiles: any[];

  //School Classes
  subscribedSchoolClasses:any[];
  availableSchoolClasses:any[];

  getNewsFiles(news: any);
  deleteNewsElement(n: any);
  getTeacherState(): any;
  // getAvailableSchoolClasses(teacherstate: any);
  courses: any;
  allCourses: any;
  allClasses: any;
  compactCardView: boolean;
}
export interface INewsElementViewState {
  showdialog: boolean;
  showNewsElement: boolean;
  myhtml: any;
  //Lagt til
  // allClasses : any[];
  // allCourses : any[];
  isMessageOverflown: boolean;
}

class NewsElementView extends React.Component<
  INewsElementViewProps,
  INewsElementViewState
> {
  private msgRef: React.RefObject<HTMLDivElement>;
  constructor(props) {
    super(props);
    this.msgRef = React.createRef();
    this.state = { showdialog: false, myhtml: null, showNewsElement: true , isMessageOverflown: false};
  }
  handleResize = e =>{
    const msgOverflow = this.msgRef.current.scrollHeight > this.msgRef.current.clientHeight || this.msgRef.current.scrollWidth > this.msgRef.current.clientWidth;
    this.setState({isMessageOverflown: msgOverflow});
  }
  componentDidMount(){
    //Classes
    // console.log(this.props.teacherState.availableSchoolClasses)
    // if(_.isEmpty(this.props.teacherState.availableSchoolClasses)){
      // this.props.getAvailableSchoolClasses(this.props.teacherState).then(res =>{
    //     let allClasses = Object.values(this.props.teacherState.subscribedSchoolClasses).concat(Object.values(this.props.teacherState.availableSchoolClasses));
    //     this.setState({
    //       allClasses
    //     })
    //   })
    // }else{
    //   let allClasses = Object.values(this.props.teacherState.subscribedSchoolClasses).concat(Object.values(this.props.teacherState.availableSchoolClasses));
    //   this.setState({
    //     allClasses
    //   })

    // }
    // //Courses
    //   let allCourses = Object.values(this.props.teacherState.subscribedCourses).concat(Object.values(this.props.teacherState.availableCourses));
    //   this.setState({
    //     allCourses
    //   })

    const msgOverflow = this.msgRef.current.scrollHeight > this.msgRef.current.clientHeight || this.msgRef.current.scrollWidth > this.msgRef.current.clientWidth;
    this.setState({isMessageOverflown: msgOverflow});
    window.addEventListener('resize', this.handleResize);
  }
  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
  }  
  onDeleteClick() {
    if (confirm("Vil du virkelig slette denne meldingen?")) {
      this.props.deleteNewsElement({ Id: this.props.newselement.Id });
    }
  }
  
  

  render() {
      {return this.state.showNewsElement && <li
        className="list-group-item"
        ref={input => {
          if (!this.state.myhtml) {
            this.setState({ myhtml: input });
          }
        }}
      >
        <div className="plan-kort-header">
          <h3>
            <i className="ms-Icon ms-Icon--Message" aria-hidden="true"></i>
            {this.props.newselement.Title}
          </h3>
          <div className="plan-kort-header--flex-item">
            {this.state.myhtml && (
              <ImmersiveReader htmlcontent={this.state.myhtml}></ImmersiveReader>
            )}
          </div>
        </div>
        <dl>
          <dd>
            {
              this.props.newselement.Classes.length > 0 && (
            <div>Klasser:            
              {this.props.allClasses.map((item, index) =>{
              
              if(this.props.newselement.Classes.split(";").find( element =>element === item.ClassID.toString())){
                return (<b> {`${item.ClassName}, `} </b>)
              }else{
                return '';
              }
            })}
            </div>
              )
            }
            {
              this.props.newselement.Courses.length > 0 &&
              <div>Fag: 
                { this.props.allCourses.map((item, index) =>{
                if(this.props.newselement.Courses.split(";").find( element =>element === item.CourseID)){
                  return (<b> {`${item.Title}, `} </b>)
                }else{
                  return '';
                }
              })}
            </div>
            }

            <br />
            <div
              ref={this.msgRef}
              style={{backgroundColor: "transparent"}}
              className={`remove-para-margin hideOverflow`}
              dangerouslySetInnerHTML={{
                __html: this.props.newselement.TextRaw
              }}
            />
            {this.state.isMessageOverflown && <p>...</p>}
            <br/>
            {(this.state.isMessageOverflown || (this.props.newselement.NewsDocuments  && this.props.newselement.NewsDocuments.length > 0)) && <ActionButton
              iconProps={{iconName: "RedEye"}}
              text={(this.props.newselement.NewsDocuments  && this.props.newselement.NewsDocuments.length > 0) ? "Vis hele meldingen med vedlegg" : "Vis hele meldingen"}
              onClick={() => {
                // this.props
                //   .getNewsFiles(this.props.newselement)
                //   .then(() => {
                //     this.setState({ showdialog: true });
                //   });

                this.setState({ showdialog: true });
                this.props.getNewsFiles(this.props.newselement);
              }}
            /> }
            {/* {this.props.newselement.NewsDocuments &&
              this.props.newselement.NewsDocuments.length > 0 && (
                <span>
                  <ActionButton
                    onClick={() => {
                      this.props
                        .getNewsFiles(this.props.newselement)
                        .then(() => {
                          this.setState({ showdialog: true });
                        });
                    }}
                    iconProps={{ iconName: "FileSymlink" }}
                    text="Filer"
                  />
                </span>
              )} */}
            {/* {this.props.newselement.TextRaw} */}
          </dd>
        </dl>
        <Dialog
          hidden={!this.state.showdialog}
          onDismiss={() => {
            this.setState({ showdialog: false });
          }}
          styles={{
            main: [
              {
                selectors: {
                  ["@media (min-width: 480px)"]: {
                    maxWidth: "600px",
                    minWidth: "480px"
                  }
                }
              }
            ]
          }}
          modalProps={{
            isBlocking: true,
            containerClassName: "ms-dialogMainOverride"
          }}
        >
          <h3>
            {this.props.newselement.Title}
          </h3>
          <div
            className="remove-para-margin ql-editor"
            style={{marginBottom: 20, backgroundColor: "transparent"}}
            dangerouslySetInnerHTML={{__html: this.props.newselement.TextRaw}}
          />

          {this.props.newselement.NewsDocuments && this.props.newselement.NewsDocuments.length > 0 && <ul>
            {this.props.newselement.NewsDocuments.split(";").map(d => {
              let splitNewsDocument = d.split("|")

              if (splitNewsDocument && splitNewsDocument.length > 0){
                let objectId = splitNewsDocument[0];

                let newsfile = !!this.props.newsfiles ? this.props.newsfiles.find(nf => nf.OriginalDocumentID == objectId) : undefined;
                return <li><Shimmer isDataLoaded={!!newsfile} style={{margin: "8px 0"}}>
                  {newsfile && 
                    <a href={newsfile.FileUrl} target="_blank">
                        {newsfile.FileName}
                    </a>
                  }
                  </Shimmer></li>
              }
            })}
            </ul>}
          <DialogFooter>
            <ActionButton
              onClick={() => {
                this.setState({ showdialog: false });
              }}
              text="Lukk"
            />
          </DialogFooter>
        </Dialog>
        <div className="ms-Grid-row">
        <div className="oppgaveKnapper">
          <div className="flex-button">
            <ActionButton iconProps={{ iconName: "Delete" }} text="Slett" onClick={()=>{
              if (confirm("Vil du virkelig slette denne meldingen?")) {
                this.props.deleteNewsElement({ Id: this.props.newselement.Id });
                this.setState({showNewsElement : false})
              }
            }} />
          <ActionButton
               text="Kopier til ny gruppe"
               style={{textAlign: "left"}}
               iconProps={{ iconName: "Copy" }}
               onClick={() => {
                 this.props.history.push(
                   `/meldinger/${this.props.newselement.Id}/c`
                 );
               }}
             />
          </div>
          <div className="flex-button align-right">
          <ActionButton
              onClick={() => {
                this.props.history.push(
                  "/meldinger/" + this.props.newselement.Id
                );
                
              }}
              iconProps={{ iconName: "Edit" }}
              text="Rediger"
            />
          </div>
        </div>
      </div>
      </li>
    
  }}
    
}
function mapStateToProps(state) {
  //Classes
      const allClasses = Object.values(state.teacher.subscribedSchoolClasses).concat(Object.values(state.teacher.availableSchoolClasses));
    //Courses
      const allCourses = Object.values(state.teacher.subscribedCourses).concat(Object.values(state.teacher.availableCourses));
      
  return {
    newsfiles: state.ukeplanelements.NewsFiles,
    teacherState: state.teacher,
    courses: state.teacher.subscribedCourses,
    allClasses,
    allCourses,
    compactCardView: state.ukeplanelements.CompactCardView
  };
}
// export default connect(mapStateToProps, {})(NewsElementView);
export default connect(mapStateToProps, { getNewsFiles, deleteNewsElement, getTeacherState, getAvailableSchoolClasses })(
  NewsElementView
);
// export default withRouter(NewsElementView)
