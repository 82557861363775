import * as React from "react";
import { gradeQuizAnswer } from "../../actions/Action_Quiz";
import { connect } from "react-redux";
import QuizGraderPoints from "./QuizGraderPoints";
import QuizGraderForm from "./QuizGraderForm";
// import {Nav} from 'react-bootstrap';
// import { Tab,Tabs } from 'react-bootstrap';
export interface IQuizFastgraderProps {
  quiz: any;
  gradeQuizAnswer(QuizAttemptAnswer: any, Points: number);
}
export interface IQuizFastgraderState {
  quiz: any;
  key: string;
  pointholder: any;
}
class QuizFastgrader extends React.Component<
  IQuizFastgraderProps,
  IQuizFastgraderState
> {
  constructor(props) {
    super(props);
    let p = {};
    if (this.props.quiz) {
      Object.values(this.props.quiz.Attempts).forEach((element: any) => {
        p[element.Id] = element;
      });
    }

    this.state = {
      quiz: this.props.quiz,
      key: "0",
      pointholder: p,
    };
  }
  componentDidUpdate(prevProps, prevState, snapshot) {
    if (
      this.props.quiz &&
      this.props.quiz.Id != prevProps.quiz.Id &&
      !this.state.pointholder
    ) {
      let p = {};
      this.props.quiz.Attempts.forEach((element) => {
        p[element.Id] = element;
      });

      this.setState({ pointholder: p });
    }
  }
  mapToCssClass = (answer: any, question: any): string => {
    let selected = false;
    Object.keys(answer.SelectedOptions).forEach((tmpAns) => {
      if (tmpAns == question.QID) {
        selected = true;
      }
    });

    if (selected && question.Correct) {
      return "selectedcorrect";
    }
    if (selected && !question.Correct) {
      return "selectedincorrect";
    }
    if (!selected && question.Correct) {
      return "notselectedcorrect";
    }
    if (!selected && !question.Correct) {
      return "notselectedincorrect";
    }
  };
  public render() {
    let totalclass = "";
    if (this.state.key == "0") {
      totalclass = "active";
    }

    return (
      <div>
        <ul className="nav nav-tabs mt-10" id="qnav">
          <li
            className="nav-item"
            onClick={() => {
              this.setState({ key: "0" });
            }}
          >
            <a href="javascript:void(0);" className={`nav-link ${totalclass}`}>
              Total
            </a>
          </li>
          {Object.values(this.props.quiz.Questions).map((que: any, index) => {
            let qclass = "";
            if (que.Id == this.state.key) {
              qclass = "active";
            }
            let i = index + 1;
            return (
              <li
                className="nav-item"
                onClick={() => {
                  this.setState({ key: que.Id });
                }}
              >
                <a href="javascript:void(0);" className={`nav-link ${qclass}`}>
                  {" "}
                  Spørsmål {i}
                </a>
              </li>
            );
          })}
        </ul>
        <div id="questionList">
          {this.state.key == "0" && (
            <div className="speedgrade" id="gradequestion-total"></div>
          )}

          {Object.values(this.props.quiz.Questions).map((que: any) => {
            return (
              <div>
                {que.Id == this.state.key && (
                  <div
                    style={{
                      display: "grid",
                      width: "100%",
                      marginTop: 10,
                      gridTemplateColumns:
                        "repeat(auto-fit, minmax(200px, 1fr))",
                      gridRowGap: ".5em",
                      gridColumnGap: "1em",
                    }}
                    id="gradequestion-@que.Id"
                  >
                    {this.props.quiz.Attempts &&
                      Object.values(this.props.quiz.Attempts).map((qa: any) => {
                        return qa.Answers.map((qaa) => {
                          let gQuestion: any = Object.values(
                            this.props.quiz.Questions
                          ).find((ques: any) => ques.Id == qaa.QuestionID);
                          let gAnswer: any = Object.values(
                            this.props.quiz.Answers
                          ).find((ans: any) => ans.Id == qaa.Id);
                          let gAttempts: any = Object.values(
                            this.props.quiz.Attempts
                          ).find((att: any) => att.Id == qaa.QAid);
                          if (qaa.QuestionID == que.Id) {
                            return (
                              <div
                                style={{
                                  borderTop: "3px solid #909090",
                                  backgroundColor: "#f1f1f1",
                                  padding: 10,
                                }}
                              >
                                <h5>Elev: {qa.StudentName} </h5>
                                <span className="quizPoints">
                                  <span className="ms-label">Poeng:</span>{" "}
                                  <input
                                    id="answer-@qaa.Id"
                                    data-quizattemptanswerid="@@item.gAttempts.Id"
                                    data-studentid="@qaa.Attempt.Id"
                                    className="quizPoints-@qaa.Attempt.Id ms-TextField-field"
                                    type="number"
                                    defaultValue={qaa.Points}
                                    min="@que.QMinPoints"
                                    max="@que.QMaxPoints"
                                    onChange={(event) => {
                                      const target = event.target;
                                      const value =
                                        target.type === "checkbox"
                                          ? target.checked
                                          : target.value;
                                      let deepClone = JSON.parse(
                                        JSON.stringify(this.state.pointholder)
                                      );
                                      deepClone[gQuestion.Id] = parseInt(
                                        value.toString()
                                      );
                                      let newtotal = 0;
                                      Object.keys(deepClone).forEach((it) => {
                                        newtotal += deepClone[it];
                                      });
                                      this.setState({
                                        pointholder: deepClone,
                                      });
                                      this.props.gradeQuizAnswer(
                                        gAnswer,
                                        value ? Number(value) : 0
                                      );
                                    }}
                                  />
                                  <span> / {que.QMaxPoints}</span>
                                </span>

                                {que.QType == "4" && (
                                  <span
                                    dangerouslySetInnerHTML={{
                                      __html: qaa.Answer,
                                    }}
                                    className="quizcomment"
                                  ></span>
                                )}
                                {gQuestion.Options && (
                                  <ul className="gradingList">
                                    {gQuestion.Options.map((o: any) => {
                                      return (
                                        <li
                                          className={this.mapToCssClass(qaa, o)}
                                        >
                                          {o.Text}
                                        </li>
                                      );
                                    })}
                                  </ul>
                                )}
                              </div>
                            );
                          }
                        });
                      })}
                    {/* {que.AllAnswers.map(qaa => {
                      return (
                        <div className="student-block">
                          <h5 className="quiz-student-name">
                            Elev: {qaa.Attempt.StudentName} |{" "}
                          </h5>
                          <span className="quizPoints">
                            <span className="ms-label">Poeng:</span>{" "}
                            <input
                              id="answer-@qaa.Id"
                              data-quizattemptanswerid="@qaa.Id"
                              data-studentid="@qaa.Attempt.Id"
                              className="quizPoints-@qaa.Attempt.Id ms-TextField-field"
                              type="number"
                              value="@qaa.Points"
                              min="@que.QMinPoints"
                              max="@que.QMaxPoints"
                            />
                            <span> / {que.QMaxPoints}</span>
                          </span>

                          {que.QType == "4" && <span>{qaa.Answer}</span>}

                          <ul className="gradingList">
                            {qaa.Question.Options.map(o => {
                              return <li className={o.Selected}>{o.Text}</li>;
                            })}
                          </ul>
                        </div>
                      );
                    })} */}
                  </div>
                )}
              </div>
            );
          })}
        </div>

        <div id="studentPoints">
          <h4 className="mt-10"> Total oversikt alle elever</h4>
          <div className=" table-responsive">
            <table className="table table-total">
              <thead>
                <tr>
                  <th>Navn</th>
                  <th>Poeng</th>
                  <th style={{ width: "70%" }}>Karakter</th>
                </tr>
              </thead>
              <tbody>
                {Object.values(this.props.quiz.Attempts).map((student: any) => {
                  // console.log('gradingmap',student);
                  // console.log('gradinganswers1',this.state.quiz);
                  // console.log('gradinganswers2',this.props.quiz);
                  let gAnswer: any = Object.values(
                    this.props.quiz.Answers
                  ).find((ans: any) => ans.QAid == student.Id);
                  var studentanswers = Object.values(
                    this.props.quiz.Answers
                  ).filter(function (ans: any) {
                    //console.log('gradingcheck',ans.);
                    return ans.QAid == student.Id;
                  });
                 
				  const reducer = (accumulator, item) => {
					return accumulator + parseInt(item.Points);
				  };
                  let points = studentanswers.reduce( reducer,
                  0);
                  console.log("gradingpoints", points);
                  return (
                    <tr
                      className="studentattemptrow"
                      data-attemptid="@student.Id"
                    >
                      <td>
                        <span className="studentName">
                          <b>{student.StudentName}</b>
                        </span>
                      </td>
                      <td className="studenttotal" data-attemptid="@student.Id">
                        <span id="totalPoints-@student.Id">
                          Har til sammen{" "}
                          <span className="count">
                            {points} {student.MaxPoints ? `av ${student.MaxPoints}` : ""} poeng
                          </span>
                        </span>
                      </td>
                      <td className="studenttotal" data-attemptid="@student.Id">
                        <QuizGraderForm
                          form={"fastgradestudent-" + student.Id}
                          initialValues={student}
                          points={points}
                        ></QuizGraderForm>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    );
  }
}
function mapStateToProps(state, ownProps) {
  // if(this.state.Quiz)
  // {
  return {
    quiz: state.Quiz.Quiz,
  };
}

export default connect(mapStateToProps, { gradeQuizAnswer })(QuizFastgrader);
