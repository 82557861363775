import axios from "axios";
import * as _ from "lodash";
import { ROOT_URL, SP_HOST_URL } from "./constants";

export const GET_UKEPLAN_ELEMENT = "GET_UKEPLAN_ELEMENT";
export const GET_UKEPLAN_ELEMENTS = "GET_UKEPLAN_ELEMENTS";
export const GET_UKEPLANSTUDENT_ELEMENTS = "GET_UKEPLANSTUDENT_ELEMENTS";
export const UPDATE_UKEPLAN_ELEMENT = "UPDATE_UKEPLAN_ELEMENT";
export const DELETE_UKEPLAN_ELEMENT = "DELETE_UKEPLAN_ELEMENT";
export const GET_STUDENTS = "GET_STUDENTS";
export const GET_COURSE_ASSIGNMENTGROUPS = "GET_COURSE_ASSIGNMENTGROUPS";
export const GET_TASK_ELEMENT = "GET_TASK_ELEMENT";
export const UPDATE_TASK_ELEMENT = "UPDATE_TASK_ELEMENT";
export const GET_TASK_ELEMENTEXT = "GET_TASK_ELEMENTEXT";
export const DISTRIBUTE_TASK = "DISTRIBUTE_TASK";
export const UPDATE_MESSAGE_ELEMENT = "UPDATE_MESSAGE_ELEMENT";
export const GET_NEWS_ELEMENT = "GET_NEWS_ELEMENT";
export const DELETE_NEWS_ELEMENT = "DELETE_NEWS_ELEMENT";

export const DELETE_WSTASK_ELEMENT = "DELETE_WSTASK_ELEMENT";

export const GET_UKEPLAN_FILES = "GET_UKEPLAN_FILES";
export const GET_NEWS_FILES = "GET_NEWS_FILES";
export const GET_IMMERSIVE_TOKEN = "GET_IMMERSIVE_TOKEN";
export const SET_UKEPLAN_SELECTED_DATE = "SET_UKEPLAN_SELECTED_DATE";
export const SET_COMPACT_CARD_VIEW_TOGGLE = "SET_COMPACT_CARD_VIEW_TOGGLE";
// const jwtDecode = require("jwt-decode");
// import * as moment from "moment";
// axios.interceptors.request.use(
//   async (config) => {
//     const tokenExpirationTime = moment(
//       jwtDecode(localStorage.getItem("id_token")).exp,
//       "DD/MM/YYYY H:mm"
//     ).unix();

//     const expiryThreshold = moment().add(2, "days").unix();
//     if (tokenExpirationTime < expiryThreshold && config.url.indexOf('GetAuth')<0) {
//       console.log("interceptor", "expired");
      
//     }
//     console.log('interceptor',config);
//     return config;
//   },
//   function (error) {
//     return Promise.reject(error);
//   }
// );
export function getUkeplanElements(statedata: any, week: number, year: number) {
  const data: FormData = new FormData();
  data.set(
    "classes",
    JSON.stringify(_.values(statedata.subscribedSchoolClasses))
  );
  data.set("schools", JSON.stringify(_.values(statedata.schools)));
  data.set("courses", JSON.stringify(_.values(statedata.subscribedCourses)));

  // const newState = {
  //     schoolClasses: _.values(statedata.schoolClasses),
  //     courses: _.values(statedata.courses),
  //     schools: _.values(statedata.schools),
  // }
  const request = axios.post(
    `${ROOT_URL}/GetTeacherAssignmentPart/${week}-${year}${SP_HOST_URL}`,
    data,
    {
      headers: {
        Authorization: localStorage.getItem("id_token"),
      },
    }
  );

  return {
    type: GET_UKEPLAN_ELEMENTS,
    payload: request,
  };
}
export function getUkeplanElementsStudent(
  statedata: any,
  week: number,
  year: number
) {
  statedata.Wsweek = week;
  statedata.Wsyear = year;
  const request = axios.post(
    `${ROOT_URL}/GetStudentWeek/${week}-${year}${SP_HOST_URL}`,
    statedata,
    {
      headers: {
        Authorization: localStorage.getItem("id_token"),
      },
    }
  );

  return {
    type: GET_UKEPLANSTUDENT_ELEMENTS,
    payload: request,
  };
}
export function getPlanElement(Id: number) {
  const request = axios.get(`${ROOT_URL}/GetPlanElement/${Id}${SP_HOST_URL}`, {
    headers: {
      Authorization: localStorage.getItem("id_token"),
    },
  });

  return {
    type: GET_UKEPLAN_ELEMENT,
    payload: request,
  };
}

export function getPlanElementFiles(Id: number) {
  const request = axios.get(
    `${ROOT_URL}/GetPlanElementFiles/${Id}${SP_HOST_URL}`,
    {
      headers: {
        Authorization: localStorage.getItem("id_token"),
      },
    }
  );

  return {
    type: GET_UKEPLAN_FILES,
    payload: request,
  };
}
export function getTaskElementFiles(Id: number) {
  const request = axios.get(
    `${ROOT_URL}/GetTaskElementFiles/${Id}${SP_HOST_URL}`,
    {
      headers: {
        Authorization: localStorage.getItem("id_token"),
      },
    }
  );

  return {
    type: GET_UKEPLAN_FILES,
    payload: request,
  };
}
export function getNewsElement(Id: number) {
  const request = axios.get(`${ROOT_URL}/GetNewsElement/${Id}${SP_HOST_URL}`, {
    headers: {
      Authorization: localStorage.getItem("id_token"),
    },
  });

  return {
    type: GET_NEWS_ELEMENT,
    payload: request,
  };
}
export function deleteNewsElement(n: any) {
  const request = axios.post(
    `${ROOT_URL}/DeleteNewsElement/${SP_HOST_URL}`,
    n,
    {
      headers: {
        Authorization: localStorage.getItem("id_token"),
      },
    }
  );

  return {
    type: DELETE_NEWS_ELEMENT,
    payload: request,
  };
}

export function getTaskElement(Id: number) {
  const request = axios.get(`${ROOT_URL}/GetTaskElement/${Id}${SP_HOST_URL}`, {
    headers: {
      Authorization: localStorage.getItem("id_token"),
    },
  });

  return {
    type: GET_TASK_ELEMENT,
    payload: request,
  };
}
export function getTaskElementExt(Id: number) {
  const request = axios.get(
    `${ROOT_URL}/GetTaskElementExt/${Id}${SP_HOST_URL}`,
    {
      headers: {
        Authorization: localStorage.getItem("id_token"),
      },
    }
  );

  return {
    type: GET_TASK_ELEMENTEXT,
    payload: request,
  };
}
export function distributeTask(data: any) {
  const request = axios.post(
    `${ROOT_URL}/DistributeTask/${SP_HOST_URL}`,
    data,
    {
      headers: {
        Authorization: localStorage.getItem("id_token"),
      },
    }
  );

  return {
    type: DISTRIBUTE_TASK,
    payload: request,
  };
}
export function distributeTeamsTask(data: any) {
  const request = axios.post(
    `${ROOT_URL}/DistributeTeamsTask/${SP_HOST_URL}`,
    data,
    {
      headers: {
        Authorization: localStorage.getItem("id_token"),
      },
    }
  );

  return {
    type: DISTRIBUTE_TASK,
    payload: request,
  };
}
export function getStudents(data: any) {
  const request = axios.post(`${ROOT_URL}/GetStudents/${SP_HOST_URL}`, data, {
    headers: {
      Authorization: localStorage.getItem("id_token"),
    },
  });

  return {
    type: GET_STUDENTS,
    payload: request,
  };
}
export function updatePlanElement(se, callback: any, errorCallback?: any) {
  const request = axios
    .post(`${ROOT_URL}/UpdatePlanElement${SP_HOST_URL}`, se, {
      headers: {
        Authorization: localStorage.getItem("id_token"),
      },
    })
    .then((resp) => callback(resp.data))
    .catch(error => {
      if (errorCallback) {
        errorCallback();
      }
    });

  return {
    type: UPDATE_UKEPLAN_ELEMENT,
    payload: request,
  };
}
export function updateMessageElement(se, callback: any, errorCallback?: any) {
  const request = axios
    .post(`${ROOT_URL}/UpdateMessage${SP_HOST_URL}`, se, {
      headers: {
        Authorization: localStorage.getItem("id_token"),
      },
    })
    .then(() => callback())
    .catch(error => {
      if (errorCallback) {
        errorCallback();
      }
    });

  return {
    type: UPDATE_MESSAGE_ELEMENT,
    payload: request,
  };
}
export function updateTaskElement(se, callback: any, errorCallback?: any) {
  const request = axios
    .post(`${ROOT_URL}/UpdateTaskElement${SP_HOST_URL}`, se, {
      headers: {
        Authorization: localStorage.getItem("id_token"),
      },
    })
    .then((resp) => callback(resp.data))
    .catch(error => {
      if (errorCallback) {
        errorCallback();
      }
    });

  return {
    type: UPDATE_TASK_ELEMENT,
    payload: request,
  };
}
export function updateTeamTaskElement(se, callback: any) {
  const request = axios
    .post(`${ROOT_URL}/UpdateTeamTaskElement${SP_HOST_URL}`, se, {
      headers: {
        Authorization: localStorage.getItem("id_token"),
      },
    })
    .then((resp) => callback(resp.data));

  return {
    type: UPDATE_TASK_ELEMENT,
    payload: request,
  };
}
export function deleteTaskElement(object, callback: () => void) {
  const request = axios
    .post(`${ROOT_URL}/DeleteTaskElement/${SP_HOST_URL}`, object, {
      headers: {
        Authorization: localStorage.getItem("id_token"),
      },
    })
    .then(() => callback());

  // Returns id of deleted post
  return {
    type: DELETE_WSTASK_ELEMENT,
    payload: object.Id,
  };
}
export function deletePlanElement(object, callback: () => void) {
  const request = axios
    .post(`${ROOT_URL}/DeletePlanElement/${SP_HOST_URL}`, object, {
      headers: {
        Authorization: localStorage.getItem("id_token"),
      },
    })
    .then(() => callback());

  // Returns id of deleted post
  return {
    type: DELETE_UKEPLAN_ELEMENT,
    payload: object.Id,
  };
}
export async function getAssignmentGroup(course: any) {
  const request = await axios.post(
    `${ROOT_URL}/GetAssignmentGroups${SP_HOST_URL}`,
    course,
    {
      headers: {
        Authorization: localStorage.getItem("id_token"),
      },
    }
  );

  return {
    type: GET_COURSE_ASSIGNMENTGROUPS,
    payload: request,
  };
}
export function getNewsFiles(news: any) {
  const request = axios.post(`${ROOT_URL}/GetNewsFiles${SP_HOST_URL}`, news, {
    headers: {
      Authorization: localStorage.getItem("id_token"),
    },
  });

  return {
    type: GET_NEWS_FILES,
    payload: request,
  };
}
export function getImmersiveToken() {
  const request = axios.get(`${ROOT_URL}/getimmersivetoken/${SP_HOST_URL}`, {
    headers: {
      Authorization: localStorage.getItem("id_token"),
    },
  });

  return {
    type: GET_IMMERSIVE_TOKEN,
    payload: request,
  };
}

export function setUkeplanSelectedDate(date: Date) {
  return {
    type: SET_UKEPLAN_SELECTED_DATE,
    payload: date
  }
}

export function setCompactCardViewToggle(compactView: boolean) {
  return {
    type: SET_COMPACT_CARD_VIEW_TOGGLE,
    payload: compactView
  }
}
