import * as _ from 'lodash';

import {ARCHIVE_MESSAGE, GET_MESSAGES, CHECK_KVITTERING_MESSAGES, DEARCHIVE_MESSAGE} from "../actions/Action_Message";

const KEY_INBOX = 'Inbox';
const KEY_OUTBOX = 'Outbox';
const KEY_SAVED = 'Saved';

const defaultState = {
    inbox: {},
    outbox: {},
    saved: {},
};

export default function (state = defaultState, action) {

    switch (action.type){

        case GET_MESSAGES:

            const messages = action.payload.data;

            const inbox = messages[KEY_INBOX];
            const outbox = messages[KEY_OUTBOX];
            const saved = messages[KEY_SAVED];

            const messageState = {
                inbox: _.mapKeys(inbox, 'Id'),
                outbox: _.mapKeys(outbox, 'Id'),
                saved: _.mapKeys(saved, 'Id')
            };

            return _.assign({}, state, messageState);

        case ARCHIVE_MESSAGE:

            const archivedMessage = action.payload;

            const inboxMessages = state.inbox;
            delete inboxMessages[archivedMessage.Id];

            const outboxMessages = state.outbox;
            delete outboxMessages[archivedMessage.Id];

            const savedMessages = state.saved;
            savedMessages[archivedMessage.Id] = archivedMessage;

            const archivedState = {
                inbox: inboxMessages,
                outbox: outboxMessages,
                saved: savedMessages,
            };
            return _.assign({}, state, archivedState);
        case DEARCHIVE_MESSAGE:
            const newSavedMessages = state.saved;
            
            return _.assign({}, state.saved, newSavedMessages)
        case CHECK_KVITTERING_MESSAGES:
            let kvitteringer = JSON.parse(action.payload.data);
            return {...state, kvitteringer: kvitteringer};

        default:
            return state;
    }
}