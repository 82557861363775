import * as React from "react";
import {Label} from "office-ui-fabric-react";
import ReactQuill from "react-quill";


const RichTextField = (props) => {

    const {
        input,
        required,
        meta: { touched, error },
        children
    } = props;

    return (
        <div className="ms-Grid-row">
            <div className="z-form-group">
                <div className="ms-Grid-col ms-sm12 ms-md4">
                    <Label required={required} className="z-form-label ms-Label">
                        {props.label}
                    </Label>
                </div>
                <div className="ms-Grid-col ms-sm12 ms-md8">
                    <ReactQuill value={input.value} onChange={(html) => {
                        input.onChange(html);
                    }}
                    />
                    {touched && error && <span className="error-message">{error}</span>}
                </div>
            </div>
        </div>
    );
};

export default RichTextField;