
import * as _ from 'lodash';
import * as moment from 'moment';
import {connect} from 'react-redux';
import {ScheduleElement} from "../models/ScheduleElement";
import {DATE_FORMAT, LOCALE, TIME_FORMAT} from "../components/timeplan/ScheduleElementDetailView";
import {updateScheduleElement, deleteScheduleElement, getScheduleElement} from "../actions/Action_ScheduleElement";
import {SchoolClass} from "../models/SchoolClass";
import {PlanElement} from "../models/PlanElement"


export default class PlanUtils {


    public mapPlanElementsToKeys(se: PlanElement){
        const options = _.values(se);
        const optionKeyList = [];

        options.forEach(se => {
            optionKeyList.push({key: se.CourseID, text: se.Title});
        });

        return optionKeyList;
    }
    public mapGroupElementsToKeys(options:any[], addEmpty?: boolean){
        const optionKeyList = [];
        
        if (addEmpty && addEmpty === true) {
            optionKeyList.push({key: null, text: 'Ingen tilretteleggingsgruppe'});
        }
        optionKeyList.push({key: 0, text: 'Alle'});
        
        options.forEach(se => {
            if (!(se.Name === "Alle" && options.findIndex(x => x.Name === "Alle") !== -1)) {
                optionKeyList.push({key: se.Id, text: se.Name});
            }
        });
        
        return optionKeyList;
    }

    public mapGroupElementsToKeysExperimental(options:any[], chosenKey?: string){
        const optionKeyList = [];
        //optionKeyList.push({key: null, text: 'Ingen tilretteleggingsgruppe'});
        optionKeyList.push({key: 0, text: 'Alle'});
        
        options.forEach(se => {
            if (!(se.Name === "Alle" && options.findIndex(x => x.Name === "Alle") !== -1)) {
                optionKeyList.push({key: se.Id, text: se.Name});
            }
        });

        if (chosenKey) {
            if(this.isGroupDeleted(options, chosenKey)) {
                optionKeyList.push({key: chosenKey, text: "Slettet"});
            }
        }

        return optionKeyList;
    }

    public mapStudentsToKeys(options:any[]){
        const optionKeyList = [];
        options.forEach(student => {
            optionKeyList.push({key: student.LoginName, text: student.Name})
        });
        return optionKeyList;
    }

    public isGroupDeleted(groupList: any[], group: string) {
        if (groupList.findIndex(x => x.Id == 0) == -1) {
            groupList.push({Id: 0, Name: "Alle"});
        }

        if (groupList.findIndex(x => x.Id == group) == -1) {
            return true;
        }
        else {
            return false;
        }
    }
    
    public getInitialValuesFromObject(se:PlanElement){
        return {
            Id: se.Id,
           Text:se.Text
          
           
        };
    }

    public getDefaultInitialValues(schoolClasses:SchoolClass[]){

        return {
            Id: '0',
            ClassID: schoolClasses.length ? schoolClasses[0].ClassID : '',
            Title: '',
            Color: 'zSheduleColorBlue',
            Start: moment().hours(8).minutes(0).locale(LOCALE).format(DATE_FORMAT),
            End: moment().hours(9).minutes(0).locale(LOCALE).format(TIME_FORMAT),
            DisplayLength: 'one-hour'
        }
    }
}