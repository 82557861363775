import * as React from 'react';

import SideBarLink from './SidebarLink';

class SidebarViewStudent extends React.Component {

    render(){
        return(
            <div className="sidebar-view">
                <SideBarLink path='home' iconName='Home' description='Ukeplan elev' color="#01b3ef" fullWidth={true}   />
                <SideBarLink path='/ukeplanstudent' iconName='Calendar' description='Ukeplan elev'  />
                <SideBarLink path='/innleveringerstudent' iconName='CompletedSolid' description='Innlevering elev'  />
                <SideBarLink path='/timeplanstudent' iconName='ViewAll' description='Timeplan elev'   />
                <SideBarLink path='/portefoljestudent' iconName='FabricOpenFolderHorizontal' description='Portefølje for elev'  />
                <SideBarLink path='/quizstudent' iconName='PageListSolid' description='Prøver'  />
            </div>
        )
    }
}

export default SidebarViewStudent;