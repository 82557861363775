import { IIdentitySet } from "../IIdentitySet";
import { IOutcome } from "../IOutcome";
import { IFeedback } from "./IFeedback";

export class FeedbackOutcome {
    id: string;
    lastModifiedBy: IIdentitySet;
    lastModifiedDateTime: Date;
    feedback: IFeedback;
    publishedFeedback: IFeedback;

    
    constructor(outcome: IOutcome) {
        this.id = outcome.id;
        this.lastModifiedBy = outcome.lastModifiedBy;
        this.lastModifiedDateTime = outcome.lastModifiedDateTime;
        this.feedback = outcome.feedback;
        this.publishedFeedback = outcome.publishedFeedback;
    }
}