import * as React from "react";
import { PlanElement } from "../../models/PlanElement";
import { ActionButton, DefaultButton } from "office-ui-fabric-react";
import HeaderView from "../view_containers/HeaderView";

export interface IPlanElementConfirmProps {
  planelement: PlanElement;
  history: any;
}
class PlanElementConfirmView extends React.Component<IPlanElementConfirmProps> {
  constructor(props) {
    super(props);
  }
  render() {
    if (this.props.planelement) {
      return (
        <div className="ms-Grid" id="TeacherTextDetail">
          <div className="main">
            <HeaderView
              title="Ukeplanen er delt ut"
              description="Nå kan du gå til ukeplanen eller kopiere ukeplanen og tilpasse den til en ny gruppe"
              iconName="Calendar"
            />
            <div className="card" style={{ maxWidth: "900px" }}>
              <div className="card-body">
                <div className="table-responsive">
                  <table className="table">
                    <tr>
                      <td className="distributeTableTitle">
                        <label
                          className="ms-Label"
                          style={{ textTransform: "uppercase" }}
                        >
                          Fag
                        </label>
                      </td>

                      <td>{this.props.planelement.CourseID}</td>
                    </tr>
                    <tr>
                      <td className="distributeTableTitle">
                        <label
                          className="ms-Label"
                          style={{ textTransform: "uppercase" }}
                        >
                          Tilretteleggingsgruppe
                        </label>
                      </td>

                      <td>{this.props.planelement.AssignmentGroup.Name}</td>
                    </tr>
                    <tr>
                      <td className="distributeTableTitle">
                        <label
                          className="ms-Label"
                          style={{ textTransform: "uppercase" }}
                        >
                          Tema
                        </label>
                      </td>

                      <td>{this.props.planelement.Theme}</td>
                    </tr>
                    <tr>
                      <td className="distributeTableTitle">
                        <label
                          className="ms-Label"
                          style={{ textTransform: "uppercase" }}
                        >
                          Kompetansemål
                        </label>
                      </td>
                      <td>
                        <ul>
                          {this.props.planelement.GrepCodeList.map(grp => {
                            return (
                              <li>
                                {grp.GrepKode} {" "}
                                {grp.GrepTekst}
                              </li>
                            );
                          })}
                        </ul>
                      </td>
                    </tr>
                    {
                      this.props.planelement.SkolonResources &&
                      <tr>
                        <td className="distributeTableTitle">
                          <label
                            className="ms-Label"
                            style={{ textTransform: "uppercase" }}
                          >
                            <img width='40px' src={require('../../images/SkolonIcon.svg')} />
                          </label>
                        </td>
                        <td>
                          <ul>
                            {
                              this.props.planelement.SkolonResources.map((item) => {
                                return (
                                  <li>
                                    <a href={item.url}>{item.name}</a>
                                  </li>
                                )
                              })
                            }
                          </ul>
                        </td>
                      </tr>
                    }
                    <tr>
                      <td className="distributeTableTitle">
                        <label
                          className="ms-Label"
                          style={{ textTransform: "uppercase" }}
                        >
                          Egendefinerte mål
                        </label>
                      </td>
                      <td>
                        <span className="list-group-unordered"
                          dangerouslySetInnerHTML={{
                            __html: this.props.planelement.Goal
                          }}
                        ></span>
                      </td>
                    </tr>

                    <tr>
                      <td className="distributeTableTitle">
                        <label
                          className="ms-Label"
                          style={{ textTransform: "uppercase" }}
                        >
                          Type vurdering
                        </label>

                      </td>

                      <td>
                        <ul>
                          {
                            this.props.planelement.EvalElements.map((evel => {
                              return (
                                <li>
                                  {evel}
                                </li>
                              )
                            }))
                          }
                        </ul>
                      </td>
                    </tr>
                    <tr>
                      <td className="distributeTableTitle">
                        <label
                          className="ms-Label"
                          style={{ textTransform: "uppercase" }}
                        >
                          Annen vurdering
                        </label>

                      </td>

                      <td>
                        <span className="list-group-unordered"
                          dangerouslySetInnerHTML={{
                            __html: this.props.planelement.EvaluationTextOther
                          }}
                        ></span>

                      </td>
                    </tr>
                    <tr>
                      <td className="distributeTableTitle">
                        <label
                          className="ms-Label"
                          style={{ textTransform: "uppercase" }}
                        >
                          Arbeid på skolen
                        </label>
                      </td>
                      <td>
                        <span className="list-group-unordered"
                          dangerouslySetInnerHTML={{
                            __html: this.props.planelement.TextOnPrem
                          }}
                        ></span>
                      </td>
                    </tr>
                    <tr>
                      <td className="distributeTableTitle">
                        <label
                          className="ms-Label"
                          style={{ textTransform: "uppercase" }}
                        >
                          Arbeid hjemme
                        </label>
                      </td>

                      <td>
                        <span className="list-group-unordered"
                          dangerouslySetInnerHTML={{
                            __html: this.props.planelement.Text
                          }}
                        ></span>
                      </td>
                    </tr>
                    <tr>
                      <td className="distributeTableTitle">
                        <label
                          className="ms-Label"
                          style={{ textTransform: "uppercase" }}
                        >
                          Lenke
                        </label>
                      </td>

                      <td>{this.props.planelement.Link}</td>
                    </tr>
                    <tr>
                      <td className="distributeTableTitle">
                        <label
                          className="ms-Label"
                          style={{ textTransform: "uppercase" }}
                        >
                          Frist
                        </label>
                      </td>

                      <td>{this.props.planelement.DateText}</td>
                    </tr>
                    <tr>
                      <td className="distributeTableTitle">
                        <label
                          className="ms-Label"
                          style={{ textTransform: "uppercase" }}
                        >
                          Uke
                        </label>
                      </td>

                      <td>{this.props.planelement.Week}</td>
                    </tr>
                  </table>
                </div>
              </div>
              <div className="card-footer">
                <div className="footer-buttons">
                  <ActionButton
                    text="Kopier til ny gruppe"
                    iconProps={{ iconName: "Copy" }}
                    onClick={() => {
                      this.props.history.push(
                        `/ukeplan/${this.props.planelement.Id}/c`
                      );
                    }}
                  />
                  <ActionButton
                    text="Gå til ukeplan"
                    iconProps={{ iconName: "Calendar" }}
                    onClick={() => {
                      this.props.history.push(`/ukeplan`);
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    } else {
      return <div>laster</div>;
    }
  }
}
export default PlanElementConfirmView;
