import * as React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import ReactQuill from "react-quill"; // Typescript
import EvalForm from "../innleveringer/EvalForm";
import { Check } from "office-ui-fabric-react/lib/Check";
import { getStudentFiles } from "../../actions/Action_Innleveringer";
import {
  submitResource,
  getAssignmentOutcomes,
} from "../../actions/Action_Graph";
import {
  DefaultButton,
  Dialog,
  DialogFooter,
  DialogType,
  ActionButton,
  PrimaryButton,
  IconButton,
} from "office-ui-fabric-react";
import PlagStatus from "../innleveringer/PlagStatus";
import { TeamSubmission } from "../../models/graph/TeamSubmission";
import EvalFormTeams from "../innleveringer/EvalFormTeams";

export interface IInnleveringStatusViewItemTeamsProps {
  studenttask: any;
  history: any;
  task: any;
  key: any;
  itemIndex: any;
  selection: any;
  studentfiles: any;
  savestatus: string;
  showeval: boolean;
  sign: string;
  submission: TeamSubmission;
  classid: string;
  assignmentid: string;
  outcome: any;
  outcomz:any;
  getStudentFiles(studenttask: any);
  submitResource(
    classid: string,
    assignmentid: string,
    resource: any,
    resheader: any
  );
  getAssignmentOutcomes(classid: string, assignmentid: string, submission: any);
}
export interface IInnleveringStatusViewItemTeamsState {
  ischecked: boolean;
  showCopyScheduleModal: boolean;
  resources: any[];
  studenttask:any;
}

class InnleveringStatusViewItemTeams extends React.Component<
  IInnleveringStatusViewItemTeamsProps,
  IInnleveringStatusViewItemTeamsState
> {
  constructor(props) {
    super(props);
    this.state = {
      ischecked: this.props.studenttask.ShowEval,
      showCopyScheduleModal: false,
      resources: [],
      studenttask:this.props.studenttask
    };
  }
  componentDidMount() {
    console.log("item", this.props);
    
    // if (
    //   this.props.submission.outcomz &&
    //   (this.props.submission.outcomz.value[0].feedback ||
    //     this.props.submission.outcomz.value[2].publishedRubricQualityFeedback
    //       .length > 0)
    // ) {
    //   console.log("has");
    // }
    // this.props
    //   .getAssignmentOutcomes(
    //     this.props.classid,
    //     this.props.assignmentid,
    //     this.props.submission
    //   )
    //   .then((resp) => {
    //     // console.log('outcome',resp);
    //   });
  }
  componentWillReceiveProps(props) {
     console.log('itemprops',props);
    this.setState({ ischecked: props.showeval,studenttask:props.studenttask });
  }
  render() {
    //  console.log('submission',this.props.submission);
    let ur = this.props.studenttask.UrkundSignificance;
    if (this.props.sign.length > 0) {
      ur = this.props.sign;
    }
    return (
      <li
        data-is-focusable={true}
        data-selection-index={this.props.itemIndex}
        className="ms-ListItem innleveringItem @cc is-selectable @dd"
      >
        
        <div className="ms-Grid-row">
          <div className="ms-Grid-col ms-sm12 ms-lg4">
            <div className="plag">
              <PlagStatus reporturl="" significance={ur}></PlagStatus>
            </div>
            <span className="ms-ListItem-primaryText">
              <Link to={"/innleveringer/student/" + this.props.studenttask.Id}>
                {" "}
                {this.props.submission.student.displayName}
              </Link>
            </span>
          
            <span className="ms-ListItem-secondaryText"></span>
            <span className="ms-ListItem-metaText">...</span>
            <div className="plag"> </div>
          </div>
          <div className="ms-Grid-col ms-sm12 ms-lg8">
            <div className="ms-ListItem-actions">
              <div className="ms-ListItem-action tooltipContainer">
                <i
                  className={
                    "ms-Icon ms-Icon--InboxCheck " +
                    (this.props.studenttask.DeliveryStatus != "1" ||
                    (this.props.submission &&
                      (this.props.submission.graphSubmission.status == "returned" ||
                        this.props.submission.graphSubmission.status == "submitted"))
                      ? "ms-fontColor-green"
                      : this.props.studenttask.DeliveryStatus)
                  }
                 
                ></i>
                <span className="tooltiptext">
                  Innlevert av elev {this.props.studenttask.DeliveryStatus}
                </span>
              </div>

              <div className="ms-ListItem-action tooltipContainer">
                <i
                  id="graded-this.props.studenttask.Id"
                  className={
                    "ms-Icon ms-Icon--RedEye " +
                    (this.props.studenttask.DeliveryStatus == "2"
                      ? "ms-fontColor-green"
                      :  (this.props.submission && this.props.submission.graphSubmission.status == "returned"
                      ? "ms-fontColor-yellow"
                      :  ""))
                  }
                  onClick={()=>{
                    console.log(this.props.outcomz);
                    console.log(this.state.studenttask);
                    let tmp = JSON.parse(JSON.stringify(this.state.studenttask));
                    tmp.levelmaprev = this.props.outcomz.levelmaprev;
                    tmp.GradingComment = this.props.outcomz.value[0].feedback.text.content;
                    tmp.Uipkms.forEach(element => {
                      element.Eval = this.props.outcomz.km[element.Kmid].levelset;
                    });
                    tmp.DeliveryStatus ='2';
                    this.setState({studenttask:tmp});
                  }}
                ></i>
                <span className="tooltiptext">Ikke sett av lærer</span>
              </div>
              <div className="ms-ListItem-action tooltipContainer">
                <i
                  className={
                    "ms-Icon ms-Icon--CheckMark " +
                    (this.props.studenttask.ShowEval
                      ? "ms-fontColor-green"
                      : "")
                  }
                ></i>
                <span className="tooltiptext">Ferdig vurdert</span>
              </div>
            </div>
          </div>
        </div>
        <div className="ms-Grid-row">
          <div className="ms-Grid-col ms-sm12 text-right">
            {this.props.submission &&
              this.props.submission.graphSubmission.resources.length > 0 && (
                <ActionButton
                  onClick={() => {
                    //this.props.getStudentFiles(this.props.studenttask);
                    this.setState({
                      showCopyScheduleModal: true,
                      resources: this.props.submission.graphSubmission.resources,
                    });
                  }}
                  iconProps={{ iconName: "Attach" }}
                  text="Se filer"
                />
              )}
            {/* {this.props.submission &&
            <div>
              {this.props.submission.status}
              <ul>
                {
                  this.props.submission.resources.map(res=>{
                   
                   
                  
                  })
                }
              </ul>
              </div>
              } */}
          </div>
        </div>
       
        {
        // (this.state.studenttask.DeliveryStatus == "1"  &&(this.props.submission && this.props.submission.status=='submitted')||this.state.studenttask.DeliveryStatus == "2"  &&(this.props.submission && this.props.submission.status=='returned')) &&
         ((this.props.submission && this.props.submission.graphSubmission.status=='submitted')||this.state.studenttask.DeliveryStatus == "2" ) &&
          <EvalFormTeams
          task={this.state.studenttask}
          fromprops={this.state.studenttask}
          showeval={this.state.ischecked}
          singleview={false}
          submission={this.props.submission}
        ></EvalFormTeams>
        }
      
        {this.state.showCopyScheduleModal && (
          <Dialog
            hidden={false}
            onDismiss={() => this.setState({ showCopyScheduleModal: false })}
            dialogContentProps={{
              type: DialogType.normal,
              title: "Filer som hører til innlevering-",
              subText: "",
            }}
            styles={{
              main: [
                {
                  selectors: {
                    ["@media (min-width: 480px)"]: {
                      maxWidth: "600px",
                      minWidth: "480px",
                    },
                  },
                },
              ],
            }}
            modalProps={{
              titleAriaId: "myLabelId",
              subtitleAriaId: "mySubTextId",
              isBlocking: true,
              containerClassName: "ms-dialogMainOverride",
            }}
          >
            <div>
              {this.state.resources && (
                <ul className="no-type-list">
                  {this.state.resources.map((res) => {
                    // console.log('resource',res);
                    if (res.plaginfo) {
                      //  console.log('resource2',res.plaginfo);
                      // console.log('resplaginfo',res.plaginfo);
                      return (
                        <li>
                          {res.resource.displayName}
                          <div className="plgImg" data-plagblock="@item.Id">
                            {res.plaginfo.urkundState == "Analyzed" && (
                              <div>
                                <PlagStatus
                                  reporturl={res.plaginfo.urkundReportUrl}
                                  significance={res.plaginfo.urkundSignificance}
                                ></PlagStatus>
                              </div>
                            )}
                            {res.plaginfo.urkundState != "Analyzed" && (
                              <span>{res.plaginfo.urkundState}</span>
                            )}
                          </div>
                        </li>
                      );
                    }
                    if (!res.plaginfo) {
                      return (
                        <li>
                          {res.resource.displayName}
                          {!res.plaginfo && (
                            <IconButton
                              iconProps={{ iconName: "RevToggleKey" }}
                              className="remove-icon"
                              onClick={() =>
                                this.props.submitResource(
                                  this.props.classid,
                                  this.props.assignmentid,
                                  res.resource,
                                  res
                                )
                              }
                            />
                          )}
                        </li>
                      );
                    }
                  })}
                </ul>
              )}
            </div>

            <DialogFooter>
              <DefaultButton
                onClick={() => {
                  this.setState({ showCopyScheduleModal: false });
                }}
                text="Lukk"
              />
            </DialogFooter>
          </Dialog>
        )}
      </li>
    );
  }
}
function mapStateToProps(state) {
  console.log("staten", state);
  state.GraphReducer.currentassignmentoutcomes.forEach((element) => {});
  return {
    studentfiles: state.tasks.newStatestudentfiles,
  };
}
export default connect(mapStateToProps, {
  getStudentFiles,
  submitResource,
  getAssignmentOutcomes,
})(InnleveringStatusViewItemTeams);
