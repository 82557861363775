import * as React from "react";
import { Calendar, momentLocalizer } from 'react-big-calendar'
import * as moment from "moment";

import * as _ from "lodash";
import { connect } from "react-redux";

import HeaderView from "../view_containers/HeaderView";
import {
  copyScheduleElements,
  getScheduleElements,
  deleteMultipleScheduleElement
} from "../../actions/Action_ScheduleElement";
import {
  resetSelectedWeekAndYear,
  setSelectedClass,
  setSelectedWeek,
  setSelectedYear,
} from "../../actions/Action_Filtervalues";
import ReactTooltip from 'react-tooltip';
import {CustomToolbar} from "./TimeplanToolbar";
import {
  ActionButton,
  CommandBarButton,
  DefaultButton,
  Dialog,
  DialogFooter,
  DialogType,
  Dropdown,
  Icon,
  PrimaryButton,
  Stack,
  Toggle,
  IconButton
} from "office-ui-fabric-react";
import store from "react-notifications-component";

import { ScheduleElement } from "../../models/ScheduleElement";
import StyleUtils from "../../utils/StyleUtils";
import { SchoolClass } from "../../models/SchoolClass";
import { CALENDAR_MAX, CALENDAR_MIN } from "../../utils/DateUtils";
import SidebarActionButtonList from "../sidebar/SidebarActionButtonList";
import { CopySchedule } from "../../models/CopySchedule";
import { LoadingDialog } from "../loading/LoadingDialog";
import "./timeplan.scss";
import TemplatePanel from "./TemplatePanel";
import axios from "axios";
import { ROOT_URL, SP_HOST_URL } from "../../actions/constants";
import { ClassAssignmentGroup } from "../../models/ClassAssignmentGroup";
moment.locale("nb");

export interface ITimeplanViewProps {
  scheduleElements: {};
  subscribedSchoolClasses: {};
  history: any;

  getScheduleElements(schoolClass: string, week: number, year: number, tilretteleggingsgruppeId?: number);
  copyScheduleElements(copySchedule: CopySchedule, callback: any);
  deleteMultipleScheduleElement(scheduleElementList: ScheduleElement[]);
  setSelectedClass(key: string);
  resetSelectedWeekAndYear();
  setSelectedWeek(week: number);
  setSelectedYear(year: number);
  selectedclass: string;
  selectedWeek?: number;
  selectedYear?: number;
}

export interface ITimeplanViewState {
  // Big Calendar
  selectedDate: any;
  selectedWeek: number;
  selectedYear: number;
  selectedClass: SchoolClass;
  scheduleElementsToDelete: ScheduleElement[];
  deletedElements: ScheduleElement[];

  // Modals
  showDateSelectModal: boolean;
  showCopyScheduleModal: boolean;
  showCopyScheduleLoadingModal: boolean;

  // Dropdown
  dropdownSelectedWeek: any;
  dropdownSelectedYear: any;
  dropdownSelectedClassAssignmentGroup: any;
  dropdownSelectedClassAssignmentGroupOptions: ISelectOption[];
  dropdownSelectedClass: any;
  dropdownWeekOptions: any;
  dropdownYearOptions: any;
  showTemplatePanel: boolean;

  //Edit Bar
  deleteMode: boolean;
  overlappingElementer: boolean;

  //Link Modal
  showLinkModal: boolean;
  selectedLinks: string;
}

interface ISelectOption {
  key: number;
  text: string;
}

const getWeekOptions = (): ISelectOption[] => {
  const weekOptions = [];

  for (let i = 1; i <= 52; i++) {
    weekOptions.push({ key: i, text: i.toString() });
  }

  return weekOptions;
};

const getYearOptions = (): ISelectOption[] => {
  const yearOptions = [];

  for (let i = -1; i <= 1; i++) {
    yearOptions.push({
      key: moment(new Date()).year() + i,
      text: (moment(new Date()).year() + i).toString(),
    });
  }

  return yearOptions;
};

const getCurrentWeek = (): number => moment(new Date()).week();

const getCurrentYear = (): number => moment(new Date()).year();

export const getInitialDate = (selectedWeek?: number, selectedYear?: number): Date =>
  selectedWeek && selectedYear
    ? moment(new Date()).year(selectedYear).week(selectedWeek).day(1).toDate()
    : new Date();

class TimeplanView extends React.Component<
  ITimeplanViewProps,
  ITimeplanViewState
> {
  constructor(props) {
    super(props);

    this.onSelectEvent = this.onSelectEvent.bind(this);
    this.onSelectedSchoolClass = this.onSelectedSchoolClass.bind(this);
    this.renderSchoolClassDropdown = this.renderSchoolClassDropdown.bind(this);
    this.filterEventsBySelectedSchoolClass = this.filterEventsBySelectedSchoolClass.bind(
      this
    );
  }

  /****************************
     Lifecycle hooks
     ****************************/

  async componentDidMount() {
    const { selectedWeek, selectedYear } = this.props;

    const date = getInitialDate(selectedWeek, selectedYear);
    const week = selectedWeek || getCurrentWeek();
    const year = selectedYear || getCurrentYear();

    let schoolClass = _.values(
      this.props.subscribedSchoolClasses
    )[0] as SchoolClass;

    if (schoolClass) {
      let cid = schoolClass.ClassID;

      if (this.props.selectedclass) {
        cid = this.props.selectedclass;
        schoolClass = this.props.subscribedSchoolClasses[cid];
      } else {
        this.props.setSelectedClass(schoolClass.ClassID);
      }

      const weekOptions = getWeekOptions();
      const yearOptions = getYearOptions();
      const classAssignmentGroupOptions = await this.getClassAssignmentGroupOptions(cid);

      this.setState(
        {
          // Dropdown
          dropdownSelectedWeek: week,
          dropdownSelectedYear: year,
          dropdownSelectedClass: cid,
          dropdownWeekOptions: weekOptions,
          dropdownYearOptions: yearOptions,
          dropdownSelectedClassAssignmentGroup: -1,
          dropdownSelectedClassAssignmentGroupOptions: classAssignmentGroupOptions,

          // Modals
          showDateSelectModal: false,
          showCopyScheduleLoadingModal: false,
          showCopyScheduleModal: false,

          // Calendar state
          selectedDate: date,
          selectedWeek: week,
          selectedYear: year,
          selectedClass: schoolClass,
          scheduleElementsToDelete: [],
          deletedElements: [],

          //Edit Bar
          deleteMode: false,
          overlappingElementer: true
        },
        () => {
          this.props.getScheduleElements(cid, week, year);
          //this.props.resetSelectedWeekAndYear();
        }
      );
    }
  }

  /****************************
     Event Handlers
     ****************************/

  onSelectEvent(se: ScheduleElement) {
    if (this.state && !this.state.deleteMode) {
      this.props.history.push("/timeplan/" + se.Id);
    }
    else if (this.state) {
      if (this.state.scheduleElementsToDelete.indexOf(se) !== -1) {
        this.setState({
          scheduleElementsToDelete: this.state.scheduleElementsToDelete.filter(element => element !== se)
        })
      }
      else {
        this.setState({
          scheduleElementsToDelete: [ ...this.state.scheduleElementsToDelete, se]
        })
      }
    }
  }

  async onSelectedSchoolClass(value) {
    const { key } = value;

    const selectedClass = this.props.subscribedSchoolClasses[key];
    this.props.setSelectedClass(value.key.toString());
    let classAssignmenGroupOptions = await this.getClassAssignmentGroupOptions(selectedClass.ClassID);
    this.setState(
      {
        selectedClass: selectedClass,
        dropdownSelectedClassAssignmentGroup: -1,
        dropdownSelectedClassAssignmentGroupOptions: classAssignmenGroupOptions
      },
      () => {
        this.props.getScheduleElements(
          selectedClass.ClassID,
          this.state.selectedWeek,
          this.state.selectedYear,
          this.state.dropdownSelectedClassAssignmentGroup
        );
      }
    );
  }

  onWeekChanged(date) {
    const week = moment(date).week();
    const year = moment(date).year();
    console.log("YEAR:", year.toString());
    this.setState(
      {
        selectedWeek: week,
        selectedYear: year,
        //selectedDate:date
      },
      () => {
        this.props.setSelectedWeek(week);
        this.props.setSelectedYear(year);
        this.props.getScheduleElements(
          this.state.selectedClass.ClassID,
          week,
          year,
          this.state.dropdownSelectedClassAssignmentGroup
        );
      }
    );
  }

  onChangedClassAndWeek(value, date) {
    const selectedClass = this.props.subscribedSchoolClasses[value];
    this.props.setSelectedClass(value);
    const week = moment(date).week();
    const year = moment(date).year();

    const newDate = moment(new Date()).year(year).week(week).toDate();

    this.setState(
      {
        selectedClass: selectedClass,
        selectedWeek: week,
        selectedYear: year,
        selectedDate: newDate,
      },
      () => {
        this.props.getScheduleElements(
          this.state.selectedClass.ClassID,
          week,
          year,
          this.state.dropdownSelectedClassAssignmentGroup
        );
      }
    );
  }
  onCalendarDateSet() {
    const newDate = moment(new Date())
      .year(this.state.dropdownSelectedYear)
      .week(this.state.dropdownSelectedWeek)
      .toDate();

    this.setState(
      {
        selectedDate: newDate,
        showDateSelectModal: false,
      },
      () => {
        this.props.getScheduleElements(
          this.state.selectedClass.ClassID,
          this.state.dropdownSelectedWeek,
          this.state.dropdownSelectedYear,
          this.state.dropdownSelectedClassAssignmentGroup
        );
      }
    );
  }

  onCopyScheduleSubmit() {
    this.setState({
      showCopyScheduleModal: false,
    });

    const calendarWeek = moment(this.state.selectedDate).week().toString();
    const calendarYear = moment(this.state.selectedDate).year().toString();

    const copySchedule = new CopySchedule(
      this.state.selectedClass.ClassID,
      this.state.dropdownSelectedWeek,
      this.state.dropdownSelectedYear,
      this.state.dropdownSelectedClass,
      calendarWeek,
      calendarYear
    );

    if (
      confirm(
        "Ved å kopiere denne planen til ny klasse/uke, vil du overskrive en eventuell plan som finnes for denne klassen/uka fra før. Vil du fortsette kopieringen?"
      )
    ) {
      this.setState({ showCopyScheduleLoadingModal: true });
      this.props.copyScheduleElements(copySchedule, (success: boolean) => {
        this.setState(
          {
            showCopyScheduleLoadingModal: false,
          },
          () => {
            const newDate = moment(new Date())
              .year(this.state.dropdownSelectedYear)
              .week(this.state.dropdownSelectedWeek)
              .toDate();

            this.setState(
              {
                selectedDate: newDate,
              },
              () => {
                this.onChangedClassAndWeek(
                  this.state.dropdownSelectedClass,
                  newDate
                );
              }
            );
          }
        );
        if (!success) {
          alert("Kunne ikke kopiere timeplanen, prøv igjen senere.");
        }
      });
    }
  }

  onDeleteClicked = () => {
      this.props.deleteMultipleScheduleElement(this.state.scheduleElementsToDelete).then(() => {
        this.setState({
          deletedElements: [ ...this.state.deletedElements, ...this.state.scheduleElementsToDelete],
          scheduleElementsToDelete: [],
          deleteMode: false
        });
        this.props.getScheduleElements(this.state.selectedClass.ClassID, this.state.selectedWeek, this.state.selectedYear, this.state.dropdownSelectedClassAssignmentGroup).then(() => {
        });
      }).catch(error => {
        store.addNotification({
          title: "Zokrates sier!",
          message: 'Klarte ikke å slette!',
          type: "danger",
          insert: "top",
          container: "top-right",
          animationIn: ["animated", "fadeIn"],
          animationOut: ["animated", "fadeOut"],
          dismiss: {
            duration: 5000,
            onScreen: true,
          }
        });

        this.setState({
          scheduleElementsToDelete: [],
          deleteMode: false
        });
      });
  }

  /****************************
     Helper methods
     ****************************/


  exitDeleteMode = () => {

  }

  getClassAssignmentGroupOptions = async (classId: string): Promise<ISelectOption[]> => {
    let classAssignmentGroups = (await axios.get(`${ROOT_URL}/GetClassAssignmentGroups/${classId}${SP_HOST_URL}`, {
			headers: {
				Authorization: localStorage.getItem('id_token')
			}
		})).data as ClassAssignmentGroup[];


    var groupOptions: ISelectOption[] = [({ key: -1, text: "Alle"}), ({ key: 0, text: "Elementer synlig for alle"})];
    groupOptions.push(...classAssignmentGroups.map(group => ({
        key: group.Id,
        text: group.Name,
    })));
    
    return groupOptions;
  }

  getAndSetClassAssignmentGroupOptions = async (classId: string): Promise<ISelectOption[]> => {
    var groupOptions: ISelectOption[] = await this.getClassAssignmentGroupOptions(classId);

    this.setState({dropdownSelectedClassAssignmentGroupOptions: groupOptions});
    return groupOptions;
  }

  mapSchoolClassesToKeys() {
    const schoolClassesList: SchoolClass[] = _.values(
      this.props.subscribedSchoolClasses
    );
    const keyList = [];

    schoolClassesList.forEach((se) => {
      keyList.push({ key: se.ClassID, text: se.ClassName });
    });

    return keyList;
  }

  filterEventsBySelectedSchoolClass() {
    // Returns an empty array if there are ScheduleElements yet
    if (_.isEmpty(this.props.scheduleElements)) {
      return [];
    }

    // Returns an empty array if teacher has no SchoolClasses or state for selectedClass is not set
    if (
      _.isEmpty(this.props.subscribedSchoolClasses) ||
      !this.state ||
      !this.state.selectedClass
    ) {
      return [];
    }
    let values = _.values(this.props.scheduleElements).filter(se => se.ClassID === this.props.selectedclass && !(this.state.deletedElements.indexOf(se) !== -1)).sort((a, b) => {
      if(a.Title < b.Title) { return -1; }
      if(a.Title > b.Title) { return 1; }
      return 0;
    });

    // Filter by AssignmentGroup
    values = values.filter(se => this.state.dropdownSelectedClassAssignmentGroup == -1 || se.TilretteleggingsgruppeId == null ||  se.TilretteleggingsgruppeId == 0 || se.TilretteleggingsgruppeId == this.state.dropdownSelectedClassAssignmentGroup);


    if (this.state && !this.state.overlappingElementer) {
      // Because of weird behaviour when overlap is turned off in react big calendar, the order of elements needs to be reversed
      values = values.reverse();
    }
    return values;
  }

  filterEventsBySelectedSchoolClassForTemplate() {
    // Returns an empty array if there are ScheduleElements yet
    if (_.isEmpty(this.props.scheduleElements)) {
      return [];
    }

    // Returns an empty array if teacher has no SchoolClasses or state for selectedClass is not set
    if (
      _.isEmpty(this.props.subscribedSchoolClasses) ||
      !this.state ||
      !this.state.selectedClass
    ) {
      return [];
    }

    return _.filter(
      _.values(this.props.scheduleElements),
      (i: ScheduleElement) => {
        return (
          i.ClassID === this.props.selectedclass &&
          i.WeekNo.toString() ===
            moment(this.state.selectedDate).week().toString() &&
          !(this.state.deletedElements.indexOf(i) !== -1)
        );
      }
    );
  }
  eventStyleGetter = (se: ScheduleElement) => {
    let eventArr: any[] = this.filterEventsBySelectedSchoolClass();
    let tempArr: any[] = [];
    let toBeDeleted: boolean = this.state ? this.state.scheduleElementsToDelete.indexOf(se) !== -1 : false;

    eventArr.reduce((elemArray: any[], elem, i) => {
      if (
        moment(se.End).isSame(moment(elem.Start)) ||
        moment(se.Start).isSame(moment(elem.End))
      ) {
        tempArr.push(elem);
      }
    });

    return tempArr.length > 0
      ? {
          style: {...new StyleUtils().getCalendarScheduleStyle(se.Color), opacity: this.state.deleteMode ? (toBeDeleted ? 1 : 0.4) : 0.9},
          //className: "calendar-event-wide",
        }
      : { style: {...new StyleUtils().getCalendarScheduleStyle(se.Color), opacity: this.state.deleteMode ? (toBeDeleted ? 1 : 0.4) : 0.9}};
  };

  /****************************
     Render methods
     ****************************/

  renderCopyScheduleModal() {
    return (
      <Dialog
        hidden={false}
        onDismiss={() => this.setState({ showCopyScheduleModal: false })}
        dialogContentProps={{
          type: DialogType.normal,
          title: "Kopier timeplan til ny uke / klasse",
          subText: "",
        }}
        styles={{
          main: [
            {
              selectors: {
                ["@media (min-width: 480px)"]: {
                  maxWidth: "600px",
                  minWidth: "480px",
                },
              },
            },
          ],
        }}
        modalProps={{
          titleAriaId: "myLabelId",
          subtitleAriaId: "mySubTextId",
          isBlocking: true,
          containerClassName: "ms-dialogMainOverride",
        }}
      >
        <div className="ms-Grid">
          <div className="ms-Grid-col ms-sm3">
            <Dropdown
              selectedKey={this.state.dropdownSelectedWeek}
              placeHolder="Velg uke"
              label="Velg uke"
              id="selScheduleWeek"
              onChanged={(value) => {
                this.setState({ dropdownSelectedWeek: value.key });
              }}
              options={this.state.dropdownWeekOptions}
            />
          </div>
          <div className="ms-Grid-col ms-sm3">
            <Dropdown
              selectedKey={this.state.dropdownSelectedYear}
              placeHolder="Velg år"
              label="Velg år"
              id="selScheduleYear"
              onChanged={(value) => {
                this.setState({ dropdownSelectedYear: value.key });
              }}
              options={this.state.dropdownYearOptions}
            />
          </div>
          <div className="ms-Grid-col ms-sm6">
            <Dropdown
              //selectedKey={this.props.selectedclass}
              selectedKey={this.state.dropdownSelectedClass}
              placeHolder="Velg klasse"
              label="Velg Klasse"
              id="selScheduleClass"
              onChanged={(value) => {
                this.setState({ dropdownSelectedClass: value.key });
                this.props.setSelectedClass(value.key.toString());
              }}
              options={this.mapSchoolClassesToKeys()}
            />
          </div>
        </div>
        <DialogFooter>
          <DefaultButton
            onClick={() => {
              this.setState({ showCopyScheduleModal: false });
            }}
            text="Avbryt"
          />
          <PrimaryButton
            onClick={() => {
              this.onCopyScheduleSubmit();
            }}
            text="Kopier timeplanen"
          />
        </DialogFooter>
      </Dialog>
    );
  }

  renderDateSelectorModal() {
    return (
      <Dialog
        hidden={false}
        onDismiss={() => this.setState({ showDateSelectModal: false })}
        dialogContentProps={{
          type: DialogType.normal,
          title: "Angi uke og år",
          subText: "",
        }}
        styles={{
          main: [
            {
              selectors: {
                ["@media (min-width: 480px)"]: {
                  maxWidth: "600px",
                  minWidth: "480px",
                },
              },
            },
          ],
        }}
        modalProps={{
          titleAriaId: "myLabelId",
          subtitleAriaId: "mySubTextId",
          isBlocking: true,
          containerClassName: "ms-dialogMainOverride",
        }}
      >
        <div className="ms-Grid">
          <div className="ms-Grid-col ms-sm6">
            <Dropdown
              selectedKey={this.state.dropdownSelectedWeek}
              placeHolder="Velg uke"
              label="Velg uke"
              id="selScheduleWeek"
              onChanged={(value) => {
                this.setState({ dropdownSelectedWeek: value.key });
              }}
              options={this.state.dropdownWeekOptions}
            />
          </div>
          <div className="ms-Grid-col ms-sm6">
            <Dropdown
              selectedKey={this.state.dropdownSelectedYear}
              placeHolder="Velg år"
              label="Velg år"
              id="selScheduleYear"
              onChanged={(value) => {
                this.setState({ dropdownSelectedYear: value.key });
              }}
              options={this.state.dropdownYearOptions}
            />
          </div>
        </div>
        <DialogFooter>
          <DefaultButton
            onClick={() => {
              this.setState({ showDateSelectModal: false });
            }}
            text="Avbryt"
          />
          <PrimaryButton
            onClick={() => {
              this.onCalendarDateSet();
            }}
            text="Gå direkte til uke"
          />
        </DialogFooter>
      </Dialog>
    );
  }

  renderSchoolClassDropdown() {
    //------Sorting dropdown-----//
    const alfabetClass = this.mapSchoolClassesToKeys().sort((a, b) => {
      return +a.text.substring(0, a.text.length - 1) >
        +b.text.substring(0, b.text.length - 1)
        ? 1
        : +a.text.substring(0, a.text.length - 1) <
          +b.text.substring(0, b.text.length - 1)
        ? -1
        : 0;
    });

    return (
      <div>
        {!_.isEmpty(this.props.subscribedSchoolClasses) && (
          //<div className="ms-Grid-row">
            <div className="ms-Grid-col toolbar-select">
              <Dropdown
                placeHolder={
                  this.state
                    ? this.state.selectedClass.ClassName
                    : "Velg klasse"
                }
                selectedKey={this.props.selectedclass}
                label="Velg klasse"
                id="selCourseStatus"
                onChanged={this.onSelectedSchoolClass}
                options={alfabetClass}
              />
            </div>
          //</div>
        )}
      </div>
    );
  }

  renderAssignmentGroupDropdown() {
    let selectedKey = 0;
    let options: ISelectOption[] = [];

    if (this.state) {
      selectedKey = this.state.dropdownSelectedClassAssignmentGroup;
      options = this.state.dropdownSelectedClassAssignmentGroupOptions;
    }

    return <div className="ms-Grid-col toolbar-select-assignment-group">
      <Dropdown
        id="selectAssignmentGroup"
        label="Filtrer på tilretteleggingsgruppe"
        onChange={(e, option) => {
          this.setState({dropdownSelectedClassAssignmentGroup: option.key}, () => {
              this.props.getScheduleElements(
                this.state.selectedClass.ClassID,
                this.state.selectedWeek,
                this.state.selectedYear,
                this.state.dropdownSelectedClassAssignmentGroup
              );
          });
        }}
        selectedKey={selectedKey}
        options={options}
        >

      </Dropdown>
    </div>
  }

  renderDateDropdown = () => {
    let today = moment();
    let schoolYear = (today.week().valueOf() > 29) ? today.year().valueOf() + 1 :  today.year().valueOf()
    let yearList = [];
    let weekList = [];

    if (this.state) {
  
      for (let index = 1; index <= moment().year(this.state.selectedYear).weeksInYear(); index++) {
        weekList.push({key: index, text: index.toString()});
        
      }
      for (let index = schoolYear; index >= schoolYear-2; index--) {
        yearList.push({key: index, text: index.toString()})
      }
    }

    return (
      <Stack horizontal>
        <div style={{minWidth: "100px"}}>
          <Dropdown
          placeholder="Velg uke"
          label="Velg uke"
          options={weekList}
          selectedKey={this.state && this.state.selectedWeek}
          onChange={(status, weekSelected) => {
            let week: number;

            week = parseInt(weekSelected.text);
            
            this.onWeekChanged(moment().week(week).weekday(0).year(this.state.selectedYear).toDate())
          }}
          >
          </Dropdown>
        </div>
        <div style={{minWidth: "100px", marginLeft: "7px"}}>
          <Dropdown
          placeholder="Velg år"
          label="Velg år"
          options={yearList}
          selectedKey={this.state && this.state.selectedYear}
          onChange={(status, yearSelected) => {
            const selectedWeek = (this.state.selectedWeek > 52) ? 52 : this.state.selectedWeek;

            this.onWeekChanged(moment().week(selectedWeek).weekday(0).year(parseInt(yearSelected.text)).toDate());
          }}
          >
          </Dropdown>
        </div>
      </Stack>
    )
  }

  renderEditBar = () => {
    return (
      <div>
        {!_.isEmpty(this.props.subscribedSchoolClasses) && this.state && (
          <div>
            <Stack horizontal style={{alignItems: "center"}}>
              <span style={{marginRight: 20}}>
                <Toggle 
                  defaultChecked
                  style={{marginTop: 6, marginLeft: 10}} 
                  label={
                    <span style={{display: "flex", alignItems: "center", marginTop: 8}}>Overlapping <Icon data-tip data-for='overlapTooltip' iconName="Info" style={{marginTop: 6, marginLeft: 4}}/>
                      <ReactTooltip id="overlapTooltip">
                        <p style={{fontSize: 15}}>Tillater timeplanelementer til å overlappe hverandre under visning</p>
                      </ReactTooltip>
                    </span>}
                  onChange={() => {
                    this.setState({overlappingElementer: !this.state.overlappingElementer})
                  }}
                  inlineLabel
                />
              </span>

              {!this.state.deleteMode && <ActionButton
                className="ms-font-l"
                id="btnDeleteMode"
                onClick={() =>
                  this.setState({ deleteMode: true})
                }
                iconProps={{ iconName: "Delete" }}
              >
                Slette-modus
              </ActionButton>}
              {this.state.deleteMode && <ActionButton
                className="ms-font-l"
                id="btnCancelDelete"
                onClick={() =>
                  this.setState({ 
                    deleteMode: false,
                    scheduleElementsToDelete: []
                  })
                }
                iconProps={{ iconName: "Cancel"}}
                styles={{
                  icon: {color: "red"}
                }}
              >
                Avbryt sletting
              </ActionButton>}
              {this.state.deleteMode && <ActionButton
                disabled={!(this.state.scheduleElementsToDelete.length > 0)}
                className="ms-font-l"
                id="btnDelete"
                onClick={this.onDeleteClicked}
                iconProps={{ iconName: "Delete" }}
              >
                Slett {this.state.scheduleElementsToDelete.length} elementer
              </ActionButton>}

              
            </Stack>
          </div>
        )}
      </div>
    );
  }

  renderCustomEvent = (scheduleElement: ScheduleElement) => {
    return <div className="custom-event-container" onClick={() => {
      if(!(scheduleElement.Links && scheduleElement.Links.length > 0)) {
        this.onSelectEvent(scheduleElement);
      }
    }}>
      <div className="custom-event-label">
        <span className="custom-event-label-time" onClick={() => this.onSelectEvent(scheduleElement)}>
          {scheduleElement.Start ? moment(scheduleElement.Start).format("HH:mm") : ""} - {scheduleElement.End ? moment(scheduleElement.End).format("HH:mm") : ""}
        </span>
        {(scheduleElement.Links && scheduleElement.Links.length > 0) && <span>
          <Icon data-tip data-for={`link-tooltip-${scheduleElement.Id}`} 
            className={!this.state.deleteMode ? "custom-event-icon" : ""}
            iconName="Link" 
            onClick={() => {
              if(!this.state.deleteMode) {
                this.setState({showLinkModal: true, selectedLinks: scheduleElement.Links})
              }
              else {
                this.onSelectEvent(scheduleElement);
              }
            }}
          />
          <ReactTooltip id={`link-tooltip-${scheduleElement.Id}`}>
            <span style={{textAlign: "center"}}>
                <div>
                  Dette timeplanelementet har {scheduleElement.Links ? scheduleElement.Links.split("|").length : 0} lenker
                </div>
                {!this.state.deleteMode && <p>Trykk for å se lenker</p>}
            </span>
          </ReactTooltip>
        </span>}

      </div>
      <div style={{flexGrow: 1}} onClick={() => this.onSelectEvent(scheduleElement)}>
        {scheduleElement.Title}
      </div>
    </div>
  }

  renderLinkModal = () => {
    let linkList: string[] = this.state.selectedLinks.split("|");
    console.log("Selected Links:", this.state.selectedLinks, linkList);

    return (<Dialog
      hidden={false}
      dialogContentProps={{
        type: DialogType.normal,
        title: "Tilknyttede lenker"
      }}
      modalProps={{isBlocking: false}}
      onDismiss={() => {
        this.setState({showLinkModal: false});
      }}
    >
        <ul style={{listStyleType: "circle"}}>
          {linkList.map((link) => {
            return <li className="addedlink" style={{marginLeft: 20}}>
              <span className="labelText">
                <a target="_blank" href={link}>
                  {link}
                </a>
              </span>
            </li>;
          })}
        </ul>
      <DialogFooter>
        <DefaultButton
          onClick={() => {
            this.setState({showLinkModal: false});
          }}
          text="Lukk"
        ></DefaultButton>
      </DialogFooter>
    </Dialog>)
  }

  render() {
    const localizer = momentLocalizer(moment);
    return (
      <div className="ms-Grid container main">
        {this.state && this.state.showCopyScheduleLoadingModal && (
          <LoadingDialog
            title="Kopierer timeplanen"
            description="Vennligst vent"
          />
        )}
        {/* {this.state &&
          this.state.showDateSelectModal &&
          this.renderDateSelectorModal()} */}
        {this.state &&
          this.state.showCopyScheduleModal &&
          this.renderCopyScheduleModal()}

        <SidebarActionButtonList>
          <CommandBarButton
            onClick={() => this.props.history.push("/timeplan/nytt_innslag")}
            className="sidenav-buttons"
            iconProps={{ iconName: "Add" }}
            text="Nytt innslag"
          />

          <CommandBarButton
            onClick={() => this.setState({ showTemplatePanel: true })}
            className="sidenav-buttons"
            iconProps={{ iconName: "Forward" }}
            text="Kopier timeplanen"
          />
        </SidebarActionButtonList>

        <HeaderView
          title={`Timeplan ${
            this.state ? moment(this.state.selectedDate).format("YYYY") : ""
          }`}
          description="Her finner du dine timeplaner for dine klasser"
          iconName="ViewAll"
        />
        <Stack horizontal>
          {this.renderSchoolClassDropdown()}
          {this.renderAssignmentGroupDropdown()}
          {this.renderDateDropdown()}
        </Stack>

        {this.renderEditBar()}

        {!_.isEmpty(this.props.subscribedSchoolClasses) ? (
          <div>
            <Calendar

              localizer={localizer}
              events={this.filterEventsBySelectedSchoolClass()}
              min={CALENDAR_MIN}
              max={CALENDAR_MAX}
              defaultView={"work_week"}
              eventPropGetter={this.eventStyleGetter}
              views={["day", "work_week"]}
              // onSelectEvent={this.onSelectEvent}
              dayLayoutAlgorithm={this.state && !this.state.overlappingElementer ? "no-overlap" : "overlap"}
              titleAccessor="Title"
              startAccessor="Start"
              endAccessor="End"
              components={{
                toolbar: (props) =>  <CustomToolbar toolbar={props} week= {this.state ? this.state.selectedWeek : 1} year= {this.state ? this.state.selectedYear : moment().year().valueOf()}></CustomToolbar>,
                event: ({event}) => (
                  this.renderCustomEvent(event)
                )}}
              onNavigate={(date) => {
                this.setState({
                  deleteMode: false,
                  scheduleElementsToDelete: [],
                  //selectedDate:date
                });
                this.onWeekChanged(date);
              }}
              date={this.state ? this.state.selectedDate : new Date()}
            />
            {this.state && this.state.showTemplatePanel && (
              <>
                <TemplatePanel
                  bigCalendarProps={{events: this.filterEventsBySelectedSchoolClass(), eventPropGetter: this.eventStyleGetter, views: ["day", "work_week"]}}
                  ondone={() => {
                    this.setState({ showTemplatePanel: false });
                    this.props.getScheduleElements(this.state.selectedClass.ClassID, this.state.selectedWeek, this.state.selectedYear)
                  }}
                  weekno={moment(this.state.selectedDate).week()}
                  selectedYear={this.state.selectedYear}
                  classid={this.state.selectedClass.ClassID}
                  currentElements={this.filterEventsBySelectedSchoolClassForTemplate()}
                  deletedElements={this.state.deletedElements}
                  // selectedClass={this.state.selectedClass}
                  selectedDate={this.state.selectedDate}
                ></TemplatePanel>
                
              </>
            )}

            {this.state && this.state.showLinkModal && this.renderLinkModal()}

          </div>
        ) : (
          <div>
            For å kunne se timeplan må du velge minst en klasse i Zokrates
            administrasjon
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { selectedclass, selectedWeek, selectedYear } = state.FilterReducer;

  return {
    scheduleElements: state.scheduleElements,
    subscribedSchoolClasses: state.teacher.subscribedSchoolClasses,
    selectedclass,
    selectedWeek,
    selectedYear,
  };
};

export default connect(mapStateToProps, {
  getScheduleElements,
  copyScheduleElements,
  setSelectedClass,
  setSelectedYear,
  setSelectedWeek,
  resetSelectedWeekAndYear,
  deleteMultipleScheduleElement
})(TimeplanView);
