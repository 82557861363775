import {Course} from "./Course";

export class TeacherCourse {
    TeacherID:string;
    CourseId:string;

    constructor(TeacherID: string, CourseId: string) {
        this.TeacherID = TeacherID;
        this.CourseId = CourseId;
    }
}