import * as React from 'react';
import * as _ from 'lodash';

import {connect} from 'react-redux';
import {Course} from "../../../models/Course";
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import MainContent from "../../view_containers/MainContent";
import SpinLoader from "../../loading/SpinLoader";
import StudentDragCard from "./StudentDragCard";
import {CommandBar} from "office-ui-fabric-react";
import GroupHeader from "./GroupHeader";
import {AssignmentGroup} from "../../../models/AssignmentGroup";

import {deleteGroup, getGroups, saveGroups, updateCourseStudents} from "../../../actions/Action_Teacher";
import {LoadingDialog} from "../../loading/LoadingDialog";

import {ToastContainer, toast, Slide} from "react-toastify";
import TeamMapper from '../../graph/TeamMapper';

// a little function to help us with reordering the result
const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
};

/**
 * Moves an item from one list to another list.
 */
const moveStudent = (source, destination, droppableSource, droppableDestination) =>{
    const sourceClone = Array.from(source);
    const destClone = Array.from(destination);
    const [removed] = sourceClone.splice(droppableSource.index, 1);

    destClone.splice(droppableDestination.index, 0, removed);


    return [
        sourceClone,
        destClone
    ];
};

const getItemStyle = (isDragging, draggableStyle) => ({
    // some basic styles to make the messages look a bit nicer
    userSelect: 'none',

    // change background colour if dragging
    background: '#f0f0f0',
    boxShadow: isDragging ? '0 15px 35px rgba(50,50,93,.3), 0 5px 15px rgba(0,0,0,.2)': 'none',

    // styles we need to apply on draggables
    ...draggableStyle
});

const getListStyle = isDraggingOver => ({
    background: isDraggingOver ? 'lightblue' : '#f8f8f8',
});

const STUDENT_DROPPABLE_ID = -1;

export interface IAdminStudentsAndCoursesDetailViewState {
    course:Course;
    unassignedStudents:{};
    assignmentGroups:{};    // Mutable values that can be altered in view
    initialGroups:{};

    awaitingResponse:boolean;
}

export interface IAdminStudentsAndCoursesDetailViewProps {
    subscribedCourses:{};
    match:any;

    getGroups(course:Course);
    saveGroups(groups:AssignmentGroup[], callback:(data)=>void);
    deleteGroup(group:AssignmentGroup, callback:(data)=>void);
    updateCourseStudents(course:Course);    // Method that updates local state according to changes.
}

class AdminStudentsAndCoursesDetailView extends React.Component<IAdminStudentsAndCoursesDetailViewProps, IAdminStudentsAndCoursesDetailViewState>{

    constructor(props){
        super(props);

        const {id} = this.props.match.params;
        const unassignedStudents = new AssignmentGroup(
            STUDENT_DROPPABLE_ID,
            this.props.subscribedCourses[id].Title,
            id,
            null,
            this.props.subscribedCourses[id].Students
        );

        let assignmentGroups = {};

        if (this.props.subscribedCourses[id].Groups != null){
            assignmentGroups = this.getDroppablesForGroups();
        }

        this.state = {
            awaitingResponse: false,
            course: this.props.subscribedCourses[id],
            unassignedStudents,
            assignmentGroups,
            initialGroups: _.cloneDeep(assignmentGroups)
        };

        this.onDragEnd = this.onDragEnd.bind(this);
        this.onCreateNewGroup = this.onCreateNewGroup.bind(this);
    }

    /****************************
            Lifecycle hooks
     ****************************/

    componentWillMount(){
        this.props.getGroups(this.state.course).then(()=>{
            this.setState({
                assignmentGroups: this.getDroppablesForGroups(),
                initialGroups: Object.assign({}, this.getDroppablesForGroups()),
            });
        });
    }

    /****************************
            Event handlers
     ****************************/

    onDragEnd(result){
        const { source, destination } = result;

        // dropped outside the list
        if (!destination) {
            return;
        }

        // If a student it dropped in its previous group, we just rearrange it based on drop location
        if (source.droppableId === destination.droppableId) {
            const items = reorder(
                this.state.assignmentGroups[source.droppableId].Students,
                source.index,
                destination.index
            );

            const assignmentGroups = this.state.assignmentGroups;
            assignmentGroups[source.droppableId].Students = items;

            this.setState({
                assignmentGroups: assignmentGroups
            });
        } else {
            const result = moveStudent(
                this.state.assignmentGroups[source.droppableId].Students,
                this.state.assignmentGroups[destination.droppableId].Students,
                source,
                destination
            );

            const assignmentGroups = this.state.assignmentGroups;
            assignmentGroups[source.droppableId].Students = result[0];
            assignmentGroups[destination.droppableId].Students = result[1];

            this.setState({
                assignmentGroups: assignmentGroups
            });
        }
    };

    onRemoveStudentFromGroup(studentId:number, groupId:number){

        const result = moveStudent(
            this.state.assignmentGroups[groupId].Students,
            this.state.assignmentGroups[STUDENT_DROPPABLE_ID].Students,
            groupId,
            STUDENT_DROPPABLE_ID
        );

        const assignmentGroups = this.state.assignmentGroups;
        assignmentGroups[groupId].Students = result[0];
        assignmentGroups[STUDENT_DROPPABLE_ID].Students = result[1];

        this.setState({
            assignmentGroups: assignmentGroups
        });
    }

    onGroupNameChanged(groupId, event){

        const assignmentGroups = this.state.assignmentGroups;
        assignmentGroups[groupId].Name = event.target.defaultValue;

        this.setState({
            assignmentGroups: assignmentGroups
        });
    }

    onDeleteAssignmentGroup(group:AssignmentGroup){

        if (confirm('Du er iferd med å slette en tilretteleggingsgruppe. Vær obs på at oppgaver delt ut til denne gruppen ikke lenger vil være synlig for elevene i gruppen')) {

            const assignmentGroups = this.state.assignmentGroups;

            if (group.Students){
                assignmentGroups[STUDENT_DROPPABLE_ID].Students = assignmentGroups[STUDENT_DROPPABLE_ID].Students.concat(group.Students);
            }

            // If the deleted group has not been saved, we just remove it front-end
            if (isNaN(group.Id)){
                delete assignmentGroups[group.Id];

                this.setState({
                    assignmentGroups: assignmentGroups
                });
            } else {

                this.props.deleteGroup(group, (response)=>{
                    delete assignmentGroups[group.Id];

                    const updatedCourse = this.state.course;
                    updatedCourse.Students = this.state.assignmentGroups[STUDENT_DROPPABLE_ID].Students;

                    this.props.updateCourseStudents(updatedCourse);

                    toast.success('Gruppen har blitt slettet');

                    this.setState({
                        course: updatedCourse,
                        assignmentGroups: assignmentGroups,
                        initialGroups: _.cloneDeep(assignmentGroups)
                    });
                });
            }
        }
    }

    onCreateNewGroup(addAllStudents:boolean){

        const assignmentGroups = this.state.assignmentGroups;

        const newAssignmentGroup = new AssignmentGroup(
            this.getNewAssignmentGroupIndex(),
            '',
            this.state.course.CourseID,
            this.state.course.Id,
            addAllStudents ? assignmentGroups[STUDENT_DROPPABLE_ID].Students : []
        );

        if (addAllStudents){
            assignmentGroups[STUDENT_DROPPABLE_ID].Students = [];
        }

        assignmentGroups[newAssignmentGroup.Id] = newAssignmentGroup;

        this.setState({
            assignmentGroups: assignmentGroups,
        });
    }

    onSubmit(){

        const anyGroupsMissingNames : boolean = _.values(this.state.assignmentGroups).some(g => !g.Name);

        if (anyGroupsMissingNames){
            toast.info('Alle grupper må ha et navn.');
            return;
        }

        // We only submit groups that have either changed their name, students or are newly created.
        const requestData = this.getRequestData();

        if (!requestData.length){
            toast.info('Du har ikke gjort noen endringer for gruppene');
            return;
        }

        this.setState({awaitingResponse: true});

        this.props.saveGroups(requestData, response => response).then(res =>{

            const updatedCourse = this.state.course;
            updatedCourse.Students = this.state.assignmentGroups[STUDENT_DROPPABLE_ID].Students;

            this.props.updateCourseStudents(updatedCourse);

            const assignmentGroups = this.getDroppablesForGroups();

            this.setState({
                course: updatedCourse,
                assignmentGroups,
                initialGroups: _.cloneDeep(assignmentGroups),
                awaitingResponse: false,
            })
        })
    }

    /****************************
            Helper methods
     ****************************/

    hasGroupChanged = (group : AssignmentGroup) : boolean =>{

        const originalGroup = this.state.initialGroups[group.Id];

        if (!originalGroup){
            //return false;
            return true;
        }

        return !_.isEqual(group, originalGroup);
    };

    getDroppablesForGroups(){
        const {id} = this.props.match.params;

        const studentGroup = new AssignmentGroup(
            STUDENT_DROPPABLE_ID,
            this.props.subscribedCourses[id].Title,
            id,
            null,
            this.props.subscribedCourses[id].Students ? this.props.subscribedCourses[id].Students : []
        );

        const assignmentGroups = {};

        // Assigns a dropGroup for course students not assigned to a group
        assignmentGroups[STUDENT_DROPPABLE_ID] = studentGroup;

        // Assigns a new dropGroup for each existing group
        this.props.subscribedCourses[id].Groups.forEach(group => {
            // Assigns copies of group from state, making it easier to compare changed on submit
            assignmentGroups[group.Id] = Object.assign({}, {...group, Students: group.Students || []});
        });

        return assignmentGroups;
    }

    getRequestData():AssignmentGroup[]{

        const assignmentGroups = Object.assign({}, this.state.assignmentGroups);
        const initialGroups = this.state.initialGroups;

        // Remove droppable assignment group for Course students, as it is irrelevant for request
        delete assignmentGroups[STUDENT_DROPPABLE_ID];
        delete initialGroups[STUDENT_DROPPABLE_ID];

        const assignmentGroupList:AssignmentGroup[] = _.values(assignmentGroups);

        const filteredAssignmentGroups:AssignmentGroup[] = [];

        for(let i=0; i < assignmentGroupList.length; i++){

            const assignmentGroup:any = assignmentGroupList[i];
            const initialGroup = initialGroups[assignmentGroup.Id];

            // If state doesn't contain a group for Id, we assume it's a new group
            if (!initialGroup) {
                assignmentGroupList[i].Id = 0;
                filteredAssignmentGroups.push(assignmentGroupList[i]);
                continue;
            }

            // We add the group if it's Name or length of Students changed
            if (assignmentGroup.Name != initialGroup.Name || assignmentGroup.Students.length != initialGroup.Students.length){
                filteredAssignmentGroups.push(assignmentGroupList[i]);
                continue;
            }

            // Sort arrays and compares them - handles cases where arrays might have the same length, but containing different students
            if (!_.isEqual(
                    _.orderBy(assignmentGroup.Students, (s)=>{return s.Id}, ['desc']),
                    _.orderBy(initialGroup.Students,    (s)=>{return s.Id}, ['desc'])
            )){
                filteredAssignmentGroups.push(assignmentGroupList[i]);
            }
        }

        return filteredAssignmentGroups;
    }

    getNewAssignmentGroupIndex():string{

        const count = _.values(this.state.assignmentGroups)
            .filter(group => isNaN(group.Id))
            .length;

        return 'new_'+count;
    }

    /****************************
            Render methods
     ****************************/

    renderAssignmentGroup(group : AssignmentGroup){
        const students = group.Students != null ? group.Students : [];

        // We do not render course students twice
        if (group.Id == STUDENT_DROPPABLE_ID){
            return null
        }

        const draggableListClasses = `draggable-list ${this.hasGroupChanged(group) ? 'changed':''}`;


        return(
            <div className="ms-Grid-col ms-sm12 ms-md6 ms-lg4 ms-xl3">
              
                <GroupHeader group={group}
                             title={group.Name}
                             onNameChanged={this.onGroupNameChanged.bind(this)}
                             onDeleteGroup={this.onDeleteAssignmentGroup.bind(this)}
                />
                <Droppable droppableId={group.Id}>
                    {(provided, snapshot) => (
                        <div className={draggableListClasses}
                             ref={provided.innerRef}
                             style={getListStyle(snapshot.isDraggingOver)}>
                            {students.map((student, index) => (
                                <Draggable
                                    key={student.Id}
                                    draggableId={student.Id}
                                    index={index}>
                                    {(provided, snapshot) => (
                                        <div className="draggable-list-item"
                                             ref={provided.innerRef}
                                             {...provided.draggableProps}
                                             {...provided.dragHandleProps}
                                             style={getItemStyle(
                                                 snapshot.isDragging,
                                                 provided.draggableProps.style
                                             )}>
                                            <StudentDragCard
                                                student={student}
                                                group={group}
                                                buttonClick={this.onRemoveStudentFromGroup.bind(this)}/>
                                        </div>
                                    )}
                                </Draggable>
                            ))}
                            {provided.placeholder}
                        </div>
                    )}
                </Droppable>
            </div>
        )
    }

    render(){

        const unassignedGroup = this.state.assignmentGroups[STUDENT_DROPPABLE_ID];

        return(
            <div className="ms-Grid main">
                <div className="ms-Grid-row">
                    <ToastContainer transition={Slide}/>

                    {this.state && this.state.awaitingResponse &&
                    <LoadingDialog title="Oppdaterer tilretteleggingsgrupper" description="Vennligst vent"/>
                    }
                    <div className="ms-Grid-col ms-md12">
                        <div>
                            <h4>{this.state.course.Title}</h4>
                            <p className="ms-font-l">Klikk på knappen "Ny gruppe" for å opprette en tilretteleggingsgruppe. Dra hver elev inn i tilretteleggingsgruppen du ønsker for eleven i dette faget. Skulle du ønske å endre, er det bare å dra den videre til annen gruppe. For å ta eleven helt ut av tilretteleggingsgruppen - trykk på pil tilbake. </p>
                            <p className="ms-font-l"><strong>PS!</strong> Husk at å flytte en elev fra tilretteleggingsgruppe kan gjøre noe med elevens tilgang på allerede tildelte planer/innleveringer i det bestemte faget.</p>
                           <TeamMapper classID={this.state.course.CourseID}>

                            </TeamMapper>
                        </div>
                    </div>
                    <div className="ms-Grid-col ms-md12">
                        <MainContent>
                            {!Object.keys(this.state.assignmentGroups).length &&
                            <SpinLoader text="Henter tilretteleggingsgrupper"/>
                            }

                            {!_.isEmpty(this.state.assignmentGroups) && !_.isEmpty(unassignedGroup) &&
                            <DragDropContext onDragEnd={this.onDragEnd}>

                                <div className="ms-Grid-col ms-sm12">
                                    <GroupHeader title={`Elever i: ${this.state.course.Title}`}/>

                                </div>

                                <Droppable droppableId={STUDENT_DROPPABLE_ID} direction="horizontal">
                                    {(provided, snapshot) => (
                                        <div className="draggable-list"
                                             ref={provided.innerRef}
                                             style={getListStyle(snapshot.isDraggingOver)}>
                                            {unassignedGroup.Students.map((student, index) => (
                                                <Draggable
                                                    key={student.Id}
                                                    draggableId={student.Id}
                                                    index={index}>
                                                    {(provided, snapshot) => (
                                                        <div className="draggable-list-item ms-Grid-col ms-sm12 ms-md6 ms-lg4 ms-xl3"
                                                             ref={provided.innerRef}
                                                             {...provided.draggableProps}
                                                             {...provided.dragHandleProps}
                                                             style={getItemStyle(
                                                                 snapshot.isDragging,
                                                                 provided.draggableProps.style
                                                             )}>
                                                            <StudentDragCard
                                                                student={student}
                                                                group={null}
                                                                buttonClick={this.onRemoveStudentFromGroup.bind(this)}/>
                                                        </div>
                                                    )}
                                                </Draggable>
                                            ))}
                                            {provided.placeholder}
                                        </div>
                                    )}
                                </Droppable>

                                <div className="command-bar" style={{marginBottom: 20, maxWidth: '530px'}}>
                                    <CommandBar
                                        items={[
                                            {
                                                key: 'Ny gruppe',
                                                name: 'Ny Gruppe',
                                                iconProps: {
                                                    iconName: 'Group'
                                                },
                                                onClick: ()=> this.onCreateNewGroup(false)
                                            },
                                            {
                                                key: 'Ny gruppe for resten',
                                                name: 'Ny Gruppe for resten',
                                                iconProps: {
                                                    iconName: 'AddGroup'
                                                },
                                                onClick: ()=> this.onCreateNewGroup(true)
                                            },
                                        ]}
                                        farItems={[
                                            {
                                                key: 'Lagre',
                                                name: 'Lagre',
                                                iconProps: {
                                                    iconName: 'Save'
                                                },
                                                onClick: this.onSubmit.bind(this)
                                            },
                                        ]}
                                    />
                                </div>

                                {   // Renders a dropGroup for each existing group
                                    _.values(this.state.assignmentGroups)
                                        .sort((a,b) => a < b)
                                        .map((group:AssignmentGroup) => (
                                            this.renderAssignmentGroup(group)
                                        )
                                )}
                            </DragDropContext>
                            }
                        </MainContent>
                    </div>
                </div>
            </div>
        )
    }
}

function mapStateToProps(state, ownProps) {
    return{
        subscribedCourses: state.teacher.subscribedCourses
    }
}

export default connect(mapStateToProps, {getGroups, saveGroups, deleteGroup, updateCourseStudents})(AdminStudentsAndCoursesDetailView);