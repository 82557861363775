import {Label, TextField} from "office-ui-fabric-react";
import * as React from "react";

interface ITextFieldProps {
    label:string;
    input:any;
    required?:boolean;
    isNumeric?:boolean;
    meta:{
        touched: boolean;
        error?: string;
    }
}

const InputField = ({ label, input, isNumeric, required, meta: { touched, error } }:ITextFieldProps) => {

    return(
        <div className="ms-Grid-row">
            <div className="z-form-group">
                <div className="ms-Grid-col ms-sm12 ms-md4">
                    <Label required={required} className="z-form-label ms-Label">
                        {label}
                    </Label>
                </div>
                <div className="ms-Grid-col ms-sm12 ms-md8">
                    <TextField
                        {...input}
                        type={isNumeric ? "number":"text"}
                        errorMessage={touched ? error : ''}
                        value={isNumeric && !input.value ? "0" : input.value}
                    />
                </div>
            </div>
        </div>
    )
};

export default InputField;