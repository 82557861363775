import axios from 'axios';
import { ActionButton, Icon, Label, PrimaryButton, Spinner, SpinnerSize } from 'office-ui-fabric-react';
import * as React from 'react'
import { ROOT_URL, SP_HOST_URL } from '../../actions/constants';
import ReactTooltip from 'react-tooltip';
import OneDrivePicker, { ISelectedItem } from '../shared/OneDrivePicker';
import { ITaskEvaluationFileInfo } from '../../models/interfaces/ITaskEvaluationFile';


export interface IEvaluationFilesProps {
    Task: any;
    CanBeEdited: boolean;
    SemiBoldTitle?: boolean;
    isLoadingFiles: boolean;
	filesToUpload: any[];
	evaluationFiles: ITaskEvaluationFileInfo[];
	filesLoading: string[];
    errorFetchingFiles: boolean;
    fetchEvaluationFiles(task: any);
    deleteEvaluationFile?(task: any, file: any);
    onSelecteEvaluationFiles?(file: any) 
}
export interface IEvaluationFilesState {
	// isLoadingFiles: boolean;
	// filesToUpload: any[];
	// evaluationFiles: ITaskEvaluationFileInfo[];
	// filesLoading: string[];
    // errorFetchingFiles: boolean;
}

export default class EvaluationFiles extends React.Component<IEvaluationFilesProps, IEvaluationFilesState> {

    constructor(props) {
		super(props);

		// this.state = {
		// 	isLoadingFiles: false,
		// 	filesToUpload: [],
		// 	evaluationFiles: [],
		// 	filesLoading: [],
        //     errorFetchingFiles: false
		// };
	}

    async componentDidMount(): Promise<void> {
        await this.props.fetchEvaluationFiles(this.props.Task);
	}

    renderFetchError = () => {
        return <div style={{padding: "10px 0 0 0", display: 'flex', alignItems: "center"}}><Icon style={{fontSize: 18, color: "red"}} className="standard-icon" iconName="AlertSolid" /> Noe gikk galt ved innhenting av filer</div>;
    }

    renderEvaluationFileListStudent() {
        return <div style={{minHeight: "35px"}}>
                {this.props.isLoadingFiles ? <Spinner size={SpinnerSize.large}></Spinner> : 
                
                    !this.props.errorFetchingFiles ? <div>
                        {(this.props.evaluationFiles.length > 0 || this.props.filesToUpload.length > 0) ? this.renderEvaluationFileListBody() : <div style={{padding: "14px 0 0 0"}}>Ingen filer er lagt til</div>}
                    </div> : this.renderFetchError()
                
                }
        </div>
    }

    renderEvaluationFileListEdit() {
        return <div style={{margin: "10px 0px 10px 0px", padding: "10px", backgroundColor: "#fff"}}>
                {this.props.isLoadingFiles ? <Spinner size={SpinnerSize.large}></Spinner> : 
                
                !this.props.errorFetchingFiles ? <div>
                    {(this.props.evaluationFiles.length > 0 || this.props.filesToUpload.length > 0) ? this.renderEvaluationFileListBody() : <div>Ingen filer er lagt til</div>}
                </div> : this.renderFetchError()
                }
        </div>
    }

    renderDeleteButton = (file: any,) => {
        const uploaded: boolean = !!file.WopiUrl;
        const uniqueId: string = uploaded ? file.UniqueId : file.GraphItemId;
        const tooltipId: string = `${uniqueId}-${file.FileName}`;

        return uploaded ? <ActionButton className='deleteEvaluationFileButton' 
        iconProps={{ iconName: "Delete" }} 
        onClick={async () => {this.props.deleteEvaluationFile(this.props.Task, file)}}
        >Slett</ActionButton> 
        : <ActionButton iconProps={{ iconName: "Delete" }} onClick={async () => {
            await this.props.deleteEvaluationFile(this.props.Task, file)
            //this.setState({filesToUpload: this.props.filesToUpload.filter(x => x.FileName != file.FileName)});
        }}>
            Fjern
        </ActionButton>
    }

    renderEvaluationFileListBody() {
		let unified = [...this.props.evaluationFiles, ...this.props.filesToUpload]

		//return <div style={{marginLeft: "0.5em", display: "grid", gridTemplateColumns: "auto auto 1fr"}}>
		return <div>
			{unified && unified.length && unified.map && unified.map(file => {
                const uploaded: boolean = !!file.WopiUrl;
				const uniqueId: string = uploaded ? file.UniqueId : file.GraphItemId;
				const tooltipId: string = `${uniqueId}-${file.FileName}`;

				return <div style={{display: "grid", gridTemplateColumns: "1fr auto auto ", alignItems: "center", minHeight: "40px"}}>
					<span>
						{uploaded ?
							<a href={file.WopiUrl} target="_blank"><Icon iconName="Link"></Icon> {file.FileName}</a>
							: file.FileName
						}
					</span>
					<span>
						<Icon hidden={uploaded} style={{fontSize: 18, color: "orange"}} className="standard-icon" data-tip data-for={tooltipId} iconName="AlertSolid" />
						<ReactTooltip id={tooltipId}>
							Denne filen har blitt valgt, men ikke lastet opp. <br/>
							Trykk på 'Lagre vurdering' for å laste opp filen
						</ReactTooltip>
					</span>
                    {this.props.CanBeEdited &&
                        <span>
                            {this.props.filesLoading.indexOf(uniqueId) < 0 ? this.renderDeleteButton(file) : <div style={{minWidth: "50px"}}><Spinner size={SpinnerSize.medium}></Spinner></div>}
                        </span>
                    }
				</div>
			})} 
		</div>
	}

  render() {
    if (false) {
        return (
        <div style={{margin: "20px 0px 10px 0px"}}>
            <Label>
                {this.props.SemiBoldTitle ? <div className="ms-font-m ms-fontWeight-semibold">Evalueringsfiler</div> : <b>Evalueringsfiler</b>}
            </Label>
            {this.props.CanBeEdited ? this.renderEvaluationFileListEdit() : this.renderEvaluationFileListStudent()}
    
            {this.props.CanBeEdited && <div style={{margin: "0 0 20px 0"}}>
                {/* <PrimaryButton
                    disabled={this.state.filesToUpload.length === 0 || this.state.filesLoading.length > 0}
                    onClick={async () => {
                        await this.saveEvaluationFiles();
                    }}
                    text={`Lagre`}
                    iconProps={{ iconName: 'Save' }}
                /> */}
                <OneDrivePicker onClose={async (selected) => {
                    this.props.onSelecteEvaluationFiles(selected);
                }}
                />
            </div>}
        </div>
        )
    }
    else {
        return (<div></div>)
    }
  }
}
