import * as React from "react";
import * as moment from "moment";
import * as _ from "lodash";
import { connect } from "react-redux";
moment.locale("nb");
import ReactHtmlParser, {
  processNodes,
  convertNodeToElement,
  htmlparser2
} from "react-html-parser";
import { withRouter } from "react-router-dom";
import { PlanElement } from "../../../models/PlanElement";
import { Checkbox } from "office-ui-fabric-react/lib/Checkbox";
import { ActionButton } from "office-ui-fabric-react";
import FilesModal from "../FilesModal";
import { capitalizeFirstLetter } from "../../../utils/HelperFunctions";
export interface IStudentPlanelementViewProps {
  planelement: PlanElement;
  history: any;
  match: any;
}
export interface IStudentPlanelementViewState {
  showfilesmodal:boolean;
}

class StudentPlanelementView extends React.Component<
  IStudentPlanelementViewProps,
  IStudentPlanelementViewState
> {
  constructor(props) {
    super(props);
    this.state = { showfilesmodal: false };
  }
  render() {
    if (!this.props) {
      return <div>tom</div>;
    }
    let classname = "";
    if (this.props.planelement.IsChecked) {
      classname = "dimmed";
    }
    return (

      <li  className={`list-group-item ${classname}`}>
        <dl>
          <div className="plan-kort-header">
        
          {this.props.planelement.Theme.length && (
            <h3 style={{flex: 1}}>
              <i
                className="ms-Icon ms-Icon--CalendarWeek"
                aria-hidden="true"
              ></i>{" "}
              {this.props.planelement.Theme}
            </h3>
          )}
          {this.props.planelement.IsChecked && (
              <div className="pb-10 mb-10">
                <Checkbox
                  checked={this.props.planelement.IsChecked}
                  label="Fullført!"
                />
                {/* <input type="checkbox" className="ms-CheckBox-input" data-itemid="@this.props.planelement.Id" />
                                <label role="checkbox" className="ms-CheckBox-field" aria-checked="false">
                                    <span className="ms-Label"></span>
                                </label> */}
              </div>
            )}
            {!this.props.planelement.IsChecked && (
              <div className="pb-10 mb-10">
                <Checkbox
                  checked={this.props.planelement.IsChecked}
                  label="Ikke fullført"
                />
                {/* <input type="checkbox" className="ms-CheckBox-input" data-itemid="@this.props.planelement.Id" />
                                <label role="checkbox" className="ms-CheckBox-field" aria-checked="false">
                                    <span className="ms-Label"></span>
                                </label> */}
              </div>
            )}
        </div>


          {this.props.planelement.GrepCodeList &&
            this.props.planelement.ShowKmpGoals && (
              <div>
                <dt className="week-plan-label">Kompetansemål</dt>
              </div>
            )}
          {this.props.planelement.ShowKmpGoals && (
            <dd>
              <ul className="grep-list">
                {this.props.planelement.GrepCodeList.map(element => {
                  return (
                    <li>
                      <a href={element.GrepTekst} target="_blank">
                        {element.GrepTekst}
                      </a>
                    </li>
                  );
                })}
              </ul>
            </dd>
          )}

          {this.props.planelement.Goal.length > 0 && (
            <div>
              <dt>Egendefinerte mål:</dt>
              <dd>
                <span className="emove-para-margin list-group-unordered"
                  dangerouslySetInnerHTML={{
                    __html: this.props.planelement.Goal
                  }}
                ></span>
              </dd>
            </div>
          )}

          {// this.props.planelement.EvalElements || this.props.planelement.EvaluationTextOther &&
          this.props.planelement.EvalElements.length > 0 && (
            <div>
              <dt>Vurdering:</dt>
              <dd className="scheduleEvaluation">
                <ul>
                  {this.props.planelement.EvalElements.map(element => {
                    return <li>{element} </li>;
                  })}
                </ul>
              </dd>
            </div>
          )}

          {/* {
                        this.props.planelement.EvaluationText.length > 0 || this.props.planelement.EvaluationTextOther.length > 0 &&
                        <dl>
                            <dt>Evaluering:</dt>
                            <dd className="scheduleEvaluation">
                                <ul>
                                    {
                                        this.props.planelement.GrepCodeList.map(g => {
                                            <li>{g}</li>
                                        })


                                    }

                                </ul>
                            </dd>
                        </dl>
                    } */}

          {this.props.planelement.TextOnPrem.length > 0 && (
            <div>
              <dt>Arbeid på skolen:</dt>
              <dd>
                <span className="remove-para-margin list-group-unordered"
                  dangerouslySetInnerHTML={{
                    __html: this.props.planelement.TextonPremRaw
                  }}
                ></span>
                {/* {this.props.planelement.TextOnPremRaw} */}
              </dd>
            </div>
          )}

          {this.props.planelement.Text.length > 0 && (
            <div>
              <dt>Arbeid hjemme:</dt>
              <dd>
                <span className="emove-para-margin list-group-unordered"
                  dangerouslySetInnerHTML={{
                    __html: this.props.planelement.TextRaw
                  }}
                ></span>
                {/* {this.props.planelement.TextRaw} */}
              </dd>
            </div>
          )}
          {this.props.planelement.Link.length > 0 && (
            <div>
              <dt>Lenke</dt>
              <dd className="planLenke">
                <a target="_blank" href={this.props.planelement.Link}>
                  {this.props.planelement.Link}
                </a>
              </dd>
            </div>
          )}
          <dt>Frist:</dt>
          <dd>{capitalizeFirstLetter(moment(this.props.planelement.Date).format("dddd").toString())} {this.props.planelement.DateText}</dd>
        </dl>
        <div className="ms-Grid-row">
          <div className="oppgaveKnapper">
            {this.props.planelement.HasFiles && (
            <ActionButton iconProps={{ iconName: "Attach" }} style={{flex:1}} onClick={()=>{
              // getPlanElementFiles(this.props.planelement.Id);
              this.setState({ showfilesmodal: true });
          }}>
            Se filer
          </ActionButton>
          )}
          </div>
        </div>
         {
            this.state.showfilesmodal &&
            <FilesModal mode={1} id={this.props.planelement.Id}  closemodal={()=>{
                this.setState({ showfilesmodal: false });
            }}/>
            // <FilesModal  />
        }

      </li>
    );
  }
  onEditPlan(pe: any) {
    this.props.history.push("/ukeplan/" + pe.Id + "/e");
  }
}
function mapStateToProps(state) {
  return {};
}
// export default connect(mapStateToProps, {})(PlanelementView);
export default withRouter(StudentPlanelementView);
