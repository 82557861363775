import * as React from 'react';
import ReactTooltip from 'react-tooltip';

export interface IQuizItemProps {
    quizitem: any,
    history: any;
}

import {ActionButton} from "office-ui-fabric-react";

export default class QuizItem extends React.Component<IQuizItemProps, any> {

    renderAssignmentGroup = () => {
        let assignmentGroupText = "Ingen gruppe";

        if (this.props.quizitem.AssignmentGroupName) {
            assignmentGroupText = this.props.quizitem.AssignmentGroupName;
        }
        else if (this.props.quizitem.AssignmentGroupID != null) {
            assignmentGroupText = this.props.quizitem.AssignmentGroupID;
        }
        
        return <span className="ms-font-l" title="Tilretteleggingsgruppe">
                {assignmentGroupText}
            </span>
    }

    renderStudents = () => {

        let hasStudents: boolean = this.props.quizitem.Elever && this.props.quizitem.Elever != null;

        return <span>
                <span data-tip data-for={`students-${this.props.quizitem.Id}`} className="ms-font-l" title="Antall ekstra elever prøven er utdelt til">
                    {hasStudents ? this.props.quizitem.Elever.split(";").length + ` ${this.props.quizitem.Elever.split(";").length === 1 ? "elev" : "elever"}` : 0}
                </span>
                {hasStudents && <ReactTooltip id={`students-${this.props.quizitem.Id}`}>
                    {this.props.quizitem.Elever.split(";").map(elev => {
                        return <span>{elev}<br/></span>
                    })}
                </ReactTooltip>}
            </span>
    
    }

    public render() {
        return (
                <div className="ms-Grid-col ms-sm12 ms-lg6 ms-xl4">
                <div className="card" data-coursename="@item.CourseId" data-courseid="@item.CourseId" data-remaining="@item.DaysRemaining">
                    <div className="card-header myTestsHeader">
                        <div className="ms-font-l">
                            {this.props.quizitem.Title}
                        </div>
                    </div>
                    <div className="card-body">
                        <ul className="list-group list-group-flush">
                            <li className="list-group-item">
                                <span className="ms-font-xl">
                                    <i className="ms-Icon ms-Icon--ReadingMode x-hidden-focus" title="Fag" aria-hidden="true"></i>
                                </span>
                                <span className="ms-font-l" title="Fag">
                                    {this.props.quizitem.CourseName}
                                </span>
                            </li>
                            <li className="list-group-item">
                                <span className="ms-font-xl">
                                    <i className="ms-Icon ms-Icon--Group x-hidden-focus" title="Tilretteleggingsgruppe" aria-hidden="true"></i>
                                </span>
                                {this.renderAssignmentGroup()}
                            </li>
                            <li className="list-group-item">
                                <span className="ms-font-xl">
                                    <i className="ms-Icon ms-Icon--PeopleAdd x-hidden-focus" title="Antall ekstra elever prøven er utdelt til" aria-hidden="true"></i>
                                </span>
                                {this.renderStudents()}
                            </li>
                            <li className="list-group-item">
                                <span className="ms-font-xl">
                                    <i className="ms-Icon ms-Icon--Clock x-hidden-focus" title="Varighet"></i>
                                </span>
                                <span className="ms-font-l" title="Varighet">
                                    {this.props.quizitem.Duration} minutter
                                </span>
                            </li>
                            <li className="list-group-item">
                                <span className="ms-font-xl">
                                    <i className="ms-Icon ms-Icon--BufferTimeBefore x-hidden-focus" title="Gyldig fra"></i>
                                </span>
                                <span className="ms-font-l" title="Gyldig fra">
                                {this.props.quizitem.ValidFromText}
                                </span>
                            </li>
                            <li className="list-group-item">
                                <span className="ms-font-xl">
                                    <i className="ms-Icon ms-Icon--BufferTimeAfter x-hidden-focus" title="Gyldig til"></i>
                                </span>
                                <span className="ms-font-l" title="Gyldig til">
                                {this.props.quizitem.ValidToText}
                                </span>
                            </li>
                        </ul>
                    </div>
                    <div className="card-footer quiz-card-footer">
                        <ActionButton iconProps={{ iconName: 'Edit'}} text="Rediger prøve" onClick={() => this.props.history.push('/quiz/' + this.props.quizitem.Id)}>
                        </ActionButton>
                        <ActionButton iconProps={{ iconName: 'OpenInNewWindow'}} text="Åpne prøve" onClick={() => this.props.history.push('/quiz/take/' + this.props.quizitem.Id)}>
                        </ActionButton>
                        <ActionButton iconProps={{ iconName: 'CheckboxComposite'}} text="Vurder prøve" onClick={() => this.props.history.push('/quiz/grade/' + this.props.quizitem.Id)}>
                        </ActionButton>
                    </div>
                    <div className="modal fade quizStudentStatus" id="studentStatus-@item.Id" role="dialog" aria-labelledby="studentStatusLabel">
                        <div className="modal-dialog" role="document">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h4 className="modal-title" id="studentStatusLabel">
                                        Status: {this.props.quizitem.Title}
                                        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                            <span aria-hidden="true">&times;</span>
                                        </button>
                                    </h4>

                                </div>
                                <div className="modal-body">
                                    <div id="status-@item.Id"></div>
                                </div>
                                <div className="modal-footer">

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                </div>
        );
    }
}
