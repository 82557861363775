import * as React from 'react';
import { Field, reduxForm, change } from 'redux-form';
import { withRouter, Link } from "react-router-dom";
import { connect } from 'react-redux';
import ReactQuill from 'react-quill'; // Typescript
export interface ITakeQuizFormProps {
  quiz: any,
  initialvalues: any;

}
export interface ITakeQuizFormState {
}
let answers = {};
const Completionist = () => <span>Ferdig</span>;
class TakeQuizForm extends React.Component<ITakeQuizFormProps, ITakeQuizFormState> {
  public render() {
    return (
      <div style={{display: 'flex', flexWrap: 'wrap'}}>

        {
          this.props.quiz &&
          this.props.quiz.Quiz.Questions.map((item) => {
            return (
              <div style={{flexGrow: 1, margin: 5, maxWidth: '400px'}}>
                <div className="takeQuiz card" style={{padding: 10}}>
                  <li className="question" id="thequestion-@item.Id" data-mandatory="@item.QMandatory" data-qid="@item.Id" data-qtype="@item.QType">
                    <div className="questionHeader">
                      <span className="ms-font-l">
                        {item.QText}
                      </span>
                      <div className="statusIcon">
                        <i className="ms-Icon ms-Icon--CheckMark" aria-hidden="true"></i>
                      </div>
                    </div>
                    <div style={{padding: 10}}>


                      {
                        item.QType == "1" &&
                        <ul className="no-type-list">
                          {
                            item.Options.map((o) => {
                              return (
                                <li>
                                  <input style={{marginRight: 5}} name="r-@item.Id" type="radio" value="@o.QID" onChange={(checked) => {
                                    answers[item.Id] = o.QID;
                                  }} />
                                  <span>{o.Text}</span>

                                </li>
                              )
                            })

                          }
                        </ul>
                      }
                      {
                        item.QType == "2" &&
                        <ul className="no-type-list">

                          {
                            item.Options.map((o) => {
                              return (
                                <li>
                                  <input style={{marginRight: 5}} type="checkbox" value="@o.QID" onChange={() => {
                                    answers[item.Id] = o.QID;
                                  }} />
                                  <span>{o.Text}</span>
                                </li>
                              )
                            })

                          }
                        </ul>
                      }
                      {
                        item.QType == "4" &&
                        <div id="question-@item.Id" className="textquestion">
                          <ReactQuill defaultValue={""} onChange={(e) => {
                            answers[item.Id] = e;
                          }} />

                        </div>
                      }



                    </div>

                  </li>

                  <div className="questionMainContent">

                  </div>
                  <div className="questionTestsFooter">

                  </div>
                </div>
              </div>
            )
          })
        }
      </div>
    );
  }
}
const mapStateToProps = (state, ownProps) => {
 
  return {
    quiz: ownProps.initialValues
  };
};
function validate(values) {
  const errors: any = {};


  return errors;
}
export default reduxForm({
  form: 'TakeQuizForm',
  validate,
  enableReinitialize: true,
})(
  connect(mapStateToProps, {})(withRouter(TakeQuizForm))
);