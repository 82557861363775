import * as React from "react";
import { connect } from "react-redux";
import CourseContainerTeams from "./CourseContainerTeams";
import {
  Dropdown,
  Icon,
  ActionButton,
  PrimaryButton,
  DefaultButton,
  Checkbox,
} from "office-ui-fabric-react";
import { getTeacherState } from "../../actions/Action_Teacher";
import {
  getTaskElement,
  saveStudentEvalVis,
  plagcheckTask,
} from "../../actions/Action_Innleveringer";
import InnleveringStatusViewTeamsItem from "./InnleveringStatusViewItemTeams";

import { CommandBar } from "office-ui-fabric-react/lib/CommandBar";
import { Check } from "office-ui-fabric-react/lib/Check";
import { IContextualMenuItem } from "office-ui-fabric-react/lib/ContextualMenu";
import { MarqueeSelection } from "office-ui-fabric-react/lib/MarqueeSelection";
import {
  ISelection,
  Selection,
  SelectionMode,
  SelectionZone,
} from "office-ui-fabric-react/lib/Selection";
import { Toggle } from "office-ui-fabric-react/lib/Toggle";
import { mergeStyleSets, IRawStyle } from "office-ui-fabric-react/lib/Styling";
import { memoizeFunction } from "office-ui-fabric-react/lib/Utilities";

import axios from "axios";
import {
  getAssignment,
  getAssignmentFull,
  getClassMembers,
  updateAssignment,
  getAssignmentSubmissions,
  getAssignmentPlagInfo,
  getAssignmentOutcomes,
} from "../../actions/Action_Graph";
import { TeamAssignment } from "../../models/graph/TeamAssignment";
import { TeamSubmission } from "../../models/graph/TeamSubmission";
export interface IInnleveringStatusViewTeamsProps {
  getTaskElement(teacherState: any);
  saveStudentEvalVis(studenttasks: any[]);
  plagcheckTask(data: any);
  getAssignment(classid: string, assignmentid: string);
  getAssignmentFull(classid: string, assignmentid: string);
  getAssignmentSubmissions(classid: string, assignmentid: string);
  getClassMembers(classid: string);
  updateAssignment(classid: string, assignmentid: string, task: any);
  getAssignmentPlagInfo(classid: string, assignmentid: string);
  match: any;
  task: any;
  history: any;
  teamssub: any[];
  plagschecks: any[];
  courseteam: string;
  // membercoll: any;
  // submissions: {};
  plags: any;
  tid: any;
  // outcomz: any;
  assignment: TeamAssignment;
}
export interface IInnleveringStatusViewTeamsState {
  submissions: any[];
  selection: ISelection;
  showstatus: any;
}
let selection: ISelection;
class InnleveringStatusViewTeams extends React.Component<
  IInnleveringStatusViewTeamsProps,
  IInnleveringStatusViewTeamsState
> {
  async componentDidMount() {
    if (this.props.match) {
      const { id } = this.props.match.params;
      let element = await this.props.getTaskElement(id);

      let tid = element.payload.data.TeamsTask.split("|")[0];
      let assid = element.payload.data.TeamsTask.split("|")[1];
      await this.props.getAssignmentFull(tid, assid)
    } else {
      let element = await this.props.getTaskElement(this.props.tid);

      let tid = element.payload.data.TeamsTask.split("|")[0];
      let assid = element.payload.data.TeamsTask.split("|")[1];
      await this.props.getAssignmentFull(tid, assid)
    }
    //console.log("props", this.props);
    // this.props.getAssignmentPlagInfo('87373eb8-72c8-4034-819f-2dc93df8b84c', '18a7cf5f-4d57-42bf-9a05-0f69ad1483d0');
  }
  constructor(props) {
    super(props);
    let tmp = {};
    if (this.props.task) {
      this.props.task.StudentTasks.forEach((element) => {
        tmp[element.Id] = element.ShowEval;
      });
    }
    this.state = {
      submissions: [],
      selection: new Selection({
        onSelectionChanged: this._onSelectionChanged,
      }),
      showstatus: tmp,
    };
  }
  componentWillReceiveProps(props) {
    // console.log("propsgotten", props);
    if (props.task) {
    }
    let tmp = {};
    if (props.task) {
      props.task.StudentTasks.forEach((element) => {
        tmp[element.Id] = element.ShowEval;
      });
      // console.log("props", props);
      if (props.courseteam.length > 0 && !props.membercoll) {
        // this.props.getClassMembers(props.courseteam).then(() => {
        //   this.props.getAssignmentSubmissions(
        //     props.courseteam,
        //     this.props.task.TeamsTask
        //   ).then(()=>{

        //   });

        //   //
        // });
        // console.log("calling", props);

        // let tid = props.task.TeamsTask.split("|")[0];
        // let assignmentId = props.task.TeamsTask.split("|")[1];
        // this.props
        //   .getAssignmentFull(tid, assignmentId)
        //   .then((fullass) => {
        //     //  console.log("fullass", fullass);
        //     if (!props.plags) {
        //       this.props.getAssignmentPlagInfo(
        //         tid,
        //         assignmentId
        //       );
        //     }
        //   });
      }
    }

    this.setState({ showstatus: tmp });
  }
  private _onSelectionChanged = (): void => {
    // this.forceUpdate();
  };

  render() {
    // let a =100;
    // let b = a.indexOf('v');
    return (
      <div>
        {this.props.task && <div></div>}
        {this.props.task && this.props.assignment && (
          <div className="ms-Grid">
            <div className="main container" id="status-view">
              <div className="ms-Grid-row">
                <div className="ms-Grid-col ms-lg5">
                  <div className="header">
                    <div className="ms-Grid-row">
                      <div className="ms-Grid-col">
                        <h1 className="ms-fontColor-orangeLight">
                          {" "}
                          <Icon iconName={"CompletedSolid"} /> Innlevering
                          (TEAMS)
                        </h1>
                        <h2 className="ms-fontColor-orangeLight zSubTitle ms-font-l">
                          {" "}
                          <b>Oppgave: </b>
                          {this.props.task.Name}
                        </h2>
                        <h2 className="ms-fontColor-orangeLight zSubTitle ms-font-l">
                          {" "}
                          <b>Frist:</b> {this.props.task.Deadlinetekst}
                        </h2>
                        <h2 className="ms-fontColor-orangeLight zSubTitle ms-font-l">
                          <b>Tilretteleggingsgruppe:</b>{" "}
                          {this.props.task.GroupName}
                        </h2>
                        <h2 className="ms-fontColor-orangeLight zSubTitle ms-font-l">
                          <b>Fag:</b> {this.props.task.CourseName}
                        </h2>
                      </div>
                    </div>
                    {/* <div className="ms-Grid-row">
                                            {this.props.children}
                                        </div> */}
                  </div>
                </div>
                <div className="ms-Grid-col ms-lg7">
                  <div className="ms-Grid-col ms-sm12 ms-xxl6 text-left ms-fontColor-black studentTaskTopCol">
                    <h4 className=" ms-fontColor-black">Oppgavetekst</h4>
                    <p>
                      {" "}
                      <span
                        dangerouslySetInnerHTML={{
                          __html: this.props.task.Text,
                        }}
                      ></span>
                    </p>
                    <p>
                      <ul>
                        {this.props.task.TaskFiles &&
                          this.props.task.TaskFiles.map((taskF) => {
                            return (
                              <li>
                                <a href={taskF.FileUrl} target="_blank">
                                  {taskF.FileName}
                                </a>
                              </li>
                            );
                          })}
                      </ul>
                    </p>
                  </div>
                  <div className="ms-Grid-col ms-sm12 ms-xxl6 text-left ms-fontColor-black studentTaskTopCol">
                    <h4 className="ms-fontColor-black">Kompetansemål</h4>
                    <ul>
                      {this.props.task.GrepCodeList.map((grp) => {
                        return (
                          <li>
                            <a href={grp.DetailUrl} target="_blank">
                              {grp.GrepTekst}
                            </a>
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                </div>
              </div>
              <div className="ms-Grid-row">
                <div className="ms-Grid-col ms-sm12">
                  <div className="ms-Grid-row ms-bgColor-neutralLight topTool">
                    <div className="ms-Grid-col ms-sm12 ms-md6 ms-lg7 text-left mb-10">
                      <Toggle
                        defaultChecked={false}
                        onText="Alle er valgt"
                        offText="Velg alle"
                        onChanged={(newVal) => {
                          let tmp = this.state.showstatus;
                          this.props.task.StudentTasks.forEach((element) => {
                            tmp[element.Id] = newVal;
                          });
                          this.setState({ showstatus: tmp });
                        }}
                      />
                    </div>

                    <div className="ms-Grid-col ms-sm12">
                      <ActionButton
                        onClick={() => {
                          this.props.history.push(
                            "/task/distribute/" + this.props.task.Id
                          );
                        }}
                        iconProps={{ iconName: "RecurringTask" }}
                        className="ms-font-l"
                        text="Del ut"
                      />
                      <ActionButton
                        iconProps={{ iconName: "Send" }}
                        className="ms-font-l"
                        text="Send vurdering til elev(er)"
                        onClick={() => {
                          let data = [];
                          this.props.task.StudentTasks.forEach((element) => {
                            element.ShowEval = this.state.showstatus[
                              element.Id
                            ];
                            data.push({
                              Id: element.Id,
                              ShowEval: element.ShowEval,
                            });
                          });
                          this.props.saveStudentEvalVis(data).then(() => {
                            this.props.getTaskElement(this.props.task.Id);
                          });
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="ms-Grid-row">
                {/* <ActionButton
                  iconProps={{ iconName: "Refresh" }}
                  className="ms-font-l"
                  text="Oppdater plagiatkontroll"
                  onClick={() => {
                    // this.props.plagcheckTask(this.props.task);
                    this.props.getAssignmentPlagInfo(
                      this.props.courseteam,
                      this.props.task.TeamsTask
                    );
                  }}
                /> */}
              </div>
              <div className="ms-Grid-row">
                <ul className="no-type-list">
                  {/* {
                                            this.props.task.StudentTasks.map((studenttask) => {
                                                return (
                                                    <InnleveringStatusViewTeamsItem studenttask={studenttask} history={this.props.history} task={this.props.task}></InnleveringStatusViewTeamsItem>
                                                )
                                            }
                                            )

                                        } */}
                </ul>
                <div className="ms-SelectionBasicExample">
                  {/* <CommandBar items={this.props.task.StudentTasks} /> */}
                  {/* <MarqueeSelection selection={selection} isEnabled={true}>
                                            <SelectionZone selection={selection} > */}

                  {/* {this.props.task.StudentTasks.map( */}
                  {this.props.assignment.submissions && this.props.assignment.submissions.map(
                    (submission: TeamSubmission, index: number) => {
                      //  console.log("props", this.props);
                      //  console.log("map", item);
                      let sign = "";
                      if (this.props.plagschecks) {
                        this.props.plagschecks.forEach((element) => {
                          if (element.Id == submission.graphSubmission.id) {
                            sign = element.UrkundErrorMessage;
                          }
                        });
                      }
                      let outcome: any = null;
                      if (submission.graphSubmission.outcomes && submission.graphSubmission.outcomes.length > 0) {
                        console.log("item", "haroutcome");
                        outcome = submission.graphSubmission.outcomes[0];
                      } else {
                        console.log("item", "harikkeoutcome");
                      }
                      return (
                        <div>
                          {/* <Check checked={this.state.showstatus[item.Id]} /> */}
                          <Checkbox
                            checked={this.state.showstatus[submission.graphSubmission.id]}
                            label=""
                            onChange={(e, val) => {
                              let tmp = this.state.showstatus;
                              tmp[submission.graphSubmission.id] = val;
                              this.setState({ showstatus: tmp });
                            }}
                          />
                          <InnleveringStatusViewTeamsItem
                              outcomz={outcome}
                              key={submission.graphSubmission.id}
                              itemIndex={index}
                              selection={selection}
                              studenttask={submission}
                              history={this.props.history}
                              task={this.props.task}
                              showeval={this.state.showstatus[submission.graphSubmission.id]}
                              sign={sign}
                              submission={submission}
                              classid={this.props.courseteam}
                              assignmentid={this.props.task.TeamsTask}
                            ></InnleveringStatusViewTeamsItem>
                        </div>
                      );
                    }
                  )}

                  {this.props.task.StudentTasks.map(
                    (item: any, index: number) => {
                      //  console.log("props", this.props);
                      //  console.log("map", item);
                      let sign = "";
                      if (this.props.plagschecks) {
                        this.props.plagschecks.forEach((element) => {
                          if (element.Id == item.Id) {
                            sign = element.UrkundErrorMessage;
                          }
                        });
                      }
                      let submission: TeamSubmission = null;
                      let outcome: any = null;
                      if (this.props.assignment.submissions) {
                        submission = this.props.assignment.submissions.find(s => s.userName == item.LoginName);
                        if (submission.graphSubmission.outcomes && submission.graphSubmission.outcomes.length > 0) {
                          console.log("item", "haroutcome");
                          outcome = submission.graphSubmission.outcomes[0];
                        } else {
                          console.log("item", "harikkeoutcome");
                        }
                      }
                      return (
                        <div>
                          {/* <Check checked={this.state.showstatus[item.Id]} /> */}
                          <Checkbox
                            checked={this.state.showstatus[item.Id]}
                            label=""
                            onChange={(e, val) => {
                              let tmp = this.state.showstatus;
                              tmp[item.Id] = val;
                              this.setState({ showstatus: tmp });
                            }}
                          />
                          {this.props.plags && (
                            <InnleveringStatusViewTeamsItem
                              outcomz={outcome}
                              key={item.key}
                              itemIndex={index}
                              selection={selection}
                              studenttask={item}
                              history={this.props.history}
                              task={this.props.task}
                              showeval={this.state.showstatus[item.Id]}
                              sign={sign}
                              submission={submission}
                              classid={this.props.courseteam}
                              assignmentid={this.props.task.TeamsTask}
                            ></InnleveringStatusViewTeamsItem>
                          )}
                        </div>
                      );
                    }
                  )}

                  {/* //     </SelectionZone>
                                        // </MarqueeSelection> */}
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}

function mapStateToProps(state) {
  console.log("state", state);
  let ct = "";
  if (state.tasks.task) {
    ct = state.teacher.teamscourses[state.tasks.task.CourseID];
  }

  // console.log("state.GraphReducer", state.GraphReducer);
  if (state.GraphReducer.assignmentplags) {
    // console.log("har");
  } else {
    //   console.log("harikke");
  }
//   if (state.GraphReducer.currentassignment) {
//     let qualmap = {};
//     let levelmap = {};
//     let levelmaprev ={};
//     if (state.GraphReducer.currentassignment.rubricz) {
//       qualmap = state.GraphReducer.currentassignment.rubricz.qualities.reduce(
//         function (map, obj) {
//           map[obj.qualityId] = obj;
//           return map;
//         },
//         {}
//       );
//       levelmap = state.GraphReducer.currentassignment.rubricz.levels.reduce(
//         function (map, obj) {
//           map[obj.levelId] = obj;
//           return map;
//         },
//         {}
//       );
//       levelmaprev = state.GraphReducer.currentassignment.rubricz.levels.reduce(
//         function (map, obj) {
//           map[obj.displayName] = obj;
//           return map;
//         },
//         {}
//       );
//     }

//     let alloutcomes = state.GraphReducer.currentassignmentoutcomes.reduce(
//       function (map, obj) {
//         let red = obj.value[2].rubricQualitySelectedLevels.reduce(function (
//           map,
//           objqual
//         ) {
//           objqual.level = levelmap[objqual.columnId];
//           objqual.levelmap = levelmap;
//           if (levelmap[objqual.columnId]) {
//             switch (levelmap[objqual.columnId].displayName) {
//               case 'Ikke vurdert':
//                 objqual.levelset = 0;
//                 break;
//               case 'Lav':
//                 objqual.levelset = 1;
//                 break;
//                 case 'Middels':
//                   objqual.levelset = 2;
//                   break;
//                   case 'Høy':
//                 objqual.levelset = 3;
//                 break;
//               default:
//                 objqual.levelset = 0;
//                 break;
//             }
//           }
//           map[
//             qualmap[objqual.qualityId].description.content.split("|")[1]
//           ] = objqual;
//           return map;
//         },
//         {});

//         obj.km = red;
// obj.levelmaprev = levelmaprev;
// obj.levelmap = levelmap;
//         map[obj.submissionid] = obj;
//         return map;
//       },
//       {}
//     );
//     // console.log("ok");
//     if (state.GraphReducer.assignmentplags) {
//       //    console.log("plags", state.GraphReducer.assignmentplags);
//       //     console.log(
//       //   "res",
//       //   state.GraphReducer.currentassignment.submissionsz.resources
//       //);
//       Object.keys(state.GraphReducer.currentassignment.submissionsz).forEach(
//         (key) => {
//           //   console.log(key);
//           if (
//             alloutcomes[
//               state.GraphReducer.currentassignment.submissionsz[key].id
//             ]
//           ) {
//             console.log("HIT");
//             state.GraphReducer.currentassignment.submissionsz[key].outcomz =
//               alloutcomes[
//                 state.GraphReducer.currentassignment.submissionsz[key].id
//               ];
//           }
//           state.GraphReducer.currentassignment.submissionsz[
//             key
//           ].resources.forEach((element) => {
//             if (state.GraphReducer.assignmentplags[element.id]) {
//               element.plaginfo = state.GraphReducer.assignmentplags[element.id];
//             }
//           });
//         }
//       );
//     }

//     //   console.log("subz", state.GraphReducer.currentassignment.submissionsz);
//     //   console.log("state.GraphReducersetting", state.GraphReducer);
//     return {
//       task: state.tasks.task,
//       plagschecks: state.tasks.plagcheck,
//       courseteam: ct,
//       membercoll: state.GraphReducer.currentassignment.membersz,
//       submissions: state.GraphReducer.currentassignment.submissionsz,
//       plags: state.GraphReducer.assignmentplags,
//       outcomz: alloutcomes,
//       assignment: state.GraphReducer.currentassignment
//     };
//   }
  // console.log("null");
  return {
    task: state.tasks.task,
    plagschecks: state.tasks.plagcheck,
    courseteam: ct,
    membercoll: null,
    submissions: null,
    plags: null,
    outcomz: {},
    assignment: state.GraphReducer.currentassignment
  };
}

export default connect(mapStateToProps, {
  getTaskElement,
  saveStudentEvalVis,
  plagcheckTask,
  getAssignment,
  getAssignmentFull,
  getClassMembers,
  updateAssignment,
  getAssignmentSubmissions,
  getAssignmentPlagInfo,
  getAssignmentOutcomes,
})(InnleveringStatusViewTeams);
