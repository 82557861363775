
export class CopySchedule {
    Id:string;
    Classid:string;
    Toweek:string;
    Toyear:string;
    Toclassid:string;
    Weekno:string;
    Yearno:string;


    constructor(Classid: string, Toweek: string, Toyear: string, Toclassid: string, Weekno: string, Yearno: string) {
        this.Classid = Classid;
        this.Toweek = Toweek;
        this.Toyear = Toyear;
        this.Toclassid = Toclassid;
        this.Weekno = Weekno;
        this.Yearno = Yearno;
    }
}