import axios from "axios";
import * as _ from "lodash";
import { SP_HOST_URL, ROOT_URL } from "./constants";

export const GET_STUDENT_PORTFOLIO = "GET_STUDENT_PORTFOLIO";
export const GET_COURSE_PORTFOLIO = "GET_COURSE_PORTFOLIO";
export const GET_ACTORS_PORTFOLIO = "GET_ACTORS_PORTFOLIO";
export const GET_COURSE_GRADEREPORT = "GET_COURSE_GRADEREPORT";
export const CHECK_STUDENT_PORTFOLIO = 'CHECK_STUDENT_PORTFOLIO'; 
export const CHECK_COURSE_PORTFOLIO = 'CHECK_COURSE_PORTFOLIO'; 

export const SET_PLANVERSJON = 'SET_PLANVERSJON';
// export const SET_KOMPETANSEMAALDETAILS = ""
export function setPlanversjon(planversjon: string){
    return {
        type: SET_PLANVERSJON,
        payload: planversjon
    }
}

export function getStudentElements(
  student: string,
  lp: string,
  from: any,
  to: any
) {
  const data: FormData = new FormData();
  data.set("Student", student);
  data.set("LP", lp);
  data.set("From", from);
  data.set("To", to);
  const request = axios.post(
    `${ROOT_URL}/GetStudentPortfolio${SP_HOST_URL}`,
    data,
    {
      headers: {
        Authorization: localStorage.getItem("id_token"),
      },
    }
  );

  return {
    type: GET_STUDENT_PORTFOLIO,
    payload: request,
  };
}
export function getCourseElements(
  student: string,
  lp: string,
  from: any,
  to: any
) {
  const data: FormData = new FormData();
  data.set("Student", student);
  data.set("LP", lp);
  data.set("From", from);
  data.set("To", to);

  const request = axios.post(
    `${ROOT_URL}/GetCoursePortfolio${SP_HOST_URL}`,
    data,
    {
      headers: {
        Authorization: localStorage.getItem("id_token"),
      },
    }
  );

  return {
    type: GET_COURSE_PORTFOLIO,
    payload: request,
  };
}
export function getGradeReport(course: any) {
  course.CourseID = course.CourseID.split("|")[0];
  const request = axios.post(
    `${ROOT_URL}/GetGradeReport${SP_HOST_URL}`,
    course,
    {
      headers: {
        Authorization: localStorage.getItem("id_token"),
      },
    }
  );

  return {
    type: GET_COURSE_GRADEREPORT,
    payload: request,
  };
}

export function checkStudentPortfolio (
  student: string,
  lpKoder: string,
  from: any,
  to: any
) {
  console.log("PORTFOLIO-PROPS:", from, to);
  const data: FormData = new FormData();
  data.set("Student", student);
  data.set("Koder", lpKoder);
  data.set("From", from);
  data.set("To", to);
  const request = axios.post(
    `${ROOT_URL}/CheckStudentPortfolio${SP_HOST_URL}`,
    data,
    {
      headers: {
        Authorization: localStorage.getItem("id_token"),
      },
    }
  );

  return {
    type: CHECK_STUDENT_PORTFOLIO,
    payload: request,
  };
}

export function checkCoursePortfolio (
  student: string,
  lpKoder: string,
  from: any,
  to: any
) {
  const data: FormData = new FormData();
  data.set("Student", student);
  data.set("Koder", lpKoder);
  data.set("From", from);
  data.set("To", to);
  const request = axios.post(
    `${ROOT_URL}/CheckCoursePortfolio${SP_HOST_URL}`,
    data,
    {
      headers: {
        Authorization: localStorage.getItem("id_token"),
      },
    }
  );

  return {
    type: CHECK_COURSE_PORTFOLIO,
    payload: request,
  };
}


export function getActorElements(statedata: any) {
  const data: FormData = new FormData();
  data.set(
    "classes",
    JSON.stringify(_.values(statedata.subscribedSchoolClasses))
  );
  data.set("schools", JSON.stringify(_.values(statedata.schools)));
  data.set("courses", JSON.stringify(_.values(statedata.subscribedCourses)));

  const request = axios.post(
    `${ROOT_URL}/GetActorsPortfolio${SP_HOST_URL}`,
    data,
    {
      headers: {
        Authorization: localStorage.getItem("id_token"),
      },
    }
  );

  return {
    type: GET_ACTORS_PORTFOLIO,
    payload: request,
  };
}
