import * as React from "react";
interface ITimerProps {
   minutes:number;
   timesup();
}
interface ITimerState {
    minutes: number,
    seconds: number,
}
let myInterval;
export default class Timer extends React.Component<
ITimerProps,
ITimerState
> 
   {
    constructor(props: ITimerProps) {
        super(props);
    
        this.state = {
            minutes: props.minutes,
            seconds: 0,
        };
      }
    componentDidMount() {
         myInterval = setInterval(() => {
            const { seconds, minutes } = this.state

            if (seconds > 0) {
                this.setState(({ seconds }) => ({
                    seconds: seconds - 1
                }))
            }
            if (seconds === 0) {
                if (minutes === 0) {
                    clearInterval(myInterval);
                    this.props.timesup();
                } else {
                    this.setState(({ minutes }) => ({
                        minutes: minutes - 1,
                        seconds: 59
                    }))
                }
            } 
        }, 1000)
    }

    componentWillUnmount() {
        clearInterval(myInterval)
    }

    render() {
        const { minutes, seconds } = this.state
        return (
            <div>
                { minutes === 0 && seconds === 0
                    ? <h1>Ferdig</h1>
                    : <h1>{minutes}:{seconds < 10 ? `0${seconds}` : seconds}</h1>
                }
            </div>
        )
    }
}
   

   