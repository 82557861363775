import * as React from 'react';

import { withRouter, Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { Field, reduxForm, change } from 'redux-form';
import {
	Dropdown,
	TextField,
	autobind,
	PrimaryButton,
	ActionButton,
	DefaultButton,
	Label,
	Toggle,
	Icon,
	Spinner,
	SpinnerSize
} from 'office-ui-fabric-react';
import DatePicker from 'react-datepicker';
import * as moment from 'moment';
import ReactQuill from 'react-quill'; // Typescript
import { saveStudentEval, getTaskElement } from '../../actions/Action_Innleveringer';
import SelectField from '../form_fields/SelectField';
import RichTextField from '../form_fields/RichTextField';
import { setstatus } from '../../actions/Action_Teacher';
import { IconNames } from '@uifabric/icons';
import OneDrivePicker from "../shared/OneDrivePicker";
import { updateOutcome, returnSubmission } from '../../actions/Action_Graph';
import SelectFieldNoStyling from '../form_fields/SelectFieldNoStyling';
import RichTextFieldNoStyling from '../form_fields/RichTextFieldNoStyling';
import { Student } from '../../models/Student';
import RadioButtonList from '../form_fields/RadioButtonList';
import ToggleField from '../form_fields/ToggleField';
import ReactTooltip from 'react-tooltip';
import axios from 'axios';
import { ROOT_URL, SP_HOST_URL } from '../../actions/constants';
import { Grid } from '@material-ui/core';
import { fileURLToPath } from 'url';
import EvaluationFiles from './EvaluationFiles';
import { ITaskEvaluationFileInfo } from '../../models/interfaces/ITaskEvaluationFile';
export interface IEvalFormRebuildProps {
	initialValues: any;
	history: any;
	match: any;
	handleSubmit: any;
	fromprops: any;
	showeval: boolean;
	saveStudentEval(studenttask: any);
	setstatus(statedata: any);
	updateOutcome(outcome: any, classid: string, assignmentid: string, submissionid: string, outcomeid: string);
	returnSubmission(classid: string, assignmentid: string, submissionid: string);
	singleview: boolean;
	submission: any;
	getTaskElement(teacherState: any);
	// onClickNext();
	focusTopRef();
	location: any;
	studentArray: any[];
	onSaveFinished?: () => void;

}
export interface IEvalFormRebuildState {
	Task: any;
	Uips: any[];
	ShowEval: boolean;
	ShowNext: boolean;
	nextClicked: boolean;

	// Used for EvaluationFiles component because React doesnt work
	isLoadingFiles: boolean;
	filesToUpload: any[];
	evaluationFiles: ITaskEvaluationFileInfo[];
	filesLoading: string[];
  	errorFetchingFiles: boolean;
}
let comment = '';
class EvalFormRebuild extends React.Component<IEvalFormRebuildProps, IEvalFormRebuildState> {
	constructor(props) {
		super(props);
		// console.log('constructor', props);
		this.state = {
			Task: this.props.initialValues,
			Uips: this.props.initialValues.Uipkms,
			ShowEval: this.props.initialValues.ShowEval,
			nextClicked: false,
			ShowNext: true,

			// EVALUATION FILES
			isLoadingFiles: false,
			filesToUpload: [],
			evaluationFiles: [],
			filesLoading: [],
	  		errorFetchingFiles: false
		};
	}
	componentWillReceiveProps(props) {
	//	this.setState({Uips:props.initialValues.Uipkms});
		//this.setState({EvalText: props.GradingComment});
	}
	/****************************
          Event handlers
   ****************************/

	async onFormSubmit(values) {
		const requestValues = this.formatFormValueDatesToDateString(values);
		console.log('tttsaving',values);
		await this.saveEvaluationFiles(this.state.Task);
		if (this.props.submission) {
			console.log('22',this.props.submission);
			let clone = JSON.parse(JSON.stringify(this.props.submission.outcome.value[2].rubricQualitySelectedLevels));
			let newoutcome = [];
			console.log('1234', this.props.submission);
			values.Uipkms.forEach((element) => {
				console.log('1234',element);

				clone.forEach((r) => {
					console.log('1234',r.qualityId);
					if (r.qualityId == this.props.submission.qualmaprev[element.Kmid].qualityId) {
						console.log('1234','match');
						console.log('1234element',element);
						let columnId = '';
						switch (element.Eval) {
							case 0:
								columnId = this.props.submission.levelmaprev['Ikke vurdert'].levelId;
								break;
							case 1:
								columnId = this.props.submission.levelmaprev['Lav'].levelId;
								break;
							case 2:
								columnId = this.props.submission.levelmaprev['Middels'].levelId;
								break;
							case 3:
								columnId = this.props.submission.levelmaprev['Høy'].levelId;
								break;
								case '0':
								columnId = this.props.submission.levelmaprev['Ikke vurdert'].levelId;
								break;
							case '1':
								columnId = this.props.submission.levelmaprev['Lav'].levelId;
								break;
							case '2':
								columnId = this.props.submission.levelmaprev['Middels'].levelId;
								break;
							case '3':
								columnId = this.props.submission.levelmaprev['Høy'].levelId;
								break;
							default:
								break;
						}
						console.log('1234columnId',columnId);
						newoutcome.push({ qualityId: r.qualityId, columnId: columnId });
					}
				});
			});
			console.log('newoutcome', newoutcome);
			let c = {} as any;
			c['@odata.type'] = '#microsoft.graph.educationRubricOutcome';
			c.rubricQualitySelectedLevels = newoutcome;
			c.publishedRubricQualitySelectedLevel = newoutcome;

			this.props
				.updateOutcome(
					c,
					this.props.submission.classid,
					this.props.submission.assignmentid,
					this.props.submission.id,
					this.props.submission.outcome.value[2].id
				)
				.then(() => {
					console.log('tttsaving2',values);
					let t = JSON.parse(JSON.stringify(this.props.submission.outcome.value[0]));
					t['@odata.type'] = '#microsoft.graph.educationFeedbackOutcome';

					t.feedback = {
						text: {
							content: values.GradingComment,
							contentType: 'text'
						}
					};
					if (t.feedback && t.feedback.text) {
						t.feedback.text.content = values.GradingComment;
					}
					this.props
						.updateOutcome(
							t,
							this.props.submission.classid,
							this.props.submission.assignmentid,
							this.props.submission.id,
							this.props.submission.outcome.value[0].id
						)
						.then(() => {
							console.log('saved');
							this.props
								.returnSubmission(
									this.props.submission.classid,
									this.props.submission.assignmentid,
									this.props.submission.id
								)
								.then(() => {
									console.log('returned');
									this.props.setstatus({
										working: true,
										text: 'saving ' + this.props.fromprops.StudentName
									});
									console.log('saving');
									saveStudentEval(
										{
											Grade: values.Grade,
											GradeModifier: values.GradeModifier,
											GradingComment: values.GradingComment,
											Id: this.props.fromprops.Id,
											Uipkms: values.Uipkms,
											ShowEval: this.state.ShowEval
										},
										async () => {
											this.props.setstatus({
												working: false,
												text: `Lagret ${this.props.initialValues.StudentName}`
											});
											if (values.ShowEval) {
												await this.publishEvaluationFiles();
											}
											this.props.getTaskElement(this.props.fromprops.WsTaskID);
											if (this.props.singleview) {
												if (this.state.nextClicked) {
													this.props.focusTopRef();
													this.onClickNext();
												} else {
													this.props.history.push(
														'/innleveringer/' + this.props.fromprops.WsTaskID
													);
												}
											}
										}
									);
								});
						});
				});
		} else {
			this.props.setstatus({
				working: true,
				text: 'saving ' + this.props.initialValues.StudentName
			});
			console.log('feilsaving', values);
			saveStudentEval(
				{
					Grade: values.Grade,
					GradeModifier: values.GradeModifier,
					GradingComment: values.GradingComment,
					Id: values.Id,
					//Uipkms: this.state.Uips,
					Uipkms: values.Uipkms,
					//ShowEval: this.state.ShowEval
					ShowEval: values.ShowEval
				},
				async (ret) => {
					console.log('evalsave',ret);
					if(ret.data && ret.data==='OK')
					{
						this.props.setstatus({
							working: false,
							text: `Lagret ${this.props.initialValues.StudentName}`,
							error:false,
						});
						if(this.props.onSaveFinished) {
							this.props.onSaveFinished();
						}
					}
					else{
						this.props.setstatus({
							working: false,
							text: `Lagring feilet for ${this.props.initialValues.StudentName}`,
							error:true
						});
					}
					if (values.ShowEval) {
						await this.publishEvaluationFiles();
					}

					console.log('feilsaved', this.props);
					if (this.props.singleview) {
						if (this.state.nextClicked) {
							this.props.focusTopRef();
							this.onClickNext();
						} else {
							console.log('feilredirect');
							this.props.history.push('/innleveringer/' + this.props.initialValues.WsTaskID);
						}
					} else {
						console.log('feilnotredirect');
						// this.props.getTaskElement(this.props.initialValues.WsTaskID);
					}
				}
			);
		}
		//this.props.setstatus({working:true,text:'saving '+this.props.fromprops.StudentName});

		// saveStudentEval({ Grade: this.state.Grade, GradeModifier: this.state.GradeModifier, GradingComment: comment, Id: this.props.fromprops.Id, Uipkms:this.state.Uips,ShowEval:this.state.ShowEval}, () => {
		//  this.props.setstatus({working:false,text:`Lagret ${this.props.fromprops.StudentName}`});
		//   if (this.props.singleview)
		//   {
		//     this.props.history.push("/innleveringer/" + this.props.fromprops.WsTaskID);
		//   }

		// })
	}

	// handleChange(event) {
	//   const target = event.target;
	//   const value = target.value;
	//   const name = target.name;
	//   switch (name) {
	//     case "Grade":
	//       this.setState({ Grade: value });
	//       break;
	//     case "GradeModifier":
	//       this.setState({ GradeModifier: value });
	//       break;
	//     default:
	//       comment = value;
	//       break;
	//   }
	// }

	handleChangeQuill(html) {
		comment = html;
	}
	onDeleteClick() {
		const { id } = this.props.match.params;

		if (isNaN(id)) {
			return;
		}

		if (confirm('Vil du virkelig slette dette timeplanelementet?')) {
			// this.props.deletePlanElement(this.props.planElements[id], () => {
			//     this.props.history.push('/timeplan');
			// });
		}
	}

	/****************************   
          Helper methods
   ****************************/
	onClickNext = () => {
		let nextStudentId;
		let { studentArray } = this.props;

		//------Getting id to next student-----//
		for (let i = 0; i < studentArray.length; i++) {
			console.log(studentArray[i].value, this.state.Task.Id);
			if (studentArray[i].value == this.state.Task.Id) {
				if (i == studentArray.length - 1) {
					nextStudentId = studentArray[0].value;
					break;
				}
				nextStudentId = studentArray[i + 1].value;
				break;
			}
		}
		this.props.history.push(`${nextStudentId}`);
	};
	formatFormValueDatesToDateString(values) {
		return values;
	}

	fetchEvaluationFiles = async (task) => {
        try {
            this.setState({isLoadingFiles: true, errorFetchingFiles: false});
            const res = await axios.get(`${ROOT_URL}/GetTaskEvaluationFiles${SP_HOST_URL}&taskId=${task.WsTaskID}&studentLoginName=${encodeURIComponent(task.LoginName)}`, {
                headers: {
                    Authorization: localStorage.getItem('id_token')
                }
            })
            this.setState({evaluationFiles: res.data as ITaskEvaluationFileInfo[], isLoadingFiles: false});
        }
        catch (error) {
            this.setState({errorFetchingFiles: true, isLoadingFiles: false});
        }
    }

	saveEvaluationFiles = async (task: any) => {
		if (this.state.filesToUpload.length > 0) {
			console.log("Saving");
			const request =  {
				FileList: this.state.filesToUpload.map(file => {
					return {
						FileName: file.FileName,
						GraphItemId: file.GraphItemId,
						GraphParentDriveId: file.GraphParentDriveId,
						GraphParentItemId: file.GraphParentItemId,
						OriginalDocumentID: file.OriginalDocumentID,
					}
				}),
				Task: task
			};
	
			this.setState({filesLoading: [...this.state.filesLoading, ...this.state.filesToUpload.map(x => x.GraphItemId)]});
			const res = await axios.post(`${ROOT_URL}/AddTaskEvaluationFile${SP_HOST_URL}`, {
				request
			}, {
				headers: {
					Authorization: localStorage.getItem('id_token')
				}
			})
			
			const uploadFiles = this.state.filesToUpload;
			this.setState({evaluationFiles: [...res.data], filesToUpload: [], filesLoading: [...this.state.filesLoading.filter(x => uploadFiles.map(y => y.GraphItemId).indexOf(x) < 0)]})
		}
	  }
	
	
	  deleteEvaluationFile = async (task, file) =>  {
		const uploaded: boolean = !!file.WopiUrl;
		const uniqueId: string = uploaded ? file.UniqueId : file.GraphItemId;
		const tooltipId: string = `${uniqueId}-${file.FileName}`;
		try {
			if (uploaded) {
			  this.setState({filesLoading: [...this.state.filesLoading, uniqueId]})
			  const res = await axios.post(`${ROOT_URL}/DeleteTaskEvaluationFiles${SP_HOST_URL}`, {
				  FilesToDelete: [file],
				  Task: task
			  }, {
				  headers: {
					  Authorization: localStorage.getItem('id_token')
				  }
			  })
			  
			  this.setState({evaluationFiles: [...this.state.evaluationFiles.filter(x => x.FileName != file.FileName)], filesLoading: [...this.state.filesLoading.filter(x => x != uniqueId)]})
			}
			else {
			  this.setState({filesToUpload: this.state.filesToUpload.filter(x => x.FileName != file.FileName)});
			}
		}
		catch (error) {
		}
	}

	  onSelecteEvaluationFiles = (file: any) => {
		this.setState({filesToUpload: [...this.state.filesToUpload, file]})
	  }

	publishEvaluationFiles = async () => {
		const res = await axios.post(`${ROOT_URL}/PublishTaskEvaluationFiles${SP_HOST_URL}`, {
			Task: this.state.Task
		}, {
			headers: {
				Authorization: localStorage.getItem('id_token')
			}
		});
	}

	/****************************
          Render methods
   ****************************/
	mapPlanElementsToKeys() {
		const optionKeyList = [];
		optionKeyList.push({ key: '', text: 'Angi karakter' });
		optionKeyList.push({ key: 'Lav', text: 'Lav' });
		optionKeyList.push({ key: 'Middels', text: 'Middels' });
		optionKeyList.push({ key: 'Høy', text: 'Høy' });
		optionKeyList.push({ key: '1', text: '1' });
		optionKeyList.push({ key: '2', text: '2' });
		optionKeyList.push({ key: '3', text: '3' });
		optionKeyList.push({ key: '4', text: '4' });
		optionKeyList.push({ key: '5', text: '5' });
		optionKeyList.push({ key: '6', text: '6' });
		optionKeyList.push({ key: 'Godkjent', text: 'Godkjent' });
		optionKeyList.push({ key: 'Ikke godkjent', text: 'Ikke godkjent' });
		optionKeyList.push({ key: 'Ikke vurdert', text: 'Ikke vurdert' });

		{
			/* <option>
            <option>Angi karakter</option>
                  <option value="1">1</option>
                  <option value="2">2</option>
                  <option value="3">3</option>
                  <option value="4">4</option>
                  <option value="5">5</option>
                  <option value="6">6</option>
                  <option value="Godkjent">Godkjent</option>
                  <option value="ikke godkjent">Ikke godkjent</option>
            </option> */
		}
		return optionKeyList;
	}
	mapModifierElementsToKeys() {
		const optionKeyList = [];
		optionKeyList.push({ key: '', text: 'Angi styrke' });
		optionKeyList.push({ key: 'Sterk', text: 'Sterk' });
		optionKeyList.push({ key: 'Svak', text: 'Svak' });
		optionKeyList.push({ key: '+', text: '+' });
		optionKeyList.push({ key: '-', text: '-' });

		{
			/* <option value="Sterk">Sterk</option>
                  <option value="Svak">Svak</option>
                  <option value="+">+</option>
                  <option value="-">-</option> */
		}
		return optionKeyList;
	}
	render() {
		console.log('008render', this.props);
		const { handleSubmit } = this.props;

		return (
			<form onSubmit={handleSubmit(this.onFormSubmit.bind(this))}>
				<div className="ms-Grid-row">
					<div className="ms-Grid-col ms-sm12">
						<div className="mb-10">
							<div className="ms-Grid-row">
								<div className="ms-Grid-col ms-md12 ms-lg6 ms-xxl4">
									<Field name="Grade" label="Karakter" component={SelectFieldNoStyling}>
										<option>{this.mapPlanElementsToKeys()}</option>
									</Field>
								</div>

								<div className="ms-Grid-col ms-md12 ms-lg6 ms-xxl4">
									<Field name="GradeModifier" label="" component={SelectFieldNoStyling}>
										<option>{this.mapModifierElementsToKeys()}</option>
									</Field>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className="ms-Grid-row">
					<div className="ms-Grid-col ms-sm12">
						<Field name="GradingComment" label="Egendefinerte mål" component={RichTextFieldNoStyling} />
					</div>
				</div>

				<div className="uip-row">
					<Label>
						<b>Grad av måloppnåelse</b>
					</Label>
					<Field name="Uipkms" label="" component={RadioButtonList}>
					</Field>
				</div>
				<EvaluationFiles
					Task={this.state.Task}
					CanBeEdited={true}
					isLoadingFiles={this.state.isLoadingFiles}
					filesToUpload={this.state.filesToUpload}
					evaluationFiles={this.state.evaluationFiles}
					filesLoading={this.state.filesLoading}
					errorFetchingFiles={this.state.errorFetchingFiles}
					fetchEvaluationFiles={this.fetchEvaluationFiles}
					deleteEvaluationFile={this.deleteEvaluationFile}
					onSelecteEvaluationFiles={this.onSelecteEvaluationFiles}
				/>
				{this.props && (
					<div className="ms-Grid-row">
						<div className="ms-Grid-col ms-sm-12 float-left">
							{this.props.singleview && (
								// <Toggle
								// 	defaultChecked={this.props.initialValues.ShowEval}
								// 	onText="Publiser til elev"
								// 	offText="Ikke publiser til elev"
								// 	onChanged={(newVal) => {
								// 		this.setState({ ShowEval: newVal });
								// 	}}
								// 	style={{ marginBottom: '5px' }}
								// />
								<Field name="ShowEval" label="" component={ToggleField} />
							)}
							<PrimaryButton
								onClick={handleSubmit(this.onFormSubmit.bind(this))}
								text={`Lagre vurdering for ${this.props.initialValues.StudentName}`}
								iconProps={{ iconName: 'Save' }}
								style={{ marginBottom: '5px' }}
							/>
							{this.props.singleview && (
								<PrimaryButton
									onClick={() => {
										this.setState({
											nextClicked: true
										});
									}}
									text={`Lagre vurdering og gå til neste elev`}
									iconProps={{ iconName: 'Save' }}
									type="submit"
								/>
							)}
						</div>
					</div>
				)}
			</form>
		);
	}
}

function validate(values) {
	const errors: any = {};

	// // Validate inputs from 'values'
	// if (!values.ClassID){
	//     errors.ClassID = 'Dette feltet er påkrevd.';
	// }

	// if (!values.Title){
	//     errors.Title = 'Dette feltet er påkrevd.';
	// }

	// if (!values.Start){
	//     errors.Start = 'Dette feltet er påkrevd.';
	// }

	// if (!values.End){
	//     errors.End = 'Dette feltet er påkrevd.';
	// }

	// if (values.Start.substring(11) > values.End){
	//     errors.End = 'Slutt kan ikke være før start.';
	// }

	// if (!values.Color){
	//     errors.Color = 'Dette feltet er påkrevd.';
	// }

	// If errors is an empty object, the form can be submitted
	// If errors has *any* properties, redux forms assumes the form is invalid
	return errors;
}

const mapStateToProps = (state, ownProps) => {
	console.log('008state', state);
	console.log('008props', ownProps);

	return {
		studenttask: ownProps.studenttask
	};
};

export default reduxForm({
	form: 'EvalFormRebuild',
	validate,
	enableReinitialize: true
})(
	connect(mapStateToProps, {
		saveStudentEval,
		setstatus,
		updateOutcome,
		returnSubmission,
		getTaskElement
	})(withRouter(EvalFormRebuild))
);
