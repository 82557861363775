
export class SchoolClassCheckbox {
    ClassName:string;
    ClassID:string;
    SchoolID:string;
    Selected:boolean;

    constructor(ClassName: string, ClassID: string, SchoolID: string, Selected: boolean) {
        this.ClassName = ClassName;
        this.ClassID = ClassID;
        this.SchoolID = SchoolID;
        this.Selected = Selected;
    }
}