import axios from 'axios';
import * as _ from 'lodash';
import { ROOT_URL, SP_HOST_URL } from './constants';

export const GET_LP_FAGFORNYELSEN = 'GET_LP_FAGFORNYELSEN';
export const GET_LPDETAILS_FAGFORNYELSEN = 'GET_LPDETAILS_FAGFORNYELSEN';
export const GET_KM_SET = 'GET_KM_SET';
export const GET_LPDETAILS_KUNNSKAPSLOFTET = 'GET_LPDETAILS_KUNNSKAPSLOFTET';


export function getLPDetailsKunnskapsLoftet(lp: string){
        
    const request = axios.get(`https://data.udir.no/kl06//${lp}`);

    return {
        type: GET_LPDETAILS_KUNNSKAPSLOFTET,
        payload: request
    }

}
export function getLPFagfornyelsen() {
    // Returns arrays for SchoolClasses, Courses and Schools.
    // This is requested when teacher has been authenticated when webapp starts
    const request = axios.get(`https://data.udir.no/kl06/v201906/laereplaner-lk20`,{
        headers: {
            //Authorization: localStorage.getItem('id_token')
        }
    });
    
    return {
        type: GET_LP_FAGFORNYELSEN,
        payload: request
    }
}
export function getLPDetailsFagfornyelsen(lp:string) {

    // Returns arrays for SchoolClasses, Courses and Schools.
    // This is requested when teacher has been authenticated when webapp starts
    const request = axios.get(`https://data.udir.no/kl06/v201906/laereplaner-lk20//${lp}`,{
        headers: {
            //Authorization: localStorage.getItem('id_token')
        }
    })
    // console.log(request, lp, '++++++++++++++++++++++++++++++++++++++++++++++++++++');
    return {
        type: GET_LPDETAILS_FAGFORNYELSEN,
        payload: request
    }
}
export function getKMSet(kms:string) {

    // Returns arrays for SchoolClasses, Courses and Schools.
    // This is requested when teacher has been authenticated when webapp starts
    const request = axios.get(`https://data.udir.no/kl06/v201906/kompetansemaalsett-lk20//${kms}`,{
        headers: {
            //Authorization: localStorage.getItem('id_token')
        }
    });

    return {
        type: GET_KM_SET,
        payload: request
    }
}