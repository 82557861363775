import * as React from 'react';
import {withRouter} from "react-router-dom";
import {ActionButton, Icon, FontSizes} from "office-ui-fabric-react";


export interface ISchoolClassCard{
    ClassId:string;
    Title:string;

    history:any;
}

class SchoolClassCard extends React.Component<ISchoolClassCard>{

    constructor(props){
        super(props);
    }

    render(){
        return(
            <div className="ms-Grid-col ms-sm12 ms-lg6 ms-xl4">
                <div className="school-class-card">
                    <div className="school-class-card-header">
                        <Icon iconName="Group" style={{fontSize: 17, verticalAlign: 'middle'}}/>
                        <span className="ms-font-l">{this.props.Title}</span>
                    </div>
                    <div className="school-class-card-body">
                        <div>{this.props.ClassId}</div>

                        <ActionButton
                            iconProps={{ iconName: 'Edit' }}
                            allowDisabledFocus={true}
                            checked={false}
                            onClick={()=> this.props.history.push(`/admin/elever_og_fag/klasse/${this.props.ClassId}`)}
                        >
                            Rediger
                        </ActionButton>
                    </div>
                </div>
            </div>
        )
    }
}

export default withRouter(SchoolClassCard);