import * as React from "react";

import { withRouter, Link } from "react-router-dom";
import { connect } from "react-redux";
import { Field, reduxForm, change } from "redux-form";
import {
  Dropdown,
  TextField,
  autobind,
  PrimaryButton,
  ActionButton,
  DefaultButton,
  Label,
  Toggle,
} from "office-ui-fabric-react";
import DatePicker from "react-datepicker";
import * as moment from "moment";
import ReactQuill from "react-quill"; // Typescript
import {
  saveStudentEval,
  getTaskElement,
} from "../../actions/Action_Innleveringer";
import SelectField from "../form_fields/SelectField";
import RichTextField from "../form_fields/RichTextField";
import { setstatus } from "../../actions/Action_Teacher";
import { IconNames } from "@uifabric/icons";
import {
  updateOutcome,
  returnSubmission,
  getAllAssignmentOutcomes
} from "../../actions/Action_Graph";
import SelectFieldNoStyling from "../form_fields/SelectFieldNoStyling";
import RichTextFieldNoStyling from "../form_fields/RichTextFieldNoStyling";
import { TeamSubmission } from "../../models/graph/TeamSubmission";
export interface IEvalFormTeamsProps {
  // initialValues: any;
  history: any;
  match: any;
  handleSubmit: any;
  // fromprops: any;
  showeval: boolean;
  saveStudentEval(studenttask: any);
  setstatus(statedata: any);
  updateOutcome(
    outcome: any,
    classid: string,
    assignmentid: string,
    submissionid: string,
    outcomeid: string
  );
  returnSubmission(classid: string, assignmentid: string, submissionid: string);
  singleview: boolean;
  submission: TeamSubmission;

  getTaskElement(teacherState: any);
  onClickNext();
  focusTopRef();
  location: any;
  getAllAssignmentOutcomes(
    classid: string,
    assignmentid: string,
    submission: any[]
  )
  outcome: any;
  outcomes:any[];
  task: any;
}
export interface IEvalFormTeamsState {
  Task: any;
  Uips: any[];
  ShowEval: boolean;
  ShowNext: boolean;
  nextClicked: boolean;
}
let comment = "";
class EvalFormTeams extends React.Component<
  IEvalFormTeamsProps,
  IEvalFormTeamsState
> {
  constructor(props) {
    console.log("constructor", props);
    super(props);
    this.state = {
      // Task: this.props.initialValues,
      // Uips: this.props.initialValues.Uipkms,
      // ShowEval: this.props.initialValues.ShowEval,

      Task: this.props.task,
      Uips: [],
      ShowEval: true,
      nextClicked: false,
      ShowNext: true,
    };
  }
  componentWillReceiveProps(props) {
    console.log("newprops", props);
    //this.setState({EvalText: props.GradingComment});
  }
  /****************************
          Event handlers
   ****************************/

  onFormSubmit(values) {
    console.log("777updatingsubmission", values);
    console.log("777submission", this.props.submission);
    const requestValues = this.formatFormValueDatesToDateString(values);
    if (this.props.submission) {
     
      let clone = JSON.parse(
        JSON.stringify(
          //this.props.submission.outcomz.value[2].rubricQualitySelectedLevels
          this.props.submission.rubricOutcome.rubricQualitySelectedLevels
        )
      );
      let newoutcome = [];
     
      this.state.Uips.forEach((element) => {
        console.log('777uips',element);

        clone.forEach((r) => {
            console.log('777r',r);
          if (
            r.qualityId ==
            this.props.submission.qualmaprev[element.Kmid].qualityId
          ) {
            let columnId = "";
            switch (element.Eval) {
              case 0:
                columnId = this.props.submission.levelmaprev[
                  "Ikke vurdert"
                ].levelId;
                break;
              case 1:
                columnId = this.props.submission.levelmaprev["Lav"]
                  .levelId;
                break;
              case 2:
                columnId = this.props.submission.levelmaprev["Middels"]
                  .levelId;
                break;
              case 3:
                columnId = this.props.submission.levelmaprev["Høy"]
                  .levelId;
                break;
              default:
                break;
            }
            newoutcome.push({ qualityId: r.qualityId, columnId: columnId });
          }
        });
      });
      console.log("777newoutcome", newoutcome);
      let c = {} as any;
      c["@odata.type"] = "#microsoft.graph.educationRubricOutcome";
      c.rubricQualitySelectedLevels = newoutcome;
      c.publishedRubricQualitySelectedLevel = newoutcome;
console.log('updating',c);
      this.props
        .updateOutcome(
          c,
          this.props.submission.classid,
          this.props.submission.assignmentid,
          this.props.submission.graphSubmission.id,
          this.props.submission.rubricOutcome.id
        )
        .then(() => {
          console.log('updatingdone');
          let t = JSON.parse(
            //JSON.stringify(this.props.submission.outcomz.value[0])
            JSON.stringify(this.props.submission.feedbackOutcome)
          );
          t["@odata.type"] = "#microsoft.graph.educationFeedbackOutcome";

          t.feedback = {
            text: {
              content: "",
              contentType: "text",
            },
          };
          if (t.feedback && t.feedback.text) {
            t.feedback.text.content = values.GradingComment;
          }
          console.log('updating',t);
          this.props
            .updateOutcome(
              t,
              this.props.submission.classid,
              this.props.submission.assignmentid,
              this.props.submission.graphSubmission.id,
              this.props.submission.feedbackOutcome.id
            )
            .then(() => {
              console.log("updatingdone");
              this.props
                .returnSubmission(
                  this.props.submission.classid,
                  this.props.submission.assignmentid,
                  this.props.submission.graphSubmission.id
                )
                .then(() => {
                  console.log("returned");
                  this.props.setstatus({
                    working: true,
                    text: "saving " + this.props.submission.userName,
                  });
                  console.log("saving");
                  saveStudentEval(
                    {
                      Grade: values.Grade,
                      GradeModifier: values.GradeModifier,
                      GradingComment: values.GradingComment,
                      Id: this.props.submission.userName,
                      Uipkms: this.state.Uips,
                      ShowEval: this.state.ShowEval,
                    },
                    () => {
                      this.props.setstatus({
                        working: false,
                        text: `Lagret ${this.props.submission.userName}`,
                      });
                      this.props.getTaskElement(this.props.task.Id);
                      if (this.props.singleview) {
                        if (this.state.nextClicked) {
                          this.props.focusTopRef();
                          this.props.onClickNext();
                        } else {
                          this.props.history.push(
                            "/innleveringer/" + this.props.task.Id
                          );
                        }
                      }
                    }
                  );
                });
            });
        });
    } else {
      this.props.setstatus({
        working: true,
        text: "saving " + this.props.submission.userName,
      });
      console.log("feilsaving", values);
      saveStudentEval(
        {
          Grade: values.Grade,
          GradeModifier: values.GradeModifier,
          GradingComment: values.GradingComment,
          Id: values.Id,
          Uipkms: this.state.Uips,
          ShowEval: this.state.ShowEval,
        },
        () => {
          this.props.setstatus({
            working: false,
            text: `Lagret ${this.props.submission.userName}`,
          });
          console.log("feilsaved", this.props);
          if (this.props.singleview) {
            if (this.state.nextClicked) {
              this.props.focusTopRef();
              this.props.onClickNext();
            } else {
              console.log("feilredirect");
              this.props.history.push(
                "/innleveringer/" + this.props.task.Id
              );
            }
          } else {
            console.log("feilnotredirect");
            this.props.getTaskElement(this.props.task.Id);
          }
        }
      );
    }
    //this.props.setstatus({working:true,text:'saving '+this.props.fromprops.StudentName});

    // saveStudentEval({ Grade: this.state.Grade, GradeModifier: this.state.GradeModifier, GradingComment: comment, Id: this.props.fromprops.Id, Uipkms:this.state.Uips,ShowEval:this.state.ShowEval}, () => {
    //  this.props.setstatus({working:false,text:`Lagret ${this.props.fromprops.StudentName}`});
    //   if (this.props.singleview)
    //   {
    //     this.props.history.push("/innleveringer/" + this.props.fromprops.WsTaskID);
    //   }

    // })
  }

  // handleChange(event) {
  //   const target = event.target;
  //   const value = target.value;
  //   const name = target.name;
  //   switch (name) {
  //     case "Grade":
  //       this.setState({ Grade: value });
  //       break;
  //     case "GradeModifier":
  //       this.setState({ GradeModifier: value });
  //       break;
  //     default:
  //       comment = value;
  //       break;
  //   }
  // }

  handleChangeQuill(html) {
    comment = html;
    console.log("quillupdated", comment);
  }
  onDeleteClick() {
    const { id } = this.props.match.params;

    if (isNaN(id)) {
      return;
    }

    if (confirm("Vil du virkelig slette dette timeplanelementet?")) {
      // this.props.deletePlanElement(this.props.planElements[id], () => {
      //     this.props.history.push('/timeplan');
      // });
    }
  }

  /****************************   
          Helper methods
   ****************************/

  formatFormValueDatesToDateString(values) {
    return values;
  }

  /****************************
          Render methods
   ****************************/
  mapPlanElementsToKeys() {
    const optionKeyList = [];
    optionKeyList.push({ key: "", text: "Angi karakter" });
    optionKeyList.push({ key: "1", text: "1" });
    optionKeyList.push({ key: "2", text: "2" });
    optionKeyList.push({ key: "3", text: "3" });
    optionKeyList.push({ key: "4", text: "4" });
    optionKeyList.push({ key: "5", text: "5" });
    optionKeyList.push({ key: "6", text: "6" });
    optionKeyList.push({ key: "Godkjent", text: "Godkjent" });
    optionKeyList.push({ key: "Ikke godkjent", text: "Ikke godkjent" });

    {
      /* <option>
            <option>Angi karakter</option>
                  <option value="1">1</option>
                  <option value="2">2</option>
                  <option value="3">3</option>
                  <option value="4">4</option>
                  <option value="5">5</option>
                  <option value="6">6</option>
                  <option value="Godkjent">Godkjent</option>
                  <option value="ikke godkjent">Ikke godkjent</option>
            </option> */
    }
    return optionKeyList;
  }
  mapModifierElementsToKeys() {
    const optionKeyList = [];
    optionKeyList.push({ key: "", text: "Angi styrke" });
    optionKeyList.push({ key: "Sterk", text: "Sterk" });
    optionKeyList.push({ key: "Svak", text: "Svak" });
    optionKeyList.push({ key: "+", text: "+" });
    optionKeyList.push({ key: "-", text: "-" });

    {
      /* <option value="Sterk">Sterk</option>
                  <option value="Svak">Svak</option>
                  <option value="+">+</option>
                  <option value="-">-</option> */
    }
    return optionKeyList;
  }
  render() {
    console.log("feilrender", this.props);
    const { handleSubmit } = this.props;

    return (
      <form onSubmit={handleSubmit(this.onFormSubmit.bind(this))}>
        <div className="ms-Grid-row">
          <div className="ms-Grid-col ms-sm12">
            <div className="mb-10">
              <div className="ms-Grid-row">
                <div className="ms-Grid-col ms-lg12 ms-xl2">
                  <Field
                    name="Grade"
                    label="Karakter"
                    component={SelectFieldNoStyling}
                  >
                    <option>{this.mapPlanElementsToKeys()}</option>
                  </Field>
                </div>

                <div className="ms-Grid-col ms-lg12 ms-xl2">
                  <Field
                    name="GradeModifier"
                    label=""
                    component={SelectFieldNoStyling}
                  >
                    <option>{this.mapModifierElementsToKeys()}</option>
                  </Field>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="ms-Grid-row">
          <div className="ms-Grid-col ms-sm12">
            <Field
              name="GradingComment"
              label="Egendefinerte mål"
              component={RichTextFieldNoStyling}
            />
          </div>
        </div>

        <div className="uip-row">
          <Label>
            <b>Grad av måloppnåelse</b>
          </Label>
          <div className="table-responsive">
            <table className="table table-bordered">
              <thead>
                <tr>
                  <th className="iup-table-header-first">Kompetansemål</th>
                  <th className="iup-table-header-second">Måloppnåelse</th>
                </tr>
              </thead>
              <tbody>
                {this.props &&
                  this.state &&
                  this.state.Uips &&
                  this.state.Uips.map((uip) => {
                    return (
                      <tr>
                        <td
                          className="kmtext"
                          data-lpid="@uip.Lpid"
                          data-kmid="@uip.Kmid"
                        >
                          {uip.Kmtext}
                        </td>
                        <td>
                          <div className="ms-ChoiceFieldGroup">
                            <div className="ms-ChoiceField inline-block pr-15">
                              <input
                                className="ms-ChoiceField-input"
                                value="0"
                                type="radio"
                                name={uip.Kmid}
                                checked={uip.Eval == "0"}
                                onChange={(
                                  e: React.FormEvent<HTMLInputElement>
                                ) => {
                                  let a = this.state.Uips;
                                  a.forEach((u) => {
                                    if (uip.Kmid == uip.Kmid) {
                                      uip.Eval = 0;
                                    }
                                  });
                                  this.setState({ Uips: a });
                                }}
                              />
                              <label className="ms-ChoiceField-field">
                                <span className="ms-Label" title="Ikke vurdert">
                                  Ikke vurdert
                                </span>
                              </label>
                            </div>
                            <div className="ms-ChoiceField inline-block pr-15">
                              <input
                                className="ms-ChoiceField-input"
                                value="1"
                                type="radio"
                                name={uip.Kmid}
                                checked={uip.Eval == "1"}
                                onChange={(
                                  e: React.FormEvent<HTMLInputElement>
                                ) => {
                                  let a = this.state.Uips;
                                  a.forEach((u) => {
                                    if (uip.Kmid == uip.Kmid) {
                                      uip.Eval = 1;
                                    }
                                  });
                                  this.setState({ Uips: a });
                                }}
                              />
                              <label className="ms-ChoiceField-field">
                                <span className="ms-Label" title="Lav">
                                  <i
                                    className="ms-Icon ms-Icon--TestBeaker"
                                    aria-hidden="true"
                                  ></i>
                                </span>
                              </label>
                            </div>
                            <div className="ms-ChoiceField inline-block pr-15">
                              <input
                                className="ms-ChoiceField-input"
                                value="2"
                                type="radio"
                                name={uip.Kmid}
                                checked={uip.Eval == "2"}
                                onChange={(
                                  e: React.FormEvent<HTMLInputElement>
                                ) => {
                                  let a = this.state.Uips;
                                  a.forEach((u) => {
                                    if (uip.Kmid == uip.Kmid) {
                                      uip.Eval = 2;
                                    }
                                  });
                                  this.setState({ Uips: a });
                                }}
                              />
                              <label className="ms-ChoiceField-field">
                                <span className="ms-Label" title="Middels">
                                  <i
                                    className="ms-Icon ms-Icon--TestBeakerSolid"
                                    aria-hidden="true"
                                  ></i>
                                </span>
                              </label>
                            </div>
                            <div className="ms-ChoiceField inline-block pr-15">
                              <input
                                className="ms-ChoiceField-input"
                                value="3"
                                type="radio"
                                name={uip.Kmid}
                                checked={uip.Eval == "3"}
                                onChange={(
                                  e: React.FormEvent<HTMLInputElement>
                                ) => {
                                  let a = this.state.Uips;
                                  a.forEach((u) => {
                                    if (uip.Kmid == uip.Kmid) {
                                      uip.Eval = 3;
                                    }
                                  });
                                  this.setState({ Uips: a });
                                }}
                              />
                              <label className="ms-ChoiceField-field">
                                <span className="ms-Label" title="Høy">
                                  <i
                                    className="ms-Icon ms-Icon--TestCase"
                                    aria-hidden="true"
                                  ></i>
                                </span>
                              </label>
                            </div>
                          </div>
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>
          </div>
        </div>

        {this.props && (
          <div className="ms-Grid-row">
            <div className="ms-Grid-col ms-sm-12 float-left">
              {this.props.singleview && (
                <Toggle
                  defaultChecked={this.state.ShowEval}
                  onText="Publiser til elev"
                  offText="Ikke publiser til elev"
                  onChanged={(newVal) => {
                    this.setState({ ShowEval: newVal });
                  }}
                  style={{ marginBottom: "5px" }}
                />
              )}
              <PrimaryButton
                onClick={handleSubmit(this.onFormSubmit.bind(this))}
                text={`Lagre vurdering for ${this.props.submission.student.displayName}`}
                iconProps={{ iconName: "Save" }}
                style={{ marginBottom: "5px" }}
              />
              {/* {this.props.singleview &&(
                <PrimaryButton
                  onClick={() =>{
                    this.setState({
                      nextClicked: true
                    })
                  }}
                  text={`Lagre vurdering og gå til neste elev`}
                  iconProps={{ iconName: "Save" }}
                  type="submit"
                />
              )} */}
            </div>
          </div>
        )}
      </form>
    );
  }
}

function validate(values) {
  const errors: any = {};

  // // Validate inputs from 'values'
  // if (!values.ClassID){
  //     errors.ClassID = 'Dette feltet er påkrevd.';
  // }

  // if (!values.Title){
  //     errors.Title = 'Dette feltet er påkrevd.';
  // }

  // if (!values.Start){
  //     errors.Start = 'Dette feltet er påkrevd.';
  // }

  // if (!values.End){
  //     errors.End = 'Dette feltet er påkrevd.';
  // }

  // if (values.Start.substring(11) > values.End){
  //     errors.End = 'Slutt kan ikke være før start.';
  // }

  // if (!values.Color){
  //     errors.Color = 'Dette feltet er påkrevd.';
  // }

  // If errors is an empty object, the form can be submitted
  // If errors has *any* properties, redux forms assumes the form is invalid
  return errors;
}

const mapStateToProps = (state, ownProps) => {
  console.log("feilevalformloaded", state);
  console.log("feilownProps", ownProps);
  let outc = null;
  // if (state.GraphReducer && state.GraphReducer.currentassignmentalloutcomes) {
  //   state.GraphReducer.currentassignmentalloutcomes.forEach((element) => {
  //     if (ownProps.submission.id == element.submissionid) {
  //       ownProps.submission.outcomz = element;
  //     }
  //   });
  // }
  console.log("submission", ownProps.submission);
  return {
    studenttask: ownProps.studenttask,
    outcomes:state.GraphReducer.currentassignmentalloutcomes,
  };
};

export default reduxForm({
  form: "EvalFormTeams",
  validate,
  enableReinitialize: true,
})(
  connect(mapStateToProps, {
    saveStudentEval,
    setstatus,
    updateOutcome,
    returnSubmission,
    getTaskElement,
    getAllAssignmentOutcomes
  })(withRouter(EvalFormTeams))
);
