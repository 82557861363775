import * as React from "react";
import { connect } from "react-redux";
import { ApplicationInsights } from '@microsoft/applicationinsights-web'
import { store } from 'react-notifications-component';
import { Dialog, DialogType, DialogFooter } from 'office-ui-fabric-react/lib/Dialog';
import { PrimaryButton, DefaultButton } from "office-ui-fabric-react";
import * as sourceStackTrace from 'sourcemapped-stacktrace';
export interface IErrorBoundaryState {
  error: any;
  info: any;
  showDialog: boolean;
}
export interface IErrorBoundaryProps {
    state: any;
  }
  const ai = new ApplicationInsights({
    config: {
        //instrumentationKey: '6d6312d0-b027-4a25-a4fb-93baee98499a',
        instrumentationKey: '64ccbdc4-3541-4e0a-8da8-ec149d28ebab'
    }
})
ai.loadAppInsights()
class ErrorBoundary extends React.Component<
IErrorBoundaryProps,
  IErrorBoundaryState
> {
  constructor(props) {
    super(props);
    // Add some default error states
    this.state = {
      error: false,
      info: null,
      showDialog: false
    };
  }
  
  toggleHideDialog(){
    this.setState({
      showDialog: !this.state.showDialog
    });
    window.location.reload();
  }

  sendErrorData(){
    //this.toggleHideDialog();
    this.setState({
      showDialog: !this.state.showDialog
    });
    store.addNotification({
      title: "Zokrates sier!",
      message: 'Feilen er logget',
      type: "success",
      insert: "top",
      container: "top-right",
      animationIn: ["animated", "fadeIn"],
      animationOut: ["animated", "fadeOut"],
      dismiss: {
        duration: 5000,
        onScreen: true,
      }
    });
    sourceStackTrace.mapStackTrace(this.state.error.stack, (stackTrace: string[]) => {
      console.log('crash',stackTrace);
      ai.trackEvent({ name: 'Crash', properties: { info:this.state.info,error:stackTrace,user:this.props.state.teacher.user.login,state:this.props.state,token: localStorage.getItem('id_token')} })
      // Do your thing here
  });
    // Something happened to one of my children.
    // Add error to state
    ai.trackEvent({ name: 'Crash', properties: { info:this.state.info,error:this.state.error.stack,user:this.props.state.teacher.login,state:this.props.state,token: localStorage.getItem('id_token')} })
    window.location.reload();
  }

  displayUserAgreement(){
    const modalPropsStyles = { main: { maxWidth: 850}};

    const dialogContentProps = {
      type: DialogType.largeHeader,
      title: 'Uventet Feil',
    };

    return (<div>{this.state.showDialog && <Dialog
      hidden={!this.state.showDialog}
      onDismiss={this.toggleHideDialog}
      dialogContentProps={dialogContentProps}
      modalProps={{styles: modalPropsStyles, isBlocking: true}}
    >
      
      <h5 style={{lineHeight: "170%"}}>
        Har du lyst til å sende inn feildataen til Zokrates?
        Dette hjelper Zokrates teamet med å forbedre nettsiden. 
      </h5>
      <DialogFooter>
        <PrimaryButton onClick={() => this.sendErrorData()} text="Send" />
        <DefaultButton onClick={() => this.toggleHideDialog()} text="Ikke send" />
      </DialogFooter>
    </Dialog>}
    </div>)
  }


  componentDidCatch(error, info) {
    console.log('errourboundary',error);
    console.log('errourboundary',info);
    window.location.href = "/#"
    this.setState({
      showDialog: true,
      error: error,
      info: info
    });
  }

  render() {
    // if (this.state.info) {
    //   return <div>
    //       {/* {this.state.info.componentStack} */}

    //       Noe gikk feil, feilen er logget
    //       </div>
    // }
    // No errors were thrown. As you were.
    //return this.props.children;
    return <React.Fragment>
      {this.displayUserAgreement()}
      {this.props.children}
    </React.Fragment>
  }
}
function mapStateToProps(state) {
    return {
     state
    };
  }
  
  export default connect(mapStateToProps, {
    
  })(ErrorBoundary);
