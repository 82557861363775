import { PrimaryButton } from 'office-ui-fabric-react';
import * as React from 'react';
import { connect } from "react-redux";
import {
    submitTask,
    checkStatusTaskTeacher,
    initStatusTask,
    addDeliveryFileTeacher
  } from "../../actions/Action_Innleveringer";
import OneDrivePicker from '../shared/OneDrivePicker';
import ProgressList from '../student/innlevering/ProgressList';
export interface ISubmitForStudentProps {
    studenttask:any;
    addDeliveryFileTeacher(file: any);
    initStatusTask(files: any[]);
    checkStatusTaskTeacher(files: any[]);
    submitTask(task: any);
    deliveryfinished();
    addfinished(ret:any);
    history:any;
}
export interface ISubmitForStudentState {
    delivering:boolean;
}
class SubmitForStudent extends React.Component<ISubmitForStudentProps,ISubmitForStudentState> {
    constructor(props) {
        super(props);
        this.state = {
        
          delivering: false,
        };
      }
  public render() {
    let emptylist = [];
    if (this.props.studenttask) {
      emptylist = JSON.parse(
        JSON.stringify(this.props.studenttask.DeliveryFiles)
      );
      emptylist.forEach((element) => {
        element.UrkundOptOutMessage = null;
      });
    }
    return (
      <div>
        <div className="ms-Grid-col ms-sm12">
          <OneDrivePicker
            onClose={(selected) => {
              const draft =
                selected.OriginalDocumentID +
                "|" +
                selected.FileName +
                "|" +
                selected.GraphParentDriveId +
                "|" +
                selected.GraphItemId;
              this.props
                .addDeliveryFileTeacher({
                  Draft: draft,
                  StudentTaskID: this.props.studenttask.Id,
                })
                .then((ret) => {
                  console.log("added file", ret);
                 this.props.addfinished(ret);
                
                });
            }}
          />
          </div>
          <div className="ms-Grid-col ms-sm12">
           <PrimaryButton
            onClick={() => {
              console.log(
                "trigger baby",
                this.props.studenttask.DeliveryFiles
              );
              this.setState({ delivering: true }, () => {
                this.props.initStatusTask(emptylist);
                this.props
                  .submitTask(this.props.studenttask)
                  .then((resp) => {
                    console.log("resp submitTask", resp);
                    this.props.checkStatusTaskTeacher(resp.payload.data);
                  });
              });
            }}
          >
            Send inn for elev
          </PrimaryButton>
          {this.state.delivering && (
            <ProgressList
              finished={() => {
                console.log("gotfinishmessage");
                this.setState({
                  delivering: false
                })
               this.props.deliveryfinished();
              }}
              currenttask={this.props.studenttask}
            ></ProgressList>
          )}
          </div>
      </div>
    );
  }
}
function mapStateToProps(state) {
   
    return {
     
    };
  }
export default connect(mapStateToProps, { checkStatusTaskTeacher, initStatusTask,submitTask,addDeliveryFileTeacher })(
    SubmitForStudent
  );
