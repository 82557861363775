import * as React from 'react';
import * as moment from 'moment';
import * as _ from 'lodash';
import { connect } from 'react-redux';
import { getNewsFiles } from "../../../actions/Action_Student";
moment.locale('nb');
import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from 'react-html-parser';
import { ActionButton, Dialog, DialogFooter, Shimmer } from 'office-ui-fabric-react';
import ImmersiveReader from '../../shared/ImmersiveReader';
import '../../QuillStyles.scss';
export interface IStudentNewsElementViewProps {
    newselement: any;
    getNewsFiles(news: any);
    newsfiles: any[];
}
export interface IStudentNewsElementViewState {
    showdialog: boolean;
    myhtml: any;
    isMessageOverflown: boolean;
}


class StudentNewsElementView extends React.Component<IStudentNewsElementViewProps, IStudentNewsElementViewState>{
    private msgRef: React.RefObject<HTMLDivElement>;  
    constructor(props) {
        super(props);
        this.state = { showdialog: false,myhtml:null, isMessageOverflown: false };
        this.msgRef = React.createRef();
    }

    componentDidMount(){
      const msgOverflow = this.msgRef.current.scrollHeight > this.msgRef.current.clientHeight || this.msgRef.current.scrollWidth > this.msgRef.current.clientWidth;
      this.setState({isMessageOverflown: msgOverflow});
      window.addEventListener('resize', this.handleResize);
    }
    handleResize = e =>{
      const msgOverflow = this.msgRef.current.scrollHeight > this.msgRef.current.clientHeight || this.msgRef.current.scrollWidth > this.msgRef.current.clientWidth;
      this.setState({isMessageOverflown: msgOverflow});
    }
    componentWillUnmount() {
      window.removeEventListener('resize', this.handleResize);
    }  
    render() {

        return (
            <li className="list-group-item"   ref={input => {
              if (!this.state.myhtml) {
                this.setState({ myhtml: input });
              }
            }}>
         
          
                <div className="plan-kort-header">
                <h3>
                  <i className="ms-Icon ms-Icon--Message" aria-hidden="true"></i>
                  {this.props.newselement.Title}
                </h3>
                <div className="plan-kort-header--flex-item">
                  {this.state.myhtml && (
                  <ImmersiveReader htmlcontent={this.state.myhtml}></ImmersiveReader>
                  )}
              </div>
                </div>
                <dd>
                    <br />
                    <div
                      ref={this.msgRef}
                      style={{maxHeight: 300, overflow: 'hidden', backgroundColor: "transparent"}}
                      className="remove-para-margin ql-editor"
                      dangerouslySetInnerHTML={{
                        __html: this.props.newselement.TextRaw
                      }}
                    />
                    {this.state.isMessageOverflown && <p>...</p>}
                    <br/>
                    {(this.state.isMessageOverflown || (this.props.newselement.NewsDocuments  && this.props.newselement.NewsDocuments.length > 0)) && <ActionButton
                      iconProps={{iconName: "RedEye"}}
                      text={(this.props.newselement.NewsDocuments  && this.props.newselement.NewsDocuments.length > 0) ? "Vis hele meldingen med vedlegg" : "Vis hele meldingen"}
                      onClick={() => {
                        this.setState({ showdialog: true });
                        this.props.getNewsFiles(this.props.newselement);
                      }}
                    /> }
                    {/* {this.props.newselement.NewsDocuments &&
              this.props.newselement.NewsDocuments.length > 0 && (
                <span>
                  <ActionButton
                    onClick={() => {
                      this.props
                        .getNewsFiles(this.props.newselement)
                        .then(() => {
                          this.setState({ showdialog: true });
                        });
                    }}
                    iconProps={{ iconName: "FileSymlink" }}
                    text="Filer"
                  />
                </span>
              )}
                <ActionButton
                    onClick={() => {
                     
                          this.setState({ showdialog: true });
                       
                    }}
                    iconProps={{ iconName: "RedEye" }}
                    text="Les mer"
                  /> */}
                    {/* {this.props.newselement.TextRaw} */}
                </dd>
          
            <Dialog
          hidden={!this.state.showdialog}
          onDismiss={() => {
            this.setState({ showdialog: false });
          }}
          styles={{
            main: [
              {
                selectors: {
                  ["@media (min-width: 480px)"]: {
                    maxWidth: "600px",
                    minWidth: "480px"
                  }
                }
              }
            ]
          }}
          modalProps={{
            isBlocking: true,
            containerClassName: "ms-dialogMainOverride"
          }}
        > <span className="remove-para-margin ql-editor" style={{backgroundColor: "transparent"}} dangerouslySetInnerHTML={{__html:this.props.newselement.TextRaw}} ></span>
          
          {this.props.newselement.NewsDocuments && this.props.newselement.NewsDocuments.length > 0 && <ul>
            {this.props.newselement.NewsDocuments.split(";").map(d => {
              let splitNewsDocument = d.split("|")

              if (splitNewsDocument && splitNewsDocument.length > 0){
                let objectId = splitNewsDocument[0];

                let newsfile = !!this.props.newsfiles ? this.props.newsfiles.find(nf => nf.OriginalDocumentID == objectId) : undefined;
                return <li><Shimmer isDataLoaded={!!newsfile} style={{margin: "8px 0"}}>
                  {newsfile && 
                    <a href={newsfile.FileUrl} target="_blank">
                        {newsfile.FileName}
                    </a>
                  }
                  </Shimmer></li>
              }
            })}
            </ul>}
          <DialogFooter>
            <ActionButton
              onClick={() => {
                this.setState({ showdialog: false });
              }}
              text="Lukk"
            />
          </DialogFooter>
        </Dialog>
         
            
            
        </li>
              
        )
    }

}
function mapStateToProps(state) {

    return {
        newsfiles: state.StudentReducer.NewsFiles
      };
}
export default connect(mapStateToProps, {getNewsFiles})(StudentNewsElementView);