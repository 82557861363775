import * as React from 'react';
import {Spinner, SpinnerSize} from "office-ui-fabric-react";


export interface ISpinLoaderProps{
    text:string;
    hide?:boolean;
}

export default class SpinLoader extends React.Component<ISpinLoaderProps>{

    constructor(props){
        super(props);
    }

    render(){

        if (this.props.hide){
            return null;
        }

        return(
            <div className="align-center" style={{padding: 15}}>
                <Spinner size={SpinnerSize.large} label={this.props.text} ariaLive="assertive" />
            </div>
        )
    }
}