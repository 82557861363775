import * as React from 'react';
import * as moment from 'moment';
import { connect } from 'react-redux';
moment.locale('nb');
import { processNodes, convertNodeToElement, htmlparser2 } from 'react-html-parser';
import StudentPlanElementView from "./StudentPlanElementView";
import StudentTaskElementView from "./StudentTaskElementView";
import StudentNewsElementView from "./StudentNewsElementView";
import { Course } from '../../../models/Course';

export interface IStudentCourseElementViewViewProps {
    teacherassignment: Course;
    history:any;
}
export interface IStudentCourseElementViewViewState {

}


class StudentCourseElementView extends React.Component<IStudentCourseElementViewViewProps, IStudentCourseElementViewViewState>{
    constructor(props) {
        super(props);
    }
    render() {
        return (
            <div className="item courseitem">
                <div className="panel panel-default" id={this.props.teacherassignment.CourseCategory}>
                    <div className="panel-heading">
                        <h2>{this.props.teacherassignment.Title}</h2>

                    </div>
                    <div className="panel-body">
                        <ul className="list-group">
                            {this.props.teacherassignment.News.map((news) => {
                                return (
                                    <StudentNewsElementView newselement={news}/>
                                )
                            })
                            }
                            {this.props.teacherassignment.StudentTexts.map((text) => {
                                return (
                                    <StudentPlanElementView planelement={text} course={this.props.teacherassignment}/>
                                )
                            }
                            )}
                            {this.props.teacherassignment.StudentTasks.map((task) => {
                                return (
                                    <StudentTaskElementView history={this.props.history} taskelement={task} course={this.props.teacherassignment} />
                                )
                            })}


                        </ul>
                    </div>
                </div>

            </div>

        )
    }

}
function mapStateToProps(state) {

    return {}
}
export default connect(mapStateToProps, {})(StudentCourseElementView);