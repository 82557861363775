import axios from 'axios';
import * as _ from 'lodash';
import { SP_HOST_URL, ROOT_URL } from './constants';


export const GET_CURRENT_TASKS = 'GET_CURRENT_TASKS';
export const GET_TASKS_FOR_GRADING = "GET_TASKS_FOR_GRADING";
export const GET_LAST_TASKS = 'GET_LAST_TASKS';
export const GET_SAVED_TASKS = 'GET_SAVED_TASKS';
export const GET_STUDENT_TASK = 'GET_STUDENT_TASK';
export const GET_TASK = 'GET_TASK';
export const GET_STUDENT_FILES = 'GET_STUDENT_FILES';
export const SAVE_STUDENT_EVAL = 'SAVE_STUDENT_EVAL';
export const SAVE_STUDENT_EVALVIS = 'SAVE_STUDENT_EVALVIS';
export const SAVE_STUDENT_DEADLINE = 'SAVE_STUDENT_DEADLINE';
export const PLAGCHECK_FILE = 'PLAGCHECK_FILE';
export const PLAGCHECK_TASK = 'PLAGCHECK_TASK';
export const TEACHERADD_STUDENT_DELIVERYFILE = 'TEACHERADD_STUDENT_DELIVERYFILE';
export const INIT_SUBMISSIONTEACHER_STATUS = 'INIT_SUBMISSIONTEACHER_STATUS';
export const CHECK_SUBMISSIONTEACHER_STATUS = 'CHECK_SUBMISSIONTEACHER_STATUS';
export const TEACHERSUBMIT_STUDENT_TASK = 'TEACHERSUBMIT_STUDENT_TASK';
export const CONNECT_TO_TEAMS = 'CONNECT_TO_TEAMS';
export const DELETE_STUDENT_TASK = 'DELETE_STUDENT_TASK';
export const DOWNLOAD_STUDENT_FILES = 'DOWNLOAD_STUDENT_FILES';
export const REMOVE_STUDENT_DELIVERYFILE_TEACHER = "REMOVE_STUDENT_DELIVERYFILE_TEACHER";

export function saveStudentDeadline(studenttask:any, callback:any) {
       
        const request = axios.post(`${ROOT_URL}/SaveStudentDeadline${SP_HOST_URL}`, studenttask, {
            headers: {
                Authorization: localStorage.getItem('id_token')
            }
        }).then(() => callback());
    
        return {
            type: SAVE_STUDENT_DEADLINE,
            payload: request
        }
    }
export function saveStudentEval(studenttask:any, callback:any) {
       
        const request = axios.post(`${ROOT_URL}/SaveStudentEval${SP_HOST_URL}`, studenttask, {
            headers: {
                Authorization: localStorage.getItem('id_token')
            }
        }).then((a) => callback(a));
    
        return {
            type: SAVE_STUDENT_EVAL,
            payload: request
        }
    }
    export function saveStudentEvalVis(studenttask:any[], callback:any) {
       
        const request = axios.post(`${ROOT_URL}/SaveStudentEvalVisibility${SP_HOST_URL}`, studenttask, {
            headers: {
                Authorization: localStorage.getItem('id_token')
            }
        });
        // }).then(() => callback());
    
        return {
            type: SAVE_STUDENT_EVALVIS,
            payload: request
        }
    }
export function getStudentFiles(studenttask:any) {
       
        const request = axios.post(`${ROOT_URL}/GetStudentFiles${SP_HOST_URL}`, studenttask, {
            headers: {
                Authorization: localStorage.getItem('id_token')
            }
        });
    
        return {
            type: GET_STUDENT_FILES,
            payload: request
        }
    }
    export function downloadStudentFiles(wstask:any) {
       
        const request = axios.post(`${ROOT_URL}/downloadStudentFiles${SP_HOST_URL}`, wstask, {
            headers: {
                Authorization: localStorage.getItem('id_token')
            }
        });
    
        return {
            type: DOWNLOAD_STUDENT_FILES,
            payload: request
        }
    }
export async function getCurrentTaskElements(statedata: any, callback?, errorCallback?) {
    try {
        const data:FormData = new FormData();
        data.set('classes', JSON.stringify(_.values(statedata.subscribedSchoolClasses)));
        data.set('schools', JSON.stringify(_.values(statedata.schools)));
        data.set('courses', JSON.stringify(_.values(statedata.subscribedCourses)));

        const request = await axios.post(`${ROOT_URL}/GetTeacherCurrentTasksTeams${SP_HOST_URL}`, data, {
            headers: {
                Authorization: localStorage.getItem('id_token')
            }
        });
        
        if (callback) {
            callback();
        }
        
        return {
            type: GET_CURRENT_TASKS,
            payload: request
        }
    }
    catch (error) {
        if (errorCallback) {
            errorCallback();
        }
    }
}

export async function getTasksReadyForGrading(teacherstate: any, callback?, errorCallback?) {
    try {
        const data: FormData = new FormData();
        data.set("classes", JSON.stringify(_.values(teacherstate.subscribedSchoolClasses)));
        data.set("schools", JSON.stringify(_.values(teacherstate.schools)));
        data.set("courses", JSON.stringify(_.values(teacherstate.subscribedCourses)));
    
        const request = await axios.post(`${ROOT_URL}/GetTeacherTasksReadyForGrading${SP_HOST_URL}`, data, {
            headers: {
                Authorization: localStorage.getItem('id_token')
            }
        });

        if (callback) {
            callback();
        }
    
        return {
            type: GET_TASKS_FOR_GRADING,
            payload: request
        }
    }
    catch (error) {
        if (errorCallback) {
            errorCallback();
        }
    }
}

export function getCurrentCourseTaskElements(course:any) {
    
    const request = axios.post(`${ROOT_URL}/GetCourseCurrentTasks${SP_HOST_URL}`, course, {
        headers: {
            Authorization: localStorage.getItem('id_token')
        }
    });

    return {
        type: GET_CURRENT_TASKS,
        payload: request
    }
}
export async function getLastTaskElements(statedata:any, callback?, errorCallback?) {
    try {
        const data:FormData = new FormData();
        data.set('classes', JSON.stringify(_.values(statedata.subscribedSchoolClasses)));
        data.set('schools', JSON.stringify(_.values(statedata.schools)));
        data.set('courses', JSON.stringify(_.values(statedata.subscribedCourses)));
        
        // const newState = {
        //     schoolClasses: _.values(statedata.schoolClasses),
        //     courses: _.values(statedata.courses),
        //     schools: _.values(statedata.schools),
        // }
        const request = await axios.post(`${ROOT_URL}/GetTeacherLastTasks${SP_HOST_URL}`, data, {
            headers: {
                Authorization: localStorage.getItem('id_token')
            }
        });
    
        if (callback) {
            callback();
        }
    
        return {
            type: GET_LAST_TASKS,
            payload: request
        }
    }
    catch (error) {
        if (errorCallback) {
            errorCallback();
        }    
    }
}
export function getLastCourseTaskElements(course:any) {
   
    const request = axios.post(`${ROOT_URL}/GetTeacherCourseLastTasks${SP_HOST_URL}`, course, {
        headers: {
            Authorization: localStorage.getItem('id_token')
        }
    });

    return {
        type: GET_LAST_TASKS,
        payload: request
    }
}
export async function getSavedTaskElements(statedata:any, callback?, errorCallback?) {
    try {
        const data:FormData = new FormData();
        data.set('classes', JSON.stringify(_.values(statedata.subscribedSchoolClasses)));
        data.set('schools', JSON.stringify(_.values(statedata.schools)));
        data.set('courses', JSON.stringify(_.values(statedata.subscribedCourses)));
        
        // const newState = {
        //     schoolClasses: _.values(statedata.schoolClasses),
        //     courses: _.values(statedata.courses),
        //     schools: _.values(statedata.schools),
        // }
        const request = await axios.post(`${ROOT_URL}/GetTeacherSavedTasks${SP_HOST_URL}`, data, {
            headers: {
                Authorization: localStorage.getItem('id_token')
            }
        });
        
        if(callback) {
            callback();
        }

        return {
            type: GET_SAVED_TASKS,
            payload: request
        }
    }
    catch (error) {
        if (errorCallback) {
            errorCallback();
        }
    }
}
export function getSavedCourseTaskElements(course:any) {
       
    const request = axios.post(`${ROOT_URL}/GetTeacherCourseSavedTasks${SP_HOST_URL}`, course, {
        headers: {
            Authorization: localStorage.getItem('id_token')
        }
    });
    
    return {
        type: GET_SAVED_TASKS,
        payload: request
    }
}
export function getStudentTaskElement(Id:number) {
    const data:FormData = new FormData();
        const request = axios.post(`${ROOT_URL}/GetStudentTask/${Id}${SP_HOST_URL}`,data, {
            headers: {
                Authorization: localStorage.getItem('id_token')
            }
        });
        
    return {
        type: GET_STUDENT_TASK,
        payload: request
    }
}
export function getTaskElement(Id:number) {
    const data:FormData = new FormData();
        const request = axios.post(`${ROOT_URL}/GetTask/${Id}${SP_HOST_URL}`,data, {
            headers: {
                Authorization: localStorage.getItem('id_token')
            }
        });
            
    return {
        type: GET_TASK,
        payload: request
    }
}
export function plagcheckFile(data:any) {

    const request = axios.post(`${ROOT_URL}/PlagCheck/${SP_HOST_URL}`,data,{
        headers: {
            Authorization: localStorage.getItem('id_token')
        }
    });

    return {
        type: PLAGCHECK_FILE,
        payload: request
    }
}
export function plagcheckTask(data:any) {

    const request = axios.post(`${ROOT_URL}/PlagCheckTask/${SP_HOST_URL}`,data,{
        headers: {
            Authorization: localStorage.getItem('id_token')
        }
    });

    return {
        type: PLAGCHECK_TASK,
        payload: request
    }
}
export function removeDeliveryFileTeacher(file:any) {
    const request = axios.post(`${ROOT_URL}/removeDeliveryFileTeacher${SP_HOST_URL}`, file, {
        headers: {
            Authorization: localStorage.getItem('id_token')
        }
    });

    return {
        type: REMOVE_STUDENT_DELIVERYFILE_TEACHER,
        payload: request
    }
}


export function addDeliveryFileTeacher(file:any) {
    const request = axios.post(`${ROOT_URL}/AddDeliveryFileTeacher${SP_HOST_URL}`, file, {
        headers: {
            Authorization: localStorage.getItem('id_token')
        }
    });
            
    return {
        type: TEACHERADD_STUDENT_DELIVERYFILE,
        payload: request
    }
}
export function initStatusTask(files:any[]) {



    return {
        type: INIT_SUBMISSIONTEACHER_STATUS,
        payload: files
    }
}
            
export function checkStatusTaskTeacher(files:any[]) {
            
    const request = axios.post(`${ROOT_URL}/CheckSubmissionTeacher${SP_HOST_URL}`, files, {
        headers: {
            Authorization: localStorage.getItem('id_token')
        }
    });
            
    return {
        type: CHECK_SUBMISSIONTEACHER_STATUS,
        payload: request
    }
}
export function submitTask(task:any) {

    const request = axios.post(`${ROOT_URL}/SubmitStudentTaskTeacher${SP_HOST_URL}`, task, {
        headers: {
            Authorization: localStorage.getItem('id_token')
        }
    });
            
    return {
        type: TEACHERSUBMIT_STUDENT_TASK,
        payload: request
    }
}
export function connectTaskToTeams(task:any) {
       
    const request = axios.post(`${ROOT_URL}/ConnectToTeams${SP_HOST_URL}`, task, {
        headers: {
            Authorization: localStorage.getItem('id_token')
        }
    });
    return {
        type: CONNECT_TO_TEAMS,
        payload: request
    }
}
export function deleteStudentTask(id: any) {
    const request = axios
      .post(`${ROOT_URL}/DeleteStudentTask/${id}/${SP_HOST_URL}`, null, {
        headers: {
          Authorization: localStorage.getItem("id_token"),
        },
      })
    //   .then(() => callback());
              
    // Returns id of deleted post
    return {
      type: DELETE_STUDENT_TASK,
      payload: id,
    };
}
