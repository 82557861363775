import * as React from "react";
import * as BigCalendar from "react-big-calendar";
import * as moment from "moment";

import { getUkeplanElements, setUkeplanSelectedDate, setCompactCardViewToggle } from "../../actions/Action_Ukeplan";
import * as _ from "lodash";

import HeaderView from "../view_containers/HeaderView";
import { connect } from "react-redux";
import {
  CommandBarButton,
  ActionButton,
  DefaultButton,
  Dialog,
  DialogFooter,
  DialogType,
  Dropdown,
  PrimaryButton,
  Checkbox
} from "office-ui-fabric-react";

import SidebarActionButtonList from "../sidebar/SidebarActionButtonList";
import "./Ukeplan.scss";
import CourseDayView from "./CourseDayView";
import Masonry from "react-masonry-component";
import CourseElementView from "./CourseElementView";
import { getQueryParam } from "../../utils/HelperFunctions";
import NewsElementView from "./NewsElementView";
import PlanElementView from "./PlanElementView";
import TaskElementView from "./TaskElementView";

moment.locale("nb");

BigCalendar.momentLocalizer(moment);

export interface IUkeplanViewProps {
  history: any;
  teacherstate: any;
  ukeplanelements: any;
  master: any;
  planelements: any;
  initialDate: Date;
  compactCardView: boolean;

  getUkeplanElements(teacherState: any, week: number, year: number);
  setUkeplanSelectedDate(date: Date);
  setCompactCardViewToggle(compactCardView: boolean);
}

export interface IUkeplanViewState {
  selectedWeek: number;
  selectedYear: number;
  courseview: boolean;
  dayview: boolean;
  // Dropdown
  dropdownSelectedWeek: any;
  dropdownSelectedYear: any;
  dropdownSelectedClass: any;
  dropdownWeekOptions: any;
  dropdownYearOptions: any;

  showDateSelectModal: boolean;
  selectedcourses: any;
  showall: boolean;
  thedate: Date;
}

class UkeplanView extends React.Component<
  IUkeplanViewProps,
  IUkeplanViewState
> {
  /****************************
     Lifecycle hooks
     ****************************/
  componentWillReceiveProps(props) {
    if (props.ukeplanelements && props.ukeplanelements.AllTas) {
      let f = {};

      props.ukeplanelements.AllTas.forEach(element => {
        f[element.Course.CourseID] = false;
      });
      // this.setState({selectedcourses: f, showall: true });
    }
  }

  componentDidMount() {
    let momentDate = this.props.initialDate ? moment(this.props.initialDate) : moment(new Date());
    let week = momentDate.week();
    let year = momentDate.year();

    if (week === 1) {
      let previousWeekDate = moment(momentDate).add(-1, "weeks");
      if (previousWeekDate.year() == momentDate.year()) {
        year++;
      }
    }

    

    const weekOptions = [];
    const yearOptions = [];
    for (let i = 1; i <= 52; i++) {
      weekOptions.push({ key: i, text: i.toString() });
    }
    for (let i = -1; i <= 1; i++) {
      yearOptions.push({
        key: moment(new Date()).year() + i,
        text: (moment(new Date()).year() + i).toString()
      });
    }
    let f = {};

    this.setState(
      {
        selectedWeek: week,
        selectedYear: year,
        courseview: true,
        dayview: false,
        dropdownSelectedWeek: week,
        dropdownSelectedYear: year,
        dropdownWeekOptions: weekOptions,
        dropdownYearOptions: yearOptions,
        selectedcourses: f,
        showall: true,
        // Modals
        showDateSelectModal: false,
        thedate:  momentDate.toDate()
      },
      () => {
        this.props.getUkeplanElements(this.props.teacherstate, week, year);
      }
    );
  }

  changeweek(newDate: moment.Moment) {

    let week = newDate.week();
    let year = newDate.year();

    if (week === 1) {
      let previousWeekDate = moment(newDate).add(-1, "weeks");
      if (previousWeekDate.year() == newDate.year()) {
        year++;
      }
    }

    if (this.props.ukeplanelements && this.props.ukeplanelements.AllTas) {
      let f = {};

      this.props.ukeplanelements.AllTas.forEach(element => {
        f[element.Course.CourseID] = false;
      });

      this.setState(
        {
          selectedWeek: week,
          selectedYear: year,
          thedate: newDate.toDate(),
          selectedcourses: f, 
          showall: true
        },
        () => {
          this.props.setUkeplanSelectedDate(newDate.toDate());
          this.props.getUkeplanElements(this.props.teacherstate, week, year);
        }
      );
    }
    else {
      this.setState(
        {
          selectedWeek: week,
          selectedYear: year,
          thedate: newDate.toDate()
        },
        () => {
          this.props.setUkeplanSelectedDate(newDate.toDate());
          this.props.getUkeplanElements(this.props.teacherstate, week, year);
        }
      );
    }
  }

  onCalendarDateSet() {
    const newDate = moment(new Date())
      .year(this.state.dropdownSelectedYear)
      .week(this.state.dropdownSelectedWeek);

    this.setState(
      {
        showDateSelectModal: false
      },
      () => {
        this.changeweek(newDate);
      }
    );
  }

  filteredTeacherAssignments(teacherAssignment: any) {
    let taToReturn = {
        Course: teacherAssignment.Course,
        Student: teacherAssignment.Student,
        News: teacherAssignment.News,//this.filterByYear(teacherAssignment.News),
        Tasks: this.filterByYear(teacherAssignment.Tasks),
        TextElements: this.filterByYear(teacherAssignment.TextElements),
        Empty: false
    };

    if (taToReturn.News.length == 0 && taToReturn.Tasks.length == 0 && taToReturn.TextElements.length == 0) {
        taToReturn.Empty = true;
    }

    return taToReturn;
  }

  coursesToShow = (): any[] => {
    let courses = []
    if (this.state && this.state.selectedcourses) {
      _.values(this.state.selectedcourses).forEach(course => {
        if (course == true) {
          courses.push(course);
        }
      });
    }
    return courses;
  }

  filterByYear(listToFilter: any[]) {
    if (this.state.selectedYear && listToFilter && listToFilter.length > 0) {
        let listToReturn = listToFilter.filter(item => {
            if (item.Year) {
                if (item.Year >= this.state.selectedYear) {
                    return true;
                }
                return false;
            }
            else if (item.DateSortable) {
                try {
                    let parsedYear = item.DateSortable.split(".")[0]
                    if (parsedYear == this.state.selectedYear) {
                        return true;
                    }
                    return false;
                }
                catch (error) {
                    return false;
                }
            }
        });
        return listToReturn;
    }
    return listToFilter;
  }

  sortElementDisplay = (array) : any[]=>{    
    const order = {"News": 1, default: Infinity}
      return array.sort(function (a, b) {
        let comp = a.Course.CourseCategory ? a.Course.CourseCategory:'';
        let compb = b.Course.CourseCategory ? b.Course.CourseCategory:'';
        //return (order[a.Course.CourseCategory] || order.default) - (order[b.Course.CourseCategory] || order.default) || a.Course.CourseCategory.localeCompare(b.Course.CourseCategory);
        return (order[comp] || order.default) - (order[compb] || order.default) || comp.localeCompare(compb);
    });
  
  }
  renderDateSelectorModal() {
    return (
      <Dialog
        hidden={false}
        onDismiss={() => this.setState({ showDateSelectModal: false })}
        dialogContentProps={{
          type: DialogType.normal,
          title: "Angi uke og år",
          subText: ""
        }}
        styles={{
          main: [
            {
              selectors: {
                ["@media (min-width: 480px)"]: {
                  maxWidth: "600px",
                  minWidth: "480px"
                }
              }
            }
          ]
        }}
        modalProps={{
          titleAriaId: "myLabelId",
          subtitleAriaId: "mySubTextId",
          isBlocking: true,
          containerClassName: "ms-dialogMainOverride"
        }}
      >
        <div className="ms-Grid">
          <div className="ms-Grid-col ms-sm6">
            <Dropdown
              selectedKey={this.state.dropdownSelectedWeek}
              placeHolder="Velg uke"
              label="Velg uke"
              id="selScheduleWeek"
              onChanged={value => {
                this.setState({ dropdownSelectedWeek: value.key });
              }}
              options={this.state.dropdownWeekOptions}
            />
          </div>
          <div className="ms-Grid-col ms-sm6">
            <Dropdown
              selectedKey={this.state.dropdownSelectedYear}
              placeHolder="Velg år"
              label="Velg år"
              id="selScheduleYear"
              onChanged={value => {
                this.setState({ dropdownSelectedYear: value.key });
              }}
              options={this.state.dropdownYearOptions}
            />
          </div>
        </div>
        <DialogFooter>
          <DefaultButton
            onClick={() => {
              this.setState({ showDateSelectModal: false });
            }}
            text="Avbryt"
          />
          <PrimaryButton
            onClick={() => {
              this.onCalendarDateSet();
            }}
            text="Gå direkte til uke"
          />
        </DialogFooter>
      </Dialog>
    );
  }

  renderSingleCourseView = (ta) => {
    let filteredTA = this.filteredTeacherAssignments(ta);
    if (!filteredTA.Empty && (this.state.selectedcourses[ta.Course.CourseID] || this.state.showall)) {
      return <div style={{display: "flex", flexDirection: "row", justifyContent: "flex-start", flexFlow: "wrap"}}>
        {filteredTA.News.map((news) => {
          return (
            <div className="item courseitem" style={{minWidth: "300px", width: "30%", margin: "0 20px 20px 0"}}>
              <div className="panel panel-default" id={ta.Course.CourseCategory}>
                  <div className="panel-heading">
                      <h2 className="week-course-name">{ta.Course.Title}</h2>
                  </div>
                  <div className="panel-body">
                    <NewsElementView newselement={news} history={this.props.history}/>
                  </div>
              </div>
          </div>)
          
        })}

        {filteredTA.TextElements.map((text) => {
            return (
              <div className="item courseitem" style={{minWidth: "300px", width: "30%", margin: "0 20px 20px 0"}}>
                <div className="panel panel-default" id={ta.Course.CourseCategory}>
                    <div className="panel-heading">
                        <h2 className="week-course-name">{ta.Course.Title}</h2>
                    </div>
                    <div className="panel-body">
                      <PlanElementView planelement={text} />
                    </div>
                </div>
            </div>
            )
        })}
        {filteredTA.Tasks.map((task) => {
            return (
              <div className="item courseitem"  style={{minWidth: "300px", width: "30%", margin: "0 20px 20px 0"}}>
                <div className="panel panel-default" id={ta.Course.CourseCategory}>
                    <div className="panel-heading">
                        <h2 className="week-course-name">{ta.Course.Title}</h2>
                    </div>
                    <div className="panel-body">
                      <TaskElementView taskelement={task} />
                    </div>
                </div>
            </div>
            )
        })}
      </div>
    }
  }

  render() {
    if (_.isEmpty(this.props.ukeplanelements)) {
      return <div>Loading...</div>;
    }
    if (!this.state) return <div>Loading...</div>;

    return (
      <div>
        {this.state &&
          this.state.showDateSelectModal &&
          this.renderDateSelectorModal()}
        <SidebarActionButtonList>
          <CommandBarButton
            data-tip="hei"
            onClick={() => this.props.history.push("/ukeplan/nytt_innslag/n")}
            className="sidenav-buttons"
            iconProps={{ iconName: "Add" }}
            text="Lag ny plan"
          ></CommandBarButton>

          <CommandBarButton
            onClick={() => this.props.history.push("/task/ny_innlevering/n")}
            className="sidenav-buttons"
            iconProps={{ iconName: "Add" }}
            text="Lag ny innlevering"
          ></CommandBarButton>
          <CommandBarButton
            onClick={() => this.props.history.push("/meldinger/ny_melding")}
            className="sidenav-buttons"
            iconProps={{ iconName: "Add" }}
            text="Lag ny melding"
          ></CommandBarButton>
          <hr />
          <CommandBarButton
            onClick={() => this.setState({ showDateSelectModal: true })}
            className="sidenav-buttons"
            iconProps={{ iconName: "PublicCalendar" }}
            text="Gå direkte til uke"
          ></CommandBarButton>
          <CommandBarButton
            onClick={() => this.props.history.push("/ukeplan/getstudent")}
            className="sidenav-buttons"
            iconProps={{ iconName: "CalendarWeek" }}
            text="Hent ukeplan"
          ></CommandBarButton>
          <CommandBarButton
            onClick={() => {
              window.open(
                `${this.props.teacherstate.user.tenanturl}/v2ukeplanlarer/v3PrintView/start-${this.state.selectedYear}-${this.state.selectedWeek}?SPHostUrl=${this.props.teacherstate.user.url}`
              );
            }}
            className="sidenav-buttons"
            iconProps={{ iconName: "Print" }}
            text="Skriv ut"
          ></CommandBarButton>
          <CommandBarButton
            className="sidenav-buttons"
            iconProps={{ iconName: "MailForward" }}
            text="Send på e-post"
            onClick={() => {
              window.open(
                `${this.props.teacherstate.user.tenanturl}/v2ukeplanlarer/V3EmailViewPrerender/start-${this.state.selectedYear}-${this.state.selectedWeek}?SPHostUrl=${this.props.teacherstate.user.url}`
              );
            }}
          ></CommandBarButton>
        </SidebarActionButtonList>
        <div className="ms-Grid" id="ukeplan_view">
          <div className="main">
            <div className="ms-Grid-row">
              <div className="ms-Grid-col ms-sm12 ms-lg7 ms-xl5">
                <div className="ms-Grid-col ms-sm12">
                  <HeaderView
                    title="Ukeplan"
                    description="Planer og innleveringer"
                    iconName="Calendar"
                  />
                </div>
                <div className="ms-Grid-col ms-sm12">
                  <div className="weekButtons">
                    <ActionButton
                      id="btnNormal"
                      className="ms-font-l"
                      onClick={() =>
                        this.setState({ courseview: true, dayview: false })
                      }
                      iconProps={{ iconName: "Switch" }}
                    >
                      Uke
                    </ActionButton>
                    <ActionButton
                      className="ms-font-l"
                      id="btnWeek"
                      onClick={() =>
                        this.setState({ courseview: false, dayview: true })
                      }
                      iconProps={{ iconName: "Table" }}
                    >
                      Dag
                    </ActionButton>
                    <ActionButton
                      className="ms-font-l"
                      id="btnPrint"
                      iconProps={{ iconName: "Print" }}
                      onClick={() => {
                        window.open(
                          `${this.props.teacherstate.user.tenanturl}/v2ukeplanlarer/v2GutenbergSelectorv3?SPHostUrl=${this.props.teacherstate.user.url}&week=${this.state.selectedWeek}&year=${this.state.selectedYear}`
                        );
                      }}
                    >
                      Skriv ut
                    </ActionButton>
                    <ActionButton 
                      iconProps={{ iconName: this.props.compactCardView ? "RedEye" : "Hide" }}
                      onClick={() => {
                        this.props.setCompactCardViewToggle(!this.props.compactCardView)
                      }}>{this.props.compactCardView ? "Vis full oppgave tekst" : "Begrens oppgave tekst"}</ActionButton>
                  </div>
                </div>
              </div>
              <div className="ms-Grid-col ms-sm12 ms-lg5 ms-xl7">
                {console.log(this.props.ukeplanelements)}
                {this.props.ukeplanelements && (
                  <div>
                    {this.props.ukeplanelements.AllTas.map(c => {
                      return (
                        <div>
                          <div>
                            <Checkbox
                              checked={
                                this.state.selectedcourses[c.Course.CourseID]
                              }
                              className="fag-checkbox"
                              label={c.Course.Title}
                              value={c.Course.CourseID}
                              onChange={(a, b) => {
                                console.log(b);
                                let tmp = this.state.selectedcourses;
                                console.log(tmp);
                                tmp[c.Course.CourseID] = b;
                                let showall = true;
                                if (b) {
                                  showall = false;
                                } else {
                                  Object.keys(
                                    this.state.selectedcourses
                                  ).forEach(k => {
                                    if (
                                      k !== c.Course.CourseID &&
                                      this.state.selectedcourses[k]
                                    ) {
                                      showall = false;
                                    }
                                  });
                                }

                                this.setState({
                                  selectedcourses: tmp,
                                  showall: showall
                                });
                              }}
                            ></Checkbox>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                )}
              </div>
              <div className="ms-Grid-col ms-sm12 ms-lg5 ms-xl7">
                <div id="fagselector"></div>
              </div>
            </div>
            <div className="ms-Grid-row ms-bgColor-neutralLight topTool">
              <div className="ms-Grid-col hiddenLgDown ms-xxl4"></div>
              {this.state && (
                <div className="ms-Grid-col ms-lg12 ms-xxl4">
                  <div className="ms-Grid-col ms-sm2 ms-md3 ms-lg4 prevWeek">
                    <ActionButton
                      onClick={() => {
                        let newDate = moment(this.state.thedate).add(-1, "weeks");
                        this.changeweek(newDate);
                      }}
                      style={{height: "inherit"}}
                      className="ms-font-xl"
                    >
                      <i
                        className="ms-Icon ms-Icon--PageLeft"
                        aria-hidden="true"
                      ></i>{" "}
                      <span> Forrige </span>
                    </ActionButton>
                  </div>
                  <div className="ms-Grid-col ms-sm8 ms-md6 ms-lg4 text-center pt-5">
                    <span
                      className="ms-font-xl ms-fontWeight-semibold week-number"
                      id="weekText"
                    >
                      Uke {this.state.selectedWeek}
                    </span>
                  </div>
                  <div className="ms-Grid-col ms-sm2 ms-md3 ms-lg4 nextWeek">
                    <ActionButton
                      onClick={() => {
                        let newDate = moment(this.state.thedate).add(7, "days");
                        this.changeweek(newDate);
                      }}
                      style={{height: "inherit"}}
                      className="ms-font-xl nextWeek"
                    >
                      <div>
                        <span>Neste</span>{" "}
                        <i
                          className="ms-Icon ms-Icon--PageRight"
                          aria-hidden="true"
                        >
                          {" "}
                        </i>
                      </div>
                    </ActionButton>
                  </div>
                </div>
              )}
              <div className="ms-Grid-col hiddenLgDown ms-xxl4"></div>
            </div>
            <div className="ms-Grid-row" id="mainWeekPart">
              {this.state.courseview && this.coursesToShow().length != 1 && (
                <div className="row1" id="list">
                  <Masonry>
                    {this.props.ukeplanelements.AllTas.map(ta => {
                      console.log('ata',ta);
                      let filteredTA = this.filteredTeacherAssignments(ta);
                      console.log('atafilter',filteredTA);
                      if (
                        !filteredTA.Empty && (this.state.selectedcourses[ta.Course.CourseID] ||
                        this.state.showall)
                      ) {
                        return (
                          <CourseElementView
                            teacherassignment={filteredTA}
                            history={this.props.history}
                          />
                        );
                      }
                    })}
                  </Masonry>
                </div>
              )}

              {this.state.courseview && this.coursesToShow().length == 1 && (
                <div>
                  {this.props.ukeplanelements.AllTas.map(ta => {
                    return this.renderSingleCourseView(ta)
                  })} 
                </div>
              )
              }

              {this.props.ukeplanelements && this.state.dayview && (
                <div className="row1" id="list2">
                  <Masonry>
                    <div className="ms-Grid-col ms-u-md4 ms-u-xl2 weekViewCol">
                      <h3 className="day-name">Mandag</h3>
                      {this.sortElementDisplay(this.props.ukeplanelements.MondayTa).map(ta => {
                        let filteredTA = this.filteredTeacherAssignments(ta);
                        if (
                          !filteredTA.Empty && this.state.selectedcourses[ta.Course.CourseID] ||
                          this.state.showall
                        ) {
                          return (
                            <CourseDayView
                              teacherassignment={ta}
                              history={this.props.history}
                            />
                          );
                        }
                      })}
                    </div>
                    <div className="ms-Grid-col ms-u-md4 ms-u-xl2 weekViewCol">
                      <h3 className="day-name">Tirsdag</h3>
                      {this.sortElementDisplay(this.props.ukeplanelements.TuesdayTa).map(ta => {
                        let filteredTA = this.filteredTeacherAssignments(ta);
                        if (
                          !filteredTA.Empty && this.state.selectedcourses[ta.Course.CourseID] ||
                          this.state.showall
                        ) {
                          return (
                            <CourseDayView
                              teacherassignment={ta}
                              history={this.props.history}
                            />
                          );
                        }
                      })}
                    </div>
                    <div className="ms-Grid-col ms-u-md4 ms-u-xl2 weekViewCol">
                      <h3 className="day-name">Onsdag</h3>
                      {this.sortElementDisplay(this.props.ukeplanelements.WednesdayTa).map(ta => {
                        let filteredTA = this.filteredTeacherAssignments(ta);
                        if (
                          !filteredTA.Empty && this.state.selectedcourses[ta.Course.CourseID] ||
                          this.state.showall
                        ) {
                          return (
                            <CourseDayView
                              teacherassignment={ta}
                              history={this.props.history}
                            />
                          );
                        }
                      })}
                    </div>
                    <div className="ms-Grid-col ms-u-md4 ms-u-xl2 weekViewCol">
                      <h3 className="day-name">Torsdag</h3>
                      {this.sortElementDisplay(this.props.ukeplanelements.ThursdayTa).map(ta => {
                        let filteredTA = this.filteredTeacherAssignments(ta);
                        if (
                          !filteredTA.Empty && this.state.selectedcourses[ta.Course.CourseID] ||
                          this.state.showall
                        ) {
                          return (
                            <CourseDayView
                              teacherassignment={ta}
                              history={this.props.history}
                            />
                          );
                        }
                      })}
                    </div>

                    <div className="ms-Grid-col ms-u-md4 ms-u-xl2 weekViewCol">
                      <h3 className="day-name">Fredag</h3>
                      {this.sortElementDisplay(this.props.ukeplanelements.FridayTa).map(ta => {
                        let filteredTA = this.filteredTeacherAssignments(ta);
                        if (
                          !filteredTA.Empty && this.state.selectedcourses[ta.Course.CourseID] ||
                          this.state.showall
                        ) {
                          return (
                            <CourseDayView
                              teacherassignment={ta}
                              history={this.props.history}
                            />
                          );
                        }
                      })}
                    </div>
                    <div className="ms-Grid-col ms-u-md4 ms-u-xl2 weekViewCol">
                      <h3 className="day-name">Fremtidige planer</h3>
                      {this.sortElementDisplay(this.props.ukeplanelements.FutureTa).map(ta => {
                        let filteredTA = this.filteredTeacherAssignments(ta);
                        if (
                          !filteredTA.Empty && this.state.selectedcourses[ta.Course.CourseID] ||
                          this.state.showall
                        ) {
                          return (
                            <CourseDayView
                              teacherassignment={ta}
                              history={this.props.history}
                            />
                          );
                        }
                      })}
                    </div>
                  </Masonry>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    teacherstate: state.teacher,
    ukeplanelements: state.ukeplanelements.AllTAs,
    planelements: state.ukeplanelements.planelements,
    initialDate: state.ukeplanelements.SelectedDate,
    master: state.ukeplanelements,
    compactCardView: state.ukeplanelements.CompactCardView
  };
}

export default connect(mapStateToProps, { getUkeplanElements, setUkeplanSelectedDate, setCompactCardViewToggle })(UkeplanView);
