import * as _ from "lodash";
import {
  GET_SCHEDULE_ELEMENT,
  GET_SCHEDULE_ELEMENTS,
  DELETE_SCHEDULE_ELEMENT,
  UPDATE_SCHEDULE_ELEMENT,
  COPY_SCHEDULE_ELEMENT,
  SET_SCHEDULE_CLASS,
  REMEMBER_TIME,
  DELETE_MULTIPLE_SCHEDULE_ELEMENT,
  DISTRIBUTE_TEMPLATE,
  CLEAR_SCHEDULE_ELEMENTS
} from "../actions/Action_ScheduleElement";

import { default as DateUtils } from "./../utils/DateUtils";
import {CopySchedule} from "../models/CopySchedule";

const copyScheduleElement = (state, action) =>{

    const {Toweek, Toyear, Toclassid} = action.payload as CopySchedule;

    const updatedScheduleElements = _.values(state)
        .filter(s => s.WeekNo !== Toweek && s.Year !== Toyear && s.ClassID !== Toclassid);

    // Returns updated state where the overwritten ScheduleElements are removed front-end
    return _.mapKeys(updatedScheduleElements, "Id");
};


export default function (state = {}, action) {
  const dateUtils = new DateUtils();

  switch (action.type) {
    case REMEMBER_TIME:
        return {...state, rememberedTime: action.payload}
    case COPY_SCHEDULE_ELEMENT:
      return copyScheduleElement(state, action);
    case UPDATE_SCHEDULE_ELEMENT:
      const newScheduleElement = action.payload.data;

      newScheduleElement.Start = dateUtils.dateTimeStringToDate(
        newScheduleElement.Start
      );
      newScheduleElement.End = dateUtils.dateTimeStringToDate(
        newScheduleElement.End
      );

      return { ...state, [newScheduleElement.Id]: newScheduleElement };
    case DELETE_SCHEDULE_ELEMENT:
      // action.payload is the id of the post we deleted.
      return _.omit(state, action.payload);
    case DELETE_MULTIPLE_SCHEDULE_ELEMENT:
      return {...state}
    case GET_SCHEDULE_ELEMENT:
      const scheduleElement = action.payload.data;

      scheduleElement.Start = dateUtils.dateTimeStringToDate(
        scheduleElement.Start
      );
      scheduleElement.End = dateUtils.dateTimeStringToDate(scheduleElement.End);

      return { ...state, [scheduleElement.Id]: scheduleElement };
    case SET_SCHEDULE_CLASS:
      const selectedclass = action.payload;

      return { ...state, selectedclass: selectedclass };
    case DISTRIBUTE_TEMPLATE:
      let week = action.payload.Targets.split("|");
      
      let stateArray =  Object.entries(state);
      
      let newState = {};

      stateArray.forEach(element => {
        let value: any = element[1];
        if (value.Id && value.WeekNo) {
          if(week.indexOf(value.WeekNo.toString()) == -1) {
            newState[element[0]] = element[1];
          }
        }
        else {
          newState[element[0]] = element[1];
        }
      });

      return newState;
    case GET_SCHEDULE_ELEMENTS:
      const scheduleElements = action.payload.data;

      // If there are no ScheduleElements in response, we return the current state
      if (!action.payload.data.length) {
        return state;
      }

      // Converting DateTime strings to Date objects for fetched ScheduleElements
      scheduleElements.forEach((se) => {
        se.Start = dateUtils.dateTimeStringToDate(se.Start);
        se.End = dateUtils.dateTimeStringToDate(se.End);
      });

      // Mapping list of objects by key for scalability and faster lookups in detail view.
      // State collects all fetched ScheduleElements from all previous requests.
      return _.assign({}, state, _.mapKeys(scheduleElements, "Id"));
    case CLEAR_SCHEDULE_ELEMENTS:
        return {selectedclass: action.payload}
    default:
      return state;
  }
}
