import * as React from "react";
import {withRouter, Link} from "react-router-dom";
import {connect} from "react-redux";
import {Field, reduxForm, change} from "redux-form";
import {
    TextField,
    Checkbox,
    Label,
    PrimaryButton,
    DefaultButton,
    ActionButton
} from "office-ui-fabric-react";
import {saveQuestion} from "../../actions/Action_Quiz";
import "../../../../src/app/formelements.scss";
import CheckboxField from "../form_fields/CheckboxField";
import InputField from "../form_fields/InputField";
import SelectField from "../form_fields/SelectField";
import {Question} from "../../models/Question";
import {QuestionOption} from "../../models/QuestionOption";
import {QuestionType} from "../../models/common/enums/QuestionType";

interface IReduxFormProps {
    change: (key: string, value) => void;
    handleSubmit: any;
}

export interface IQuestionFormProps {
    question: Question;
    newQuestion: Question;
    options: QuestionOption[];

    saveQuestion(question: Question);

    onClose: () => any;
    onContinue: () => any;
}

export interface IQuestionFormState {
    question: any;
    options: any[];
}

type QuestionFormTypes = IQuestionFormProps & IReduxFormProps;

class QuestionForm extends React.Component<QuestionFormTypes,
    IQuestionFormState> {
    constructor(props) {
        super(props);
        this.state = {question: {}, options: null};

        this.onFormSubmit = this.onFormSubmit.bind(this);
    }

    /*****************************
     Lifecycle hooks
     *****************************/

    componentWillReceiveProps() {
        if (!this.state.options) {
            const {question, options} = this.props;
            this.setState({question, options});
        }
    }

    /*****************************
     Mapping methods
     *****************************/

    public getQTypeOptions = () => [
        {key: QuestionType.RadioButtons, text: "Radioknapper"},
        {key: QuestionType.CheckBoxes, text: "Avkryssingsbokser"},
        {key: QuestionType.TextAnswer, text: "Tekstsvar"}
    ];

    public getAlternativesError = (): string => {
        if (this.state.options) {
            for (let option of this.state.options) {
                if (!option.Text) {
                    return "Alle alternativer må inneholde tekst.";
                }

                if (!(parseFloat(option.Points as string) <= 0 || parseFloat(option.Points as string) >= 0)) {
                    return "Alle alternativer må ha oppgitt poengsum.";
                }

                if (this.props.newQuestion.QType === QuestionType.RadioButtons) {
                    if (
                        this.state.options &&
                        this.state.options.filter(o => o.Correct).length !== 1
                    ) {
                        return "Kun ett alterativ kan være riktig for radioknapper";
                    }
                }
            }

            const {QType} = this.props.newQuestion;

            if (
                QType === QuestionType.RadioButtons ||
                QType === QuestionType.CheckBoxes
            ) {
                if (this.state.options.length < 2) {
                    return "Spørsmålet må inneholde minst to alternativer.";
                }

                const numCorrect = this.state.options.filter(option => option.Correct)
                    .length;

                if (QType === QuestionType.CheckBoxes && !numCorrect) {
                    return "Minst ett alternativ må være riktig.";
                }

                if (QType === QuestionType.RadioButtons && numCorrect !== 1) {
                    return "Kun ett alternativ kan være riktig.";
                }
            }
        }

        return "";
    };

    /*****************************
     Event handlers
     *****************************/

    public onFormSubmit = (values: Question) => {
        if (this.getAlternativesError()) {
            return;
        }

        const questionToBeSaved: Question = {
            ...this.props.newQuestion,
            QOptions: JSON.stringify(this.state.options)
        };

        this.props.saveQuestion(questionToBeSaved).then(() => {
            this.props.onClose();
        });
    };

    public onQTypeChanged = (QType : QuestionType) => {

        const {question} = this.state;

        this.setState({
            options: [],
            question: {
                ...question,
                QType,
                QMaxPoints: 0,
                QMinPoints: 0,
            }
        }, () =>{
            if (QType !== QuestionType.TextAnswer){
                this.onAddAlternative();
                this.onAddAlternative();
            }
        });
    };
    public onFormSubmitContinue = (values: Question) => {
        if (this.getAlternativesError()) {
            return;
        }

        const questionToBeSaved: Question = {
            ...this.props.newQuestion,
            QOptions: JSON.stringify(this.state.options)
        };

        this.props.saveQuestion(questionToBeSaved).then(() => {
            this.props.onContinue();
        });
    };


    onAlternativeTextChanged = (value: string, index: number) => {
        const {options} = this.state;
        options[index].Text = value;

        this.setState({options});
    };

    onAlternativeCorrectToggled = (index: number) => {

        let {options} = this.state;

        if (this.props.newQuestion.QType === QuestionType.RadioButtons) {
            options = options.map(option => ({...option, Correct: false}));
        }

        options[index].Correct = !options[index].Correct;

        this.setState({options});
    };

    getHighestSumOfOptionsForCheckboxes = (options : QuestionOption[]) : number =>{

        if (!options.length){
            return 0;
        }

        const sortedOptionPoints : number[] = options
            .map(o => o.Points)
            .sort()
            .reverse();

        let max = Math.max(...sortedOptionPoints);

        for (let i=0; i<sortedOptionPoints.length; i++){
            const listRange = sortedOptionPoints.slice(0, i);

            const sumOfListRange = listRange.length
                ? listRange.reduce((s, current) => s+current)
                : 0;

            if (sumOfListRange > max){
                max = sumOfListRange
            }
        }

        return max;
    };

    setMinAndMaxPointsByQuestionType = (options : QuestionOption[], QType : QuestionType) : void =>{

        const optionPoints : number[] = options.map(o => o.Points);

        switch (QType){
            case QuestionType.CheckBoxes:
                // Lowest checkbox values is always zero, in case no options are selected
                this.props.change("QMinPoints", 0);

                // Sets highest sum of point values from options
                const QMaxPointsCheckbox : number =  this.getHighestSumOfOptionsForCheckboxes(options);
                this.props.change("QMaxPoints", QMaxPointsCheckbox);
                break;
            case QuestionType.RadioButtons:
                // Sets lowest point value from options
                const QMinPointsRadio : number = Math.min(...optionPoints);
                this.props.change("QMinPoints", QMinPointsRadio);

                const QMaxPointsRadio : number = Math.max(...optionPoints);
                this.props.change("QMaxPoints", QMaxPointsRadio);

                break;
            default:
                break;
        }
    };

    onAlternativePointsChanged = (value: string, index: number) => {


        const {QType} = this.state.question;

        const {options} = this.state;
        options[index].Points = parseInt(value) ? parseInt(value) : 0;

        this.setMinAndMaxPointsByQuestionType(options, QType);

        this.setState({options});
    };

    onAddAlternative = () => {
        let {options, question} = this.state;

        options.push({
            Text: "",
            Correct: false,
            QID: (options.length + 1).toString(),
            Points: 0
        });

        this.setState({options}, () =>{
            this.setMinAndMaxPointsByQuestionType(options, question.QType);
        });
    };

    /*****************************
     Render methods
     *****************************/

    public renderOption = (option, index) => (
        <li>
            <div className="vertical-align">
                <div className="ms-Grid-col ms-sm2 p-x-0">
                    <Label>Riktig</Label>
                    <Checkbox
                        className={`qCorrect ${
                            this.props.newQuestion.QType === QuestionType.RadioButtons
                                ? "isRadioButton"
                                : ""
                            }`}
                        checked={option.Correct}
                        onChange={() => this.onAlternativeCorrectToggled(index)}
                    />
                </div>
                <div className="ms-Grid-col ms-sm6">
                    <Label>Tekst</Label>
                    <TextField
                        className="qOptions"
                        type="text"
                        value={option.Text}
                        onChanged={value => this.onAlternativeTextChanged(value, index)}
                    />
                </div>
                <div className="ms-Grid-col ms-sm3">
                    <Label>Poeng</Label>
                    <TextField
                        className="qPoints"
                        type="number"
                        value={option.Points}
                        onChanged={value => this.onAlternativePointsChanged(value, index)}
                    />
                </div>
                <div className="ms-Grid-col ms-sm2 qDelete">
                    <ActionButton
                        iconProps={{iconName: "Delete"}}
                        onClick={() => {
                            let op = [];
                            this.state.options.forEach(o => {
                                if (o.QID != option.QID) {
                                    op.push(o);
                                }
                            });
                            this.setState({options: op}, ()=> this.setMinAndMaxPointsByQuestionType(op, this.state.question.QType));
                        }}
                    />
                </div>
            </div>
        </li>
    );

    public renderOptions = () => {
        return (
            <div className="ms-Grid-row">
                <div className="z-form-group">
                    <div className="ms-Grid-col ms-sm12 ms-md4">
                        <Label className="z-form-label">Valg</Label>
                    </div>
                    <div className="ms-Grid-col ms-sm12 ms-md8">
                        {
                            <ul className="choice-list">
                                {this.state.options &&
                                this.state.options.map((option, index) => {
                                    return this.renderOption(option, index);
                                })}
                            </ul>
                        }
                        <div className="ms-Grid-col ms-sm12 error-message">
                            {this.getAlternativesError()}
                        </div>
                        <ActionButton
                            text="Legg til valg"
                            style={{margin: "10px 0"}}
                            iconProps={{iconName: "Add"}}
                            onClick={this.onAddAlternative}
                        />
                    </div>
                </div>
            </div>
        );
    };

    public renderAnswerType = () => {
        const {QType} = this.props.newQuestion;

        switch (QType) {
            case QuestionType.TextAnswer:
                return (
                    <Field
                        name="QAnswer"
                        label="Svar"
                        component={InputField}
                    />
                );
            case QuestionType.CheckBoxes:
            case QuestionType.RadioButtons:
                return this.renderOptions();
            default:
                return null;
        }
    };

    public render() {
        const {handleSubmit} = this.props;
        return (
            <form>
                <div className="form-content">
                    <Field
                        name="QText"
                        label="Spørsmålstittel"
                        required={true}
                        component={InputField}
                    />

                    <Field
                        name="QType"
                        label="Spørsmålstype"
                        placeholder="Velg spørsmålstype"
                        required={true}
                        component={SelectField}
                        options={this.getQTypeOptions()}
                        onSelect={this.onQTypeChanged}
                    />

                    {this.renderAnswerType()}

                    <Field
                        name="QMinPoints"
                        label="Minimum poeng"
                        required={true}
                        isNumeric={true}
                        component={InputField}

                    />

                    <Field
                        name="QMaxPoints"
                        label="Maks poeng"
                        required={true}
                        isNumeric={true}
                        component={InputField}
                    />

                    <Field
                        name="QMandatory"
                        label="Obligatorisk"
                        component={CheckboxField}
                    />
                    {this.props.newQuestion.QType != QuestionType.TextAnswer && (
                        <Field
                            name="QRandom"
                            label="Tilfeldig rekkefølge"
                            component={CheckboxField}
                        />
                    )}
                    {this.props.newQuestion.QType != QuestionType.TextAnswer && this.props.newQuestion.QType != QuestionType.RadioButtons && (
                        <Field
                            name="QMAllOrNothing"
                            label="Kun gi poeng når alle svaralternativ er riktige"
                            component={CheckboxField}
                        />
                    )}
                </div>
                <div className="form-footer">
                    <div className="form-footer-left">
                        <DefaultButton text="Avbryt" onClick={() => this.props.onClose()}/>
                    </div>
                    <div className="form-footer-right">
                        <PrimaryButton
                            text="Lagre og fortsett"
                            onClick={handleSubmit(this.onFormSubmitContinue)}
                        />
                        <PrimaryButton
                            text="Lagre"
                            onClick={handleSubmit(this.onFormSubmit)}
                        />
                    </div>
                </div>

            </form>
        );
    }
}

const mapStateToProps = (state, ownProps) => {
    const question: Question = ownProps.initialValues;
    let options: QuestionOption[] = [];

    if (ownProps.initialValues && ownProps.initialValues.QOptions) {
        options = JSON.parse(ownProps.initialValues.QOptions) as QuestionOption[];
    }

    return {
        question,
        options,
        newQuestion: state.form.QuestionForm.values
    };
};

const validate = values => {
    const errors: any = {};

    if (!values.QText) {
        errors.QText = "Dette feltet er påkrevd.";
    }

    if (!values.QType) {
        errors.QType = "Dette feltet er påkrevd.";
    }

    if (values.QMinPoints === undefined) {
        errors.QMinPoints = "Dette feltet er påkrevd.";
    }

    if (values.QMaxPoints === undefined) {
        errors.QMaxPoints = "Dette feltet er påkrevd.";
    }

    // Hvis det ikke er tidligere feilmeldinger på min og maks poeng, men min er større en maks
    if (!errors.QMinPoints && !errors.QMaxPoints && parseInt(values.QMinPoints) > parseInt(values.QMaxPoints)) {
        errors.QMaxPoints = "Maks poeng må være større eller lik minimum poeng.";
    }

    return errors;
};

export default reduxForm({
    form: "QuestionForm",
    validate,
    enableReinitialize: true
})(connect(mapStateToProps, {saveQuestion})(withRouter(QuestionForm)));
