import * as React from 'react';
import {Icon} from "office-ui-fabric-react";
import Timer from '../student/quiz/Timer';


export interface IHeaderViewProps{
    title:string;
    description?:string;
    iconName:string;
}


class HeaderView extends React.Component<IHeaderViewProps>{

    constructor(props){
        super(props);
    }

    render(){
        //console.log(this.props);
        return(
            <div className="header">
                <div className="ms-Grid-row">
                    <div className="ms-Grid-col">
                    <h1 className="ms-fontColor-orangeLight"> <Icon iconName={this.props.iconName} /> {this.props.title}</h1>
                        <h2 className="ms-fontColor-orangeLight zSubTitle ms-font-l">{this.props.description}</h2>
                    </div>
                </div>
                <div className="ms-Grid-row">
                    {this.props.children}
                 
                </div>
            </div>
        )
    }
}

export default HeaderView;