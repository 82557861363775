import { IEducationUser } from "./IEducationUser";
import { IOutcome } from "./IOutcome";
import { ISubmission } from "./ISubmission";
import { FeedbackOutcome } from "./Outcome/FeedbackOutcome";
import { FeedbackResourceOutcome } from "./Outcome/FeedbackResourceOutcome";
import { PointsOutcome } from "./Outcome/PointsOutcome";
import { RubicOutcome } from "./Outcome/RubricOutcome";

export class TeamSubmission {
    graphSubmission: ISubmission;

    //outcome: any;
    assignmentid: string;
    classid: string;
    qualmap: {};
    qualmaprev: {};
    levelmaprev: {};
    student: IEducationUser;
    userName: string;

    feedbackOutcome?: FeedbackOutcome;
    pointsOutcome?: PointsOutcome;
    rubricOutcome?: RubicOutcome;
    feedbackResourceOutcome?: FeedbackResourceOutcome;

    constructor(submission: ISubmission) {
        this.graphSubmission = submission;

        this.populateOutcomes();
    }

    setOutcomes = (outcomes: IOutcome[]) => {
        this.graphSubmission.outcomes = outcomes;
    }

    populateOutcomes = ()  => {
        let submission = this.graphSubmission;
        if (submission.outcomes) {
            submission.outcomes.forEach(outcome => {
                if (outcome["@odata.type"] == "#microsoft.graph.educationFeedbackOutcome") {
                    this.feedbackOutcome = new FeedbackOutcome(outcome);
                }
                else if (outcome["@odata.type"] == "#microsoft.graph.educationPointsOutcome") {
                    this.pointsOutcome = new PointsOutcome(outcome);
                }
                else if (outcome["@odata.type"] == "#microsoft.graph.educationRubricOutcome") {
                    this.rubricOutcome = new RubicOutcome(outcome);
                }
                else if (outcome["@odata.type"] == "#microsoft.graph.educationFeedbackResourceOutcome ") {
                    this.feedbackResourceOutcome = new FeedbackResourceOutcome(outcome);
                }
            });

        }       
    }
}