import * as React from "react";
import * as moment from "moment";
import {
  getStudentElements,
  getStudentState,
  getStudentIUPlist,
  getStudentIUP
} from "../../../actions/Action_Student";


import "../../portfolio/portfolio.scss";
import { PortfolioListViewStudent } from "./PortfolioListViewStudent";

import HeaderView from '../../view_containers/HeaderView';
import { Label, Dropdown } from 'office-ui-fabric-react';
import SpinLoader from '../../loading/SpinLoader';
import { DefaultButton } from 'office-ui-fabric-react/lib/Button';
import { connect } from 'react-redux';
import DatePicker from 'react-datepicker';
import '../../portfolio/portfolio.scss';
import PortfolioListView from '../../portfolio/PortfolioListView';
import { IupClassReportItemViewStudent } from './IupClassReportItemViewStudent';

export interface IPortfolioStudentViewProps {
	getStudentElements(student: any, from: any, to: any);
	getStudentIUPlist();
	getStudentIUP(iup: any);
	student: any;
	options: any[];
	optionslp: any[];
	optionsselect: any[];
	selitem: string;
	selitemlp: string;
	items: any[];
	lp: any;
	teacherstate: any;
	gradereport: any;
	history: any;
	iuplist: any[];
	iupreport: any;
}

export interface IPorfolioStudentViewState {
	selitem: string;
	selitemlp: string;
	result: any[];
	fromdate: any;
	todate: any;
	loading: boolean;
	showlp: boolean;
	showport: boolean;
	showgrades: boolean;
	showiup: boolean;
}

class PortfolioStudentView extends React.Component<IPortfolioStudentViewProps, IPorfolioStudentViewState> {
	constructor(props) {
		super(props);

		//   this.setState({
		//     fromdate: moment()
		// });
		this.state = {
			fromdate: moment().add('years', -1),
			todate: moment(),
			selitem: '',
			selitemlp: '',
			result: [],
			loading: false,
			showgrades: false,
			showlp: false,
			showport: false,
			showiup: false
		};
	}

	/****************************
            Lifecycle hooks
     ****************************/

	componentDidMount() {
		this.setState({ loading: true });
		this.props.getStudentIUPlist();
		this.props
			.getStudentElements(this.props.student, this.state.fromdate.format(), this.state.todate.format())
			.then(() => {
				this.setState({ loading: false, showport: true });
			});
	}

	/****************************
            Event Handlers
     ****************************/

	/****************************
            Helper methods
     ****************************/

	/****************************
            Render methods
     ****************************/

	render() {
		let iupoptions = [{ key: '0', text: 'Alle' }];
		let dict = {};
		console.log('iuplist', this.props.iuplist);
		if (this.props.iuplist) {
			this.props.iuplist.forEach((element) => {
				iupoptions.push({ key: element.Iup.Id, text: element.Iup.Name });
			});
		}

		return (
			<div className="ms-Grid" id="portfolio-teacher-view">
				<div className="container main">
					<HeaderView
						title="Portefølje"
						description="Finn dine planer, innleveringer og IUP'er"
						iconName="FabricOpenFolderHorizontal"
					/>
					<div className="ms-Grid-row ms-bgColor-neutralLight topTool">
						<div className="ms-Grid-row">
							<div className="mt-10">
								<span className="ms-Grid-col ms-sm4 ms-lg1 ms-xxl1">
									<Label className="date-label">Fra:</Label>
								</span>
								<div className="ms-Grid-col ms-sm8 ms-lg3 ms-xxl2">
									<DatePicker
										autocomplete="off"
										selected={this.state.fromdate}
										locale="nb-NO"
										onChange={(date) => {
											this.setState({
												fromdate: date
											});
										}}
										showWeekNumbers
									/>
								</div>
								<span className="ms-Grid-col ms-sm4 ms-lg1 ms-xxl1">
									<Label className="date-label">Til:</Label>
								</span>
								<div className="ms-Grid-col ms-sm8 ms-lg3 ms-xxl2">
									<DatePicker
										autocomplete="off"
										selected={this.state.todate}
										locale="nb-NO"
										onChange={(date) => {
											this.setState({
												todate: date
											});
										}}
										showWeekNumbers
									/>
								</div>
								<div className="ms-Grid-col ms-sm12 ms-lg4 ms-xxl6">
									<DefaultButton
										primary={true}
										className="portfolio-student--button"
										data-automation-id="test"
										disabled={false}
										text="Hent"
										onClick={() => {
											this.setState({ loading: true });

											this.props
												.getStudentElements(
													this.props.student,
													this.state.fromdate.format(),
													this.state.todate.format()
												)
												.then(() => {
													this.setState({ loading: false, showport: true, showiup: false });
												});
										}}
										allowDisabledFocus={true}
									/>
								</div>
							</div>
						</div>
						<div className="ms-Grid-row mt-10">
							<div className="ms-Grid-col ms-sm4 ms-lg1">
								<Label className="date-label">IUP:</Label>
							</div>
							<div className="ms-Grid-col ms-sm8 ms-lg11">
								<Dropdown
									defaultSelectedKey={1}
									placeHolder="Velg status"
									id="selCourseStatus"
									style={{ maxWidth: '554px' }}
									onChanged={(status) => {
										this.setState({
											showiup: false,
											showlp: false,
											showgrades: false,
											showport: false,
											loading: true
										});
										this.props.getStudentIUP({ Id: status.key }).then((iup) => {
											console.log('iup', iup);
											this.setState({
												showiup: true,
												showlp: false,
												showgrades: false,
												showport: false,
												loading: false
											});
										});
									}}
									options={iupoptions}
								/>
								{this.state.showiup && (
									<IupClassReportItemViewStudent
										item={this.props.iupreport}
									></IupClassReportItemViewStudent>
								)}
							</div>
						</div>
					</div>
					<div className="ms-Grid-row">
						<div className="ms-Grid-col ms-sm12">
							<div id="detailView">
								{
									// Renders SpinLoader while loading courses if it is not fetched from an earlier request
									this.state.loading && <SpinLoader text="Laster portefølje" />
								}

								{this.state.showport && !this.state.loading && (
									 <PortfolioListViewStudent
									 items={this.props.items}
									 history={this.props.history}
								   ></PortfolioListViewStudent>
								)}
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

function mapStateToProps(state) {
	let iup = [];

	if (state.StudentReducer) {
		iup = state.StudentReducer.iup;
	}
	return {
		student: state.StudentReducer.student,
		items: state.StudentReducer.portfolioitems,
		iuplist: iup,
		iupreport: state.StudentReducer.iupreport
	};
}

export default connect(mapStateToProps, {
	getStudentElements,
	getStudentState,
	getStudentIUPlist,
	getStudentIUP
})(PortfolioStudentView);
