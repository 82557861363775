import {
  GET_ALL_CLASSES,
  GET_CLASS_ASSIGNMENTS,
  GET_ASSIGNMENT,
  GET_CLASS_MEMBERS,
  GET_ASSIGNMENT_SUBMISSIONS,
  GET_ASSIGNMENT_PLAG,
  GET_ASSIGNMENT_FULL,
  GET_ASSIGNMENT_OUTCOMES,
  GET_ASSIGNMENT_ALLOUTCOMES,
  SAVE_TEAMMAP,
  GET_FULL_SUBMISSION,
  CLEAR_CURRENT_ASSIGNMENT
} from "../actions/Action_Graph";
import { ISubmission } from "../models/graph/ISubmission";
import { default as DateUtils } from "./../utils/DateUtils";
const INITIAL_STATE = { currentassignmentoutcomes: [] };
export default function (state = INITIAL_STATE, action) {
  const dateUtils = new DateUtils();

  switch (action.type) {
    case GET_ALL_CLASSES:
      const allclasses = action.payload.value;
      return { ...state, allclasses: allclasses };
    case GET_CLASS_ASSIGNMENTS:
      const classAssignments = action.payload.value;

      if (action.payload && action.payload.value) {
        return { ...state, classAssignments: classAssignments };
        //return {...state, scoreList: [...state.scoreList, action.payload]}
      } else {
        return state;
      }

    case GET_ASSIGNMENT_FULL:
      const currentassignmentfull = action.payload;
      return { ...state, currentassignment: currentassignmentfull };
    case GET_ASSIGNMENT_OUTCOMES:
      const currentassignmentoutcomes = action.payload;
      // const oldBooks = state.currentassignmentoutcomes.filter(book => !currentassignmentoutcomes.some(newBook => newBook.submissionid === book.submissionid));
      // oldBooks.concat(currentassignmentoutcomes);
      // return {
      //   ...state,
      //   currentassignmentoutcomes: currentassignmentoutcomes,
      // };
      return {
        ...state,
        currentassignmentoutcomes: [
          ...state.currentassignmentoutcomes,
          action.payload,
        ],
      };
    case GET_ASSIGNMENT_ALLOUTCOMES:
      const alloutcomes = action.payload;
      let red = alloutcomes.reduce(function (map, obj) {
        map[obj.submissionid] = obj;
        return map;
      }, {});
      return {
        ...state,
        currentassignmentalloutcomes: red,
      };

    case GET_ASSIGNMENT:
      const currentassignment = action.payload;
      return { ...state, currentassignment: currentassignment };
    case GET_CLASS_MEMBERS:
      const currentClassMembers = action.payload.value;
      return { ...state, currentClassMembers: currentClassMembers };

    case GET_ASSIGNMENT_SUBMISSIONS:
      const currentsubmissions = action.payload.value as ISubmission[];
      return { ...state, assignmentsubmissions: currentsubmissions };
    case GET_ASSIGNMENT_PLAG:
      const currentPlags = action.payload.data;
      let reduced = null;
      reduced = currentPlags.reduce(function (map, obj) {
        map[obj.fileid] = obj;
        return map;
      }, {});
      return { ...state, assignmentplags: reduced };
    case SAVE_TEAMMAP:
      return { ...state, MappedTeams: action.payload.data };
    case GET_FULL_SUBMISSION:
      const currentteamssubmissions = action.payload.value;
      return { ...state, Submission: currentteamssubmissions };
    case CLEAR_CURRENT_ASSIGNMENT:
      return { ...state, currentassignment: undefined, currentClassMembers: []}
    default:
      return state;
  }
}
