import * as React from "react";
import { BrowserRouter, Route, Switch, HashRouter } from "react-router-dom";
import { connect } from "react-redux";
import Sidebar from "react-sidebar";
import InnleveringStatusView from "../innleveringer/InnleveringStatusView";
import InnleveringerView from "../innleveringer/InnleveringerView";
import InnleveringerCourseView from "../teams/InnleveringerCourseView";
import StudentTaskView from "../innleveringer/StudentTaskView";
import UkeplanView from "../ukeplan/UkeplanView";
import PreviewUkeplanView from "../ukeplan/PreviewUkeplanView";
import PlanelementEditView from "../ukeplan/PlanElementEditView";
import TimeplanView from "../timeplan/TimeplanView";
import ScheduleElementDetailView from "../timeplan/ScheduleElementDetailView";
import SidebarView from "../sidebar/SidebarView";
import SidebarViewStudent from "../sidebar/SidebarViewStudent";
import AdminView from "../admin/AdminView";
import AdminStudentsAndCoursesView from "../admin/students_and_courses/AdminStudentsAndCoursesView";
import AdminCoursesView from "../admin/courses/AdminCoursesView";
import MeldingerView from "../meldinger/MeldingerView";
import AdminSchoolClassesView from "../admin/schoolClasses/AdminSchoolClassesView";
import AdminStudentsAndCoursesDetailView from "../admin/students_and_courses/AdminStudentsAndCoursesDetailView";
import { getStudentState } from "../../actions/Action_Student";
import { toggleSidebar } from "../../actions/Action_Sidebar";
import MyQuizzesTeacherView from "../Quiz/MyQuizzesTeacherView";
import QuizEditView from "../Quiz/QuizEditView";
import TakeQuizView from "../Quiz/TakeQuizView";
import QuizGrader from "../Quiz/QuizGrader";
import IupView from "../iup/iupView";
import TaskElementEditView from "../ukeplan/TaskElementEditView";
import TaskDistributeView from "../ukeplan/TaskDistributeView";
import MessageEditView from "../ukeplan/MessageEditView";
import testing from "../graph/testing";
import Config from "../config/config";
import Auth from "../config/auth";
import UkeplanStudentView from "../student/ukeplan/UkeplanStudentView";
import InnleveringerStudentView from "../student/innlevering/InnleveringerStudentView";
import TimeplanStudentView from "../student/timeplan/TimeplanStudentView";
import PortfolioStudentView from "../student/portfolio/PortfolioStudentView";
import StudentTaskDeliver from "../student/innlevering/StudentTaskDeliver";
import PortfolioView from "../portfolio/PortfolioView";
import StudentQuizOverview from "../student/quiz/StudentQuizOverview";
import StudentQuizGrading from "../student/quiz/StudentQuizGrading";
import Discovery from "../discovery/discovery";
import StudentQuizAttemptView from "../student/quiz/StudentQuizAttemptView";
import Statusbar from "../shared/Statusbar";
import InnleveringStatusViewTeams from "../teams/InnleveringStatusViewTeams";
import { SplashScreenView } from "../loading/SplashScreen";
import Impersonator from "../godmode/Impersonator";
import * as _ from "lodash";
import SidebarViewGod from "../sidebar/SideBarViewGod";
import AdminStudentsAndClassesDetailView from "../admin/schoolClasses/AdminStudentsAndClassesDetailView";

const mql = window.matchMedia(`(min-width: 800px)`);

export interface IDashboardProps {
  schools: any;
  user: any;
  sidebar: boolean;
  getStudentState();
  toggleSidebar(show: boolean);
}

export interface IDashboardViewState {}
const reload = () => window.location.reload();
class DashboardView extends React.Component<
  IDashboardProps,
  IDashboardViewState
> {
  constructor(props) {
    super(props);

    this.props.toggleSidebar(false);

    this.mediaQueryChanged = this.mediaQueryChanged.bind(this);
    this.onSetSidebarDocked = this.onSetSidebarDocked.bind(this);
  }

  componentWillMount() {
    mql.addListener(this.mediaQueryChanged);
  }

  componentWillUnmount() {
    mql.removeListener(this.mediaQueryChanged);
  }

  onSetSidebarDocked() {
    this.props.toggleSidebar(!this.props.sidebar);
  }

  mediaQueryChanged() {
    this.props.toggleSidebar(!this.props.sidebar);
  }

  render() {
    if (_.isEmpty(this.props.user)) {
      return <SplashScreenView />;
    }
    if (
      window.location.hash.toLowerCase().indexOf("innleveringercourseview") >
        0 ||
      window.location.hash.toLowerCase().indexOf("config") > 0
    ) {
      return (
        <HashRouter>
          {this.props.user.role == "teacher" && (
            <div id="content-view" className="content-view">
              <div>
                <Statusbar></Statusbar>
                <Switch>
                  <Route path="/config" component={Config} />
                  <Route
                    path="/innleveringer/student/:id"
                    component={StudentTaskView}
                  />
                  <Route
                    path="/innleveringer/:id"
                    component={InnleveringStatusView}
                  />
                  <Route path="/innleveringer" component={InnleveringerView} />
                  <Route
                    path="/innleveringerteams/:id"
                    component={InnleveringStatusViewTeams}
                  />
                  <Route
                    path="/InnleveringerCourseView/:id"
                    component={InnleveringerCourseView}
                  />
                  <Route
                    path="/meldinger/:id/:mode"
                    component={MessageEditView}
                  />
                  <Route path="/meldinger/:id" component={MessageEditView} />
                  <Route path="/meldinger" component={MeldingerView} />
                  <Route
                    path="/timeplan/:id"
                    component={ScheduleElementDetailView}
                  />
                  <Route path="/timeplan" component={TimeplanView} />
                  <Route
                    path="/ukeplan/:id/:mode"
                    component={PlanelementEditView}
                  />

                  <Route
                    path="/ukeplan/getstudent"
                    component={PreviewUkeplanView}
                  />

                  <Route path="/ukeplan" component={UkeplanView} />
                  <Route
                    path="/task/distribute/:id"
                    component={TaskDistributeView}
                  />
                  <Route
                    path="/task/:id/:mode"
                    component={TaskElementEditView}
                  />

                  <Route path="/portefolje" component={PortfolioView} />
                  <Route
                    path="/admin/elever_og_fag/fag/:id"
                    component={AdminStudentsAndCoursesDetailView}
                  />
                  <Route
                    path="/admin/elever_og_fag/klasse/:id"
                    component={AdminStudentsAndClassesDetailView}
                  />
                  <Route
                    path="/admin/elever_og_fag"
                    component={AdminStudentsAndCoursesView}
                  />
                  <Route
                    path="/admin/dine_klasser"
                    component={AdminSchoolClassesView}
                  />
                  <Route path="/admin/dine_fag" component={AdminCoursesView} />
                  <Route path="/admin" component={AdminView} />

                  <Route path="/quiz/grade/:id" component={QuizGrader} />
                  <Route path="/quiz/take/:id" component={TakeQuizView} />
                  <Route path="/quiz/:id/:mode" component={QuizEditView} />
                  <Route path="/quiz/:id" component={QuizEditView} />
                  <Route path="/quiz" component={MyQuizzesTeacherView} />
                  <Route path="/iup" component={IupView} />
                  <Route path="/graph" component={testing} />
                </Switch>
              </div>
            </div>
          )}
        </HashRouter>
      );
    }
    if (this.props.user.role == "teacher")
      return (
        <HashRouter>
          {this.props.user.role == "teacher" && (
            <Sidebar
              sidebar={<SidebarView />}
              onSetDocked={this.onSetSidebarDocked}
              docked={!this.props.sidebar}
            >
              <div className="sidebar-toggle">
                <div onClick={this.onSetSidebarDocked}>
                  {this.props.sidebar ? "Vis" : "Skjul"} meny
                  
                </div>
              </div>
              <div id="content-view" className="content-view">

                <div>
                  <Statusbar></Statusbar>
                  <Switch>
                  <Route path="/godmode" component={Impersonator} />
                    <Route path="/discovery" component={Discovery} />
                    <Route path="/config" component={Config} />
                    <Route path="/auth" component={Auth} />
                    <Route
                      path="/innleveringer/student/:id"
                      component={StudentTaskView}
                    />
                    <Route
                      path="/innleveringer/:id"
                      component={InnleveringStatusView}
                    />
                    <Route
                      path="/innleveringer"
                      component={InnleveringerView}
                    />
                    <Route
                      path="/innleveringerteams/:id"
                      component={InnleveringStatusViewTeams}
                    />
                    <Route
                      path="/InnleveringerCourseView/:id"
                      component={InnleveringerCourseView}
                    />
                    <Route
                      path="/meldinger/:id/:mode"
                      component={MessageEditView}
                    />
                    <Route path="/meldinger/:id" component={MessageEditView} />
                    <Route path="/meldinger" component={MeldingerView} />
                    <Route
                      path="/timeplan/:id"
                      component={ScheduleElementDetailView}
                    />
                    <Route path="/timeplan" component={TimeplanView} />
                    <Route
                      path="/ukeplan/:id/:mode"
                      component={PlanelementEditView}
                    />

                    <Route
                      path="/ukeplan/getstudent"
                      component={PreviewUkeplanView}
                    />

                    <Route path="/ukeplan" component={UkeplanView} />
                    <Route
                      path="/task/distribute/:id"
                      component={TaskDistributeView}
                    />
                    <Route
                      path="/task/:id/:mode"
                      component={TaskElementEditView}
                    />

                    <Route path="/portefolje" component={PortfolioView} />
                    
                    <Route
                    path="/admin/elever_og_fag/fag/:id"
                    component={AdminStudentsAndCoursesDetailView}
                    />
                    <Route
                      path="/admin/elever_og_fag/klasse/:id"
                      component={AdminStudentsAndClassesDetailView}
                    />
                    <Route
                      path="/admin/elever_og_fag"
                      component={AdminStudentsAndCoursesView}
                    />
                    <Route
                      path="/admin/dine_klasser"
                      component={AdminSchoolClassesView}
                    />
                    <Route
                      path="/admin/dine_fag"
                      component={AdminCoursesView}
                    />
                    <Route path="/admin" component={AdminView} />

                    <Route path="/quiz/grade/:id" component={QuizGrader} />
                    <Route path="/quiz/take/:id" component={TakeQuizView} />
                    <Route path="/quiz/:id/:mode" component={QuizEditView} />
                    <Route path="/quiz/:id" component={QuizEditView} />
                    <Route path="/quiz" component={MyQuizzesTeacherView} />
                    <Route path="/iup" component={IupView} />
                    <Route path="/graph" component={testing} />
                  </Switch>
                </div>
              </div>
            </Sidebar>
          )}
        </HashRouter>
      );
    if (this.props.user.role == "student")
      return (
        <HashRouter>
          <Sidebar
            sidebar={<SidebarViewStudent />}
            onSetDocked={this.onSetSidebarDocked}
            docked={!this.props.sidebar}
          >
            <div className="sidebar-toggle">
              <div onClick={this.onSetSidebarDocked}>
                {this.props.sidebar ? "Vis" : "Skjul"} meny
              </div>
            </div>
            <div id="content-view" className="content-view">
              <div>
                <Switch>
                  <Route
                    path="/innleveringer/student/:id"
                    component={StudentTaskView}
                  />
                  <Route
                    path="/innleveringer/:id"
                    component={InnleveringStatusView}
                  />
                  <Route
                    path="/innleveringerstudent"
                    component={InnleveringerStudentView}
                  />
                  <Route path="/meldinger/:id" component={MessageEditView} />
                  <Route path="/meldinger" component={MeldingerView} />
                  <Route
                    path="/timeplan/:id"
                    component={ScheduleElementDetailView}
                  />
                  <Route
                    path="/timeplanstudent"
                    component={TimeplanStudentView}
                  />
                  <Route
                    path="/ukeplan/:id/:mode"
                    component={PlanelementEditView}
                  />

                  <Route
                    path="/ukeplan/getstudent"
                    component={PreviewUkeplanView}
                  />

                  <Route
                    path="/ukeplanstudent"
                    component={UkeplanStudentView}
                  />
                  <Route
                    path="/task/distribute/:id"
                    component={TaskDistributeView}
                  />
                  <Route
                    path="/taskstudent/:id"
                    component={StudentTaskDeliver}
                  />

                  <Route
                    path="/portefoljestudent"
                    component={PortfolioStudentView}
                  />
                  <Route
                    path="/admin/elever_og_fag/fag/:id"
                    component={AdminStudentsAndCoursesDetailView}
                  />
                  <Route
                    path="/admin/elever_og_fag"
                    component={AdminStudentsAndCoursesView}
                  />
                  <Route
                    path="/admin/dine_klasser"
                    component={AdminSchoolClassesView}
                  />
                  <Route path="/admin/dine_fag" component={AdminCoursesView} />
                  <Route path="/admin" component={AdminView} />

                  <Route
                    path="/quizstudent/grade/:id"
                    component={StudentQuizGrading}
                  />
                  <Route path="/quiz/take/:id" component={TakeQuizView} />
                  <Route path="/quiz/:id/:mode" component={QuizEditView} />
                  <Route path="/quiz/:id" component={QuizEditView} />
                  <Route path="/quiz" component={MyQuizzesTeacherView} />
                  <Route path="/iup" component={IupView} />
                  <Route path="/graph" component={testing} />
                  <Route
                    path="/quizstudent/attempt/:id"
                    component={StudentQuizAttemptView}
                  />
                  <Route path="/quizstudent" component={StudentQuizOverview} />
                </Switch>
              </div>
            </div>
          </Sidebar>
        </HashRouter>
      );
      if (this.props.user.role == "god")
      return (
        <HashRouter>
          <Sidebar
            sidebar={<SidebarViewGod />}
            onSetDocked={this.onSetSidebarDocked}
            docked={!this.props.sidebar}
          >
            <div className="sidebar-toggle">
              <div onClick={this.onSetSidebarDocked}>
                {this.props.sidebar ? "Vis" : "Skjul"} meny
              </div>
            </div>
            <div id="content-view" className="content-view">
              <div>
                <Switch>
                  <Route
                    path="/innleveringer/student/:id"
                    component={StudentTaskView}
                  />
                  <Route
                    path="/innleveringer/:id"
                    component={InnleveringStatusView}
                  />
                  <Route
                    path="/innleveringerstudent"
                    component={InnleveringerStudentView}
                  />
                  <Route path="/meldinger/:id" component={MessageEditView} />
                  <Route path="/meldinger" component={MeldingerView} />
                  <Route
                    path="/timeplan/:id"
                    component={ScheduleElementDetailView}
                  />
                  <Route
                    path="/timeplanstudent"
                    component={TimeplanStudentView}
                  />
                  <Route
                    path="/ukeplan/:id/:mode"
                    component={PlanelementEditView}
                  />

                  <Route
                    path="/ukeplan/getstudent"
                    component={PreviewUkeplanView}
                  />

                  <Route
                    path="/ukeplanstudent"
                    component={UkeplanStudentView}
                  />
                  <Route
                    path="/task/distribute/:id"
                    component={TaskDistributeView}
                  />
                  <Route
                    path="/taskstudent/:id"
                    component={StudentTaskDeliver}
                  />

                  <Route
                    path="/portefoljestudent"
                    component={PortfolioStudentView}
                  />
                  <Route
                    path="/admin/elever_og_fag/fag/:id"
                    component={AdminStudentsAndCoursesDetailView}
                  />
                  <Route
                    path="/admin/elever_og_fag/klasse/:id"
                    component={AdminStudentsAndClassesDetailView}
                  />
                  <Route
                    path="/admin/elever_og_fag"
                    component={AdminStudentsAndCoursesView}
                  />
                  <Route
                    path="/admin/dine_klasser"
                    component={AdminSchoolClassesView}
                  />
                  <Route path="/admin/dine_fag" component={AdminCoursesView} />
                  <Route path="/admin" component={AdminView} />

                  <Route
                    path="/quizstudent/grade/:id"
                    component={StudentQuizGrading}
                  />
                  <Route path="/quiz/take/:id" component={TakeQuizView} />
                  <Route path="/quiz/:id/:mode" component={QuizEditView} />
                  <Route path="/quiz/:id" component={QuizEditView} />
                  <Route path="/quiz" component={MyQuizzesTeacherView} />
                  <Route path="/iup" component={IupView} />
                  <Route path="/graph" component={testing} />
                  <Route
                    path="/quizstudent/attempt/:id"
                    component={StudentQuizAttemptView}
                  />
                  <Route path="/quizstudent" component={StudentQuizOverview} />
               
                  <Route path="/godmode" component={Impersonator} />
                    <Route path="/discovery" component={Discovery} />
                    <Route path="/config" component={Config} />
                    <Route path="/auth" component={Auth} />
                    <Route
                      path="/innleveringer/student/:id"
                      component={StudentTaskView}
                    />
                    <Route
                      path="/innleveringer/:id"
                      component={InnleveringStatusView}
                    />
                    <Route
                      path="/innleveringer"
                      component={InnleveringerView}
                    />
                    <Route
                      path="/innleveringerteams/:id"
                      component={InnleveringStatusViewTeams}
                    />
                    <Route
                      path="/InnleveringerCourseView/:id"
                      component={InnleveringerCourseView}
                    />
                    <Route
                      path="/meldinger/:id/:mode"
                      component={MessageEditView}
                    />
                    <Route path="/meldinger/:id" component={MessageEditView} />
                    <Route path="/meldinger" component={MeldingerView} />
                    <Route
                      path="/timeplan/:id"
                      component={ScheduleElementDetailView}
                    />
                    <Route path="/timeplan" component={TimeplanView} />
                    <Route
                      path="/ukeplan/:id/:mode"
                      component={PlanelementEditView}
                    />

                    <Route
                      path="/ukeplan/getstudent"
                      component={PreviewUkeplanView}
                    />

                    <Route path="/ukeplan" component={UkeplanView} />
                    <Route
                      path="/task/distribute/:id"
                      component={TaskDistributeView}
                    />
                    <Route
                      path="/task/:id/:mode"
                      component={TaskElementEditView}
                    />

                    <Route path="/portefolje" component={PortfolioView} />
                    <Route
                      path="/admin/elever_og_fag/:id"
                      component={AdminStudentsAndCoursesDetailView}
                    />
                    <Route
                      path="/admin/elever_og_fag"
                      component={AdminStudentsAndCoursesView}
                    />
                    <Route
                      path="/admin/dine_klasser"
                      component={AdminSchoolClassesView}
                    />
                    <Route
                      path="/admin/dine_fag"
                      component={AdminCoursesView}
                    />
                    <Route path="/admin" component={AdminView} />

                    <Route path="/quiz/grade/:id" component={QuizGrader} />
                    <Route path="/quiz/take/:id" component={TakeQuizView} />
                    <Route path="/quiz/:id/:mode" component={QuizEditView} />
                    <Route path="/quiz/:id" component={QuizEditView} />
                    <Route path="/quiz" component={MyQuizzesTeacherView} />
                    <Route path="/iup" component={IupView} />
                    <Route path="/graph" component={testing} />
                  </Switch>
               
              </div>
            </div>
          </Sidebar>
        </HashRouter>
      );
  }
}

function mapStateToProps(state) {
  return {
    sidebar: state.sidebar,
    schools: state.teacher.schools,
    user: state.teacher.user,
  };
}

export default connect(mapStateToProps, {
  toggleSidebar,
  getStudentState,
})(DashboardView);
