import * as React from "react";
import * as moment from "moment";
import * as _ from "lodash";
import { connect } from "react-redux";
moment.locale("nb");
import ReactHtmlParser, {
  processNodes,
  convertNodeToElement,
  htmlparser2,
} from "react-html-parser";
import { ActionButton, FontIcon, Icon, IconButton } from "office-ui-fabric-react";
import { Checkbox } from "office-ui-fabric-react/lib/Checkbox";
import ReactTooltip from 'react-tooltip';
import FilesModal from "../../ukeplan/FilesModal";
import ImmersiveReader from "../../shared/ImmersiveReader";
import '../../QuillStyles.scss';
import { capitalizeFirstLetter } from "../../../utils/HelperFunctions";
import { getAssignment } from "../../../utils/GraphEduService";
import UkeplanElementModal from "../../ukeplan/UkeplanElementModal";
import { Course } from "../../../models/Course";
export interface IStudentTaskElementViewProps {
  taskelement: any;
  history: any;
  compactCardView: boolean;
  course: Course;
}
export interface IStudentTaskElementViewState {
  showfilesmodal: boolean;
  myhtml: any;
  showContentModal: boolean;
}

class StudentTaskElementView extends React.Component<
  IStudentTaskElementViewProps,
  IStudentTaskElementViewState
> {
  constructor(props) {
    super(props);
    this.state = { showfilesmodal: false, myhtml: false, showContentModal: false };
  }

  getEditButtonStatus = (): string => {
    if (this.props.taskelement.DeliveryStatus == 1 && (moment().isBefore(moment(this.props.taskelement.Deadline)) || this.props.taskelement.WsTask.DeliverAfterDeadline)) {
      return "Besvar oppgave"
    }
    else if (this.props.taskelement.WsTask.KeepOpen && this.props.taskelement.DeliveryStatus != 1 && this.props.taskelement.DeliveryStatus != 2 && (moment().isBefore(moment(this.props.taskelement.Deadline)))) {
      return "Lever ny versjon"
    }
    else {
      return "Se oppgave"
    }
  }

  render() {
    console.log('studenttask', this.props.taskelement.TeamsTask);
    if (this.props.taskelement.WsTask.TeamsTask && this.props.taskelement.WsTask.TeamsTask.length > 0 && false) {
      return (<></>)
    }
    let classname = "";
    if (
      this.props.taskelement.DeliveryStatus != 1 &&
      !(this.props.taskelement.DeliveryStatus == 4 && this.props.taskelement.GradingStatus == 1) && !(this.props.taskelement.WsTask.TeamsDeepLink)
    ) {
      classname = "dimmed";
      console.log('dimming');
    }

    return (
      <li className={`list-group-item ${classname}`} ref={input => {
        if (!this.state.myhtml) {
          this.setState({ myhtml: input });
        }
      }}>

        <div className="plan-kort-header">
          <h3 style={{ flex: 1 }}>
            <i className="ms-Icon ms-Icon--CompletedSolid" aria-hidden="true"></i>{" "}
            {this.props.taskelement.WsTask.Name}
          </h3>
          
          {this.props.taskelement.DeliveryStatus != 1 &&
            !(this.props.taskelement.DeliveryStatus == 4 && this.props.taskelement.GradingStatus == 1) && !(this.props.taskelement.WsTask.TeamsDeepLink) && (
              <div style={{ marginRight: 10 }}>
                <Checkbox checked={true} label="Fullført!" />
              </div>
            )}
          {(this.props.taskelement.WsTask.TeamsTask || this.props.taskelement.WsTask.TeamsDeepLink) && <div>
              <FontIcon data-tip data-for={`teamstask-${this.props.taskelement.Id}`} iconName="TeamsLogo" style={{fontSize: 20, marginRight: "10px", color: "#4E5FBF", cursor: "default"}}/>
              <ReactTooltip id={`teamstask-${this.props.taskelement.Id}`}>Denne oppgaven er knyttet til Teams</ReactTooltip>
            </div>
          }
          {this.props.taskelement.WsTask.KeepOpen && <span>
            <Icon data-tip data-for="MultipleAttempts" iconName="RepeatAll" style={{ fontSize: 18, marginRight: 10, color: "rgb(43, 175, 43)", cursor: "default" }}></Icon>
            <ReactTooltip id="MultipleAttempts">Ingen begrensninger på antall ganger man kan levere</ReactTooltip>
          </span>
          }
          {(this.props.taskelement.WsTask.DeliverAfterDeadline) && <span>
            <Icon data-tip data-for="DeliverAfterDeadline" iconName="TimeEntry" style={{ fontSize: 18, marginRight: 10, color: "rgb(43, 175, 43)", cursor: "default" }}></Icon>
            <ReactTooltip id="DeliverAfterDeadline">
              <span style={{ textAlign: "center" }}>
                <div>Åpent for å levere etter fristen</div>
                <p>Besvarelser levert etter fristen vil bli markert som forsinket</p>
              </span>
            </ReactTooltip>
          </span>
          }
          {this.state.myhtml && (
            <ImmersiveReader htmlcontent={this.state.myhtml}></ImmersiveReader>
          )}
          <div>
            <IconButton
              data-tip
              data-for={`tooltip-task-${this.props.taskelement.Id}`}
              iconProps={{ iconName: "MiniExpand", styles: {root: {fontSize: 18}}}} 
              // style={{fontSize: 20, margin: "0 10px", cursor: "pointer"}}
              onClick={() => this.setState({showContentModal: true})}
              style={{margin: "0 5px", alignItems: "start"}}
            ></IconButton>
            <ReactTooltip id={`tooltip-task-${this.props.taskelement.Id}`}>
              Åpne i forstørret vindu
            </ReactTooltip>
            <UkeplanElementModal showModal={this.state.showContentModal} onDismiss={() => this.setState({showContentModal: false})} task={this.props.taskelement.WsTask} course={this.props.course}></UkeplanElementModal>
          </div>
        </div>

        <dl>
          {this.props.taskelement.WsTask.ShowKmpGoals &&
            this.props.taskelement.WsTask.GrepCodeList.length > 0 && (
              <div>
                <dt>Kompetansemål</dt>
                <dd>
                  {this.props.taskelement.WsTask.GrepCodeList.length > 1 && this.props.compactCardView ? <span>{this.props.taskelement.WsTask.GrepCodeList.length} Kompetansemål</span> :
                    <ul id="kmp-2-@this.props.taskelement.Id" className="grep-list">
                      {this.props.taskelement.WsTask.GrepCodeList.map((element) => {
                        return (
                          <li>
                            <a href={element.DetailUrl} target="_blank">
                              {element.GrepTekst}
                            </a>
                          </li>
                        );
                      })}
                  </ul>}
                </dd>
              </div>
            )}
        </dl>
        {console.log(this.props.taskelement)}
        {this.props.taskelement.WsTask.Goal.length > 0 && (
          <dl>
            <dt>Egendefinerte mål</dt>
            <dd>
              <span className={`remove-para-margin list-group-unordered ${this.props.compactCardView ? "hideOverflow" : ""}`}
                dangerouslySetInnerHTML={{
                  __html: this.props.taskelement.WsTask.Goal,
                }}
              ></span>
            </dd>
          </dl>
        )}
        {this.props.taskelement.WsTask.EvaluationText.length > 0 ||
          (this.props.taskelement.WsTask.EvaluationTextOther.length > 0 && (
            <dl>
              <dt>Evaluering</dt>
              <dd className="scheduleEvaluation">
                <ul>
                  {this.props.taskelement.WsTask.EvalElements.map((element) => {
                    <li>{element}</li>;
                  })}
                </ul>
              </dd>
            </dl>
          ))}

        <dl>
          <dt>Beskrivelse</dt>
          <dd>
            <span className={`remove-para-margin list-group-unordered ${this.props.compactCardView ? "hideOverflow" : ""}`}
              dangerouslySetInnerHTML={{
                __html: this.props.taskelement.WsTask.TextRaw,
              }}
            ></span>
          </dd>
        </dl>
        {this.props.taskelement.WsTask.Link.length > 0 && (
          <div>
            <dt>Lenke</dt>
            <dd>
              <a target="_blank" href={this.props.taskelement.WsTask.Link}>
                {this.props.taskelement.WsTask.Link}
              </a>
            </dd>
          </div>
        )}

        {this.props.taskelement.WsTask.Starttime &&
          <div>
            <dt> Starttidspunkt</dt>
            <dd>{capitalizeFirstLetter(moment(this.props.taskelement.WsTask.Starttime).format("dddd").toString())} {moment(this.props.taskelement.WsTask.Starttime).format("DD.MM.YYYY HH:mm")}</dd>
          </div>
        }

        <div>
          {(this.props.taskelement.WsTask.HideDeadline != true) && <span>
            <dt> Frist</dt>
            <dd>{capitalizeFirstLetter(moment(this.props.taskelement.WsTask.Deadline).format("dddd").toString())} {this.props.taskelement.WsTask.Deadlinetekst}</dd>
          </span>
          }

          {(!this.props.taskelement.WsTask.Starttime || (this.props.taskelement.WsTask.Starttime && moment(this.props.taskelement.WsTask.Starttime).valueOf() <= moment().valueOf())) &&
            <div className="oppgaveKnapper">
              {this.props.taskelement.WsTask.HasFiles && (
                <ActionButton
                  iconProps={{ iconName: "Attach" }}
                  style={{ flex: 1 }}
                  onClick={() => {
                    // getPlanElementFiles(this.props.planelement.Id);
                    this.setState({ showfilesmodal: true });
                  }}
                >
                  Se filer
                </ActionButton>
                //    <i
                //    className="ms-Icon ms-Icon--Attach x-hidden-focus fa-2x"
                //    title="Se filer"
                //    aria-hidden="true"
                //    onClick={()=>{
                //      this.setState({showfilesmodal:true});
                //    }}
                //  ></i>
              )}
              {
                (!this.props.taskelement.WsTask.TeamsTask && !this.props.taskelement.WsTask.TeamsDeepLink) &&
                <div>
                  <ActionButton
                    text={this.getEditButtonStatus()}
                    iconProps={this.getEditButtonStatus() == "Se oppgave" ? { iconName: "RedEye" } : { iconName: "Edit" }}
                    className="ms-font-l"
                    onClick={() =>
                      this.props.history.push(
                        `/taskstudent/${this.props.taskelement.Id}`
                      )
                    }
                  />
                </div>
              }
              {(this.props.taskelement.WsTask.TeamsTask || this.props.taskelement.WsTask.TeamsDeepLink) && (
                <ActionButton
                  text="Åpne i Teams"
                  style={{ textAlign: "left" }}
                  iconProps={{ iconName: "OpenInNewTab" }}
                  onClick={async () => {

                    if (this.props.taskelement.WsTask.TeamsDeepLink) {
                      window.open(this.props.taskelement.WsTask.TeamsDeepLink, "_blank", "noreferrer");
                    }
                    else if (this.props.taskelement.WsTask.TeamsTask) {
                      let tid = this.props.taskelement.WsTask.TeamsTask.split("|")[0];
                      let assid = this.props.taskelement.WsTask.TeamsTask.split("|")[1];
  
                      let assignment = await getAssignment(tid, assid);
                      window.open(assignment.webUrl, "_blank", "noreferrer");
                    }
                  }}
                />
              )}

            </div>
          }
          {
            this.state.showfilesmodal && (
              <FilesModal
                mode={2}
                id={this.props.taskelement.WsTask.Id}
                closemodal={() => {
                  this.setState({ showfilesmodal: false });
                }}
              />
            )
            // <FilesModal  />
          }
        </div>
      </li>
    );
  }
}
function mapStateToProps(state) {
  return {
    compactCardView: state.ukeplanelements.CompactCardView
  };
}
export default connect(mapStateToProps, {})(StudentTaskElementView);
