import * as React from "react";
import { Panel, PanelType } from "office-ui-fabric-react/lib/Panel";
import axios from "axios";
import { GraphFileBrowser } from "@microsoft/file-browser";
import { ActionButton } from "office-ui-fabric-react/lib/Button";
import {
  getTokenSilent,
  MSAL_CONFIG,
  getTokenSilentmsal,
} from "../../../msalConfig";
// import { MSAL_INSTANCE, MSAL_INSTANCEOD, myMSALObj } from "../../../index";
import * as moment from "moment";
import { IOneDriveFileData } from "./IOneDrivePickerData";
import {
  Providers,
  TeamsHelper,
  TeamsProvider,
  MsalProvider,
} from "@microsoft/mgt";
import "./GraphFileBrowser.scss";
const jwtDecode = require("jwt-decode");

export interface ISelectedItem {
  FileName: string;
  OriginalDocumentID: string;
  GraphParentDriveId: string;
  GraphParentItemId: string;
  GraphItemId: string;
}

export interface IOneDrivePickerProps {
  onClose: (selecteditems: ISelectedItem) => void;
}

export interface IOneDrivePickerState {
  showOneDrive: boolean;
  thetoken:string;
}

export default class OneDrivePicker extends React.Component<
  IOneDrivePickerProps,
  IOneDrivePickerState
> {
  constructor(props) {
    super(props);
    this.state = { showOneDrive: false,thetoken:'' };
  }

  /****************************
            Authentication
     ****************************/

   getAuthenticationToken = (): Promise<string> => {
    // const token =  Providers.globalProvider.getAccessToken().then((tok) => {

    // });
    // if (MSAL_INSTANCE.getAccount()) {
    //   getTokenSilent(MSAL_INSTANCE)
    //     .then((response) => {
    //       console.log(response);
    //     })
    //     .catch((error) => error && console.error(error));
    // } else {
    //   console.log("else");
    //   MSAL_INSTANCE.loginPopup({
    //     scopes: ["user.read"],
    //   });
    // }
    // const token = Providers.globalProvider.getAccessToken().then((tok) => {});
    // console.log("aaa");
    // const tokenExpirationTime = jwtDecode(localStorage.getItem("adal.idtoken"))
    //   .exp;
    // const expiryThreshold = moment().add(20, "minutes").unix();

    //if (tokenExpirationTime > expiryThreshold) {
      if (true) {
      return new Promise<string>((resolve) =>
        resolve(localStorage.getItem("adal.idtoken"))
      );
    } 
    // else {
    //   return new Promise<string>((resolve) => {
    //     // getTokenSilentmsal(myMSALObj).then((tok)=>{
    //     //   console.log('i am back',tok);
    //     //   resolve(tok);
    //     // })
    //     // Providers.globalProvider.getAccessToken().then((ur) => {
    //     //     localStorage.setItem("adal.idtoken", ur);
    //     //     console.log('refreshed');
    //     //     resolve(ur);
    //     // })
    //   });

      //Providers.globalProvider.login();
    // }
    // return new Promise<string>((resolve, reject) => {
    //   console.log("expired");
    //   MSAL_INSTANCEOD.loginPopup({}).then((ty) => {
    //     console.log("ty", ty);

    //     MSAL_INSTANCEOD.acquireTokenSilent({
    //         scopes: [".default"],
    //       }).then((resp) => {
    //         console.log("resp", resp);
    //         resolve(resp.accessToken);
    //       });

    //   });
    // });

    // return new Promise<string>((resolve, reject) => {
    //   console.log("expired");
    //   getTokenSilent(MSAL_INSTANCE)
    //     .then((response) => {
    //       resolve(localStorage.getItem("adal.idtoken") as string);
    //     })
    //     .catch((error) => {
    //       console.error(error);
    //       reject();
    //     });
    // });
  };

  /****************************
             Event handlers
     ****************************/

  onModalOpen = () => {
    this.setState({ showOneDrive: true });
  };

  onModalClose = () => {
    this.setState({ showOneDrive: false });
  };

  onGraphFileBrowserSuccess = (keys) => {
    let token = Providers.globalProvider.getAccessToken().then((thetoken) => {
      console.log('tokenrefresh',thetoken);
      for (let i = 0; i < keys.length; i++) {
        let currentItem = keys[i];
        let someFunction = function (c) {
          return new Promise(function (resolve, reject) {

            // do a thing, possibly async, then…
            let url =
              "https://graph.microsoft.com/v1.0/drives/" +
              c.driveItem_203[1] +
              "/items/" +
              c.driveItem_203[2];
            const request = axios.get<IOneDriveFileData>(url, {
              headers: {
                Authorization: thetoken,//localStorage.getItem("adal.idtoken"),
              },
            });
            request.then((response) => {
              console.log("onedriveResp", response);
              response.data.zid = response.data.eTag.split(",")[0].slice(2, -1);
              let r: ISelectedItem = {
                FileName: response.data.name,
                OriginalDocumentID: response.data.eTag.split(",")[0].slice(2, -1),
                GraphParentDriveId: response.data.parentReference.driveId,
                GraphParentItemId: response.data.parentReference.id,
                GraphItemId: response.data.id,
              };
              resolve(r);
            });
          });
        };

        someFunction(currentItem).then((data: ISelectedItem) => {
          this.setState({ showOneDrive: false });
          this.props.onClose(data);
        });
      }
    }

    );

  };

  /****************************
             Render methods
     ****************************/

  public render() {
    // console.log('expiry2',this.getAuthenticationToken());
    
    return (
      <>
        <ActionButton
          iconProps={{ iconName: "OneDrive" }}
          data-toggle="modal"
          data-target="#myModalGrepQuiz"
          onClick={this.onModalOpen}
        >
          Legg ved filer fra OneDrive
        </ActionButton>

        <Panel
          headerText="Velg filer"
          isOpen={this.state.showOneDrive}
          type={PanelType.largeFixed}
          closeButtonAriaLabel="Lukk"
          onDismiss={this.onModalClose}
        >
          <div style={{ height: "80vh" }}>
            <GraphFileBrowser
              //getAuthenticationToken={this.getAuthenticationToken}
              getAuthenticationToken={()=>Providers.globalProvider.getAccessToken() }
              onSuccess={this.onGraphFileBrowserSuccess}
              onCancel={this.onModalClose}
            />
          </div>
        </Panel>
      </>
    );
  }
}
