import * as React from 'react';
import { setstatus } from "../../actions/Action_Teacher";
import { connect } from "react-redux";
import { store } from 'react-notifications-component';
import { Spinner,SpinnerSize } from 'office-ui-fabric-react';

export interface IStatusBarProps {
    setstatus(statedata:any);
    status:any;
}

export  class StatusBar extends React.Component<IStatusBarProps> {
    componentWillReceiveProps(nextProps:any)
    {
        if(nextProps.status)
        {
            if(!nextProps.status.working && !nextProps.status.error)
            {
                store.addNotification({
                    title: "Zokrates sier!",
                    message: nextProps.status.text,
                    type: "success",
                    insert: "top",
                    container: "top-right",
                    animationIn: ["animated", "fadeIn"],
                    animationOut: ["animated", "fadeOut"],
                    dismiss: {
                      duration: 2000,
                      onScreen: true
                    }
                  });
               
            }
            if(!nextProps.status.working && nextProps.status.error)
            {
                store.addNotification({
                    title: "Zokrates sier!",
                    message: nextProps.status.text,
                    type: "danger",
                    insert: "top",
                    container: "top-right",
                    animationIn: ["animated", "fadeIn"],
                    animationOut: ["animated", "fadeOut"],
                    dismiss: {
                      duration: 2000,
                      onScreen: true
                    }
                  });
               
            }
        }
       
    }
  public render() {
      
    // if(this.props.status && this.props.status.working)
    // {
    //     return (
    //         <Spinner size={SpinnerSize.large}  ariaLive="assertive" />
    //       );
    // }
    return (
        <div>
          
             
           
        </div>
      );
   
  }
}

function mapStateToProps(state) {
    return {
     status:state.teacher.status
    };
  }
  
  export default connect(mapStateToProps, {

    setstatus
  })(StatusBar);
