import * as React from 'react';
import {connect} from 'react-redux';
import {getTeacherState} from "../../actions/Action_Teacher";
import HeaderView from "../view_containers/HeaderView";
import AdminCategoryCard from "./AdminCategoryCard";
import './Admin.scss';


export interface IAdminViewProps {
    history:any;
}


class AdminView extends React.Component<IAdminViewProps> {

    render(){
        return(
            <div className="ms-Grid container main">
                <HeaderView
                    title="Administrasjon"
                    description="På denne siden setter du enkelt opp tilretteleggingsgrupper i dine fag"
                    iconName="Settings"
                />

                <div className="ms-Grid">
                    <AdminCategoryCard
                        iconName="PlayerSettings"
                        title="Elever, fag og klasser"
                        description="Sett opp tilretteleggingsgrupper i dine fag"
                        path="/admin/elever_og_fag/"
                    />
                    <AdminCategoryCard
                        iconName="Checklist"
                        title="Velg dine fag"
                        description="Hent fagene du underviser i"
                        path="/admin/dine_fag/"
                    />
                    <AdminCategoryCard
                        iconName="Family"
                        title="Velg dine klasser"
                        description="Hent dine klasser"
                        path="/admin/dine_klasser/"
                    />
                </div>
            </div>
        )
    }
}

function mapStateToProps(state) {

    return {
        subscribedSchoolClasses: state.teacher.subscribedSchoolClasses
    };
}

export default connect(mapStateToProps, {getTeacherState})(AdminView);