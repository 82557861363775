import * as React from "react";
import { connect } from "react-redux";
import { loadsubmission, getAssignmentPlagInfo, submitResource, loadfulltask, getPreview } from "../../actions/Action_Graph";
import { getTeacherState } from "../../actions/Action_Teacher";
import {
    Panel,
    PanelType,
    ActionButton,
    PrimaryButton,
    Icon,
    SearchBox,
    ActivityItem,
    Link,
    mergeStyleSets,
    Stack,
    mergeStyles,
    TooltipHost,
    ITooltipHostStyles,
    IIconProps
} from "office-ui-fabric-react";
import {
    DetailsList,
    DetailsListLayoutMode,
    Selection,
    IColumn,
    SelectionMode,
} from "office-ui-fabric-react/lib/DetailsList";
import { MarqueeSelection } from "office-ui-fabric-react/lib/MarqueeSelection";
import { IconButton } from "@material-ui/core";
import PlagStatus from "../innleveringer/PlagStatus";
import EvalFormGraph from "./EvalFormGraph";
import RubricDisplay from "./RubricDisplay";
import { File } from '@microsoft/mgt-react';
// import { useId } from '@fluentui/react-hooks';
export interface ITeamssubmissionProps {
    loadsubmission(classid: string,
        assignmentid: string,
        submission: any);
    loadfulltask(
        classid: string,
        assignmentid: string,
    )
    getAssignmentPlagInfo(classid: string, assignmentid: string);
    submitResource(
        classid: string,
        assignmentid: string,
        resource: any,
        resheader: any
    );
    getPreview(
        url: string,

    )
    submission;
    studenttask;
    task: any;
}
export interface ITeamssubmissionState {
    submission: any;
    loaded: boolean;
    rubric: any;
    outcome: any;
    previewurl: any;
}
function mapStateToProps(state) {
    console.log('teammapper', state);
    //console.log(state.GraphReducer.classAssignments);
    let mappedcourses = {};

    return {
        submission: {}
    };
}

interface IActivityItemLi {
    url: string;
    getPreview(
        url: string,

    )
}
const ActivityItemLi = (props: IActivityItemLi) => {

    return (
        <li >

            <File
                onClick={() => {
                    props.getPreview(props.url).then((preview) => {
                        console.log(preview.payload.getUrl);
                    })
                }}
                fileQuery={props.url}></File>

        </li>)

}
const stackItemStyles = mergeStyles({
    alignItems: 'flex-start',

    display: 'flex',
    height: 50,
    justifyContent: 'flex-start',
    width: 50,
});
const stackItemStylesWide = mergeStyles({
    alignItems: 'flex-start',

    display: 'flex',
    height: 50,
    justifyContent: 'flex-start',
    width: 200,
});
class Teamssubmission extends React.Component<ITeamssubmissionProps, ITeamssubmissionState> {

    constructor(props) {
        super(props);
        this.state = {
            submission: null,
            loaded: false,
            rubric: null,
            outcome: null,
            previewurl: null

        };

    }

    componentDidMount() {
        console.log('evalformgraph1', this.props.task);
        this.props.loadsubmission(this.props.studenttask.Teamsclassid, this.props.studenttask.Teamstaskid, this.props.studenttask.Teamssubmissionid,).then((res) => {
            console.log('evalformgraph', res.payload);
            console.log('evalformgraph2', this.props.studenttask);
            this.props.loadfulltask(this.props.studenttask.Teamsclassid, this.props.studenttask.Teamstaskid).then((resfull) => {
                console.log('evalformgraph3', resfull);
                this.setState({ rubric: resfull.payload.rubric, outcome: res.payload.outcomes[2] });
            });
            this.setState({ submission: res.payload });
            this.props.getAssignmentPlagInfo(this.props.studenttask.Teamsclassid, this.props.studenttask.Teamstaskid).then((info) => {
                console.log('info', info.payload.data);
                let tmp = JSON.parse(JSON.stringify(this.state.submission));
                tmp.Grade = '3';
                if (tmp.outcomes && tmp.outcomes.length > 0 && tmp.outcomes[0].feedback) {
                    console.log('evalformgraph', 'ok');
                    tmp.GradingComment = tmp.outcomes[0].feedback.text.content;
                }
                tmp.submittedResources.forEach(element => {
                    info.payload.data.forEach(resultat => {
                        if (element.id === resultat.fileid) {
                            console.log('match');
                            element.urkundState = resultat.urkundState;
                            element.urkundReportUrl = resultat.urkundReportUrl;
                            element.urkundSignificance = resultat.urkundSignificance;
                        }
                    });

                });
                this.setState({ submission: tmp, loaded: true });

            })
        })
    }
    wasclicked(url) {
        this.props.getPreview(url);
    }
    render() {
        const classNames = mergeStyleSets({
            exampleRoot: {
                marginTop: '20px',
            },
            nameText: {
                fontWeight: 'bold',
            },
        });
        console.log('evalformgraphstate', this.state);
        return (
            <div>
                {
                    this.state && this.state.submission &&
                    <>
                        {this.props.studenttask.Name}

                        {this.props.submission.status}
                        {/* <EvalFormGraph initialValues={this.state.submission}></EvalFormGraph> */}
                        <span>
                            {this.state.submission.GradingComment}
                        </span>
                        <span>
                            {
                                this.state.rubric &&
                                <RubricDisplay rubric={this.state.rubric} outcome={this.state.outcome}></RubricDisplay>
                            }

                        </span>
                        <div className="ms-Grid-row">
                            <div className="z-form-group">
                                <div className="ms-Grid-col ms-sm12 ms-md4">
                                    <ul>
                                        {
                                            this.state.submission.submittedResources && this.state.submission.submittedResources.map((resource) => {

                                                return (

                                                    <li >
                                                        {/* <Stack horizontal horizontalAlign={'start'}> */}
                                                        <Stack horizontal horizontalAlign={'start'}>
                                                            <Stack>
                                                                <span className={stackItemStylesWide}>
                                                                    <File
                                                                        onClick={() => {
                                                                            this.props.getPreview(resource.resource.fileUrl).then((preview) => {
                                                                                console.log(preview.payload.getUrl);
                                                                                this.setState({ previewurl: preview.payload.getUrl });
                                                                            })
                                                                        }}
                                                                        fileQuery={resource.resource.fileUrl}>
                                                                    </File>
                                                                </span>
                                                            </Stack>
                                                            <Stack>
                                                                <span className={stackItemStyles}>
                                                                    {
                                                                        !resource.urkundState && this.state.loaded &&
                                                                        <ActionButton
                                                                            iconProps={{ iconName: 'NewAnalyticsQuery' }}
                                                                            allowDisabledFocus={true}
                                                                            checked={false}
                                                                            onClick={() => {
                                                                                this.props.submitResource(
                                                                                    this.props.studenttask.Teamsclassid,
                                                                                    this.props.studenttask.Teamstaskid,
                                                                                    resource.resource,
                                                                                    resource
                                                                                )
                                                                            }}
                                                                        >
                                                                        </ActionButton>

                                                                    }
                                                                    {
                                                                        resource.urkundState && resource.urkundState != 'Error' &&
                                                                        <>
                                                                            <PlagStatus
                                                                                reporturl={resource.urkundReportUrl}
                                                                                significance={resource.urkundSignificance}
                                                                            ></PlagStatus>
                                                                        </>

                                                                    }
                                                                    {
                                                                        resource.urkundState && resource.urkundState == 'Error' &&
                                                                        <>
                                                                            {resource.urkundState}
                                                                        </>

                                                                    }
                                                                </span>

                                                            </Stack>

                                                        </Stack>





                                                    </li>
                                                )
                                            })
                                        }
                                    </ul>
                                </div>
                                <div className="ms-Grid-col ms-sm12 ms-md8">
                                    {
                                        this.state.previewurl &&
                                        <iframe width={'100%'} height={'600px'} src={this.state.previewurl}></iframe>
                                    }

                                </div>
                            </div>
                        </div>



                    </>
                }
                {/* {
                    this.props.studenttask && this.props.studenttask.Teamssubmissionid &&
                    
                } */}

            </div>
        );
    }

}

export default connect(mapStateToProps, { loadsubmission, getTeacherState, getAssignmentPlagInfo, submitResource, loadfulltask, getPreview })(
    Teamssubmission
);
