import * as React from 'react';
import * as moment from 'moment';
import * as _ from 'lodash';
import { connect } from 'react-redux';
moment.locale('nb');
import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from 'react-html-parser';
import StudentPlanElementView from "./StudentPlanElementView";
import StudentTaskElementView from "./StudentTaskElementView";
import StudentNewsElementView from "./StudentNewsElementView";
import Masonry from 'react-masonry-component';
export interface ICourseDayViewViewProps {
    teacherassignment: any;
    history:any;
}
export interface ICourseDayViewViewState {

}

class CourseDayViewStudent extends React.Component<ICourseDayViewViewProps, ICourseDayViewViewState>{
    constructor(props) {
        super(props);
    }
    render() {
        console.log(this.props);
        return (
            <div className="item courseitem">
                <div className="panel panel-default" id={this.props.teacherassignment.CourseCategory}>
                    <div className="panel-heading">
                        <h2>{this.props.teacherassignment.Title}</h2>


                    </div>
                    <div className="panel-body">
                        <ul className="list-group">
                            {this.props.teacherassignment.News.map((news) => {
                                return (
                                    <StudentNewsElementView newselement={news} history={this.props.history}/>
                                        
                                )
                            })
                            }
                            {this.props.teacherassignment.StudentTexts.map((text) => {
                                return (

                                    <StudentPlanElementView planelement={text} />

                                )
                            }
                            )}
                            {this.props.teacherassignment.StudentTasks.map((task) => {
                                return (
                                    <StudentTaskElementView taskelement={task} history={this.props.history}/>
                                )
                            })}


                        </ul>
                    </div>
                </div>

            </div>
          
        )
    }

}
function mapStateToProps(state) {

    return {}
}
export default connect(mapStateToProps, {})(CourseDayViewStudent);