import * as React from 'react';
import { connect } from 'react-redux';
import {
  getIupForClass,
  getIupReportForClassDraft,
  getIupForCourse,
  getIupReportForCourse,
  createIUP
} from "../../actions/Action_Iup";
import HeaderView from "../view_containers/HeaderView";
import "./iup.scss";
import {
	ActionButton,
	DefaultButton,
	PrimaryButton,
	Panel,
	PanelType,
	TextField,
	Label,
	Spinner,
	SpinnerSize
} from 'office-ui-fabric-react';

import Select from 'react-select';
import IupClassReportView from './IupClassReportView';
import IupCourseReportView from './IupCourseReportView';
import DatePicker from 'react-datepicker';
export const DATE_FORMAT = 'DD.MM.YYYY';
export interface IIupViewProps {
  teacherstate: any;
  getIupForClass(schoolclass: any);
  getIupReportForClassDraft(iupId: number, includeDraft?:boolean);
  getIupForCourse(course: any);
  getIupReportForCourse(iup: any);
  createIUP(iup: any);
  courses: any[];
  classes: any[];
  iupclass: any[];
  iupcourse: any[];
  iupClassReport: any;
  iupCourseReport: any;
}
export interface IIupViewState {
  showcourse: boolean;
  showclass: boolean;
  showcreate: boolean;
  Deadline: any;
  from: any;
  includeDraft: boolean;
  selectedclass: any;
  selectedCourse: any;
  selectedIUP: any;
  working: boolean;
  formIsDirty: boolean;
}
let text = '';
class IupView extends React.Component<IIupViewProps, IIupViewState> {
  constructor(props) {
    super(props);
    this.state = {
      showclass: false,
      showcourse: false,
      showcreate: false,
      Deadline: null,
      from: null,
      selectedclass: null,
      selectedCourse: null,
      selectedIUP: null,
      working: false,
      includeDraft: false,
	  formIsDirty: false,
    };
  }

  sortDropdown(str: string): any[]{
    let returnArray;
    if(str === "Fag"){
      returnArray = this.props.courses.sort((a,b) =>{
        return a.label > b.label ? 1 : b.label > a.label ? -1 : 0;
      })
    }else if(str === "Klasser"){
      returnArray = this.props.classes.sort((a,b) =>{
        return +a.label.substring(0, a.label.length - 1) > +b.label.substring(0, b.label.length -1) ? 1 : 
        +a.label.substring(0, a.label.length - 1) < +b.label.substring(0, b.label.length -1) ? -1 : 0;
    })
    }
    return returnArray;
  }

  iupViewToggleDraft = () =>{
    this.setState(prevState =>({
      includeDraft: !prevState.includeDraft
    }), 
    () => {
        this.refreshSelectedIUP();
      }
    )
  }

  setIupFormStatus = (isDirty: boolean) => {
	this.setState({formIsDirty: isDirty});
  }

  refreshSelectedIUP = () => {
    if (this.state.selectedCourse) {
      this.props.getIupForCourse({ CourseID: this.state.selectedCourse });
    }
    if (this.state.selectedclass) {
      this.props.getIupForClass({ClassID: this.state.selectedclass});
    }
    if (this.state.selectedIUP) {
      this.props.getIupReportForClassDraft(this.state.selectedIUP.Id, this.state.includeDraft);
    }
  }

	public render() {
		return (
			<div className="ms-Grid container main">
				<HeaderView title="IUP" description="Opprett/Hent IUP" iconName="Transition" />
				<div className="ms-Grid-row ms-bgColor-neutralLight topTool">
					<div className="ms-Grid-row">
						<div className="ms-Grid-col ms-sm12 ms-lg6">
							<h3 className="iup-h3-smaller">For faglærer</h3>
							<div className="ms-TextField ms-TextField--placeholder">
								<label className="ms-Label">Finn fag</label>
								<Select
									placeholder={'Velg'}
									onChange={(selected) => {
										this.setState({ selectedCourse: selected.value });
										this.props.getIupForCourse({ CourseID: selected.value });
									}}
									options={this.sortDropdown('Fag')}
								/>
							</div>
							<div id="periodspinner" />
							<ul className="iup-list" id="selGrpFilterTop">
								{this.props.iupcourse &&
									this.props.iupcourse.map((iup) => {
										let c = 'iup-item';
										if (this.props.iupCourseReport && iup.Id === this.props.iupCourseReport.Id) {
											c = 'iup-item jegervalgt';
										}
										return (
											<li
												className={c}
												onClick={() => {
													let changeIupConfirmation = this.state.formIsDirty ? confirm("Du har ulagrede endringer, er du sikker på at du vil bytte iup?") : true;
													if (changeIupConfirmation) {
														this.props.getIupReportForCourse(iup);
														this.setState({ showclass: false, showcourse: true, formIsDirty: false });
													}
												}}
											>
												{iup.Name}
											</li>
										);
									})}
							</ul>
						</div>
						<div className="ms-Grid-col ms-sm12 ms-lg6">
							<h3 className="iup-h3-smaller">For kontaktlærer</h3>
							<div className="ms-Grid-col ms-sm12 ms-xl8">
								<div className="ms-TextField ms-TextField--placeholder">
									<label className="ms-Label">Finn klasse</label>
									<Select
										placeholder={'Velg'}
										onChange={(selected) => {
											this.props.getIupForClass({
												ClassID: selected.value + '|' + selected.label
											});
											this.setState({
												selectedclass: selected.value + '|' + selected.label
											});
										}}
										options={this.sortDropdown('Klasser')}
									/>
								</div>
							</div>
							<div className="ms-Grid-col ms-sm12 ms-xl4">
								{this.state.selectedclass && (
									<ActionButton
										styles={{
											root: {
												marginTop: 24
											}
										}}
										iconProps={{ iconName: 'Add' }}
										text="Opprett IUP"
										onClick={() => {
											this.setState({ showcreate: true });
										}}
									/>
								)}

								<Panel
									isOpen={this.state.showcreate}
									onDismiss={() => {
										this.setState({ showcreate: false });
									}}
									type={PanelType.smallFixedFar}
									headerText="Opprett IUP"
								>
									<div>
										<TextField
											label="Tittel"
											onChange={(t, val) => {
												text = val;
											}}
										/>
										<Label>Fra</Label>
										<DatePicker
											autocomplete="off"
											selected={this.state.from}
											onChange={(date) => {
												this.setState({ from: date });
											}}
											filterDate={(date) => {
												return date.day() !== 0 && date.day() !== 6;
											}} // Weekends are disabled
											locale="nb-NO"
											dateFormat={DATE_FORMAT}
											showWeekNumbers
										/>

										<Label>Til</Label>
										<DatePicker
											autocomplete="off"
											selected={this.state.Deadline}
											onChange={(date) => {
												this.setState({ Deadline: date });
											}}
											filterDate={(date) => {
												return date.day() !== 0 && date.day() !== 6;
											}} // Weekends are disabled
											locale="nb-NO"
											dateFormat={DATE_FORMAT}
											showWeekNumbers
										/>

										<PrimaryButton
											styles={{
												root: {
													marginTop: 24
												}
											}}
											text="Lagre"
											onClick={() => {
												let i: any = {};
												i.ClassID = this.state.selectedclass;
												i.Name = text;
												i.From = this.state.from;
												i.To = this.state.Deadline;
												i.Id = 0;
												this.setState({ working: true });
												this.props.createIUP(i).then(() => {
													this.setState({ showcreate: false, working: false });
													this.props.getIupForClass({
														ClassID: this.state.selectedclass
													});
												});
											}}
										/>
										{this.state.working && (
											<Spinner size={SpinnerSize.large} ariaLive="assertive" />
										)}
										<DefaultButton
											styles={{
												root: {
													marginTop: 24
												}
											}}
											text="Avbryt"
											onClick={() => {
												this.setState({ showcreate: false });
											}}
										/>
									</div>
								</Panel>
								{/* <Modal

                                    isOpen={this.state.showcreate}
                                    onDismissed={() => {
                                        this.setState({ showcreate: false })
                                    }}
                                >


                                </Modal> */}
              </div>
              <div className="ms-Grid-col ms-sm12">
                <ul className="iup-list" id="selIUPClass">
                  {this.props.iupclass &&
                    this.props.iupclass.map(iup => {
                      let c = "iup-item";
                      if (
                        this.props.iupClassReport &&
                        iup.Id === this.props.iupClassReport.Id
                      ) {
                        c = "iup-item jegervalgt";
                      }
                      return (
                        <li
                          className={c}
                          onClick={() => {
							let changeIupConfirmation = this.state.formIsDirty ? confirm("Du har ulagrede endringer, er du sikker på at du vil bytte iup?") : true;
							if (changeIupConfirmation) {
								this.props.getIupReportForClassDraft(iup.Id, this.state.includeDraft);
								this.setState({
								  showclass: true,
								  showcourse: false,
								  selectedIUP: iup,
								  formIsDirty: false,
								});
							}
                          }}
                        >
                          {iup.Name}
                        </li>
                      );
                    })}
                </ul>
              </div>
            </div>
          </div>

					<div className="ms-Grid-col ms-sm12 ms-lg12 ms-xl4 ms-xxl6">
						<div id="buttons">
							<div
								className="modal fade"
								id="iupnameModal"
								role="dialog"
								aria-labelledby="exampleModalLabel"
								aria-hidden="true"
							>
								<div className="modal-dialog" role="document">
									<div className="modal-content">
										<div className="modal-header">
											<h5 className="modal-title" id="exampleModalLabel">
												Opprett IUP
											</h5>
											<button
												type="button"
												className="close"
												data-dismiss="modal"
												aria-label="Close"
											>
												<span aria-hidden="true">&times;</span>
											</button>
										</div>
										<div className="modal-body">
											<div className="ms-Textfield">
												<label className="ms-Label is-required">Tittel</label>
												<input className="ms-TextField-field" type="text" id="periodName" />
											</div>
											<div className="ms-DatePicker">
												<div className="ms-TextField">
													<label className="ms-Label">Fra</label>
													<i className="ms-DatePicker-event ms-Icon ms-Icon--Event"></i>
												</div>
												<div className="ms-DatePicker-monthComponents">
													<span className="ms-DatePicker-nextMonth js-nextMonth">
														<i className="ms-Icon ms-Icon--ChevronRight"></i>
													</span>
													<span className="ms-DatePicker-prevMonth js-prevMonth">
														<i className="ms-Icon ms-Icon--ChevronLeft"></i>
													</span>
													<div className="ms-DatePicker-headerToggleView js-showMonthPicker"></div>
												</div>
												<span className="ms-DatePicker-goToday js-goToday">
													@LangResources.Resources.WSTextFormToday
												</span>
											</div>
											<div className="ms-DatePicker">
												<div className="ms-TextField">
													<label className="ms-Label">Til</label>
													<i className="ms-DatePicker-event ms-Icon ms-Icon--Event"></i>
												</div>
												<div className="ms-DatePicker-monthComponents">
													<span className="ms-DatePicker-nextMonth js-nextMonth">
														<i className="ms-Icon ms-Icon--ChevronRight"></i>
													</span>
													<span className="ms-DatePicker-prevMonth js-prevMonth">
														<i className="ms-Icon ms-Icon--ChevronLeft"></i>
													</span>
													<div className="ms-DatePicker-headerToggleView js-showMonthPicker"></div>
												</div>
												<span className="ms-DatePicker-goToday js-goToday">
													@LangResources.Resources.WSTextFormToday
												</span>
											</div>
										</div>
										<div className="modal-footer">
											<button
												type="button"
												className="ms-Button ms-Button--default"
												data-dismiss="modal"
											>
												<span className="ms-Button-label"> Lukk</span>
											</button>
											<button type="button" className="ms-Button ms-Button--primary">
												<span className="ms-Button-label">Lagre IUP</span>
											</button>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>

        <div className="ms-Grid-row main" id="iup-page">
          <div className="ms-Grid-col ms-sm12">
            {this.state.showclass && this.props.iupClassReport && (
              <IupClassReportView
                iupClassReport={this.props.iupClassReport}
                iupViewToggleDraft = {this.iupViewToggleDraft}
                refreshSelectedIUP = {this.refreshSelectedIUP }
				includeDraft = {this.state.includeDraft}
				setIupFormStatus={this.setIupFormStatus}
              ></IupClassReportView>
            )}
            {this.state.showcourse && this.props.iupCourseReport && (
              <IupCourseReportView
                iupCourseReport={this.props.iupCourseReport}
				setIupFormStatus = {this.setIupFormStatus}
              ></IupCourseReportView>
            )}
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
	const options = [];
	Object.keys(state.teacher.subscribedSchoolClasses).forEach((key) => {
		let c = state.teacher.subscribedSchoolClasses[key];
		options.push({ value: c.ClassID, label: c.ClassName });
	});

	const optionscourse = [];
	Object.keys(state.teacher.subscribedCourses).forEach((key) => {
		let c = state.teacher.subscribedCourses[key];
		optionscourse.push({ value: c.CourseID, label: c.Title });
	});

  return {
    teacherstate: state.teacher,
    classes: options,
    courses: optionscourse,
    iupclass: state.Iup.iupselectors,
    iupcourse: state.Iup.iupcourseselectors,
    iupClassReport: state.Iup.iupclassreportdraft,
    iupCourseReport: state.Iup.iupcoursereport
  };
}

export default connect(mapStateToProps, {
  getIupForClass,
  getIupReportForClassDraft,
  getIupForCourse,
  getIupReportForCourse,
  createIUP
})(IupView);
