import {IStudentQuiz} from "../models/student/IStudentQuiz";
import * as moment from 'moment';
import {difference} from 'lodash';
import {sharePointDateStringToMoment} from "./DateUtils";
import {IStudentQuizAttempt} from "../models/student/IStudentQuizAttempt";



const mapStudentQuizDtoToObject = (quizDto : any) : IStudentQuiz =>({
    ...quizDto,
    ValidFrom: sharePointDateStringToMoment(quizDto.ValidFrom),
    ValidTo: sharePointDateStringToMoment(quizDto.ValidTo)
});

export const mapStudentQuizzesDtoToObject = (quizzes : any[]) : IStudentQuiz[] =>(
    quizzes.map(q => mapStudentQuizDtoToObject(q))
);

export const mapStudentQuizAttemptDtoToObject = (quizAttempt) : IStudentQuizAttempt =>({
    ...quizAttempt,
    Started: sharePointDateStringToMoment(quizAttempt.Started),
    Modified: sharePointDateStringToMoment(quizAttempt.Modified),
    Quiz: mapStudentQuizDtoToObject(quizAttempt.Quiz)
});

// export const getActiveQuizzes = (quizzes : IStudentQuiz[]) : IStudentQuiz[] =>(
//     quizzes
//         .filter(q =>  moment() > q.ValidFrom && moment() < q.ValidTo)
//         .sort((a, b) => a.ValidTo < b.ValidTo ? 1 : -1)
// );
export const getActiveQuizzes = (quizzes : IStudentQuiz[]) : IStudentQuiz[] =>(
    quizzes
        .filter(q =>  moment() < q.ValidTo)
        .sort((a, b) => a.ValidTo < b.ValidTo ? 1 : -1)
);

export const getInactiveQuizzes = (allQuizzes : IStudentQuiz[], activeQuizzes : IStudentQuiz[]) : IStudentQuiz[] =>(
    difference(allQuizzes, activeQuizzes)
);

export const convertArrayToObject = (array, key) : {} => {
    const initialValue = {};
    return array.reduce((obj, item) => {
        return {
        ...obj,
        [item[key]]: item,
      };
    }, initialValue);
  };