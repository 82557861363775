import * as React from "react";
import { Checkbox } from "office-ui-fabric-react";

interface ICheckboxFieldProps {
  input: any;
  label: string;
  required?: boolean;
}

const FabricCheckboxField = ({input, label, required}:ICheckboxFieldProps) =>{
    return(
        <div className="ms-Grid-row">
            <div className="z-form-group vertical-align">
                <div className="ms-Grid-col ms-sm12">
                    <Checkbox
                        checked={!!input.value}
                        label={label}
                        onChange={(e, checked) => input.onChange(checked)}
                    />
                </div>
            </div>
        </div>
    )
};

export default FabricCheckboxField;
