import * as React from 'react';
import moment = require('moment');
import { DefaultButton, Label, PrimaryButton, Stack } from 'office-ui-fabric-react';
import DatePicker from 'react-datepicker';

import './../../formelements.scss';
import { Moment } from 'moment';

const DEFAULT_DATE_FORMAT: string = 'DD.MM.YYYY';
const DEFAULT_TIME_FORMAT: string = 'HH:mm';
const LOCALE = 'nb';

interface IDateTimeFieldProps {
	input: any;
	label: string;
	required?: boolean;
	meta: {
		touched: boolean;
		error: string;
		visited: boolean;
		pristine: boolean;
	};
	timeFormat?: string;
	dateFormat?: string;
	showTime?: boolean;
	minTime?: Moment;
	maxTime?: Moment;
	canRemove?: boolean;
}

const DateTimeField = (props: IDateTimeFieldProps) => {
	const {
		input,
		label,
		required,
		minTime,
		maxTime,
		showTime,
		dateFormat,
		timeFormat,
		meta: { touched, error, visited, pristine },
		canRemove
	} = props;

	const displayFormat = showTime
		? [dateFormat || DEFAULT_DATE_FORMAT, timeFormat || DEFAULT_TIME_FORMAT].join(' ')
		: dateFormat;

	
	let selectedDate = input.value ? moment(input.value, displayFormat) : null;

	const resetDate = () => {
		selectedDate = moment().hour(8).minute(0);
		input.onChange(selectedDate.locale(LOCALE).format(displayFormat));
	}

	const removeDate = () => {
			input.onChange("");
	}

	const showYearDropdown = (): boolean => {
		return selectedDate && selectedDate.isSameOrBefore(moment().subtract(1, "quarters"))
	}

	return (
		<div className="ms-Grid-row">
			<div className="z-form-group">
				<div className="ms-Grid-col ms-sm12 ms-md4">
					<Label required={required} className="z-form-label">
						{label}
					</Label>
				</div>
				<div className={`ms-Grid-col ms-sm12 ms-md8 ${(touched || (visited && !pristine)) && error ? 'error' : ''}`}>
					<Stack horizontal horizontalAlign="space-between" >
						<DatePicker
						{...input}
						allowTextInput={true}
						selected={selectedDate}
						onChange={(date) => {
							if (showTime && input.value == "" && date.day() != moment().day() && date.hour() == 0 && date.minute() == 0) {
								date.hour(8).minute(0);
							}
							selectedDate = date;
							input.onChange(date.locale(LOCALE).format(displayFormat));
							input.value = date.locale(LOCALE).format(displayFormat);
						}}
						timeFormat={timeFormat || null}
						timeIntervals={5}
						minTime={minTime || null} // ScheduleElements cannot start before 8:00AM
						maxTime={maxTime || null} // ScheduleElements cannot end after 16:00AM
						locale="nb-NO"
						dateFormat={dateFormat || DEFAULT_TIME_FORMAT}
						timeCaption="Tid"
						// showYearDropdown = {showYearDropdown()}
						
						
						showTimeSelect={showTime ? true : false}
						showWeekNumbers
						autoComplete="off"
						
						onBlur={() => {
							// input.onBlur(input.value);
						}}
						
						/>
						{ input.value && !canRemove && showYearDropdown() && <PrimaryButton style={{marginRight: "0", height: "inherit"}}  onClick={() => resetDate()}>Reset</PrimaryButton>}
						{ input.value && canRemove && <PrimaryButton style={{marginRight: "0", height: "inherit"}}  onClick={() => removeDate()}>Fjern</PrimaryButton>}
					</Stack>
					{(touched || (visited && !pristine)) && error && <span className="error-message">{error}</span>}
				</div>
			</div>
		</div>
	);
};

export default DateTimeField;
