import * as moment from "moment";
import {
    SET_FILTER_TASKS,
    SET_SCHEDULE_CLASS,
    SET_PORT_FILTER_VALUES,
    SET_SCHEDULE_WEEK,
    SET_SCHEDULE_YEAR,
    ISetSelectedWeekAction,
    ISetSelectedYearAction,
    ISetSelectedClassAction,
    RESET_SELECTED_WEEK_AND_YEAR,
    SET_SCHEDULE_CALENDAR_CONTEXT,
    ISetScheduleCalendarContextAction,
} from "../actions/Action_Filtervalues";

interface IFilterState {
    Filter: any,
    portFilterValues: any,
    TaskFilters?: any,
    selectedclass?: string,
    selectedWeek?: number,
    selectedYear?: number
}

const initialState: IFilterState = {
    Filter: {},
    portFilterValues: {},
};

const setFilterTask = (state: IFilterState, {payload}: any): IFilterState =>({
    ...state,
    TaskFilters: payload
});

const setScheduleClass = (state: IFilterState, {payload}: ISetSelectedClassAction): IFilterState =>({
    ...state,
    selectedclass: payload
});

const setPortFilterValues = (state: IFilterState, {payload}: any): IFilterState =>({
    ...state,
    portFilterValues: payload
});

const setSelectedWeek = (state: IFilterState, {week}: ISetSelectedWeekAction): IFilterState =>({
    ...state,
    selectedWeek: week
});

const setSelectedYear = (state: IFilterState, {year}: ISetSelectedYearAction): IFilterState =>({
    ...state,
    selectedYear: year
});

const resetSelectedWeekAndYear = (state: IFilterState): IFilterState =>({
    ...state,
    selectedWeek: undefined,
    selectedYear: undefined
});

const setScheduleCalendarContext = (state: IFilterState, {selectedclass, date}: ISetScheduleCalendarContextAction): IFilterState =>({
    ...state,
    selectedclass,
    selectedWeek:moment(date).week(),
    selectedYear: moment(date).year()
});

export default function (state = initialState, action) {
    switch (action.type) {
        case SET_FILTER_TASKS:
            return setFilterTask(state, action);
        case SET_SCHEDULE_CLASS:
            return setScheduleClass(state, action);
        case SET_PORT_FILTER_VALUES:
            return setPortFilterValues(state, action);
        case SET_SCHEDULE_WEEK:
            return setSelectedWeek(state, action);
        case SET_SCHEDULE_YEAR:
            return setSelectedYear(state, action);
        case RESET_SELECTED_WEEK_AND_YEAR:
            return resetSelectedWeekAndYear(state);
        case SET_SCHEDULE_CALENDAR_CONTEXT:
            return setScheduleCalendarContext(state, action);
        default:
            return state;
    }
}
