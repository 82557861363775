import * as React from "react";
import { connect } from "react-redux";
import { getAllClasses, saveTeamMap } from "../../actions/Action_Graph";
import { getTeacherState } from "../../actions/Action_Teacher";
import {
  Panel,
  PanelType,
  ActionButton,
  PrimaryButton,
  Icon,
  SearchBox
} from "office-ui-fabric-react";
import {
  DetailsList,
  DetailsListLayoutMode,
  Selection,
  IColumn,
  SelectionMode,
} from "office-ui-fabric-react/lib/DetailsList";
import { MarqueeSelection } from "office-ui-fabric-react/lib/MarqueeSelection";

export interface ITeamMapperProps {
  getAllClasses();
  saveTeamMap(tm: any);
  classID: string;
  classlist: any[];
  onClose: (selecteditems: any[]) => any;
  teammaps: any;
  getTeacherState();
}
export interface ITeamMapperState {
  showasspicker: boolean;
  items: any[];
  selectionDetails: string;
  saved:boolean;
  searchInput: string;
}
function mapStateToProps(state) {
    console.log('teammapper',state);
  //console.log(state.GraphReducer.classAssignments);
  let mappedcourses = {};
  if (state.teacher) {
    mappedcourses = state.teacher.teamscourses;
  }
  return {
    classlist: state.GraphReducer.allclasses,
    teammaps: mappedcourses,
  };
}

class TeamMapper extends React.Component<ITeamMapperProps, ITeamMapperState> {
  private _selection: Selection;
  private _allItems: any[];
  private _columns: IColumn[];
  constructor(props) {
    super(props);
    this.state = {
      showasspicker: false,
      items: props.assignmentlist,
      selectionDetails: "",
      saved:false,
      searchInput: ""
    };
    this._columns = [
      {
        key: "column1",
        name: "Name",
        fieldName: "displayName",
        minWidth: 100,
        maxWidth: 200,
        isResizable: true,
      },
    ];
    this._selection = new Selection({
      onSelectionChanged: () => {
        //this.setState({ selectionDetails: this._getSelectionDetails() })
      },
    });
  }
  componentDidMount() {
    this.props.getAllClasses();
  }

  onSearch(input) {
    let selectedList = this._selection.getSelection();
    let selected = null;
    let index: number;

    if (selectedList && selectedList.length > 0){
      selected = selectedList[0];
    }
    this._selection.setAllSelected(false);
    this.setState({
      searchInput: input.toLowerCase()
    }, () => {
      index = this.props.classlist.filter(c => c.displayName.toLowerCase().indexOf(this.state.searchInput) !=  -1).indexOf(selected);
      if (index != -1) {
        this._selection.setIndexSelected(index, true, false);
      }
    });
  }

  render() {
    const { items, selectionDetails } = this.state;
    if (this.props.teammaps[this.props.classID]) {
      return <div style={{padding:10}}><Icon iconName="TeamsLogo16" style={{color: '#525abf', verticalAlign:'middle', fontSize: 20, marginRight: 5}}/>Dette faget er koblet til Teams</div>;
    }
    if(this.state.saved)
    {
      return <div>mapping lagret</div>;
    }
    //console.log('items',items);
    return (
      <div>
        <ActionButton
          iconProps={{ iconName: "Add" }}
          data-toggle="modal"
          data-target="#myModalGrepQuiz"
          onClick={() => {
            this.setState({ showasspicker: true });
          }}
        >
          Knytt til team
        </ActionButton>
        <Panel
          isOpen={this.state.showasspicker}
          type={PanelType.medium}
          onDismiss={() => {
            this.setState({ showasspicker: false });
          }}
          headerText="Knytt til team"
          closeButtonAriaLabel="Lukk"
          onRenderFooterContent={this._onRenderFooterContent}
        >
          {this.props.classlist && (
            <div>
              <SearchBox 
                placeholder="Søk etter team" 
                onChange={(input) => this.onSearch(input)}
              ></SearchBox>
              <MarqueeSelection selection={this._selection}>
                <DetailsList
                  items={this.props.classlist.filter(c => c.displayName.toLowerCase().indexOf(this.state.searchInput) !=  -1)}
                  columns={this._columns}
                  setKey="set"
                  layoutMode={DetailsListLayoutMode.justified}
                  selection={this._selection}
                  selectionMode={SelectionMode.single}
                  selectionPreservedOnEmptyClick={true}
                  ariaLabelForSelectionColumn="Toggle selection"
                  ariaLabelForSelectAllCheckbox="Toggle selection for all items"
                  checkButtonAriaLabel="Row checkbox"
                  //onItemInvoked={this._onItemInvoked}
                />
              </MarqueeSelection>

            </div>
          )}
        </Panel>
      </div>
    );
  }
  private _getSelectionDetails(): string {
    const selectionCount = this._selection.getSelectedCount();

    switch (selectionCount) {
      case 0:
        return "No items selected";
      case 1:
        return (
          "1 item selected: " + (this._selection.getSelection()[0] as any).name
        );
      default:
        return `${selectionCount} items selected`;
    }
  }
  private _onRenderFooterContent = () => {
    return (
      <div>
        <PrimaryButton
          text="Lagre"
          onClick={() => {
            //   console.log('selected',this._selection.getSelection());
            this.setState({ showasspicker: false,saved:true });
            let selected = this._selection.getSelection()[0] as any;
            this.props.saveTeamMap({
              CourseID: this.props.classID,
              TeamID: selected.id,
            });
          }}
        ></PrimaryButton>
      </div>
    );
  };
}

export default connect(mapStateToProps, { getAllClasses, saveTeamMap,getTeacherState })(
  TeamMapper
);
