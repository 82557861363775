import axios from 'axios';
import * as _ from 'lodash';
import { ROOT_URL, SP_HOST_URL } from './constants';
import {IStudentQuizAttemptDto} from "../models/student/Dtos/IStudentQuizAttemptDto";

export const GET_STUDENT_STATE = 'GET_STUDENT_STATE';
export const GET_UKEPLAN_ELEMENTS = 'GET_UKEPLAN_ELEMENTS';
export const GET_STUDENTCURRENT_TASKS = 'GET_STUDENTCURRENT_TASKS';
export const GET_STUDENTLAST_TASKS = 'GET_STUDENTLAST_TASKS';
export const GET_STUDENTSAVED_TASKS = 'GET_STUDENTSAVED_TASKS';
export const GET_STUDENTSCHEDULE_ELEMENTS = 'GET_STUDENTSCHEDULE_ELEMENTS';
export const GET_STUDENT_PORTFOLIOSTUDENT = 'GET_STUDENT_PORTFOLIOSTUDENT';
export const GET_STUDENT_TASKDELIVER = 'GET_STUDENT_TASKDELIVER';
export const ADD_STUDENT_DELIVERYFILE = 'ADD_STUDENT_DELIVERYFILE';
export const REMOVE_STUDENT_DELIVERYFILE = 'REMOVE_STUDENT_DELIVERYFILE';
export const SUBMIT_STUDENT_TASK = 'SUBMIT_STUDENT_TASK';
export const GET_STUDENT_QUIZZES = 'GET_STUDENT_QUIZZES';
export const GET_STUDENT_QUIZ_ATTEMPT = "GET_STUDENT_QUIZ_ATTEMPT";
export const SAVE_QUIZ_ANSWER = "SAVE_QUIZ_ANSWER";
export const GET_STUDENT_IUPLIST = 'GET_STUDENT_IUPLIST';
export const GET_STUDENT_IUP = 'GET_STUDENT_IUP';
export const GET_UKEPLAN_CHECKS = 'GET_UKEPLAN_CHECKS';
export const SET_UKEPLAN_CHECKS = 'SET_UKEPLAN_CHECKS';
export const CHECK_SUBMISSION_STATUS = 'CHECK_SUBMISSION_STATUS';
export const INIT_SUBMISSION_STATUS = 'INIT_SUBMISSION_STATUS';
export const SAVE_STUDENT_LINK = 'SAVE_STUDENT_LINK';
export const GET_STUDENTNEWS_FILES = 'GET_STUDENTNEWS_FILES';
export const CREATE_STUDENT_QUIZ_ATTEMPT = 'CREATE_STUDENT_QUIZ_ATTEMPT';
export const GET_STUDENT_QUIZ_ATTEMPTGRADE = 'GET_STUDENT_QUIZ_ATTEMPTGRADE';
export const GET_STUDENTTASK_COMMENTS = 'GET_STUDENTTASK_COMMENTS';


export function getStudentState() {

    // Returns arrays for SchoolClasses, Courses and Schools.
    // This is requested when teacher has been authenticated when webapp starts
    const request = axios.get(`${ROOT_URL}/GetStudentState/${SP_HOST_URL}`,{
        headers: {
            Authorization: localStorage.getItem('id_token')
        }
    });

    return {
        type: GET_STUDENT_STATE,
        payload: request
    }
}

export function getUkeplanElements(student, week:number, year:number) {

    const data:FormData = new FormData();
    data.set('student', JSON.stringify(student));

    const request = axios.post(`${ROOT_URL}/GetStudentWS/${week}-${year}${SP_HOST_URL}`, data, {
        headers: {
            Authorization: localStorage.getItem('id_token')
        }
    });

    return {
        type: GET_UKEPLAN_ELEMENTS,
        payload: request
    }
}

export function getUkeplanChecks(week:number, year:number) {

    const request = axios.get(`${ROOT_URL}/GetStudentChecks/${week}-${year}${SP_HOST_URL}`, {
        headers: {
            Authorization: localStorage.getItem('id_token')
        }
    });

    return {
        type: GET_UKEPLAN_CHECKS,
        payload: request
    }
}

export function setUkeplanChecks(check:any) {

    const request = axios.post(`${ROOT_URL}/SetStudentChecks/${SP_HOST_URL}`,check, {
        headers: {
            Authorization: localStorage.getItem('id_token')
        }
    });

    return {
        type: SET_UKEPLAN_CHECKS,
        payload: request
    }
}
export function getCurrentTaskElements(student:any) {
    const data:FormData = new FormData();
    data.set('student', JSON.stringify(student));

    const request = axios.post(`${ROOT_URL}/GetStudentCurrentTasks${SP_HOST_URL}`, data, {
        headers: {
            Authorization: localStorage.getItem('id_token')
        }
    });

    return {
        type: GET_STUDENTCURRENT_TASKS,
        payload: request
    }
}
export function getLastTaskElements(student:any) {
    const data:FormData = new FormData();
    data.set('student', JSON.stringify(student));

    const request = axios.post(`${ROOT_URL}/GetStudentLastTasks${SP_HOST_URL}`, data, {
        headers: {
            Authorization: localStorage.getItem('id_token')
        }
    });

    return {
        type: GET_STUDENTLAST_TASKS,
        payload: request
    }
}
export function getSavedTaskElements(student:any) {
    const data:FormData = new FormData();
    data.set('student', JSON.stringify(student));
    const request = axios.post(`${ROOT_URL}/GetStudentSavedTasks${SP_HOST_URL}`, data, {
        headers: {
            Authorization: localStorage.getItem('id_token')
        }
    });

    return {
        type: GET_STUDENTSAVED_TASKS,
        payload: request
    }
}
export function getScheduleElements(student:any, week:number, year:number) {

    const data:FormData = new FormData();
    data.set('student', JSON.stringify(student));
    data.set('week', week.toString());
    data.set('year', year.toString());

    const request = axios.post(`${ROOT_URL}/GetClassWeekStudent${SP_HOST_URL}`, data, {
        headers: {
            Authorization: localStorage.getItem('id_token')
        }
    });

    return {
        type: GET_STUDENTSCHEDULE_ELEMENTS,
        payload: request
    }
}
export function getStudentElements(student:any,from:any,to:any) {
    const data:FormData = new FormData();
    data.set('Student', JSON.stringify(student));
    data.set('From', from);
    data.set('To', to);
    const request = axios.post(`${ROOT_URL}/GetStudentPortfolioStudent${SP_HOST_URL}`, data, {
        headers: {
            Authorization: localStorage.getItem('id_token')
        }
    });

    return {
        type: GET_STUDENT_PORTFOLIOSTUDENT,
        payload: request
    }
}
export function getStudentTaskDeliver(task:any) {

    const request = axios.post(`${ROOT_URL}/GetStudentTaskDeliver${SP_HOST_URL}`, task, {
        headers: {
            Authorization: localStorage.getItem('id_token')
        }
    });

    return {
        type: GET_STUDENT_TASKDELIVER,
        payload: request
    }
}
export function getStudentIUPlist() {

    const request = axios.get(`${ROOT_URL}/GetStudentIUPS${SP_HOST_URL}`, {
        headers: {
            Authorization: localStorage.getItem('id_token')
        }
    });

    return {
        type: GET_STUDENT_IUPLIST,
        payload: request
    }
}
export function getStudentIUP(iup:any) {

    const request = axios.post(`${ROOT_URL}/getStudentIUP${SP_HOST_URL}`, iup, {
        headers: {
            Authorization: localStorage.getItem('id_token')
        }
    });

    return {
        type: GET_STUDENT_IUP,
        payload: request
    }
}
export function addDeliveryFile(file:any) {

    const request = axios.post(`${ROOT_URL}/AddDeliveryFile${SP_HOST_URL}`, file, {
        headers: {
            Authorization: localStorage.getItem('id_token')
        }
    });

    return {
        type: ADD_STUDENT_DELIVERYFILE,
        payload: request
    }
}
export function removeDeliveryFile(file:any) {

    const request = axios.post(`${ROOT_URL}/removeDeliveryFile${SP_HOST_URL}`, file, {
        headers: {
            Authorization: localStorage.getItem('id_token')
        }
    });

    return {
        type: REMOVE_STUDENT_DELIVERYFILE,
        payload: request
    }
}
export function submitTask(task:any) {

    const request = axios.post(`${ROOT_URL}/SubmitStudentTask${SP_HOST_URL}`, task, {
        headers: {
            Authorization: localStorage.getItem('id_token')
        }
    });

    return {
        type: SUBMIT_STUDENT_TASK,
        payload: request
    }
}

export function saveLink(link:any) {

    const request = axios.post(`${ROOT_URL}/SaveStudentLink${SP_HOST_URL}`, link, {
        headers: {
            Authorization: localStorage.getItem('id_token')
        }
    });

    return {
        type: SAVE_STUDENT_LINK,
        payload: request
    }
}

export function getStudentQuizzes(){

    const request = axios.get(`${ROOT_URL}/GetStudentQuizzes${SP_HOST_URL}`, {
        headers: {
            Authorization: localStorage.getItem('id_token')
        }
    });

    return{
        type: GET_STUDENT_QUIZZES,
        payload: request
    }
}

export const getStudentQuizAttempt = (attemptId : number) =>{

    const payload = {
        Id: attemptId
    };

    const request = axios.post(`${ROOT_URL}/GetStudentQuizAttempt${SP_HOST_URL}`, payload, {
        headers: {
            Authorization: localStorage.getItem('id_token')
        }
    });

    return{
        type: GET_STUDENT_QUIZ_ATTEMPT,
        payload: request
    }
};
export const getStudentQuizAttemptGrade = (attemptId : number) =>{

    const payload = {
        Id: attemptId
    };

    const request = axios.post(`${ROOT_URL}/GetStudentQuizAttemptGrade${SP_HOST_URL}`, payload, {
        headers: {
            Authorization: localStorage.getItem('id_token')
        }
    });

    return{
        type: GET_STUDENT_QUIZ_ATTEMPTGRADE,
        payload: request
    }
};

export const createStudentQuizAttempt = (quiz : any) =>{

    
    const request = axios.post(`${ROOT_URL}/CreateStudentQuizAttempt${SP_HOST_URL}`, quiz, {
        headers: {
            Authorization: localStorage.getItem('id_token')
        }
    });

    return{
        type: CREATE_STUDENT_QUIZ_ATTEMPT,
        payload: request
    }
};
export const saveQuizAnswer = (answer: IStudentQuizAttemptDto) =>{

    const request = axios.post(`${ROOT_URL}/SaveQuizAnswer${SP_HOST_URL}`, answer, {
        headers: {
            Authorization: localStorage.getItem('id_token')
        }
    });

    return{
        type: SAVE_QUIZ_ANSWER,
        payload: request
    }
};

export function initStatusTask(files:any[]) {



    return {
        type: INIT_SUBMISSION_STATUS,
        payload: files
    }
}

export function checkStatusTask(files:any[]) {

    const request = axios.post(`${ROOT_URL}/CheckSubmission${SP_HOST_URL}`, files, {
        headers: {
            Authorization: localStorage.getItem('id_token')
        }
    });

    return {
        type: CHECK_SUBMISSION_STATUS,
        payload: request
    }
}
export function getNewsFiles(news:any) {

    const request = axios.post(`${ROOT_URL}/GetNewsFilesStudent${SP_HOST_URL}`, news, {
        headers: {
            Authorization: localStorage.getItem('id_token')
        }
    });

    return {
        type: GET_STUDENTNEWS_FILES,
        payload: request
    }
}
export function getComments(st:any) {

    const request = axios.post(`${ROOT_URL}/GetCommentsStudent${SP_HOST_URL}`, st, {
        headers: {
            Authorization: localStorage.getItem('id_token')
        }
    });

    return {
        type: GET_STUDENTTASK_COMMENTS,
        payload: request
    }
}