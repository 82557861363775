import * as React from 'react';
import * as moment from 'moment';
import * as _ from 'lodash';
import { connect } from 'react-redux';
moment.locale('nb');
import { getNewsFiles } from "../../../actions/Action_Ukeplan";
import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from 'react-html-parser';
import { ActionButton, Dialog, DialogFooter, Spinner, SpinnerSize } from 'office-ui-fabric-react';

export interface IStudentNewsElementViewProps {
    newselement: any;
    newsfiles: any[];
    getNewsFiles(news: any);
}
export interface IStudentNewsElementViewState {
    loadingFiles: boolean;
    showDialog: boolean;
}


class StudentNewsElementView extends React.Component<IStudentNewsElementViewProps, IStudentNewsElementViewState>{
    constructor(props) {
        super(props);

        this.state = {
            loadingFiles: false,
            showDialog: false
        }
    }

    getActioButtonText = () => {
        return (this.props.newselement.NewsDocuments  && this.props.newselement.NewsDocuments.length > 0) ? "Vis hele meldingen med vedlegg" : "Vis hele meldingen";
    }

    render() {

        return (
            <li className="list-group-item">
            <dl>
                <dt>
                    <div className="plan-kort-header">
                        <h3>
                            <i className="ms-Icon ms-Icon--Message" aria-hidden="true"></i>
                            {this.props.newselement.Title}
                        </h3>
                    </div>
                </dt>
                <dd>
                    {/* {
                        this.props.newselement.Classes.length > 0 &&
                        <span>
                            Klasser: {this.props.newselement.Classes}
                        </span>
                        
                    } */}
                    <br />
                    <span className="remove-para-margin ql-editor ql-background" 
                        dangerouslySetInnerHTML={{__html:this.props.newselement.TextRaw}} 
                    >
                    </span>
                    {/* {this.props.newselement.TextRaw} */}
                    {((this.props.newselement.NewsDocuments  && this.props.newselement.NewsDocuments.length > 0)) && <ActionButton
                        iconProps={{iconName: "RedEye"}}
                        //text={this.getActioButtonText()}
                        onClick={() => {
                            this.setState({loadingFiles: true});
                            this.props
                            .getNewsFiles(this.props.newselement)
                            .then(() => {
                                this.setState({ showDialog: true, loadingFiles: false });
                            })
                            .catch(error => {
                                confirm("Noe gikk galt under hentingen av meldingen");
                                this.setState({loadingFiles: false});
                            });
                        }}
                    >
                        <span style={{marginRight: 5}}>{this.getActioButtonText()}</span> 
                        {!this.state.loadingFiles ? "" : <Spinner size={SpinnerSize.small}></Spinner>}
                    </ActionButton> }
                </dd>
            </dl>
            <Dialog
                hidden={!this.state.showDialog}
                onDismiss={() => {
                    this.setState({ showDialog: false });
                }}
                styles={{
                    main: [
                    {
                        selectors: {
                        ["@media (min-width: 480px)"]: {
                            maxWidth: "600px",
                            minWidth: "480px"
                        }
                        }
                    }
                    ]
                }}
                modalProps={{
                    isBlocking: true,
                    containerClassName: "ms-dialogMainOverride"
                }}
                >
                <div
                    className="remove-para-margin ql-editor ql-background"
                    style={{marginBottom: 20}}
                    dangerouslySetInnerHTML={{__html: this.props.newselement.TextRaw}}
                />
                <ul>
                    {this.props.newsfiles &&
                    this.props.newsfiles.map(f => {
                        return (
                        <li>
                            <a href={f.FileUrl} target="_blank">
                            {f.FileName}
                            </a>
                        </li>
                        );
                    })}
                </ul>
                <DialogFooter>
                    <ActionButton
                    onClick={() => {
                        this.setState({ showDialog: false });
                    }}
                    text="Lukk"
                    />
                </DialogFooter>
            </Dialog>
        </li>
              
        )
    }

}
function mapStateToProps(state) {

    return {
        newsfiles: state.ukeplanelements.NewsFiles,
    }
}
export default connect(mapStateToProps, { getNewsFiles })(StudentNewsElementView);