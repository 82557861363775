import * as React from "react";
import { connect } from "react-redux";
import {getStudentQuizzes} from "../../../actions/Action_Student";
import HeaderView from "../../view_containers/HeaderView";
import {IStudentQuiz} from "../../../models/student/IStudentQuiz";
import StudentQuizTile from "./StudentQuizTile";

import './StudentQuiz.scss';
import {getActiveQuizzes, getInactiveQuizzes} from "../../../utils/StudentQuizUtils";
import NoContent from "../../view_containers/NoContent";
import SpinLoader from "../../loading/SpinLoader";

interface IStudentQuizOverviewProps {
    activeQuizzes: IStudentQuiz[];
    inactiveQuizzes: IStudentQuiz[];
    history:any;
    getStudentQuizzes:()=>any;
}

interface IStudentQuizOverviewState {
    awaitingResponse: boolean;
}

class StudentQuizOverview extends React.Component<IStudentQuizOverviewProps, IStudentQuizOverviewState>{

    constructor(props:IStudentQuizOverviewProps){
        super(props);

        this.state = {
            awaitingResponse: false
        }
        }

    componentDidMount(){
        this.getStudentQuizzes();
    }

    getStudentQuizzes = () =>{

        this.setState({awaitingResponse: true});

        this.props.getStudentQuizzes()
            .then(response => {
                this.setState({awaitingResponse: false});
            })
            .catch(error =>{
                this.setState({awaitingResponse: false});
            })
    };

    render(){

        const {activeQuizzes, inactiveQuizzes} = this.props;
        const {awaitingResponse} = this.state;

        const hasAnyActiveQuizzes : boolean = awaitingResponse || activeQuizzes.length && !awaitingResponse;
        const hasAnyInactiveQuizzes : boolean = awaitingResponse || inactiveQuizzes.length && !awaitingResponse;

        return(
            <div className="main">
                <HeaderView
                    title= "Mine Prøver"
                    description="På denne siden finner du dine aktive og historiske prøver."
                    iconName="PageListSolid"
                />
                <div className="ms-Grid">
                    <div className="quiz-section ms-Grid-row ms-sm12">
                        <h2>Aktive Prøver</h2>
                        <hr />
                        <NoContent
                            text="Du har ingen aktive Prøver"
                            hide={hasAnyActiveQuizzes}
                        />
                        <SpinLoader
                            text="Laster aktive prøver"
                            hide={!!activeQuizzes.length || !awaitingResponse}
                        />
                        {activeQuizzes.map(quiz =>(
                            <StudentQuizTile quiz={quiz} history={this.props.history}/>
                        ))}
                    </div>
                    <div className="quiz-section ms-Grid-row ms-sm12">
                        <h2>Tidligere Prøver</h2>
                        <hr />
                        <NoContent
                            text="Du har ingen tidligere Prøver"
                            hide={hasAnyInactiveQuizzes}
                        />
                        <SpinLoader
                            text="Laster tidlgere prøver"
                            hide={!!inactiveQuizzes.length || !awaitingResponse}
                        />
                        {inactiveQuizzes.map(quiz =>(
                            <StudentQuizTile quiz={quiz}/>
                        ))}
                    </div>
                </div>
            </div>
        )
    }
}


const mapStateToProps = (state, ownProps) =>{

    const quizzes = state.StudentReducer.student.quizzes || [];

    const activeQuizzes = getActiveQuizzes(quizzes);
    const inactiveQuizzes = getInactiveQuizzes(quizzes, activeQuizzes);

    return{
        activeQuizzes,
        inactiveQuizzes
    }
};

export default connect(mapStateToProps, {getStudentQuizzes})(StudentQuizOverview);