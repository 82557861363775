import * as React from "react";
import * as moment from "moment";
import * as _ from "lodash";
import { connect } from "react-redux";
moment.locale("nb");
import ReactHtmlParser, {
  processNodes,
  convertNodeToElement,
  htmlparser2,
} from "react-html-parser";
import { withRouter } from "react-router-dom";
import { PlanElement } from "../../models/PlanElement";
import { ActionButton, IButtonProps, IconButton } from "office-ui-fabric-react";
import { Label } from "office-ui-fabric-react/lib/Label";
import { TextField } from "office-ui-fabric-react/lib/TextField";
import FilesModal from "./FilesModal";
import ImmersiveReader from "../shared/ImmersiveReader";
import { capitalizeFirstLetter } from "../../utils/HelperFunctions";
import UkeplanElementModal from "./UkeplanElementModal";
import { Course } from "../../models/Course";
import ReactTooltip from 'react-tooltip';
//import { getPlanElementFiles } from "../../actions/Action_Ukeplan";
export interface IPlanelementViewProps {
  planelement: PlanElement;
  history: any;
  match: any;
  compactCardView: boolean;
  course: Course;

  // getPlanElementFiles(id: number);
}
export interface IPlanelementViewState {
  showfilesmodal: boolean;
  myhtml: any;
  showContentModal: boolean;
}

class PlanElementView extends React.Component<
  IPlanelementViewProps,
  IPlanelementViewState
> {
  constructor(props) {
    super(props);
    this.state = { showfilesmodal: false, myhtml: null, showContentModal: false };
  }

  render() {
    if (!this.props) {
      return <div>tom</div>;
    }
    return (
      <li
        className="list-group-item"
        ref={(input) => {
          if (!this.state.myhtml) {
            this.setState({ myhtml: input });
          }
        }}
      >
        <div className="ms-Grid-row">
          <div className="plan-kort-header">
            {this.props.planelement.Theme && (
              <h3>
                <i
                  className="ms-Icon ms-Icon--CalendarWeek"
                  aria-hidden="true"
                ></i>
                <span>{this.props.planelement.Theme}</span>
              </h3>
            )}
            <div className="plan-kort-header--flex-item">
              {this.props.planelement.AssignmentGroup && (
                <div
                  style={{
                    fontWeight: "bolder",
                    fontSize: 16,
                    marginRight: 10,
                  }}
                >
                  <i
                    className="ms-Icon ms-Icon--Group"
                    style={{
                      marginRight: 5,
                      verticalAlign: "middle",
                      fontSize: 20,
                    }}
                    title="Tilretteleggingsgruppe"
                  ></i>
                  {this.props.planelement.AssignmentGroup.Name}
                </div>
              )}

              {this.state.myhtml && (
                <ImmersiveReader
                  htmlcontent={this.state.myhtml}
                ></ImmersiveReader>
              )}

              <IconButton 
                data-tip
                data-for={`tooltip-task-${this.props.planelement.Id}`}
                iconProps={{ iconName: "MiniExpand", styles: {root: {fontSize: 18}}}} 
                // style={{fontSize: 20, margin: "0 10px", cursor: "pointer"}}
                onClick={() => this.setState({showContentModal: true})}
                style={{margin: "0 5px", alignItems: "start"}}
              ></IconButton>
              <ReactTooltip id={`tooltip-task-${this.props.planelement.Id}`}>
                Åpne i forstørret vindu
              </ReactTooltip>
              <UkeplanElementModal showModal={this.state.showContentModal} onDismiss={() => this.setState({showContentModal: false})} planElement={this.props.planelement} course={this.props.course}></UkeplanElementModal>
            </div>
          </div>
        </div>

        {/* <div>
            {}
            <Label className="list-group-item--label">
              Tilretteleggingsgruppe:
            </Label>
            {this.props.planelement.AssignmentGroup && (
              <div style={{fontWeight: 'bolder', fontSize: 15}}>
                <i className="ms-Icon ms-Icon--Group" style={{marginRight: 5, fontSize: 16}}></i>{this.props.planelement.AssignmentGroup.Name}
              </div>
            )}
          </div> */}

        {this.props.planelement.GrepCodeList.length > 0 && (
          <div>
            <Label className="list-group-item--label">Kompetansemål</Label>
            <dd>
              {this.props.planelement.GrepCodeList.length > 1 && this.props.compactCardView ? <span>{this.props.planelement.GrepCodeList.length} Kompetansemål</span> : <ul className="grep-list">
                {this.props.planelement.GrepCodeList.map((element) => {
                  return (
                    <li>
                      <a href={element.DetailUrl} target="_blank">
                        {element.GrepTekst}
                      </a>
                  </li>
                  );
                })}
              </ul>}
            </dd>
          </div>
        )}

        {this.props.planelement.SkolonResources && this.props.planelement.SkolonResources.length > 0 && (
          <>
          <div>
            <Label className="list-group-item--label"> <img width='40px' src={require('../../images/SkolonIcon.svg')} /></Label>
          </div>
            <dd>
            <ul className="grep-list">
              {this.props.planelement.SkolonResources.map((element) => {
                return (
                  <li>
                    <a href={element.url} target="_blank">
                      {element.name}
                    </a>
                  </li>
                );
              })}
            </ul>
          </dd>
          </>
        )}

      
        {this.props.planelement.Goal && (
          <div>
            <Label className="list-group-item--label">Egendefinerte mål</Label>
            <dd>
              <span
                className={`remove-para-margin list-group-unordered ${this.props.compactCardView ? "hideOverflow" : ""}`}
                dangerouslySetInnerHTML={{
                  __html: this.props.planelement.GoalRaw,
                }}
              ></span>
              {/* {this.props.planelement.GoalRaw} */}
            </dd>
          </div>
        )}

        {
          // this.props.planelement.EvalElements || this.props.planelement.EvaluationTextOther &&

          this.props.planelement.EvalElements.length > 0 && (
            <div>
              <Label className="list-group-item--label">Vurdering</Label>
              <dd className="scheduleEvaluation">
                <ul>
                  {this.props.planelement.EvalElements.map((element) => {
                    return <li className={`${this.props.compactCardView ? "hideOverflow" : ""}`}>{element} </li>;
                  })}
                </ul>
              </dd>
            </div>
          )
        }

        {this.props.planelement.TextOnPrem && (
          <div>
            <Label className="list-group-item--label">Arbeid på skolen</Label>
            <dd>
              <span
                className={`remove-para-margin list-group-unordered ${this.props.compactCardView ? "hideOverflow" : ""}`}
                dangerouslySetInnerHTML={{
                  __html: this.props.planelement.TextonPremRaw,
                }}
              ></span>
              {/* {this.props.planelement.TextOnPremRaw} */}
            </dd>
          </div>
        )}
        {this.props.planelement.TextRaw && (
          <div>
            <Label className="list-group-item--label">Arbeid hjemme</Label>
            <dd>
              <span
                className={`remove-para-margin list-group-unordered ${this.props.compactCardView ? "hideOverflow" : ""}`}
                dangerouslySetInnerHTML={{
                  __html: this.props.planelement.TextRaw,
                }}
              ></span>
              {/* {this.props.planelement.TextRaw} */}
            </dd>
          </div>
        )}

        {this.props.planelement.Link && (
          <div>
            <Label className="list-group-item--label">Lenke</Label>
            <dd className="planLenke">
              <a target="_blank" href={this.props.planelement.Link}>
                {this.props.planelement.Link}
              </a>
            </dd>
          </div>
        )}

        <Label className="list-group-item--label">Frist</Label>
        <dd>{capitalizeFirstLetter(moment(this.props.planelement.Date).format("dddd").toString())} {this.props.planelement.DateText}</dd>

        <div className="ms-Grid-row">
          <div className="oppgaveKnapper">
            <div className="flex-button">
              {this.props.planelement.HasFiles && (
                <ActionButton
                  iconProps={{ iconName: "Attach" }}
                  onClick={() => {
                    // getPlanElementFiles(this.props.planelement.Id);
                    this.setState({ showfilesmodal: true });
                  }}
                  style={{ flex: 1 }}
                >
                  Se filer
                </ActionButton>
              )}
              <ActionButton
								text="Kopier til ny gruppe"
                style={{textAlign: "left"}}
								iconProps={{ iconName: 'Copy' }}
								onClick={() => {
									this.props.history.push(`/ukeplan/${this.props.planelement.Id}/c`);
								}}
							/>
              <ActionButton
                onClick={() => {
                  this.onEditPlan(this.props.planelement);
                }}
                iconProps={{ iconName: "Edit" }}
                text="Rediger"
              />
            </div>
          </div>
        </div>
        {
          this.state.showfilesmodal && (
            <FilesModal
              mode={1}
              id={this.props.planelement.Id}
              closemodal={() => {
                this.setState({ showfilesmodal: false });
              }}
            />
          )
          // <FilesModal  />
        }
      </li>
    );
  }
  onEditPlan(pe: any) {
    this.props.history.push("/ukeplan/" + pe.Id + "/e");
  }
}

function mapStateToProps(state) {
  return {
    compactCardView: state.ukeplanelements.CompactCardView
  };
}
export default connect(mapStateToProps, {})(withRouter(PlanElementView));
