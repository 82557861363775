import "../../formelements.scss";

import * as React from "react";

import { withRouter, Link } from "react-router-dom";
import { connect } from "react-redux";
import { Field, reduxForm, change, getFormValues } from "redux-form";
import { planUtils } from "./PlanElementEditView";
import {
  PrimaryButton,
  ActionButton,
  DefaultButton,
  Label,
  IconButton,
  Icon,
  Checkbox,
  Spinner,
  SpinnerSize,
  Dialog,
  DialogFooter,
  DialogType,
  Dropdown,
  Shimmer,
} from "office-ui-fabric-react";
import {
  updateTaskElement,
  getAssignmentGroup,
  deleteTaskElement,
  updateTeamTaskElement,
} from "../../actions/Action_Ukeplan";
import { getRecipients, getTeacherState, getStudentsFromCourse } from "../../actions/Action_Teacher";
import KmpgoalPicker from "../shared/KmpgoalPicker";
import axios from "axios";
import { store } from 'react-notifications-component';
import OneDrivePicker from "../shared/OneDrivePicker";
import {
  dateStringToMoment,
  isDateStringBeforeToday,
} from "../../utils/DateUtils";
import SelectField from "../form_fields/SelectField";
import InputField from "../form_fields/InputField";
import RichTextField from "../form_fields/RichTextField";
import DateTimeField from "../form_fields/DateTimeField";
import CheckboxField from "../form_fields/CheckboxField";
import AssignmentPicker from "../graph/AssignmentPicker";
import {
  createAssignment,
  createRubric,
  attachRubric,
  clearCurrentAssignment,
} from "../../actions/Action_Graph";
import { isSameGroupAsBefore } from "../../guards/CopyPlanGuard";
import { ROOT_URL, SP_HOST_URL } from "../../actions/constants";
import MultiSelectField from "../form_fields/MultiSelectField";
import { stringify } from "querystring";
import SkolonPicker from "../shared/SkolonPicker";

export interface ITaskElementFormProps {
  formValues: any; // TODO: lag klasse eller interface for TaskElement

  teacherstate: any;

  taskElement: any;
  courses: any;
  groups: any[];
  schoolClasses: any;
  students: any[];

  initialValues: any;
  handleSubmit: any;
  history: any;
  match: any;
  deletePlanElement(se, callback);
  deleteTaskElement(se, callback);
  getAssignmentGroup(course: any);
  updateTeamTaskElement(se);
  createAssignment(classid: string, task: any);
  createRubric(rubric: any);
  attachRubric(rubric: any, assignment: any);
  getRecipients(statedata: any);
  getStudentsFromCourse(courseID: string);
  clearCurrentAssignment();
  selectedfiles: any[];
  selected: any[];
  skolonpicks:any[];
  change: (key: string, value) => void;
  courseteams: any;
  onsave();
  mode: string;
  showskolon: boolean;
}

export interface ITaskElementFormState {
  showkmp: boolean;
  selected: any[];
  showonedrive: boolean;
  selectedfiles: any[];
  classes: any[];
  members: any;
  recipients: any[];
  hasteam: boolean;
  teamid: string;
  connectedteamstask: any;
  saving: boolean;
  assignmentgroupStudents: any[],
  students: any[];
  attemptedToSave: boolean;
  loadingStudents: boolean;
  showCopyPlanDialog;
  skolonpicks: any[];
}

class TaskElementForm extends React.Component<
  ITaskElementFormProps,
  ITaskElementFormState
> {
  constructor(props) {
    super(props);
    this.state = {
      showkmp: false,
      selected: null,
      showonedrive: false,
      selectedfiles: null,
      classes: [],
      members: {},
      recipients: [],
      hasteam: false,
      teamid: "",
      connectedteamstask: null,
      saving: false,
      showCopyPlanDialog: false,
      students: [],
      assignmentgroupStudents: [],
      attemptedToSave: false,
      loadingStudents: false,
      skolonpicks: []
    };
  }

  componentWillReceiveProps(nextProps: ITaskElementFormProps) {
    if (!this.state.selectedfiles) {
      this.setState({
        selectedfiles: this.props.selectedfiles,
        selected: this.props.selected,
        skolonpicks:this.props.skolonpicks
      });
    }

    // Setter tilretteleggingsgruppe til 'Alle' dersom fag har blitt valgt for første gang
    if (nextProps.formValues.CourseID && !this.props.formValues.CourseID) {
      this.props.change("GroupID", 0);
      this.props.change("Elever", "");
    }
  }

  async componentDidMount(): Promise<void> {
      
    this.props.clearCurrentAssignment();
    if (this.props.initialValues.GroupID) {
      await this.getGroupStudents(this.props.initialValues.GroupID)
    }
    if (this.props.initialValues.CourseID != null && this.props.initialValues.CourseID != "") {
      await this.props.getStudentsFromCourse(this.props.initialValues.CourseID);
    }
  }

  /****************************
            Event handlers
     ****************************/

  onFormSubmit(values) {
    console.log(values)
    const requestValues = this.formatFormValueDatesToDateString(values);
    let tmp = requestValues;
    tmp = JSON.stringify(tmp);
    tmp = JSON.parse(tmp)
    if(tmp.Goal){
      if(tmp.Goal == "<p><br></p>" || tmp.Goal.replace(/\s/g, "") == "<p></p>"){
        tmp.Goal = "";
      }
    }
    if(tmp.Text){
      if(tmp.Text == "<p><br></p>" || tmp.Text.replace(/\s/g, "") == "<p></p>"){
        tmp.Text = "";
      }
    }
    tmp.GrepCodeList = this.state.selected;
    tmp.TaskFiles = this.state.selectedfiles;
    tmp.SkolonResources = this.state.skolonpicks;
    tmp.TaskDocument = "";

    if (tmp.GroupID === 0) {
      tmp.Elever = "";
    }

    this.state.selectedfiles.forEach((element) => {
      if (tmp.TaskDocument.length > 0) {
        tmp.TaskDocument += ";";
      }
      tmp.TaskDocument += element.OriginalDocumentID + "|" + element.FileName;
    });

    tmp.Grep = "";
    this.state.selected.forEach((element) => {
      if (tmp.Grep.length > 0) {
        tmp.Grep += ";";
      }
      tmp.Grep += element.KMID + "|" + element.LPID + "|" + element.GrepTekst;
    });
    let evals = [];
    if (requestValues.CheckInnlevering) {
      evals.push("Innlevering");
    }
    if (requestValues.CheckPresentasjon) {
      evals.push("Presentasjon");
    }
    if (requestValues.CheckProve) {
      evals.push("Prøve");
    }
    if (requestValues.CheckTest) {
      evals.push("Test");
    }
    if (requestValues.CheckLeksesjekk) {
      evals.push("Leksesjekk");
    }
    tmp.EvalElements = evals;
    let qual = [];
    this.state.selected.forEach((element) => {
      qual.push({
        description: {
          content: `${element.LPID}|${element.KMID}|${element.GrepTekst}`,
          contentType: "text",
        },
        criteria: [
          {
            description: {
              content: "Ikke vurdert",
              contentType: "text",
            },
          },
          {
            description: {
              content: "Eleven viser lav måloppnåelse",
              contentType: "text",
            },
          },
          {
            description: {
              content: "Eleven viser middels måloppnåelse",
              contentType: "text",
            },
          },
          {
            description: {
              content: "Eleven viser høy måloppnåelse",
              contentType: "text",
            },
          },
        ],
      });
    });
    const educationRubric = {
      displayName: "Kompetansemål",
      description: {
        content: "",
        contentType: "text",
      },
      levels: [
        {
          displayName: "Ikke vurdert",
          description: {
            content: "",
            contentType: "text",
          },
          grading: null,
        },
        {
          displayName: "Lav",
          description: {
            content: "",
            contentType: "text",
          },
          grading: null,
        },
        {
          displayName: "Middels",
          description: {
            content: "",
            contentType: "text",
          },
          grading: null,
        },
        {
          displayName: "Høy",
          description: {
            content: "",
            contentType: "text",
          },
          grading: null,
        },
      ],
      qualities: qual,
      grading: null,
    };
    if (this.state.connectedteamstask && this.state.connectedteamstask.id) {
      tmp.TeamsTask = this.state.connectedteamstask.id;
     console.log(this.state.connectedteamstask);
      if (
        this.state.connectedteamstask["assignTo"]["@odata.type"] ===
        "#microsoft.graph.educationAssignmentIndividualRecipient"
      ) {
        alert("Not supported");
      } else if (this.state.connectedteamstask.state == "assigned") {
      //  console.log("2", tmp);
        updateTeamTaskElement(tmp, (bu) => {
          if (this.props.history) {
            this.props.history.push("/task/distribute/" + bu.Id);
          } else {
            this.props.onsave();
          }
        });
      } else {
      //  console.log("3");
        this.props.createRubric(educationRubric).then((respr) => {
          this.props
            .attachRubric(respr.payload, this.state.connectedteamstask)
            .then(() => {
              tmp.TeamsTask = this.state.connectedteamstask.id;
              updateTaskElement(tmp, (bu) => {
                if (this.props.history) {
                  this.props.history.push("/task/distribute/" + bu.Id);
                } else {
                  this.props.onsave();
                }
              }, () => this.onUpdateElementError());
            });
        });
        // updateTaskElement(tmp, (bu) => {
        //   this.props.history.push("/task/distribute/" + bu.Id);
        // });
      }
    } else if (
      this.state.connectedteamstask &&
      !this.state.connectedteamstask.id
    ) {
     //// console.log("4");
     // console.log("opprett ny");
     // console.log(tmp);

      let newteamstask = {
        dueDateTime: dateStringToMoment(tmp.Deadline).toISOString(),
        closeDateTime: dateStringToMoment(tmp.Deadline).toISOString(),
        // "assignDateTime":dateStringToMoment(tmp.ShowFrom).toISOString(),
        displayName: tmp.Name,
        instructions: {
          contentType: "text",
          content: tmp.Text,
        },
        grading: {
          "@odata.type":
            "#microsoft.education.assignments.api.educationAssignmentPointsGradeType",
          maxPoints: 100,
        },
        assignTo: {
          "@odata.type":
            "#microsoft.education.assignments.api.educationAssignmentClassRecipient",
        },
        status: "draft",
        allowStudentsToAddResourcesToSubmission: true,
      };
      this.props
        .createAssignment(this.state.teamid, newteamstask)
        .then((resp) => {
          this.props.createRubric(educationRubric).then((respr) => {
         //   console.log("resp", resp);
         //   console.log("respr", respr);
            this.props.attachRubric(respr.payload, resp.payload).then(() => {
             // console.log(resp.payload.id);
              tmp.TeamsTask = resp.payload.id;
              updateTaskElement(tmp, (bu) => {
                if (this.props.history) {
                  this.props.history.push("/task/distribute/" + bu.Id);
                } else {
                  this.props.onsave();
                }
              }, () => this.onUpdateElementError());
            });
          });
        });
      // this.props.createRubric(educationRubric).then((resp) => {
      //   console.log(resp);
      // });
    } else {
    //  console.log("5");
    console.log(tmp);
      updateTaskElement(tmp, (bu) => {
     //   console.log('hist',this.props.history);
     console.log(bu);
        if (this.props.history) {
          this.props.history.push("/task/distribute/" + bu.Id);
        } else {
          this.props.onsave();
        }
      }, () => this.onUpdateElementError());
    }
  }

  onDeleteClick() {
    if (confirm("Vil du virkelig slette denne innleveringen?")) {
      this.props.deleteTaskElement(this.props.taskElement, () => {
        this.props.history.push("/ukeplan");
      });
    }
  }

  onUpdateElementError() {
    this.setState({
      saving: false,
      showCopyPlanDialog: false
    });
    store.addNotification({
      title: "Noe gikk galt!",
      message: 'Kunne ikke lagre endringene, vennligst prøv igjen senere.',
      type: "danger",
      insert: "top",
      container: "top-right",
      animationIn: ["animated", "fadeIn"],
      animationOut: ["animated", "fadeOut"],
      dismiss: {
        duration: 5000,
        onScreen: true,
      }
      });
  }

  /****************************
            Helper methods
     ****************************/

  formatFormValueDatesToDateString(values) {
    return values;
  }

  getGroupStudents = async (GroupID: number) => {
    let ind = { Id: GroupID };

    try {
      this.setState({loadingStudents: true})
      const result = await axios.post(`${ROOT_URL}/GetStudentsGroup`, ind, {
        headers: {
          Authorization: localStorage.getItem("id_token"),
        },
      });

      this.setState({ assignmentgroupStudents: result.data});
    }
    catch (error) {

    }
    finally {
      this.setState({loadingStudents: false})
    }
  }

  focusTopRef = () =>{
    // document.querySelector("#root > div > div > div:nth-child(3) > div:nth-child(3)").scrollTo(0,0);
    document.querySelector("#root > div > div > div:nth-child(3) > div:nth-child(3)").scrollTo(0,0);
  }
  /****************************
            Render methods
     ****************************/

  onCourseChanged = async (CourseID: string) => {
   console.log("CourseID", CourseID);
    await this.props.getAssignmentGroup({ CourseID });
    if (this.props.courseteams[CourseID.toString()]) {
     // console.log("hasteam");
      this.setState({
        hasteam: true,
        teamid: this.props.courseteams[CourseID.toString()],
      });
    } else {
      this.setState({ hasteam: false });
    }
    let theclass = "";
    this.state.classes.forEach((element) => {
      if (element.displayName == CourseID) {
        theclass = element.id;
      }
    });
    
    this.props.change("GroupID", 0);
    this.props.change("Elever", "");
    await this.props.getStudentsFromCourse(CourseID);
    // const res = await axios.get(`${ROOT_URL}/GetStudentsFromCourse/${SP_HOST_URL}&courseID=${CourseID}`, {
    //   headers: {
    //       Authorization: localStorage.getItem('id_token')
    //   }
    // });

    // console.log("StudenCourse RES:", res)

    // this.setState({students: res.data});
  };

  onGroupChanged = async (GroupID: number) => {
    let ind = { Id: GroupID };

    try {
      this.setState({loadingStudents: true})
      const result = await axios.post(`${ROOT_URL}/GetStudentsGroup`, ind, {
        headers: {
          Authorization: localStorage.getItem("id_token"),
        },
      });
        
      let r = [];
  
      result.data.forEach((element) => {
        if (this.state.members[element.LoginName]) {
          r.push(this.state.members[element.LoginName].id);
        }
      });
      this.setState({ recipients: r, assignmentgroupStudents: result.data});
      this.props.change("Elever", "");
        
    }
    catch (error) {

    }
    finally {
      this.setState({loadingStudents: false})
    }
  };


  renderCheckField(field) {
    const {
      input,
      meta: { touched, error },
      children,
    } = field;

    return (
      <div className="ms-Grid-col ms-sm12 ms-md6">
        <Checkbox
          checked={input.value}
          label={field.label}
          errorMessage={touched ? error : ""}
          {...field.input}
          onChange={(event, u) => {
            input.onChange(u);
          }}
        />
        <label>
          <span>{error ? "*" : ""}</span>
        </label>
      </div>
    );
  }
  render() {
    const { handleSubmit } = this.props;
    return (
      <form onSubmit={handleSubmit(this.onFormSubmit.bind(this))}>
        <div className="form-content">
          <Field
            name="CourseID"
            label="Fag"
            component={SelectField}
            required={true}
            onSelect={async (courseID) => {
              await this.onCourseChanged(courseID);
            }}
            attemptedToSave={this.state.attemptedToSave}
          >
            <option>
              {planUtils.mapPlanElementsToKeys(this.props.courses)}
            </option>
          </Field>

          <Field
            name="GroupID"
            label="Tilretteleggingsgruppe"
            component={SelectField}
            // required={true}
            disabled={!this.props.formValues.CourseID}
            onSelect={async (value: number) => await this.onGroupChanged(value)}
            attemptedToSave={this.state.attemptedToSave}
          >
            <option>
              {planUtils.mapGroupElementsToKeys(this.props.groups, true)}
            </option>
          </Field>

           <Field
            name="Elever"
            label="Elever"
            component={(props) => 
              <MultiSelectField
                label = {props.label}
                input = {props.input}
                required = {props.required}
                disabled = {props.disabled || this.props.formValues.GroupID == 0}
                // onSelect = {props.onSelect}
                defaultValue = {props.defaultValue}
                placeholder = {props.placeHolder}
                options = {this.props && this.props.students && this.props.students.length > 0 ? planUtils.mapStudentsToKeys(this.props.students) : []}
                lockedDefaultOptions = {this.state && this.state.assignmentgroupStudents && this.state.assignmentgroupStudents.length > 0 ? planUtils.mapStudentsToKeys(this.state.assignmentgroupStudents) : []}
                meta = {props.meta}
                children = {props.children}
                attemptedToSave={this.state.attemptedToSave}
                isLoading={this.state.loadingStudents}
              ></MultiSelectField>
            }
            required={false}
            disabled={!this.props.formValues.CourseID}
            >
            {/* <option>
              {this.props && this.props.students && this.props.students.length > 0 ? planUtils.mapStudentsToKeys(this.props.students) : []}
            </option> */}
          </Field>
          <Field required={true} name="Name" label="Tema" component={InputField} />

          <div className="ms-Grid-row">
            <div className="z-form-group">
              <div className="ms-Grid">
                <div className="ms-Grid-col ms-sm12 ms-md4">
                  <Label className="ms-Label z-form-label">Kompetansemål</Label>
                </div>
                {this.state.selected && (
                  <div className="ms-Grid-col ms-sm12 ms-md8">
                    <Field
                      name="ShowKmpGoals"
                      label="Vis kompetansemål på ukeplan for elever" // Custom fields variables can be fetched in component (i.e. field.label)
                      component={this.renderCheckField}
                    />
                    <div className="ms-Grid-col ms-sm12">
                      <KmpgoalPicker
                        selected={this.state.selected}
                        onClose={(selected) => {
                          this.setState({ selected: selected });
                        }}
                      />

                      <ul className="no-type-list kmp-maal-list">
                        {this.state.selected.map((grp) => {
                          return (
                            <li className="ms-Grid-row">
                              <div className="ms-Grid-col ms-sm1">
                                <IconButton
                                  style={{color: 'red'}}
                                  iconProps={{ iconName: "BoxSubtractSolid" }}
                                  title="Fjern kompetansemål"
                                  onClick={() => {
                                    let tmp = [];

                                    this.state.selected.forEach((element) => {
                                      if (grp.KMID != element.KMID) {
                                        tmp.push(element);
                                      }
                                    });
                                    this.setState({ selected: tmp });
                                  }}
                                />
                              </div>
                              <div className="ms-Grid-col ms-sm11 pt-5">
                                <span>
                                  {grp.KMID} - {grp.LPID}: {grp.GrepTekst}
                                </span>
                              </div>
                            </li>
                          );
                        })}
                      </ul>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
          {
						this.props.showskolon &&
						<div className="ms-Grid-row">
							<div className="z-form-group">
								<div className="ms-Grid-col ms-sm12 ms-md4">
									<label className="z-form-label ms-Label"><img width='40px' src={require('../../images/SkolonIcon.svg')} /></label>
								</div>

								<div className="ms-Grid-col ms-sm12 ms-md8">

									<div className="ms-Grid-col ms-sm12">
										{this.state.selected && (
											<SkolonPicker onClose={(pick) => {
												console.log('picks', pick);
                        console.log('existing', this.state.skolonpicks);
												let tmp = JSON.parse(JSON.stringify(this.state.skolonpicks));
												var index = tmp.findIndex(x => x.id == pick.id);
												if (index < 0) {
													tmp.push(pick);
													this.setState({ skolonpicks: tmp });
												}
											}} />
										)}
										<ul className="no-type-list kmp-maal-list">
											{this.state.skolonpicks &&
												this.state.skolonpicks.map((grp) => {
													return (
														<li className="ms-Grid-row">
															<div className="ms-Grid-col ms-sm1">
																<IconButton
																	className="vertical-align-middle green"
																	iconProps={{ iconName: 'BoxSubtractSolid' }}
																	title="Fjern kompetansemål"
																	onClick={() => {
																		let tmp = [];

																		this.state.skolonpicks.forEach((element) => {
																			if (grp.id != element.id) {
																				tmp.push(element);
																			}
																		});
																		this.setState({ skolonpicks: tmp });
																	}}
																/>
															</div>
															<div className="ms-Grid-col ms-sm11 pt-5">
																<span>

																	{grp.name}
																</span>
															</div>
														</li>
													);
												})}
										</ul>
									</div>
								</div>
							</div>
						</div>
					}
          {/* {this.state.hasteam && (
            <div className="ms-Grid-row">
              <div className="z-form-group">
                <div className="ms-Grid">
                  <div className="ms-Grid-col ms-sm12 ms-md4">
                    <Label className="ms-Label z-form-label">Teams</Label>
                  </div>

                  <div className="ms-Grid-col ms-sm12 ms-md8">
                    <div className="ms-Grid-col ms-sm12">
                      <AssignmentPicker
                        classID={this.state.teamid}
                        onClose={(selected) => {
                        //  console.log("theselection", selected);
                          if (selected.length > 0) {
                            this.setState({ connectedteamstask: selected[0] });
                            this.props.change(
                              "Text",
                              selected[0].instructions.content
                            );
                            this.props.change("Name", selected[0].displayName);
                          }
                        }}
                      ></AssignmentPicker>
                      {this.state.connectedteamstask && (
                        <div>{this.state.connectedteamstask.displayName}</div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )} */}

          <Field
            name="Goal"
            label="Egendefinerte mål"
            component={RichTextField}
          />

          <Field name="Text" label="Oppgavetekst" component={RichTextField} />
          <Field name="Link" label="Lenke" component={InputField} />
          <Field
            name="ShowFrom"
            label="Vises fra"
            dateFormat="DD.MM.YYYY"

            required={true}
            component={DateTimeField}
          />

          <Field
            name="Starttime"
            label="Starttidspunkt"
            dateFormat="DD.MM.YYYY"
            timeFormat="HH:mm"
            showTime={true}
            canRemove={true}

            component={DateTimeField}
          />
          
          <Field
            name="Deadline"
            label="Frist"
            dateFormat="DD.MM.YYYY"
            timeFormat="HH:mm"
            showTime={true}
            required={true}
            component={DateTimeField}
          />

          <Field
            name="Plagiatkontroll"
            label="Plagiatkontroll"
            component={CheckboxField}
          />
          <Field
            name="KeepOpen"
            label="Flere innleveringsforsøk"
            component={CheckboxField}
          />
          <Field
            name="HideDeadline"
            label="Skjul frist i ukeplanen"
            component={CheckboxField}
          />
          <Field
            name="DeliverAfterDeadline"
            label="Levering etter frist"
            component={CheckboxField}
          />

          <div className="ms-Grid-row">
            <div className="z-form-group">
              <div className="ms-Grid">
                <div className="ms-Grid-col ms-sm12 ms-md4">
                  <label className="z-form-label"></label>
                </div>
                {this.state.selectedfiles && (
                  <div className="ms-Grid-col ms-sm12 ms-md8">
                    <OneDrivePicker
                      onClose={(selected) => {
                        let tmp = this.state.selectedfiles;
                        tmp.push(selected);
                        this.setState({ selectedfiles: tmp });
                      }}
                    />
                    <ul className="task-element-form--list">
                      {this.state.selectedfiles.map((file) => {
                        return (
                          <li>
                            <li
                              className="ms-Grid-row"
                              id="@t.OriginalDocumentID"
                            >
                              <div className="ms-Grid-col ms-sm1">
                              <IconButton
                                title="Fjern dokument"
                                style={{color: 'red', verticalAlign: 'middle'}}
                                iconProps={{ iconName: "BoxSubtractSolid" }}
                                onClick={() => {
                                  let tmp = this.state.selectedfiles;
                                  for (var i = 0; i < tmp.length; i++) {
                                    if (
                                      tmp[i].OriginalDocumentID ===
                                      file.OriginalDocumentID
                                    ) {
                                      tmp.splice(i, 1);
                                    }
                                  }
                                  this.setState({ selectedfiles: tmp });
                                }}
                              />
                              </div>
                              <div className="ms-Grid-col ms-sm11 pt-5">
                                <span>
                                  {file.FileName}
                                </span>
                              </div>
                       
                            </li>
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="form-footer">
          <div className="form-footer-left">
            {this.props.mode !== 'c' && (<span>
              <ActionButton
                iconProps={{ iconName: "Delete" }}
                onClick={this.onDeleteClick.bind(this)}
              >
                Slett
              </ActionButton>
              <ActionButton
                text="Kopier til ny gruppe"
                iconProps={{ iconName: "Copy" }}
                onClick={() => {
                  this.props.getAssignmentGroup({ CourseID: this.props.initialValues.CourseID });
                  this.props.change("GroupID", this.props.initialValues.GroupID);
                  this.props.change("CourseID", this.props.initialValues.CourseID);
                  this.props.getStudentsFromCourse(this.props.initialValues.CourseID);

                  this.focusTopRef();
                  this.props.history.push(`/task/${this.props.formValues.Id}/c`);
                }}
              />
            </span>)}

          </div>
          <div className="form-footer-right">
            {/* <DefaultButton
              onClick={() => {
                this.props.history.push("/ukeplan");
              }}
              style={{ marginRight: 5 }}
            > */}
            <DefaultButton
              onClick={() => {
                this.props.getAssignmentGroup({ CourseID: this.props.initialValues.CourseID });
							  this.props.change("GroupID", this.props.initialValues.GroupID);
							  this.props.change("CourseID", this.props.initialValues.CourseID);
                this.props.getStudentsFromCourse(this.props.initialValues.CourseID);

                this.focusTopRef();
                this.props.history.goBack()
              }}
              style={{ marginRight: 5 }}
            >
              Avbryt
            </DefaultButton>
            <PrimaryButton 
              onClick={() => {
                this.setState({attemptedToSave: true});
                if (!this.state.saving && this.props.mode == "c" && isSameGroupAsBefore({courseId: this.props.formValues.CourseID, groupId: this.props.formValues.GroupID}, {courseId: this.props.initialValues.CourseID, groupId: this.props.initialValues.GroupID}))
                {
                  this.setState({showCopyPlanDialog: true});
                }
                else {
                  if (!this.state.saving && Object.keys(validate(this.props.formValues)).length == 0) {
                    this.setState({
                        saving: true
                      }, handleSubmit(this.onFormSubmit.bind(this))
                      );
                    }
                  }
                }
              }
            >
              {!this.state.saving ? "Lagre" : 
              <Spinner
                size={SpinnerSize.small}
              ></Spinner>}
            </PrimaryButton>
          </div>
        </div>
        <Dialog
					hidden={!this.state.showCopyPlanDialog}
					onDismiss={() => {
						this.setState({saving: false, showCopyPlanDialog: false})
					}}
					dialogContentProps = {{title: "Obs!", styles: {subText: {fontSize: "15px"}}, subText: "Du er ferd med å opprette en ny innlevering uten å endre gruppe. Er du sikker på at du vil lagre?", type: DialogType.normal}}
				>
					<DialogFooter>
						<PrimaryButton onClick={() => {
								this.setState({showCopyPlanDialog: false, saving: true});
								handleSubmit(this.onFormSubmit.bind(this));
								this.onFormSubmit(this.props.formValues);
							}}
							text="Lagre" />
						<DefaultButton onClick={() => {
							this.setState({saving: false, showCopyPlanDialog: false})
						}} text="Avbryt" />
					</DialogFooter>
				</Dialog>
      </form>
    );
  }
}

const validate = (values) => {
  const errors: any = {};

  // Dersom fag ikke er satt
  if (!values.CourseID) {
    errors.CourseID = "Dette feltet er påkrevd.";
  }

  if (values.CourseID && ((values.GroupID === null || values.GroupID === "") && (values.Elever == null || values.Elever == ""))) {
    errors.GroupID = "Innleveringen må ha gyldige mottakere - velg elever i feltet under.";
    errors.Elever = "Innleveringen må ha gyldige mottakere.";
  }

  // Vises fra er påkrevd
  if (!values.ShowFrom) {
    errors.ShowFrom = "Dette feltet er påkrevd.";
  }
  if (!values.Name || values.Name.length==0) {
    console.log('Name er ikke satt');
    errors.Name = "Dette feltet er påkrevd.";
  }
  // Frist er påkrevd
  if (!values.Deadline) {
    errors.Deadline = "Dette feltet er påkrevd.";
  }

  if (values.Starttime && dateStringToMoment(values.Starttime) < dateStringToMoment(values.ShowFrom)) {
    errors.Starttime = "Kan ikke være tidligere enn visningsdato"
  }

  if (values.Starttime && dateStringToMoment(values.Deadline) <= dateStringToMoment(values.Starttime)) {
    errors.Deadline = "Kan ikke være tidligere enn starttidspunktet"
  }

  // Hvis frist er satt til tidligere enn i dag
  // if (isDateStringBeforeToday(values.Deadline)) {
  //   errors.Deadline = "Fristen kan ikke være tidligere enn i dag.";
  // }
  
  // Hvis begge datoene er satt, men fristen er før visningsdato
  if (
    values.Deadline &&
    values.ShowFrom &&
    dateStringToMoment(values.Deadline) < dateStringToMoment(values.ShowFrom)
  ) {
    errors.Deadline = "Fristen kan ikke være tidligere enn visningsdato.";
  }

  return errors;
};

const mapStateToProps = (state, ownProps) => {
  let g = [];
  if (state.ukeplanelements.ag) {
    g = state.ukeplanelements.ag;
  } else if (state.form.TaskElementForm.initial.Course) {
    g = state.form.TaskElementForm.initial.Course.Groups;
  }
  let tmp = [];
  if (state.form.TaskElementForm.initial.TaskDocument) {
    state.form.TaskElementForm.initial.TaskDocument.split(";").forEach(
      (element) => {
        if (element.indexOf("|") > 0) {
          tmp.push({
            OriginalDocumentID: element.split("|")[0],
            FileName: element.split("|")[1],
          });
        }
      }
    );
  }
  let gr = [];
  if (state.form.TaskElementForm.initial.Grep) {
    state.form.TaskElementForm.initial.Grep.split(";").forEach((element) => {
      if (element.indexOf("|") > 0) {
        gr.push({
          KMID: element.split("|")[0],
          LPID: element.split("|")[1],
          GrepTekst: element.split("|")[2],
        });
      }
    });
  }
  let studentsFromCourse = [];

  if (state.teacher.studentsFromCourse) {
    studentsFromCourse = state.teacher.studentsFromCourse;
  }

  const formValues = getFormValues("TaskElementForm")(state);
  // console.log("STATE:", state);
  // console.log("FORM VALUES:", formValues);

  return {
    courses: state.teacher.subscribedCourses,
    taskElement: state.ukeplanelements.taskElement,
    groups: g,
    selectedfiles: tmp,
    selected: gr,
    formValues,
    courseteams: state.teacher.teamscourses,
    students: studentsFromCourse,
    teacherstate: state.teacher,
    showskolon: state.teacher.user.skolon,
		skolonpicks:state.form.TaskElementForm.initial.SkolonResources ? state.form.TaskElementForm.initial.SkolonResources:[]
  };
};

export default reduxForm({
  form: "TaskElementForm",
  validate,
  enableReinitialize: true,
  touchOnBlur: true,
  onSubmitFail: () => {
    document.getElementById("content-view").scrollIntoView();
  },
})(
  connect(mapStateToProps, {
    getTeacherState,
    getRecipients,
    getStudentsFromCourse,
    getAssignmentGroup,
    deleteTaskElement,
    createAssignment,
    createRubric,
    attachRubric,
    clearCurrentAssignment,
  })(withRouter(TaskElementForm))
);
