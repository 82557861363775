import axios from 'axios';
import * as _ from 'lodash';
import { ROOT_URL, SP_HOST_URL } from './constants';

export const GET_TEACHER_MYQUIZZES = 'GET_TEACHER_MYQUIZZES';
export const GET_QUIZ_ELEMENT = 'GET_QUIZ_ELEMENT';
export const GET_QUIZ_QUESTIONS = 'GET_QUIZ_QUESTIONS';
export const SAVE_QUIZ = 'SAVE_QUIZ';
export const DELETE_QUIZ = 'DELETE_QUIZ';
export const DELETE_QUIZQUESTION = 'DELETE_QUIZQUESTION';

export const SAVE_QUIZ_QUESTION = 'SAVE_QUIZ_QUESTION';
export const GET_KMP_ELEMENTS = 'GET_KMP_ELEMENTS';
export const GET_KM_ELEMENTS = 'GET_KM_ELEMENTS';
export const GET_COURSE_ASSIGNMENTGROUPS = 'GET_COURSE_ASSIGNMENTGROUPS';
export const GET_START_QUIZ = 'GET_START_QUIZ';
export const GET_GRADE_QUIZ = 'GET_GRADE_QUIZ';
export const GET_GRADE_QUIZF = 'GET_GRADE_QUIZF';

export const SAVE_GRADING_QUIZ = 'SAVE_GRADING_QUIZ';
export const SAVE_ANSWERGRADING_QUIZ = 'SAVE_ANSWERGRADING_QUIZ';
export const COPY_QUIZ_QUESTIONS = 'COPY_QUIZ_QUESTIONS';

export function getTeacherQuizzes(statedata: any) {
	const data: FormData = new FormData();
	data.set('classes', JSON.stringify(_.values(statedata.subscribedSchoolClasses)));
	data.set('schools', JSON.stringify(_.values(statedata.schools)));
	data.set('courses', JSON.stringify(_.values(statedata.subscribedCourses)));
	const request = axios.post(`${ROOT_URL}/GetTeacherQuizzes${SP_HOST_URL}`, data, {
		headers: {
			Authorization: localStorage.getItem('id_token')
		}
	});

	return {
		type: GET_TEACHER_MYQUIZZES,
		payload: request
	};
}

export function getQuizElement(Id: number) {
	const request = axios.get(`${ROOT_URL}/GetQuizElement/${Id}${SP_HOST_URL}`, {
		headers: {
			Authorization: localStorage.getItem('id_token')
		}
	});

	return {
		type: GET_QUIZ_ELEMENT,
		payload: request
	};
}
export function copyQuestions(question: any) {
	const request = axios.post(`${ROOT_URL}/GetQuestionsCopy${SP_HOST_URL}`, question, {
		headers: {
			Authorization: localStorage.getItem('id_token')
		}
	});

	return {
		type: COPY_QUIZ_QUESTIONS,
		payload: request
	};
}
export function getQuestions(Id: number) {
	const request = axios.get(`${ROOT_URL}/GetQuizQuestions/${Id}${SP_HOST_URL}`, {
		headers: {
			Authorization: localStorage.getItem('id_token')
		}
	});

	return {
		type: GET_QUIZ_QUESTIONS,
		payload: request
	};
}

export function saveQuestion(question: any) {
	const request = axios.post(`${ROOT_URL}/SaveQuestion${SP_HOST_URL}`, question, {
		headers: {
			Authorization: localStorage.getItem('id_token')
		}
	});

	return {
		type: SAVE_QUIZ_QUESTION,
		payload: request
	};
}
export function saveQuiz(quiz: any) {
	const request = axios.post(`${ROOT_URL}/SaveQuiz${SP_HOST_URL}`, quiz, {
		headers: {
			Authorization: localStorage.getItem('id_token')
		}
	});

	return {
		type: SAVE_QUIZ,
		payload: request
	};
}
export function deleteQuiz(quiz: any) {
	const request = axios.post(`${ROOT_URL}/DeleteQuiz${SP_HOST_URL}`, quiz, {
		headers: {
			Authorization: localStorage.getItem('id_token')
		}
	});

	return {
		type: DELETE_QUIZ,
		payload: request
	};
}
export function deleteQuizQuestion(question: any) {
	const request = axios.post(`${ROOT_URL}/DeleteQuizQuestion${SP_HOST_URL}`, question, {
		headers: {
			Authorization: localStorage.getItem('id_token')
		}
	});

	return {
		type: DELETE_QUIZQUESTION,
		payload: request
	};
}
export function getLP() {
	const request = axios.get(`${ROOT_URL}/GetLP/${SP_HOST_URL}`, {
		headers: {
			Authorization: localStorage.getItem('id_token')
		}
	});

	return {
		type: GET_KMP_ELEMENTS,
		payload: request
	};
}

export function getKM(code: string) {
	const request = axios.get(`${ROOT_URL}/GetKM/${code}${SP_HOST_URL}`, {
		headers: {
			Authorization: localStorage.getItem('id_token')
		}
	});

	return {
		type: GET_KM_ELEMENTS,
		payload: request
	};
}

export function getAssignmentGroup(course: any) {
	const request = axios.post(`${ROOT_URL}/GetAssignmentGroups${SP_HOST_URL}`, course, {
		headers: {
			Authorization: localStorage.getItem('id_token')
		}
	});

	return {
		type: GET_COURSE_ASSIGNMENTGROUPS,
		payload: request
	};
}

export function startQuiz(quiz: any) {
	const request = axios.post(`${ROOT_URL}/StartQuiz${SP_HOST_URL}`, quiz, {
		headers: {
			Authorization: localStorage.getItem('id_token')
		}
	});

	return {
		type: GET_START_QUIZ,
		payload: request
	};
}

export function gradeQuiz(quiz: any) {
	const request = axios.post(`${ROOT_URL}/GradeQuizn${SP_HOST_URL}`, quiz, {
		headers: {
			Authorization: localStorage.getItem('id_token')
		}
	});

	return {
		type: GET_GRADE_QUIZ,
		payload: request
	};
}
export function gradeQuizf(quiz: any) {
	const request = axios.post(`${ROOT_URL}/GradeQuizf${SP_HOST_URL}`, quiz, {
		headers: {
			Authorization: localStorage.getItem('id_token')
		}
	});

	return {
		type: GET_GRADE_QUIZF,
		payload: request
	};
}
export function gradeQuizAnswer(quizAttemptAnswer: any, points: number) {
	const request = axios.post(
		`${ROOT_URL}/GradeQuizAnswer${SP_HOST_URL}`,
		{ Id: quizAttemptAnswer.Id, Points: points },
		{
			headers: {
				Authorization: localStorage.getItem('id_token')
			}
		}
	);

	return {
		type: SAVE_ANSWERGRADING_QUIZ,
		payload: request,
		quizAttemptAnswer: quizAttemptAnswer,
		points: points
	};
}

export function saveGradingQuiz(quizattempt: any) {
	console.log('kom hit');
	const request = axios.post(`${ROOT_URL}/SaveGrading${SP_HOST_URL}`, quizattempt, {
		headers: {
			Authorization: localStorage.getItem('id_token')
		}
	});
	console.log(request);
	return {
		type: SAVE_GRADING_QUIZ,
		payload: request
	};
}
