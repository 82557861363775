import * as React from 'react';
import { Icon } from "office-ui-fabric-react";
import {
    Accordion,
    AccordionItem,
    AccordionItemTitle,
    AccordionItemBody,
} from 'react-accessible-accordion';
import 'react-accessible-accordion/dist/fancy-example.css';

export interface ICourseContainerTeamsItemProps {
    color: string;
    iconName: string;
    statusText: string;
    studentTasks: any[];
    history:any;
}


class CourseContainerTeamsItem extends React.Component<ICourseContainerTeamsItemProps>{

    constructor(props) {
        super(props);
    }

    render() {

        const { color, iconName, statusText } = this.props;

        const iconStyle = { color: color };
        const containerStyle = { borderTop: '5px solid', borderTopColor: color };
        return (
            <div className="ms-Grid-col ms-sm12 ms-xl4">
                <div className="course-container-item">
                    <div className="ms-Grid-col ms-sm6 ms-font-su" style={iconStyle}>
                        <Icon iconName={iconName}></Icon>
                    </div>
                    <div className="ms-Grid-col ms-sm6 ms-textAlignRight">
                        <h4>{this.props.studentTasks.length}</h4>
                        <span>{statusText}</span>
                    </div>
                    <div className="course-container-item-footer ms-Grid-col ms-sm12" style={containerStyle}>

                        <Accordion>
                            <AccordionItem>
                                <AccordionItemTitle>
                                    <Icon iconName="DrillDownSolid" /> <span className="ms-fontWeight-semibold">Vis detaljer</span>
                                </AccordionItemTitle>
                                <AccordionItemBody>
                                    <ul className="no-type-list">
                                        {
                                            this.props.studentTasks.map((s)=>{
                                                return(
                                                    <li className="pointer" onClick={()=> this.onEditStudentTask(s)}>
                                                        {s.StudentName}
                                                    </li>
                                                )
                                            })
                                        }
                                        
                                    </ul>
                                </AccordionItemBody>
                            </AccordionItem>

                        </Accordion>

                    </div>
                </div>
            </div>
        )
    }
    onEditStudentTask(pe:any) {
        if (pe.TeamsSubmissionDeepLink) {
            window.open(pe.TeamsSubmissionDeepLink, "_blank", "noreferrer");
        }
    }
   
}

export default CourseContainerTeamsItem;