import * as React from "react";
import { Dropdown, Label } from "office-ui-fabric-react";

interface IRadioButtonProps {
  label: string;
  input: any;
  options?: any[];
  meta: {
    touched: boolean;
    error: string;
  };
  placeholder?: string;
  defaultValue?: any;
  required?: boolean;
  disabled?: boolean;
  children: any;
  onSelect?: (key: any) => void;
}

const RadioButtonList = (props: IRadioButtonProps) => {
  console.log("008rblist", props);
  const {
    label,
    input,
    required,
    disabled,
    onSelect,
    defaultValue,
    placeholder,
    options,
    meta: { touched, error },
    children,
  } = props;

  const handleChange = (item, parent, value) => {
    console.log("hotfixrb", item);
    console.log("hotfixrb", parent);
    console.log("hotfixrb", value);
    let updval = JSON.parse(JSON.stringify(input.value));
    updval.forEach((element) => {
      if (element.Kmid == parent.Kmid) {
        element.Eval = value;
      }
    });
    input.onChange(updval);
  };
  console.log("hotfixrb", input);
  return (
    <div>
      <div className="table-responsive">
        <table className="table table-bordered">
          <thead>
            <tr>
              <th className="iup-table-header-first">Kompetansemål</th>
              <th className="iup-table-header-second">Måloppnåelse</th>
            </tr>
          </thead>
          <tbody>
            {input.value &&
            input.value.map((km) => {
              return (
                <tr>
                  <td
                    className="kmtext"
                    data-lpid="@uip.Lpid"
                    data-kmid="@uip.Kmid"
                  >
                    {km.Kmtext}
                  </td>
                  <td>
                    <div className="ms-ChoiceFieldGroup">
                      <div className="ms-ChoiceField inline-block pr-15">
                        <input
                          className="ms-ChoiceField-input"
                          value="0"
                          type="radio"
                          name={km.Kmid}
                          checked={km.Eval == "0"}
                          onChange={(item) => handleChange(item, km, "0")}
                        />
                        <label className="ms-ChoiceField-field">
                          <span className="ms-Label" title="Ikke vurdert">
                            Ikke vurdert
                          </span>
                        </label>
                      </div>
                      <div className="ms-ChoiceField inline-block pr-15">
                        <input
                          className="ms-ChoiceField-input"
                          value="1"
                          type="radio"
                          name={km.Kmid}
                          checked={km.Eval == "1"}
                          onChange={(item) => handleChange(item, km, "1")}
                        />
                        <label className="ms-ChoiceField-field">
                          <span className="ms-Label" title="Lav">
                            <i
                              className="ms-Icon ms-Icon--TestBeaker"
                              aria-hidden="true"
                            ></i>
                          </span>
                        </label>
                      </div>
                      <div className="ms-ChoiceField inline-block pr-15">
                        <input
                          className="ms-ChoiceField-input"
                          value="2"
                          type="radio"
                          name={km.Kmid}
                          checked={km.Eval == "2"}
                          onChange={(item) => handleChange(item, km, "2")}
                        />
                        <label className="ms-ChoiceField-field">
                          <span className="ms-Label" title="Middels">
                            <i
                              className="ms-Icon ms-Icon--TestBeakerSolid"
                              aria-hidden="true"
                            ></i>
                          </span>
                        </label>
                      </div>
                      <div className="ms-ChoiceField inline-block pr-15">
                        <input
                          className="ms-ChoiceField-input"
                          value="3"
                          type="radio"
                          name={km.Kmid}
                          checked={km.Eval == "3"}
                          onChange={(item) => handleChange(item, km, "3")}
                        />
                        <label className="ms-ChoiceField-field">
                          <span className="ms-Label" title="Høy">
                            <i
                              className="ms-Icon ms-Icon--TestCase"
                              aria-hidden="true"
                            ></i>
                          </span>
                        </label>
                      </div>
                    </div>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>

      {/* {input.value.map((km) => {
          console.log('hotfixrbkm',km);
          console.log('hotfixrbkmeval',km.Eval);
          console.log('hotfixrbkmeval',km.Eval == "1");
        return (
          <>
            <input
              className="ms-ChoiceField-input"
              value="0"
              type="radio"
              name={km.Kmid}
              checked={km.Eval == "0"}
              onChange={(item) => handleChange(item, km, "0")}
            />
            <input
              className="ms-ChoiceField-input"
              value="1"
              type="radio"
              name={km.Kmid}
              checked={km.Eval == "1"}
              onChange={(item) => handleChange(item, km, "1")}
            />
            <input
              className="ms-ChoiceField-input"
              value="2"
              type="radio"
              name={km.Kmid}
              checked={km.Eval == "2"}
              onChange={(item) => handleChange(item, km, "2")}
            />
            <input
              className="ms-ChoiceField-input"
              value="3"
              type="radio"
              name={km.Kmid}
              checked={km.Eval == "3"}
              onChange={(item) => handleChange(item, km, "3")}
            />
            <br></br>
          </>
        );
      })} */}
    </div>
  );
};

export default RadioButtonList;
