
export class CourseCheckbox {
    Title:string;
    CourseID:string;
    Selected:boolean;
    SchoolID:string;

    constructor(Title: string, CourseID: string, Selected: boolean, SchoolID: string) {
        this.Title = Title;
        this.CourseID = CourseID;
        this.Selected = Selected;
        this.SchoolID = SchoolID;
    }
}