import * as React from 'react';
import { connect } from 'react-redux';
import { gradeQuiz,gradeQuizf, saveGradingQuiz } from '../../actions/Action_Quiz'
import QuizGraderStudent from './QuizGraderStudent';
import QuizFastgrader from './QuizFastgrader';
import QuizGraderForm from './QuizGraderForm';
import QuizGraderPoints from './QuizGraderPoints';
import { setstatus } from '../../actions/Action_Teacher';
import { CompoundButton, Spinner } from 'office-ui-fabric-react';
import { ROOT_URL, SP_HOST_URL } from '../../actions/constants';
import axios from 'axios';
import './quiz.scss'
export interface IQuizGraderProps {
  gradeQuiz(quiz: any);
  gradeQuizf(quiz: any);
  match: any;
  quiz: any;
  quizf: any;
  saveGradingQuiz(values: any);
  setstatus(status: any);
  editedForms: any;
}

export interface IQuizGraderState {
  mode: string;
  blockRender: boolean;
  spinner: boolean;
  listOfStudentAttempt: IStudentAttempt[]
}

interface IStudentAttempt {
  id: number;
  totalPoints: number;
}

class QuizGrader extends React.Component<IQuizGraderProps, IQuizGraderState> {
  constructor(props) {
    super(props);

    this.state = { mode: '1', blockRender: true, spinner: false, listOfStudentAttempt:  []};
  }
  public render() {
    return (
      <div>
         <link rel="stylesheet" href="//cdn.quilljs.com/1.2.6/quill.snow.css"></link>
        {
          this.state.mode == '1' &&
          <div className="ms-Grid main quizForm container">
            <CompoundButton primary secondaryText="Rette spørsmål for spørsmål pr.elev" onClick={()=>{
              this.setState({mode:'2'});
            }}>
              Hurtigretting
            </CompoundButton>
            <CompoundButton primary className="lever-alle-knapp" style={{backgroundColor: "rgb(43, 175, 43)", color: "#FFF"}} secondaryText="Publiser vurdering til alle valgte elever" onClick={()=>{
              this.sendAll()
            }}>
              {this.state.spinner ? <Spinner /> : "Publiser til alle" }
            </CompoundButton>
            <div id="studentlist" className="ms-Grid-row">
              <div className="ms-Grid-col ms-sm12 test">
                {
                  this.props.quiz && !this.state.blockRender &&
                    Object.values(this.props.quiz.Attempts).map((student: any) => {

                    return (
                      // <QuizGraderStudent attempt={student}></QuizGraderStudent>
                      <div>

                      <QuizGraderPoints
                      initialValues={student}
                      setStudentPoints={this.setStudentPoints}
                    ></QuizGraderPoints>
                      <QuizGraderForm form={'gradestudent-' + student.Id} initialValues={student} quiz={this.props.quiz} points={this.getStudentPoints(student.Id)}></QuizGraderForm>
                      </div>
                    )
                  })

                }
              </div>
            </div>
          </div>
        }
         {
          this.state.mode == '2' &&
          <div className="ms-Grid main quizForm container">
            <CompoundButton primary secondaryText="Se alle spørsmål pr.elev" onClick={()=>{
              this.setState({mode:'1'});
            }}>
            Vanlig retting
            </CompoundButton>
            <CompoundButton primary className="lever-alle-knapp" style={{backgroundColor: "rgb(43, 175, 43)", color: "#FFF"}} secondaryText="Publiser vurdering til alle elever" onClick={()=>{
             this.sendAll();
            }}>
              Publiser til alle
            </CompoundButton>
            <div id="studentlist" className="ms-Grid-row">
              <div className="ms-Grid-col ms-sm12 test">
                {
                  this.props.quizf &&
                  
                   <QuizFastgrader quiz={this.props.quizf}></QuizFastgrader>
                  

                }
              </div>
            </div>
          </div>
        }
      </div>
    );
  }
  sendAll = async () =>{
    let submitArr = [];
    Object.values(this.props.editedForms).forEach((editedForm: any) =>{
      console.log(editedForm)
      if(editedForm.values.ShowGrading){
        submitArr.push(editedForm.values)
      }
    })
    
    this.setState({ spinner: true });
    this.props.setstatus({ working: true, text: 'saving ' });
    try {
      await Promise.all(
        await submitArr.map(async (values) => {
          const result = await this.saveGrading(values);
        })
      )

      await this.props.gradeQuiz({Id: this.props.quiz.Id});
      await this.props.gradeQuizf({Id: this.props.quiz.Id});

      this.props.setstatus({ working: false, text: 'lagret ' });
    }
    catch (error) {
      this.props.setstatus({ working: false, error: true, text: 'Noe gikk galt under publiseringen' });
    }
    finally {
      this.setState({ spinner: false });
    }

  }


  saveGrading = async (quizattempt) => {
    const request = await axios.post(`${ROOT_URL}/SaveGrading${SP_HOST_URL}`, quizattempt, {
      headers: {
        Authorization: localStorage.getItem('id_token')
      }
    });

    return request.data;
  }


  setStudentPoints = (id: number, points: number) =>
  {
    let tmp = this.state.listOfStudentAttempt;

    let tmpValue = tmp.find(e => e.id == id);
    if(tmpValue) {
      tmpValue.totalPoints = points;
      this.setState({listOfStudentAttempt: tmp})
    }
    else {
      let newValue: IStudentAttempt = {id: id, totalPoints: points};
      tmp.push(newValue);
      this.setState({listOfStudentAttempt: tmp})
    }
  }

  getStudentPoints = (id: number): number =>
  {
    let attempt = this.state.listOfStudentAttempt.find(e => e.id == id);
    if(attempt) {
      return attempt.totalPoints
    }
    return 0;
  }

  componentDidMount() {
    const { id, mode } = this.props.match.params;

    this.setState({blockRender: true}, () =>{

      this.props.gradeQuiz({ Id: id })
        .then(response => {
          // this.setState({ takeQuizForm: response.payload.data });
          this.setState({
            blockRender: false
          })
        }).catch(error => {
  
        });
        this.props.gradeQuizf({ Id: id })
        .then(response => {
          // this.setState({ takeQuizForm: response.payload.data });
  
        }).catch(error => {
  
        });
    })
  }
}


function mapStateToProps(state, ownProps) {

  
  // if(this.state.Quiz)
  // {
  return {
    quiz: state.Quiz.Quiz,
    quizf: state.Quiz.gradequizf,
    editedForms: state.form
  };

}

export default connect(mapStateToProps, { gradeQuiz,gradeQuizf, saveGradingQuiz, setstatus })(QuizGrader);