import { IIdentitySet } from "../IIdentitySet";
import { IOutcome } from "../IOutcome";
import { IAssignmentPointsGrade } from "./IAssignmentPointsGrade";

export class PointsOutcome {
    id: string;
    lastModifiedBy: IIdentitySet;
    lastModifiedDateTime: Date;
    points: IAssignmentPointsGrade;
    publishedPoints: IAssignmentPointsGrade;

    constructor(outcome: IOutcome) {
        this.id = outcome.id;
        this.lastModifiedBy = outcome.lastModifiedBy;
        this.lastModifiedDateTime = outcome.lastModifiedDateTime;
        this.points = outcome.points;
        this.publishedPoints = outcome.publishedPoints;
    }
}