import * as React from "react";
import * as moment from "moment";
import * as _ from "lodash";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
moment.locale("nb");
import {
  Label,
  ActionButton,
  BasePickerListBelow,
  Icon
} from "office-ui-fabric-react";
//import '../QuillStyles.scss';

export interface IPortfolioItemViewStudentProps {
  item: any;
  showtask: boolean;
  showtext: boolean;
  showquiz: boolean;
  history: any;
}
export interface IPortfolioItemViewStudentState { }

export class PortfolioItemViewStudent extends React.Component<
  IPortfolioItemViewStudentProps,
  IPortfolioItemViewStudentState
> {
  constructor(props) {
    super(props);
  }
  render() {
    if (this.props.item.Qa) {
      console.log('quizitem', this.props);
    }
    if (this.props.item.StudentTaskItem && this.props.showtask) {
      if (this.props.item.StudentTaskItem.WsTask.TeamsTask && this.props.item.StudentTaskItem.WsTask.TeamsTask.length > 0) {
        return (<></>);
      }
      return (
        <div
          className="card"
          data-courseid="@item.StudentTaskItem.WsTask.CourseID"
          data-coursename="@item.StudentTaskItem.WsTask.CourseName"
          data-groupname="task"
        >
          <div className="card-header portHeading">
            <h3>
              {this.props.item.StudentTaskItem.WsTask.CourseName}{" "}
              <Icon iconName="CompletedSolid" />
            </h3>
            <span className="font-size-l-bold">
              {this.props.item.StudentTaskItem.WsTask.Name} |{" "}
            </span>{" "}
            <span className="font-size-l">
              Frist: {this.props.item.StudentTaskItem.WsTask.Deadlinetekst}{" "}
            </span>
          </div>
          <div className="card-body">
            <div className="ms-Grid-row">
              <div className="ms-Grid-col ms-md6">
                <Label>Beskrivelse:</Label>
                <span className="remove-para-margin ql-editor" style={{ backgroundColor: "transparent" }}
                  dangerouslySetInnerHTML={{
                    __html: this.props.item.StudentTaskItem.WsTask.TextRaw
                  }}
                ></span>
                {/* {this.props.item.StudentTaskItem.WsTask.TextRaw} */}
              </div>
              <div className="ms-Grid-col ms-md6">
                <div className="comment">
                  <Label>Kommentar:</Label>

                  {this.props.item.StudentTaskItem.ShowEval && (
                    <div>
                      <span className="remove-para-margin ql-editor" style={{ backgroundColor: "transparent" }}
                        dangerouslySetInnerHTML={{
                          __html: this.props.item.StudentTaskItem.GradingComment
                        }}
                      ></span>
                      <div style={{ marginTop: 10 }}>
                        <Label>Karakter:</Label>
                        {this.props.item.StudentTaskItem.Grade}
                        <span style={{ paddingLeft: 5 }}>
                          {this.props.item.StudentTaskItem.GradeModifier}
                        </span>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
            {this.props.item.StudentTaskItem.WsTask.GrepCodeList && this.props.item.StudentTaskItem.WsTask.GrepCodeList.length > 0 && (
              <div className="ms-Grid-row">
                <div className="ms-Grid-col ms-sm12">
                  <Label>Kompetansemål:</Label>
                  <ul className="kompMaalList">
                    {this.props.item.StudentTaskItem.WsTask.GrepCodeList.map(
                      km => {
                        return (
                          <li>
                            {km.GrepKode} {km.GrepTekst}
                          </li>
                        );
                      }
                    )}
                  </ul>
                </div>
              </div>
            )}
          </div>
          {
            !this.props.item.StudentTaskItem.WsTask.TeamsDeepLink &&
            <>
              <div className="card-footer">
                <div className="form-footer-left">
                  <ActionButton
                    iconProps={{ iconName: "RedEye" }}
                    onClick={() => {
                      this.props.history.push(
                        "/taskstudent/" +
                        this.props.item.StudentTaskItem.Id
                      );
                    }}
                  >
                    Se innleveringsside
                  </ActionButton>
                  <ActionButton iconProps={{ iconName: "Attach" }}>
                    Se filer
                  </ActionButton>
                </div>
                {this.props.item.StudentTaskItem.ShowGrade && (
                  <div className="form-footer-right">
                    <Label>Vurdering</Label>
                    <div className="text-center">
                      {this.props.item.StudentTaskItem.Grade}
                    </div>
                  </div>
                )}
              </div>
            </>
          }
          {
            this.props.item.StudentTaskItem.WsTask.TeamsDeepLink &&
            <>
              <div className="card-footer">
                <div className="form-footer-left">
                  <ActionButton
                    iconProps={{ iconName: "RedEye" }}
                    onClick={() => {
                      window.open(this.props.item.StudentTaskItem.WsTask.TeamsDeepLink)
                    }}
                  >
                    Åpne i Teams
                  </ActionButton>

                </div>

              </div>
            </>
          }
        </div>
      );
    }
    if (this.props.item.Taskitem && this.props.showtask) {
      let GrepCodeList = this.props.item.Taskitem.GrepCodeList;
      if (GrepCodeList.length < 1 && this.props.item.Taskitem.Grep) {
        GrepCodeList = this.props.item.Taskitem.Grep.split(";");
        for (let i = 0; i < GrepCodeList.length; i++) {
          let code = GrepCodeList[i];
          //code = code.replace(/[|]/g, "|;").split(";");
          code = code.replace("|", "+").split("|");
          code[0] = code[0].replace("+", "|");
          GrepCodeList[i] = {
            GrepKode: `${code[0]}` + " ",
            GrepTekst: code[1]
          }

        }
      }
      return (
        <div
          className="card"
          data-courseid="@item.StudentTaskItem.WsTask.CourseID"
          data-coursename="@item.StudentTaskItem.WsTask.CourseName"
          data-groupname="task"
        >
          <div className="card-header portHeading">
            <h3>
              {this.props.item.Taskitem.CourseName}{" "}
              <i
                className="ms-Icon ms-Icon--CompletedSolid"
                aria-hidden="true"
              ></i>
            </h3>
            <h4>
              {this.props.item.Taskitem.Name}{" "}
              {this.props.item.Taskitem.Deadlinetekst}{" "}
            </h4>
          </div>
          <div className="card-body">
            <div className="ms-Grid-row">
              <div className="ms-Grid-col ms-md6">
                <Label>Beskrivelse:</Label>
                <span className="remove-para-margin ql-editor" style={{ backgroundColor: "transparent" }}
                  dangerouslySetInnerHTML={{
                    __html: this.props.item.Taskitem.TextRaw
                  }}
                ></span>
                {/* {this.props.item.StudentTaskItem.WsTask.TextRaw} */}
              </div>
              <div className="ms-Grid-col ms-md6">
                <div className="comment">
                  <Label>Kommentar</Label>
                </div>
              </div>
            </div>
            <div className="ms-Grid-row">
              <div className="ms-Grid-col ms-sm12">
                <Label>Kompetansemål</Label>
                <ul className="kompMaalList">
                  {

                    GrepCodeList.map(km => {
                      return (
                        <li>
                          {km.GrepKode} {km.GrepTekst}
                        </li>
                      )
                    })
                  }
                </ul>
              </div>
            </div>
          </div>
          <div className="card-footer">
            <div className="form-footer-left">
              <ActionButton iconProps={{ iconName: "RedEye" }}>
                Se innleveringsside
              </ActionButton>
            </div>
            <div className="form-footer-right"></div>
          </div>
        </div>
      );
    }
    if (
      this.props.item.TextItem &&
      this.props.showtext &&
      this.props.item.TextItem.Course
    ) {
      return (
        <div
          className="card"
          data-courseid="@item.TextItem.CourseID"
          data-coursename="@item.TextItem.Course.Title"
          data-groupname="text"
        >
          <div className="card-header portHeading">
            <h3>
              {this.props.item.TextItem.Course.Title}{" "}
              <i
                className="ms-Icon ms-Icon--CalendarWeek"
                aria-hidden="true"
                title="Ukeplanelement"
              ></i>
            </h3>
            <h4>
              {this.props.item.TextItem.Theme}|{" "}
              {this.props.item.TextItem.DateText}
            </h4>
          </div>
          <div className="card-body">
            <div className="ms-Grid-row">
              <div className="ms-Grid-col ms-md6">
                {this.props.item.TextItem.TextRaw && (
                  <div>
                    <label className="ms-Label">Arbeid hjemme:</label>
                    <div>
                      <span className="remove-para-margin ql-editor" style={{ backgroundColor: "transparent" }}
                        dangerouslySetInnerHTML={{
                          __html: this.props.item.TextItem.TextRaw
                        }}
                      ></span>
                      {/* {this.props.item.TextItem.TextRaw} */}
                    </div>
                  </div>
                )}

                {
                  this.props.item.TextItem.GoalRaw && this.props.item.TextItem.GoalRaw.length > 0 &&
                  <div>
                    <Label>Egendefinerte mål:</Label>
                    <span className="remove-para-margin ql-editor" style={{ backgroundColor: "transparent" }}
                      dangerouslySetInnerHTML={{
                        __html: this.props.item.TextItem.GoalRaw
                      }}
                    ></span>
                  </div>
                }


              </div>
              <div className="ms-Grid-col ms-md6">
                {this.props.item.TextItemTextonPremRaw && (
                  <div>
                    <Label>Arbeid på skolen</Label>
                    <div className="remove-para-margin ql-editor" style={{ backgroundColor: "transparent" }}
                      dangerouslySetInnerHTML={{
                        __html: this.props.item.TextItem.TextonPremRaw
                      }}
                    ></div>
                    {/* {this.props.item.TextItem.TextonPremRaw} */}
                  </div>
                )}
              </div>
            </div>
            <div className="ms-Grid-row">
              <div className="ms-Grid-col ms-md12">
                {this.props.item.TextItem.GrepCodeList && this.props.item.TextItem.GrepCodeList.length > 0 && (
                  <div>
                    <Label>Kompetansemål</Label>
                    <ul>
                      {this.props.item.TextItem.GrepCodeList.map(km => {
                        return (
                          <li>
                            {km.GrepKode} {km.GrepTekst}
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="card-footer">
            <ActionButton
              iconProps={{ iconName: "RedEye" }}
              onClick={() => {
                this.props.history.push(
                  "/ukeplan/" + this.props.item.TextItem.Id + "/e"
                );
              }}
            >
              Se planelement
            </ActionButton>
          </div>
        </div>
      );
    }
    if (
      this.props.item.Qa &&
      this.props.showquiz &&
      this.props.item.Qa.Quiz &&
      this.props.item.Qa.Quiz.Course
    ) {
      return (
        <div
          className="card"
          data-courseid="@item.Qa.Quiz.CourseId"
          data-coursename="@item.Qa.Quiz.CourseName"
          data-groupname="quiz"
        >
          <div className="card-header portHeading">
            <h3>
              {this.props.item.Qa.Quiz.Course.Title}{" "}
              <i className="ms-Icon ms-Icon--PageListSolid" title="Prøve"></i>
            </h3>
            <h4>
              {this.props.item.Qa.Quiz.Title} {this.props.item.Qa.Modifiedtext}
            </h4>
          </div>
          <div className="card-body">
            <div className="ms-Grid-row">
              <div className="ms-Grid-col ms-md12">
                {this.props.item.Qa.Quiz.GrepCodeList && this.props.item.Qa.Quiz.GrepCodeList.length > 0 && (
                  <div>
                    <Label>Kompetansemål</Label>
                    <ul>
                      {this.props.item.Qa.Quiz.GrepCodeList.map(km => {
                        return (
                          <li>
                            {km.GrepKode} {km.GrepTekst}
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                )}
              </div>
            </div>

            {(this.props.item.Qa.GradingComment || this.props.item.Qa.Grade) &&
              <div className="ms-Grid-row">
                <div className="ms-Grid-col ms-md12">
                  <hr />
                </div>
              </div>
            }

            <div className="ms-Grid-row">
              <div className="ms-Grid-col ms-md8">
                {this.props.item.Qa.GradingComment ? (
                  <div>
                    <Label>Kommentar</Label>
                    <span className="remove-para-margin ql-editor" style={{ backgroundColor: "transparent" }}
                      dangerouslySetInnerHTML={{
                        __html: this.props.item.Qa.GradingComment
                      }}
                    ></span>
                  </div>
                ) : this.props.item.Qa.Grade ?
                  <div>
                    <Label>Kommentar</Label>
                    <span> Ingen Kommentar</span>
                  </div> : ""
                }
              </div>
              <div className="ms-Grid-col ms-md4">
                {this.props.item.Qa.Grade && (
                  <div>
                    <Label>Karakter</Label>
                    <div>
                      <span className="remove-para-margin ql-editor" style={{ backgroundColor: "transparent" }}
                        dangerouslySetInnerHTML={{
                          __html: `${this.props.item.Qa.Grade} ${this.props.item.Qa.GradeModifier}`
                        }}
                      ></span>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="card-footer">
          </div>
        </div>
      );
    }
    if (
      this.props.item.QuizItem &&
      this.props.showquiz &&
      this.props.item.QuizItem.Course
    ) {
      return (
        <div
          className="card"
          data-courseid="@item.Qa.Quiz.CourseId"
          data-coursename="@item.Qa.Quiz.CourseName"
          data-groupname="quiz"
        >
          <div className="card-header portHeading">
            <h3>
              {this.props.item.QuizItem.Course.Title}{" "}
              <i className="ms-Icon ms-Icon--PageListSolid" title="Prøve"></i>
            </h3>
            <h4>
              {this.props.item.QuizItem.Title}
            </h4>
          </div>
          <div className="card-body">
            <div className="ms-Grid-row">
              <div className="ms-Grid-col ms-md6"></div>
              <div className="ms-Grid-col ms-md6">
                <div>

                </div>
              </div>
            </div>
            <div className="ms-Grid-row">
              <div className="ms-Grid-col ms-md12">
                {this.props.item.QuizItem.GrepCodeList && this.props.item.QuizItem.GrepCodeList.length > 0 && (
                  <div>
                    <Label>Kompetansemål</Label>
                    <ul>
                      {this.props.item.QuizItem.GrepCodeList.map(km => {
                        return (
                          <li>
                            {km.GrepKode} {km.GrepTekst}
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="card-footer">
            <div className="form-footer-left"></div>
            <div className="form-footer-right">

            </div>
          </div>
        </div>
      );
    }
    else {
      return <div></div>;
    }
  }
}
function mapStateToProps(state) {
  return {};
}
export default connect(mapStateToProps, {})(PortfolioItemViewStudent);
