import * as React from "react";
import {Label} from "office-ui-fabric-react";
import ReactQuill from "react-quill";


const RichTextFieldNoStyling = (props) => {

    const {
        input,
        required,
        meta: { touched, error },
        children
    } = props;

    return (
      
                    <ReactQuill value={input.value} onChange={(html) => {
                        input.onChange(html);
                    }}
                    />

    );
};

export default RichTextFieldNoStyling;