import * as React from "react";
import { IconButton } from "office-ui-fabric-react";
import { withRouter } from "react-router-dom";

export interface ISidebarLinkProps {
  description: string;
  iconName: string;
  path: string;
  color?: string;
  fullWidth?: boolean;
  history: any;
}
const jwtDecode = require("jwt-decode");
class SideBarLink extends React.Component<ISidebarLinkProps> {
  onButtonClicked() {
      console.log(this.props.path);
     
    if (this.props.path == "home") {
      const thetoken = jwtDecode(localStorage.getItem("id_token"));
      console.log('thetoken',thetoken);
        console.log('home',localStorage.getItem("initurl"));
      window.location.href = thetoken.url;
    } else {
      this.props.history.push(this.props.path);
    }
  }

  render() {
    const containerClass = `ms-Grid-col ${
      this.props.fullWidth ? "ms-sm12" : "ms-sm6"
    } ms-font-xxl sidebar-view-item`;
    const buttonStyle = {
      backgroundColor: this.props.color ? this.props.color : "#2baf2b",
    };

    return (
      <div>
        <div className={containerClass}>
          <IconButton
            iconProps={{ iconName: this.props.iconName }}
            style={buttonStyle}
            title={this.props.description}
            onClick={this.onButtonClicked.bind(this)}
          />
        </div>
      </div>
    );
  }
}

export default withRouter(SideBarLink);
