import * as React from 'react'

interface IQuizGraderQuestionTileProps {
    answer: any;
    gQuestion: any;
    gAnswer: any;
	onPointChange(event, gradedQuestion, gradedAnswer): void;
}

export default function QuizGraderQuestionTile(props: IQuizGraderQuestionTileProps) {

    const mapToCssClass = (answer: any, question: any): string => {
		let selected = false;
		Object.keys(answer.SelectedOptions).forEach((tmpAns) => {
			if (tmpAns == question.QID) {
				selected = true;
			}
		});

		if (selected && question.Correct) {
			return 'selectedcorrect';
		}
		if (selected && !question.Correct) {
			return 'selectedincorrect';
		}
		if (!selected && question.Correct) {
			return 'notselectedcorrect';
		}
		if (!selected && !question.Correct) {
			return 'notselectedincorrect';
		}

		return '';
	};

    return (
        <div>
			<div
				style={{
					backgroundColor: '#f1f1f1',
					padding: 10,
					flexGrow: 1,
					margin: 5,
					borderRadius: 8,
					boxShadow: "0 2px 4px 0 rgb(0 0 0 / 20%)"
				}}
			>
			<h5>{props.gQuestion.QText}</h5>

			<div className="quizPoints">
				{/* **********{gAnswer.Points}********* */}
				{props.gAnswer && (
					<input
						name={props.gQuestion.Id}
						// data-ganswerid="@item.ganswerid.Id"
						data-quizattemptanswerid="@item.gAttempts.Id"
						className="quizPoints-@qa.Id ms-TextField-field"
						type="number"
						//defaultValue={gAnswer.Points}
						value={props.gAnswer.Points}
						// value={item.QuizAttemptAnswer.Points}
						onChange={(event) => props.onPointChange(event, props.gQuestion, props.gAnswer)}
					/>
				)}
				<span> / {props.gQuestion.QMaxPoints}</span>
			</div>
			{props.gQuestion.QType == '4' && props.gAnswer && (
				<span
					dangerouslySetInnerHTML={{
						__html: props.gAnswer.Answer
					}}
					className="quizcomment"
				></span>
			)}
			<ul className="gradingList">
				{props.gQuestion.Options &&
					props.gQuestion.Options.map((o) => {
						return (
							<li className={mapToCssClass(props.answer, o)}>{o.Text}</li>
						);
					})}
			</ul>
	    	</div>
	    </div>
    )
}
