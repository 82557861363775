import * as React from "react";
import { getTeacherState } from "../../actions/Action_Teacher";
import * as _ from "lodash";
import { connect } from "react-redux";
import * as microsoftTeams from "@microsoft/teams-js";
import { Dropdown, Label, PrimaryButton } from "office-ui-fabric-react";
import { getAllClasses,saveTeamMap } from '../../actions/Action_Graph';
import { planUtils } from "../ukeplan/PlanElementEditView";
export interface IConfigProps {
  subscribedCourses: {};
  saveTeamMap(tm:any);
}
let selectedcourse ='';
class Config extends React.Component<IConfigProps> {
  public mapPlanElementsToKeys(se: any) {
    const options = _.values(se);
    const optionKeyList = [];

    options.forEach((se) => {
      optionKeyList.push({ key: se.CourseID, text: se.Title });
    });

    return optionKeyList;
  }
  componentDidMount() {
    console.log('config starting');
    microsoftTeams.initialize();
  }
  public _savesettings() {
    console.log("lagrer");
    microsoftTeams.getContext((ctx)=>{
      console.log('thecontext',ctx);
      console.log('teamid',ctx);
      this.props.saveTeamMap({CourseID:selectedcourse,TeamID:ctx.tid});
    });
    //
    microsoftTeams.settings.setValidityState(true);
  }
  public render() {
    return (
      <div>
        <div className="ms-Grid-row">
          <div className="z-form-group">
            <div className="ms-Grid-col ms-sm12 ms-md4">
              <Label required={true} className="z-form-label">
                Velg fag
              </Label>
            </div>
            <div className="ms-Grid-col ms-sm12 ms-md8">
              <Dropdown
                options={this.mapPlanElementsToKeys(
                  this.props.subscribedCourses
                )}
                onChanged={(item) => {
                  selectedcourse=item.key.toString();
                  //microsoftTeams.settings.setValidityState(true);
                 // console.log(item);
                  microsoftTeams.settings.registerOnSaveHandler((saveEvent) => {
                    microsoftTeams.settings.setSettings({
                      // websiteUrl: `https://zokratesbeta.azurewebsites.net/#/InnleveringerCourseView/${item.key}`,
                      // contentUrl: `https://zokratesbeta.azurewebsites.net/#/InnleveringerCourseView/${item.key}`,
                      websiteUrl: `https://larserik-enable.eu.ngrok.io/#/InnleveringerCourseView/${item.key}`,
                      contentUrl: `https://larserik-enable.eu.ngrok.io/#/InnleveringerCourseView/${item.key}`,
                      entityId: "redIconTab",
                      suggestedDisplayName: `${item.text}`,
                    });
                    saveEvent.notifySuccess();
                    
                  });
                }}
              />
            </div>
          </div>
        </div>
        <div className="ms-Grid-row">
          <div className="z-form-group">
            <div className="ms-Grid-col ms-sm12 ms-md4"></div>
            <div className="ms-Grid-col ms-sm12 ms-md8">
              <PrimaryButton onClick={()=>{
                  microsoftTeams.getContext((ctx)=>{
                    console.log('thecontext',ctx);
                    this.props.saveTeamMap({CourseID:selectedcourse,TeamID:ctx.groupId});
                  });
                  //
                  microsoftTeams.settings.setValidityState(true);
              }}>Lagre</PrimaryButton>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
function mapStateToProps(state) {
  return {
    subscribedCourses: state.teacher.subscribedCourses,
  };
}

export default connect(mapStateToProps, { getTeacherState,saveTeamMap })(Config);
