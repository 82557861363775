import * as React from "react";
import {Dropdown, Label, Shimmer} from "office-ui-fabric-react";


interface ISelectFieldProps{
    label: string;
    input: any;
    options?:any[]
    meta: {
        touched: boolean;
        error: string;
    },
    placeholder?:string;
    defaultValue?:any;
    required?:boolean;
    disabled?:boolean;
    children:any;
    attemptedToSave?: boolean;
    onSelect?:(key:any)=>void;
    isLoading?: boolean;
}


const SelectField = (props:ISelectFieldProps) =>{

    const {
        label,
        input,
        required,
        disabled,
        onSelect,
        defaultValue,
        placeholder,
        options,
        meta: {
            touched,
            error
        },
        children
    } = props;

    const sortOptions = (list: any[]): any[] => {
        return list.sort((a, b) => {
           
            if (a.text === "Ingen tilretteleggingsgruppe") {
                return -1;
            }
            if (b.text === "Ingen tilretteleggingsgruppe") {
                return 1;
            }

            if (a.text === "Alle" && b.text != "Ingen tilretteleggingsgruppe") {
                return -1;
            }
            if (b.text === "Alle" && a.text != "Ingen tilretteleggingsgruppe") {
                return 1;
            }
            
            return a.text.toString().localeCompare(b.text);
        });
    }


    let displayElements;
    if(children && children.props && children.props.children && children.props.children.length > 0 && children.props.children[0].key == 0 && children.props.children[0].text == "Alle"){
        let options = children.props.children as any[];
        displayElements = sortOptions(options);
    }else if(children){
        let options = children.props.children as any[];
        displayElements = sortOptions(options);
    }
    const dropdownOptions = options || displayElements;
    // const dropdownOptions = options || children.props.children;

    const handleChange = (item) =>{
        input.onChange(item.key, item.value);

        if (onSelect){
            onSelect(item.key);
        }
    };

    return(
        <div className="ms-Grid-row">
            <div className="z-form-group">
                <div className="ms-Grid-col ms-sm12 ms-md4">
                    <Label required={required || false} className="z-form-label ms-Label">
                        {label}
                    </Label>
                </div>
                <div className="ms-Grid-col ms-sm12 ms-md8">
                    {!props.isLoading ? <Dropdown
                        {...input}
                        options={dropdownOptions}
                        selectedKey={input.value}
                        placeholder={placeholder}
                        defaultValue={(defaultValue != null || defaultValue != undefined) ? defaultValue : null}
                        required={required || false}
                        disabled={disabled || false}
                        errorMessage={(touched || (props.attemptedToSave)) ? error : ''}
                        onChanged={(item) => handleChange(item)}
                    /> : <Shimmer styles={{shimmerWrapper: {height: 30}}}/>}
                </div>
            </div>
        </div>
    )
};

export default SelectField;