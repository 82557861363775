import * as React from "react";
import * as moment from "moment";
import { PortfolioItemView } from "./PortfolioItemView";
import * as _ from "lodash";
import { connect } from "react-redux";
import {
  Dropdown,
  IDropdown,
  DropdownMenuItemType,
  IDropdownOption
} from "office-ui-fabric-react/lib/Dropdown";
import Select from "react-select";
moment.locale("nb");

export interface IPortfolioListViewViewProps {
  items: any[];
  history:any;
}
export interface IPortfolioListViewViewState {
  filtervalue: string;
  typefilter:string;
}

let showtext = true;
let showtask = true;
let showquiz = true;
const options = [
  { value: "0", label: "Planer, innleveringer og prøver" },
  { value: "1", label: "Planer" },
  { value: "2", label: "Innleveringer" },
  { value: "3", label: "Prøver" }
];
export class PortfolioListView extends React.Component<
  IPortfolioListViewViewProps,
  IPortfolioListViewViewState
> {
  constructor(props) {
    super(props);
    this.state = { filtervalue: "0",typefilter:"0"};
  }

  sortAlfabetic = (arr: any[]) : any[] =>{
    let sortArr =
    arr.slice(1).sort((a, b) =>{
      return a.label == b.label ? 0 : +(a.label > b.label) || -1;
    })
    return [arr[0], ...sortArr]
  }
  render() {
    let allcourses = [{
        value: "0",
        label: "Alle"
      }];
    let dict = {};
    this.props.items.forEach(element => {
      if (!dict[element.CourseID] && element.CourseName) {
        dict[element.CourseID] = element;
        allcourses.push({
          value: element.CourseID,
          label: element.CourseName
        });
      }
    });
    return (
      <div>
        <div className="ms-Grid-row">
          <div className="ms-Grid-col ms-sm12">
            {this.props.items.length > 0 && (
              <div style={{display: 'flex' }}>
                <div  style={{flex: 1}}>
                 
                <Select
                  options={options}
                  placeholder="Velg type"
                  style={{flex: 1}}
                  className="mb-10 mt-10 mr-10"
                  onChange={(e, val) => {
                    this.setState({typefilter : e.value });
                  }}
                />
                </div>
                <div style={{flex: 1}}>
                <Select
                  options={this.sortAlfabetic(allcourses)}
                  placeholder="Velg fag"
                  className="mb-10 mt-10"
                  onChange={(e, val) => {
                    this.setState({ filtervalue: e.value });
                  }}
                />
                </div>
              </div>
            )}
          </div>
          <div id='portfoliotext' className="ms-Grid-col ms-sm12">
            {this.props.items.map(item => {
              if (
                (this.state.filtervalue === "0" ||
                this.state.filtervalue === item.CourseID)
                &&
                (this.state.typefilter==="0"||item.Type==this.state.typefilter)
              ) {
                return (
                  <PortfolioItemView
                    item={item}
                    showtask={true}
                    showtext={true}
                    showquiz={true}
                    history={this.props.history}
                  ></PortfolioItemView>
                );
              }
            })}
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {};
}
export default connect(
  mapStateToProps,
  {}
)(PortfolioListView);
