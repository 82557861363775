import { IIdentitySet } from "../IIdentitySet";
import { IOutcome } from "../IOutcome";
import { IResource } from "../IResource";

export class FeedbackResourceOutcome {
    id: string;
    lastModifiedBy: IIdentitySet;
    lastModifiedDateTime: Date;
    feebackResource: IResource;
    resourceStatus: string;

    constructor(outcome: IOutcome) {
        this.id = outcome.id;
        this.lastModifiedBy = outcome.lastModifiedBy;
        this.lastModifiedDateTime = outcome.lastModifiedDateTime;
        this.feebackResource = outcome.feebackResource;
        this.resourceStatus = outcome.resourceStatus;
    }
}