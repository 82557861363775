import * as React from "react";

import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { Field, reduxForm, getFormValues } from "redux-form";
import {
  Checkbox,
  PrimaryButton,
  ActionButton,
  DefaultButton,
  Label,
  IconButton,
  Icon,
  TooltipHost,
  Dialog,
  DialogFooter,
  DialogType,
  Spinner,
  SpinnerSize
} from "office-ui-fabric-react";
import OneDrivePicker from "../shared/OneDrivePicker";
import { getRecipients } from "../../actions/Action_Teacher";
import {
  updateMessageElement,
  deleteNewsElement,
} from "../../actions/Action_Ukeplan";
import Select from "react-select";
import InputField from "../form_fields/InputField";
import DateTimeField from "../form_fields/DateTimeField";
import RichTextField from "../form_fields/RichTextField";
import ReactTooltip from "react-tooltip";
import moment = require("moment");
import { MessageElement } from "../../models/MessageElement";
import { isSameGroupAsBefore, isSameRecipientsAsBefore } from "../../guards/CopyPlanGuard";
export const LOCALE = "nb";
export const DATE_FORMAT = "DD.MM.YYYY";
export const TIME_FORMAT = "LT";
export interface IMessageFormProps {
  updateMessageElement(se, callback: any, errorCallback?: any);
  getRecipients(statedata: any);
  deleteNewsElement(n: any);
  planElements: {};
  teacherstate: any;
  rcps: any[];
  initialValues: any;
  handleSubmit: any;
  history: any;
  match: any;
  selectedfiles: any[];
  startselected: any[];
  rcpstudent: any[];
  mode: string;
  formValues: MessageElement; //TODO: MessageElement Interface
}
export interface IMessageFormState {
  selectedfiles: any[];
  showrct: boolean;
  recipientDiscrepancy: boolean;
  showCopyPlanDialog: boolean;
  saving: boolean;
  showDraftModeWarning: boolean;
}

interface ITooltipInfo {
  tooltipIcon: string;
  tooltipText: string;
}

let rcps = [];
let initialselected = [];
const rcpDiscrepancyTooltip = "tooltipId"; 
class MessageForm extends React.Component<
  IMessageFormProps,
  IMessageFormState
> {
  constructor(props) {
    super(props);
    this.state = { 
      selectedfiles: null, 
      showrct: false, 
      recipientDiscrepancy: false,
      showCopyPlanDialog: false,
      saving: false,
      showDraftModeWarning: false
    };
  }

  componentWillReceiveProps(props) {
      console.log('receiving',props);
    if (!this.state.selectedfiles) {
      this.setState({ selectedfiles: this.props.selectedfiles });
    }
  }

  componentWillMount(){
    this.checkRcpDiscrepancy();
    if(this.props.initialValues && this.props.initialValues.Foresattportal){
      this.setState({ showrct: true });
    }
  }
  /****************************
            Event handlers
     ****************************/
  onFormSubmit(values) {
    console.log("Submit");
    
    const requestValues = this.formatFormValueDatesToDateString(values);
    let tmp = requestValues;
    tmp.NewsDocuments = "";
    this.state.selectedfiles.forEach((element) => {
      if (tmp.NewsDocuments.length > 0) {
        tmp.NewsDocuments += ";";
      }
      tmp.NewsDocuments += element.OriginalDocumentID + "|" + element.FileName;
    });
    tmp.NewsFiles = this.state.selectedfiles;
    let r = "";
    if (Array.isArray(requestValues.Recipients)) {
      requestValues.Recipients.forEach((element) => {
        if (r.length > 0) {
          r += ";";
        }
        r += element.value;
      });
      tmp.Recipients = r;
    } else {
      initialselected.forEach((element) => {
        if (r.length > 0) {
          r += ";";
        }
        r += element.value;
      });
      tmp.Recipients = r;
    }
    if(!tmp.Foresattportal){
      tmp.Kvittering = false;
    }
    this.props.updateMessageElement(tmp, (end) => {
      this.props.history.push("/ukeplan");
    }, () => {
      this.setState({saving: false, showCopyPlanDialog: false});
    });
  }

  onDeleteClick() {
    const { id } = this.props.match.params;

    if (isNaN(id)) {
      return;
    }

    if (confirm("Vil du virkelig slette denne meldingen?")) {
      this.props.deleteNewsElement({ Id: id });
      this.props.history.push("/ukeplan");
    }
  }

  /****************************
            Helper methods
     ****************************/
  sortDropdownArr = (array) : any[] =>{
    //1 - skole, 2 - klasser, 3 - elever
    let optionIdentifier = 1;
    let schoolArray = [];
    let classArray = [];
    let studArray = [];
    let fagArray = [];
    array.forEach(element =>{
      (element.label === 'Elever' || element.label === "Klasse" || element.label === "Fag") && optionIdentifier++;

      switch(optionIdentifier){
        case 1:
          schoolArray.push(element);
          break;
        case 2:
          classArray.push(element);
          break;
        case 3:
          studArray.push(element);
          break;
        case 4:
          fagArray.push(element);
        default:
          break;
      }
    })
    const alphabeticSortFunc = function(a, b){
      return a.label == b.label ? 0 : +(a.label > b.label) || -1;
    }

    const numbericSortFunc = function(a,b){
      return +a.label.substring(0, a.label.length - 1) > +b.label.substring(0, b.label.length -1) ? 1 : 
      +a.label.substring(0, a.label.length - 1) < +b.label.substring(0, b.label.length -1) ? -1 : 0;
    }
    schoolArray[0].isDisabled = true;
    classArray[0].isDisabled = true;
    studArray[0].isDisabled = true;
    fagArray[0].isDisabled = true;
    schoolArray = [...schoolArray.slice(0,1), ...schoolArray.slice(1).sort(alphabeticSortFunc)]
    fagArray = [...fagArray.slice(0,1), ...fagArray.slice(1).sort(alphabeticSortFunc)]
    classArray = [...classArray.slice(0,1), ...classArray.slice(1).sort(numbericSortFunc)];
    studArray = [...studArray.slice(0,1), ...studArray.slice(1).sort(alphabeticSortFunc)];
    return [...schoolArray, ...classArray, ...studArray, ...fagArray];
  }
  focusTopRef = () =>{
    document.querySelector("#root > div > div > div:nth-child(3) > div:nth-child(3)").scrollTo(0,0);
  }
  formatFormValueDatesToDateString(values) {
    return values;
  }

  checkRcpDiscrepancy() {
    if(this.props.initialValues && this.props.initialValues.AllRcps){
      let initialRcp = this.props.initialValues.AllRcps.trim().split(";").filter(i => i);
      
      if(initialRcp.length != initialselected.length){
        this.setState({recipientDiscrepancy: true});
      }
      else{
        this.setState({recipientDiscrepancy: false});
      }
    }

  }

  shouldShowWarning(foresattportal: boolean, hideBeforeStart: boolean) {
    return (foresattportal && hideBeforeStart);
  }

  /****************************
            Render methods
     ****************************/

  renderCheckField(field) {
    const {
      input,
      meta: { touched, error },
    } = field;
    return (
      <div className="ms-Grid row">
        <div className="z-form-group">
          <div className="ms-Grid-col ms-sm12 ms-md4"></div>
          <div className="ms-Grid-col ms-sm12 ms-md8">
            <Checkbox
              checked={input.value}
              label={field.label}
              errorMessage={touched ? error : ""}
              {...field.input}
              onChange={(event, u) => {
                input.onChange(u);
              }}
            />
            <label>
              <span>{error ? "*" : ""}</span>
            </label>
          </div>
        </div>
      </div>
    );
  }

  renderCheckFieldTextLeft(field: any, tooltipInfo?: ITooltipInfo) {
    const {
      input,
      meta: { touched, error },
    } = field;
    return (
      <div className="ms-Grid row">
        <div className="z-form-group">
          <div className="ms-Grid-col ms-sm12 ms-md4">
            <Label className="z-form-label" style={{display: "flex", alignItems: "center"}}>
                {field.label}
                {tooltipInfo && <span>
                  <Icon data-tip iconName={tooltipInfo.tooltipIcon} style={{fontSize: 15, marginLeft: 6, color: "grey", marginTop: 2}}/>
                  </span>}
            </Label>
                  <ReactTooltip>
                    <p>{tooltipInfo.tooltipText}</p>
                  </ReactTooltip>
          </div>
          <div className="ms-Grid-col ms-sm12 ms-md8">
            <div style={{marginTop: 5}}>
              <Checkbox
                checked={input.value}
                errorMessage={touched ? error : ""}
                {...field.input}
                onChange={(event, u) => {
                  input.onChange(u);
                }}
              />
              <label>
                <span>{error ? "*" : ""}</span>
              </label>
            </div>
          </div>
        </div>
      </div>
    );
  }

  renderSelectField = (field) => {
    const {
      input,
      required,
      meta: { touched, error },
      children,
    } = field;

    let o = [];
    
    //-------- Custom styles for Dropdown ----------//
    const CustomStyle = {
      option: (base, state) => ({
        ...base,
        color: state.label === 'Skole' ? 'blue' : state.label === 'Klasse' ? '#5199FF' : state.label === 'Elever' ? 'green' : state.label === 'Fag' ? '#5dbd00' : 'black' ,
        fontWeight: state.label === 'Skole' ? '800' : state.label === 'Klasse' ? '800' : state.label === 'Elever' ? '800' : state.label === 'Fag' ? '800' : ''
      })
    }

    //------ListLoop-------//
    children.forEach((element) => {
      o.push(element.props);
    });
    o = this.sortDropdownArr(o);
    return (
      <div className="ms-Grid-row">
        <div className="z-form-group">
          <div className="ms-Grid-col ms-sm12 ms-md4">
            <Label required={required} className="z-form-label">
                {field.label}
            </Label>
          </div>
          <div className="ms-Grid-col ms-sm12 ms-md8">
            <Select
              styles={CustomStyle}
              defaultValue={initialselected}
              isMulti={true}
              placeholder="Velg mottaker..."
              onChange={(selected) => {
                input.onChange(selected);
              }}
              options={o}
            />
            {touched && error && <span className="error-message">{error}</span>}
          </div>
        </div>
      </div>
    );
  }

  
  render() {
    console.log(this.props)
    const { handleSubmit } = this.props;
console.log('formrendering',this.props);
    return (
      <form id="MessageForm" onSubmit={handleSubmit(this.onFormSubmit.bind(this))}>
        <div className="form-content">
          <Field
            name="Recipients"
            required={true}
            label={this.state.recipientDiscrepancy ? 
              <div style={{display: "inline-block"}}>
                MOTTAKER {" "}
                <TooltipHost
                content="NB! Denne meldingen har mottakere du ikke lenger abonnerer på, og derfor vises ikke disse lenger i listen. Lagrer du nå vil de slettes som mottaker og ikke lenger få meldingen."
                id={rcpDiscrepancyTooltip}
                style={{display: "inline-block"}}
                >
                  <Icon aria-describedby={rcpDiscrepancyTooltip} iconName={"Error"} className={'ms-fontColor-red ms-fontSize-l'}/>
                </TooltipHost>
              </div> 
              : "Mottaker"
            }

            component={this.renderSelectField}
          >
            {this.props.rcps.map((r) => (
              <option value={r.value} label={r.label} />
            ))}
          </Field>
          <div className="ms-Grid-row">
            <div className="z-form-group">
              <div className="ms-Grid-col ms-sm12 ms-md4">
                <Label className="z-form-label">Sendes til</Label>
              </div>
            </div>
          </div>
          <Field
            name="Foresattportal"
            label="Foresattportal (NB! Går umiddelbart som kopi til foresattes e-post)"
            component={this.renderCheckField}
            onChange={(event, value) => {
              
              if (this.shouldShowWarning(value, this.props.formValues.HideBeforeStart)) {
                this.setState({ showrct: value, showDraftModeWarning: true });
              }
              else {
                this.setState({ showrct: value });
              }
            }}
          />
          {this.state.showrct && (
            <Field
              name="Kvittering"
              label="Krever kvittering"
              component={this.renderCheckField}
            />
          )}

          <Field
            name="StartsideElev"
            label="Startside elev"
            component={this.renderCheckField}
          />
          <Field
            name="Ukeplan"
            label="Ukeplan"
            component={this.renderCheckField}
          />
          <Field
            name="Title"
            label="Tittel"
            required={true}
            component={InputField}
          />
          <Field
            name="Text"
            label="Melding"
            required={true}
            component={RichTextField}
          />
          <Field
            name="DateFrom"
            label="Vis fra"
            dateFormat="DD.MM.YYYY"
            showTime={false}
            required={true}
            component={DateTimeField}
          />
          <Field
            name="Date"
            label="Vis til"
            dateFormat="DD.MM.YYYY"
            showTime={false}
            required={true}
            component={DateTimeField}
          />
          <Field
            name="HideBeforeStart"
            label="Skjul før startdato"
            component={(field) => this.renderCheckFieldTextLeft(field, {tooltipIcon: "Info", tooltipText: "Meldingen vil ikke vises for elever før startdato, men andre lærere vil kunne se og redigere meldingen"})}
            onChange={(event, value) => {
              if (this.shouldShowWarning(this.props.formValues.Foresattportal, value)) {
                this.setState({showDraftModeWarning: true})
              }
            }}
          />

          <div className="ms-Grid">
            <div className="ms-Grid-col ms-sm12 ms-md4"></div>
            <div className="ms-Grid-col ms-sm12 ms-md8">
              <OneDrivePicker
                onClose={(selected) => {
                  let tmp = this.state.selectedfiles;
                  tmp.push(selected);
                  this.setState({ selectedfiles: tmp });
                }}
              />
              <ul className="task-element-form--list">
                {this.state.selectedfiles &&
                  this.state.selectedfiles.map((file) => {
                    return (
                      <li className="ms-Grid-row">
                        <div className="ms-Grid-col ms-sm1">
                        <IconButton
                          iconProps={{ iconName: "BoxSubtractSolid" }}
                          style={{color: 'red'}}
                          onClick={() => {
                            let tmp = [];
                            this.state.selectedfiles.forEach((element) => {
                              if (
                                element.OriginalDocumentID !=
                                file.OriginalDocumentID
                              ) {
                                tmp.push(element);
                              }
                            });
                            this.setState({ selectedfiles: tmp });
                          }}
                        />
                        </div>
                        <div className="ms-Grid-col ms-sm11 pt-5">
                            <span>
                              {file.FileName}
                            </span>
                        </div>
                      </li>
                    );
                  })}
              </ul>
            </div>
          </div>
        </div>
        <div className="form-footer">
          <div className="form-footer-left">
            {this.props.match.params.id !== "ny_melding"
            //  && this.props.initialValues.Id!==0 
            && this.props.mode !== 'c' 
             &&(
              <div>
                  <ActionButton
                iconProps={{ iconName: "Delete" }}
                onClick={this.onDeleteClick.bind(this)}
              >
                Slett
              </ActionButton>
              
               <ActionButton
               text="Kopier til ny gruppe"
               iconProps={{ iconName: "Copy" }}
               onClick={() => {
                 this.focusTopRef();
                 this.props.history.push(
                   `/meldinger/${this.props.match.params.id}/c`
                 );
               }}
             />
              
              </div>
            
            )}
          </div>
          <div className="form-footer-right">
            <DefaultButton onClick={() => this.props.history.goBack()}>
              Avbryt
            </DefaultButton>
            <PrimaryButton 
              // onClick={handleSubmit(this.onFormSubmit.bind(this))}>
              onClick={() => {
                if (!this.state.saving && this.props.mode == "c" && isSameRecipientsAsBefore(this.props.formValues.Recipients, this.props.initialValues.Recipients))
                {
                  console.log("ShowDialog")
                  this.setState({showCopyPlanDialog: true});
                }
                else {
                  if (!this.state.saving && Object.keys(validate(this.props.formValues)).length == 0) {
                    console.log("SaveMessage")
                    this.setState({
                        saving: true
                      }, handleSubmit(this.onFormSubmit.bind(this)));
                    }
                  }
                  console.log("Elsed", validate(this.props.formValues))
                }
              }
              >
              {!this.state.saving ? "Lagre" : 
                <Spinner
                    size={SpinnerSize.small}
                ></Spinner>}
            </PrimaryButton>
          </div>
        </div>
        <Dialog
					hidden={!this.state.showCopyPlanDialog}
					onDismiss={() => {
						this.setState({saving: false, showCopyPlanDialog: false})
					}}
					dialogContentProps = {{title: "Obs!", styles: {subText: {fontSize: "15px"}}, subText: "Du er ferd med å opprette en ny melding uten å endre gruppe. Er du sikker på at du vil lagre?", type: DialogType.normal}}
				>
					<DialogFooter>
						<PrimaryButton onClick={() => {
								this.setState({showCopyPlanDialog: false, saving: true});
								handleSubmit(this.onFormSubmit.bind(this));
								this.onFormSubmit(this.props.formValues);
							}}
							text="Lagre" />
						<DefaultButton onClick={() => {
							this.setState({saving: false, showCopyPlanDialog: false})
						}} text="Avbryt" />
					</DialogFooter>
				</Dialog>
        <Dialog
          hidden={!this.state.showDraftModeWarning}
          onDismiss={() => {
            this.setState({showDraftModeWarning: false})
          }}
          dialogContentProps={{title: "Obs!", styles: {subText: {fontSize: "15px"}}, subText:"Foresattportal er markert og dette medfører umiddelbar mail til foresatte. For å unngå dette, ta bort markeringen på 'Foresattportal' og aktiver den igjen før meldingen skal publiseres.", type: DialogType.normal}}
        ><DialogFooter>
          <PrimaryButton
            onClick={() => {
              this.setState({showDraftModeWarning: false})
            }}
          >
            Ok
          </PrimaryButton>
        </DialogFooter>
        </Dialog>
      </form>
    );
  }
}

function validate(values) {
  const errors: any = {};
  // Validate inputs from 'values'
  if (!values.Recipients || !values.Recipients.length) {
    errors.Recipients = "Du må legge til minst en mottaker.";
  }

  if (!values.Title) {
    errors.Title = "Tittel er påkrevd.";
  }

  if (!values.Text) {
    errors.Text = "Meldingstekst er påkrevd.";
  }

  if (!values.DateFrom) {
    errors.DateFrom = "Dette feltet er påkrevd.";
  }

  if (!values.Date) {
    errors.Date = "Dette feltet er påkrevd.";
  }
  console.log('validating2',values);
  if (
    moment(values.DateFrom, "DD.MM.YYYY") > moment(values.Date, "DD.MM.YYYY")
  ) {
    errors.Date = 'Kan ikke være tidligere enn "Vis Fra".';
  }
  // If errors is an empty object, the form can be submitted
  // If errors has *any* properties, redux forms assumes the form is invalid
  return errors;
}

const mapStateToProps = (state, ownProps) => {
  let t = {};
  initialselected = [];
  if (initialselected.length == 0) {
    if (state.form.MessageForm.initial.AllRcps) {
      state.form.MessageForm.initial.AllRcps.split(";").forEach((element) => {
        t[element] = true;
      });
    }
  }

  const options = [];

  if (state.teacher.schools) {
    options.push({ value: "s-", label: "Skole" });
    Object.keys(state.teacher.schools).forEach((key) => {
      options.push({
        value: "s-" + state.teacher.schools[key].SchoolId,
        label: state.teacher.schools[key].Name,
      });
      if (t[state.teacher.schools[key].SchoolId]) {
        initialselected.push({
          value: "s-" + state.teacher.schools[key].SchoolId,
          label: state.teacher.schools[key].Name,
        });
      }
    });
  }
  if (state.teacher.subscribedSchoolClasses) {
    options.push({ value: "c-", label: "Klasse"});
    Object.keys(state.teacher.subscribedSchoolClasses).forEach((key) => {
      options.push({
        value: "c-" + state.teacher.subscribedSchoolClasses[key].ClassID,
        label: state.teacher.subscribedSchoolClasses[key].ClassName,
      });

      if (t[state.teacher.subscribedSchoolClasses[key].ClassID]) {
        initialselected.push({
          value: "c-" + state.teacher.subscribedSchoolClasses[key].ClassID,
          label: state.teacher.subscribedSchoolClasses[key].ClassName,
        });
      }
    });
  }
  if(state.teacher.subscribedCourses){
    options.push({value: "f-", label: "Fag"})
    Object.keys(state.teacher.subscribedCourses).forEach((key) =>{
      options.push({
        value: "f-" + state.teacher.subscribedCourses[key].CourseID,
        label: state.teacher.subscribedCourses[key].Title,
      });
      if (t[state.teacher.subscribedCourses[key].CourseID]) {
        initialselected.push({
          value: "f-" + state.teacher.subscribedCourses[key].CourseID,
          label: state.teacher.subscribedCourses[key].Title,
        });
      }
    })
  }
  if (state.teacher.rcps) {
    if (initialselected.length == 0) {
      if (state.form.MessageForm.initial.AllRcps) {
        state.form.MessageForm.initial.AllRcps.split(";").forEach((element) => {
          t[element] = true;
        });
      }
    }

    options.push({ value: "e-", label: "Elever" });
    state.teacher.rcps.forEach((l) => {
      options.push({ value: "e-" + l.LoginName, label: l.Name });
      if (t[l.LoginName]) {
        initialselected.push({ value: "e-" + l.LoginName, label: l.Name });
      }
    });
  }
  rcps = options;
  let tmp = [];
  if (state.form.MessageForm.initial.NewsDocuments) {
    state.form.MessageForm.initial.NewsDocuments.split(";").forEach(
      (element) => {
        tmp.push({
          OriginalDocumentID: element.split("|")[0],
          FileName: element.split("|")[1],
        });
      }
    );
  }

  const formValues = getFormValues('MessageForm')(state);

  return {
    teacherstate: state.teacher,
    rcps: options,
    selectedfiles: tmp,
    formValues
  };
};

export default reduxForm({
  form: "MessageForm",
  validate,
  enableReinitialize: true,
})(
  connect(mapStateToProps, {
    getRecipients,
    updateMessageElement,
    deleteNewsElement,
  })(withRouter(MessageForm))
);
