import * as React from "react";
import { connect } from "react-redux";
import { getStudentQuizAttemptGrade } from "../../../actions/Action_Student";
import StudentQuizGradingCard from "./StudentQuizGradingCard";
export interface IStudentQuizGradingProps {
  getStudentQuizAttemptGrade: (attemptId: number) => any;
  match: any;
  quizAttempt: any;
}
interface IStudentQuizGradingtate {
  awaitingResponse: boolean;
}
export class StudentQuizGrading extends React.Component<
  IStudentQuizGradingProps,
  IStudentQuizGradingtate
> {
  fetchQuizAttempt = () => {
    const { id } = this.props.match.params;

    this.setState({ awaitingResponse: true });

    this.props
      .getStudentQuizAttemptGrade(id)
      .then((response) => {
        console.log(response);
        this.setState({ awaitingResponse: false });
      })
      .catch((error) => {
        console.error(error);
        this.setState({ awaitingResponse: false });
      });
  };

  getSelectedQuestionsList = (allQuestions: any[], selectedQuestions: string): any[] => {
    let questionsToReturn: any[];
    
    if(selectedQuestions && selectedQuestions.length > 0) {
      let questions: string[]  = selectedQuestions.split(";");
      questionsToReturn = allQuestions.filter(q => questions.indexOf(q.Id.toString()) !== -1); 
    }
    else {
      questionsToReturn = allQuestions;
    }
    
    return questionsToReturn;
  }

  renderQuizAttemptPoints() {
    return <span>
      <span className="labelQuiz">Poeng:</span>{" "}
        <span id="totalPoints-@qa.Id">
              {this.props.quizAttempt.Points} / {this.props.quizAttempt.MaxPoints ? this.props.quizAttempt.MaxPoints : this.props.quizAttempt.Quiz.TotalMaxPoints}
            </span>
      </span>
  }

  renderQuizAttemptComment() {
    if (this.props.quizAttempt && this.props.quizAttempt.ShowGrading) {
      return <div className="ms-Grid-row">
              <div className="ms-Grid-col ms-sm12 ms-lg7">
                <h5>Kommentar:</h5>
                <span className="remove-para-margin ql-editor" style={{backgroundColor: "transparent"}}
                  dangerouslySetInnerHTML={{
                    __html: this.props.quizAttempt.GradingComment,
                  }}
                ></span>
                <h5>Karakter</h5>
                {this.props.quizAttempt.Grade}{" "}
                {this.props.quizAttempt.GradeModifier}
              </div>
            </div> 
    }
    else {
      return <h5>
        Prøven er ikke rettet av lærer enda
      </h5>
    }
  }

  componentDidMount() {
    this.fetchQuizAttempt();
  }
  public render() {
    return (
      <div className="ms-Grid main quizForm container">
          {this.props.quizAttempt && (this.props.quizAttempt.ShowGrading || this.props.quizAttempt.Quiz) && (
          <div className="ms-Grid-col ms-sm12 header" id="evalBlock">
            <div className="ms-Grid-row">
              <div className="ms-Grid-col ms-sm12 ms-lg7">
                <h3 className="student-name">
                  {this.props.quizAttempt.ShowGrading ? `${this.props.quizAttempt.StudentName} | ` : this.props.quizAttempt.StudentName}
                  {this.props.quizAttempt.ShowGrading && <small className="text-muted">
                    {this.renderQuizAttemptPoints()}
                  </small>}
                </h3>
              </div>
            </div>
            {this.renderQuizAttemptComment()}
          </div>
        )}
        {this.props.quizAttempt && (
          <div className="ms-Grid-col ms-sm12">
            <div style={{ display: "flex", flexWrap: "wrap" }}>
              {this.props.quizAttempt.Quiz &&
                this.props.quizAttempt.Quiz.Questions &&
                
                this.getSelectedQuestionsList(this.props.quizAttempt.Quiz.Questions, this.props.quizAttempt.QuestionSelection).map((item) => {
                  console.log("quizAttempt", this.props.quizAttempt);
                  console.log("questionitem", item);
                  return <StudentQuizGradingCard question={item}></StudentQuizGradingCard>
                })}
            </div>
          </div>
        )}
        {this.props.quizAttempt && this.props.quizAttempt === "GradeNotYetAvailable" &&  
        <h4>
          Resultat fra prøve er ikke tilgjengelig enda
        </h4>}
      </div>
    );
  }
}
const mapStateToProps = (state, ownProps) => {
  const id = parseInt(ownProps.match.params.id);

  //   let quizAttempt = state.StudentReducer.student.quizAttemptGrades
  //     ? state.StudentReducer.student.quizAttempts.find((qa) => qa.Id === id)
  //     : undefined;

  return {
    quizAttempt: state.StudentReducer.student.quizAttemptGrades, // mapAnswersToQuizAttemptQuestions(quizAttempt)
  };
};

export default connect(mapStateToProps, {
  getStudentQuizAttemptGrade,
})(StudentQuizGrading);
