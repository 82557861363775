import * as React from "react";
import * as moment from "moment";
import * as _ from "lodash";
import { connect } from "react-redux";
moment.locale("nb");
import ReactHtmlParser, {
  processNodes,
  convertNodeToElement,
  htmlparser2
} from "react-html-parser";
import { ActionButton } from "office-ui-fabric-react";
import { Checkbox } from "office-ui-fabric-react/lib/Checkbox";
import FilesModal from "../FilesModal";
import { capitalizeFirstLetter } from "../../../utils/HelperFunctions";

export interface IStudentTaskelementViewProps {
  taskelement: any;
}
export interface IStudentTaskelementViewState {
  showfilesmodal: boolean;
}

class StudentTaskelementView extends React.Component<
  IStudentTaskelementViewProps,
  IStudentTaskelementViewState
> {
  constructor(props) {
    super(props);
    this.state = { showfilesmodal: false };
  }
  render() {
    let classname = "";
    if (this.props.taskelement.DeliveryStatus != 1 &&
      this.props.taskelement.DeliveryStatus != "UtenFil") {
      classname = "dimmed";
    }
    return (
      <li className={`list-group-item ${classname}`}>
        <div className="plan-kort-header">
          <h3 style={{ flex: 1 }}>
            <i className="ms-Icon ms-Icon--CompletedSolid" aria-hidden="true"></i>{" "}
            {this.props.taskelement.WsTask.Name}
          </h3>
          {this.props.taskelement.DeliveryStatus != 1 &&
            this.props.taskelement.DeliveryStatus != "UtenFil" && (
              <div className="pb-10 mb-10">
                <Checkbox checked={true} label="Fullført!" />
              </div>
            )}
          {this.props.taskelement.DeliveryStatus == 1 ||
            (this.props.taskelement.DeliveryStatus == "UtenFil" && (
              <div></div>
            ))}
          {
            this.props.taskelement.DeliveryStatus === 1 &&
            <div className="pb-10 mb-10">
              <Checkbox label="Ikke fullført" />
            </div>
          }
        </div>

        <dl>
          {this.props.taskelement.WsTask.ShowKmpGoals &&
            this.props.taskelement.WsTask.GrepCodeList.length > 0 && (
              <div>
                <dt>Kompetansemål</dt>
                <dd>
                  <ul id="kmp-2-@this.props.taskelement.Id" className="grep-list">
                    {this.props.taskelement.WsTask.GrepCodeList.map(element => {
                      return (
                        <li>
                          <a href={element.DetailUrl} target="_blank">
                            {element.GrepTekst}
                          </a>
                        </li>
                      );
                    })}
                  </ul>
                </dd>
              </div>
            )}
        </dl>

        {this.props.taskelement.WsTask.Goal.length > 0 && (
          <dl>
            <dt>Egendefinerte mål</dt>
            <dd>
              <span className="remove-para-margin ql-editor ql-background"
                dangerouslySetInnerHTML={{
                  __html: this.props.taskelement.Goal
                }}
              ></span>
            </dd>
          </dl>
        )}
        {this.props.taskelement.WsTask.EvaluationText.length > 0 ||
          (this.props.taskelement.WsTask.EvaluationTextOther.length > 0 && (
            <dl>
              <dt>Evaluering</dt>
              <dd className="scheduleEvaluation">
                <ul>
                  {this.props.taskelement.WsTask.EvalElements.map(element => {
                    <li>{element}</li>;
                  })}
                </ul>
              </dd>
            </dl>
          ))}

        <dl>
          <dt>Beskrivelse</dt>
          <dd>
            <span className="remove-para-margin ql-editor ql-background"
              dangerouslySetInnerHTML={{
                __html: this.props.taskelement.WsTask.TextRaw
              }}
            ></span>
          </dd>
        </dl>
        {this.props.taskelement.WsTask.Link.length > 0 && (
          <div>
            <dt>Lenke</dt>
            <dd>
              <a target="_blank" href={this.props.taskelement.WsTask.Link}>
                {this.props.taskelement.WsTask.Link}
              </a>
            </dd>
          </div>
        )}
        <dt> Frist</dt>
        <dd>{capitalizeFirstLetter(moment(this.props.taskelement.Deadline).format("dddd").toString())} {this.props.taskelement.WsTask.Deadlinetekst}</dd>

      </li>
    );
  }
}
function mapStateToProps(state) {
  return {};
}
export default connect(
  mapStateToProps,
  {}
)(StudentTaskelementView);
