import * as _ from "lodash";
import {
    GET_TENANTS,
    GET_USERS
} from "../actions/Action_Godmode";
const statestructure = {
};
export default function (state = statestructure, action) {
    switch (action.type) {
     
          case GET_TENANTS: 
          const Tenants = action.payload.data;
          return {...state, Tenants};
          case GET_USERS: 
          const users = action.payload.data;
          return {...state, users};
      default:
        return state;
    }
  }