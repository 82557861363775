import { IIdentitySet } from "../IIdentitySet";
import { IItemBody } from "../IItemBody";
import { IOutcome } from "../IOutcome";

export class RubicOutcome {
    id: string;
    lastModifiedBy: IIdentitySet;
    lastModifiedDateTime: Date;
    publishedRubricQualityFeedback:  IRubicQualityFeedbackModel;
    publishedRubricQualitySelectedLevels: IRubicQualitySelectedColumnModel;
    rubricQualityFeedback: IRubicQualityFeedbackModel;
    rubricQualitySelectedLevels: IRubicQualitySelectedColumnModel;

    constructor(outcome: IOutcome) {
        this.id = outcome.id;
        this.lastModifiedBy = outcome.lastModifiedBy;
        this.lastModifiedDateTime = outcome.lastModifiedDateTime;
        this.publishedRubricQualityFeedback = outcome.publishedRubricQualityFeedback;
        this.publishedRubricQualitySelectedLevels = outcome.publishedRubricQualitySelectedLevels;
        this.rubricQualityFeedback = outcome.rubricQualityFeedback;
        this.rubricQualitySelectedLevels = outcome.rubricQualitySelectedLevels;
    }
}

export interface IRubicQualityFeedbackModel {
    feedback: IItemBody;
    qualityId: string;
}

export interface IRubicQualitySelectedColumnModel {
    columnId: string;
    qualityId: string;
}