import * as React from "react";
import * as moment from "moment";
import * as _ from "lodash";
import { connect } from "react-redux";
import {
  Accordion,
  AccordionItem,
  AccordionItemTitle,
  AccordionItemBody
} from "react-accessible-accordion";
import "react-accessible-accordion/dist/fancy-example.css";
import { Label, Icon } from "office-ui-fabric-react";
import Reducer_Grep from "../../reducers/Reducer_Grep";
moment.locale("nb");

export interface IPortfolioLPFFProps {
  toggleLoading();
  item: any;
  items: any[];
  history: any;
  kmsdetails: any;
  portfolioItems: any[];
}
export interface IPortfolioLPFFState {
  item: any;
}

export default class PortfolioLPFF extends React.Component<
  IPortfolioLPFFProps,
  IPortfolioLPFFState
> {
  constructor(props) {
    super(props);

    this.state = {
      item: {}
    }
  }



  componentWillMount(){
      this.mapFagfornyelsen();
  }

  

  //--------------Helping methods-------------------//
  mapFagfornyelsen(){
    let localitem = this.props.item;
    let portItemList = this.props.portfolioItems.filter(portitem => (portitem.Tasks.length > 0 || 
      portitem.Texts.length > 0 || portitem.Wstasks.length > 0 || 
      (portitem.QuizAttempts && portitem.QuizAttempts.length > 0) || 
      (portitem.Quizzes && portitem.Quizzes.length > 0)))

    let aarstrinnArray = [];

    Object.values(this.props.kmsdetails).forEach((kmsdetail)=>{
      aarstrinnArray.push([kmsdetail["etter-aarstrinn"], kmsdetail["etter-fag"], kmsdetail["kompetansemaal"]]);
    })


    aarstrinnArray.forEach((aarstrinnElement, i) =>{
      aarstrinnElement[2].forEach((kompMal) =>{
        kompMal["tilhoerer_kompetansemaalsett"] = {kode: ""};
        kompMal["tilhoerer_kompetansemaalsett"].kode = Object.values(this.props.kmsdetails)[i]["kode"]
      })
      
    });

    localitem.kompetansemaal_kapittel.kompetansemaalsett.forEach((kompMal, i) =>{
      kompMal["etter_aarstrinn"] = aarstrinnArray[i][0];
      kompMal["etter_fag"] = aarstrinnArray[i][1];
      kompMal["kompetansemaal"] = aarstrinnArray[i][2];
    })

    for (let settIndex = 0; settIndex < localitem.kompetansemaal_kapittel.kompetansemaalsett.length; settIndex++) {
      const sett = localitem.kompetansemaal_kapittel.kompetansemaalsett[settIndex];
      for (let kompMalIndex = 0; kompMalIndex < sett.kompetansemaal.length; kompMalIndex++) {
        const kompMal = sett.kompetansemaal[kompMalIndex];
        kompMal.portfolioitems = {
          Tasks: [],
          Texts: [],
          Wstasks: [],
          QuizAttempts: [],
          Quizzes: [],
        }
        for (let portfolioIndex = 0; portfolioIndex < portItemList.length; portfolioIndex++) {
          const pfItem = portItemList[portfolioIndex];
          if(kompMal.kode == pfItem.KompetansemaalKode && (pfItem.Tasks.length > 0 || pfItem.Texts.length > 0 || pfItem.Wstasks.length > 0 || (pfItem.QuizAttempts && pfItem.QuizAttempts.length > 0) || (pfItem.Quizzes && pfItem.Quizzes.length > 0))){
            kompMal.portfolioitems = pfItem;
          }
        }
      } 
    }
    this.setState({item: localitem});
    
    this.props.toggleLoading();
  } 


  render() {
    return (
      <div>
        {/* --------------HELE LISTEN ---------*/}
        <Accordion>
          {this.state.item.kompetansemaal_kapittel.kompetansemaalsett.map(
            loopitem => {
             
              let totalset = 0;
              let totalhitcount = 0;
                let hit = false;
                loopitem.kompetansemaal.forEach(k => {
                  let totalhit = false;
                  if (k.tilhoerer_kompetansemaalsett.kode == loopitem.kode) {
                    totalset++;
                    if(k.portfolioitems.Texts.length>0)
                    {
                        console.log('treff');
                    }
                    if(k.portfolioitems.Tasks.length>0)
                    {
                        console.log('treff2');
                    }
                    if(k.portfolioitems.Wstasks && k.portfolioitems.Wstasks.length>0)
                    {
                        console.log('treff3');
                    }
                    if (
                      k.portfolioitems.Tasks.length > 0 ||
                      k.portfolioitems.Texts.length > 0 ||
                      k.portfolioitems.Wstasks.length > 0 ||
                      (k.portfolioitems.QuizAttempts && k.portfolioitems.QuizAttempts.length > 0) ||
                      (k.portfolioitems.Quizzes && k.portfolioitems.Quizzes.length > 0)
                    ) {
                      hit = true;
                      totalhit = true;
                    }
                  }
                  if (totalhit) {
                    totalhitcount++;
                  }
                });
              
              return (
                <AccordionItem>
                  <AccordionItemTitle>
                    <div className="ms-Grid-row">
                      <div className="ms-Grid-col ms-sm2">
                        {/* logikk for ikon må inn her */}
                        {totalhitcount / totalset < 0.3 && (
                          <Icon
                            iconName="StatusErrorFull"
                            className="red-color font-size-l"
                          />
                        )}
                        {totalhitcount / totalset >= 0.3 &&
                          totalhitcount / totalset < 0.7 && (
                            <Icon
                              iconName="IncidentTriangle"
                              className="yellow-color font-size-l"
                            />
                          )}
                        {totalhitcount / totalset >= 0.7 && (
                          <Icon
                            iconName="CheckboxCompositeReversed"
                            className="green-color font-size-l"
                          />
                        )}

                        <span className="ml-5">
                          {totalhitcount} av {totalset}
                        </span>
                      </div>
                      <div className="ms-Grid-col ms-sm3">
                        <span>
                          {loopitem.kode}
                          {(loopitem.etter_aarstrinn && loopitem.etter_aarstrinn[0]) && ` - ${loopitem.etter_aarstrinn[0].tittel}`}
                        </span>
                      </div>
                      <div className="ms-Grid-col ms-sm6">
                        <span>{(loopitem && loopitem.etter_fag && loopitem.etter_fag[0]) && loopitem.etter_fag[0].tittel}</span>
                      </div>
                    </div>
                  </AccordionItemTitle>
                  
                  <AccordionItemBody>
                    <div className="hArea panel">
                      <div className="ms-Grid-row">
                        <div className="ms-Grid-col ms-sm12">
                          <ul className="list-group no-type-list">
                            {loopitem.kompetansemaal.map(k => {
                              // Vet ikke om den trengs
                              if (
                                k.tilhoerer_kompetansemaalsett.kode
                              ) {
                                let classn = "redBG";
                                let iconName = "StatusErrorFull";
                                let classColor = "red-color";
                                if (
                                  k.portfolioitems.Tasks.length >
                                    0 ||
                                  k.portfolioitems.Texts.length >
                                    0
                                    ||
                                  k.portfolioitems.Wstasks.length >
                                    0
                                    ||
                                  (k.portfolioitems.QuizAttempts && k.portfolioitems.QuizAttempts.length > 0) ||
                                  (k.portfolioitems.Quizzes && k.portfolioitems.Quizzes.length > 0)
                                ) {
                                  classn = "hasitems greenBG";
                                  iconName =
                                    "CheckboxCompositeReversed";
                                  classColor = "green-color";
                                }

                                //-------ET LISTEELEMENT TIL KOMPETANSEMÅL--------//
                                return (
                                                
                                  <li
                                  className={`${classn +
                                    " list-group-item"}`}
                                    >
                                    <div className="ms-Grid-row">
                                      <div className="ms-Grid-col ms-sm1">
                                        <div className="statusIcon">
                                          <Icon
                                            iconName={`${iconName}`}
                                            className={`${classColor +
                                              " font-size-l"}`}
                                          />
                                                        
                                        </div>
                                      </div>
                                      <div className="ms-Grid-col ms-sm11">
                                        {k && k.tittel}

                                      <div className="portItms">
                                        <div className="ms-Grid-row">
                                          <div className="ms-Grid-col ms-sm4">
                                            <b>Innleveringer</b>
                                            <ul className="task-@k.kode no-type-list">
                                              {k.portfolioitems.Tasks.map(
                                                t => {
                                                  return (
                                                    <li className="workItem"  >
                                                      <a
                                                        className="pointer"
                                                        onClick={() => {
                                                          this.props.history.push(
                                                            "/innleveringer/student/" +
                                                              t.Id
                                                          );
                                                        }}
                                                      >
                                                        {
                                                          t
                                                            .WsTask
                                                            .Name
                                                        }
                                                      </a>
                                                    </li>
                                                  );
                                                }
                                              )}
                                              {k.portfolioitems.Wstasks.map(
                                                t => {
                                                  return (
                                                    <li className="workItem"  >
                                                      {
                                                        t.Id != 0 && 
                                                        <a
                                                        className="pointer"
                                                        onClick={() => {
                                                          this.props.history.push(
                                                            "/innleveringer/" +
                                                              t.Id
                                                          );
                                                        }}
                                                      >
                                                        {
                                                          t
                                                            .Name
                                                        }
                                                      </a>
                                                      }
                                                      {
                                                        t.Id == 0 && 
                                                        <a
                                                        className="pointer"
                                                       href={t.TeamsDeepLink}
                                                      >
                                                        {
                                                          t
                                                            .Name
                                                        }
                                                      </a>
                                                      }
                                                    </li>
                                                  );
                                                }
                                              )}
                                            </ul>
                                          </div>
                                            <div className="ms-Grid-col ms-sm4">
                                              <b>Planelementer</b>
                                              <ul className="plan-@k.kode">
                                                {k.portfolioitems.Texts.map(
                                                  t => {
                                                    return (
                                                      <li>
                                                         <a
                                                          className="pointer"
                                                          onClick={() => {
                                                            this.props.history.push("/ukeplan/" + t.Id + "/e");
                                                          }}
                                                        >
                                                          {
                                                            t.Theme
                                                          }
                                                        </a>
                                                        
                                                      </li>
                                                    );
                                                  }
                                                )}
                                              </ul>
                                            </div>
                                            <div className="ms-Grid-col ms-sm4">
                                              <b>Prøver</b>
                                              <ul className="task-@k.kode no-type-list">
                                               {k.portfolioitems.QuizAttempts && <div>{k.portfolioitems.QuizAttempts.map(
                                                  quizAttempt => {
                                                    return (
                                                      <li className="workItem"  >
                                                        <a
                                                          className="pointer"
                                                          onClick={() => {
                                                            this.props.history.push(
                                                              "/quiz/" +
                                                                quizAttempt.Qid
                                                            );
                                                          }}
                                                        >
                                                          {
                                                            quizAttempt.Quiz.Title
                                                          }
                                                        </a>
                                                      </li>
                                                    );
                                                  }
                                                )}</div>}
                                                {k.portfolioitems.Quizzes && <div>{k.portfolioitems.Quizzes.map(
                                                  quiz => {
                                                    return (
                                                      <li className="workItem"  >
                                                        <a
                                                          className="pointer"
                                                          onClick={() => {
                                                            this.props.history.push(
                                                              "/quiz/" +
                                                                quiz.Id
                                                            );
                                                          }}
                                                        >
                                                          {
                                                            quiz.Title
                                                          }
                                                        </a>
                                                      </li>
                                                    );
                                                  }
                                                )}</div>}
                                              </ul>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </li>
                                );
                              }
                            })}
                          </ul>
                        </div>
                      </div>
                    </div>
                  </AccordionItemBody>
                </AccordionItem>
              );
            }
          )}
        </Accordion>
      </div>
    )
  }
}
