import * as React from 'react';
import * as moment from 'moment';
import { connect } from 'react-redux';
import Select from 'react-select';
import DatePicker from 'react-datepicker';
import { Label, PrimaryButton, SearchBox, Stack, StackItem, Text, TextField } from 'office-ui-fabric-react';

import MyElementsList from './MyElementsList';
import SpinLoader from '../loading/SpinLoader';
import { Course } from '../../models/Course';
import { WsTask } from '../../models/WsTask';
import { WsText } from '../../models/WsText';
import { WsElement } from '../../models/WsElement';
import { getAvailableCourses } from '../../actions/Action_Teacher';
import { getAllElementsTeacher } from '../../actions/Action_Discovery';
import { Quiz } from '../../models/Quiz';
import { stringify } from 'querystring';

interface IMyElementsViewProps {
	getAllElementsTeacher(fromDate: any, toDate: any, courseList: any);
	getAvailableCourses(teacherState: any);
	teacherState: any;
	elementsFromServer: {
		tasks: WsTask[];
		texts: WsText[];
		quizzes: Quiz[];
	};
	availableCourses: Course;
	courseOptions: ICourseOption;
	history: any;
}

interface IMyElementsViewState {
	allElements: WsElement[];
	selectedCourses: ICourseOption[];
	selectedCoursesForFilter: ICourseOption[];
	searchWord: string;
	searchWordCourses: string[];
	detectedCourses: ICourseOption[];
	isLoadingElements: boolean;
	fromDate: moment.Moment;
	toDate: moment.Moment;
}

interface ICourseOption {
	key: string;
	label: string;
	text: string;
	value: string;
	courseName?: string;
	schoolYear?: string;
}

class MyElementsView extends React.Component<IMyElementsViewProps, IMyElementsViewState> {
	constructor(props) {
		super(props);

		this.state = {
			allElements: null,
			selectedCourses: [],
			selectedCoursesForFilter: [],
			searchWord: '',
			searchWordCourses: [],
			detectedCourses: [],
			isLoadingElements: false,
			fromDate: moment().subtract(1, 'years'),
			toDate: moment()
		};
	}

	componentDidMount() {
		this.props.getAvailableCourses(this.props.teacherState);
	}

	/****************************
            Helper methods
     ****************************/

	// TODO: Få kopier til ny innlevering til å virke ordentlig.
	hentElementer() {
		let tmpElementArray = [];
		let tmpDetectedCoursesArray = { courseList: [], courseNameList: [] };
		this.setState({
			isLoadingElements: true,
			detectedCourses: []
		});

		this.props
			.getAllElementsTeacher(this.state.fromDate.format(), this.state.toDate.format(), this.state.selectedCourses)
			.then(() => {
				this.props.elementsFromServer.tasks.forEach((taskElement) => {
					if (!taskElement.Name || taskElement.Name === null) {
						taskElement.Name = '';
					}

					let elementToAdd: WsElement = this.mapWsTaskToWsElement(taskElement); 
					
					this.addSchoolYearToElement(elementToAdd);

					let datedCourseName = `${elementToAdd.SchoolYear}   ${elementToAdd.CourseName}`;

					if (tmpDetectedCoursesArray.courseNameList.indexOf(datedCourseName) < 0) {
						tmpDetectedCoursesArray.courseNameList.push(datedCourseName);
						tmpDetectedCoursesArray.courseList.push({
							key: elementToAdd.CourseID,
							label: datedCourseName,
							text: datedCourseName,
							value: `${elementToAdd.CourseID} ${elementToAdd.SchoolYear}`,
							courseName: elementToAdd.CourseName,
							schoolYear: elementToAdd.SchoolYear
						});
					}

					tmpElementArray.push(elementToAdd);
				});

				this.props.elementsFromServer.texts.forEach((textElement) => {
					if (!textElement.Theme || textElement.Theme === null) {
						textElement.Theme = '';
					}

					this.addSchoolYearToElement(textElement);

					textElement.Name = textElement.Theme;
					textElement.ElementType = 'text';

					tmpElementArray.push(textElement);
				});

				this.props.elementsFromServer.quizzes.forEach((quiz) => {
					let elementToAdd: WsElement = this.mapQuizToWsElement(quiz);

					this.addSchoolYearToElement(elementToAdd);

					let datedCourseName = `${elementToAdd.SchoolYear}   ${elementToAdd.CourseName}`;

					if (tmpDetectedCoursesArray.courseNameList.indexOf(datedCourseName) < 0) {
						tmpDetectedCoursesArray.courseNameList.push(datedCourseName);
						tmpDetectedCoursesArray.courseList.push({
							key: elementToAdd.CourseID,
							label: datedCourseName,
							text: datedCourseName,
							value: `${elementToAdd.CourseID} ${elementToAdd.SchoolYear}`,
							courseName: elementToAdd.CourseName,
							schoolYear: elementToAdd.SchoolYear
						});
					}

					tmpElementArray.push(elementToAdd);
				});
			})
			.then(() => {
				this.setState({
					allElements: tmpElementArray,
					detectedCourses: tmpDetectedCoursesArray.courseList.sort(this.courseListSchoolYearSorter),
					isLoadingElements: false
				});
			});
	}

	mapWsTaskToWsElement = (task: WsTask): WsElement => {
		let elementToReturn: WsElement = {
			ElementType: "task",
			Id: task.Id,
			Course: task.Course,
			CourseID: task.CourseID,
			CourseName: task.CourseName,
			Day: task.Day,
			Deadline: task.Deadline,
			Deadlinetekst: task.Deadlinetekst,
			Goal: task.Goal,
			Grep: task.Grep,
			GrepCodeList: task.GrepCodeList,
			Hasfiles: task.Hasfiles,
			Link: task.Link,
			Plagiatkontroll: task.Plagiatkontroll,
			Status: task.Status,
			Students: task.Students,
			Teacher: task.Teacher,
			Text: task.Text,
			Week: task.Week,
			Year: task.Year,
			WeekFrom: task.WeekFrom,
			Name: task.Name
		};


		return elementToReturn;
	}

	mapQuizToWsElement = (quiz: Quiz): WsElement => {
		let elementToReturn: WsElement = {
			ElementType: "quiz",
			Id: quiz.Id,
			Course: quiz.Course,
			CourseID: quiz.CourseId,
			CourseName: quiz.CourseName,
			Day: moment(quiz.ValidFrom).day(),
			//Deadlinetekst: moment(quiz.ValidTo).format("DD.MM.yyyy hh:mm"),
			Deadlinetekst: quiz.ValidToText,
			Goal: "",
			Grep: quiz.Grep,
			GrepCodeList: quiz.GrepCodeList,
			Hasfiles: false,
			Link: "",
			Status: "",
			Students: null,
			Teacher: null,
			Text: quiz.Title,
			Week: moment(quiz.ValidTo).week(),
			Year: moment(quiz.ValidFrom).year(),
			WeekFrom: moment(quiz.ValidFrom).week(),
			Name: quiz.Title
		};


		return elementToReturn;
	}

	courseListSchoolYearSorter(a, b) {
		if (a.schoolYear < b.schoolYear) {
			return -1;
		}
		if (a.schoolYear > b.schoolYear) {
			return 1;
		}
		return 0;
	}

	createListForEachYear(listOfElements) {
		let elementDict = {};
		let key = '';

		listOfElements.forEach((element) => {
			key = element.SchoolYear;

			if (elementDict[key]) {
				elementDict[key].push(element);
			} else {
				elementDict[key] = [];
				elementDict[key].push(element);
			}
		});

		return elementDict;
	}

	addSchoolYearToElement(teacherElement) {
		if (teacherElement.Week < 30) {
			teacherElement.SchoolYear = `${teacherElement.Year - 1} - ${teacherElement.Year}`;
		} else {
			teacherElement.SchoolYear = `${teacherElement.Year} - ${teacherElement.Year + 1}`;
		}
	}

	addSchoolYearToQuiz(quiz) {
		if (quiz.Week < 30) {
			quiz.SchoolYear = `${quiz.Year - 1} - ${quiz.Year}`;
		} else {
			quiz.SchoolYear = `${quiz.Year} - ${quiz.Year + 1}`;
		}
	}

	filterElements(listOfElements): WsElement[] {
		let listToReturn = listOfElements.filter((e) =>
			e.Name.toLowerCase().includes(this.state.searchWord.toLowerCase())
		);
		if (this.state.selectedCoursesForFilter.length > 0) {
			listToReturn = listToReturn.filter((e) => {
				let isMatch = false;
				this.state.selectedCoursesForFilter.forEach((course) => {
					if (
						course.key.toLowerCase() === e.CourseID.toLowerCase() &&
						course.schoolYear.toLowerCase() === e.SchoolYear.toLowerCase()
					) {
						isMatch = true;
					}
				});
				return isMatch;
			});
		}
		return listToReturn;
	}

	/****************************
            Render methods
     ****************************/

	renderDateFields() {
		return (
			<Stack horizontal wrap horizontalAlign="start" tokens={{ childrenGap: 'm' }}>
				<div className="ms-Grid-row">
					<div className="ms-Grid-col ms-md-4">
						<Label className="date-label">Fra:</Label>
					</div>
					<div className="ms-Grid-col ms-md-8">
						<DatePicker
							selected={this.state.fromDate}
							locale="nb-NO"
							showWeekNumbers
							onChange={(date) => {
								this.setState({
									fromDate: date
								});
							}}
							onBlur={() => {}}
							autocomplete="off"
						></DatePicker>
					</div>
				</div>
				<div className="ms-Grid-row">
					<div className="ms-Grid-col ms-md-4">
						<Label className="date-label">Til:</Label>
					</div>
					<div className="ms-Grid-col ms-md-8">
						<DatePicker
							selected={this.state.toDate}
							locale="nb-NO"
							showWeekNumbers
							onChange={(date) => {
								this.setState({
									toDate: date
								});
							}}
							onBlur={() => {}}
							autocomplete="off"
						></DatePicker>
					</div>
				</div>
			</Stack>
		);
	}

	renderSearchFields() {
		return (
			<Stack
				className="searchfield-stack"
				horizontal
				horizontalAlign="space-between"
				verticalAlign="center"
				wrap
				tokens={{ childrenGap: 'm' }}
			>
				<StackItem grow={1}>
					<SearchBox
						className="searchbox"
						disabled={!this.state.allElements}
						placeholder="Søk i hentede elementer"
						onChange={(text) => this.setState({ searchWord: text })}
					></SearchBox>
				</StackItem>
				<StackItem grow={1}>
					<div className="select-detected-courses">
						<Select
							placeholder="Filtrer etter fag basert på år"
							isDisabled={this.state.detectedCourses.length < 1}
							options={this.state.detectedCourses}
							isClearable={true}
							isMulti={true}
							onChange={(item) => {
								this.setState({
									selectedCoursesForFilter: item
								});
							}}
						></Select>
					</div>
				</StackItem>
			</Stack>
		);
	}

	render() {
		return (
			<div>
				<div className="ms-Grid-row ms-bgColor-neutralLight topTool">
					<Stack
						horizontal
						wrap
						verticalAlign="center"
						horizontalAlign="space-between"
						tokens={{ childrenGap: 'm' }}
					>
						<Stack.Item grow={1}>
							<div className="select-current-courses">
								<Select
									placeholder="Begrens søk etter fag"
									options={this.props.courseOptions}
									isClearable={true}
									isMulti={true}
									onChange={(item) => {
										if (item) {
											this.setState({
												selectedCourses: item
											});
										}
									}}
								/>
							</div>
						</Stack.Item>

						<Stack horizontal wrap tokens={{ childrenGap: 'm' }}>
							{this.renderDateFields()}
							<Stack.Item>
								<PrimaryButton
									style={{ marginRight: '0px' }}
									onClick={() => {
										this.hentElementer();
									}}
								>
									Hent data
								</PrimaryButton>
							</Stack.Item>
						</Stack>
					</Stack>

					<hr />
					{this.renderSearchFields()}
				</div>

				{this.state.isLoadingElements && <SpinLoader text="Laster tidligere elementer" />}

				{!this.state.isLoadingElements && this.state.allElements && this.state.allElements.length > 0 && (
					<MyElementsList
						history={this.props.history}
						elementDict={this.createListForEachYear(this.filterElements(this.state.allElements))}
					></MyElementsList>
				)}
			</div>
		);
	}
}

function mapStateToProps(state) {
	let allElementsFromBackend = null;
	let availableCourses = [];
	let courseOptions = [];

	if (state.DiscoveryReducer.teacherElements) {
		allElementsFromBackend = state.DiscoveryReducer.teacherElements;
	}

	if (state.teacher.availableCourses) {
		availableCourses = state.teacher.availableCourses;

		for (var key in state.teacher.subscribedCourses) {
			let course = state.teacher.subscribedCourses[key];
			courseOptions.push({
				key: course.CourseID,
				label: course.Title,
				text: course.Title,
				value: course.CourseID
			});
		}

		for (var key in availableCourses) {
			let course = availableCourses[key];
			courseOptions.push({
				key: course.CourseID,
				label: course.Title,
				text: course.Title,
				value: course.CourseID
			});
		}
	}

	courseOptions.sort((a, b) => {
		if (a.text < b.text) {
			return -1;
		}
		else if (a.text > b.text) {
			return 1;
		}
		else {
			return 0;
		}
	});

	let courseName = "Fag for hele skolen";

	courseOptions.sort((a, b) => a.text == courseName ? -1 : b.text == courseName ? 1 : 0);

	return {
		teacherState: state.teacher,
		availableCourses: availableCourses,
		courseOptions: courseOptions,
		elementsFromServer: allElementsFromBackend
	};
}

export default connect(mapStateToProps, {
	getAllElementsTeacher,
	getAvailableCourses
})(MyElementsView);
