import { Student } from "./Student";

export class ClassAssignmentGroup {
    Id:any;                 // Can be string for new groups.
    Name:string;
    ClassId: string;
    Students:Student[];

    constructor(Id: any, Name: string, classId: string, Students: Student[]) {
        this.Id = Id;
        this.Name = Name;
        this.ClassId = classId;
        this.Students = Students;
    }
}