import * as React from "react";

import { withRouter, Link } from "react-router-dom";
import { connect } from "react-redux";
import { Field, reduxForm, change } from "redux-form";
import {
  getStudentTaskDeliver,
  saveLink,
} from "../../../actions/Action_Student";
import {
  Dropdown,
  TextField,
  autobind,
  PrimaryButton,
  ActionButton,
  DefaultButton,
  Label,
  Toggle,
  IconButton,
} from "office-ui-fabric-react";
import DatePicker from "react-datepicker";
import * as moment from "moment";
import ReactQuill from "react-quill"; // Typescript

import { IconNames } from "@uifabric/icons";
export interface ILinkFormProps {
  LinkCollection: any[];
  studenttask: any;
  handleSubmit: any;
  getStudentTaskDeliver(task: any);
  saveLink(link: any);
}
export interface ILinkFormState {}
let comment = "";
class LinkForm extends React.Component<ILinkFormProps, ILinkFormState> {
  constructor(props) {
    console.log("constructor", props);
    super(props);
    //comment = this.props.studenttask.GradingComment;
  }
  componentWillReceiveProps(props) {
    console.log("newprops", props);
    //this.setState({EvalText: props.GradingComment});
  }
  /****************************
          Event handlers
   ****************************/

  onFormSubmit(values) {
    console.log("submitted", values);
    if(values.Link)
    {
      let tmp = JSON.parse(JSON.stringify(this.props.studenttask));
      if (
        this.props.studenttask.Links &&
        this.props.studenttask.Links.length > 0
      ) {
        tmp.Links = `${this.props.studenttask.Links}|${values.Link}`;
      } else {
        tmp.Links = values.Link;
      }
      this.props.saveLink(tmp).then((updated) => {
        console.log("updated", updated);
        this.props.getStudentTaskDeliver(tmp);
      });
    }
   
  }

  handleChange(event) {
    const target = event.target;
    const value = target.value;
    const name = target.name;
    switch (name) {
      case "Grade":
        this.setState({ Grade: value });
        break;
      case "GradeModifier":
        this.setState({ GradeModifier: value });
        break;
      default:
        comment = value;
        break;
    }
  }

  handleChangeQuill(html) {
    comment = html;
  }
  onDeleteClick() {}

  /****************************   
          Helper methods
   ****************************/

  formatFormValueDatesToDateString(values) {
    return values;
  }

  /****************************
          Render methods
   ****************************/
  renderTextField(field) {
    const {
      meta: { touched, error },
    } = field;

    return <TextField errorMessage={touched ? error : ""} {...field.input} />;
  }
  render() {
    const { handleSubmit } = this.props;

    return (
      <form onSubmit={handleSubmit(this.onFormSubmit.bind(this))}>
    
        {/* <input
                           onChange={() => this.handleChange(event)}
                            className="ms-TextField-field"
                            type="text"
                            placeholder="Angi lenke"
                            id="linkinput"
                          /> */}
        
        <div className="ms-Grid-row mt-10">
          <div className="ms-Grid-col ms-sm12">
            <div className="ms-Grid-col ms-sm8" style={{paddingLeft:0, paddingRight:0}}>
            <Label style={{fontWeight: 'bold'}}>Legg til lenker</Label>
                <Field
                  name="Link"
                  label="" // Custom fields variables can be fetched in component (i.e. field.label)
                  required={true}
                  component={this.renderTextField}
                />
            </div>
            <div className="ms-Grid-col ms-sm4">
              <ActionButton
                    iconProps={{ iconName:"Add" }}
                    style={{marginTop: '23px'}}
                    onClick={handleSubmit(this.onFormSubmit.bind(this))}
                  >Legg til lenke
                  
              </ActionButton>
            </div>
               
          </div>
               
        </div>

        <ul id="addedlinks">
          {this.props.studenttask.LinksCollection &&
            this.props.studenttask.LinksCollection.map((link) => {
              return <li className="addedlink"><a target="_blank" href={link}>
              {link}
            </a></li>;
            })}
        </ul>
      </form>
    );
  }
}

function validate(values) {
  const errors: any = {};

  return errors;
}

const mapStateToProps = (state, ownProps) => {
  console.log("stateform", state);
  return {
    studenttask: state.StudentReducer.studenttask,
  };
};

export default reduxForm({
  form: "EvalForm",
  validate,
  enableReinitialize: true,
})(
  connect(mapStateToProps, {
    getStudentTaskDeliver,
    saveLink,
  })(withRouter(LinkForm))
);
