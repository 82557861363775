import * as React from 'react';
import * as _ from 'lodash';

import {connect} from 'react-redux';
import {Course} from "../../../models/Course";
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import MainContent from "../../view_containers/MainContent";
import SpinLoader from "../../loading/SpinLoader";
import StudentDragCard from "../students_and_courses/StudentDragCard";
import {CommandBar} from "office-ui-fabric-react";
import GroupHeader from "../students_and_courses/GroupHeader";
import {AssignmentGroup} from "../../../models/AssignmentGroup";

import {deleteGroup, getGroups, saveGroups, updateCourseStudents} from "../../../actions/Action_Teacher";
import {LoadingDialog} from "../../loading/LoadingDialog";

import {ToastContainer, toast, Slide} from "react-toastify";
import TeamMapper from '../../graph/TeamMapper';
import { ClassAssignmentGroup } from '../../../models/ClassAssignmentGroup';
import { SchoolClass } from '../../../models/SchoolClass';
import axios from 'axios';
import { ROOT_URL, SP_HOST_URL } from '../../../actions/constants';
import { Student } from '../../../models/Student';
import ClassAssignmentGroupHeader from './ClassAssignmentGroupHeader';

// a little function to help us with reordering the result
// const reorder = (list, startIndex, endIndex) => {
//     const result = Array.from(list);
//     const [removed] = result.splice(startIndex, 1);
//     result.splice(endIndex, 0, removed);

//     return result;
// };

const reorderStudents = (list: Student[], startIndex, endIndex): Student[] => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
};

/**
 * Moves an item from one list to another list.
 */
const moveStudent = (source: Student[], destination: Student[], droppableSource, droppableDestination) =>{
    const sourceClone = Array.from(source);
    const destClone = Array.from(destination);
    const [removed] = sourceClone.splice(droppableSource.index, 1);

    destClone.splice(droppableDestination.index, 0, removed);


    return [
        sourceClone,
        destClone
    ];
};

const getItemStyle = (isDragging, draggableStyle) => ({
    // some basic styles to make the messages look a bit nicer
    userSelect: 'none',

    // change background colour if dragging
    background: '#f0f0f0',
    boxShadow: isDragging ? '0 15px 35px rgba(50,50,93,.3), 0 5px 15px rgba(0,0,0,.2)': 'none',

    // styles we need to apply on draggables
    ...draggableStyle
});

const getListStyle = isDraggingOver => ({
    background: isDraggingOver ? 'lightblue' : '#f8f8f8',
});

const STUDENT_DROPPABLE_ID = -1;

export interface IAdminStudentsAndClassesDetailViewProps {
    subscribedClasses:{};
    match:any;

    getGroups(course:Course);
    saveGroups(groups:ClassAssignmentGroup[], callback:(data)=>void);
    deleteGroup(group:ClassAssignmentGroup, callback:(data)=>void);
    updateCourseStudents(course:Course);    // Method that updates local state according to changes.
}

export interface IAdminStudentsAndClassesDetailViewState {
    schoolClass: SchoolClass;
    allStudents: Student[];
    unassignedStudents: Student[];
    // assignmentGroups:{};    // Mutable values that can be altered in view
    initialGroups:ClassAssignmentGroup[];
    assignmentGroups: ClassAssignmentGroup[];

    awaitingResponse:boolean;
}


class AdminStudentsAndClassesDetailView extends React.Component<IAdminStudentsAndClassesDetailViewProps, IAdminStudentsAndClassesDetailViewState>{

    constructor(props){
        super(props);

        const {id} = this.props.match.params;
        console.log("ID:", id, this.props.subscribedClasses);
        let schoolClass: SchoolClass = this.props.subscribedClasses[id];
        // const unassignedStudents = new ClassAssignmentGroup(
        //     STUDENT_DROPPABLE_ID,
        //     schoolClass.ClassName,
        //     id,
        //     schoolClass.Students
        // );

        // let assignmentGroups = {};

        // if (this.props.subscribedClasses[id].Groups != null){
        //     assignmentGroups = this.getDroppablesForGroups();
        // }

        this.state = {
            awaitingResponse: false,
            schoolClass: schoolClass,
            unassignedStudents: schoolClass.Students ? schoolClass.Students : [],
            allStudents: [],
            assignmentGroups: [],
            // initialGroups: _.cloneDeep(assignmentGroups)
            initialGroups: []
        };

        this.onDragEnd = this.onDragEnd.bind(this);
        this.onCreateNewGroup = this.onCreateNewGroup.bind(this);
    }

    /****************************
            Lifecycle hooks
     ****************************/

    async componentDidMount() {
        // this.props.getGroups(this.state.schoolClass).then(()=>{
        //     this.setState({
        //         assignmentGroups: this.getDroppablesForGroups(),
        //         initialGroups: Object.assign({}, this.getDroppablesForGroups()),
        //     });
        // });
        const {id} = this.props.match.params;
        // let classAssignmentGroups = await this.getAndSetClassAssignmentGroups(id);
        let students = await this.getStudentsByClassId(id);
        let classAssignmentGroups = await this.getAndSetClassAssignmentGroups(id);
        let dropGroups = this.getDroppablesForGroups(classAssignmentGroups, students);

        this.setState({allStudents: students, assignmentGroups: dropGroups, initialGroups: _.cloneDeep(dropGroups)});        
    }

    /****************************
            Event handlers
     ****************************/

    onDragEnd(result){
        const { source, destination } = result;

        // dropped outside the list
        if (!destination) {
            return;
        }

        console.log("DRAGUNOV:", source, destination);
        // If a student it dropped in its previous group, we just rearrange it based on drop location
        if (source.droppableId === destination.droppableId) {
            console.log("SOURCE:", this.getGroupStudentsById(source.droppableId));
            const items = reorderStudents(
                this.getGroupStudentsById(source.droppableId),
                source.index,
                destination.index
            );

            const assignmentGroups = this.state.assignmentGroups;
            assignmentGroups.find(ag => ag.Id == source.droppableId).Students = items;

            this.setState({
                assignmentGroups: assignmentGroups
            });
        } 
        else {

            console.log("SOURCE:", this.getGroupStudentsById(source.droppableId));
            console.log("DEST:", this.getGroupStudentsById(destination.droppableId));
            console.log("MOVE:", source, destination);

            const result = moveStudent(
                this.getGroupStudentsById(source.droppableId),
                this.getGroupStudentsById(destination.droppableId),
                source,
                destination
            );

            const assignmentGroups = this.state.assignmentGroups;
            assignmentGroups.find(ag => ag.Id == source.droppableId).Students = result[0];
            assignmentGroups.find(ag => ag.Id == destination.droppableId).Students = result[1];

            this.setState({
                assignmentGroups: assignmentGroups
            });
        }
    };

    onRemoveStudentFromGroup(studentId:number, groupId:number){

        const result = moveStudent(
            this.getGroupById(groupId).Students,
            this.getGroupById(STUDENT_DROPPABLE_ID).Students,
            groupId,
            STUDENT_DROPPABLE_ID
        );

        const assignmentGroups = this.state.assignmentGroups;
        assignmentGroups.find(ag => ag.Id == groupId).Students = result[0];
        assignmentGroups.find(ag => ag.Id == STUDENT_DROPPABLE_ID).Students = result[1];

        this.setState({
            assignmentGroups: assignmentGroups
        });
    }

    onGroupNameChanged(groupId, event){

        const assignmentGroups = this.state.assignmentGroups;
        assignmentGroups.find(ag => ag.Id == groupId).Name = event.target.defaultValue;

        this.setState({
            assignmentGroups: assignmentGroups
        });
    }

    onDeleteAssignmentGroup(group: ClassAssignmentGroup){

        if (confirm('Du er iferd med å slette en tilretteleggingsgruppe. Vær obs på at oppgaver delt ut til denne gruppen ikke lenger vil være synlig for elevene i gruppen')) {

            let assignmentGroups = this.state.assignmentGroups;

            if (group.Students){
                this.getGroupFromListById(assignmentGroups, STUDENT_DROPPABLE_ID).Students = this.getGroupFromListById(assignmentGroups, STUDENT_DROPPABLE_ID).Students.concat(group.Students);
            }

            // If the deleted group has not been saved, we just remove it front-end
            if (isNaN(group.Id)) {
                assignmentGroups = assignmentGroups.filter(ag => ag.Id != group.Id);
                this.setState({
                    assignmentGroups: assignmentGroups
                });
            } else {

                this.props.deleteGroup(group, (response)=>{
                    assignmentGroups = assignmentGroups.filter(ag => ag.Id != group.Id);

                    const updatedCourse = this.state.schoolClass;
                    updatedCourse.Students = this.getGroupById(STUDENT_DROPPABLE_ID).Students;

                    toast.success('Gruppen har blitt slettet');
                    
                    this.setState({
                        schoolClass: updatedCourse,
                        assignmentGroups: assignmentGroups,
                        initialGroups: _.cloneDeep(assignmentGroups)
                    });
                });
            }
        }
    }

    onCreateNewGroup(addAllStudents:boolean){

        let assignmentGroups = this.state.assignmentGroups;

        const newAssignmentGroup = new ClassAssignmentGroup(
            this.getNewAssignmentGroupIndex(),
            "",
            this.state.schoolClass.ClassID,
            addAllStudents ? assignmentGroups.find(ag => ag.Id == STUDENT_DROPPABLE_ID).Students : []
        );

        assignmentGroups.push(newAssignmentGroup);

        if (addAllStudents){
            assignmentGroups.find(ag => ag.Id == STUDENT_DROPPABLE_ID).Students = [];
        }

        this.setState({
            assignmentGroups: assignmentGroups,
        });
    }

    async onSubmit(){

        const anyGroupsMissingNames : boolean = _.values(this.state.assignmentGroups).some(g => !g.Name);

        if (anyGroupsMissingNames){
            toast.info('Alle grupper må ha et navn.');
            return;
        }

        // We only submit groups that have either changed their name, students or are newly created.
        const requestData = this.getRequestData();

        if (!requestData.length){
            toast.info('Du har ikke gjort noen endringer for gruppene');
            return;
        }

        this.setState({awaitingResponse: true});

        this.props.saveGroups(requestData, response => response).then( async (res) =>{

            const updatedClass = this.state.schoolClass;
            updatedClass.Students = this.getGroupById(STUDENT_DROPPABLE_ID).Students;
            
            let classAssignmentGroups = await this.getAndSetClassAssignmentGroups(this.state.schoolClass.ClassID);
            let dropGroups = this.getDroppablesForGroups(classAssignmentGroups, this.state.allStudents);
        
            this.setState({
                schoolClass: updatedClass,
                assignmentGroups: dropGroups,
                initialGroups: _.cloneDeep(dropGroups),
                awaitingResponse: false,
            })
        })


    }

    /****************************
            Helper methods
     ****************************/

    getGroupById = (groupId: number): ClassAssignmentGroup => {
        return this.state.assignmentGroups.find(ag => ag.Id == groupId);
    }

    getGroupStudentsById = (groupId: number): Student[] => {
        return this.getGroupById(groupId).Students;
    }

    getStudentsByClassId = async (classId: string) => {
        const request = await axios.post(`${ROOT_URL}/GetStudents/${SP_HOST_URL}`, { ClassID: classId}, {
            headers: {
              Authorization: localStorage.getItem("id_token"),
            },
          });

        return request.data as Student[];
    }
    
    getGroupFromListById = (assignmentGroup: ClassAssignmentGroup[], groupId: number): ClassAssignmentGroup => {
        return assignmentGroup.find(ag => ag.Id == groupId);
    }

    hasGroupChanged = (group : ClassAssignmentGroup) : boolean =>{

        const originalGroup = this.getGroupFromListById(this.state.initialGroups, group.Id);

        if (!originalGroup){
            //return false;
            return true;
        }

        return !_.isEqual(group, originalGroup);
    };

    // getDroppablesForGroups(){
    //     const {id} = this.props.match.params;

    //     const studentGroup = new ClassAssignmentGroup(
    //         STUDENT_DROPPABLE_ID,
    //         this.props.subscribedClasses[id].Title,
    //         id,
    //         this.props.subscribedClasses[id].Students ? this.props.subscribedClasses[id].Students : []
    //     );

    //     const assignmentGroups: ClassAssignmentGroup[] = _.cloneDeep(this.state.initialGroups);
        
    //     // Assigns a dropGroup for course students not assigned to a group
    //     let ag = assignmentGroups.find(a => a.Id == STUDENT_DROPPABLE_ID);
    //     ag = studentGroup;

    //     // assignmentGroups[STUDENT_DROPPABLE_ID] = studentGroup;
    //     console.log(this.props.subscribedClasses);
    //     // Assigns a new dropGroup for each existing group
    //     this.state.assignmentGroups.forEach(group => {
    //         // Assigns copies of group from state, making it easier to compare changed on submit
    //         assignmentGroups[group.Id] = Object.assign({}, {...group, Students: group.Students || []});
    //     });

        
    //     let classAssignmentGroups: ClassAssignmentGroup[] = [];

    //     this.props.subscribedClasses[id].Groups.forEach(group => {
    //         // Assigns copies of group from state, making it easier to compare changed on submit
    //         assignmentGroups[group.Id] = Object.assign({}, {...group, Students: group.Students || []});
    //     });

    //     return assignmentGroups;
    // }

    getDroppablesForGroups = (classAssignmentGroups: ClassAssignmentGroup[], students: Student[]): ClassAssignmentGroup[] => {
        const {id} = this.props.match.params;


        let unassignedStudents: Student[] = students;


        let assignedStudents: Student[] = [];

        classAssignmentGroups.forEach(group => assignedStudents.push(...group.Students));

        unassignedStudents = unassignedStudents.filter(s => assignedStudents.find(assignedStudent => assignedStudent.Id == s.Id) == null);
        console.log("Unassigned:", unassignedStudents);

        const studentGroup = new ClassAssignmentGroup(
            STUDENT_DROPPABLE_ID,
            this.state.schoolClass.ClassName,
            id,
            unassignedStudents
        );

        const assignmentGroups: ClassAssignmentGroup[] = _.cloneDeep(this.state.initialGroups);
        
        // Assigns a dropGroup for course students not assigned to a group
        //let ag = assignmentGroups.find(a => a.Id == STUDENT_DROPPABLE_ID);
        //ag = studentGroup;

        assignmentGroups.push(studentGroup);

        // assignmentGroups[STUDENT_DROPPABLE_ID] = studentGroup;
        console.log(this.props.subscribedClasses);
        // Assigns a new dropGroup for each existing group
        // this.state.assignmentGroups.forEach(group => {
        //     // Assigns copies of group from state, making it easier to compare changed on submit
        //     assignmentGroups[group.Id] = Object.assign({}, {...group, Students: group.Students || []});
        // });

        
        //let classAssignmentGroups: ClassAssignmentGroup[] = [];

        // this.props.subscribedClasses[id].Groups.forEach(group => {
        //     // Assigns copies of group from state, making it easier to compare changed on submit
        //     assignmentGroups[group.Id] = Object.assign({}, {...group, Students: group.Students || []});
        // });

        return assignmentGroups;
    }

    getRequestData(): ClassAssignmentGroup[]{

        //const assignmentGroups = Object.assign({}, this.state.assignmentGroups);
        let assignmentGroups = this.state.assignmentGroups.filter(ag => ag.Id != STUDENT_DROPPABLE_ID);
        const initialGroups = this.state.initialGroups.filter(ag => ag.Id != STUDENT_DROPPABLE_ID);

        // Remove droppable assignment group for Course students, as it is irrelevant for request
        //delete assignmentGroups[STUDENT_DROPPABLE_ID];
        //delete initialGroups[STUDENT_DROPPABLE_ID];

        //const assignmentGroupList:ClassAssignmentGroup[] = _.values(assignmentGroups);

        const filteredAssignmentGroups:ClassAssignmentGroup[] = [];

        for(let i=0; i < assignmentGroups.length; i++){

            const assignmentGroup:any = assignmentGroups[i];
            const initialGroup = this.getGroupFromListById(initialGroups, assignmentGroup.Id);

            // If state doesn't contain a group for Id, we assume it's a new group
            if (!initialGroup) {
                assignmentGroups[i].Id = 0;
                filteredAssignmentGroups.push(assignmentGroups[i]);
                continue;
            }

            // We add the group if it's Name or length of Students changed
            if (assignmentGroup.Name != initialGroup.Name || assignmentGroup.Students.length != initialGroup.Students.length){
                filteredAssignmentGroups.push(assignmentGroups[i]);
                continue;
            }

            // Sort arrays and compares them - handles cases where arrays might have the same length, but containing different students
            if (!_.isEqual(
                    _.orderBy(assignmentGroup.Students, (s)=>{return s.Id}, ['desc']),
                    _.orderBy(initialGroup.Students,    (s)=>{return s.Id}, ['desc'])
            )){
                filteredAssignmentGroups.push(assignmentGroups[i]);
            }
        }

        return filteredAssignmentGroups;
    }

    getNewAssignmentGroupIndex():string{

        const count = _.values(this.state.assignmentGroups)
            .filter(group => isNaN(group.Id))
            .length;

        return 'new_'+count;
    }

    getClassAssignmentGroups = async (classId: string): Promise<ClassAssignmentGroup[]> => {
		const request = await axios.get(`${ROOT_URL}/GetClassAssignmentGroups/${classId}${SP_HOST_URL}`,{
			headers: {
				Authorization: localStorage.getItem('id_token')
			}
		});

		return request.data as ClassAssignmentGroup[];
	}

    getAndSetClassAssignmentGroups = async (classId: string): Promise<ClassAssignmentGroup[]>=> {
		let classAssignmentGroups = await this.getClassAssignmentGroups(classId);

		this.setState({assignmentGroups: classAssignmentGroups, initialGroups: _.cloneDeep(classAssignmentGroups)});
		return classAssignmentGroups;
	}

    /****************************
            Render methods
     ****************************/

    renderAssignmentGroup(group : ClassAssignmentGroup){
        const students = group.Students != null ? group.Students : [];

        // We do not render course students twice
        if (group.Id == STUDENT_DROPPABLE_ID){
            return null
        }

        const draggableListClasses = `draggable-list ${this.hasGroupChanged(group) ? 'changed':''}`;


        return(
            <div className="ms-Grid-col ms-sm12 ms-md6 ms-lg4 ms-xl3">
              
                <ClassAssignmentGroupHeader 
                    group={group}
                    title={group.Name}
                    onNameChanged={this.onGroupNameChanged.bind(this)}
                    onDeleteGroup={this.onDeleteAssignmentGroup.bind(this)}
                />
                <Droppable droppableId={group.Id}>
                    {(provided, snapshot) => (
                        <div className={draggableListClasses}
                             ref={provided.innerRef}
                             style={getListStyle(snapshot.isDraggingOver)}>
                            {students.map((student, index) => (
                                <Draggable
                                    key={student.Id}
                                    draggableId={student.Id}
                                    index={index}>
                                    {(provided, snapshot) => (
                                        <div className="draggable-list-item"
                                             ref={provided.innerRef}
                                             {...provided.draggableProps}
                                             {...provided.dragHandleProps}
                                             style={getItemStyle(
                                                 snapshot.isDragging,
                                                 provided.draggableProps.style
                                             )}>
                                            <StudentDragCard
                                                student={student}
                                                group={group}
                                                buttonClick={this.onRemoveStudentFromGroup.bind(this)}/>
                                        </div>
                                    )}
                                </Draggable>
                            ))}
                            {provided.placeholder}
                        </div>
                    )}
                </Droppable>
            </div>
        )
    }

    render(){

        const unassignedGroup = this.state.assignmentGroups.find(group => group.Id == STUDENT_DROPPABLE_ID);
        console.log("UNASSIGNED_GROUP:", unassignedGroup);
        return(
            <div className="ms-Grid main">
                <div className="ms-Grid-row">
                    <ToastContainer transition={Slide}/>

                    {this.state && this.state.awaitingResponse &&
                    <LoadingDialog title="Oppdaterer tilretteleggingsgrupper" description="Vennligst vent"/>
                    }
                    <div className="ms-Grid-col ms-md12">
                        <div>
                            <h4>{this.state.schoolClass.ClassName}</h4>
                            <p className="ms-font-l">Klikk på knappen "Ny gruppe" for å opprette en tilretteleggingsgruppe. Dra hver elev inn i tilretteleggingsgruppen du ønsker for eleven i deenne klassen. Skulle du ønske å endre, er det bare å dra den videre til annen gruppe. For å ta eleven helt ut av tilretteleggingsgruppen - trykk på pil tilbake</p>
                            <p className="ms-font-l"><strong>PS!</strong> Husk at å flytte en elev fra tilretteleggingsgruppe kan gjøre noe med elevens tilgang på allerede tildelte timeplaner for denne klassen.</p>
                           {/* <TeamMapper classID={this.state.schoolClass.CourseID}>

                            </TeamMapper> */}
                        </div>
                    </div>
                    <div className="ms-Grid-col ms-md12">
                        <MainContent>
                            {!Object.keys(this.state.assignmentGroups).length &&
                            <SpinLoader text="Henter tilretteleggingsgrupper"/>
                            }

                            {this.state.assignmentGroups && this.state.assignmentGroups.length > 0 &&
                            <DragDropContext onDragEnd={this.onDragEnd}>

                                <div className="ms-Grid-col ms-sm12">
                                    <GroupHeader title={`Elever i: ${this.state.schoolClass.ClassName}`}/>

                                </div>

                                <Droppable droppableId={STUDENT_DROPPABLE_ID} direction="horizontal">
                                    {(provided, snapshot) => (
                                        <div className="draggable-list"
                                             ref={provided.innerRef}
                                             style={getListStyle(snapshot.isDraggingOver)}>
                                            {/* {this.state.unassignedStudents && this.state.unassignedStudents.map((student, index) => ( */}
                                            {unassignedGroup && unassignedGroup.Students && unassignedGroup.Students.map((student, index) => (
                                                <Draggable
                                                    key={student.Id}
                                                    draggableId={student.Id}
                                                    index={index}>
                                                    {(provided, snapshot) => (
                                                        <div className="draggable-list-item ms-Grid-col ms-sm12 ms-md6 ms-lg4 ms-xl3"
                                                             ref={provided.innerRef}
                                                             {...provided.draggableProps}
                                                             {...provided.dragHandleProps}
                                                             style={getItemStyle(
                                                                 snapshot.isDragging,
                                                                 provided.draggableProps.style
                                                             )}>
                                                            <StudentDragCard
                                                                student={student}
                                                                group={null}
                                                                buttonClick={this.onRemoveStudentFromGroup.bind(this)}/>
                                                        </div>
                                                    )}
                                                </Draggable>
                                            ))}
                                            {provided.placeholder}
                                        </div>
                                    )}
                                </Droppable>

                                <div className="command-bar" style={{marginBottom: 20, maxWidth: '530px'}}>
                                    <CommandBar
                                        items={[
                                            {
                                                key: 'Ny gruppe',
                                                name: 'Ny Gruppe',
                                                iconProps: {
                                                    iconName: 'Group'
                                                },
                                                onClick: ()=> this.onCreateNewGroup(false)
                                            },
                                            {
                                                key: 'Ny gruppe for resten',
                                                name: 'Ny Gruppe for resten',
                                                iconProps: {
                                                    iconName: 'AddGroup'
                                                },
                                                onClick: ()=> this.onCreateNewGroup(true)
                                            },
                                        ]}
                                        farItems={[
                                            {
                                                key: 'Lagre',
                                                name: 'Lagre',
                                                iconProps: {
                                                    iconName: 'Save'
                                                },
                                                onClick: this.onSubmit.bind(this)
                                            },
                                        ]}
                                    />
                                </div>

                                {   // Renders a dropGroup for each existing group
                                    _.values(this.state.assignmentGroups)
                                        .sort((a,b) => a < b)
                                        .map((group:ClassAssignmentGroup) => (
                                            this.renderAssignmentGroup(group)
                                        )
                                )}
                            </DragDropContext>
                            }
                        </MainContent>
                    </div>
                </div>
            </div>
        )
    }
}

function mapStateToProps(state, ownProps) {
    return{
        subscribedClasses: state.teacher.subscribedSchoolClasses
    }
}

export default connect(mapStateToProps, {getGroups, saveGroups, deleteGroup, updateCourseStudents})(AdminStudentsAndClassesDetailView);