import * as React from "react";
import {
  getTaskElementExt,
  distributeTask,
  distributeTeamsTask,
} from "../../actions/Action_Ukeplan";
import { connectTaskToTeams } from "../../actions/Action_Innleveringer";
import {
  getAssignment,
  getClassMembers,
  updateAssignment,
  publishAssignment,
  createAssignment,
  createRubric,
  attachRubric,
} from "../../actions/Action_Graph";

import {
  dateStringToMoment,
  isDateStringBeforeToday,
} from "../../utils/DateUtils";
import { connect } from "react-redux";
import axios from "axios";
import HeaderView from "../view_containers/HeaderView";
import {
  ChoiceGroup,
  IChoiceGroupOption,
} from "office-ui-fabric-react/lib/ChoiceGroup";
import {
  DefaultButton,
  PrimaryButton,
  ActionButton,
} from "office-ui-fabric-react";
import SpinLoader from "../loading/SpinLoader";

import * as moment from "moment";
export const LOCALE = "nb";
export const DATE_FORMAT = "DD.MM.YYYY";
export const DATE_FORMAT_TIME = "DD.MM.YYYY HH:mm";


export interface ITaskDistributeViewProps {
  taskelement: any;
  getTaskElementExt(Id: number);
  distributeTask(data: any);
  distributeTeamsTask(data: any);
  getAssignment(classid: string, assignmentid: string);
  getClassMembers(classid: string);
  updateAssignment(classid: string, assignmentid: string, task: any);
  publishAssignment(classid: string, assignmentid: string);
  createAssignment(classid: string, task: any);
  createRubric(rubric: any);
  attachRubric(rubric: any, assignment: any);
  connectTaskToTeams(task: any);
  match: any;
  history: any;
  teamstask: any;
  classMembers: any;
  courseteam: string;
}
export interface ITaskDistributViewState {
  taskelement: any;
  updating: boolean;
  modus: string;
  btndisabled: boolean;
  distributedinteams:boolean;
}
class TaskDistributeView extends React.Component<
  ITaskDistributeViewProps,
  ITaskDistributViewState
> {
  constructor(props) {
    super(props);
    this.state = { taskelement: null, updating: false, modus: "1", btndisabled: false,distributedinteams:false };
  }
  componentWillReceiveProps() {
    this.setState({ updating: false });
  }
  componentWillMount() {
    const { id, mode } = this.props.match.params;
    this.props.getTaskElementExt(id).then(() => {
      //  console.log('gettingassignment');
      this.props.getClassMembers(this.props.courseteam).then(() => {
        console.log('gettingassignment',this.props.taskelement.TeamsTask);
        if (this.props.taskelement.TeamsTask) {
          this.props.getAssignment(
            this.props.courseteam,
            this.props.taskelement.TeamsTask.split('|')[1]
          );
        }
      });
    });
  }

  getStudentsAsText = (): string => {
    let stringToReturn: string = "";
    
    if(this.props.taskelement.Elever && this.props.taskelement.Elever != null) {
      stringToReturn = this.props.taskelement.Elever.split(";").join(", ");
    }

    return stringToReturn;    
  }

  distributeButtonDisabled = (): boolean => {
    if (this.state.updating) {
      return true;
    }
    if (this.props.taskelement && this.props.taskelement.TeamsTask) {
      return true;
    }
    if (this.state.distributedinteams) {
      return true;
    }

    return false;
  }

  public render() {
    if (!this.props.taskelement) {
      return <div>laster</div>;
    }
    console.log("courseteam", this.props.courseteam);
    return (
      <div className="ms-Grid container main" id="Week">
        <HeaderView title="Del ut innlevering" iconName="CompletedSolid" />
        <div className="ms-Grid-row" id="DistributeTask">
          <div className="table-responsive">
            <table className="table distributeTable">
              <tr>
                <td className="distributeTableTitle">
                  {this.props.taskelement.Course.Title}
                </td>
                <td>{this.props.taskelement.Course.Title}</td>
              </tr>
              <tr>
                <td className="distributeTableTitle">Tilretteleggingsgruppe</td>
                <td>{this.props.taskelement.AssignmentGroup ? this.props.taskelement.AssignmentGroup.Name : ""}</td>
              </tr>
              {this.props.taskelement.Elever && (
                <tr>
                  <td className="distributeTableTitle">Elever</td>
                  <td className="overflorwTable" style={{ maxWidth: "250px"}}>{this.getStudentsAsText()}</td>
                </tr>
              )}
              <tr>
                <td className="distributeTableTitle">Mål</td>
                <td>
                  <span className="remove-para-margin ql-editor" style={{ backgroundColor: "transparent" }}
                    dangerouslySetInnerHTML={{
                      __html: this.props.taskelement.Goal,
                    }}
                  ></span>
                </td>
              </tr>
              <tr>
                <td className="distributeTableTitle">Kompetansemål</td>
                <td>
                  <ul>
                    {this.props.taskelement.GrepCodeList &&
                      this.props.taskelement.GrepCodeList.map((grp) => {
                        return (
                          <li>
                            {grp.GrepKode} {grp.GrepTekst}
                          </li>
                        );
                      })}
                  </ul>
                </td>
              </tr>
              {
                this.props.taskelement.SkolonResources &&
                <tr>
                  <td className="distributeTableTitle"><img width='40px' src={require('../../images/SkolonIcon.svg')} /></td>
                  <td>
                    <ul>
                      {
                        this.props.taskelement.SkolonResources.map((item) => {
                          return (
                            <li>
                              <a href={item.url}>{item.name}</a>
                            </li>
                          )
                        })
                      }
                    </ul>
                  </td>
                </tr>
              }

              {/* <tr>
                <td className="distributeTableTitle">Evaluering</td>
                <td>
                  <ul>
                    {this.props.taskelement.EvalElements &&
                      this.props.taskelement.EvalElements.map(evalTekst => {
                        return <li>{evalTekst}</li>;
                      })}
                  </ul>
                </td>
              </tr> */}
              <tr>
                <td className="distributeTableTitle">Navn</td>
                <td>{this.props.taskelement.Name}</td>
              </tr>
              <tr>
                <td className="distributeTableTitle">Oppgavetekst</td>
                <td>
                  <span className="remove-para-margin ql-editor" style={{ backgroundColor: "transparent" }}
                    dangerouslySetInnerHTML={{
                      __html: this.props.taskelement.Text,
                    }}
                  ></span>
                </td>
              </tr>
              <tr>
                <td className="distributeTableTitle">Lenke</td>
                <td>{this.props.taskelement.Link}</td>
              </tr>
              {this.props.taskelement.Starttime && <tr>
                <td className="distributeTableTitle">Starttid</td>
                <td>{moment(this.props.taskelement.Starttime).locale(LOCALE).format(DATE_FORMAT_TIME)}</td>
              </tr>}
              <tr>
                <td className="distributeTableTitle">Frist</td>
                <td>{this.props.taskelement.Deadlinetekst}</td>
              </tr>
              <tr>
                <td className="distributeTableTitle">Uke</td>
                <td>{this.props.taskelement.Week}</td>
              </tr>
              <tr>
                <td className="distributeTableTitle">Plagiatkontroll</td>
                <td>{this.props.taskelement.PlagiatkontrollTekst}</td>
              </tr>
              <tr>
                <td className="distributeTableTitle">Flere Forsøk</td>
                <td>{this.props.taskelement.KeepOpen ? "Ja" : "Nei"}</td>
              </tr>
              <tr>
                <td className="distributeTableTitle">Filer</td>
                <td className="taskDocDistribute">
                  <ul>
                    {this.props.taskelement.TaskFiles &&
                      this.props.taskelement.TaskFiles.map((tFile) => {
                        return <li>{tFile.FileName}</li>;
                      })}
                  </ul>
                </td>
              </tr>
            </table>
          </div>
          <div className="panels">
            {this.props.taskelement.Students &&
              this.props.taskelement.Students.map((item) => {
                let assignedinteams = false;
                if (
                  this.props.classMembers &&
                  this.props.classMembers[item.LoginName] &&
                  this.props.classMembers[item.LoginName].hasassigned
                ) {
                  item.AssignedInTeams = true;
                }

                return (
                  <div className="panel panel-default">
                    <div className="panel-body">
                      <h3>{item.Name}</h3>
                      <div className="statusBokser">
                        <div className="statusInnlevering">
                          <div className="statusLabel">Status</div>
                          <i className="ms-Icon ms-Icon--CloudUpload fa-2x"></i>
                          {item.Hasreport && !this.state.updating && (
                            <div
                              id="@Html.DisplayFor(modelItem => item.LoginName)"
                              className="statusVerdi lys-gronn"
                            >
                              OK
                            </div>
                          )}
                          {!item.Hasreport &&
                            !this.state.updating &&
                            (!item.AssignedInTeams &&!this.state.distributedinteams) && (
                              <div
                                id="@Html.DisplayFor(modelItem => item.LoginName)"
                                className="statusVerdi rod"
                              >
                                Ikke utlevert
                              </div>
                            )}
                          {!item.Hasreport &&
                            !this.state.updating &&
                            (item.AssignedInTeams||this.state.distributedinteams) && (
                              <div
                                id="@Html.DisplayFor(modelItem => item.LoginName)"
                                className="statusVerdi rod"
                              >
                                Utlevert i Teams
                              </div>
                            )}
                          {this.state.updating && (
                            <div
                              id="@Html.DisplayFor(modelItem => item.LoginName)"
                              className="statusVerdi checking"
                            >
                              <SpinLoader text=" Deler ut" />
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
          </div>
        </div>
        <div className="ms-Grid-row" id="distributeTaskButtons">
          {/* Kan denne versjonen brukes? - Jannicke */}
          {this.props.taskelement && !this.props.courseteam && (
            <ChoiceGroup
              className="defaultChoiceGroup"
              defaultSelectedKey="1"
              options={[
                {
                  key: "1",
                  text: "Opprett fil",
                } as IChoiceGroupOption,
                {
                  key: "2",
                  text: "Ikke opprett fil",
                },
                {
                  key: "3",
                  text: "Annen vurdering",
                },
              ]}
              onChange={(e, val) => {
                this.setState({ modus: val.key });
              }}
            />
          )}
          {this.props.taskelement && this.props.courseteam && (
            <ChoiceGroup
              disabled={this.distributeButtonDisabled()}
              className="defaultChoiceGroup"
              defaultSelectedKey="1"
              options={[
                {
                  key: "1",
                  text: "Opprett fil",
                } as IChoiceGroupOption,
                {
                  key: "2",
                  text: "Ikke opprett fil",
                },
                {
                  key: "3",
                  text: "Annen vurdering",
                },
                {
                  key: "4",
                  text: "Del ut i Teams",
                },
              ]}
              onChange={(e, val) => {
                this.setState({ modus: val.key });
              }}
            />
          )}

          {this.props.courseteam && "Elev og lærer arbeider videre med denne oppgaven i Teams"}

          <div className="form-footer">
            <div className="form-footer-left ">
              <DefaultButton
                text="Avbryt"
                onClick={() => {
                  this.props.history.push("/ukeplan");
                }}
              />
              {this.props.taskelement && this.state.modus != "4" && (
                <PrimaryButton
                  disabled={this.distributeButtonDisabled()}
                  id="distributebutton"
                  onClick={() => {
                    let el = this.props.taskelement;
                    el.TaskDocument = this.state.modus;

                    this.props.distributeTask(el);
                    this.setState({ updating: true, btndisabled: true });
                  }}
                  text="Del ut"
                />
              )}
              {this.props.taskelement && this.state.modus == "4" && (
                <PrimaryButton
                  disabled={this.distributeButtonDisabled()}
                  id="distributebutton"
                  onClick={() => {
                    console.log(this.props.taskelement.Deadline);
                    console.log(this.props.taskelement.Deadlinetekst);
                    console.log(
                      dateStringToMoment(
                        this.props.taskelement.Deadlinetekst
                      ).toISOString()
                    );
                    let qual = [];
                    this.props.taskelement.GrepCodeList.forEach((element) => {
                      qual.push({
                        description: {
                          content: `${element.LPID}|${element.KMID}|${element.GrepTekst}`,
                          contentType: "text",
                        },
                        criteria: [
                          {
                            description: {
                              content: "Ikke vurdert",
                              contentType: "text",
                            },
                          },
                          {
                            description: {
                              content: "Eleven viser lav måloppnåelse",
                              contentType: "text",
                            },
                          },
                          {
                            description: {
                              content: "Eleven viser middels måloppnåelse",
                              contentType: "text",
                            },
                          },
                          {
                            description: {
                              content: "Eleven viser høy måloppnåelse",
                              contentType: "text",
                            },
                          },
                        ],
                      });
                    });
                    const educationRubric = {
                      displayName: "Kompetansemål",
                      description: {
                        content: "",
                        contentType: "text",
                      },
                      levels: [
                        {
                          displayName: "Ikke vurdert",
                          description: {
                            content: "",
                            contentType: "text",
                          },
                          grading: null,
                        },
                        {
                          displayName: "Lav",
                          description: {
                            content: "",
                            contentType: "text",
                          },
                          grading: null,
                        },
                        {
                          displayName: "Middels",
                          description: {
                            content: "",
                            contentType: "text",
                          },
                          grading: null,
                        },
                        {
                          displayName: "Høy",
                          description: {
                            content: "",
                            contentType: "text",
                          },
                          grading: null,
                        },
                      ],
                      qualities: qual,
                      grading: null,
                    };
                    let newteamstask = {
                      dueDateTime: dateStringToMoment(
                        this.props.taskelement.Deadlinetekst
                      ).toISOString(),
                      closeDateTime: dateStringToMoment(
                        this.props.taskelement.Deadlinetekst
                      ).toISOString(),
                      // "assignDateTime":dateStringToMoment(tmp.ShowFrom).toISOString(),
                      displayName: this.props.taskelement.Name,
                      instructions: {
                        contentType: "text",
                        content: this.props.taskelement.Text,
                      },
                      // grading: {
                      //   "@odata.type":
                      //     "#microsoft.education.assignments.api.educationAssignmentPointsGradeType",
                      //   maxPoints: 100,
                      // },
                      // assignTo: {
                      //   "@odata.type":
                      //     "#microsoft.education.assignments.api.educationAssignmentClassRecipient",
                      // },
                      status: "draft",
                      allowStudentsToAddResourcesToSubmission: true,
                    };
                    this.props
                      .createAssignment(this.props.courseteam, newteamstask)
                      .then((resp) => {
                        console.log("assignmentcreated", resp);
                        this.props.connectTaskToTeams({
                          Id: this.props.taskelement.Id,
                          TeamsTask: `${this.props.courseteam}|${resp.payload.id}`,
                        });
                        this.props
                          .createRubric(educationRubric)
                          .then((respr) => {
                            //   console.log("resp", resp);
                               console.log("respr", respr);
                            this.props
                              .attachRubric(respr.payload, resp.payload)
                              .then(() => {
                                let el = this.props.taskelement;
                                el.TaskDocument = this.state.modus;

                                let recipients = [];
                                this.props.taskelement.Students.forEach(
                                  (item) => {
                                    let translatedstudent = this.props
                                      .classMembers[item.LoginName];
                                    ////  console.log(item.LoginName,translatedstudent);
                                    if (translatedstudent) {
                                      recipients.push(translatedstudent.id);
                                    }
                                  }
                                );
                                let updatedteamstask = {
                                  assignTo: {
                                    "@odata.type":
                                      "#microsoft.graph.educationAssignmentIndividualRecipient",
                                    recipients: recipients,
                                  },
                                };
                                console.log('minisprint',updatedteamstask);
                                this.props
                                  .updateAssignment(
                                    this.props.courseteam,
                                    resp.payload.id,
                                    updatedteamstask
                                  )
                                  .then(() => {
                                    console.log('minisprint2',resp);
                                    this.props.publishAssignment(
                                      this.props.courseteam,
                                      resp.payload.id
                                    ).then(()=>{
                                      console.log('minisprint4','ferdig');
                                      this.setState({ updating: false,distributedinteams:true });
                                    })
                                  });
                                  console.log('minisprint3',resp);
                               // this.props.distributeTeamsTask(el);
                                this.setState({ updating: true });
                              });
                          });
                      });
                  }}
                  text="Del ut"
                />
              )}
            </div>
            <div className="form-footer-right">
              <ActionButton
                text="Kopier til ny gruppe"
                iconProps={{ iconName: "Copy" }}
                onClick={() => {
                  this.props.history.push(
                    `/task/${this.props.taskelement.Id}/c`
                  );
                }}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  // if (state.ukeplanelements.taskElement.Students) {
  //     this.setState({ taskelement: state.ukeplanelements.taskElement.Students });
  // }
  // console.log('state', state.GraphReducer.currentClassMembers);
  // console.log('state', state.GraphReducer.currentassignment);
  let assignedtocoll = {};
  if (
    state.GraphReducer.currentassignment &&
    state.GraphReducer.currentassignment.assignTo &&
    state.GraphReducer.currentassignment.assignTo.recipients
  ) {
    assignedtocoll = state.GraphReducer.currentassignment.assignTo.recipients.reduce(
      function (map, obj) {
        map[obj] = obj;
        return map;
      },
      {}
    );
  }
  //console.log('assignedtocoll', assignedtocoll);
  let membercoll = null;
  if (state.GraphReducer.currentClassMembers) {
    membercoll = state.GraphReducer.currentClassMembers.reduce(function (
      map,
      obj
    ) {
      if (assignedtocoll[obj.id]) {
        obj.hasassigned = true;
      }
      if (
        state.GraphReducer.currentassignment &&
        state.GraphReducer.currentassignment.status === "assigned" &&
        state.GraphReducer.currentassignment.assignTo["@odata.type"] ===
        "#microsoft.graph.educationAssignmentClassRecipient"
      ) {
        obj.hasassigned = true;
      }
      map[obj.userPrincipalName] = obj;
      return map;
    },
      {});
  }
  // console.log('membercoll', membercoll);
  if (state.ukeplanelements.taskElement) {
    console.log("taskElement", state.ukeplanelements.taskElement);
    console.log("teamscourses", state.teacher.teamscourses);
    console.log(
      "theteam",
      state.teacher.teamscourses[state.ukeplanelements.taskElement.CourseID]
    );
  }

  if (state.ukeplanelements.taskElement) {
    let ct =
      state.teacher.teamscourses[state.ukeplanelements.taskElement.CourseID];
    if (!ct && state.GraphReducer && state.GraphReducer.MappedTeams) {
      ct =
        state.GraphReducer.MappedTeams[
        state.ukeplanelements.taskElement.CourseID
        ];
    }
    return {
      taskelement: state.ukeplanelements.taskElement,
      teamstask: state.GraphReducer.currentassignment,
      classMembers: membercoll,
      courseteam:
        ct
    };
  } else {
    return {};
  }
};

export default connect(mapStateToProps, {
  getTaskElementExt,
  distributeTask,
  distributeTeamsTask,
  getAssignment,
  getClassMembers,
  updateAssignment,
  publishAssignment,
  createAssignment,
  createRubric,
  attachRubric,
  connectTaskToTeams
})(TaskDistributeView);
