import * as _ from 'lodash';
import {
    GET_CLASS_IUPS, GET_CLASSREPORT_IUPS, GET_COURSE_IUPS, GET_COURSEEPORT_IUPS, GET_COURSEEPORT_STUDENT, GET_CLASSREPORT_IUPS_DRAFT
} from "../actions/Action_Iup";

import { default as DateUtils } from "./../utils/DateUtils";

export default function (state = {}, action) {
   
    const dateUtils = new DateUtils();

    switch (action.type) {
        case GET_CLASS_IUPS:
            const iupselectors = action.payload.data;



            //return _.assign({}, portfolio);
            return { ...state, iupselectors: iupselectors };
        case GET_COURSE_IUPS:
            const iupcourseselectors = action.payload.data;



            return { ...state, iupcourseselectors: iupcourseselectors };
        case GET_CLASSREPORT_IUPS:
            const iupclassreport = action.payload.data;

            return { ...state, iupclassreport: iupclassreport };
        case GET_CLASSREPORT_IUPS_DRAFT:
            const iupclassreportdraft = action.payload.data;

            return { ...state, iupclassreportdraft: iupclassreportdraft };
        case GET_COURSEEPORT_IUPS:
            const iupcoursereport = action.payload.data;

            return { ...state, iupcoursereport: iupcoursereport,iupstudentcoursereport:null };
        case GET_COURSEEPORT_STUDENT:
            const iupstudentcoursereport = action.payload.data;

            return { ...state, iupstudentcoursereport: iupstudentcoursereport };
        default:
            // const alp = 'hu'
            // return { ...state, alp };
            return state;
    }
}