import * as _ from "lodash";
import {
  GET_STUDENT_PORTFOLIO,
  GET_ACTORS_PORTFOLIO,
  GET_COURSE_GRADEREPORT,
  GET_COURSE_PORTFOLIO,
  CHECK_STUDENT_PORTFOLIO,
  SET_PLANVERSJON,
  CHECK_COURSE_PORTFOLIO
} from "../actions/Action_Portfolio";

import { default as DateUtils } from "./../utils/DateUtils";

export default function (state = {}, action) {
  const dateUtils = new DateUtils();

  switch (action.type) {
    case GET_STUDENT_PORTFOLIO:
      const portfolio = action.payload.data;

      //return _.assign({}, portfolio);
      return { ...state, portfolio: portfolio };
    case GET_COURSE_PORTFOLIO:
      const portfoliocourse = action.payload.data;
      //return _.assign({}, portfolio);
      return { ...state, portfolio: portfoliocourse };
    case GET_COURSE_GRADEREPORT:
      const gradereport = action.payload.data;

      return { ...state, gradereport: gradereport };

    case GET_ACTORS_PORTFOLIO:
      const actors = action.payload.data;

      return _.assign({ ...state }, actors);
    // return { ...state, actors: actors };
    case CHECK_STUDENT_PORTFOLIO:
        const studentPortfolioTasks = action.payload.data;
        return {...state, studentPortfolioTasks: studentPortfolioTasks}
    case CHECK_COURSE_PORTFOLIO:
      const coursePortfolioTasks = action.payload.data;
      return {...state, studentPortfolioTasks: coursePortfolioTasks}
    case SET_PLANVERSJON:
      const planversjon = action.payload;
      

      return{...state, planversjon : planversjon}
    default:
      return state;
  }
}
