import { Dropdown, IDropdownOption, Label, Shimmer } from "office-ui-fabric-react"
import * as React from 'react';

interface IMultiSelectFieldProps {
    label: string;
    input: any;
    options?:any[]
    meta: {
        touched: boolean;
        error: string;
    },
    placeholder?:string;
    defaultValue?:any;
    required?:boolean;
    disabled?:boolean;
    children:any;
    attemptedToSave?: boolean;
    lockedDefaultOptions?: IDropdownOption[];
    onSelect?:(key:any)=>void;
    isLoading: boolean;
}

interface IMultiSelectFieldState {
    selectedValues: string;
}

class MultiSelectField extends React.Component<IMultiSelectFieldProps, IMultiSelectFieldState> {

    constructor(props) {
        super(props);

        this.state = {
            selectedValues: ""
        }
    }

    componentDidMount(): void {
        this.setState({selectedValues: this.props.input.value});
    }

    handleChange = (event: React.FormEvent<HTMLDivElement>, item: IDropdownOption): void => {
        let values = "";
        if (item) {
            let selectedKeys: any[] = this.state.selectedValues.split(';');

            if(item.selected) {
                if(this.state.selectedValues.length > 0) {
                    
                    selectedKeys.push(item.key)
                    
                    values = selectedKeys.join(';');
                }
                else {
                    values = item.key.toString();
                }
            }
            else {
                values = selectedKeys.filter(key => key !== item.key).join(';');
            }
            this.setState({selectedValues: values})
        }
    }

    getOptions = (): IDropdownOption[] => {
        let options: IDropdownOption[] = [];

        if (this.props.options) {
            options = this.props.options.map(o => o as IDropdownOption);

            if (this.props.lockedDefaultOptions) {
                this.props.lockedDefaultOptions.forEach(value => {
                    const index = options.findIndex(x => x.key == value.key);

                    if (index > -1) {
                        let newValue: IDropdownOption = { ...value, selected: true, disabled: true }
                        options[index] = newValue;
                    }
                })
            }
            
        }

        return options;
    }


    // Brukes for å vise bruker hvilke elever som er valgt
    // Viser hvilke elever som allerede er valgt fra tilretteleggingsgruppen
    getSelectedKeysForDisplay = (): string[] => {
        let keysToReturn: string[] = []
        if (this.props.lockedDefaultOptions) {
            this.props.lockedDefaultOptions.forEach(option => {
                keysToReturn.push(option.key.toString());
            })
        }
        if (this.state.selectedValues && this.state.selectedValues != "") {
            keysToReturn.push(...this.state.selectedValues.split(';'));
        }
        return keysToReturn;
    }

    public render() {
        return <div className="ms-Grid-row">
            <div className="z-form-group">
                <div className="ms-Grid-col ms-sm12 ms-md4">
                    <Label required={this.props.required || false} className="z-form-label ms-Label">
                        {this.props.label}
                    </Label>
                </div>
                <div className="ms-Grid-col ms-sm12 ms-md8">
                    {!this.props.isLoading ? <Dropdown
                        // {...input}
                        options={this.getOptions()}
                        multiSelect
                        selectedKeys={this.getSelectedKeysForDisplay()}
                        placeholder={this.props.placeholder}
                        defaultValue={this.props.defaultValue || null}
                        required={this.props.required || false}
                        disabled={this.props.disabled || false}
                        errorMessage={(this.props.meta.touched || (this.props.attemptedToSave)) ? this.props.meta.error : ''}
                        onChange={(event, item) => this.handleChange(event, item)} 
                        onDismiss={() => {
                            this.props.input.onChange(this.state.selectedValues);
                        }}
                        onBlur={() => {
                            this.props.input.onChange(this.state.selectedValues);
                        }}
                        /> : <Shimmer styles={{shimmerWrapper: {height: 30}}}/>
                    }    
                </div>
            </div>
        </div>
    }
};

export default MultiSelectField;