import * as React from "react";
import HeaderView from "../../view_containers/HeaderView";
import { connect } from "react-redux";
import {
  getStudentQuizAttempt,
  createStudentQuizAttempt,
} from "../../../actions/Action_Student";
import { IStudentQuizAttempt } from "../../../models/student/IStudentQuizAttempt";
import SpinLoader from "../../loading/SpinLoader";
import StudentQuizAttemptForm from "./StudentQuizAttemptForm";
import Countdown  from 'react-countdown';
import Timer from "./Timer";
import moment = require("moment");
interface IStudentQuizAttemptViewProps {
  quizAttempt: IStudentQuizAttempt | undefined;
  getStudentQuizAttempt: (attemptId: number) => any;
  createStudentQuizAttempt(quiz: any);
  match: any;
  history:any;
}

interface IStudentQuizAttemptViewState {
  awaitingResponse: boolean;
}

class StudentQuizAttemptView extends React.Component<
  IStudentQuizAttemptViewProps,
  IStudentQuizAttemptViewState
> {
  constructor(props: IStudentQuizAttemptViewProps) {
    super(props);

    this.state = {
      awaitingResponse: false,
    };
  }

  fetchQuizAttempt = () => {
    const { id } = this.props.match.params;

    this.setState({ awaitingResponse: true });
    if (isNaN(id)) {
      console.log("erher");
      const qid = id.toString().split("-")[1];
      this.props.createStudentQuizAttempt({ Id: qid }).then((qa) => {
        console.log("opprettet", qa);
        this.props
          .getStudentQuizAttempt(qa.payload.data.Id)
          .then((response) => {
            console.log(response);
            this.setState({ awaitingResponse: false });
          })
          .catch((error) => {
            console.error(error);
            this.setState({ awaitingResponse: false });
          });
      });
    } else {
      this.props
        .getStudentQuizAttempt(id)
        .then((response) => {
          console.log(response);
          this.setState({ awaitingResponse: false });
        })
        .catch((error) => {
          console.error(error);
          this.setState({ awaitingResponse: false });
        });
    }
  };

  componentDidMount() {
    this.fetchQuizAttempt();
  }

  renderHeader() {
    const { quizAttempt } = this.props;

    if (!quizAttempt) {
      return null;
    }

    return (
      <HeaderView
        title={quizAttempt.Quiz.Title}
        description=""
        iconName="PageListSolid"
      >
  
        </HeaderView>
    );
  }

  render() {
    const { quizAttempt } = this.props;
    console.log("theattempt", quizAttempt);
    if (quizAttempt) {
      quizAttempt.Quiz.Questions.forEach((element) => {
        console.log("question", element);
        quizAttempt.Answers.forEach((answer) => {
          if (answer.QuestionID == element.Id) {
            if (element.QType == "1") {
              element.Options.forEach((opt) => {
                if (opt.QID == answer.Answer) {
                  opt.Selected = true;
                }
              });
            }
            if (element.QType == "2") {
              console.log("match1", answer);
              console.log("match2", element);
              const answerkey = answer.Answer.split(";");
              let dict = {};
              answerkey.forEach(k => {
                dict[k] = true;
              });
              element.Options.forEach((opt) => {
                if (dict[opt.QID]) {
                  opt.Selected = true;
                }
              });
            }
            if (element.QType == "4") {
              element.QuizAttemptAnswer = answer;
            }
          }
        });
      });
    }
    console.log("theattempt2", quizAttempt);
    return (
      <div className="main">
        {this.renderHeader()}
        <div className="ms-Grid">
          <SpinLoader
            text="Laster spørsmål"
            hide={!this.state.awaitingResponse}
          />
          {quizAttempt && (
            <StudentQuizAttemptForm history={this.props.history} initialValues={quizAttempt} />
          )}
        </div>
      </div>
    );
  }
}

const mapAnswersToQuizAttemptQuestions = (
  quizAttempt: IStudentQuizAttempt | undefined
): IStudentQuizAttempt | undefined => {
  if (!quizAttempt) {
    return undefined;
  }

  const { Answers } = quizAttempt;

  if (!Answers.length) {
    return quizAttempt;
  }

  quizAttempt.Quiz.Questions.map((question) => {
    const answerForQuestion = Answers.find(
      (a) => Number(a.QuestionID) === question.Id
    );

    if (answerForQuestion) {
      console.log("answerForQuestion", answerForQuestion);

      const updatedQuestion = {
        ...question,
        QuizAttemptAnswer: answerForQuestion.Answer,
      };

      console.log("updatedQuestion", updatedQuestion);

      return updatedQuestion;
    }

    return question;
  });

  return quizAttempt;
};

const mapStateToProps = (state, ownProps) => {
  const id = parseInt(ownProps.match.params.id);

  let quizAttempt = state.StudentReducer.student.quizAttempts
    ? state.StudentReducer.student.quizAttempts.find((qa) => qa.Id === id)
    : undefined;

  return {
    quizAttempt: quizAttempt, // mapAnswersToQuizAttemptQuestions(quizAttempt)
  };
};

export default connect(mapStateToProps, {
  getStudentQuizAttempt,
  createStudentQuizAttempt,
})(StudentQuizAttemptView);
