import * as React from 'react';
import * as moment from 'moment';
import * as _ from 'lodash';
import { connect } from 'react-redux';
moment.locale('nb');
import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from 'react-html-parser';
import Taskelement from './TaskElementView';
import TaskelementView from './TaskElementView';
import NewsElementView from './NewsElementView';
import Masonry from 'react-masonry-component';
import PlanElementView from "./PlanElementView";
export interface ICourseDayViewViewProps {
    teacherassignment: any;
    history:any;
}
export interface ICourseDayViewViewState {

}

class CourseDayView extends React.Component<ICourseDayViewViewProps, ICourseDayViewViewState>{
    constructor(props) {
        super(props);
    }
    render() {
        return (
            <div className="item courseitem">
                <div className="panel panel-default" id={this.props.teacherassignment.Course.CourseCategory}>
                    <div className="panel-heading">
                        <h2>{this.props.teacherassignment.Course.Title}</h2>


                    </div>
                    <div className="panel-body">
                        <ul className="list-group">
                            {this.props.teacherassignment.News.map((news) => {
                                return (
                                    <NewsElementView newselement={news} history={this.props.history}/>
                                        
                                )
                            })
                            }
                            {this.props.teacherassignment.TextElements.map((text) => {
                                return (

                                    <PlanElementView planelement={text} />

                                )
                            }
                            )}
                            {this.props.teacherassignment.Tasks.map((task) => {
                                return (
                                    <TaskelementView taskelement={task} />
                                )
                            })}


                        </ul>
                    </div>
                </div>

            </div>
          
        )
    }

}
function mapStateToProps(state) {

    return {}
}
export default connect(mapStateToProps, {})(CourseDayView);