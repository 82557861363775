import * as React from "react";
import {IStudentQuiz} from "../../../models/student/IStudentQuiz";
import './StudentQuiz.scss';
import { Link } from "react-router-dom";
import { ActionButton } from "office-ui-fabric-react";
import { connect } from "react-redux";
import * as moment from "moment";
import {
    createStudentQuizAttempt,
  } from "../../../actions/Action_Student";
import { render } from "react-dom";
interface IStudentQuizTileProps {
    quiz: IStudentQuiz;
    createStudentQuizAttempt(quiz: any);
    history:any;
}

//const StudentQuizTile = ({quiz}:IStudentQuizTileProps) =>{
    class StudentQuizTile extends React.Component<
    IStudentQuizTileProps,
    {}
  > 
  {
    render()
    {
        let quiz = this.props.quiz
        let now = new Date();

       
        // if(quiz.ValidFrom>moment(now))
        // {
        //     console.log('validFrom',quiz.ValidFrom);
        //     console.log(moment(now));
        //     console.log(moment(now)>quiz.ValidFrom);
        //     return(<></>)
        // }
        return(
            <div className="student-quiz-tile-container ms-Grid-col">
                <div className={`student-quiz-tile ${quiz.Course.CourseCategory}`}>
                    <div className="student-quiz-tile-header">
                        <h4>{quiz.Title}</h4>
                    </div>
                    <div className="student-quiz-tile-body">
                        <div className="student-quiz-tile-body-row vertical-align ms-Grid-row">
                            <i className="ms-Icon ms-Icon--ReadingMode" title="Fag" />
                            <div>
                                <strong>Fag</strong>
                                <div>{quiz.CourseName}</div>
                            </div>
                        </div>
                        <div className="student-quiz-tile-body-row vertical-align ms-Grid-row">
                            <i className="ms-Icon ms-Icon--Clock" title="Varighet" />
                            <div>
                                <strong>Varighet</strong>
                                <div>{quiz.Duration} minutter</div>
                            </div>
                        </div>
                        {quiz.QuizAttempt && quiz.Timeleft>0  &&
                        <div className="student-quiz-link">
                            <Link to={`/quizstudent/attempt/${quiz.QuizAttempt.Id}`}>Åpne Prøve</Link>
                        </div>
                        }
                          {(quiz.ShowFasit && quiz.QuizAttempt && !quiz.QuizAttempt.ShowGrading && quiz.Timeleft < 0  && moment().isSameOrAfter(moment(quiz.ValidTo)) ) &&
                        <div className="student-quiz-link">
                              <Link to={`/quizstudent/grade/${quiz.QuizAttempt.Id}`}>Se Fasit</Link>
                        </div>
                        }
                         {!quiz.QuizAttempt && moment() > quiz.ValidFrom &&  moment() < quiz.ValidTo &&
                        <div className="student-quiz-link">
                           {/* <Link to={`/quizstudent/attempt/q-${quiz.Id}`}>Start Prøve</Link> */}
                           <ActionButton onClick={()=>{
                              this.props.createStudentQuizAttempt(quiz).then((qa)=>{
                                  this.props.history.push(`/quizstudent/attempt/${qa.payload.data.Id}`);
                              })
                           }}>
                               Start prøve
                           </ActionButton>
                        </div>
                        }
                         {quiz.QuizAttempt && quiz.QuizAttempt.ShowGrading &&
                        <div className="student-quiz-link">
                            <Link to={`/quizstudent/grade/${quiz.QuizAttempt.Id}`}>Se retting</Link>
                        </div>
                        }
                    </div>
                    <div className="student-quiz-tile-footer">
                        <div>
                            <strong>Gyldig fra:</strong>
                            <div>{quiz.ValidFromText}</div>
                        </div>
                        <div>
                            <strong>Gyldig til:</strong>
                            <div>{quiz.ValidToText}</div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
      
  }
    /*TODO: lag funksjonalitet for å opprette et attempt hvis det ikke finnes fra før*/
  
//};
const mapStateToProps = (state, ownProps) => {
   
  
    return {
    };
  };
  
  export default connect(mapStateToProps, {
    createStudentQuizAttempt,
  })(StudentQuizTile);
//export default StudentQuizTile;