import * as React from "react";
import { connect } from "react-redux";
import {
  getStudentTaskDeliver,
  addDeliveryFile,
  submitTask,
  checkStatusTask,
  initStatusTask,
  removeDeliveryFile,
  getComments,
} from "../../../actions/Action_Student";
import "../../QuillStyles.scss";
import Select from "react-select";
import "../../../../../src/app/formelements.scss";
import OneDrivePicker from "../../shared/OneDrivePicker";
import ProgressList from "./ProgressList";
import ReactTooltip from 'react-tooltip';
import {
  ProgressIndicator,
  ActionButton,
  Label,
  PrimaryButton,
  Icon,
  Spinner,
  SpinnerSize,
} from "office-ui-fabric-react";
import LinkForm from "./LinkForm";
// import {isDateStringBeforeToday} from '../../../utils/DateUtils';
import * as moment from 'moment';
import EvaluationFiles from "../../innleveringer/EvaluationFiles";
import { ITaskEvaluationFileInfo } from "../../../models/interfaces/ITaskEvaluationFile";
import axios from "axios";
import { ROOT_URL, SP_HOST_URL } from "../../../actions/constants";
export interface IStudentTaskViewProps {
  events: any;
  schoolClasses: any;
  teacherstate: any;
  tasks: any;
  getStudentTaskDeliver(task: any);
  addDeliveryFile(file: any);
  submitTask(task: any);
  checkStatusTask(files: any[]);
  initStatusTask(files: any[]);
  removeDeliveryFile(file: any);
  getComments(st: any);
  studenttask: any;
  history: any;
  match: any;
  activefileurl: any;
  activefile: any;
}
export interface IStudentTaskViewState {
  showevaledit: boolean;
  showevaltext: boolean;
  studenttask: any;
  showeval: boolean;
  showdeadline: boolean;
  activefile: any;
  selectedfiles: any[];
  downloading: boolean;
  comments: any[];
  activefileurl: any;
  delivering: boolean;
  fileBeingDeleted?: number;


  // Used for EvaluationFiles component because React doesnt work
  isLoadingFiles: boolean;
	filesToUpload: any[];
	evaluationFiles: ITaskEvaluationFileInfo[];
	filesLoading: string[];
  errorFetchingFiles: boolean;
}

class StudentTaskView extends React.Component<
  IStudentTaskViewProps,
  IStudentTaskViewState
> {
  componentDidMount() {
    const { id } = this.props.match.params;
    this.props.getStudentTaskDeliver({ Id: id }).then((ret) => {
      console.log("progress", ret);
      let studenttask = ret.payload.data;
      studenttask.DeliveryFiles.forEach((element) => {
        let veropt = [];
        if (element.VersionFiles) {
          element.VersionFiles.forEach((element) => {
            veropt.push({ value: element.WopiUrl, label: element.Name });
          });
        }
        
        element.opts = veropt;
      });
      let afurl = "";
      let af = undefined;
      if (studenttask.DeliveryFiles[0]) {
        afurl = studenttask.DeliveryFiles[0].WopiUrl;
        af = studenttask.DeliveryFiles[0];
      }
      
      console.log("done loading");
      this.setState({
        downloading: true,
        activefile: af,
        activefileurl: afurl,
        studenttask: studenttask,
        selectedfiles: studenttask.DeliveryFiles,
      });
      this.props.getComments(ret.payload.data).then((cmts) => {
        this.setState({ comments: cmts.payload.data });
        console.log("comments", cmts.payload.data);
      });
    });
  }
  constructor(props) {
    super(props);
    
    this.state = {
      showevaledit: false,
      showevaltext: true,
      studenttask: {},
      showdeadline: false,
      showeval: true,
      activefile: this.props.activefile,
      selectedfiles: [],
      downloading: false,
      comments: [],
      activefileurl: "",
      delivering: false,
      fileBeingDeleted: null,

      // EVALUATION FILES
      isLoadingFiles: false,
      filesToUpload: [],
      evaluationFiles: [],
      filesLoading: [],
      errorFetchingFiles: false
    };
  }

  componentWillReceiveProps(props) {
    console.log("newprops", props);
    // if (props.studenttask && props.studenttask.Id) {
    //   this.setState({
    //     studenttask: props.studenttask,
    //     selectedfiles: props.studenttask.DeliveryFiles,
    //   });
    //   props.studenttask.DeliveryFiles.forEach((element) => {
    //     let chk = [];
    //     if (element.DeliveryStatus == 5) {
    //       chk.push(element);
    //     }
    //     if (chk.length > 0) {
    //       this.props.checkStatusTask(chk);
    //     }
    //   });
    // }
  }

  /// EVALUATION FILES ///
  fetchEvaluationFiles = async (task) => {
    try {
        this.setState({isLoadingFiles: true, errorFetchingFiles: false});
        const res = await axios.get(`${ROOT_URL}/GetTaskEvaluationFiles${SP_HOST_URL}&taskId=${task.WsTaskID}&studentLoginName=${encodeURIComponent(task.LoginName)}`, {
            headers: {
                Authorization: localStorage.getItem('id_token')
            }
        })
        this.setState({evaluationFiles: res.data as ITaskEvaluationFileInfo[], isLoadingFiles: false});
    }
    catch (error) {
        this.setState({errorFetchingFiles: true, isLoadingFiles: false});
    }
}

  wasDeliveredOnTime = () => {
    try {
      
      if (this.props.studenttask.DateDelivered) {
        if (moment(this.props.studenttask.Deadline).isAfter(moment(this.props.studenttask.DateDelivered))) {
          return true;
        }
        else {
          return false;
        }
      }
      return true;
    }
    catch (e) {
      return true;
    }
  }


  hasTaskStarted = () => {
    return !this.props.studenttask.WsTask.Starttime || (this.props.studenttask.WsTask.Starttime && moment(this.props.studenttask.WsTask.Starttime).valueOf() <= moment().valueOf());
  }

  fileIsBeingDeleted = (id: number): boolean => {
    if (this.state.fileBeingDeleted && this.state.fileBeingDeleted === id) {
      return true;
    }
    return false;
  }

  renderAddedByTeacher = (file: any) => {
    const tooltipId: string = `addedByTeacherIcon-${file.Id}`;

    return <span style={{marginLeft: "0.5em"}}>
      <Icon data-tip data-for={tooltipId} iconName="UserOptional" className="fileAddedByTeacherIcon" />
      <ReactTooltip id={tooltipId}>
        Denne filen har blitt lagt til av lærer
      </ReactTooltip>
    </span>
  }

  renderTaskNotStarted = () => {
    return <div className="ms-Grid-row">
      <div className="ms-fontSize-xl">
        Innleveringen har ikke startet enda. 
      </div>
      <br/>
      <div className="ms-fontSize-xl">
        Åpner opp for svar {moment(this.props.studenttask.WsTask.Starttime).format("DD.MM.YYYY HH:mm")} 
      </div>
    </div>
  }

  renderDeleteDeliverFileButton = (item: any, loading: boolean) => {
    return (!loading) ? <ActionButton
      iconProps={{ iconName: "Delete" }}
      style={{height: '75%'}}
      onClick={async () => {
        
        // this.props
        //   .removeDeliveryFile(item)
        //   .then((resp) => {
        //     console.log(resp);
        //     this.props
        //       .getStudentTaskDeliver(
        //         this.state.studenttask
        //       )
        //       .then((ret) => {
        //         console.log("fjernet fil");
        //         let studenttask = ret.payload.data;
        //         let afurl = "";
        //         let af = undefined;
        //         if (studenttask.DeliveryFiles[0]) {
        //           afurl = studenttask.DeliveryFiles[0].WopiUrl;
        //           af = studenttask.DeliveryFiles[0];
        //         }
        //         this.setState({
        //           studenttask: studenttask,
        //           selectedfiles: studenttask.DeliveryFiles,
        //           activefile: af,
        //           activefileurl: afurl,
        //         });
        //       });
        //   });
        if (!this.state.fileBeingDeleted) {
          try {
            this.setState({fileBeingDeleted: item.Id});
            const resp = await this.props.removeDeliveryFile(item);
  
            // console.log(resp);
            const ret = await this.props.getStudentTaskDeliver(this.state.studenttask);
            console.log(ret);
            let studenttask = ret.payload.data;
            let afurl = "";
            let af = undefined;
            if (studenttask.DeliveryFiles[0]) {
              afurl = studenttask.DeliveryFiles[0].WopiUrl;
              af = studenttask.DeliveryFiles[0];
            }
  
            this.setState({
              studenttask: studenttask,
              selectedfiles: studenttask.DeliveryFiles,
              activefile: af,
              activefileurl: afurl,
            });
              
          }
          catch (error) {
  
          }
          finally {
            this.setState({fileBeingDeleted: null});
          }
        }
      }}
    >
      Slett fil
    </ActionButton> : 
    <Spinner style={{marginLeft: "1.5em"}}
      size={SpinnerSize.medium}
    ></Spinner>    
  }

  render() {
    const { id } = this.props.match.params;
    if (this.props.activefile && !this.state.activefile) {
      this.setState({ activefile: this.props.activefile });
    }
    if (!this.props.studenttask || !this.props.studenttask.Id) {
      return <div>laster</div>;
    }
    if (
      this.props.studenttask &&
      this.props.studenttask.Id &&
      id != this.props.studenttask.Id
    ) {
      return <div>laster ny innlevering</div>;
    }
    if (!this.state.downloading) {
      return <div>laster</div>;
    }
    if (this.state.studenttask) {
      let emptylist = JSON.parse(
        JSON.stringify(this.props.studenttask.DeliveryFiles)
      );
      emptylist.forEach((element) => {
        element.UrkundOptOutMessage = null;
      });
      let dstatus = "";
      switch (this.props.studenttask.DeliveryStatus) {
        case 1:
          dstatus = "Utlevert";
          break;
        case 0:
          dstatus = "Levert";
          break;
        case 2:
          dstatus = "Rettet";
          break;
        default:
          break;
      }

      if (this.props.studenttask.DeliveryStatus == 1 && !this.hasTaskStarted()) {
        dstatus = "Låst"
      }

      return (
        <div className="ms-Grid">
          <div className="main">
            <div className="ms-Grid-row">
              <div className="ms-Grid-col ms-lg3">
                <div className="header">
                  <div className="ms-Grid-row">
                    <div className="ms-Grid-col">
                      <h1 className="ms-fontColor-orangeLight">
                        {" "}
                        <i className="ms-Icon ms-Icon--CompletedSolid"></i>{" "}
                        Innlevering
                      </h1>
                      <h2 className="ms-fontColor-orangeLight zSubTitle ms-font-l">
                        <b>Oppgave:</b> {this.props.studenttask.WsTask.Name}
                      </h2>
                      {this.props.studenttask.WsTask.Starttime &&
                        <h2 className="ms-fontColor-orangeLight zSubTitle ms-font-l">
                            <b>Starttidspunkt:</b>{" "}
                            {moment(this.props.studenttask.WsTask.Starttime).format("DD.MM.YYYY HH:mm")}
                        </h2>
                      }
                      <h2 className="ms-fontColor-orangeLight zSubTitle ms-font-l">
                        <b>Frist:</b>{" "}
                        {this.props.studenttask.WsTask.Deadlinetekst}.{" "}
                      </h2>
                      {this.state.studenttask.WsTask && this.state.studenttask.WsTask.DeliverAfterDeadline && <h2 className="ms-fontColor-orangeLight zSubTitle ms-font-l">
                        Levering etter fristen
                      </h2>}
                      {this.state.studenttask.WsTask && this.state.studenttask.WsTask.KeepOpen && <h2 className="ms-fontColor-orangeLight zSubTitle ms-font-l">
                        Flere forsøk
                      </h2>}
                    </div>
                  </div>
                </div>
              </div>
              <div className="ms-Grid-col ms-lg9">
                <div className="ms-Grid-col ms-sm12 ms-md6 text-left ms-fontColor-black studentTaskTopCol">
                  {
                    this.props.studenttask.WsTask.Text && this.props.studenttask.WsTask.Text.length>0 &&
                    <h4 className=" ms-fontColor-black">Oppgavetekst</h4>
                  }
                 
                  <p>
                    <span className="remove-para-margin"
                      dangerouslySetInnerHTML={{
                        __html: this.props.studenttask.WsTask.Text,
                      }}
                    ></span>
                    {this.props.studenttask.WsTask.Link &&
                      this.props.studenttask.WsTask.Link.length > 0 && (
                        <a href={this.props.studenttask.WsTask.Link}>
                          {this.props.studenttask.WsTask.Link}
                        </a>
                      )}
                  </p>
                  <p>
                    <ul>
                      {this.state.studenttask.WsTask.TaskFiles &&
                        this.state.studenttask.WsTask.TaskFiles.map((taskF) => {
                          return (
                            <li>
                              <a href={taskF.FileUrl} target="_blank">
                                {taskF.FileName}
                              </a>
                            </li>
                          );
                        })}
                    </ul>
                  </p>
                </div>
                <div className="ms-Grid-col ms-sm12 ms-md6 ms-fontColor-black studentTaskTopCol">
                  {
                    this.state.studenttask.WsTask.GrepCodeList && this.state.studenttask.WsTask.GrepCodeList.length >0 &&
                    <h4 className=" ms-fontColor-black">Kompetansemål</h4>
                  }
                  
                  <p>
                    <ul className="grep-list">
                      {this.state.studenttask.WsTask.GrepCodeList.map((grp) => {
                        return (
                          <li style={{cursor: 'pointer'}}>
                            <a href={grp.DetailUrl} target="_blank">
                              {grp.GrepTekst}
                            </a>
                          </li>
                        );
                      })}
                    </ul>
                  </p>
                </div>
              </div>
            </div>
            <div className="ms-Grid-row">
              <div className="ms-Grid-col ms-sm12">
                <div className="ms-Grid-row ms-bgColor-neutralLight topTool">
       
                  <div>
                    <div className="ms-font-l" style={{textAlign: 'right', padding: 5, marginRight: 60}}>
                    {
                      dstatus && dstatus.length>0 &&
                      <span style={{marginRight: 10}} className="ms-fontWeight-semibold"> Status:</span>
                    }
                     
                       {(dstatus === "Rettet" && !this.props.studenttask.ShowEval) ? "Levert" : dstatus} 

                       {!this.wasDeliveredOnTime() && <span style={{textAlign: "center"}}>
                        <Icon data-tip data-for='lateDelivery' iconName="Clock" style={{fontSize: 16 , marginLeft: 5, color: "red", cursor: "default"}}/>
                        <ReactTooltip id="lateDelivery">
                          <p style={{fontSize: 15}}>Denne besvarelsen ble levert etter fristen</p>
                          <p>{moment(this.props.studenttask.DateDelivered).format("HH:mm DD.MM.yyyy")}</p>
                        </ReactTooltip>
                      </span>}

                    </div>
                  </div>
                  <div className="ms-Grid-row topToolSub">
                    <div className="ms-Grid-col ms-sm10">
                        {this.props.studenttask.ShowEval &&
                          this.state.studenttask.DeliveryStatus !== 1 &&
                        <div id="gradedBlock">
                          <div className="ms-Grid-col ms-sm6">
                            <div className="ms-font-m ms-fontWeight-semibold">
                              Vurdering fra lærer
                            </div>
                            <span className="remove-para-margin ql-editor" style={{backgroundColor: "transparent"}}
                              dangerouslySetInnerHTML={{
                                __html: this.props.studenttask
                                  .GradingCommentDisplay,
                              }}
                            ></span>
                          </div>
                          <div className="ms-Grid-col ms-sm6">
                            <div className="ms-font-m ms-fontWeight-semibold">
                              Karakter
                            </div>
                            {this.props.studenttask.Grade}{" "}
                            {this.props.studenttask.GradeModifier}
                          </div>
                          {this.state.studenttask.Uipkms && this.state.studenttask.Uipkms.length > 0 &&
                              <div className="ms-Grid-col ms-sm12">
                                <div className="uip-row">
                                  <Label>
                                    <div className="ms-font-m ms-fontWeight-semibold">Grad av måloppnåelse</div>
                                  </Label>
                                  <div className="table-responsive">
                                    <table className="table table-bordered">
                                      <thead>
                                        <tr>
                                          <th className="iup-table-header-first">
                                            <div className="ms-font-m ms-fontWeight-semibold">
                                              Kompetansemål
                                            </div>
                                          </th>
                                          <th className="iup-table-header-second">
                                            <div className="ms-font-m ms-fontWeight-semibold">
                                              Måloppnåelse
                                            </div>
                                          </th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        {this.state.studenttask.Uipkms.map(
                                            (uip) => {
                                              return (
                                                <tr className="kmrow uipitem">
                                                  <td
                                                    data-lpid="@uip.Lpid"
                                                    data-kmid="@uip.Kmid"
                                                    className="kmtext"
                                                  >
                                                    {uip.Kmtext}
                                                  </td>
                                                  <td>
                                                    <div className="ms-ChoiceFieldGroup">
                                                      {uip.Eval ==
                                                        "1" && (
                                                        <div className="ms-ChoiceField">
                                                          <i
                                                            className="ms-Icon ms-Icon--TestBeaker"
                                                            aria-hidden="true"
                                                          ></i>{" "}
                                                          Lav
                                                        </div>
                                                      )}
                                                      {uip.Eval ==
                                                        "2" && (
                                                        <div className="ms-ChoiceField">
                                                          <i
                                                            className="ms-Icon ms-Icon--TestBeakerSolid"
                                                            aria-hidden="true"
                                                          ></i>{" "}
                                                          Middels
                                                        </div>
                                                      )}
                                                      {uip.Eval ==
                                                        "3" && (
                                                        <div className="ms-ChoiceField">
                                                          <i
                                                            className="ms-Icon ms-Icon--TestCase"
                                                            aria-hidden="true"
                                                          ></i>{" "}
                                                          Høy
                                                        </div>
                                                      )}
                                                    </div>
                                                  </td>
                                                </tr>
                                              );
                                            }
                                          )}
                                      </tbody>
                                    </table>
                                  </div>
                                </div>
                              </div>
                          }                          
                          <div className="ms-Grid-col ms-sm12 ms-md12">
                            {this.props.studenttask.ShowEval && this.state.studenttask.DeliveryStatus !== 1 && 
                              <EvaluationFiles
                                Task={this.state.studenttask}
                                CanBeEdited={false}
                                SemiBoldTitle
                                isLoadingFiles={this.state.isLoadingFiles}
                                filesToUpload={this.state.filesToUpload}
                                evaluationFiles={this.state.evaluationFiles}
                                filesLoading={this.state.filesLoading}
                                errorFetchingFiles={this.state.errorFetchingFiles}
                                fetchEvaluationFiles={this.fetchEvaluationFiles}
                              />
                            }
                          </div>
                          
                        </div>
                        }
                    </div>
                  </div>
                </div>
              </div>
            </div>
            { this.hasTaskStarted() ?
            <div className="ms-Grid-row">
              <div className="ms-Grid-col ms-sm12 ms-xl6">
                <div className="studentResources">
                  {this.state.activefile &&
                    (this.state.studenttask.DeliveryStatus == 1 || (this.state.studenttask.WsTask.KeepOpen && (moment().isBefore(moment(this.state.studenttask.Deadline)) || this.state.studenttask.WsTask.DeliverAfterDeadline))) && (
                      <ActionButton
                        iconProps={{ iconName: "Edit" }}
                        style={{fontSize: '15px', border: '1px solid #444'}}
                        onClick={() => {
                          window.open(
                            this.state.activefile.WopiViewUrl,
                            "_blank"
                          );
                        }}
                      >
                        Skriv mer på filen
                        
                      </ActionButton>
                    )}
                  {(this.props.studenttask.DeliveryStatus == 1 || (this.state.studenttask.WsTask.KeepOpen && (moment().isBefore(moment(this.state.studenttask.Deadline)) || this.state.studenttask.WsTask.DeliverAfterDeadline))) && (
                    <div>
                      {(this.state.selectedfiles && this.state.selectedfiles.length > 0) ? 
                      <div style={{marginTop: 20}} className="ms-font-m-plus">
                        Ønsker du å legge til flere filer i din innlevering, lag
                        og hent disse fra OneDrive
                      </div> : 
                      <div style={{marginTop: 20}} className="ms-font-m-plus">
                        Det er ingen filer knyttet til denne innleveringen. For å legge til filer, lag og hent disse fra OneDrive
                      </div>
                      }
                      <OneDrivePicker
                        onClose={(selected) => {
                          const draft =
                            selected.OriginalDocumentID +
                            "|" +
                            selected.FileName +
                            "|" +
                            selected.GraphParentDriveId +
                            "|" +
                            selected.GraphItemId;
                          this.props
                            .addDeliveryFile({
                              Draft: draft,
                              StudentTaskID: this.props.studenttask.Id,
                            })
                            .then((ret) => {
                              console.log("added file", ret);
                              this.props
                                .getStudentTaskDeliver(this.state.studenttask)
                                .then((ret) => {
                                  console.log("hentet på nytt", ret);
                                  let studenttask = ret.payload.data;
                                  let afurl = "";
                                  let af = undefined;
                                  if (studenttask.DeliveryFiles[0]) {
                                    afurl = studenttask.DeliveryFiles[0].WopiUrl;
                                    af = studenttask.DeliveryFiles[0];
                                  }
                                  this.setState({
                                    studenttask: studenttask,
                                    selectedfiles: studenttask.DeliveryFiles,
                                    activefile: af,
                                    activefileurl: afurl,
                                  });
                                });
                            });
                        }}
                      />             
                    </div>
                  )}
                  {console.log(this.state.delivering)}
                {this.state.delivering && (
                  <ProgressList
                    finished={() => {
                      console.log("gotfinishmessage");
                      this.props.history.push("/innleveringerstudent");
                    }}
                    currenttask={this.state.studenttask}
                  ></ProgressList>
                )}
              

                  {/* {this.state.selectedfiles &&
                    this.state.selectedfiles.length == 0 && (
                      <span>
                        Det er ingen filer knyttet til denne innleveringen.{" "}
                        <br />
                        For å legge til fil, enten velg "Hent fra OneDrive" for
                        å knytte til en fil du har lagret i din OneDrive,
                        <br /> eller velg + for å lage et nytt tomt dokument
                      </span>
                    )} */}

                  <div id="linkadds">
                    {((this.state.studenttask.DeliveryStatus == 1 || this.state.studenttask.WsTask.KeepOpen) && (moment().isBefore(moment(this.state.studenttask.Deadline)) || this.state.studenttask.WsTask.DeliverAfterDeadline)) ?
                        <LinkForm></LinkForm> 
                        : <div>
                        <Label style={{ fontWeight: "bold" }}>
                          Innleverte lenker
                        </Label>
                        <ul id="addedlinks">
                          {this.state.studenttask.LinksCollection.map(
                            (link) => {
                              return (
                                <li className="addedlink">
                                  <a target="_blank" href={link}>
                                    {link}
                                  </a>
                                </li>
                              );
                            }
                          )}
                        </ul>
                      </div>
                    }
                  </div>

                  <div className="ms-ChoiceFieldGroup mt-20">
                  {this.props.studenttask.DeliveryStatus !== 4 &&
                    (<div className="ms-ChoiceFieldGroup-title">
                      <Label style={{fontWeight: 'bold'}}>
                        Dokumenter til innlevering
                        </Label>
                    </div>)}

                    {this.state.activefile &&
                      this.state.selectedfiles &&
                      this.state.selectedfiles.map((item) => {
                        console.log("file", item);
                        // if (item.DeliveryStatus === 5) {
                        //   this.props.checkStatusTask([item]);
                        // }
                        return (
                          <div className="ms-ChoiceField" style={{height: 40, display: "flex", flexDirection: "row", alignItems: "center", textAlign: "center"}}>
                            <input
                              onChange={() => {
                                console.log("changing", item);
                                this.setState({ activefile: item });
                              }}
                              data-wopiurl="@item.WopiUrl"
                              data-delid="@item.Id"
                              id="radio10-@item.Id"
                              className="ms-ChoiceField-input"
                              type="radio"
                              name="radio10"
                              checked={item.Id == this.state.activefile.Id}
                            />

                            {item.LagtTilAvLarer && item.LagtTilAvLarer === true && this.renderAddedByTeacher(item)}

                            <span className="ms-Label ml-5">
                              {item.FileNameDraft}
                            </span>

                            <a
                              target="_blank"
                              href={item.FileUrlDelivery}
                              title="Åpne fil"
                              className="pointer"
                            >
                              <i className="ms-Icon ms-Icon--OpenFile ml-10"></i> Åpne fil
                            </a>

                            {this.state.selectedfiles.length > 1 &&
                              this.state.studenttask.DeliveryStatus == 1 && this.renderDeleteDeliverFileButton(item, this.fileIsBeingDeleted(item.Id))
                            }

                            {(item.DeliveryStatus === 5 && !this.state.studenttask.WsTask.KeepOpen)  && (
                              <ProgressIndicator
                                label="Sender inn"
                                description="desc"
                              />
                            )}
                          </div>
                        );
                      })}
                      {( (this.props.studenttask.DeliveryStatus == 1 || this.state.studenttask.WsTask.KeepOpen) && this.state.studenttask.DeliverStatus!=4 && (moment().isBefore(moment(this.state.studenttask.Deadline)) || this.state.studenttask.WsTask.DeliverAfterDeadline)) && 
                                <PrimaryButton
                                style={{marginTop: "25px"}}
                                onClick={() => {
                                  console.log(
                                    "trigger baby",
                                    this.state.studenttask.DeliveryFiles
                                  );
                                  this.setState({ delivering: true }, () => {
                                    this.props.initStatusTask(emptylist);
                                    this.props
                                      .submitTask(this.state.studenttask)
                                      .then((resp) => {
                                        console.log("resp submitTask", resp);
                                        this.props.checkStatusTask(resp.payload.data);
                                      });
                                  });
                                }}
                              
                              >
                                Send inn
                              </PrimaryButton>
                      
                      }
                      {/* {!this.state.studenttask.WsTask.KeepOpen && this.state.studenttask.DeliveryStatus!=4 && (moment().isSameOrBefore(this.props.studenttask.Deadline) || this.state.studenttask.WsTask.DeliverAfterDeadline) && this.props.studenttask.DeliveryStatus == 1 &&
                        <PrimaryButton
                        onClick={() => {
                          console.log(
                            "trigger baby",
                            this.state.studenttask.DeliveryFiles
                          );
                          this.setState({ delivering: true }, () => {
                            this.props.initStatusTask(emptylist);
                            this.props
                              .submitTask(this.state.studenttask)
                              .then((resp) => {
                                console.log("resp submitTask", resp);
                                this.props.checkStatusTask(resp.payload.data);
                              });
                          });
                        }}
                      
                      >
                        Send inn
                      </PrimaryButton>
                      } */}
                    
                      <div className="ms-Grid-row" style={{marginTop: 20}}>
                          <div className="ms-Grid-col ms-sm12 ms-md12">
                        {this.state.activefile && (
                          <Select
                            placeholder={"Versjoner"}
                            onChange={(selected) => {
                              window.open(selected.value, "_blank");
                            }}
                            options={this.state.activefile.opts}
                          />
                        )}
                        </div>
                    </div>

                  <ul>
                    {this.state.comments.map((item) => {
                      return (
                      <div className="mt-10">
                        <Label style={{fontWeight:'bold'}}>Kommentarer fra lærer</Label>
                        <li>
                          <span style={{fontWeight:'bold'}}> {item.TeacherName}</span> la
                          inn kommentar den <b>{item.Datefriendly}</b>:
                          <div style={{padding:10}}>
                            <span
                              dangerouslySetInnerHTML={{
                                __html: item.CommentText,
                              }}
                            ></span>
                          </div>
                        </li>
                        </div>
                      );
                    })}
                  </ul>
                  </div>
                </div>
              </div>
              <div className="ms-Grid-row">
                <div className="ms-Grid-col ms-sm12  text-left">
                  {
                    this.state.studenttask.DeliveryStatus !== 4 &&
                    (<div className="oppgavePreviewCont">
                    <span className="ms-fontSize-xl">
                      Forhåndsvisning av{" "}
                      <a className="ms-Link" href="#">
                        {this.state.activefile &&
                          this.state.activefile.StudentTaskID == id && (
                            <span>{this.state.activefile.FileNameDraft}</span>
                          )}
                      </a>
                    </span>
                    {this.state.studenttask.WsTask.KeepOpen && this.state.activefile &&
                      this.state.activefile.StudentTaskID == id &&
                      (
                          <iframe
                            height="800px"
                            className="oppgavePreview"
                            id="wopiFrame"
                            src={this.state.activefile.WopiUrl}
                          ></iframe>
                      )}
                    {!this.state.studenttask.WsTask.KeepOpen && this.state.activefile &&
                      this.state.activefile.StudentTaskID == id &&
                      this.state.studenttask.DeliveryStatus == 1 && (
                          <iframe
                            height="800px"
                            className="oppgavePreview"
                            id="wopiFrame"
                            src={this.state.activefile.WopiUrl}
                          ></iframe>
                      )}
                    {!this.state.studenttask.WsTask.KeepOpen && this.state.activefile &&
                      this.state.activefile.StudentTaskID == id &&
                      this.state.studenttask.DeliveryStatus != 1 && (
                          <iframe
                            height="800px"
                            className="oppgavePreview"
                            id="wopiFrame"
                            src={
                              this.props.teacherstate.user.url +
                              this.state.activefile.WopiUrl
                            }
                          ></iframe>
                      )}
                  </div>)

                  }
                </div>
              </div>
            </div>
              : this.renderTaskNotStarted()
            }
          </div>
        </div>
      );
    }
  }
}

function mapStateToProps(state) {
  console.log("deliverstate", state);
  // let afurl = "";
  // let af = undefined;
  // if (
  //   state.StudentReducer.studenttask &&
  //   state.StudentReducer.studenttask.DeliveryFiles
  // ) {
  //   state.StudentReducer.studenttask.DeliveryFiles.forEach((element) => {
  //     let veropt = [];
  //     if (element.VersionFiles) {
  //       element.VersionFiles.forEach((element) => {
  //         veropt.push({ value: element.WopiUrl, label: element.Name });
  //       });
  //     }

  //     element.opts = veropt;
  //   });
  //   if (state.StudentReducer.studenttask.DeliveryFiles[0]) {
  //     afurl = state.StudentReducer.studenttask.DeliveryFiles[0].WopiUrl;
  //     af = state.StudentReducer.studenttask.DeliveryFiles[0];
  //   }
  // }
  return {
    teacherstate: state.teacher,
    studenttask: state.StudentReducer.studenttask,
    // activefileurl: afurl,
    // activefile: af,
  };
}

export default connect(mapStateToProps, {
  getStudentTaskDeliver,
  addDeliveryFile,
  submitTask,
  checkStatusTask,
  initStatusTask,
  removeDeliveryFile,
  getComments,
})(StudentTaskView);
