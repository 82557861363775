import { Providers, MsalProvider, TeamsProvider } from "@microsoft/mgt";
import { ROOT_URL, SP_HOST_URL } from "../../app/actions/constants";
import axios from "axios";
import { resolve } from "url";
import { ISubmission } from "../models/graph/ISubmission";
import { ISubmittedResource } from "../models/graph/ISubmittedResource";
import { IAssignment } from "../models/graph/IAssignment";
import { TeamAssignment } from "../models/graph/TeamAssignment";
import { TeamSubmission } from "../models/graph/TeamSubmission";
import { IRubic } from "../models/graph/IRubric";
import { IEducationUser } from "../models/graph/IEducationUser";

const url = "https://zokratesplagcheck.azurewebsites.net";
//const url =' http://localhost:7071';

// export function getAllClasses() {
//   let graphClient = Providers.globalProvider.graph.client;
//   let allClasses = graphClient
//     .api("/education/classes")
//     .version("beta")
//     .top(999)
//     .get()
//     .then();

//   return {
//     type: GET_ALL_CLASSES,
//     payload: allClasses,
//   };
// }

// export function getAllClasses() {
//   let graphClient = Providers.globalProvider.graph.client;
//   let allClasses = graphClient
//     .api("/education/me/classes")
//     .version("beta")
//     .top(999)
//     .get()
//     .then();

//   return {
//     type: GET_ALL_CLASSES,
//     payload: allClasses,
//   };
// }

// export function getClassMembers(classid: string) {
//   let graphClient = Providers.globalProvider.graph.client;
//   let classMembers = graphClient
//     .api(`/education/classes/${classid}/members`)
//     .version("beta")
//     .get()
//     .then();

//   return {
//     type: GET_CLASS_MEMBERS,
//     payload: classMembers,
//   };
// }
// export function getAllClassAssignments(classid: string) {
//   let graphClient = Providers.globalProvider.graph.client;
//   let allClassAssignments = graphClient
//     .api(`/education/classes/${classid}/assignments`)
//     .version("beta")
//     .get()
//     .then();

//   return {
//     type: GET_CLASS_ASSIGNMENTS,
//     payload: allClassAssignments,
//   };
// }
export async function getAssignment(classid: string, assignmentid: string): Promise<IAssignment> {
  let graphClient = Providers.globalProvider.graph.client;
  let assignment: IAssignment = await graphClient
    .api(`/education/classes/${classid}/assignments/${assignmentid}`)
    .version("beta")
    .get();
    return assignment;
}

const getSubmittedResources = async (classid: string, assignmentid: string): Promise<any> => {
  return new Promise((resolve, reject) => {

    let graphClient = Providers.globalProvider.graph.client;
  
    let submissionList;
    
    graphClient.api(`/education/classes/${classid}/assignments/${assignmentid}/submissions?$expand=outcomes`).version("beta").get().then((response) => {
      let batchList = [];

      submissionList = response.value;
      
      submissionList.forEach(submission => {
        batchList.push(new Promise((resolved, rejected) => {
          graphClient.api(`/education/classes/${classid}/assignments/${assignmentid}/submissions/${submission.id}/submittedResources`).version("beta").get().then((resource) => {
            submission.resources = resource.value;
            resolved({});
          })
        }))
      });
      
      Promise.all(batchList).then(promiseList => {
         resolve(submissionList);
      });
    })
  });
}

export async function getAssignmentFull(classid: string, assignmentid: string): Promise<TeamAssignment> {
  let graphClient = Providers.globalProvider.graph.client;

  let classMembers: IEducationUser[] = (await graphClient
    .api(`/education/classes/${classid}/members`)
    .version("beta")
    .get()).value;

  console.log("ASSIGNMENT_ID:", assignmentid);
  let graphAssignment: IAssignment = await graphClient
    .api(`/education/classes/${classid}/assignments/${assignmentid}`)
    .version("beta")
    .get();

  let assignment = new TeamAssignment(graphAssignment);
  //.then();

  // let submissions = await graphClient
  //   .api(
  //     `/education/classes/${classid}/assignments/${assignmentid}/submissions`
  //   )
  //   .version("beta")
  //   .get();

  let submissions: ISubmission[] = await getSubmittedResources(classid, assignmentid);

  assignment.submissions = submissions.map(sub => new TeamSubmission(sub));

  let rubric: IRubic = await graphClient
    .api(`/education/classes/${classid}/assignments/${assignmentid}/rubric`)
    .version("beta")
    .get();
    let qualmap = {};
    let qualmaprev ={};
    let levelmap = {};
    let levelmaprev = {};
    if (rubric) {
      qualmap = rubric.qualities.reduce(
        function (map, obj) {
          map[obj.qualityId] = obj;
          return map;
        },
        {}
      );
      qualmaprev = rubric.qualities.reduce(
        function (map, obj) {
          map[obj.description.content.split('|')[1]] = obj;
          return map;
        },
        {}
      );
      levelmap = rubric.levels.reduce(
        function (map, obj) {
          map[obj.levelId] = obj;
          return map;
        },
        {}
      );
      levelmaprev = rubric.levels.reduce(
        function (map, obj) {
          map[obj.displayName] = obj;
          return map;
        },
        {}
      );
    }
  let membercoll = null;
  membercoll = classMembers.reduce(function (map, obj) {
    map[obj.id] = obj;
    return map;
  }, {});

  let submissionsred = null;
  // console.log("sub", state.GraphReducer.assignmentsubmissions);
  //  console.log("membercoll", membercoll);

  let returncoll = [];
  for (let index = 0; index < assignment.submissions.length; index++) {
    const element = submissions[index];
    let outcomes: any[] = (await graphClient
      .api(
        `/education/classes/${classid}/assignments/${assignmentid}/submissions/${element.id}/outcomes`
      )
      .version("beta")
      .get()).value;
      element.outcomes = outcomes;
    returncoll.push(assignment);
  }
  console.log("TeamAssignment:", assignment);
  assignment.submissions.forEach(submission => {
    submission.levelmaprev = levelmaprev,
    submission.qualmaprev = qualmaprev;
    submission.qualmap = qualmap;
    submission.classid = classid;
    submission.assignmentid = assignmentid;
    //submission.outcome = mappedoutcomes[submission.graphSubmission.id];
    submission.student = classMembers.find(user => user.id == submission.graphSubmission.recipient.userId);
    submission.userName = membercoll[submission.graphSubmission.recipient.userId].userPrincipalName;
  });

  returncoll.forEach((element) => {});
  let mappedoutcomes = returncoll.reduce(function (map, obj) {
    map[obj.submissionid] = obj;
    return map;
  }, {});
  // submissionsred = submissions.reduce(function (map, obj) {
  //   obj.levelmaprev = levelmaprev;
  //   obj.qualmaprev = qualmaprev;
  //   obj.qualmap = qualmap;
  //   obj.classid = classid;
  //   obj.assignmentid = assignmentid;
  //   obj.outcome = mappedoutcomes[obj.id];
  //   map[membercoll[obj.recipient.userId].userPrincipalName] = obj;
  //   return map;
  // }, {});
  // assignment.subcoll = returncoll;
  // assignment.submissionsz = submissionsred;
  assignment.members = classMembers;
  assignment.graphRubic = rubric;
  return assignment;
}
// export function getAssignmentSubmissions(
//   classid: string,
//   assignmentid: string
// ) {
//   let graphClient = Providers.globalProvider.graph.client;
//   let result = graphClient
//     .api(
//       `/education/classes/${classid}/assignments/${assignmentid}/submissions`
//     )
//     .version("beta")
//     .get()
//     .then();

//   return {
//     type: GET_ASSIGNMENT_SUBMISSIONS,
//     payload: result,
//   };
// }
// export async function getAssignmentOutcomes(
//   classid: string,
//   assignmentid: string,
//   submission: any
// ) {
//   let graphClient = Providers.globalProvider.graph.client;

//   let assignment = await graphClient
//     .api(
//       `/education/classes/${classid}/assignments/${assignmentid}/submissions/${submission.id}/outcomes`
//     )
//     .version("beta")
//     .get()
//     .then();
//   assignment.submissionid = submission.id;
//   return {
//     type: GET_ASSIGNMENT_OUTCOMES,
//     payload: assignment,
//   };
// }
// export async function getAllAssignmentOutcomes(
//   classid: string,
//   assignmentid: string,
//   submission: any[]
// ) {
//   let graphClient = Providers.globalProvider.graph.client;
//   let returncoll = [];
//   for (let index = 0; index < submission.length; index++) {
//     const element = submission[index];
//     let assignment = await graphClient
//       .api(
//         `/education/classes/${classid}/assignments/${assignmentid}/submissions/${element.id}/outcomes`
//       )
//       .version("beta")
//       .get();
//     assignment.submissionid = element.id;
//     returncoll.push(assignment);
//   }

//   return {
//     type: GET_ASSIGNMENT_ALLOUTCOMES,
//     payload: returncoll,
//   };
// }
// export function getAssignmentPlagInfo(classid: string, assignmentid: string) {
//   let ind = { Delivery: assignmentid, teamid: classid };
//   const f = axios.post(`${url}/api/zCheck?teamid=` + classid, ind, {
//     headers: {
//       Authorization: localStorage.getItem("adal.idtoken"),
//     },
//   });
//   return {
//     type: GET_ASSIGNMENT_PLAG,
//     payload: f,
//   };
// }
// export function submitResource(
//   classid: string,
//   assignmentid: string,
//   resource: any,
//   resheader: any
// ) {
//   const f = axios
//     .get(`${resource.fileUrl}`, {
//       headers: {
//         Authorization: localStorage.getItem("adal.idtoken"),
//       },
//     })
//     .then((ret) => {
      
//       let d = {
//         Url: ret.data["@microsoft.graph.downloadUrl"],
//         FileName: resource.displayName,
//         Delivery: assignmentid,
//         Fileid: resheader.id,
//         Id: ret.data.id,
//       };
//       // console.log("ret", ret);

//       console.log("SUCCESS:", ret, d)
//       const fu = axios.post(`${url}/api/zSubmit?teamid=` + classid, d, {
//         headers: {
//           Authorization: localStorage.getItem("adal.idtoken"),
//         },
//       }).catch(error => {console.log("ERROR2:", error)});;
//     }).catch(error => {console.log("ERROR:", error)});
//   return {
//     type: SUBMIT_PLAG,
//     payload: f,
//   };
// }
// export function updateAssignment(
//   classid: string,
//   assignmentid: string,
//   task: any
// ) {
//   let graphClient = Providers.globalProvider.graph.client;
//   let assignment = graphClient
//     .api(`/education/classes/${classid}/assignments/${assignmentid}`)
//     .version("beta")
//     .patch(task)
//     .then();

//   return {
//     type: UPDATE_ASSIGNMENT,
//     payload: assignment,
//   };
// }
// export function publishAssignment(classid: string, assignmentid: string) {
//   let graphClient = Providers.globalProvider.graph.client;
//   let assignment = graphClient
//     .api(`/education/classes/${classid}/assignments/${assignmentid}/publish`)
//     .version("beta")
//     .post({})
//     .then();

//   return {
//     type: PUBLISH_ASSIGNMENT,
//     payload: assignment,
//   };
// }
// export function createAssignment(classid: string, task: any, callback: any) {
//   let graphClient = Providers.globalProvider.graph.client;
//   let assignment = graphClient
//     .api(`/education/classes/${classid}/assignments`)
//     .version("beta")
//     .post(task)
//     .then();

//   return {
//     type: CREATE_ASSIGNMENT,
//     payload: assignment,
//   };
// }
// export function createRubric(rubric: any, callback: any) {
//   let graphClient = Providers.globalProvider.graph.client;
//   let assignment = graphClient
//     .api(`/education/me/rubrics`)
//     .version("beta")
//     .post(rubric)
//     .then();

//   return {
//     type: CREATE_RUBRIC,
//     payload: assignment,
//   };
// }
// export function updateOutcome(
//   outcome: any,
//   classid: string,
//   assignmentid: string,
//   submissionid: string,
//   outcomeid: string,
//   callback: any
// ) {
//   let graphClient = Providers.globalProvider.graph.client;

//   let r = graphClient
//     .api(
//       `/education/classes/${classid}/assignments/${assignmentid}/submissions/${submissionid}/outcomes/${outcomeid}`
//     )
//     .version("beta")
//     .patch(outcome)
//     .then();

//   return {
//     type: UPDATE_OUTCOME,
//     payload: r,
//   };
// }
// export function returnSubmission(
//   classid: string,
//   assignmentid: string,
//   submissionid: string,
//   callback: any
// ) {
//   let graphClient = Providers.globalProvider.graph.client;

//   let r = graphClient
//     .api(
//       `/education/classes/${classid}/assignments/${assignmentid}/submissions/${submissionid}/return`
//     )
//     .version("beta")
//     .post({})
//     .then();

//   return {
//     type: RETURN_SUBMISSION,
//     payload: r,
//   };
// }
// export function attachRubric(rubric: any, assignment: any, callback: any) {
//   let graphClient = Providers.globalProvider.graph.client;
//   const educationRubric = {
//     "@odata.id": `https://graph.microsoft.com/v1.0/education/me/rubrics/${rubric.id}`,
//   };
//   let r = graphClient
//     .api(
//       `/education/classes/${assignment.classId}/assignments/${assignment.id}/rubric/$ref`
//     )
//     .version("beta")
//     .put(educationRubric)
//     .then();

//   return {
//     type: ATTACH_RUBRIC,
//     payload: r,
//   };
// }
// export async function loadsubmission(
//   classid: string,
//   assignmentid: string,
//   submission: any
// ) {
//   let graphClient = Providers.globalProvider.graph.client;

//   let s = await graphClient
//     .api(
//       `/education/classes/${classid}/assignments/${assignmentid}/submissions/${submission}?$expand=submittedResources,outcomes`
//     )
//     .version("beta")
//     .get()
//     .then();
  
//   return {
//     type: GET_FULL_SUBMISSION,
//     payload: s,
//   };
// }
// export async function loadfulltask(
//   classid: string,
//   assignmentid: string,
// ) {
//   let graphClient = Providers.globalProvider.graph.client;

//   let s = await graphClient
//     .api(
//       `/education/classes/${classid}/assignments/${assignmentid}?$expand=rubric`
//     )
//     .version("beta")
//     .get()
//     .then();
  
//   return {
//     type: GET_FULL_TASK,
//     payload: s,
//   };
// }
// export async function getPreview(
//   url: string,

// ) {
//   let graphClient = Providers.globalProvider.graph.client;

//   let s = await graphClient
//     .api(
//       `${url}/preview`
//     )
//     .version("beta")
//     .post({})
//     .then();
  
//   return {
//     type: GET_FILE_PREVIEW,
//     payload: s,
//   };
// }
// export function saveTeamMap(tm: any, callback: (data) => void) {
//   const request = axios.post(`${ROOT_URL}/SaveTeamMap/${SP_HOST_URL}`, tm, {
//     headers: {
//       Authorization: localStorage.getItem("id_token"),
//     },
//   });

//   request.then((response) => callback(response.data));

//   return {
//     type: SAVE_TEAMMAP,
//     payload: request,
//   };
// }
