import * as React from "react";
import {Checkbox, Label} from "office-ui-fabric-react";

import './../../formelements.scss'

interface ICheckboxFieldProps{
    input:any;
    label:string;
    required?:boolean;
    initialValue?: boolean;
}


const CheckboxField = ({input, label, required, initialValue}:ICheckboxFieldProps) =>{
    return(
        <div className="ms-Grid-row">
            <div className="z-form-group vertical-align checkbox-container">
                <div className="ms-Grid-col ms-sm12 ms-md4">
                    <Label required={required} className="z-form-label">
                        {label}
                    </Label>
                </div>
                <div className="ms-Grid-col ms-sm12 ms-md8 checkbox" style={{marginLeft: "12px"}}>
                    <Checkbox
                        checked={!!input.value}
                        onChange={(e, checked) => {
                            console.log("Checked:", checked);
                            input.onChange(checked);
                        }}
                    />
                </div>
            </div>
        </div>
    )
};

export default CheckboxField;