import * as React from "react";
import {Dropdown, Label, Toggle} from "office-ui-fabric-react";


interface IToggleFieldProps{
    label: string;
    input: any;
    options?:any[]
    meta: {
        touched: boolean;
        error: string;
    },
    placeholder?:string;
    defaultValue?:any;
    required?:boolean;
    disabled?:boolean;
    children:any;
    onSelect?:(key:any)=>void;
}


const ToggleField = (props:IToggleFieldProps) =>{

    const {
        label,
        input,
        required,
        disabled,
        onSelect,
        defaultValue,
        placeholder,
        options,
        meta: {
            touched,
            error
        },
        children
    } = props;


    const handleChange = (item) =>{
        input.onChange(item.key, item.value);

        if (onSelect){
            onSelect(item.key);
        }
    };

    return(
        <Toggle
        defaultChecked={input.value}
        onText="Publiser til elev"
        offText="Ikke publiser til elev"
        onChanged={(newVal) => {
            input.onChange(newVal);
        }}
        style={{ marginBottom: '5px' }}
    />
              
    )
};

export default ToggleField;