
import axios from 'axios';
import * as _ from 'lodash';
import { ROOT_URL, SP_HOST_URL } from './constants';

export const GET_STUDENT_IUPLISTTEACHER = 'GET_STUDENT_IUPLIST';
export const GET_STUDENT_IUPLDISCOVERY = 'GET_STUDENT_IUPLDISCOVERY';
export const GET_STUDENT_PORTFOLIODISCOVERY = 'GET_STUDENT_PORTFOLIODISCOVERY';
export const GET_ALL_ELEMENTS_TEACHER = "'GET_ALL_ELEMENTS_TEACHER";

export function getStudentIUPlist(student:any) {

    const request = axios.post(`${ROOT_URL}/GetStudentIUPSTeacher${SP_HOST_URL}`,student, {
        headers: {
            Authorization: localStorage.getItem('id_token')
        }
    });

    return {
        type: GET_STUDENT_IUPLISTTEACHER,
        payload: request
    }
}
export function getStudentIUPDiscovery(iup:any) {

    const request = axios.post(`${ROOT_URL}/GetStudentIUPDiscovery${SP_HOST_URL}`,iup, {
        headers: {
            Authorization: localStorage.getItem('id_token')
        }
    });

    return {
        type: GET_STUDENT_IUPLDISCOVERY,
        payload: request
    }
}

export function getAllElementsTeacher(fromDate: any, toDate: any, courseList: any) {
    const data:FormData = new FormData();
    data.set("FromDate", fromDate);
    data.set("ToDate", toDate);
    data.set("CourseList", JSON.stringify(courseList));

    const request = axios.post(`${ROOT_URL}/GetMyElementsTeacher/${SP_HOST_URL}`, data, {
        headers: {
            Authorization: localStorage.getItem('id_token')
        }
    });

    return  {
        type: GET_ALL_ELEMENTS_TEACHER,
        payload: request
    }
}

export function getStudentElementsDiscovery(student:string,lp:string,from:any,to:any) {
    const data:FormData = new FormData();
    data.set('Student', student);
    data.set('LP', lp);
    data.set('From', from);
    data.set('To', to);
    const request = axios.post(`${ROOT_URL}/GePortfolioDiscovery${SP_HOST_URL}`, data, {
        headers: {
            Authorization: localStorage.getItem('id_token')
        }
    });

    return {
        type: GET_STUDENT_PORTFOLIODISCOVERY,
        payload: request
    }
}
