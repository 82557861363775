import * as React from 'react';
import axios from 'axios';
import { withRouter, Link, Prompt } from 'react-router-dom';
import { connect } from 'react-redux';
import { Field, reduxForm, change, isDirty, getFormValues } from 'redux-form';
import { saveIupReportStudentForClass, getIupReportForClassDraft} from '../../actions/Action_Iup';
import { setstatus } from '../../actions/Action_Teacher';
import {
  DefaultButton,
  Dropdown,
  TextField,
  Label,
  ActionButton,
  Spinner,
  SpinnerSize,
  Toggle,
  Icon,
  PrimaryButton
} from "office-ui-fabric-react";
import DatePicker from "react-datepicker";
import * as moment from "moment";
import {
  DATE_FORMAT,
  LOCALE,
  scheduleUtils,
  TIME_FORMAT
} from "../timeplan/ScheduleElementDetailView";
import ReactQuill from "react-quill"; // Typescript
import "../../../../src/app/formelements.scss";
export interface IStudentreportFormProps {
  onemitform: (formvalues: any) => void;
  saveIupReportStudentForClass(iup: any);
  getIupReportForClassDraft(iup: any, includeDraft?: boolean);
  formValues: any;
  initialValues: any;
  handleSubmit: any;
  history: any;
  match: any;
  student: any;
  includeDraft: boolean;
  dirty: boolean;
  setstatus(statedata:any);
  setFormStatus(isDirty: boolean): void;
}
export interface IStudentreportFormState {
	activecourse: string;
	working: boolean;
}
class StudentreportForm extends React.Component<IStudentreportFormProps, IStudentreportFormState> {
	constructor(props) {
		super(props);
		this.state = { activecourse: '0', working: false };
	}

	componentWillUnmount() {
		this.props.onemitform(this.props);
	}

	componentDidUpdate(prevProps: IStudentreportFormProps, prevState: IStudentreportFormState, snapshot) {
		if (this.props.initialValues.StudentLogin != prevProps.initialValues.StudentLogin) {
			this.props.onemitform(prevProps);
		}

		if (this.props.dirty != prevProps.dirty) {
			this.props.setFormStatus(this.props.dirty);
		}


	}
	/****************************
            Event handlers
     ****************************/

  onFormSubmit(values) {
    this.setState({ working: true });
    this.props.saveIupReportStudentForClass(values).then(() => {
		this.props.setstatus({working:false,text:'lagret '});
		this.props.setFormStatus(false);
      	this.props.getIupReportForClassDraft(this.props.student.Report.Iupid , this.props.includeDraft);
      	this.setState({ working: false });
    });
  }

	onDeleteClick() {
		const { id } = this.props.match.params;
	}

	/****************************
            Helper methods
     ****************************/

	formatFormValueDatesToDateString(values) {
		values.End = `${values.Start.substring(0, 11)}${values.End}`;
		return values;
	}

	/****************************
            Render methods
     ****************************/

	renderTimeField(field) {
		const {
			input,
			meta: { touched, error }
		} = field;
		const selectedDate = input.value ? moment(input.value, TIME_FORMAT) : null;

		return (
			<div className="ms-Grid-row">
				<div className="z-form-group">
					<div className="ms-Grid-col ms-sm12 ms-md4">
						<Label className="z-form-label">
							{field.label}
							<span>{error ? '*' : ''}</span>
						</Label>
					</div>
					<div className="ms-Grid-col ms-sm12 ms-md8">
						<DatePicker
							autocomplete="off"
							{...input}
							selected={selectedDate}
							onChange={(date) => {
								input.onChange(date.locale(LOCALE).format(TIME_FORMAT));
							}}
							timeFormat="HH:mm"
							timeIntervals={5}
							minTime={moment().hours(8).minutes(0)} // ScheduleElements cannot start before 8:00AM
							maxTime={moment().hours(16).minutes(0)} // ScheduleElements cannot end after 16:00AM
							filterDate={(date) => {
								return date.day() !== 0 && date.day() !== 6;
							}} // Weekends are disabled
							locale="nb-NO"
							dateFormat="LT"
							timeCaption="Tid"
							showTimeSelect
							showTimeSelectOnly
						/>
						{touched && error && <span className="ms-TextField-errorMessage errorMessage-88">{error}</span>}
					</div>
				</div>
			</div>
		);
	}

	renderDateTimeField(field) {
		const {
			input,
			meta: { touched, error }
		} = field;
		const selectedDate = input.value ? moment(input.value, DATE_FORMAT) : null;

		return (
			<div className="ms-Grid-row">
				<div className="z-form-group">
					<div className="ms-Grid-col ms-sm12 ms-md4">
						<Label className="z-form-label">
							{field.label}
							<span>{error ? '*' : ''}</span>
						</Label>
					</div>
					<div className="ms-Grid-col ms-sm12 ms-md8">
						<DatePicker
							{...input}
							selected={selectedDate}
							onChange={(date) => {
								input.onChange(date.locale(LOCALE).format(DATE_FORMAT));
							}}
							autocomplete="off"
							timeFormat="HH:mm"
							timeIntervals={5}
							minTime={moment().hours(8).minutes(0)} // ScheduleElements cannot start before 8:00AM
							maxTime={moment().hours(16).minutes(0)} // ScheduleElements cannot end after 16:00AM
							filterDate={(date) => {
								return date.day() !== 0 && date.day() !== 6;
							}} // Weekends are disabled
							locale="nb-NO"
							dateFormat={DATE_FORMAT}
							timeCaption="Tid"
							showTimeSelect
							showWeekNumbers
						/>
						{touched && error && <span className="ms-TextField-errorMessage errorMessage-88">{error}</span>}
					</div>
				</div>
			</div>
		);
	}

	renderTextField(field) {
		const {
			meta: { touched, error }
		} = field;

		return (
			<div className="ms-Grid-row">
				<div className="z-form-group">
					<div className="ms-Grid-col ms-sm12 ms-md4">
						<Label className="z-form-label">
							{field.label}
							<span>{error ? '*' : ''}</span>
						</Label>
					</div>
					<div className="ms-Grid-col ms-sm12 ms-md8">
						<TextField errorMessage={touched ? error : ''} {...field.input} />
					</div>
				</div>
			</div>
		);
	}
	renderToggle(field) {
		const {
			input,
			meta: { touched, error },
			children
		} = field;
		
		return (
			<div className="ms-Grid-row">
				<div className="z-form-group">
					<div className="ms-Grid-col ms-sm12 ms-md4">
						<Label className="z-form-label">
							{field.label}
							<span>{error ? '*' : ''}</span>
						</Label>
					</div>
					<div className="ms-Grid-col ms-sm12 ms-md8">
						<Toggle
							checked = {field.input.value}
							{...field.input}
							onChanged={(html) => {
								input.onChange(html);
							}}
						></Toggle>
					</div>
				</div>
			</div>
		);
	}
	renderSelectField(field) {
		const {
			input,
			meta: { touched, error },
			children
		} = field;

		const options = children.props.children;

		return (
			<div className="ms-Grid-row">
				<div className="z-form-group">
					<div className="ms-Grid-col ms-sm12 ms-md4">
						<Label className="z-form-label">
							{field.label}
							<span>{error ? '*' : ''}</span>
						</Label>
					</div>
					<div className="ms-Grid-col ms-sm12 ms-md8">
						<Dropdown
							{...input}
							options={options}
							selectedKey={input.value}
							errorMessage={touched && error}
							onChanged={(item) => input.onChange(item.key)}
						/>
					</div>
				</div>
			</div>
		);
	}
	renderRichTextField(field) {
		const {
			input,
			meta: { touched, error },
			children
		} = field;

		return (
			<div className="ms-Grid-row">
				<div className="z-form-group">
					<div className="ms-Grid-col ms-sm12 ms-md4">
						<Label className="z-form-label">
							{field.label}
							<span>{error ? '*' : ''}</span>
						</Label>
					</div>
					<div className="ms-Grid-col ms-sm12 ms-md8">
						<ReactQuill
							value={input.value}
							onChange={(html) => {
								if(html == "<p><br></p>"){
									input.onChange(null);
								}
								else {
									input.onChange(html);
								}
							}}
						/>
					</div>
				</div>
			</div>
		);
	}
	renderReadOnly(field) {
		const {
			input,
			meta: { touched, error },
			children
		} = field;

		return (
			<div className="ms-Grid-row">
				<div className="z-form-group">
					<div className="ms-Grid-col ms-sm12 ms-md4">
						<Label className="z-form-label">
							{field.label}
							<span>{error ? '*' : ''}</span>
						</Label>
					</div>
					<div className="ms-Grid-col ms-sm12 ms-md8">
						<span>
							<span 
								dangerouslySetInnerHTML={{
									__html: input.value
								}}
							></span>
						</span>
					</div>
				</div>
			</div>
		);
	}
	render() {
		const { handleSubmit } = this.props;
		return (
			<span>

			
			<form className="schedule-form" onSubmit={handleSubmit(this.onFormSubmit.bind(this))}>
				<div className="iup-student-report-header">
					<h4>{this.props.student.Name}</h4>
				</div>

				<ul className="nav nav-tabs " id="myTab" role="tablist">
					<li
						className="nav-item nav-link active show"
						onClick={() => {
							this.setState({ activecourse: '0' });
						}}
					>
						Oppsummering
					</li>
					{this.props.student.Reports.map((rep) => {
						console.log('report', rep);
						return (
							<li
								className="nav-item nav-link active show"
								onClick={() => {
									this.setState({ activecourse: rep.Courseid });
								}}
							>
								{rep.Title}
							</li>
						);
					})}
				</ul>

				{this.state.activecourse == '0' && (
					<div className="tab-content" id="myTabContent">
						<div
							className="tab-pane active show"
							id="summary-@item.LoginName.Split('@')[0]"
							role="tabpanel"
							aria-labelledby="home-tab"
						>
							<div className="form-content">
								<Field
									name="BackEval"
									label="Generell tilbakemelding" // Custom fields variables can be fetched in component (i.e. field.label)
									component={this.renderRichTextField}
								/>
								<Field
									name="FutureEval"
									label="Generell framovermelding" // Custom fields variables can be fetched in component (i.e. field.label)
									component={this.renderRichTextField}
								/>
								<Field
									name="BackComment"
									label="Kommentar fra elevsamtale" // Custom fields variables can be fetched in component (i.e. field.label)
									component={this.renderRichTextField}
								/>
								<Field
									name="FutureComment"
									label="Kommentar fra kontaktsamtale" // Custom fields variables can be fetched in component (i.e. field.label)
									component={this.renderRichTextField}
								/>
								<Field
									name="Published"
									label="Publiser til elev og foresatte" // Custom fields variables can be fetched in component (i.e. field.label)
									component={this.renderToggle}
								/>
								<div className="form-footer">
									<div className="form-footer-left">
										{
											// Delete button is not rendered when creating new ScheduleElements
											!isNaN(this.props.match.params.id) && (
												<ActionButton
													iconProps={{ iconName: 'Delete' }}
													text="Slett"
													onClick={this.onDeleteClick.bind(this)}
												/>
											)
										}
									</div>
									<div className="form-footer-right">
										<PrimaryButton
											primary={true}
											onClick={handleSubmit(this.onFormSubmit.bind(this))}
										>
											{!this.state.working ? "Lagre" : <Spinner size={SpinnerSize.small}/>}
										</PrimaryButton>
										<ActionButton
											iconProps={{ iconName: 'pdf' }}
											styles={{
												root: {
													float: 'right'
												}
											}}
											text="Opprett PDF"
											onClick={() => {
												let contents = document.getElementsByClassName('tab-pane');
												let html =
													`<!DOCTYPE html>\
                          <html>\
                            <head>\
                              <title>${this.props.student.Name}</title>\
                              <meta charset='UTF-8' />\
                            </head>\
                            <body>\
                              <style>.table {\
                                width: 100%;\
                                margin-bottom: 1rem;\
                                border-collapse: collapse;\
                                }\
                                .table-bordered td,th {\
                                    border: 0.5px solid #696969;\
                                    padding: 5px;\
                                    text-align: left;\
                                }\
                                .table td {\
                                    padding: .75rem;\
                                    vertical-align: top;\
                                }\
                                span.form-1.kmeval {\
                                    color: #F0AD4E;\
                                }\
                                span.form-2.kmeval {\
                                    color: #1D4E90;\
                                }\
                                span.form-3.kmeval {\
                                    color: #5CB85C;\
                                }\
                                .kmeval {\
                                    padding-left: 10px;\
                                }\
                              </style>` +
													document.getElementById(`pdf-${this.props.student.Id}`).innerHTML +
													'</body>\
                          </html>';
												axios
													.post(
														`https://enablepdfgenerator.azurewebsites.net/home/genZokrates`,
														{ html: encodeURIComponent(html) },
														{
															headers: {
																// Authorization: localStorage.getItem('id_token')
															}
														}
													)
													.then((response) => {
														window.open(
															'https://enablepdfgenerator.azurewebsites.net/home/getPDFZokrates/' +
																response.data,
															'_blank'
														);
													});
											}}
										/>
									</div>
								</div>
							</div>
						</div>
					</div>
				)}

				{this.props.student.Reports.map((rep) => {
					return (
						this.state.activecourse == rep.Courseid && (
							<div
								className="tab-pane"
								id="@rep.Courseid-@item.LoginName.Split('@')[0]"
								role="tabpanel"
								aria-labelledby="home-tab"
							>
								<h5>{rep.Title}</h5>
								<div className="table-responsive">
									<h6>Innleveringer</h6>
									<table className="table table-bordered">
										{rep.Tasks.map((task) => {
											return (
												<tr className="taskrow">
													<td>
														<span className="taskname">{task.WsTask.Name}</span>
													</td>
													<td>
														<span className="taskcomment remove-para-margin ql-editor" style={{backgroundColor: "transparent"}}
															dangerouslySetInnerHTML={{
																__html: task.GradingComment
															}}
														></span>
													</td>
													<td>
														<span className="taskgrade">{task.Grade}</span>
													</td>
												</tr>
											);
										})}
									</table>
								</div>
								<div className="table-responsive">
									<h6>Prøver</h6>
									{rep.Quis && rep.Quis.length > 0 ? (
										<table className="table table-bordered">
											<thead>
												<th>Tittel</th>
												<th>Kommentar</th>
												<th>Karakter</th>
											</thead>
											{rep.Quis.map((qa) => {
												return (
													<tr className="taskrow">
														<td>
															<span className="quizname">{qa.Quiz.Title}</span>
														</td>
														<td>
															<span className="quizcomment remove-para-margin ql-editor" style={{backgroundColor: "transparent"}}
																dangerouslySetInnerHTML={{
																	__html: qa.GradingComment
																}}
															></span>
														</td>
														<td>
															<span className="quizgrade">{qa.Grade}</span>
														</td>
													</tr>
												);
											})}
										</table>
									) : (
										<p style={{ paddingLeft: '5px', color: '#696969' }}>Ingen Prøver</p>
									)}
								</div>
								<div className="table-responsive">
									<h6>Kompetansemål</h6>
									{rep.Kmevals && rep.Kmevals.length > 0 ? (
										<table className="table table-bordered">
											<thead>
												<th>Kompetansemål</th>
												<th>Måloppnåelse</th>
											</thead>
											{rep.Kmevals.map((km) => {
												return (
													<tr className="kmrow">
														<td className="kmtext">{km.Kmtext}</td>
														<td>
															{km.Evals.map((ev) => {
																let MyIcon;
																if (ev === '1') {
																	MyIcon = () => (
																		<Icon
																			iconName="TestBeaker"
																			style={{ color: '#F0AD4E' }}
																		/>
																	);
																} else if (ev === '2') {
																	MyIcon = () => (
																		<Icon
																			iconName="TestBeakerSolid"
																			style={{ color: '#1D4E90' }}
																		/>
																	);
																} else if (ev === '3') {
																	MyIcon = () => (
																		<Icon
																			iconName="TestCase"
																			style={{ color: '#5CB85C' }}
																		/>
																	);
																} 
																else {
																	return(<span></span>)
																	// MyIcon = () => (
																	// 	<Icon
																	// 		iconName="Error"
																	// 		className="ms-IconExample"
																	// 	/>
																	// );
																}
																return (
																	<span className="form-@ev kmeval">
																		<MyIcon />
																	</span>
																);
															})}
														</td>
													</tr>
												);
											})}
										</table>
									) : (
										<p style={{ paddingLeft: '5px', color: '#696969' }}>Ingen Kompetansemål</p>
									)}
								</div>
								<h6>Tilbakemelding</h6>
								<span className="remove-para-margin ql-editor" style={{backgroundColor: "transparent"}}
									dangerouslySetInnerHTML={{ __html: rep.ReportText }}></span>
								<h6>Framovermelding</h6>
								<span className="remove-para-margin ql-editor" style={{backgroundColor: "transparent"}} 
									dangerouslySetInnerHTML={{ __html: rep.Future }}></span>
							</div>
						)
					);
				})}

				<div style={{ display: 'none' }} id={`pdf-${this.props.student.Id}`}>
					<h1>{this.props.student.Name}</h1>
					<Field
						name="BackEval"
						label="Generell tilbakemelding" // Custom fields variables can be fetched in component (i.e. field.label)
						component={this.renderReadOnly}
					/>
					<Field
						name="FutureEval"
						label="Generell framovermelding" // Custom fields variables can be fetched in component (i.e. field.label)
						component={this.renderReadOnly}
					/>
					<Field
						name="BackComment"
						label="Kommentar fra elevsamtale" // Custom fields variables can be fetched in component (i.e. field.label)
						component={this.renderReadOnly}
					/>
					<Field
						name="FutureComment"
						label="Kommentar fra kontaktsamtale" // Custom fields variables can be fetched in component (i.e. field.label)
						component={this.renderReadOnly}
					/>
					<Field
						name="Published"
						label="Publiser til elev og foresatte" // Custom fields variables can be fetched in component (i.e. field.label)
						component={this.renderReadOnly}
					/>
					{this.props.student.Reports.map((rep) => {
						return (
							<div>
								<h2>{rep.Title}</h2>
								<div className="table-responsive">
									<h3>Innleveringer</h3>
									<table className="table table-bordered">
										{rep.Tasks.map((task) => {
											return (
												<tr className="taskrow">
													<td>
														<span className="taskname">{task.WsTask.Name}</span>
													</td>
													<td>
														<span className="taskcomment remove-para-margin ql-editor" style={{backgroundColor: "transparent"}}
															dangerouslySetInnerHTML={{
																__html: task.GradingComment
															}}
														></span>
													</td>
													<td>
														<span className="taskgrade">{task.Grade}</span>
													</td>
												</tr>
											);
										})}
									</table>
								</div>
								<div className="table-responsive">
									<h3>Prøver</h3>
									{rep.Quis && rep.Quis.length > 0 ? (
										<table className="table table-bordered">
											<thead>
												<th>Tittel</th>
												<th>Kommentar</th>
												<th>Karakter</th>
											</thead>{' '}
											:
											{rep.Quis.map((qa) => {
												return (
													<tr className="taskrow">
														<td>
															<span className="quizname">{qa.Quiz.Title}</span>
														</td>
														<td>
															<span className="quizcomment remove-para-margin ql-editor" style={{backgroundColor: "transparent"}}
																dangerouslySetInnerHTML={{
																	__html: qa.GradingComment
																}}
															></span>
														</td>
														<td>
															<span className="quizgrade">{qa.Grade}</span>
														</td>
													</tr>
												);
											})}
										</table>
									) : (
										<p style={{ paddingLeft: '5px', color: '#696969' }}>Ingen Prøver</p>
									)}
								</div>
								<div className="table-responsive">
									<h3>Kompetansemål</h3>
									{rep.Kmevals && rep.Kmevals.length > 0 ? (
										<table className="table table-bordered">
											<thead>
												<th>Kompetansemål</th>
												<th>Måloppnåelse</th>
											</thead>
											{rep.Kmevals.map((km) => {
												return (
													<tr className="kmrow">
														<td className="kmtext">{km.Kmtext}</td>
														<td>
															{km.Evals.map((ev) => {
																const iconSrcs = {
																	TestBeaker:
																		'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACIAAAAiCAYAAAA6RwvCAAAC50lEQVRYR+2XSUwTURzGv9fpTDszQIPgLiAJLsSFSCCGohCMiScPnjRejAc9mJhoPGiCS10wejCaeNOD8WL05MGTFw0oEAPB4BJcSFBwQ0ECOJ2WWZ6Zqa1iy8ybUBNM+q7v3///12/m+94bQimlmAOL/JcgP1qPwRjtY9KPK6pEXuMFplqryJMiSscZGKOvQXUVoCYILwKESwyjBqimAsQH4hfBFa2CHD75b0CsrmbsO5S2ZlB1BGL1QfAlDfYwbagNas9VELEYckMLfMF5zBCeFcmBZNI292j+ViWnSE4RtxTSv/Yi+uSinaByfQRcYYX9E2OsH0p7xE5caeNR+BdUubWatu8p4kEprJjXh3vAL6mzB4KQXxFPbUDtUyf8C6sT8Z7cY0DyBDI1cB9q73UQIQ9yXTO4whXTRhhjb6F0tgCaguCGAxBKmxgQEiXMIHRqEsqjEzDG3yGwcgeCa/dkHBJ7cRPxN3fBhZZD3nwWRMhngmEGiffdQezVbfjkRY6HWjJnTOULgqt3IVC5M3sgxsR7RB9HYE5NQqzaB6F8m2Pz5CP0CfmQNkXAFZS5wjApEu2+Am3wIfzFayDVnwLhAo6NqRFHtP009JGX4EubINUcmj1Iyq7UhFh7BPziWtem9v3kcxfUrku2zVns7KiI/c86zkH/9gx8SSOkmsPslqQU0e7L0IZa4Z+/HlL4uKOSjiApu/o48KVbQAIFTGoki2h8AtrgA1DTcH23ZgT5bdcBT8NnKuZC5Y52nhEkmQckEAJXsGxWMMbEB9D4uGP+ZATxalc3ShY7ZwRJ2ZXhJXODsPZZ7JwG4tV2LCBWjVsMpIHYJ+jHDuYgYgWx6pJK80vDiZP7j5UGYt0p9OGnCFbuZj4nWGHs86rvlp3QcsN5Z5B4/z3Ent+wvulA/JKdjFlZ1ATVowB8CK7bi0DFdmcQ6/Kj9l5LBJEeywpDqgknQCjbCrFqf1pCMx162aXJ3G3OgPwEG1IquVqBwS0AAAAASUVORK5CYII=',
																	TestBeakerSolid:
																		'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACIAAAAiCAYAAAA6RwvCAAAC8UlEQVRYR+2Xe0hTcRTHv1PnXBmTJjGkFOlh0EMq7TV6MuihllhUJFqSI4QZEZZpIzFM00ZEDkbMyDSjp0hqDxi9YPbYKuwB2cu0GCO0HFlTN138rl1hut17B3dgsPPv+e2czz3nfr+/XYHL5XJhHITgvwQpqzFCd8PMaX45WxJQkCnndJYc8mkiuvrnaDZ+QIe1B7/tDsyYOhlhoSFUs74BJz5++4GJYiFiZBFIks9ETtoi/4CQqvZ+J/K1BpjeWVBblErBkCAQGcUNSJwdhXKVAmLRMCDX8GkiARBPYw2sZvRUAhMJTITNgCxdv7DneCPlqFWFKZBKxNRPum12ZJc2Ug577kgKoiInsZVyy/tkaOSe1tWbcaLGiGLlauxOiodAMFyP5KqbW1Gkf4DDmXLkpCWM5LgQ+QTy5tN3ZJXcpGy9Mm89IiUT3Hp02f4gV3MHX6w9qFZvRlyMlAsDdYYzyIBjEEX6h7hieIuqwmSsTYj12OSeuR3ZpU3YrpiDYuUqhAqDOcFwBjG2foWyrAmKxFjGS432GYOpHfqCZMjjp/EH0tPbB5XmNto6unFevQlzp09hLE6vkKxGm7cBEeFhrDCcJkLWcbDSgH3bFmP/jiUICQ5iLOwcHMLpy09x5uoznMxVUGtiC1YQWq4O5xAlyxiZhK0mle+w2iiZC0OCOMmZEYQ8mfa6CacuPUGFavjJaLmy0RA5k0ke0hpwYOdSqLYmMk6SEYTetVgkRH7GckjCRWz93fK23n6U17bA3u9gfbe8gtByrbv72qfm3g6nr5vHKGevILQfkPXwEeQFZ/IfjyC0XB+97OSDYaTGygXRXuXsEYSWK68U/4p5k/MYEFp27zu7/cGBWdFSjzYwBqTm1iuoz973CwRdtGTvGmRunO/WYwyI9poJFRdb/ApCHDovfRkzyIs2K3YdawDxAH8E8aILR1OxME7GDEIckXzfauoe47PlJ68s5F+bOmsF9V082qFZ7xpeSRiKjRuQv9jdCbnUbfpKAAAAAElFTkSuQmCC',
																	TestCase:
																		'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACIAAAAiCAYAAAA6RwvCAAACuElEQVRYR+2XPUhbURTH/6+JSTQ2WhrBahsrFBNIbQjFDk7iB4qjm8ZNHATBTRCdnARdnARBQRB1EDcHwcHFodS2akiTEEJpKYVqbRuVYL585Tx6Q2Je8m58MWboWd+55/7e/3zcewVRFEWUgAklBXISPsH022mEoqGCa9Pd0I1B26BiXEkRBnIZu0RFWQUECIoLlRzi13GE42HcCqRKX4WJlglUllUq7aP4fe/bHpY8S/9BkkrdmyIiRBz/PIa12gqD1oB7ASGIzcAmtj9vw1HjwKhjFPvf94tbI6kQOo0OI69G4KxxFleRbBBUKEVLTS6IooEoQRQMRK/RY92/jjpjHTotnWnDiweCFlwlrnAeOZcmNc+ATBvxbLIenR5h+dMy6GAesA4kYXghFMeujIMsCP3B7tddrPnXpCUE02HpSLZoanewmL7fPiy6FxFJRHJyuGwutD5pzfDJCkKeqTCNpkYEQ0HIQZCv+8yN+Y/ziiBD9iG0PW3LDyQVJnYdQ7m2PDknsv02a9tmczPGX49j1beKnS87yAbA4uRUhDmRMlvBLQy/HJaGVS67UxDWBQaNQbEO7xxEkeCfA6m34l3BzdT0W/vR+7w3axiu1PBCUB3Nvp8FdRB1Wk9DDw5+HGDBvQCzwYzJN5Mw6Uyy4dJAtA+06LJ0SZ2Rj9Hgsz+2y24qBycXOw3kNpdnmsJjzjHYHtky1GAb8qgigRDAhn9Duuzma/XGevS96MPh6WHWFPCoUpB3Dc9GSqoUBIRtQmo2VTfJ1hg9LwJ/AtJhyAo5VX3VIEwN7y8vd1apuG92kGoQpkY0EeUGEQQhQxVVICT33Ic5eM483BDM0fLQkvaYUwVC+Z55NyOdyvlarbEWUy1ToDsQmSoQCkCpoUvURfSCm4UGpsvqQvuz9uQa1SDcuys4lgzIX2Q5lblKoEziAAAAAElFTkSuQmCC',
																	Error:
																		'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACIAAAAiCAYAAAA6RwvCAAAERUlEQVRYR+1XWSh1bRR+jiGEJFHccCHDjeHCdIGEUCRxYR7iwlgkQ0kIEWXMPGXmAimEK9ygyFSGpBRFiYwZ42+9/ft09nHO3ufQ//f99b+3+33Xft5nrfWs55V8fX194Q9Ykv8cECLu/Pwcc3NzWFpawtbWFo6OjhiXlpaWcHR0hI+PDwICAmBrawsNDQ21eBZl5PPzE8vLy6irq8PMzAwvuLu7O7S1tbG9vY2HhwfpN09PTxQUFMDPzw86OjoqARIEcnl5iaKiIvT29sLQ0BBhYWFISkqCs7MzjIyMeD94fHxkgMbGxjA6OoqbmxsEBwejtrYWdnZ2omCUAtnc3ERGRgbW19cRHx+PsrIyWFlZiQakDRcXF2hsbERrayssLCzQ0NDAUiaRSJSeVwhkdXUVaWlpODs7YymJiYmBlpaWSiC4TVRTCwsLyM7OxsvLC/r7++Ht7a06kOPjY8TFxYHS0tbWhsDAQMGbiKHjLqWrq4u+vj7Y29srPMJj5Pn5Gfn5+Qx9e3s7oqKifgWC+yMVe0JCAlxdXdHR0QFjY+NvYHhAFhcXERERwQ7V1NRAT09P6YXf3t4wMTGBu7s7xMbGwsDAQOnej48PVFdXo7i4GENDQyzV8ksK5OnpCZmZmaw4qfIdHBwEWSf2cnJycHp6iuHhYZiYmAjup30E2MzMDD09Pd9YkQLZ3d1lbAQFBTE2xPpfXSBUvPX19SgtLcXU1BR8fX15wKVAKHepqamg9Pj7+4vVINQFQgFJEkJCQpCens70SbadGRDKd2FhIVZWVlharK2t/xEgV1dXiI6Ohrm5OVpaWphIcosBoYJLSUlhMq1KvunwTxgROsOAXF9fs0om5aQ8CnULd4PfAFlbW8P4+DgbjjxG/hgg/3ZqTk5OMDIyAlNTUz4jNAtyc3PZ9KQN5C/EFp2prKxko4DaXZFaysfgmKci7e7u5k3wH7evGFBF3zmtIr0qLy+HpqYmnxHZHqfuKSkp4W1SFJQE6v7+HmScyJuIOTJO0Ih5RVr1TeLJAspXtCIg6nYNeRTyNbQGBgaYlsgu3tCbnp5mFiArK4sZISEPos7QIzaampqYN+ns7GSaJW+SeECIagJBs2BwcBChoaE/KYVvZzgbQAa7q6uLDT6l05f7cHBwwHzp7e0tQ+/l5fUrMFw86i4hl6bQKnI3IFelit9UVswbGxuM4f39fVGjpRAI5XR+fp75VnLjNC2p2mUFSIgm8jbEZkVFBdvW3NyMyMhIwc4SfE4cHh4iLy+PvWdI5JKTkxEeHg4bG5tvhUxtTGZ7cnKS2UF6fLm4uDBGPTw8RC2n6APr9fWVBa+qqsLe3h67ISmjk5OTdDi+v79jZ2eHsUeLhhmlJDExEfr6+irVmCgQLgr5TrKRs7OzLG2kN7KLfk5vF3qEubm5qTTBleqIStD/3qSusorFVpkRsUC//f4/EHkG/wLzRNy5CeDJewAAAABJRU5ErkJggg=='
																};
																let iconSrc;
																if (ev === '1') iconSrc = iconSrcs.TestBeaker;
																else if (ev === '2') iconSrc = iconSrcs.TestBeakerSolid;
																else if (ev === '3') iconSrc = iconSrcs.TestCase;
																//else iconSrc = iconSrcs.Error;
																return (
																	<span className="form-@ev kmeval">
																		<img src={iconSrc} />
																	</span>
																);
															})}
														</td>
													</tr>
												);
											})}
										</table>
									) : (
										<p style={{ paddingLeft: '5px', color: '#696969' }}>Ingen Kompetansemål</p>
									)}
								</div>
								<h3>Tilbakemelding</h3>
								<span className="remove-para-margin ql-editor" style={{backgroundColor: "transparent"}} 
									dangerouslySetInnerHTML={{ __html: rep.ReportText }}></span>
								<h3>Framovermelding</h3>
								<span className="remove-para-margin ql-editor" style={{backgroundColor: "transparent"}}
									dangerouslySetInnerHTML={{ __html: rep.Future }}></span>
							</div>
						);
					})}
				</div>
			</form>
			<Prompt 
				when={this.props.dirty}
				message="Du har ulagrede endringer, er du sikker på at du vil forlate siden?"
			/>
			</span>
		);
	}
}

function validate(values) {
	const errors: any = {};

	return errors;
}

const mapStateToProps = (state, ownProps) => {
	
	const formValues = getFormValues('StudentreportForm')(state);

	return {
		formValues
	};
};

export default reduxForm({
	form: 'StudentreportForm',
	validate,
	enableReinitialize: true
})(
  connect(mapStateToProps, {
    saveIupReportStudentForClass,
    setstatus, 
	getIupReportForClassDraft
  })(withRouter(StudentreportForm))
);
