import * as React from 'react';
import * as _ from 'lodash';
import {connect} from 'react-redux';
import {withRouter, Link} from "react-router-dom";
import {
    DetailsListLayoutMode,
    Selection,
    SelectionMode,
    IColumn,
    DetailsRow
} from 'office-ui-fabric-react/lib/DetailsList';
import { MarqueeSelection } from 'office-ui-fabric-react/lib/MarqueeSelection';
import {Message} from "../../models/Message";
import {ActionButton, CommandBarButton, DefaultButton, Icon, TextField, Modal, Dialog, DialogFooter,DialogType, SpinnerSize, Spinner, Panel, PanelType, GroupedList, elementContains} from "office-ui-fabric-react";
import HeaderView from "../view_containers/HeaderView";
import {ShimmeredDetailsList} from "office-ui-fabric-react/lib/components/DetailsList/ShimmeredDetailsList";

import {archiveMessage, getMessages, checkKvitteringMessages, dearchiveMessage} from "../../actions/Action_Message";
import {getAvailableSchoolClasses} from "../../actions/Action_Teacher";
import {Slide, toast, ToastContainer} from "react-toastify";
import './meldinger.scss';
import { LoadingDialog } from '../loading/LoadingDialog';
import SidebarActionButtonList from "../sidebar/SidebarActionButtonList";
import KvitteringListe from './KvitteringListe';
const INBOX = 'inbox';
const OUTBOX = 'outbox';
const SAVED = 'saved';

export interface IMeldingerViewState {
    searchValue:string;
    selectedTab:string;
    columns: IColumn[];
    messages: Message[];
    selectionDetails: string;
    isModalSelection: boolean;
    isCompactMode: boolean;
    isLoading: boolean;
    isRecipientPanelOpen: boolean;
    awaitingResponse:boolean;
    selectedmessage:any;
    kvitteringerIsLoading: boolean;
    kvitteringsObject: any;
}

export interface IMeldingerViewProps{
    inbox:{};
    outbox:{};
    saved:{};
    kvitteringer: any[];
    teacherstate: any;
    history:any;
    schoolClasses: any[];
    courselist:any[];
    getMessages(teacherState: any);
    archiveMessage(message:Message, callback:()=>void);
    checkKvitteringMessages(messages: Message[]);
    getAvailableSchoolClasses(statedata: any);
    dearchiveMessage(message: Message, callback:() => void);
}

class MeldingerView extends React.Component<IMeldingerViewProps, IMeldingerViewState> {
    private _selection: Selection;

    constructor(props: any) {
        super(props);
        
        const _columns: IColumn[] = [
            {
                key: 'column1',
                name: 'Melding',
                fieldName: 'Title',
                minWidth: 210,
                maxWidth: 450,
                isRowHeader: true,
                isResizable: true,
                isSorted: true,
                isSortedDescending: false,
                sortAscendingAriaLabel: 'Sorted A to Z',
                sortDescendingAriaLabel: 'Sorted Z to A',
                onColumnClick: this.onColumnClick,
                data: 'string',
                onRender: (message: Message) => {
                    return (
                        <div onClick={()=>{
                            this.setState({selectedmessage:message});
                        }}>
                            {message.Title}
                            {/* <Link to={`meldinger/${message.Id}`}>{message.Title}</Link> */}
                        </div>
                    )
                },
                isPadded: true
            },
            {
                key: 'column2',
                name: 'Utløper',
                fieldName: 'DateSortable',
                minWidth: 70,
                maxWidth: 70,
                isResizable: true,
                onColumnClick: this.onColumnClick,
                data: 'date',
                onRender: (item: Message) => {
                    return <span>{item.DateText}</span>;
                },
                isPadded: true
            },
            {
                key: 'column3',
                name: 'Sendt til',
                fieldName: 'Recipients',
                minWidth: 200,
                maxWidth: 300,
                isResizable: true,
                isCollapsable: true,
                data: 'string',
                onColumnClick: this.onColumnClick,
                onRender: (item: Message) => {
                    return <div dangerouslySetInnerHTML={{ __html: item.Recipients}}></div>;
                },
                isPadded: true
            },
            {
                key: 'column4',
                name: 'Kvittert',
                fieldName: 'Kvittering',
                minWidth: 50,
                maxWidth: 50,
                isResizable: true,
                isCollapsable: true,
                data: 'number',
                onColumnClick: this.onColumnClick,
                onRender: (message: Message) => {
                    if(this.messageHasAssociatedKvittering(message) || !this.state.kvitteringerIsLoading){
                        return this.displayKvittering(message);
                    }
                    else{
                        return <span>
                                <Spinner size={SpinnerSize.xSmall}/>
                            </span>
                    }
                },

            },
            {
                key: 'column5',
                name: '',
                fieldName: '',
                minWidth: 85,
                maxWidth: 105,
                isResizable: true,
                isCollapsable: false,
                data: 'number',
                onRender: (message: Message) => {
                    // Cannot archive messages that are already saved
                    if (this.state.selectedTab === SAVED){
                        return(
                            <ActionButton
                                iconProps={{ iconName: 'Undo'}}
                                allowDisabledFocus={true}
                                disabled={false}
                                checked={false}
                                onClick={()=> this.onDearchiveMessage(message, this.state.selectedTab)}
                            >
                                Dearkiver
                            </ActionButton>
                        )
                    }

                    return(
                        <ActionButton
                            iconProps={{ iconName: 'Archive' }}
                            allowDisabledFocus={true}
                            disabled={false}
                            checked={false}
                            onClick={()=> this.onArchiveMessage(message, this.state.selectedTab)}
                        >
                            Arkiver
                        </ActionButton>
                    )
                }
            }
        ];

        this._selection = new Selection({
            onSelectionChanged: () => {
                this.setState({
                    selectionDetails: this.getSelectionDetails(),
                    isModalSelection: this._selection.isModal()
                });
            }
        });

        this.state = {
            searchValue: '',
            selectedTab: OUTBOX,
            isLoading: _.isEmpty(this.props.outbox),
            messages: !_.isEmpty(this.props.outbox) ? _.values(this.props.outbox) : [],
            columns: _columns,
            selectionDetails: this.getSelectionDetails(),
            isModalSelection: this._selection.isModal(),
            isCompactMode: false,
            isRecipientPanelOpen: false,
            awaitingResponse:false,
            selectedmessage:null,
            kvitteringerIsLoading: true,
            kvitteringsObject: {}
        };
    }

    /****************************
            Lifecycle hooks
     ****************************/

    componentWillMount(){
        if(!_.isEmpty(this.props.schoolClasses) && !_.isEmpty(this.props.outbox)){
            this.setState({
                messages: this.addSchoolClassesToList(_.values(this.props.outbox)),
            })
        }
        if(_.isEmpty(this.props.teacherstate.availableSchoolClasses)){
            this.props.getAvailableSchoolClasses(this.props.teacherstate);
        }
        this.props.getMessages(this.props.teacherstate).then(() => {
            this.setState({
                messages: this.addSchoolClassesToList(_.values(this.props.outbox)),
                isLoading: false
            });
        }).then(() => {
            this.props.checkKvitteringMessages(this.state.messages).then(() => {
                    this.setState({
                        kvitteringerIsLoading: false,
                        messages: [...this.state.messages]
                    });
                }
            ).catch(error => {
                this.setState({kvitteringerIsLoading: false})
            });
        }).catch(error => {
            this.setState({
                isLoading: false
            })
        });
    }

    componentDidUpdate(previousProps: any, previousState: IMeldingerViewState) {
        if (previousState.isModalSelection !== this.state.isModalSelection) {
            this._selection.setModal(this.state.isModalSelection);
        }
    }

    /****************************
            Event handlers
     ****************************/

    dismissRecipientPanel(){
        this.setState({
            isRecipientPanelOpen: false,
            kvitteringsObject: {
                SeenRecipientList: [],
                UnSeenRecipientList: []
            }
        });
    }

    onSelectedTab(selectedTab:string):void{
        this.setState({
            selectedTab: selectedTab,
            messages: this.addSchoolClassesToList(_.values(this.props[selectedTab]))
        });
    }

    onSearchValueChanged(event){

        const value = event.target.defaultValue;

        var filteredMessages = this.props[this.state.selectedTab];

        // Filter messages by Title, Text content or Recipients
        filteredMessages = _.filter(filteredMessages, message=>{
           return message.Title.toLowerCase().includes(value.toLowerCase()) ||
                  message.Text.toLowerCase().includes(value.toLowerCase()) ||
                  message.Recipients.toLowerCase().includes(value.toLowerCase());
        });

        this.setState({
            searchValue: value,
            messages: filteredMessages
        });
    }

    onArchiveMessage(message:Message, messageContext):void{

        this.setState({awaitingResponse:true});
        this.props.archiveMessage(message, ()=>{

            this.setState({
                messages: _.values(this.props[messageContext]),
                awaitingResponse:false
            });
        });
    }

    onDearchiveMessage(message:Message, messageContext):void{

        this.setState({awaitingResponse:true});
        this.props.dearchiveMessage(message, ()=>{

            this.setState({
                messages: _.values(this.props[messageContext]),
                awaitingResponse:false,
                isLoading: true
            });

            
            this.props.getMessages(this.props.teacherstate).then(() => {
                this.setState({
                    messages: this.addSchoolClassesToList(_.values(this.props.saved)),
                    isLoading: false,
                    kvitteringerIsLoading: true
                });
            }).then(() => {
                this.props.checkKvitteringMessages(this.state.messages).then(() => {
                        this.setState({
                            kvitteringerIsLoading: false,
                            messages: [...this.state.messages]
                        });
                    }
                ).catch(error => {
                    this.setState({kvitteringerIsLoading: false})
                });
            }).catch(error => {
                this.setState({
                    isLoading: false
                })
            });
        });
    }


    private onMessageRevoked(item: any): void {
        alert(`Item invoked: ${item.Title}`);
    }

    private onColumnClick = (ev: React.MouseEvent<HTMLElement>, column: IColumn): void => {
        const { columns, messages } = this.state;
        let sortedMessages: Message[] = messages.slice();
        const newColumns: IColumn[] = columns.slice();
        const currColumn: IColumn = newColumns.filter((currCol: IColumn, idx: number) => {
            return column.key === currCol.key;
        })[0];
        newColumns.forEach((newCol: IColumn) => {
            if (newCol === currColumn) {
                currColumn.isSortedDescending = !currColumn.isSortedDescending;
                currColumn.isSorted = true;
            } else {
                newCol.isSorted = false;
                newCol.isSortedDescending = true;
            }
        });
        sortedMessages = this.sortMessages(sortedMessages, currColumn.fieldName || '', currColumn.isSortedDescending);
        this.setState({
            columns: newColumns,
            messages: sortedMessages
        });
    };

    onKvitteringClick(SeenRecipientList: any[], UnSeenRecipientList: any[]) {
        this.setState({
            isRecipientPanelOpen: true,
            kvitteringsObject: {
                SeenRecipientList: this.addClassnameToRecipientList(SeenRecipientList),
                UnSeenRecipientList: this.addClassnameToRecipientList(UnSeenRecipientList)
            }
        });
    }

    /****************************
             Helper methods
     ****************************/

    private getSelectionDetails(): string {
        const selectionCount = this._selection.getSelectedCount();

        switch (selectionCount) {
            case 0:
                return 'No messages selected';
            case 1:
                return '1 item selected: ' + (this._selection.getSelection()[0] as any).name;
            default:
                return `${selectionCount} items selected`;
        }
    }

    private messageHasAssociatedKvittering(message: Message){
        let hasMatch = false;
        if(this.props.kvitteringer){
            this.props.kvitteringer.forEach(kvittering => {
                if(kvittering.Id === message.Id){
                    hasMatch = true;
                }
            });
        }
        return hasMatch;
    }

    private addClassnameToRecipientList(listToModify: any[]){
        listToModify.forEach(melding => {
            melding.ClassName = null;
            this.props.schoolClasses.forEach(schoolClass => {
                if(melding.ClassID === schoolClass.ClassID){
                    melding.ClassName = schoolClass.ClassName;
                }
            });
        });

        return listToModify;
    }

    private addSchoolClassesToList(listToModify: any[]) {
        let listToReturn = JSON.parse(JSON.stringify(listToModify));
            listToReturn.forEach(melding => {
                let recipientsNoHtml = melding.Recipients;
                if(recipientsNoHtml.includes("<i class='fa fa-users' aria-hidden='true'></i>")){
                    recipientsNoHtml = recipientsNoHtml.replace("<i class='fa fa-users' aria-hidden='true'></i>", ";");
                }
                if (recipientsNoHtml.includes("<i class='fa fa-user' aria-hidden='true'></i>")){
                    recipientsNoHtml = recipientsNoHtml.replace("<i class='fa fa-user' aria-hidden='true'></i>", ";");
                }
                let recipientList = recipientsNoHtml.split(";")
                recipientList.forEach(recipient => {
                    let recipientDisplayName = recipient;
                    this.props.schoolClasses.forEach(schoolClass => {
                        if(recipient === schoolClass.ClassID){
                            recipientDisplayName = schoolClass.ClassName;
                        }
                    });
                    this.props.courselist.forEach(course => {
                        if(recipient === course.CourseID){
                            recipientDisplayName = course.Title;
                        }
                    });
                    melding.Recipients = melding.Recipients.replace(recipient, recipientDisplayName + " ")
                });
                melding.Recipients = melding.Recipients.replace(/;/g, "  ")
            });
        return listToReturn;
    }

    private sortMessages = (messages: Message[], sortBy: string, descending = false): Message[] => {
        if (descending) {
            return messages.sort((a: Message, b: Message) => {
                if (a[sortBy] < b[sortBy]) {
                    return 1;
                }
                if (a[sortBy] > b[sortBy]) {
                    return -1;
                }
                return 0;
            });
        } else {
            return messages.sort((a: Message, b: Message) => {
                if (a[sortBy] < b[sortBy]) {
                    return -1;
                }
                if (a[sortBy] > b[sortBy]) {
                    return 1;
                }
                return 0;
            });
        }
    };

    displayKvittering(message: Message){
        let messageToCheck = {
            Kvitteringer: 0,
            NumberOfRecipients: 0,
            SeenRecipientList: [],
            UnSeenRecipientList: []
        };

        if(this.state.selectedTab === "outbox" && this.props.kvitteringer.filter(item => item.Id === message.Id).length > 0 && message.Kvittering && message.Foresattportal){
            messageToCheck = this.props.kvitteringer.filter(item => item.Id === message.Id)[0];
            messageToCheck.Kvitteringer = messageToCheck.SeenRecipientList.length;
            messageToCheck.NumberOfRecipients = messageToCheck.SeenRecipientList.length + messageToCheck.UnSeenRecipientList.length;

            if(messageToCheck.NumberOfRecipients < 1){
                return <div></div>
            }
        }
        else{
            return <div></div>
        }

        if (messageToCheck.Kvitteringer == messageToCheck.NumberOfRecipients){
            return <ActionButton onClick={() => this.onKvitteringClick(messageToCheck.SeenRecipientList, messageToCheck.UnSeenRecipientList)} style={{display: "flex", justifyContent: "center", alignItems: "center"}}>
                <span style={{marginRight: "12px"}}>
                    {messageToCheck.Kvitteringer} / {messageToCheck.NumberOfRecipients}  
                </span>
                <Icon iconName={'CheckMark'} className={'ms-fontColor-themePrimary ms-fontSize-md'}/>
            </ActionButton>;
        } else {
            return <ActionButton onClick={() => this.onKvitteringClick(messageToCheck.SeenRecipientList, messageToCheck.UnSeenRecipientList)} style={{display: "flex", justifyContent: "center", alignItems: "center"}}> 
                <span style={{marginRight: "7px"}}>
                {messageToCheck.Kvitteringer} / {messageToCheck.NumberOfRecipients}
                </span>
                <Icon iconName={'StatusCircleErrorX'} className={'ms-fontColor-red ms-fontSize-l'} />
            </ActionButton>;
        }
    }

    /****************************
            Render methods
     ****************************/

    public render() {
        const { columns, isCompactMode, messages, selectionDetails } = this.state;

        return (

           
            <div className="ms-Grid main container">
                {
                    this.state.selectedmessage &&
                    // <Modal isOpen={!!this.state.selectedmessage} onDismiss={()=>{
                    //     this.setState({selectedmessage:null});
                    // }}>
                    //     {this.state.selectedmessage.Title}
                    // </Modal>
                    <Dialog
                    hidden={!this.state.selectedmessage}
                    // isOpen={!!this.state.selectedmessage}
                    onDismiss={()=>{
                        this.setState({selectedmessage:null});
                    }}
                    dialogContentProps={{
                        type: DialogType.normal,
                        title: this.state.selectedmessage.Title,
                        subText: ''
                    }}
                    styles={{
                        main: [{
                            selectors: {
                                ['@media (min-width: 480px)']: {
                                    maxWidth: '600px',
                                    minWidth: '480px'
                                }
                            }
                        }]
                    }}
                    modalProps={{
                        titleAriaId: 'myLabelId',
                        subtitleAriaId: 'mySubTextId',
                        isBlocking: true,
                        containerClassName: 'ms-dialogMainOverride'
                    }}
                >
                    <div className="ms-Grid">
                        <div className="ms-Grid-col ms-sm12">
                        <span 
                            className="taskcomment remove-para-margin ql-editor" style={{backgroundColor: "transparent", wordWrap: "normal", minWidth: 200}}
                            dangerouslySetInnerHTML={{__html:this.state.selectedmessage.Text}}></span>
                        </div>
                       
                    </div>
                    <DialogFooter>
                        <DefaultButton onClick={()=>{this.setState({selectedmessage: null})}} text="Avbryt" />
                        <Link to={`meldinger/${this.state.selectedmessage.Id}`}>Rediger</Link>
                        ***
                        <DefaultButton onClick={()=>{this.props.history.push(`meldinger/${this.state.selectedmessage.Id}`)}} text="Rediger" />    
                    </DialogFooter>
                </Dialog>
                }
                {this.state && this.state.awaitingResponse &&
                 
                    <LoadingDialog title={this.state.selectedTab == SAVED ? "Fjerner melding fra arkiv" : "Arkiverer melding"} description="Vennligst vent"/>
                }
                <ToastContainer transition={Slide}/>

                <HeaderView
                    title="Dashboard for meldinger"
                    description="Her finner du en oversikt over meldinger du har sendt, fått eller lagret."
                    iconName="Message"
                />

                <div className="tab-row">
                    <DefaultButton
                        className={this.state.selectedTab == OUTBOX ? 'selected':''}
                        onClick={()=> this.onSelectedTab(OUTBOX)}>
                        Meldinger fra meg
                    </DefaultButton>
                    <DefaultButton
                        className={this.state.selectedTab == INBOX ? 'selected':''}
                        onClick={()=> this.onSelectedTab(INBOX)}>
                        Meldinger til meg
                    </DefaultButton>
                    <DefaultButton
                        className={this.state.selectedTab == SAVED ? 'selected':''}
                        onClick={()=> this.onSelectedTab(SAVED)}>
                        Lagrede meldinger
                    </DefaultButton>
                </div>

                <div className="ms-Grid-row">
                    <div className="ms-Grid-col ms-hiddenSm ms-md6 ms-xl9"/>
                    <div className="ms-Grid-col ms-sm12 ms-md6 ms-xl3 action-row">
                        <TextField
                            value={this.state.searchValue}
                            disabled={this.state.isLoading}
                            onChange={this.onSearchValueChanged.bind(this)}
                            placeholder="Søk etter meldinger"
                            ariaLabel="Please enter text here"
                        />
                    </div>
                </div>

                <MarqueeSelection selection={this._selection}>
                    <ShimmeredDetailsList
                        items={messages}
                        compact={isCompactMode}
                        columns={columns}
                        selectionMode={this.state.isModalSelection ? SelectionMode.multiple : SelectionMode.none}
                        setKey="set"
                        layoutMode={DetailsListLayoutMode.justified}
                        isHeaderVisible={true}
                        selection={this._selection}
                        selectionPreservedOnEmptyClick={true}
                        //onItemInvoked={this.onMessageRevoked}
                        enableShimmer={this.state.isLoading}
                        shimmerLines={15}
                        enterModalSelectionOnTouch={true}
                    />
                </MarqueeSelection>

                <Panel 
                    isLightDismiss
                    isOpen={this.state.isRecipientPanelOpen}
                    onDismiss={() => this.dismissRecipientPanel()}
                    closeButtonAriaLabel="Lukk Panel"
                    type={PanelType.medium}
                    headerText="Kvitteringsliste"
                >
                    
                    {this.state.kvitteringsObject && <KvitteringListe kvitteringsObject={this.state.kvitteringsObject}>
                    </KvitteringListe>}
                </Panel>

                <SidebarActionButtonList>
                    <CommandBarButton
                        onClick={() => this.props.history.push("/meldinger/ny_melding")}
                        className="sidenav-buttons"
                        iconProps={{ iconName: "Add" }}
                        text="Lag ny melding"
                    />
                </SidebarActionButtonList>
            </div>
        );
    }
}

const mapStateToProps = (state, ownProps) => {

    const {inbox, outbox, saved, kvitteringer} = state.messages;

    let schoolClassList = []
let courseList = [];
    if (!_.isEmpty(state.teacher.availableSchoolClasses)){
        Object.keys(state.teacher.availableSchoolClasses).map(key => {
            schoolClassList.push(state.teacher.availableSchoolClasses[key]);
        });
    }
    if (!_.isEmpty(state.teacher.subscribedSchoolClasses)){
        Object.keys(state.teacher.subscribedSchoolClasses).map(key => {
            schoolClassList.push(state.teacher.subscribedSchoolClasses[key]);
        });
    }
    if (!_.isEmpty(state.teacher.availableCourses)){
        Object.keys(state.teacher.availableCourses).map(key => {
            courseList.push(state.teacher.availableCourses[key]);
        });
    }
    if (!_.isEmpty(state.teacher.subscribedCourses)){
        Object.keys(state.teacher.subscribedCourses).map(key => {
            courseList.push(state.teacher.subscribedCourses[key]);
        });
    }
    return{
        teacherstate: state.teacher,
        inbox,
        outbox,
        saved,
        kvitteringer,
        schoolClasses: schoolClassList,
        courselist:courseList
    }
};

export default withRouter(connect(mapStateToProps, {getMessages, archiveMessage, checkKvitteringMessages, getAvailableSchoolClasses, dearchiveMessage})(MeldingerView));