import * as React from 'react';
import {connect} from 'react-redux';

export interface ISidebarActionButtonList {
    sidebar:boolean;
}


class SidebarActionButtonList extends React.Component<ISidebarActionButtonList>{

    constructor(props){
        super(props);
    }

    render(){

        const className = `sidebar-action-button-view ${this.props.sidebar ? 'docked':''}`;

        return(
            <div className={className}>
                {this.props.children}
            </div>
        )
    }
}


function mapStateToProps(state) {

    return {
        sidebar: state.sidebar,
    };
}

export default connect(mapStateToProps, null)(SidebarActionButtonList);