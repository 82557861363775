import * as React from "react";
import { connect } from "react-redux";
import CourseContainer from "./CourseContainer";
import {
  Dropdown,
  Icon,
  ActionButton,
  PrimaryButton,
  DefaultButton,
  Checkbox,
} from "office-ui-fabric-react";
import { getTeacherState } from "../../actions/Action_Teacher";
import {
  getTaskElement,
  saveStudentEvalVis,
  plagcheckTask,
  downloadStudentFiles
} from "../../actions/Action_Innleveringer";
import {
  submitResource,
  getAssignmentOutcomes,
  getAssignmentSubmissions,
  getAssignmentFull,
  getAllAssignmentOutcomes,
  getAssignmentPlagInfo,
} from "../../actions/Action_Graph";
import InnleveringStatusViewItem from "./InnleveringStatusViewItem";
import "../QuillStyles.scss";
import { CommandBar } from "office-ui-fabric-react/lib/CommandBar";
import { Check } from "office-ui-fabric-react/lib/Check";
import { IContextualMenuItem } from "office-ui-fabric-react/lib/ContextualMenu";
import { MarqueeSelection } from "office-ui-fabric-react/lib/MarqueeSelection";
import {
  ISelection,
  Selection,
  SelectionMode,
  SelectionZone,
} from "office-ui-fabric-react/lib/Selection";
import { Toggle } from "office-ui-fabric-react/lib/Toggle";
import { mergeStyleSets, IRawStyle } from "office-ui-fabric-react/lib/Styling";
import { memoizeFunction } from "office-ui-fabric-react/lib/Utilities";

import axios from "axios";
import EvalForm from "./EvalForm";
import {LoadingDialog} from "../loading/LoadingDialog";
import { IAssignment } from "../../models/graph/IAssignment";
import { TeamAssignment } from "../../models/graph/TeamAssignment";
export interface IInnleveringStatusViewProps {
  getTaskElement(teacherState: any);
  saveStudentEvalVis(studenttasks: any[]);
  plagcheckTask(data: any);
  match: any;
  task: any;
  history: any;
  teamssub: any[];
  plagschecks: any[];
  fullassignment: TeamAssignment;
  getAssignmentOutcomes(classid: string, assignmentid: string, submission: any);
  getAllAssignmentOutcomes(
    classid: string,
    assignmentid: string,
    submission: any[]
  );
  getAssignmentSubmissions(classid: string, assignmentid: string);
  getAssignmentFull(classid: string, assignmentid: string): Promise<any>;
  getAssignmentPlagInfo(classid: string, assignmentid: string);
  downloadStudentFiles(wstask:any);
}
export interface IInnleveringStatusViewState {
  submissions: any[];
  selection: ISelection;
  showstatus: any;
  showremaining: boolean;
  showdownloading:boolean;
}
let selection: ISelection;
class InnleveringStatusView extends React.Component<
  IInnleveringStatusViewProps,
  IInnleveringStatusViewState
> {
    async componentDidMount() {
    console.log("1234hotfixmount", this.props);
    const { id } = this.props.match.params;
    let element = await this.props.getTaskElement(id);
    console.log("777gotelement", element);
    if (
      element.payload.data.TeamsTask &&
      element.payload.data.TeamsTask.length > 0
    ) {

      if (this.props.task.TeamsTask) {
        this.props.history.push(
          "/innleveringerteams/" + this.props.task.Id
        );
      }

      let tid = element.payload.data.TeamsTask.split("|")[0];
      let assid = element.payload.data.TeamsTask.split("|")[1];
        
      let full = await this.props.getAssignmentFull(tid, assid)
      console.log("777full", full);
    }
  }
  constructor(props) {
    super(props);
    let tmp = {};
    if (this.props.task) {
      this.props.task.StudentTasks.forEach((element) => {
        tmp[element.Id] = element.ShowEval;
      });
    }
    this.state = {
      submissions: [],
      selection: new Selection({
        onSelectionChanged: this._onSelectionChanged,
      }),
      showstatus: tmp,
      showremaining: false,
      showdownloading:false,
    };
  }
  componentWillReceiveProps(props) {
    console.log("1234receiveprops", props);
    console.log("hotfixprops", props);
    if (props.task) {
    }
    let tmp = {};
    if (props.task && props.task.StudentTasks) {
      props.task.StudentTasks.forEach((element) => {
        tmp[element.Id] = element.ShowEval;
      });
      console.log("teams", props.task);
      if (props.task.TeamsTask && !props.fullassignment) {
        console.log("teams", "isteams");
      }
    }

    this.setState({ showstatus: tmp });
  }
  private _onSelectionChanged = (): void => {
    // this.forceUpdate();
  };

  onFinishedSavingVurdering = () => {
    console.log("GETTING TASK ELEMENT");
    this.props.getTaskElement(this.props.task.Id);
  };

  render() {
    console.log("renderingid", this.props.match.params);
    console.log("renderingtask", this.props.task);
    // let a =100;
    // let b = a.indexOf('v');
    if (this.props.task && this.props.task.Id != this.props.match.params.id) {
      return <div>laster</div>;
    }
    return (
      <div>
      
        {this.props.task && (
          <div className="ms-Grid">
            <div className="main container" id="status-view">
              <div className="ms-Grid-row">
                <div className="ms-Grid-col ms-lg5">
                  <div className="header">
                    <div className="ms-Grid-row">
                      <div className="ms-Grid-col">
                        <h1 className="ms-fontColor-orangeLight">
                          {" "}
                          <Icon iconName={"CompletedSolid"} /> Innlevering
                        </h1>
                        <h2 className="ms-fontColor-orangeLight zSubTitle ms-font-l">
                          {" "}
                          <b>Oppgave: </b>
                          {this.props.task.Name}
                        </h2>
                        <h2 className="ms-fontColor-orangeLight zSubTitle ms-font-l">
                          {" "}
                          <b>Frist:</b> {this.props.task.Deadlinetekst}
                        </h2>
                        <h2 className="ms-fontColor-orangeLight zSubTitle ms-font-l">
                          <b>Tilretteleggingsgruppe:</b>{" "}
                          {this.props.task.GroupName}
                        </h2>
                        <h2 className="ms-fontColor-orangeLight zSubTitle ms-font-l">
                          <b>Fag:</b> {this.props.task.CourseName}
                        </h2>
                       
                      </div>
                    </div>
                    {/* <div className="ms-Grid-row">
                                            {this.props.children}
                                        </div> */}
                  </div>
                </div>
                <div className="ms-Grid-col ms-lg7">
                  {this.props.task.Text.length > 0 && (
                    <div className="ms-Grid-col ms-sm12 ms-xxl6 text-left ms-fontColor-black studentTaskTopCol">
                      <h4 className=" ms-fontColor-black">Oppgavetekst</h4>
                      <p>
                        {" "}
                        <span
                          className="remove-para-margin"
                          dangerouslySetInnerHTML={{
                            __html: this.props.task.Text,
                          }}
                        ></span>
                      </p>
                      <p>
                        <ul>
                          {this.props.task.TaskFiles &&
                            this.props.task.TaskFiles.map((taskF) => {
                              return (
                                <li>
                                  <a href={taskF.FileUrl} target="_blank">
                                    {taskF.FileName}
                                  </a>
                                </li>
                              );
                            })}
                        </ul>
                      </p>
                    </div>
                  )}
                  {this.props.task.GrepCodeList &&
                    this.props.task.GrepCodeList.length > 0 && (
                      <div className="ms-Grid-col ms-sm12 ms-xxl6 text-left ms-fontColor-black studentTaskTopCol">
                        <h4 className="ms-fontColor-black">Kompetansemål</h4>
                        <ul className="grep-list">
                          {this.props.task.GrepCodeList.map((grp) => {
                            return (
                              <li>
                                <a href={grp.DetailUrl} target="_blank">
                                  {grp.GrepTekst}
                                </a>
                              </li>
                            );
                          })}
                        </ul>
                      </div>
                    )}
                </div>
              </div>
              <div className="ms-Grid-row">
                <div className="ms-Grid-col ms-sm12">
                  <div className="ms-Grid-row ms-bgColor-neutralLight topTool">
                    <div className="ms-Grid-col ms-sm12 ms-md6 ms-lg7 text-left mb-10">
                      <Toggle
                        defaultChecked={false}
                        onText="Alle er valgt"
                        offText="Velg alle"
                        onChanged={(newVal) => {
                          let tmp = this.state.showstatus;
                          this.props.task.StudentTasks.forEach((element) => {
                            tmp[element.Id] = newVal;
                          });
                          this.setState({ showstatus: tmp });
                        }}
                      />
                    </div>

                    <div className="ms-Grid-col ms-sm12">
                      <ActionButton
                        onClick={() => {
                          this.props.history.push(
                            "/task/distribute/" + this.props.task.Id
                          );
                        }}
                        iconProps={{ iconName: "RecurringTask" }}
                        className="ms-font-l"
                        text="Del ut"
                      />
                      <ActionButton
                        iconProps={{ iconName: "Send" }}
                        className="ms-font-l"
                        text="Send vurdering til elev(er)"
                        onClick={() => {
                          let data = [];
                          this.props.task.StudentTasks.forEach((element) => {
                            element.ShowEval = this.state.showstatus[
                              element.Id
                            ];
                            data.push({
                              Id: element.Id,
                              ShowEval: element.ShowEval,
                            });
                          });
                          this.props.saveStudentEvalVis(data).then(() => {
                            this.props.getTaskElement(this.props.task.Id);
                          });
                        }}
                      />
 <ActionButton
                        iconProps={{ iconName: "CloudDownload" }}
                        className="ms-font-l"
                        text="Hent innleverte filer"
                        onClick={() => {
                          this.setState({showdownloading:true});
                          this.props.downloadStudentFiles(this.props.task).then(()=>{
                            console.log('ferdig');
                            this.setState({showdownloading:false});
                          })
                        }
                        }
                      />
                      {
this.state.showdownloading &&
<LoadingDialog title="Laster inn innleverte filer til din OneDrive" description="Vennligst vent"/>
                      }

                      {/* <ActionButton
                        onClick={() => {}}
                        iconProps={{ iconName: "TeamsLogo" }}
                        className="ms-font-l"
                        text="Hent vurderinger fra Teams"
                      /> */}
                    </div>
                  </div>
                </div>
              </div>
              <div className="ms-Grid-row">
                <ActionButton
                  iconProps={{ iconName: "Refresh" }}
                  className="ms-font-l"
                  text="Oppdater plagiatkontroll"
                  onClick={() => {
                    this.props.plagcheckTask(this.props.task);
                  }}
                />
                <ActionButton
                  iconProps={{ iconName: "Refresh" }}
                  className="ms-font-l"
                  text="Oppdater"
                  onClick={() => {
                    this.props.getTaskElement(this.props.task.Id);
                  }}
                />
                <Toggle
                  checked={this.state.showremaining}
                  onText="Vis til retting"
                  offText="Vis alle"
                  onChanged={(newVal) => {
                    console.log("toggleval", newVal);
                    this.setState({ showremaining: newVal });
                  }}
                />
                {this.props.task.TeamsTask &&
                  this.props.task.TeamsTask.length > 0 && (
                    <ActionButton
                      iconProps={{ iconName: "TeamsLogo" }}
                      className="ms-font-l"
                      text="Oppdater plagiatkontroll fra Teams"
                      onClick={() => {
                        let tid = this.props.task.TeamsTask.split("|")[0];
                        let assid = this.props.task.TeamsTask.split("|")[1];

                        this.props.getAssignmentPlagInfo(tid, assid);
                        // this.props
                        //   .getAssignmentSubmissions(tid, assid)
                        //   .then((submissions) => {
                        //     submissions.payload.value.forEach((element) => {
                        //       this.props.getAssignmentOutcomes(
                        //         tid,
                        //         assid,
                        //         element
                        //       );
                        //     });
                        //   });
                      }}
                    />
                  )}
              </div>
              <div className="ms-Grid-row">
                <ul className="no-type-list">
                  {/* {
                                            this.props.task.StudentTasks.map((studenttask) => {
                                                return (
                                                    <InnleveringStatusViewItem studenttask={studenttask} history={this.props.history} task={this.props.task}></InnleveringStatusViewItem>
                                                )
                                            }
                                            )

                                        } */}
                </ul>
                <div className="ms-SelectionBasicExample">
                  {/* <CommandBar items={this.props.task.StudentTasks} /> */}
                  {/* <MarqueeSelection selection={selection} isEnabled={true}>
                                            <SelectionZone selection={selection} > */}
                  {this.props.task.StudentTasks.map(
                    (item: any, index: number) => {
                      console.log("teamsonetask", item);
                      console.log("teamsonetask", this.props.task);
                      console.log("88", item);
                      console.log("88", this.props.fullassignment);
                      let sign = "";
                      if (this.props.plagschecks) {
                        this.props.plagschecks.forEach((element) => {
                          if (element.Id == item.Id) {
                            sign = element.UrkundErrorMessage;
                          }
                        });
                      }

                      if (
                        item.DeliveryStatus == "2" &&
                        this.state.showremaining
                      ) {
                        return <></>;
                      }

                      return (
                        <div>
                          {/* <Check checked={this.state.showstatus[item.Id]} /> */}
                          <Checkbox
                            checked={this.state.showstatus[item.Id]}
                            label=""
                            onChange={(e, val) => {
                              let tmp = this.state.showstatus;
                              tmp[item.Id] = val;
                              this.setState({ showstatus: tmp });
                            }}
                          />
                          {/* <EvalForm
                            form={"taskform-" + item.Id}
                            studenttask={item}
                            fromprops={item}
                            showeval={false}
                            singleview={false}
                          ></EvalForm> */}
                          <InnleveringStatusViewItem
                            key={item.key}
                            itemIndex={index}
                            selection={selection}
                            studenttask={item}
                            history={this.props.history}
                            task={this.props.task}
                            showeval={this.state.showstatus[item.Id]}
                            sign={sign}
                            onSaveFinished={this.onFinishedSavingVurdering}
                          ></InnleveringStatusViewItem>
                        </div>
                      );
                    }
                  )}

                  {/* //     </SelectionZone>
                                        // </MarqueeSelection> */}
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}

function mapStateToProps(state,ownprops) {
  console.log('1234props',ownprops);
  console.log("1234state", state.tasks.task);
  console.log(
    "tttstatecurrentassignment",
    state.GraphReducer.currentassignment
  );
  if (state.tasks.task && state.tasks.task.Id==ownprops.match.params.id) {
    if (state.tasks.task.TeamsTask && state.tasks.task.TeamsTask.length > 0) {
      if (state.GraphReducer.currentassignment) {
        
        let thetask = JSON.parse(JSON.stringify(state.tasks.task));
        thetask.StudentTasks.forEach(element => {
          // element.submission = state.GraphReducer.currentassignment.submissionsz[element.LoginName];
        });
        console.log("tttstudenttasks", thetask.StudentTasks);
        return {
          task: thetask,
          plagschecks: state.tasks.plagcheck,
          fullassignment: state.GraphReducer.currentassignment,
        };
      }
      else{
        return{
          task:null
        }
      }
    } else {
      return {
        task: state.tasks.task,
        plagschecks: state.tasks.plagcheck,
        fullassignment: state.GraphReducer.currentassignment,
      };
    }
  }
  else{
    console.log('1234else');
    return {
      task: null
    }
  }
}

export default connect(mapStateToProps, {
  getTaskElement,
  saveStudentEvalVis,
  plagcheckTask,
  getAssignmentOutcomes,
  getAssignmentSubmissions,
  getAssignmentFull,
  getAllAssignmentOutcomes,
  getAssignmentPlagInfo,
  downloadStudentFiles,
})(InnleveringStatusView);
