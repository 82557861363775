import * as React from "react";
import ReactQuill from "react-quill";
import { connect } from "react-redux";
import {
  ActionButton,
  PrimaryButton,
  MessageBar,
  MessageBarType,
  warnConditionallyRequiredProps,
  DefaultButton,
  Icon
} from "office-ui-fabric-react";
import {
  getIupReportStudentForCourse,
  saveIupReportStudentForCourse,
  getIupReportForCourse
} from "../../actions/Action_Iup";
import { Prompt } from "react-router-dom";
import { setstatus } from "../../actions/Action_Teacher";
import { Student } from "../../models/Student";
export interface IIupCourseReportItemViewProps {
  getIupReportStudentForCourse(iup: any);
  saveIupReportStudentForCourse(iup: any);
  getIupReportForCourse(iup: any);
  setstatus(statedata: any);
  item: any;
  report: any;
  setFormStatus(isDIrty: boolean);
}
export interface IIupCourseReportItemViewState {
  selectedtaskid: string;
  selectedquizid: string;
  backText: string;
  futureText: string;
}
let t = "";
let f = "";
let el = "";
let k = "";
let back = "";
let future: "";
let kmcoll = [];
let taskcoll = [];
let quizcoll = [];
class IupCourseReportItemView extends React.Component<
  IIupCourseReportItemViewProps,
  IIupCourseReportItemViewState
> {
  constructor(props) {
    super(props);
    this.state = { selectedtaskid: "-1", selectedquizid: "-1", backText: null, futureText: null };
    taskcoll = [];
    quizcoll = [];
  }
  handleChangeQuill = (html: string) => {
    back = html;
    if (this) {
      if (html == "<p><br></p>") {
        this.setState({ backText: null });
      }
      else {
        this.setState({ backText: html });
      }
    }

    this.props.setFormStatus(this.isFormDirty());
  }
  handleChangeQuillFuture = (html) => {
    future = html;
    if (this) {
      if (html == "<p><br></p>") {
        this.setState({ futureText: null });
      }
      else {
        this.setState({ futureText: html });
      }
    }
    this.props.setFormStatus(this.isFormDirty());
  }

  isFormDirty = (): boolean => {
    if (this.state.backText != this.props.report.IupReport.ReportText || this.state.futureText != this.props.report.IupReport.Future) {
      return true;
    }
    return false;
  }

  componentWillMount() {

  }
  componentWillReceiveProps(a: IIupCourseReportItemViewProps) {
    taskcoll = [];
    quizcoll = [];
    if (a.report.IupReport.Studentid != this.props.report.IupReport.Studentid) {
      this.setState({ backText: a.report.IupReport.ReportText, futureText: a.report.IupReport.Future }, () => {
        this.props.setFormStatus(this.isFormDirty());
      });
    }
  }



  componentDidMount() {
    this.setState({ backText: this.props.report.IupReport.ReportText, futureText: this.props.report.IupReport.Future });
  }
  public render() {
    console.log('kmcolltop',kmcoll);
    kmcoll =[];
    taskcoll=[];
    quizcoll=[];
    return (
      <div>
        {this.props.report && (
          <div>
            <h5>{this.props.report.Title}</h5>

            <div className="table-responsive">
              <h6>Innleveringer</h6>
              <table className="table table-hover table-bordered">
                <thead>
                  <tr>
                    <th>Tittel</th>
                    <th>Kommentar</th>
                    <th>Karakter</th>
                  </tr>
                </thead>
                {this.props.report.StudentTasks.map(task => {
                  if (task.WsTask.TeamsTask && task.WsTask.TeamsTask.length > 0) {
                    return (<></>);
                  }
                  let t: any = {};
                  t.WsTask = {};
                  t.WsTask.Name = task.WsTask.Name;
                  t.GradingComment = task.GradingCommentDisplay;
                  t.Grade = `${task.Grade} ${task.GradeModifier}`;
                  // taskcoll.push(task);
                  taskcoll.push(t);
                  let classn = '';
                  if (this.state.selectedtaskid === task.Id.toString()) {
                    classn = 'table-info';
                  }
                  return (
                    <tr
                      className={`taskrow ${classn}`}
                      onClick={() => {
                        this.setState({ selectedtaskid: task.Id.toString() });
                      }}
                    >
                      <td>
                        <span className="taskname">{task.WsTask.Name}</span>
                      </td>
                      <td>
                        <span className="remove-para-margin ql-editor" style={{ backgroundColor: "transparent" }}
                          dangerouslySetInnerHTML={{
                            __html: task.GradingCommentDisplay
                          }}
                        ></span>
                      </td>
                      <td>
                        <span className="taskgrade">
                          {task.Grade}{"  "} {task.GradeModifier}
                        </span>
                      </td>
                    </tr>
                  );
                })}
              </table>
            </div>

            <div className="table-responsive">
              <h6>Prøver</h6>
              <table className="table table-hover table-bordered">
                <thead>
                  <tr>
                    <th>Tittel</th>
                    <th>Kommentar</th>
                    <th>Karakter</th>
                  </tr>
                </thead>
                {this.props.report.Studentquizattempts.map(qa => {
                  var quiz: any = {};
                  quiz.Quiz = {};
                  quiz.Quiz.Title = qa.Quiz.Title;
                  quiz.GradingComment = qa.GradingComment;
                  quiz.Grade = `${qa.Grade} ${qa.GradeModifier}`;
                  quizcoll.push(quiz);
                  let classn = '';
                  if (this.state.selectedquizid === qa.Quiz.Id.toString()) {
                    classn = 'table-info';
                  }
                  return (
                    <tr className={`quizrow ${classn}`}>
                      <td>
                        <span className="quizname">{qa.Quiz.Title}</span>
                      </td>
                      <td>
                        <span className="remove-para-margin ql-editor" style={{ backgroundColor: "transparent" }}
                          dangerouslySetInnerHTML={{
                            __html: qa.GradingComment
                          }}
                        ></span>
                      </td>
                      <td>
                        <span className="quizgrade">
                          {qa.Grade} {qa.GradeModifier}
                        </span>
                      </td>
                    </tr>
                  );
                })}
              </table>
            </div>

            <div className="table-responsive">
              <table className="table table-bordered">
                <thead>
                  <tr>
                    <th scope="col">Kompetansemål</th>
                    <th scope="col">Måloppnåelse</th>
                  </tr>
                </thead>
                {Object.keys(this.props.report.Uips).map(km => {
                  var onekm: any = {};

                  onekm.Kmtext = this.props.report.Uips[km][0].Kmtext;
                  var allevals = [];
                  this.props.report.Uips[km].forEach(ev => {
                    var oneeval: any = {};

                    allevals.push(ev.Eval);
                  });
                  onekm.Evals = allevals;
                  console.log('kmcoll', kmcoll);
                  kmcoll.push(onekm);

                  let rowclass = "";
                  this.props.report.Uips[km].forEach(element => {

                    if (
                      element.Quizid === this.state.selectedquizid ||
                      element.Taskid === this.state.selectedtaskid
                    ) {
                      rowclass = "table-info";
                    }
                  });
                  return (
                    <tr className={`kmrow ${rowclass}`}>
                      <td className="kmtext">
                        {this.props.report.Uips[km][0].Kmtext}
                      </td>
                      <td>
                        {this.props.report.Uips[km].map(ev => {
                          let classn = "";
                          if (
                            ev.Quizid === this.state.selectedquizid ||
                            ev.Taskid === this.state.selectedtaskid
                          ) {
                            classn = "active-kmeval";
                          }
                          let MyIcon;
                          if (ev.Eval === '1') {
                            MyIcon = () => <Icon iconName="TestBeaker" style={{ color: '#F0AD4E' }} />;
                          }
                          else if (ev.Eval === '2') {
                            MyIcon = () => <Icon iconName="TestBeakerSolid" style={{ color: '#1D4E90' }} />;
                          }
                          else if (ev.Eval === '3') {
                            MyIcon = () => <Icon iconName="TestCase" style={{ color: '#5CB85C' }} />;
                          }
                          else {
                            return (<span></span>);
                            // MyIcon = () => <Icon iconName="Error" className="ms-IconExample" />;

                          }


                          return (
                            <span
                              className={`form-@ev.Eval kmeval ${classn}`}
                              data-evalvalue="@ev.Eval"
                            >
                              <MyIcon></MyIcon>
                            </span>
                          );
                        })}
                      </td>
                    </tr>
                  );
                })}
              </table>
            </div>
            <h6>Tilbakemelding</h6>
            <div id="quillreport">
              <ReactQuill
                value={this.state.backText}

                onChange={this.handleChangeQuill}
              ></ReactQuill>
              {/* @Html.Raw(Model.IupReport.ReportText) */}
            </div>
            <h6>Framovermelding</h6>
            <div id="quillfuture">
              <ReactQuill
                value={this.state.futureText}
                onChange={this.handleChangeQuillFuture}
              ></ReactQuill>
              {/* @Html.Raw(Model.IupReport.Future) */}
            </div>
            <div className="iup-buttons-footer">
              {!this.props.report.Iups.Published &&
                // this.props.report.Iups.Id == 0 && (
                (
                  <div>
                    <DefaultButton
                      text="Lagre vurdering"
                      onClick={() => {
                        let save: any = {};
                        save.ReportText = this.state.backText;
                        save.Future = this.state.futureText;
                        save.Id = this.props.report.IupReport.Id;

                        save.StudentID = this.props.report.IupReport.Studentid;
                        save.CourseID = this.props.report.IupReport.Courseid;
                        save.Iupheaderid = this.props.report.IupReport.Iupheaderid;
                        save.IUPID = this.props.report.IupReport.Iupid;
                        save.KM = JSON.stringify(kmcoll);
                        save.Taskstring = JSON.stringify(taskcoll);
                        save.Quisstring = JSON.stringify(quizcoll);
                        save.Title = this.props.report.Title;

                        save.Published = false;
                        console.log('taskcoll', taskcoll);
                        this.props
                          .saveIupReportStudentForCourse(save)
                          .then(() => {
                            this.props.setstatus({ working: false, text: 'kladd lagret' });
                            this.props
                              .getIupReportForCourse({
                                Id: this.props.report.IupReport.Iupheaderid
                              })
                              .then(() => {
                                this.props.getIupReportStudentForCourse(
                                  this.props.report.IupReport
                                );
                              });
                          });
                      }}
                    />
                    <PrimaryButton
                      text="Lagre og publiser til kontaktlærer"
                      onClick={() => {
                        let save: any = {};
                        save.ReportText = this.state.backText;
                        save.Future = this.state.futureText;
                        save.Id = this.props.report.IupReport.Id;
                        save.StudentID = this.props.report.IupReport.Studentid;
                        save.CourseID = this.props.report.IupReport.Courseid;
                        save.Iupheaderid = this.props.report.IupReport.Iupheaderid;
                        save.IUPID = this.props.report.IupReport.Iupid;
                        save.KM = JSON.stringify(kmcoll);
                        save.Taskstring = JSON.stringify(taskcoll);
                        save.Quisstring = JSON.stringify(quizcoll);
                        save.Title = this.props.report.Title;

                        save.Published = true;
                        this.props
                          .saveIupReportStudentForCourse(save)
                          .then(() => {
                            this.props.setstatus({ working: false, text: 'publisert' });
                            this.props
                              .getIupReportForCourse({
                                Id: this.props.report.IupReport.Iupheaderid
                              })
                              .then(() => {
                                this.props.getIupReportStudentForCourse(
                                  this.props.report.IupReport
                                );
                              });
                          });
                      }}
                    />
                  </div>
                )}

              {this.props.report.Iups.Published &&
                this.props.report.Iups.Id > 0 && (
                  <div>
                    <MessageBar
                      messageBarType={MessageBarType.warning}
                      isMultiline={false}
                    >
                      Kontaktlærer har startet arbeidet med IUP, og endringer
                      kan ikke gjøres for dette faget
                    </MessageBar>
                  </div>
                )}
            </div>
          </div>
        )}
        <Prompt
          when={this.isFormDirty()}
          message="Du har ulagrede endringer, er du sikker på at du vil forlate siden?"
        />
      </div>
    );
  }
}
function mapStateToProps(state) {
  if (state.Iup.iupstudentcoursereport) {
    future = state.Iup.iupstudentcoursereport.IupReport.Future;
    back = state.Iup.iupstudentcoursereport.IupReport.ReportText;
  }

  return {
    report: state.Iup.iupstudentcoursereport
  };
}

export default connect(
  mapStateToProps,
  {
    getIupReportStudentForCourse,
    saveIupReportStudentForCourse,
    getIupReportForCourse,
    setstatus
  }
)(IupCourseReportItemView);
