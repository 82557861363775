import * as React from 'react';
import { cloneDeep } from 'lodash';
import { withRouter, Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { Field, reduxForm, change, getFormValues } from 'redux-form';
import { Icon, PrimaryButton, Stack } from 'office-ui-fabric-react';
import { DefaultButton, Dropdown, TextField, Label, ActionButton } from 'office-ui-fabric-react';
import DatePicker from 'react-datepicker';
import * as moment from 'moment';
import ReactTooltip from 'react-tooltip';
import { DATE_FORMAT, getMaxTextCharacterAmount, LOCALE, scheduleUtils, TIME_FORMAT } from './ScheduleElementDetailView';
import {
	updateScheduleElement,
	deleteScheduleElement,
	rememberTimeForNextElement,
	clearScheduleElements
} from '../../actions/Action_ScheduleElement';
import '../../../../src/app/formelements.scss';
import { DATE_TIME_FORMAT } from '../../utils/DateUtils';
import { Moment } from 'moment';
import { setScheduleCalendarContext } from '../../actions/Action_Filtervalues';
import './timeplan.scss';
import { validateClaimsRequest } from 'msal/lib-commonjs/AuthenticationParameters';
import axios from 'axios';
import { ROOT_URL, SP_HOST_URL } from '../../actions/constants';
import { ClassAssignmentGroup } from '../../models/ClassAssignmentGroup';
import SelectField from '../form_fields/SelectField';

export interface IScheduleElementFormProps {
	onemitform: (formvalues: any) => void;
	scheduleElements: {};
	courses: any;
	subscribedSchoolClasses: any;

	formValues: any;
	initialValues: any;
	handleSubmit: any;
	handleSubmitContinue: any;
	history: any;
	match: any;
	deleteScheduleElement(se, callback);
	clearScheduleElements(classID: string);
	selectedclass: string;
	setScheduleCalendarContext(selectedclass: string, date: Date);
	rememberTimeForNextElement(date: any);
	rememberedTime: string;
	change: (key: string, value) => void;
	maxTextCharacters: number;
	currentCharacters: number;
}

interface IScheduleElementFormState {
	isEditing: boolean;
	classAssignmentGroups: ClassAssignmentGroup[];
	loadingClassAssignmentGroups: boolean;
}

class ScheduleElementForm extends React.Component<IScheduleElementFormProps, IScheduleElementFormState> {
	constructor(props) {
		super(props);

		const { id } = this.props.match.params;
		
		this.state = {
			isEditing: !isNaN(id),
			classAssignmentGroups: [],
			loadingClassAssignmentGroups: false
		};
	}

	async componentDidMount(): Promise<void> {
		if (this.props.initialValues) {
			
			let classAssignmentGroups: ClassAssignmentGroup[] = [];
			if (this.props.initialValues.ClassID) {
				classAssignmentGroups = await this.getAndSetClassAssignmentGroups(this.props.initialValues.ClassID);
			}

			if (this.props.initialValues.TilretteleggingsgruppeId) {
				this.props.change("TilretteleggingsgruppeId", this.props.initialValues.TilretteleggingsgruppeId);
			}
			else {
				this.props.change("TilretteleggingsgruppeId", 0);
			}
		}

	}

	/****************************
     Event handlers
     ****************************/

	onFormSubmit(values) {
		//Husker- og setter inn siste end date på ny start date sessionbasert
		
		let endTime = `${values.Start.substring(0, 11)}${values.End}`;
		this.props.rememberTimeForNextElement(endTime);
		const requestValues = this.formatFormValueDatesToDateString(cloneDeep(values));
		
		let prevWeek = moment(this.props.initialValues.Start, DATE_TIME_FORMAT).week().toString();
		let newWeek = moment(requestValues.Start, DATE_TIME_FORMAT).week().toString();
		
		if (this.props.initialValues.Id == 0 || prevWeek === newWeek) {
			prevWeek = "";
		}
		
		updateScheduleElement(requestValues, prevWeek, () => {
			const { history, setScheduleCalendarContext } = this.props;

			this.navigateToTimeplan(values);
		});
	}

	onFormSubmitContinue(values) {
		//Husker- og setter inn siste end date på ny start date sessionbasert
		let endTime = `${values.Start.substring(0, 11)}${values.End}`;
		this.props.rememberTimeForNextElement(endTime);
		const requestValues = this.formatFormValueDatesToDateString(cloneDeep(values));
		
		let prevWeek = moment(this.props.initialValues.Start, DATE_TIME_FORMAT).week().toString();
		let newWeek = moment(requestValues.Start, DATE_TIME_FORMAT).week().toString();
		
		if (this.props.initialValues.Id === 0 && prevWeek === newWeek) {
			prevWeek = "";
		}
		updateScheduleElement(requestValues, prevWeek, () => {
			this.props.onemitform(this.props);
		});
		document.getElementById('timeplanSavedNotification').className = 'timeplanSavedNotification';
		setTimeout(() => {
			document.getElementById('timeplanSavedNotification').classList.remove('timeplanSavedNotification');
			document.getElementById('timeplanSavedNotification').className = 'timeplSavedNotif';
		}, 2000);
	}

	onCancelClick(values: any) {
		console.log("VALUES:", values)
		this.navigateToTimeplan(values);
	}

	onDeleteClick(values: any) {
		const { id } = this.props.match.params;

		if (isNaN(id)) {
			return;
		}

		if (confirm('Vil du virkelig slette dette timeplanelementet?')) {
			this.props.deleteScheduleElement(this.props.scheduleElements[id], () => {
				this.navigateToTimeplan(values);
			});
		}
	}

	/****************************
     Helper methods
     ****************************/

	addClassesToColourList(colourList: any[]): any[] {
		colourList.forEach((element) => {
			if (element.schoolClass != '' && !element.text.includes(element.schoolClass)) {
				element.text = `${element.text} (${element.schoolClass})`;
			}
		});
		return colourList;
	}

	formatFormValueDatesToDateString(values) {
		values.End = `${values.Start.substring(0, 11)}${values.End}`;
		values.Links = values.Links;

		const startTime = moment(values.End, DATE_TIME_FORMAT);
		const endTime = moment(values.Start, DATE_TIME_FORMAT);

		values.DisplayLength = this.getV2DisplayLengthCssClass(startTime, endTime);

		return values;
	}

	getClassAssignmentGroups = async (classId: string): Promise<ClassAssignmentGroup[]> => {
		const request = await axios.get(`${ROOT_URL}/GetClassAssignmentGroups/${classId}${SP_HOST_URL}`,{
			headers: {
				Authorization: localStorage.getItem('id_token')
			}
		});

		return request.data as ClassAssignmentGroup[];
	}

	getAndSetClassAssignmentGroups = async (classId: string): Promise<ClassAssignmentGroup[]>=> {
		this.setState({loadingClassAssignmentGroups: true});
		let classAssignmentGroups = await this.getClassAssignmentGroups(classId);

		this.setState({classAssignmentGroups: classAssignmentGroups, loadingClassAssignmentGroups: false});
		return classAssignmentGroups;
	}



	// For backwards compatibility
	getV2DisplayLengthCssClass = (startTime: Moment, endTime: Moment): string => {
		const differenceInHours = startTime.diff(endTime, 'hours');

		switch (differenceInHours) {
			case 2:
				return 'two-hours';
			case 3:
				return 'three-hours';
			case 4:
				return 'four-hours';
			case 5:
				return 'five-hours';
			case 6:
				return 'six-hours';
			case 7:
				return 'seven-hours';
			case 8:
				return 'eight-hours';
			case 9:
				return 'nine-hours';
			case 10:
				return 'ten-hours';
			default:
				return 'one-hour';
		}
	};

	navigateToTimeplan = (values: any) => {
		console.log('navigating',values);
		this.props.clearScheduleElements(values.ClassID);
		this.props.setScheduleCalendarContext(values.ClassID, moment(values.Start, DATE_TIME_FORMAT).toDate());
		this.props.history.push('/timeplan');
	}

	/****************************
     Render methods
     ****************************/

	renderTimeField = (field) => {
		const {
			input,
			rememberedTime,
			meta: { touched, error }
		} = field;
		let selectedDate = input.value ? moment(input.value, TIME_FORMAT) : null;

		if (rememberedTime && !this.state.isEditing) {
			let formatTime = moment(rememberedTime, 'DD.MM.YYYY HH:mm').add(30, 'minutes').format('LT');
			if (!field.meta.dirty) {
				this.props.change('End', formatTime);
			}
		}
		return (
			<div className="ms-Grid-row">
				<div className="z-form-group">
					<div className="ms-Grid-col ms-sm12 ms-md4">
						<Label className="z-form-label">
							{field.label}
							<span>{error ? <span style={{color: "rgb(168, 0, 0)"}}> *</span> : ''}</span>
						</Label>
					</div>
					<div className="ms-Grid-col ms-sm12 ms-md8">
						<DatePicker
							{...input}
							selected={moment(selectedDate, TIME_FORMAT)}
							onChange={(date) => {
								input.onChange(date.locale(LOCALE).format(TIME_FORMAT));
								// this.setState({
								// 	pickedTime: date
								// });
							}}
							timeFormat="HH:mm"
							timeIntervals={5}
							minTime={moment().hours(8).minutes(0)} // ScheduleElements cannot start before 8:00AM
							maxTime={moment().hours(16).minutes(0)} // ScheduleElements cannot end after 16:00AM
							filterDate={(date) => {
								return date.day() !== 0 && date.day() !== 6;
							}} // Weekends are disabled
							locale="nb-NO"
							dateFormat="LT"
							timeCaption="Tid"
							showTimeSelect
							showTimeSelectOnly
							autocomplete="off"
							onBlur={() => {
								console.log('bluring');
							}}
						/>
						{touched && error && <span className="ms-TextField-errorMessage errorMessage-88">{error}</span>}
					</div>
				</div>
			</div>
		);
	};

	renderDateTimeField = (field) => {
		const {
			input,
			meta: { touched, error },
			rememberedTime
		} = field;
		let selectedDate = field.input.value ? moment(field.input.value, DATE_FORMAT) : null;
		if (rememberedTime && !this.state.isEditing) {
			selectedDate = moment(rememberedTime, DATE_FORMAT);
			let formatDate = selectedDate.format(DATE_FORMAT);
			if (!field.meta.dirty && !field.meta.touched) {
				this.props.change('Start', formatDate);
			}
		}

		return (
			<div className="ms-Grid-row">
				<div className="z-form-group">
					<div className="ms-Grid-col ms-sm12 ms-md4">
						<Label className="z-form-label">
							{field.label}
							<span>{error ? <span style={{color: "rgb(168, 0, 0)"}}> *</span> : ''}</span>
						</Label>
					</div>
					<div className="ms-Grid-col ms-sm12 ms-md8">
						<DatePicker
							{...input}
							selected={
								moment(input.value, 'DD.MM.YYYY HH:mm') || moment(selectedDate, 'DD.MM.YYYY HH:mm')
							}
							onChange={(date) => {
								// input.onChange(date.locale(LOCALE).format(DATE_FORMAT));
								this.props.change('Start', date.format(DATE_FORMAT));
							}}
							timeFormat="HH:mm"
							timeIntervals={5}
							minTime={moment().hours(7).minutes(0)} // ScheduleElements cannot start before 8:00AM
							maxTime={moment().hours(16).minutes(0)} // ScheduleElements cannot end after 16:00AM
							filterDate={(date) => {
								return date.day() !== 0 && date.day() !== 6;
							}} // Weekends are disabled
							locale="nb-NO"
							dateFormat={DATE_FORMAT}
							timeCaption="Tid"
							autocomplete="off"
							showTimeSelect
							showWeekNumbers
							onBlur={() => {
								console.log('bluring');
							}}
						/>
						{touched && error && <span className="ms-TextField-errorMessage errorMessage-88">{error}</span>}
					</div>
				</div>
			</div>
		);
	};

	renderTextField(field) {
		const {
			meta: { touched, error }
		} = field;

		return (
			<div className="ms-Grid-row">
				<div className="z-form-group" style={{paddingTop: 0}}>
					<div className="ms-Grid-col ms-sm12 ms-md4">
						<Label className="z-form-label">
							{field.label}
							<span>{error ? <span style={{color: "rgb(168, 0, 0)"}}> *</span> : ''}</span>
						</Label>
					</div>
					<div className="ms-Grid-col ms-sm12 ms-md8">
						<TextField errorMessage={touched ? error : ''} {...field.input} />
					</div>
				</div>
			</div>
		);
	}

	renderLinkField(field) {
		const {
			input,
			meta: { touched, error }
		} = field;

		let currentLink = ""; 
		let linkCollection = input.value ? input.value.split("|") : [];
		let valid = true;

		const submitLink = () => {
			currentLink = "https://" + currentLink;
			if (currentLink.indexOf("|") < 0) {
				if(input.value && input.value.length > 0) {
					input.onChange(input.value + "|" + currentLink);
				}
				else {
					input.onChange(currentLink);
				}
			}
		}

		return (
			<div className="ms-Grid-row">
				<div className="z-form-group" style={{paddingTop: 15}}>
					<div className="ms-Grid-col ms-sm12 ms-md4">
						<Label className="z-form-label">
							{field.label}
							<span>{error ? <span style={{color: "rgb(168, 0, 0)"}}> *</span> : ''}</span>
						</Label>
					</div>
					<div className="ms-Grid-col ms-sm12 ms-md8">
						<Stack style={{width: "100%"}} horizontal horizontalAlign="start">
							<TextField style={{width: "100%"}} styles={{root: {width: "100%"}, field: {width: "100%"}}} 
								errorMessage={touched ? error : ''}
								value={currentLink} 
								prefix="https://"
								onGetErrorMessage={(value) => {
									return value.indexOf("|") > -1 ? "Lenken kan ikke inneholde tegnet '|'" : ""
								}}
								onChange={(event, text) => {
										currentLink = text
								}}
								onKeyDown={(e) => {
									if(e.key == "Enter" && currentLink.length > 0) {
										submitLink()
									}
								}}
							/>
							<PrimaryButton
								style={{marginRight: 0, minWidth: "100px"}}
								onClick={submitLink}
								text="Legg til"
							></PrimaryButton>
						</Stack>
					</div>
					<div className="ms-Grid-col ms-sm12 ms-md4">
					</div>
					<div className="ms-Grid-col ms-sm12 ms-md8">
						<ul style={{listStyleType: "circle"}}>
							{linkCollection.map((link) => {
								return <li className="addedlink" style={{marginLeft: 20, marginTop: 10}}>
									<span className="labelText">
										<a target="_blank" href={link}>
											{link}
										</a>
										<Icon className="clear-icon" iconName="Clear" onClick={() => {
											let tmpArray: string[] = input.value.split("|");
											let index = tmpArray.indexOf(link);
											if (index >= 0) {
												tmpArray.splice(index, 1);
												input.onChange(tmpArray.join("|"));
											}
										}}/>
									</span>
								</li>;
							})}
						</ul>
					</div>
				</div>
			</div>
		);
	}

	renderSelectField(field) {
		const {
			input,
			meta: { touched, error },
			children
		} = field;

		let displayElements;
		if (children.props.children[0].key.startsWith('zSh')) {
			displayElements = children.props.children.sort((a, b) => {
				return a.text > b.text ? 1 : b.text > a.text ? -1 : 0;
			});
		} else {
			displayElements = children.props.children.sort((a, b) => {
				return +a.text.substring(0, a.text.length - 1) > +b.text.substring(0, b.text.length - 1)
					? 1
					: +a.text.substring(0, a.text.length - 1) < +b.text.substring(0, b.text.length - 1)
					? -1
					: 0;
			});
		}
		return (
			<div className="ms-Grid-row">
				<div className="z-form-group">
					<div className="ms-Grid-col ms-sm12 ms-md4">
						<Label className="z-form-label">
							{field.label}
							<span>{error ? <span style={{color: "rgb(168, 0, 0)"}}> *</span> : ''}</span>
						</Label>
					</div>
					<div className="ms-Grid-col ms-sm12 ms-md8">
						<Dropdown
							{...input}
							options={displayElements}
							selectedKey={input.value}
							errorMessage={touched && error}
							onChanged={(item) => input.onChange(item.key)}
						/>
					</div>
				</div>
			</div>
		);
	}

	render() {
		const { handleSubmit } = this.props;
		const { handleSubmitContinue } = this.props;

		let classAssignmenGroupOptions = [ {key: 0, text: "Alle"},
			...this.state.classAssignmentGroups.map(group => ({ key: group.Id, text: group.Name }))]

		return (
			<form className="schedule-form" onSubmit={handleSubmit(this.onFormSubmit.bind(this))}>
				<div className="form-content">
					<Field name="ClassID" 
						label="Klasse" 
						component={SelectField}
						onSelect={async (classId) => {
							await this.getAndSetClassAssignmentGroups(classId);
							this.props.change("TilretteleggingsgruppeId", 0)
						}}
					>
						<option>{scheduleUtils.mapScheduleElementsToKeys(this.props.subscribedSchoolClasses)}</option>
					</Field>

					<Field name="TilretteleggingsgruppeId" 
						label="Tilretteleggingsgruppe" 
						component={SelectField} 
						defaultValue={0}
						isLoading={this.state.loadingClassAssignmentGroups}
					> 
						<option>{classAssignmenGroupOptions}</option>
					</Field>

					<div className="ms-Grid-row" style={{marginTop: 20}}>
						<div className="ms-Grid-col ms-sm12 ms-md4"></div>
						<div className="ms-Grid-col ms-sm12 ms-md8">
							<Label htmlFor="textField" style={{marginLeft: 3}}>
								<span className="labelText"> 
									{this.props.currentCharacters} / {this.props.maxTextCharacters} Tegn <Icon data-tip data-for='maxCharacterTooltip' iconName="Info" style={{marginTop: 5, marginLeft: 4}}/>
									<ReactTooltip id="maxCharacterTooltip">
										<p style={{fontSize: 15}}>Maksimal antall tegn bestemmes av hvor lenge timeplanelementet varer</p>
									</ReactTooltip>
								</span>
							</Label>
						</div>
					</div>
					<Field
						name="Title"
						label="Tekst" // Custom fields variables can be fetched in component (i.e. field.label)
						component={this.renderTextField}
					/>
					<Field
						name="Links"
						label="Lenker"
						component={this.renderLinkField}
					/>

					<Field
						name="Start"
						label="Start" // Custom fields variables can be fetched in component (i.e. field.label)
						autoComplete="off"
						autoCorrect="off"
						spellCheck="off"
						rememberedTime={this.props.rememberedTime}
						component={this.renderDateTimeField}
					/>

					<Field
						name="End"
						label="Slutt"
						autoComplete="off"
						autoCorrect="off"
						spellCheck="off"
						rememberedTime={this.props.rememberedTime}
						component={this.renderTimeField}
					/>

					<Field name="Color" label="Farge" component={this.renderSelectField}>
						<option>{this.addClassesToColourList(scheduleUtils.getScheduleColorKeys())}</option>
					</Field>

					<div className="form-footer">
						<div className="form-footer-left">
							{
								// Delete button is not rendered when creating new ScheduleElements
								this.state.isEditing && (
									<ActionButton
										iconProps={{ iconName: 'Delete' }}
										text="Slett"
										onClick={handleSubmit(this.onDeleteClick.bind(this))}
									/>
								)
							}
						</div>
						<div className="form-footer-right">
							<span id="timeplanSavedNotification" className="timeplSavedNotif">
								Lagret <Icon iconName="SkypeCheck" />
							</span>
							<DefaultButton text="Avbryt" onClick={() => this.onCancelClick(this.props.formValues)} />
							<DefaultButton
								primary={true}
								text="Lagre"
								onClick={handleSubmit(this.onFormSubmit.bind(this))}
							/>
							{!this.state.isEditing && (
								<DefaultButton
									// style={{ borderTop: '1px solid rgba(255, 255, 255, 0.9)' }}
									primary={true}
									text="Lagre og lag nytt innslag"
									onClick={handleSubmit(this.onFormSubmitContinue.bind(this))}
								/>
							)}
						</div>
					</div>
				</div>
			</form>
		);
	}
}

function validate(values) {
	const errors: any = {};
	// Validate inputs from 'values'
	if (!values.ClassID) {
		errors.ClassID = 'Dette feltet er påkrevd.';
	}

	if (!values.Title) {
		errors.Title = 'Dette feltet er påkrevd.';
	}


	//getEstimatedQuarters(values.Start, values.End);
	let maxCharacterAmount = getMaxTextCharacterAmount(values.Start, values.End);
	
	if(values.Title.length > maxCharacterAmount) {
		errors.Title = `Teksten kan ikke være lengre enn ${maxCharacterAmount} tegn`
	}

	if (!values.Start) {
		errors.Start = 'Dette feltet er påkrevd.';
	}

	if (!values.End) {
		errors.End = 'Dette feltet er påkrevd.';
	}

	// if (values.Start.substring(11) > values.End) {
	// 	errors.End = 'Slutt kan ikke være før start.';
	// }

	if (!values.Color) {
		errors.Color = 'Dette feltet er påkrevd.';
	}

	// If errors is an empty object, the form can be submitted
	// If errors has *any* properties, redux forms assumes the form is invalid
	return errors;
}

const mapStateToProps = (state, ownProps) => {
	const formValues = getFormValues("ScheduleElementForm")(state);

	return {
		formValues,
		subscribedSchoolClasses: state.teacher.subscribedSchoolClasses,
		scheduleElements: state.scheduleElements,
		selectedclass: state.scheduleElements.selectedclass,
		rememberedTime: state.scheduleElements.rememberedTime
	};
};

export default reduxForm({
	form: 'ScheduleElementForm',
	validate,
	enableReinitialize: true
})(
	connect(mapStateToProps, {
		updateScheduleElement,
		deleteScheduleElement,
		setScheduleCalendarContext,
		rememberTimeForNextElement,
		clearScheduleElements
	})(withRouter(ScheduleElementForm))
);
