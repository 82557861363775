import * as React from 'react';

export interface INoContentProps {
    text:string;
    hide?:boolean
}

export default class NoContent extends React.Component<INoContentProps>{

    constructor(props){
        super(props);
    }

    render(){

        if (this.props.hide){
            return null;
        }

        return(
            <div className="ms-Grid-row" style={{padding: 15}}>
                <div className="ms-Grid-col ms-sm12 ms-fontSize-xxl ms-fontColor-neutralQuaternary text-center ms-u-text">
                    <h4>{this.props.text}</h4>
                </div>
            </div>
        )
    }
}