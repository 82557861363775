import * as React from 'react';
import * as _ from 'lodash';
import {connect} from 'react-redux';
import {getTeacherState} from "../../../actions/Action_Teacher";
import HeaderView from "../../view_containers/HeaderView";
import MainContent from "../../view_containers/MainContent";
import SchoolClassCard from "../schoolClasses/SchoolClassCard";
import { Dropdown, Pivot, PivotItem } from 'office-ui-fabric-react';
import CourseCard from '../schoolClasses/CourseCard';


export interface IAdminStudentsAndCoursesViewProps{
    subscribedCourses: {};
    subscribedClasses: {};
}

class AdminStudentsAndCoursesView extends React.Component<IAdminStudentsAndCoursesViewProps>{

    constructor(props){
        super(props);
    }




    render(){
        return(
            <div className="ms-Grid container main">
                <HeaderView
                    title="Elever, fag og klasser"
                    description="Her finner du en oversikt over dine fag og klasser, og får mulighet til å oppdatere/redigere dine tilretteleggingsgrupper."
                    iconName="Settings"
                />
                <MainContent>
                    <Pivot>
                        <PivotItem headerText='Fag'>
                            <hr/>
                            <div className="ms-Grid-row">
                                { _.values(this.props.subscribedCourses).map((course:any ) =>{
                                    return(
                                        <CourseCard
                                            Id={course.Id}
                                            CourseID={course.CourseID}
                                            Title={course.Title}
                                        />
                                    )
                                })}
                            </div>
                        </PivotItem>
                        <PivotItem headerText='Klasser'>
                            <hr/>
                            <div className="ms-Grid-row">
                                {
                                    _.values(this.props.subscribedClasses).map((schoolClass:any ) =>{
                                        return(
                                            <SchoolClassCard
                                                Title={schoolClass.ClassName}
                                                ClassId={schoolClass.ClassID}
                                            />
                                        )
                                    })
                                }
                            </div>
                        </PivotItem>
                    </Pivot>
                </MainContent>
            </div>
        )
    }

}

function mapStateToProps(state) {
    return {
        subscribedCourses: state.teacher.subscribedCourses,
        subscribedClasses: state.teacher.subscribedSchoolClasses
    };
}

export default connect(mapStateToProps, {getTeacherState})(AdminStudentsAndCoursesView);