import * as React from 'react';
import { gradeQuizAnswer } from '../../actions/Action_Quiz';
import { connect } from 'react-redux';
import { isEqual } from 'lodash';
import QuizGraderQuestionTile from './QuizGraderQuestionTile';
export interface IQuizGraderPointsProps {
	initialValues: any;
	gradeQuizAnswer(QuizAttemptAnswer: any, Points: number);
	quiz: any;
	setStudentPoints(id: number, points: number): void;
}
export interface IQuizGraderPointsState {
	totalpoints: number;
	pointsholder: {};
	values: any;
}
class QuizGraderPoints extends React.Component<IQuizGraderPointsProps, IQuizGraderPointsState> {
	constructor(props) {
		super(props);
		let pholder = {};
		let totPoints = 0;
		{
			this.props.initialValues.Answers && this.props.initialValues.Answers.forEach((answer) => {
				let gQuestion: any = Object.values(this.props.quiz.Questions).find(
					(ques: any) => ques.Id == answer.QuestionID
				);
				let gAnswer: any = Object.values(this.props.quiz.Answers).find((ans: any) => ans.Id == answer.Id);

				if (gAnswer) {
					pholder[gQuestion.Id] = gAnswer.Points;
					totPoints += gAnswer.Points;
				}
			});
		}
		this.state = {
			// totalpoints: this.props.initialValues.Points,
			totalpoints: totPoints,
			pointsholder: pholder,
			values: this.props.initialValues
		};
	}

	componentDidMount() {
		this.props.setStudentPoints(this.props.initialValues.Id, this.state.totalpoints ? this.state.totalpoints : this.props.initialValues.Points);
	}

	componentDidUpdate(prevProps, prevState) {
		if (!isEqual(prevProps.initialValues, this.props.initialValues)) {
			this.calculateTotalPoints();
		}
	}

	calculateTotalPoints() {
		let total = 0;
		this.props.initialValues.Answers &&
			this.props.initialValues.Answers.forEach((answer) => {
				let gQuestion: any = Object.values(this.props.quiz.Questions).find(
					(ques: any) => ques.Id == answer.QuestionID
				);
				let gAnswer: any = Object.values(this.props.quiz.Answers).find((ans: any) => ans.Id == answer.Id);
				if (gAnswer) {
					total += gAnswer.Points;
				}
			});

		this.setState({ totalpoints: total });
	}

	onPointChange = (event, gQuestion, gAnswer) => {
		const target = event.target;
		const value =
			target.type === 'checkbox'
				? target.checked
				: target.value;
		let deepClone = JSON.parse(
			JSON.stringify(this.state.pointsholder)
		);
		deepClone[gQuestion.Id] = parseInt(value.toString());
		let newtotal = 0;
		Object.keys(deepClone).forEach((it) => {
			newtotal += deepClone[it];
		});
		this.setState({
			pointsholder: deepClone,
			totalpoints: newtotal
		});
		this.props.gradeQuizAnswer(
			gAnswer,
			value ? Number(value) : 0
		);

		this.props.setStudentPoints(this.props.initialValues.Id, newtotal)
		
	}

	mapToCssClass = (answer: any, question: any): string => {
		let selected = false;
		Object.keys(answer.SelectedOptions).forEach((tmpAns) => {
			if (tmpAns == question.QID) {
				selected = true;
			}
		});

		if (selected && question.Correct) {
			return 'selectedcorrect';
		}
		if (selected && !question.Correct) {
			return 'selectedincorrect';
		}
		if (!selected && question.Correct) {
			return 'notselectedcorrect';
		}
		if (!selected && !question.Correct) {
			return 'notselectedincorrect';
		}

		return '';
	};
	componentWillReceiveProps() {
		this.calculateTotalPoints();
	}
	public render() {
		console.log('qgraderendering',this.props.initialValues);
		// var studentanswers = Object.values(
		// 	this.props.quiz.Answers
		//   ).filter(function (ans: any) {
		// 	//console.log('gradingcheck',ans.);
		// 	return ans.QAid == student.Id;
		//   });
		 
		  const reducer = (accumulator, item) => {
			return accumulator + parseInt(item.Points);
		  };
		  let points;
		  if(this.props.initialValues.Answers){
			  points = Object.values(this.props.initialValues.Answers).reduce( reducer,
				0);
		  }
		return (
			<div>
				{this.props.initialValues && (
					<div className="ms-Grid-col ms-sm12 header" id="evalBlock">
						<div className="ms-Grid-row">
							<div className="ms-Grid-col ms-sm12 ms-lg7">
								<h3 className="student-name">
									{this.props.initialValues.StudentName} |{' '}
									<small className="text-muted">
										<span className="labelQuiz">Poeng:</span>{' '}
										{/* <span id="totalPoints-@qa.Id">{this.state.totalpoints} </span> */}
										<span id="totalPoints-@qa.Id">{points} </span>
										<span>
											{' '}
											{/* / {this.props.initialValues.TotalMaxPoints} */}/{' '}
											{this.props.initialValues.MaxPoints ? this.props.initialValues.MaxPoints : this.props.quiz.TotalMaxPoints}
										</span>
									</small>
								</h3>
							</div>
							<div className="ms-Grid-col ms-sm12 ms-lg5">
								<div id="isready">
									{this.props.initialValues.Ready && (
										<span style={{display: 'flex', alignItems: "center"}}>
											{this.props.initialValues.ShowGrading ? <span>
												<i
													className="ms-Icon ms-Icon--Accept"
													aria-hidden="true"
													style={{ color: '#2baf2b' }}
												></i>{' '}
												<span>
													Publisert til elev
												</span>
											</span>
											: <span>
												<i
													className="ms-Icon ms-Icon--InboxCheck"
													aria-hidden="true"
													style={{ color: '#F0AD4E' }}
												></i>{' '}
												Klar for vurdering
											</span>
											}
										</span>
									)}
									{!this.props.initialValues.Ready && (
										<span>
											<i className="ms-Icon ms-Icon--IncidentTriangle" aria-hidden="true"></i>{' '}
											Ikke klar for vurdering
										</span>
									)}
								</div>
							</div>
						</div>
					</div>
				)}
				<div className="ms-Grid-col ms-sm12">
					<div style={{ display: 'flex', flexWrap: 'wrap' }}>
						{this.props.initialValues.Answers &&
							// this.props.initialValues.Quiz.Questions &&
							this.props.initialValues.Answers.map((answer) => {
								//console.log('qgradeanswer',answer);
								let gQuestion: any = Object.values(this.props.quiz.Questions).find(
									(ques: any) => ques.Id == answer.QuestionID
								);
								let gAttempts: any = Object.values(this.props.quiz.Attempts).find(
									(att: any) => att.Id == answer.QAid
								);
								let gAnswer: any = Object.values(this.props.quiz.Answers).find(
									(ans: any) => ans.Id == answer.Id
								);
								console.log('qgradeanswerfound',this.props.initialValues);
								console.log('qgradeanswerfoundpoints',gAnswer.Points);
								return (
									<QuizGraderQuestionTile answer={answer} gAnswer={gAnswer} gQuestion={gQuestion} onPointChange={this.onPointChange}></QuizGraderQuestionTile>
								);
							})}
					</div>
				</div>
			</div>
		);
	}
}
function mapStateToProps(state, ownProps) {
	// if(this.state.Quiz)
	// {
	// console.log('state.Quiz.gradequiz',state.Quiz.Quiz);
	return {
		quiz: state.Quiz.Quiz
	};
}

export default connect(mapStateToProps, { gradeQuizAnswer })(QuizGraderPoints);
