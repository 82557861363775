import { combineReducers } from "redux";
import { reducer as FormReducer } from "redux-form";

import TimeplanReducer from './Reducer_ScheduleElement';
import TeacherReducer from './Reducer_Teacher';
import AppSettingsReducer from './Reducer_Sidebar';
import MessagesReducer from './Reducer_Message';
import UkeplanReducer from './Reducer_Ukeplan';
import PortfolioReducer from './Reducer_Portfolio';
import TaskReducer from './Reducer_Task';
import QuizReducer from './Reducer_Quiz';
import IupReducer from './Reducer_Iup';
import StudentReducer from './Reducer_Student';
import FilterReducer from './Reducer_Filter';
import GraphReducer from './Reducer_Graph';
import GrepReducer from './Reducer_Grep';
import DiscoveryReducer from './Reducer_Discovery';
import GodmodeReducer from './Reducer_Godmode';
const rootReducer = combineReducers({
    sidebar: AppSettingsReducer,
    scheduleElements: TimeplanReducer,
    teacher: TeacherReducer,
    messages: MessagesReducer,
    form: FormReducer,
    ukeplanelements: UkeplanReducer,
    actorelements: PortfolioReducer,
    tasks:TaskReducer,
    Quiz:QuizReducer,
    Iup:IupReducer,
    StudentReducer,
    FilterReducer,
    GraphReducer,
    GrepReducer,
    DiscoveryReducer,
    GodmodeReducer

});

export default rootReducer;
