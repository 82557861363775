import {TOGGLE_SIDEBAR} from "../actions/Action_Sidebar";

export default function (state = {}, action) {

    switch (action.type){
        case TOGGLE_SIDEBAR:
            return action.payload;
        default:
            return state;
    }
}