import * as React from 'react';
import {Icon} from "office-ui-fabric-react";
import {withRouter} from "react-router-dom";


export interface IAdminCategoryCardProps{
    title:string;
    description:string;
    iconName:string;
    path:string;

    history:any;
}


class AdminCategoryCard extends React.Component<IAdminCategoryCardProps>{

    constructor(props){
        super(props);
    }

    render(){
        return(
            <div className="ms-Grid-col ms-sm12 ms-md6 ms-lg4">
                <button className="admin-category-card" onClick={()=> this.props.history.push(this.props.path)}>
                    <Icon iconName={this.props.iconName}/>
                    <div className="ms-font-xxl">{this.props.title}</div>
                    <p className="ms-font-xl">{this.props.description}</p>
                </button>
            </div>
        )
    }
}

export default withRouter(AdminCategoryCard);