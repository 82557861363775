import * as React from "react";
import { connect } from "react-redux";
import { getLP } from "../../actions/Action_Quiz";
import Select from "react-select";
import { getKM } from "../../actions/Action_Quiz";
import { Panel, PanelType } from "office-ui-fabric-react/lib/Panel";
import { getLPFagfornyelsen, getLPDetailsFagfornyelsen, getKMSet } from "../../actions/Action_Grep";
import { Label, ILabelStyles } from "office-ui-fabric-react/lib/Label";
import { Pivot, PivotItem } from "office-ui-fabric-react/lib/Pivot";
import { IStyleSet } from "office-ui-fabric-react/lib/Styling";
import { isEmpty } from "lodash"; 
import {
  Accordion,
  AccordionItem,
  AccordionItemTitle,
  AccordionItemHeading,
  AccordionItemPanel,
  AccordionItemBody,
} from "react-accessible-accordion";
import {
  ActionButton,
  PrimaryButton,
  DefaultButton,
  IButtonProps,
  IconButton,
} from "office-ui-fabric-react/lib/Button";
export interface IKmpgoalPickerProps {
  selected: any[];
  onClose: (selecteditems: any[]) => any;
  lps: any[];
  kms: any;
  getLP();
  getKM(code: string);
  getLPFagfornyelsen();
  getLPDetailsFagfornyelsen(lp: string);
  getKMSet(kms: string);
  lpsnew: any[];
  lpFagfornyelsen: any;
}
export interface IKmpgoalPickerState {
  selected: any[];
  sel: {};
  selectedlp: string;
  showkmp: boolean;
  kmsdetails: any;
}

class KmpgoalPicker extends React.Component<
  IKmpgoalPickerProps,
  IKmpgoalPickerState
> {
  constructor(props) {
    super(props);
    this.state = {
      selected: [],
      sel: {},
      selectedlp: "",
      showkmp: false,
      kmsdetails: {},
    };
  }
  componentWillReceiveProps(props) {
    let sel = {};
    props.selected.forEach((element) => {
      sel[element.kode] = true;
    });

    this.setState({ selected: props.selected, sel: sel });
  }
  componentWillMount() {
    this.props.getLP();
    this.props.getLPFagfornyelsen().then(() => {});
  }
  public render() {
    return (
      <div>
        <ActionButton
          iconProps={{ iconName: "Add" }}
          data-toggle='modal'
          data-target='#myModalGrepQuiz'
          onClick={() => {
            this.setState({ showkmp: true });
          }}>
          Legg til kompetansemål
        </ActionButton>

        <Panel
          isLightDismiss
          isOpen={this.state.showkmp}
          type={PanelType.medium}
          onDismiss={() => {
            this.setState({ showkmp: false });
          }}
          headerText='Velg kompetansemål'
          closeButtonAriaLabel='Lukk'
          onRenderFooterContent={this._onRenderFooterContent}>
          <div style={{ height: "80vh" }}>
            <Pivot>
              <PivotItem headerText='Fagfornyelsen'>

                
                    <Select
                  placeholder={
                    <div>Velg kompetansemål fra Fagfornyelsen...</div>
                  }
                  onChange={(selected) => {
                    console.log(selected.value);
                    this.setState({ selectedlp: selected.value });
                    this.props.getLPDetailsFagfornyelsen(selected.value).then((km) => {
                      console.log(km);
                      let promarray = [];
                      km.payload.data[
                        "kompetansemaal-kapittel"
                      ].kompetansemaalsett.forEach((element) => {
                        console.log("kapittel", element);
                        promarray.push(this.props.getKMSet(element.kode));
                      });
                      Promise.all(promarray).then((results) => {
                        console.log("result", results);
                        let tmp = JSON.parse(
                          JSON.stringify(this.state.kmsdetails)
                        );
                        results.forEach((element) => {
                          tmp[element.payload.data.kode] = element.payload.data;
                        });
                        this.setState({ kmsdetails: tmp });
                      });
                    });
                  }}
                  options={this.props.lpsnew}
                />
                <hr />
                {this.props.lpFagfornyelsen && !isEmpty(this.state.kmsdetails) && (
                  <Accordion>
                    {this.props.lpFagfornyelsen[
                      "kompetansemaal-kapittel"
                    ].kompetansemaalsett.map((item) => {
                      return (
                        <AccordionItem>
                          <AccordionItemTitle>
                            {item.kode} - {item.tittel}
                          </AccordionItemTitle>
                          <AccordionItemBody>
                            <ul className='no-type-list'>
                              {this.state.kmsdetails[item.kode] &&
                                this.state.kmsdetails[
                                  item.kode
                                ].kompetansemaal.map((k) => {
                                  if (!this.state.sel[k.kode]) {
                                    return (
                                      <li className='ms-Grid-row'>
                                        <div className='ms-Grid-col ms-sm1'>
                                          <IconButton
                                            className='vertical-align-middle'
                                            data-kmid='@k.kode'
                                            iconProps={{
                                              iconName: "BoxAdditionSolid",
                                            }}
                                            style={{color: 'green'}}
                                            onClick={(val) => {
                                              k.LPID = this.state.selectedlp;
                                              k.KMID = k.kode;
                                              k.GrepTekst = k.tittel;
                                              let allsel = this.state.sel;
                                              allsel[k.kode] = true;
                                              let selected = this.state
                                                .selected;
                                              selected.push(k);
                                              this.setState({
                                                sel: allsel,
                                                selected: selected,
                                              });
                                            }}
                                          />
                                        </div>
                                        <div className='ms-Grid-col ms-sm11 pt-5'>
                                          {k.tittel}
                                        </div>
                                      </li>
                                    );
                                  } else {
                                    return (
                                      <li className='ms-Grid-row'>
                                        <div className='ms-Grid-col ms-sm1'>
                                          <IconButton
                                            className='vertical-align-middle'
                                            data-kmid='@k.kode'
                                            iconProps={{
                                              iconName: "BoxSubtractSolid",
                                            }}
                                            style={{color: 'red'}}
                                            onClick={(val) => {
                                              let allsel = this.state.sel;
                                              allsel[k.kode] = false;
                                              let selected = [];
                                              this.state.selected.forEach(
                                                (element) => {
                                                  if (element.kode != k.kode) {
                                                    selected.push(element);
                                                  }
                                                }
                                              );
                                              this.setState({
                                                sel: allsel,
                                                selected: selected,
                                              });
                                            }}
                                          />
                                        </div>
                                        <div className='ms-Grid-col ms-sm11 pt-5'>
                                          {k.tittel}
                                        </div>
                                      </li>
                                    );
                                  }
                                })}
                            </ul>
                          </AccordionItemBody>
                        </AccordionItem>
                      );
                    })}
                  </Accordion>
                )}
              </PivotItem>

              <PivotItem headerText='Kunnskapsløftet'>
              <Select
                  onChange={(selected) => {
                    this.setState({ selectedlp: selected.value });
                    this.props.getKM(selected.value);
                  }}
                  options={this.props.lps}
                  placeholder={
                    <div>Velg kompetansemål fra Kunnskapsløftet...</div>
                  }
                />
                <hr />
                {this.props.kms && (
                  <Accordion>
                    {this.props.kms.kompetansemaal_kapittel.kompetansemaalsett.map(
                      (item) => {
                        return (
                          <AccordionItem>
                            <AccordionItemTitle>
                              {item.kode} - {item.etter_aarstrinn[0].tittel}
                            </AccordionItemTitle>
                            <AccordionItemBody>
                              {item.hovedomraader_i_kontekst_av_kompetansemaalsett.map(
                                (h) => {
                                  return (
                                    <div>
                                      {h.hovedomraadeverdier_under_kompetansemaalsett.tittel.map(
                                        (u) => {
                                          if (
                                            u.spraak ==
                                            "http://data.udir.no/kl06/nob"
                                          ) {
                                            return (
                                              <div className='lead mt-10'>
                                                {u.verdi}
                                              </div>
                                            );
                                          }
                                        }
                                      )}
                                      <ul className='no-type-list'>
                                        {item.kompetansemaal.map((k) => {
                                          if (
                                            k.tilhoerer_hovedomraade.kode ==
                                            h.kode
                                          ) {
                                            if (!this.state.sel[k.kode]) {
                                              return (
                                                <li className='ms-Grid-row'>
                                                  <div className='ms-Grid-col ms-sm1'>
                                                    <IconButton
                                                      className='vertical-align-middle'
                                                      data-kmid='@k.kode'
                                                      iconProps={{
                                                        iconName:
                                                          "BoxAdditionSolid",
                                                      }}
                                                      onClick={(val) => {
                                                        k.LPID = this.state.selectedlp;
                                                        k.KMID = k.kode;
                                                        k.GrepTekst = k.tittel;
                                                        let allsel = this.state
                                                          .sel;
                                                        allsel[k.kode] = true;
                                                        let selected = this
                                                          .state.selected;
                                                        selected.push(k);
                                                        this.setState({
                                                          sel: allsel,
                                                          selected: selected,
                                                        });
                                                      }}
                                                    />
                                                  </div>
                                                  <div className='ms-Grid-col ms-sm11 pt-5'>
                                                    {k.tittel}
                                                  </div>
                                                </li>
                                              );
                                            } else {
                                              return (
                                                <li className='ms-Grid-row'>
                                                  <div className='ms-Grid-col ms-sm1'>
                                                    <IconButton
                                                      className='vertical-align-middle green'
                                                      data-kmid='@k.kode'
                                                      iconProps={{
                                                        iconName:
                                                          "BoxSubtractSolid",
                                                      }}
                                                      onClick={(val) => {
                                                        let allsel = this.state
                                                          .sel;
                                                        allsel[k.kode] = false;
                                                        let selected = [];
                                                        this.state.selected.forEach(
                                                          (element) => {
                                                            if (
                                                              element.kode !=
                                                              k.kode
                                                            ) {
                                                              selected.push(
                                                                element
                                                              );
                                                            }
                                                          }
                                                        );
                                                        this.setState({
                                                          sel: allsel,
                                                          selected: selected,
                                                        });
                                                      }}
                                                    />
                                                  </div>
                                                  <div className='ms-Grid-col ms-sm11 pt-5'>
                                                    {k.tittel}
                                                  </div>
                                                </li>
                                              );
                                            }
                                          }
                                        })}
                                      </ul>
                                    </div>
                                  );
                                }
                              )}
                            </AccordionItemBody>
                          </AccordionItem>
                        );
                      }
                    )}
                  </Accordion>
                )}
              </PivotItem>
            </Pivot>
          </div>
        </Panel>
      </div>
    );
  }
  private _onRenderFooterContent = () => {
    return (
      <div>
        <PrimaryButton
          text='Lagre'
          onClick={() => {
            this.setState({ showkmp: false });
            this.props.onClose(this.state.selected);
          }}></PrimaryButton>
      </div>
    );
  };
}
const mapStateToProps = (state, ownProps) => {
  const options = [];
  if (state.Quiz.lp) {
    state.Quiz.lp.forEach((l) => {
      let tit = "";
      l.title.forEach((element) => {
        if (element.spraak === "default") {
          tit = element.verdi;
        }
      });
      options.push({ value: l.kode, label: tit + " (" + l.kode + ")" });
    });
  }
  let optionsnew = [];
  if (state.GrepReducer && state.GrepReducer.lpFagfornyelsen) {
    state.GrepReducer.lpFagfornyelsen.forEach((l) => {
      let tit = "";
      l.tittel.forEach((element) => {
        if (element.spraak === "default") {
          tit = element.verdi;
        }
      });
      optionsnew.push({ value: l.kode, label: tit + " (" + l.kode + ")" });
    });
  }
  return {
    lps: options,
    kms: state.Quiz.kms,
    selected: ownProps.selected,
    lpsnew: optionsnew,
    lpFagfornyelsen: state.GrepReducer.lpdetails,
  };
};

export default connect(mapStateToProps, {
  getLP,
  getKM,
  getLPFagfornyelsen,
  getLPDetailsFagfornyelsen,
  getKMSet,
})(KmpgoalPicker);
