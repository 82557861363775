import * as React from "react";
import { connect } from "react-redux";
import { getTeacherQuizzes } from "../../actions/Action_Quiz";
import QuizItem from "./QuizItem";
import moment = require("moment");
import "./quiz.scss";
import HeaderView from "../view_containers/HeaderView";
import SidebarActionButtonList from "../sidebar/SidebarActionButtonList";
import { CommandBarButton } from "office-ui-fabric-react";
import { Dropdown } from "office-ui-fabric-react";
import Slider from "react-rangeslider";
import "react-rangeslider/lib/index.css";
export interface IMyQuizzesTeacherViewProps {
  history: any;
  teacherState: any;
  quizzes: any;
  getTeacherQuizzes(teacherState: any);
}
export interface IMyQuizzesTeacherViewState {
  min: number;
  max: number;
  courseId: string;
}

enum QuizTimeFrame {
  Current,
  Historical,
  All,
}

class MyQuizzesTeacherView extends React.Component<
  IMyQuizzesTeacherViewProps,
  IMyQuizzesTeacherViewState
> {
  constructor(props) {
    super(props);
    this.state = { min: 100, max: 100, courseId: "" };
  }

  componentDidMount() {
    this.props.getTeacherQuizzes(this.props.teacherState);
  }

  handleOnChange = (value) => {
    this.setState({ min: value });
  };

  handleOnChangeHist = (value) => {
    this.setState({ max: value });
  };

  handleOnChangeCourse = (course, val) => {
    const { key } = course;
    this.setState({ courseId: course.key });
  };

  renderQuizzes = (timeFrame: QuizTimeFrame) => {
    if (this.props.quizzes) {
      const now = moment();

      let quizzes = this.props.quizzes;
      if (this.state.courseId) {
        quizzes = this.props.quizzes.filter(
          (q) => q.CourseId === this.state.courseId
        );
      }

      switch (timeFrame) {
        case QuizTimeFrame.Current:
          quizzes = quizzes.filter(
            (q) =>
              now.isBefore(moment(q.ValidTo)) &&
              moment(q.ValidTo).diff(now, "days") < this.state.min
          );

          break;

        case QuizTimeFrame.Historical:
          quizzes = quizzes.filter(
            (q) =>
              now.isAfter(moment(q.ValidTo)) &&
              now.diff(moment(q.ValidTo), "days") < this.state.max
          );
      }

      return quizzes.map((quiz) => (
        <QuizItem history={this.props.history} quizitem={quiz} />
      ));
    }
  };

  public render() {
    let fagoptions = [{ key: "", text: "Alle" }];
    let dict = {};
    if (this.props.quizzes) {
      this.props.quizzes.forEach((element) => {
        if (!dict[element.CourseId]) {
          dict[element.CourseId] = element;
          fagoptions.push({
            key: element.CourseId,
            text: element.CourseName,
          });
        }
      });

      fagoptions.sort((a,b) =>{
        return a.text > b.text ? 1 : b.text > a.text ? -1 : 0;
      })
    }
    return (
      <div>
        <SidebarActionButtonList>
          <CommandBarButton
            onClick={() => this.props.history.push("/quiz/nytt_innslag/n")}
            className="sidenav-buttons"
            iconProps={{ iconName: "Add" }}
            text="Lag ny prøve"
          />
        </SidebarActionButtonList>

        <div className="ms-Grid" id="teacher-quiz-view">
          <div className="main container">
            <HeaderView
              title="Mine prøver"
              description="På denne siden finner du de prøvene du tidligere har opprettet. Du kan også velge å opprette en ny prøve eller velge en eksisterende og dele ut til nye elever."
              iconName="PageListSolid"
            />

            <div className="ms-Grid-row">
              <div className="ms-Grid-row">
                <div className="ms-Grid-col ms-sm12">
                  <div className="ms-Grid-col ms-sm12 ms-md4">
                    <Dropdown
                      placeHolder="Alle"
                      //defaultSelectedKey={this.props.coursefilter}
                      label="Fag"
                      id="selCourseSubject"
                      onChanged={this.handleOnChangeCourse}
                      options={fagoptions}
                    />
                  </div>
                </div>
              </div>
              <hr/>
              <div className="ms-Grid-row">
                <div className="ms-Grid-col ms-sm12">
                  <h3 className="sliderHeader">Antall dager til prøve</h3>
                  <div id="slider" />
                  <div>{this.state.min} dager</div>
                  <Slider
                    value={this.state.min}
                    orientation="horizontal"
                    onChange={this.handleOnChange}
                    max={365}
                  />
                </div>
                <div className="ms-Grid-col ms-sm12">
                  <div id="currentQuiz">
                    {this.renderQuizzes(QuizTimeFrame.Current)}
                  </div>
                </div>
              </div>

              <hr />
              <div className="ms-Grid-row">
                <div className="ms-Grid-col ms-sm12">
                  <h3 className="sliderHeader">Antall dager etter prøve</h3>
                  <div id="sliderHistoric" />
                  <div>{this.state.max} dager</div>
                  <Slider
                    value={this.state.max}
                    orientation="horizontal"
                    onChange={this.handleOnChangeHist}
                    max={365}
                  />
                </div>
                <div className="ms-Grid-row">
                  <div className="ms-Grid-col ms-sm12">
                    <div id="historicQuiz">
                      {this.renderQuizzes(QuizTimeFrame.Historical)}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  teacherState: state.teacher,
  quizzes: state.Quiz.myquizzes,
});

export default connect(mapStateToProps, { getTeacherQuizzes })(
  MyQuizzesTeacherView
);
