import * as React from "react";
import * as BigCalendar from "react-big-calendar";
import * as moment from "moment";

import Masonry from "react-masonry-component";
import {
  getStudents,
  getUkeplanElementsStudent,
} from "../../actions/Action_Ukeplan";
import StudentCourseElementView from "./student/StudentCourseElementView";
import * as _ from "lodash";

import HeaderView from "../view_containers/HeaderView";
import { connect } from "react-redux";
import {
  CommandBarButton,
  ActionButton,
  Dropdown,
  Checkbox,
  Check,
} from "office-ui-fabric-react";
import SidebarActionButtonList from "../sidebar/SidebarActionButtonList";
import "./Ukeplan.scss";
moment.locale("nb");

BigCalendar.momentLocalizer(moment);

export interface IPreviewUkeplanViewProps {
  history: any;
  teacherstate: any;
  ukeplanelements: any;
  master: any;
  planelements: any;
  getStudents(sc: any);
  getUkeplanElementsStudent(teacherState: any, week: number, year: number);
  students: any[];
}
export interface IPreviewUkeplanViewState {
  selectedWeek: number;
  selectedYear: number;
  courseview: boolean;
  dayview: boolean;
  selectedstudent: any;
  selectedcourses: any;
  showall: boolean;
  thedate: any;
}

class PreviewUkeplanView extends React.Component<
  IPreviewUkeplanViewProps,
  IPreviewUkeplanViewState
> {
  /****************************
                Lifecycle hooks
         ****************************/
  componentWillReceiveProps(props) {
    if (props.planelements.All && props.planelements.All) {
      let f = {};

      props.planelements.All.forEach((element) => {
        f[element.CourseID] = false;
      });
      this.setState({ selectedcourses: f, showall: true });
    }
  }
  componentDidMount() {
    const week = moment(new Date()).week();
    const year = moment(new Date()).year();

    this.setState(
      {
        selectedWeek: week,
        selectedYear: year,
        courseview: true,
        dayview: false,
        thedate: new Date(),
      },
      () => {
        //this.props.getUkeplanElementsStudent(sc, week, year);
      }
    );
  }
  changeweek(week: number, year: number, thedate: any) {
    this.setState(
      {
        selectedWeek: week,
        selectedYear: year,
        thedate: thedate,
      },
      () => {
        this.props.getUkeplanElementsStudent(this.state.selectedstudent, week, year);
      }
    );
  }
  // changeweek(week: number, year: number) {
  //   this.setState(
  //     {
  //       selectedWeek: week,
  //       selectedYear: year,
  //     },
  //     () => {
  //       this.props.getUkeplanElementsStudent(
  //         this.state.selectedstudent,
  //         week,
  //         year
  //       );
  //     }
  //   );
  // }
  getStudents(sc: any) {
    this.props.getStudents(sc);
  }
  getUkeplanElementsStudent(sc: any) {
    // const week = moment(new Date()).week();
    // const year = moment(new Date()).year();

    // this.setState({
    //     selectedWeek: week,
    //     selectedYear: year,
    //     courseView: true,
    //     dayView: false
    // }, () => {
    //     this.props.getUkeplanElementsStudent(sc, week, year);
    // });
    this.props.getUkeplanElementsStudent(
      sc,
      this.state.selectedWeek,
      this.state.selectedYear
    );
  }
  render() {
    if (_.isEmpty(this.state)) {
      return <div>Loading...</div>;
    }
    let options = [];
    for (var key in this.props.teacherstate.subscribedSchoolClasses) {
      if (this.props.teacherstate.subscribedSchoolClasses.hasOwnProperty(key)) {
        options.push({
          key: this.props.teacherstate.subscribedSchoolClasses[key].ClassID,
          text: this.props.teacherstate.subscribedSchoolClasses[key].ClassName,
        });
      }
    }
    options.sort((a,b) =>{
        return +a.text.substring(0, a.text.length - 1) > +b.text.substring(0, b.text.length -1) ? 1 : 
        +a.text.substring(0, a.text.length - 1) < +b.text.substring(0, b.text.length -1) ? -1 : 0;
    })

    if (this.props.students) {
      this.props.students.forEach((element) => {
        element.key = element.loginName;
        element.text = element.Name;
      });
      this.props.students.sort((a,b) =>{
        return a.text > b.text ? 1 : b.text > a.text ? -1 : 0; 
      })
    }
    return (
      <div>
        <SidebarActionButtonList>
          <CommandBarButton
            data-tip="hei"
            onClick={() => this.props.history.push("/ukeplan/nytt_innslag/n")}
            className="sidenav-buttons"
            iconProps={{ iconName: "Add" }}
            text="Lag ny plan"
          ></CommandBarButton>

          <CommandBarButton
            onClick={() => this.props.history.push("/task/ny_innlevering/n")}
            className="sidenav-buttons"
            iconProps={{ iconName: "Add" }}
            text="Lag ny innlevering"
          ></CommandBarButton>
          <CommandBarButton
            onClick={() => this.props.history.push("/meldinger/ny_melding")}
            className="sidenav-buttons"
            iconProps={{ iconName: "Add" }}
            text="Lag ny melding"
          ></CommandBarButton>
          <hr />
          {/* <CommandBarButton
            className="sidenav-buttons"
            iconProps={{ iconName: "Print" }}
            text="Skriv ut"
          ></CommandBarButton> */}
          {/* <CommandBarButton
            className="sidenav-buttons"
            iconProps={{ iconName: "MailForward" }}
            text="Send på e-post"
          ></CommandBarButton> */}
        </SidebarActionButtonList>

        <div className="ms-Grid" id="ukeplan-view">
          <div className="main">
            <div className="ms-Grid-row">
              <div className="ms-Grid-col ms-sm12 ms-lg7 ms-xl5">
                <div className="ms-Grid-col ms-sm12">
                  <HeaderView
                    title="Ukeplan"
                    description="Planer og innleveringer"
                    iconName="Calendar"
                  />
                </div>
                <div className="ms-Grid-col ms-sm12">
                  <div className="weekButtons">
                    <ActionButton
                      id="btnNormal"
                      className="ms-font-l"
                      onClick={() =>
                        this.setState({ courseview: true, dayview: false })
                      }
                      iconProps={{ iconName: "Switch" }}
                    >
                      Uke
                    </ActionButton>
                    <ActionButton
                      className="ms-font-l"
                      id="btnWeek"
                      onClick={() =>
                        this.setState({ courseview: false, dayview: true })
                      }
                      iconProps={{ iconName: "Table" }}
                    >
                      Dag
                    </ActionButton>
                    {/* <ActionButton
                      className="ms-font-l"
                      id="btnPrint"
                      iconProps={{ iconName: "Print" }}
                    >
                      Skriv ut
                    </ActionButton> */}
                  </div>
                </div>
              </div>
              <div className="ms-Grid-col ms-sm12 ms-lg5 ms-xl7">
                {console.log("theprops", this.props.planelements)}
                {this.props.planelements && this.props.planelements.All && (
                  <div>
                    {this.props.planelements.All.map((c) => {
                      return (
                        <div>
                          <div>
                            <Checkbox
                              checked={this.state.selectedcourses[c.CourseID]}
                              className="fag-checkbox"
                              label={c.Title}
                              value={c.CourseID}
                              onChange={(a, b) => {
                                console.log(b);
                                let tmp = this.state.selectedcourses;
                                console.log(tmp);
                                tmp[c.CourseID] = b;
                                let showall = true;
                                if (b) {
                                  showall = false;
                                } else {
                                  Object.keys(
                                    this.state.selectedcourses
                                  ).forEach((k) => {
                                    if (
                                      k !== c.CourseID &&
                                      this.state.selectedcourses[k]
                                    ) {
                                      showall = false;
                                    }
                                  });
                                }

                                this.setState({
                                  selectedcourses: tmp,
                                  showall: showall,
                                });
                              }}
                            ></Checkbox>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                )}
              </div>

              <div className="ms-Grid-col ms-sm12 ms-lg5 ms-xl7">
                <div id="fagselector"></div>
              </div>
            </div>
            <div className="ms-Grid-row ms-bgColor-neutralLight topTool">
              <div className="ms-Grid-col ms-md12 ms-xl6 ms-xxl4">
                <div className="ms-Grid-col ms-sm12 ms-md6">
                  <Dropdown
                    placeHolder="Velg klasse"
                    label="Klasse"
                    id="selCourseStatus"
                    onChanged={(status) => {
                      const { key } = status;
                      let data = this.props.teacherstate
                        .subscribedSchoolClasses[status.key];
                      this.getStudents(data);
                    }}
                    options={options}
                  />
                </div>
                <div className="ms-Grid-col ms-sm12 ms-md6">
                  {this.props.students && (
                    <Dropdown
                      placeHolder="Velg elev"
                      label="Elev"
                      id="selCourseStatus"
                      onChanged={(status) => {
                        this.setState(
                          {
                            selectedstudent: status,
                          },
                          () => {
                            const { key } = status;
                            this.getUkeplanElementsStudent(status);
                          }
                        );
                      }}
                      options={this.props.students}
                    />
                  )}
                </div>
              </div>
              <div className="ms-Grid-col ms-md12 ms-xl6 ms-xxl4">
                {this.state && (
                  <div className="ms-Grid-col ms-sm12">
                    <div className="ms-Grid-col ms-sm2 ms-md3 ms-lg4 prevWeek">
                      <ActionButton
                        onClick={() => {
                          let lastdate = this.state.thedate;

                          let newDate = moment(lastdate).add("days", -7);
                          this.changeweek(
                            newDate.week(),
                            newDate.year(),
                            newDate
                          );
                        }}
                        className="ms-font-xl"
                        disabled={!this.state.selectedstudent}
                      >
                        <i
                          className="ms-Icon ms-Icon--PageLeft"
                          aria-hidden="true"
                        ></i>{" "}
                        <span> Forrige </span>
                      </ActionButton>
                    </div>
                    <div className="ms-Grid-col ms-sm8 ms-md6 ms-lg4 text-center">
                      <span
                        className="ms-font-l ms-fontWeight-semibold week-number"
                        id="weekText"
                      >
                        Uke nr. {this.state.selectedWeek}
                      </span>
                    </div>
                    <div className="ms-Grid-col ms-sm2 ms-md3 ms-lg4 nextWeek">
                      <ActionButton
                        onClick={() => {
                          let lastdate = this.state.thedate;
                          let newDate = moment(lastdate).add("days", 7);
                          this.changeweek(
                            newDate.week(),
                            newDate.year(),
                            newDate
                          );
                        }}
                        className="ms-font-xl nextWeek"
                        disabled={!this.state.selectedstudent}
                      >
                        <span>Neste</span>{" "}
                        <i
                          className="ms-Icon ms-Icon--PageRight"
                          aria-hidden="true"
                        >
                          {" "}
                        </i>
                      </ActionButton>
                    </div>
                  </div>
                )}
              </div>
              <div className="ms-Grid-col ms-xxl4 hiddenXlDown"></div>
            </div>
            <div className="ms-Grid-row" id="mainWeekPart">
              {this.state.courseview && (
                <div className="row1" id="list">
                  <Masonry>
                    {this.props.planelements.All &&
                      this.props.planelements.All.map((ta) => {
                        if (
                          this.state.selectedcourses[ta.CourseID] ||
                          this.state.showall
                        ) {
                          return (
                            <StudentCourseElementView teacherassignment={ta} />
                          );
                        }
                      })}
                  </Masonry>
                </div>
              )}

              {this.props.planelements && this.state.dayview && (
                <div className="row1" id="list2">
                  <Masonry>
                    <div className="ms-Grid-col ms-u-md4 ms-u-xl2 weekViewCol">
                      <h3 className="day-name">Mandag</h3>
                      {this.props.planelements.Monday.map((ta) => {
                        if (
                          this.state.selectedcourses[ta.CourseID] ||
                          this.state.showall
                        ) {
                          return (
                            <StudentCourseElementView teacherassignment={ta} />
                          );
                        }
                      })}
                    </div>
                    <div className="ms-Grid-col ms-u-md4 ms-u-xl2 weekViewCol">
                      <h3 className="day-name">Tirsdag</h3>
                      {this.props.planelements.Tuesday.map((ta) => {
                        if (
                          this.state.selectedcourses[ta.CourseID] ||
                          this.state.showall
                        ) {
                          return (
                            <StudentCourseElementView teacherassignment={ta} />
                          );
                        }
                      })}
                    </div>
                    <div className="ms-Grid-col ms-u-md4 ms-u-xl2 weekViewCol">
                      <h3 className="day-name">Onsdag</h3>
                      {this.props.planelements.Wednesday.map((ta) => {
                        if (
                          this.state.selectedcourses[ta.CourseID] ||
                          this.state.showall
                        ) {
                          return (
                            <StudentCourseElementView teacherassignment={ta} />
                          );
                        }
                      })}
                    </div>
                    <div className="ms-Grid-col ms-u-md4 ms-u-xl2 weekViewCol">
                      <h3 className="day-name">Torsdag</h3>
                      {this.props.planelements.Thursday.map((ta) => {
                        if (
                          this.state.selectedcourses[ta.CourseID] ||
                          this.state.showall
                        ) {
                          return (
                            <StudentCourseElementView teacherassignment={ta} />
                          );
                        }
                      })}
                    </div>

                    <div className="ms-Grid-col ms-u-md4 ms-u-xl2 weekViewCol">
                      <h3 className="day-name">Fredag</h3>
                      {this.props.planelements.Friday.map((ta) => {
                       if (
                        this.state.selectedcourses[ta.CourseID] ||
                        this.state.showall
                      ) {
                        return (
                          <StudentCourseElementView teacherassignment={ta} />
                        );
                      }
                      })}
                    </div>
                    <div className="ms-Grid-col ms-u-md4 ms-u-xl2 weekViewCol">
                      <h3 className="day-name">Fremtidige planer</h3>
                      {this.props.planelements.Future.map((ta) => {
                         if (
                          this.state.selectedcourses[ta.CourseID] ||
                          this.state.showall
                        ) {
                          return (
                            <StudentCourseElementView teacherassignment={ta} />
                          );
                        }
                      })}
                    </div>
                  </Masonry>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  if (state.ukeplanelements.ukeplanelementsstudent) {
    return {
      teacherstate: state.teacher,
      ukeplanelements: state.ukeplanelements.ukeplanelementsstudent.AllTAs,
      planelements: state.ukeplanelements.ukeplanelementsstudent,
      students: state.ukeplanelements.students,
    };
  } else {
    return {
      teacherstate: state.teacher,
      students: state.ukeplanelements.students,
      ukeplanelements: [],
      planelements: {},
    };
  }
}

export default connect(mapStateToProps, {
  getStudents,
  getUkeplanElementsStudent,
})(PreviewUkeplanView);
