
export const SCHEDULE_ELEMENT_STYLE_MAP = {
    zSheduleColorGreen:         {backgroundColor: '#007A4C', color:'white', opacity: 1, border: '0px', display: 'block'},
    zSheduleColorBlue:          {backgroundColor: '#194f90', color:'white', opacity: 1, border: '0px', display: 'block'},
    zSheduleColorLightBlue:     {backgroundColor: '#00b2ed', color:'white', opacity: 1, border: '0px', display: 'block'},
    zSheduleColorOrange:        {backgroundColor: '#ff7400', color:'white', opacity: 1, border: '0px', display: 'block'},
    zSheduleColorPurple:        {backgroundColor: '#803a7b', color:'white', opacity: 1, border: '0px', display: 'block'},
    zcopyEventColor:            {backgroundColor: '#EBEBE4', color:'black', opacity: 1, border: '1px solid gray', display: 'block'},
    zSheduleColorGrey:          {backgroundColor: '#cccccc', color:'white', opacity: 1, border: '0px', display: 'block'},
    zSheduleColorBlack:         {backgroundColor: '#333333', color:'white', opacity: 1, border: '0px', display: 'block'},
    zSheduleColorGreyMedium:    {backgroundColor: '#808080', color:'white', opacity: 1, border: '0px', display: 'block'},
    zSheduleColorDarkGrey:      {backgroundColor: '#333333', color:'white', opacity: 1, border: '0px', display: 'block'},
    zSheduleColorRed:           {backgroundColor: '#e14504', color:'white', opacity: 1, border: '0px', display: 'block'},
    zSheduleColorYellow:        {backgroundColor: '#edaa00', color:'white', opacity: 1, border: '0px', display: 'block'},
    zSheduleColorLightGreen:    {backgroundColor: '#81bc00', color:'white', opacity: 1, border: '0px', display: 'block'},
};

export default class StyleUtils {

    public getCalendarScheduleStyle(className:string){
        return SCHEDULE_ELEMENT_STYLE_MAP[className];
    }
}