
import * as React from 'react'

interface IStudentQuizGradingCardProps {
    question: any;
}

export default function StudentQuizGradingCard(props: IStudentQuizGradingCardProps) {
    return (
        <div className="quiz-grading-card">
            <h5>{props.question.QText}</h5>


            {props.question.QuizAttemptAnswer && 
                <p className="quiz-point-text">
                    Poeng: {props.question.QuizAttemptAnswer.Points} / {props.question.QMaxPoints}
                </p>}

            {props.question.QType == "4" && props.question.QuizAttemptAnswer && (
            <span className="remove-para-margin ql-editor" style={{backgroundColor: "transparent"}}
                dangerouslySetInnerHTML={{
                __html: props.question.QuizAttemptAnswer.Answer,
                }}
                // className="quizcomment"
            ></span>
            )}

            <ul className="gradingList">
            {props.question.Options &&
                props.question.Options.map((o) => {
                return <li className={o.Selected}>{o.Text}</li>;
                })}
            </ul>
        </div>
    );
}
