import * as React from "react";
import { connect } from "react-redux";
import IupClassReportItemView from "./IupClassReportItemView";
import { createZippedPDF } from "../../actions/Action_Iup";
import axios from "axios";
import {
  ActionButton,
  DefaultButton,
  PrimaryButton,
  Dialog,
  Modal,
  Panel,
  PanelType,
  TextField,
  Label,
  MessageBar,
  MessageBarType,
  Spinner,
  SpinnerSize,
  Toggle,
  Stack,
  DialogFooter,
  DialogType
  //DatePicker
} from "office-ui-fabric-react";
import {
//   getIupReportForClass,
  createIUP,
  deleteIUP,
  getIupReportForClassDraft
} from "../../actions/Action_Iup";
import IupStudentreportForm from "./IupStudentreportForm";
import * as moment from "moment";
export const LOCALE = "nb";
export const DATE_FORMAT = "DD.MM.YYYY";
export const TIME_FORMAT = "LT";
export interface IIupClassReportViewProps {
  iupClassReport: any;
  includeDraft: boolean;
  createIUP(iup: any);
  deleteIUP(iup: any);
  getIupReportForClassDraft(iup: any, includeDraft?: boolean);
  refreshSelectedIUP();
  iupViewToggleDraft();
  createZippedPDF(iups:any);
  setIupFormStatus(isDirty: boolean);
}
export interface IIupClassReportViewState {
  activestudent: any;
  showedit: boolean;
  title: string;
  allforms: any;
  activereport: any;
  deleting: boolean;
  wasdeleted: boolean;
  saving: boolean;
  zipurl:any[];
  generatingPfd: boolean;
  formIsDirty
}
let text = "";
export class IupClassReportView extends React.Component<
  IIupClassReportViewProps,
  IIupClassReportViewState
> {
  constructor(props) {
    super(props);
    let t = "";
    if (this.props.iupClassReport) {
      t = this.props.iupClassReport.Name;
    }
    this.state = {
      activestudent: null,
      showedit: false,
      title: t,
      allforms: {},
      activereport: null,
      deleting: false,
      wasdeleted: false,
      saving: false,
      generatingPfd: false,
      zipurl:[],
      formIsDirty: false
    };
  }
  componentWillReceiveProps(nextprops: IIupClassReportViewProps) {
    this.setState({ wasdeleted: false, activestudent: null });

    if (nextprops.iupClassReport.Id != this.props.iupClassReport.Id) {
      this.setState({formIsDirty: false});
    }
  }
  componentDidMount() {
    if(this.props.iupClassReport && this.props.iupClassReport.Name){
      text = this.props.iupClassReport.Name;
    }
  }

  setActiveStudent = (student: any) => {
    let changeStudentConfirmation = this.state.formIsDirty ? confirm("Du har ulagrede endringer, er du sikker på at du vil bytte elev?") : true;
    
    if (changeStudentConfirmation) {
      student.Report.StudentLogin = student.LoginName;
      student.Report.Iupid = this.props.iupClassReport.Id;
      this.setState({
        activestudent: student,
        activereport: student.Report
      });
    }
  }

  setFormStatus = (isDirty: boolean) => {
    this.setState({formIsDirty: isDirty});
    this.props.setIupFormStatus(isDirty);
  }

  public render() {
    // this.state.generatingPfd &&
    //    ( <Dialog
    //   hidden={false}
    //   onDismiss={() => this.setState({ generatingPfd: false })}
    //   >
    //   <Stack>
    //     <Label>Genererer pdf</Label>
    //     <Spinner size={SpinnerSize.large} />
    //   </Stack>
    //   <span>Genererer pdf, vennligst vent</span>
    //   <DialogFooter>
    //     <ActionButton>Hei hei hallå</ActionButton>
    //     {console.log("jeg finnes")}
    //   </DialogFooter>
    // </Dialog>)
    
    if (this.state.wasdeleted) {
      return <div>IUP slettet</div>;
    }
    return (
      <div id="detailView" className="iup-content">
        {this.props.iupClassReport && (
          <div className="ms-Grid-row" id="getIUP">
            <div className="ms-Grid-row">
              <div className="ms-Grid-col ms-md12 ms-lg8">
                <h4>
                  {this.props.iupClassReport.Name} |{" "}
                  {/* {this.props.iupClassReport.Name}  */}
                  <span className="text-muted">
                    {" "}
                    Fra:
                    {moment(this.props.iupClassReport.From)
                      .locale(LOCALE)
                      .format(DATE_FORMAT)}{" "}
                    Til:{" "}
                    {moment(this.props.iupClassReport.To)
                      .locale(LOCALE)
                      .format(DATE_FORMAT)}{" "}
                  </span>
                </h4>
                <Toggle label="Inkluder ufullstendige rapporter?" onText="Ja" offText="Nei" defaultChecked={this.props.includeDraft} onChange={this.props.iupViewToggleDraft} />
              </div>
              <div className="ms-Grid-col ms-md12 ms-lg4">
                {!this.props.iupClassReport.Hasreports && (
                  <div>
                    <DefaultButton 
                      iconProps={{ iconName: "Edit", color: "green" }}
                      text="Endre tittel på IUP"
                      onClick={() => {
                        this.setState({ showedit: true });
                      }}
                    />

                    <DefaultButton
                      iconProps={{ iconName: "Delete", color: "red" }}
                      text="Slett IUP"
                      styles={{
                        root: {
                          marginLeft: 5
                        }
                      }}
                      onClick={() => {
                        let i: any = {};
                        i.Id = this.props.iupClassReport.Id;
                        this.setState({ deleting: true });
                        this.props.deleteIUP(i).then(() => {
                          this.setState({
                            showedit: false,
                            deleting: false,
                            wasdeleted: true
                          });
                          this.props.getIupReportForClassDraft(this.props.iupClassReport.Id, this.props.includeDraft);
                        });
                      }}
                    />
                    {this.state.deleting && (
                      <Spinner size={SpinnerSize.large} ariaLive="assertive" />
                    )}
                  </div>
                )}
                {this.props.iupClassReport.Hasreports && (
                  <div>
                    <MessageBar
                      messageBarType={MessageBarType.warning}
                      isMultiline={false}
                    >
                      Denne IUP'en kan ikke endres
                    </MessageBar>
                  </div>
                )}
              </div>
              <Panel
                isOpen={this.state.showedit}
                onDismiss={() => {
                  this.setState({ showedit: false });
                }}
                type={PanelType.smallFixedFar}
                headerText="Endre IUP"
              >
                <div>
                  <TextField
                    label="Tittel"
                    onChange={(t, val) => {
                      text = val;
                    }}
                    defaultValue={this.props.iupClassReport.Name}
                  />

                  <PrimaryButton
                    styles={{
                      root: {
                        marginTop: 24
                      }
                    }}
                    text="Lagre"
                    onClick={() => {
                     let i: any = {};
                     i.Id = this.props.iupClassReport.Id;
                     i.Name = text;
                     this.setState({ saving: true });
                     this.props.createIUP(i).then(() => {
                       this.setState({
                         showedit: false,
                         title: i.Name,
                         saving: false
                       });
                      this.props.refreshSelectedIUP();
                      });
                    }}
                  />
                  {this.state.saving && (
                    <Spinner size={SpinnerSize.large} ariaLive="assertive" />
                  )}
                  <DefaultButton
                    styles={{
                      root: {
                        marginTop: 24
                      }
                    }}
                    text="Avbryt"
                    onClick={() => {
                      this.setState({ showedit: false });
                    }}
                  />
                </div>
              </Panel>
            </div>
            <div className="ms-Grid-row content-row">
              <div className="ms-Grid-col ms-sm12 ms-lg4">
                <h4>Elever</h4>
                <ul className="iup-students">
                  {this.props.iupClassReport.Students.map(item => {
                    let classn = "";
                    if (
                      this.state.activestudent &&
                      item.Id == this.state.activestudent.Id
                    ) {
                      classn = "selected";
                    }
                    return (
                      <li
                        className={`load-student-report ${classn}`}
                        onClick={() => this.setActiveStudent(item)}
                      >
                        {item.Report.Published && (
                          <div className="iup-student-haspublished-report pubicon-@student.LoginName.Split('@')[0]">
                            <i
                              className="ms-Icon ms-Icon--BoxCheckmarkSolid green"
                              aria-hidden="true"
                            ></i>
                            <span className="iup-student-name">
                              <span className="iup-student-name">
                                {item.Name} ({item.Reports.length})
                              </span>
                            </span>
                            <div>Rapport er publisert</div>
                          </div>
                        )}
                        {!item.Report.Published && (
                          <div className="iup-student-hasnotpublished-report notpubicon-@item.LoginName.Split('@')[0]">
                            <i className={`fas fa-pen-square ${item.Report.Id === 0 ? 'orange' : 'blue'}`}></i>
                            <span className="iup-student-name">
                              {item.Name} ({item.Reports.length})
                            </span>
                            {item.Report.Id === 0 && (
                              <div>Rapport er ikke påstartet</div>
                            )}
                            {item.Report.Id !== 0 && (
                              <div>Rapport er kladdet</div>
                            )}
                          </div>
                        )}
                      </li>
                    );
                  })}
                  <li></li>
                </ul>
              { this.state.generatingPfd ? (<Stack>
                <Label style={{textAlign: "center"}}><b>Genererer pdf...</b></Label>
                <Spinner size={SpinnerSize.large} />
                </Stack>) : 
                <ActionButton
                  iconProps={{ iconName: "pdf" }}
                  styles={{
                    root: {
                      float: "right"
                    }
                  }}
                  text="Opprett PDF for alle"
                  onClick={() => {
                    this.setState({
                      generatingPfd: true
                    })
                    let contents = document.getElementsByClassName("tab-pane");

                    let allstudents = document.getElementsByClassName(
                      "studentpdf"
                    );
                    let postdata = [];
                    for (let index = 0; index < allstudents.length; index++) {
                     
                    }
                    console.log(postdata);
                   
                    let promises = [];
                    for (let index = 0; index < allstudents.length; index++) {
                      const element = allstudents[index];
                      let studentname = element.getElementsByTagName("H1")[0].innerHTML;
                      let html =
                          `<!DOCTYPE html>\
                          <html>\
                            <head>\
                              <title>PDF</title>\
                              <meta charset='UTF-8' />\
                            </head>\
                            <body>\
                              <style>.table {\
                                width: 100%;\
                                margin-bottom: 1rem;\
                                border-collapse: collapse;\
                                }\
                                .table-bordered td,th {\
                                    border: 0.5px solid #696969;\
                                    padding: 5px;\
                                    text-align: left;\
                                }\
                                .table td {\
                                    padding: .75rem;\
                                    vertical-align: top;\
                                }\
                                span.form-1.kmeval {\
                                    color: #F0AD4E;\
                                }\
                                span.form-2.kmeval {\
                                    color: #1D4E90;\
                                }\
                                span.form-3.kmeval {\
                                    color: #5CB85C;\
                                }\
                                .kmeval {\
                                    padding-left: 10px;\
                                }\
                              </style>` +
                              element.innerHTML +
                            "</body>\
                          </html>";
                          postdata.push({astudent:html,studentname:studentname,iupname:this.props.iupClassReport.Name});
                      // promises.push(
                      //   axios.post(
                      //     `https://enablepdfgenerator.azurewebsites.net/home/genZokrates`,
                      //     { html: encodeURIComponent(html) },
                      //     {
                      //       headers: {
                      //         // Authorization: localStorage.getItem('id_token')
                      //       }
                      //     }
                      //   ).then((resp)=>{
                         
                      //   })
                      // )
                    }
                    console.log('postdata',postdata);

                    let allpromises = [];
                    let apost =[];
                    let i =0;
                    let counter =1;
                    
                    postdata.forEach(element => {
                      i++;
                      element.Index = counter;
                      apost.push(element);
                      if(i>10)
                      {
                          allpromises.push( this.props.createZippedPDF( apost));
                          i=0;
                          apost =[];
                          counter ++;
                      }
                    
                      
                    });
                    if (apost.length>0) {
                      allpromises.push( this.props.createZippedPDF( apost));
                    }
                   

                    Promise.all(allpromises).then((allresults)=>{
                      console.log('allresults',allresults);
                      let urls =[];
                      allresults.forEach(element => {
                        urls.push({url:element.payload.data.Name});
                      });
                      this.setState({zipurl:urls, generatingPfd: false});
                    });
                    // this.props.createZippedPDF( postdata).then((resp)=>{
                    //   console.log('zip',resp);
                    //   this.setState({zipurl:resp.payload.data.Name, generatingPfd: false});
                     
                    // })
                    // Promise.all(promises).then(responses => {
                    //   responses.forEach(response => {
                    //     // window.open(
                    //     //   "https://enablepdfgenerator.azurewebsites.net/home/getPDFZokrates/" +
                    //     //     response.data,
                    //     //   "_blank"
                    //     // );
                    //   })
                    // });
                  }}
                />}
               
                {
                  this.state.zipurl && this.state.zipurl.length>0 &&
                  this.state.zipurl.map((urlitem)=>{
                    return(
                      <ActionButton
                      iconProps={{ iconName: "download" }}
                      styles={{
                        root: {
                          float: "left"
                        },
                        label:{
                          color: "#0645AD",
                          fontWeight: 600
                        },
                        icon:{
                          fontWeight: 600,
                          color: "#32CD32"
                        }
                      }}
                      // text="Opprett PDF for alle" 
                      ><a href={urlitem.url} target="_blank">Last ned PDF </a>
                   </ActionButton>
                    )
                  })
                
                }
              </div>
              <div className="ms-Grid-col ms-sm12 ms-lg8">
                <div>
                  {this.state.activestudent && (
                    // <IupClassReportItemView
                    //   item={this.state.activestudent}
                    //   // iupid={this.props.iupClassReport.Id}
                    // ></IupClassReportItemView>
                    <IupStudentreportForm
					            initialValues={this.state.activereport}
					            toggleDraft={this.props.iupViewToggleDraft}
                      student={this.state.activestudent}
                      includeDraft={this.props.includeDraft}
                      setFormStatus={this.setFormStatus}
                      onemitform={props => {

                      }}
                    ></IupStudentreportForm>
                  )}
                </div>
              </div>
              <div id="allpdf" style={{ display: "none" }}>
                {this.props.iupClassReport.Students.filter(student => student.Report.Published).map(student => {
                  return (
                    <div className={"studentpdf"} id={`pdf-${student.Id}`}>
                      <h1>{student.Name}</h1>
                      <div>
                        <h3>Generell tilbakemelding</h3>
                        <div>
                          <span
                            dangerouslySetInnerHTML={{
                              __html: student.Report.BackEval
                            }}
                          ></span>
                        </div>
                        <h3>Generell framovermelding</h3>
                        <div>
                          <span
                            dangerouslySetInnerHTML={{
                              __html: student.Report.FutureEval
                            }}
                          ></span>
                        </div>
                        <h3>Kommentar fra elevsamtale</h3>
                        <div>
                          <span
                            dangerouslySetInnerHTML={{
                              __html: student.Report.BackComment
                            }}
                          ></span>
                        </div>
                        <h3>Kommentar fra kontaktsamtale</h3>
                        <div>
                          <span
                            dangerouslySetInnerHTML={{
                              __html: student.Report.FutureComment
                            }}
                          ></span>
                        </div>
                      </div>

          {student.Reports.map(rep => {
            return (
              <div>
                <h2>{rep.Title}</h2>
                <div className="table-responsive">
                  <h3>Innleveringer</h3>
                  <table className="table table-bordered">
                    {rep.Tasks.map(task => {
                      return (
                        <tr className="taskrow">
                          <td>
                            <span className="taskname">{task.WsTask.Name}</span>
                          </td>
                          <td>
                            <span
                              dangerouslySetInnerHTML={{
                                __html: task.GradingComment
                              }}
                              className="taskcomment"
                            ></span>
                          </td>
                          <td>
                            <span className="taskgrade">{task.Grade}</span>
                          </td>
                        </tr>
                      );
                    })}
                  </table>
                </div>
                <div className="table-responsive">
                  <h3>Prøver</h3>
                  {rep.Quis && rep.Quis.length > 0 ? <table className="table table-bordered">
                    <thead>
                      <th>Tittel</th>
                      <th>Kommentar</th>
                      <th>Karakter</th>
                    </thead> : 
                    {rep.Quis.map(qa => {
                      return (
                        <tr className="taskrow">
                          <td>
                            <span className="quizname">{qa.Quiz.Title}</span>
                          </td>
                          <td>
                            <span
                              dangerouslySetInnerHTML={{
                                __html: qa.GradingComment
                              }}
                              className="quizcomment"
                            ></span>
                          </td>
                          <td>
                            <span className="quizgrade">{qa.Grade}</span>
                          </td>
                        </tr>
                      );
                    })}
                  </table> : <p style={{paddingLeft: '5px', color: "#696969"}}>Ingen Prøver</p> }
                </div>
                <div className="table-responsive">
                  <h3>Kompetansemål</h3>
                  {rep.Kmevals && rep.Kmevals.length > 0 ? <table className="table table-bordered">
                    <thead>
                      <th>Kompetansemål</th>
                      <th>Måloppnåelse</th>
                    </thead>
                    {rep.Kmevals.map(km => {
                      return (
                        <tr className="kmrow">
                          <td className="kmtext">{km.Kmtext}</td>
                          <td>
                            {km.Evals.map(ev => {
                              
                              const iconSrcs = {
                                TestBeaker: "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACIAAAAiCAYAAAA6RwvCAAAC50lEQVRYR+2XSUwTURzGv9fpTDszQIPgLiAJLsSFSCCGohCMiScPnjRejAc9mJhoPGiCS10wejCaeNOD8WL05MGTFw0oEAPB4BJcSFBwQ0ECOJ2WWZ6Zqa1iy8ybUBNM+q7v3///12/m+94bQimlmAOL/JcgP1qPwRjtY9KPK6pEXuMFplqryJMiSscZGKOvQXUVoCYILwKESwyjBqimAsQH4hfBFa2CHD75b0CsrmbsO5S2ZlB1BGL1QfAlDfYwbagNas9VELEYckMLfMF5zBCeFcmBZNI292j+ViWnSE4RtxTSv/Yi+uSinaByfQRcYYX9E2OsH0p7xE5caeNR+BdUubWatu8p4kEprJjXh3vAL6mzB4KQXxFPbUDtUyf8C6sT8Z7cY0DyBDI1cB9q73UQIQ9yXTO4whXTRhhjb6F0tgCaguCGAxBKmxgQEiXMIHRqEsqjEzDG3yGwcgeCa/dkHBJ7cRPxN3fBhZZD3nwWRMhngmEGiffdQezVbfjkRY6HWjJnTOULgqt3IVC5M3sgxsR7RB9HYE5NQqzaB6F8m2Pz5CP0CfmQNkXAFZS5wjApEu2+Am3wIfzFayDVnwLhAo6NqRFHtP009JGX4EubINUcmj1Iyq7UhFh7BPziWtem9v3kcxfUrku2zVns7KiI/c86zkH/9gx8SSOkmsPslqQU0e7L0IZa4Z+/HlL4uKOSjiApu/o48KVbQAIFTGoki2h8AtrgA1DTcH23ZgT5bdcBT8NnKuZC5Y52nhEkmQckEAJXsGxWMMbEB9D4uGP+ZATxalc3ShY7ZwRJ2ZXhJXODsPZZ7JwG4tV2LCBWjVsMpIHYJ+jHDuYgYgWx6pJK80vDiZP7j5UGYt0p9OGnCFbuZj4nWGHs86rvlp3QcsN5Z5B4/z3Ent+wvulA/JKdjFlZ1ATVowB8CK7bi0DFdmcQ6/Kj9l5LBJEeywpDqgknQCjbCrFqf1pCMx162aXJ3G3OgPwEG1IquVqBwS0AAAAASUVORK5CYII=",
                                TestBeakerSolid: "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACIAAAAiCAYAAAA6RwvCAAAC8UlEQVRYR+2Xe0hTcRTHv1PnXBmTJjGkFOlh0EMq7TV6MuihllhUJFqSI4QZEZZpIzFM00ZEDkbMyDSjp0hqDxi9YPbYKuwB2cu0GCO0HFlTN138rl1hut17B3dgsPPv+e2czz3nfr+/XYHL5XJhHITgvwQpqzFCd8PMaX45WxJQkCnndJYc8mkiuvrnaDZ+QIe1B7/tDsyYOhlhoSFUs74BJz5++4GJYiFiZBFIks9ETtoi/4CQqvZ+J/K1BpjeWVBblErBkCAQGcUNSJwdhXKVAmLRMCDX8GkiARBPYw2sZvRUAhMJTITNgCxdv7DneCPlqFWFKZBKxNRPum12ZJc2Ug577kgKoiInsZVyy/tkaOSe1tWbcaLGiGLlauxOiodAMFyP5KqbW1Gkf4DDmXLkpCWM5LgQ+QTy5tN3ZJXcpGy9Mm89IiUT3Hp02f4gV3MHX6w9qFZvRlyMlAsDdYYzyIBjEEX6h7hieIuqwmSsTYj12OSeuR3ZpU3YrpiDYuUqhAqDOcFwBjG2foWyrAmKxFjGS432GYOpHfqCZMjjp/EH0tPbB5XmNto6unFevQlzp09hLE6vkKxGm7cBEeFhrDCcJkLWcbDSgH3bFmP/jiUICQ5iLOwcHMLpy09x5uoznMxVUGtiC1YQWq4O5xAlyxiZhK0mle+w2iiZC0OCOMmZEYQ8mfa6CacuPUGFavjJaLmy0RA5k0ke0hpwYOdSqLYmMk6SEYTetVgkRH7GckjCRWz93fK23n6U17bA3u9gfbe8gtByrbv72qfm3g6nr5vHKGevILQfkPXwEeQFZ/IfjyC0XB+97OSDYaTGygXRXuXsEYSWK68U/4p5k/MYEFp27zu7/cGBWdFSjzYwBqTm1iuoz973CwRdtGTvGmRunO/WYwyI9poJFRdb/ApCHDovfRkzyIs2K3YdawDxAH8E8aILR1OxME7GDEIckXzfauoe47PlJ68s5F+bOmsF9V082qFZ7xpeSRiKjRuQv9jdCbnUbfpKAAAAAElFTkSuQmCC",
                                TestCase: "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACIAAAAiCAYAAAA6RwvCAAACuElEQVRYR+2XPUhbURTH/6+JSTQ2WhrBahsrFBNIbQjFDk7iB4qjm8ZNHATBTRCdnARdnARBQRB1EDcHwcHFodS2akiTEEJpKYVqbRuVYL585Tx6Q2Je8m58MWboWd+55/7e/3zcewVRFEWUgAklBXISPsH022mEoqGCa9Pd0I1B26BiXEkRBnIZu0RFWQUECIoLlRzi13GE42HcCqRKX4WJlglUllUq7aP4fe/bHpY8S/9BkkrdmyIiRBz/PIa12gqD1oB7ASGIzcAmtj9vw1HjwKhjFPvf94tbI6kQOo0OI69G4KxxFleRbBBUKEVLTS6IooEoQRQMRK/RY92/jjpjHTotnWnDiweCFlwlrnAeOZcmNc+ATBvxbLIenR5h+dMy6GAesA4kYXghFMeujIMsCP3B7tddrPnXpCUE02HpSLZoanewmL7fPiy6FxFJRHJyuGwutD5pzfDJCkKeqTCNpkYEQ0HIQZCv+8yN+Y/ziiBD9iG0PW3LDyQVJnYdQ7m2PDknsv02a9tmczPGX49j1beKnS87yAbA4uRUhDmRMlvBLQy/HJaGVS67UxDWBQaNQbEO7xxEkeCfA6m34l3BzdT0W/vR+7w3axiu1PBCUB3Nvp8FdRB1Wk9DDw5+HGDBvQCzwYzJN5Mw6Uyy4dJAtA+06LJ0SZ2Rj9Hgsz+2y24qBycXOw3kNpdnmsJjzjHYHtky1GAb8qgigRDAhn9Duuzma/XGevS96MPh6WHWFPCoUpB3Dc9GSqoUBIRtQmo2VTfJ1hg9LwJ/AtJhyAo5VX3VIEwN7y8vd1apuG92kGoQpkY0EeUGEQQhQxVVICT33Ic5eM483BDM0fLQkvaYUwVC+Z55NyOdyvlarbEWUy1ToDsQmSoQCkCpoUvURfSCm4UGpsvqQvuz9uQa1SDcuys4lgzIX2Q5lblKoEziAAAAAElFTkSuQmCC",
                                Error: "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACIAAAAiCAYAAAA6RwvCAAAERUlEQVRYR+1XWSh1bRR+jiGEJFHccCHDjeHCdIGEUCRxYR7iwlgkQ0kIEWXMPGXmAimEK9ygyFSGpBRFiYwZ42+9/ft09nHO3ufQ//f99b+3+33Xft5nrfWs55V8fX194Q9Ykv8cECLu/Pwcc3NzWFpawtbWFo6OjhiXlpaWcHR0hI+PDwICAmBrawsNDQ21eBZl5PPzE8vLy6irq8PMzAwvuLu7O7S1tbG9vY2HhwfpN09PTxQUFMDPzw86OjoqARIEcnl5iaKiIvT29sLQ0BBhYWFISkqCs7MzjIyMeD94fHxkgMbGxjA6OoqbmxsEBwejtrYWdnZ2omCUAtnc3ERGRgbW19cRHx+PsrIyWFlZiQakDRcXF2hsbERrayssLCzQ0NDAUiaRSJSeVwhkdXUVaWlpODs7YymJiYmBlpaWSiC4TVRTCwsLyM7OxsvLC/r7++Ht7a06kOPjY8TFxYHS0tbWhsDAQMGbiKHjLqWrq4u+vj7Y29srPMJj5Pn5Gfn5+Qx9e3s7oqKifgWC+yMVe0JCAlxdXdHR0QFjY+NvYHhAFhcXERERwQ7V1NRAT09P6YXf3t4wMTGBu7s7xMbGwsDAQOnej48PVFdXo7i4GENDQyzV8ksK5OnpCZmZmaw4qfIdHBwEWSf2cnJycHp6iuHhYZiYmAjup30E2MzMDD09Pd9YkQLZ3d1lbAQFBTE2xPpfXSBUvPX19SgtLcXU1BR8fX15wKVAKHepqamg9Pj7+4vVINQFQgFJEkJCQpCens70SbadGRDKd2FhIVZWVlharK2t/xEgV1dXiI6Ohrm5OVpaWphIcosBoYJLSUlhMq1KvunwTxgROsOAXF9fs0om5aQ8CnULd4PfAFlbW8P4+DgbjjxG/hgg/3ZqTk5OMDIyAlNTUz4jNAtyc3PZ9KQN5C/EFp2prKxko4DaXZFaysfgmKci7e7u5k3wH7evGFBF3zmtIr0qLy+HpqYmnxHZHqfuKSkp4W1SFJQE6v7+HmScyJuIOTJO0Ih5RVr1TeLJAspXtCIg6nYNeRTyNbQGBgaYlsgu3tCbnp5mFiArK4sZISEPos7QIzaampqYN+ns7GSaJW+SeECIagJBs2BwcBChoaE/KYVvZzgbQAa7q6uLDT6l05f7cHBwwHzp7e0tQ+/l5fUrMFw86i4hl6bQKnI3IFelit9UVswbGxuM4f39fVGjpRAI5XR+fp75VnLjNC2p2mUFSIgm8jbEZkVFBdvW3NyMyMhIwc4SfE4cHh4iLy+PvWdI5JKTkxEeHg4bG5tvhUxtTGZ7cnKS2UF6fLm4uDBGPTw8RC2n6APr9fWVBa+qqsLe3h67ISmjk5OTdDi+v79jZ2eHsUeLhhmlJDExEfr6+irVmCgQLgr5TrKRs7OzLG2kN7KLfk5vF3qEubm5qTTBleqIStD/3qSusorFVpkRsUC//f4/EHkG/wLzRNy5CeDJewAAAABJRU5ErkJggg==",
                              }
                              let iconSrc;
                              if (ev === '1') iconSrc = iconSrcs.TestBeaker
                              else if (ev === '2') iconSrc = iconSrcs.TestBeakerSolid
                              else if (ev === '3') iconSrc = iconSrcs.TestCase
                              else iconSrc = iconSrcs.Error
                              return (
                                <span className="form-@ev kmeval">
                                  <img src={iconSrc}/>
                                </span>
                              );
                            })}
                          </td>
                        </tr>
                      );
                    })}
                  </table> : <p style={{paddingLeft: '5px', color: "#696969"}}>Ingen Kompetansemål</p> }
                </div>
                <h3>Tilbakemelding</h3>
                <span
                  dangerouslySetInnerHTML={{ __html: rep.ReportText }}
                ></span>
                <h3>Framovermelding</h3>
                <span dangerouslySetInnerHTML={{ __html: rep.Future }}></span>
              </div>
            );
          })}
        </div>

                      
                  
                  );
                })}
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}
function mapStateToProps(state) {
  return {};
}
export default connect(mapStateToProps, {
  createIUP,
  deleteIUP,
  createZippedPDF, 
  getIupReportForClassDraft
})(IupClassReportView);
