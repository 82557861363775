import * as React from "react";
import * as BigCalendar from "react-big-calendar";
import * as moment from "moment";

import Masonry from "react-masonry-component";
import {
  getUkeplanElements,
  getStudentState,
  getUkeplanChecks,
} from "../../../actions/Action_Student";

import StudentCourseElementView from "./StudentCourseElementView";
import * as _ from "lodash";

import HeaderView from "../../view_containers/HeaderView";
import { connect } from "react-redux";

import { ActionButton, Checkbox, CommandBarButton, DefaultButton, Dialog, DialogFooter, DialogType, Dropdown, PrimaryButton } from "office-ui-fabric-react";
import SidebarActionButtonList from "../../sidebar/SidebarActionButtonList";
import "../../ukeplan/Ukeplan.scss";
import CourseDayViewStudent from "./CourseDayViewStudent";
import { setCompactCardViewToggle, setUkeplanSelectedDate } from "../../../actions/Action_Ukeplan";

moment.locale("nb");

BigCalendar.momentLocalizer(moment);

export interface IPreviewUkeplanViewProps {
  student: any;
  history: any;
  teacherstate: any;
  ukeplanelements: any;
  master: any;
  planelements: any;
  getUkeplanElements(student, week: number, year: number);
  getUkeplanChecks(week: number, year: number);
  students: any[];
  initialDate: Date;
  setUkeplanSelectedDate(selectedDate: Date);
  setCompactCardViewToggle(compactCardView: boolean);
  compactCardView: boolean;
}
export interface IPreviewUkeplanViewState {
  selectedWeek: number;
  selectedYear: number;
  courseView: boolean;
  dayView: boolean;
  selectedStudent: any;
  selectedcourses: any;
  showAll: boolean;
  thedate: Date;

  // Dropdown
  showDateSelectModal: boolean;
  dropdownSelectedWeek: any;
  dropdownSelectedYear: any;
  dropdownWeekOptions: any;
  dropdownYearOptions: any;
}

class PreviewUkeplanView extends React.Component<
  IPreviewUkeplanViewProps,
  IPreviewUkeplanViewState
> {
  constructor(props: IPreviewUkeplanViewProps) {
    super(props);

    const weekOptions = [];
    const yearOptions = [];

    for (let i = 1; i <= 52; i++) {
      weekOptions.push({ key: i, text: i.toString() });
    }
    for (let i = -1; i <= 1; i++) {
      yearOptions.push({
        key: moment(new Date()).year() + i,
        text: (moment(new Date()).year() + i).toString()
      });
    }

    this.state = {
      selectedWeek: moment().week(),
      selectedYear: moment().year(),
      courseView: true,
      dayView: false,
      selectedStudent: null,
      thedate: new Date(),
      selectedcourses: {},
      showAll: true,
      showDateSelectModal: false,
      dropdownSelectedWeek: moment().week(),
      dropdownSelectedYear: moment().year(),
      dropdownWeekOptions: weekOptions,
      dropdownYearOptions: yearOptions,
    };
  }

  /****************************
     Lifecycle hooks
     ****************************/
  changeweek(newDate: moment.Moment) {
    let week = newDate.week();
    let year = newDate.year();

    if (week === 1) {
      let previousWeekDate = moment(newDate).add(-1, "weeks");
      if (previousWeekDate.year() == newDate.year()) {
        year++;
      }
    }

    this.setState(
      {
        selectedWeek: week,
        selectedYear: year,
        thedate: newDate.toDate(),
      },
      () => {
        this.props.setUkeplanSelectedDate(newDate.toDate());
        this.props.getUkeplanElements(this.props.student, week, year);
      }
    );
  }
  componentDidMount() {
    let date = this.props.initialDate || moment(new Date()).toDate();

    let week = moment(date).week();
    let year = moment(date).year();

    const weekOptions = [];
    const yearOptions = [];

    for (let i = 1; i <= 52; i++) {
      weekOptions.push({ key: i, text: i.toString() });
    }
    for (let i = -1; i <= 1; i++) {
      yearOptions.push({
        key: moment(new Date()).year() + i,
        text: (moment(new Date()).year() + i).toString()
      });
    }

    this.setState(
      {
        selectedWeek: week,
        selectedYear: year,
        courseView: true,
        dayView: false,

        showDateSelectModal: false,
        dropdownSelectedWeek: week,
        dropdownSelectedYear: year,
        dropdownWeekOptions: weekOptions,
        dropdownYearOptions: yearOptions,
        thedate: date
      },
      () => {
        this.props.getUkeplanElements(this.props.student, week, year);
      }
    );
  }

  onWeekChanged(week: number, year: number) {
    this.setState(
      {
        selectedWeek: week,
        selectedYear: year,
      },
      () => {
        this.props.getUkeplanElements(this.props.student, week, year);
      }
    );
  }

  onPrevWeekClicked = () => {
    this.onWeekChanged(this.state.selectedWeek - 1, this.state.selectedYear);
  };

  onNextWeekClicked = () => {
    this.onWeekChanged(this.state.selectedWeek + 1, this.state.selectedYear);
  };

  onCalendarDateSet() {
    const newDate = moment(new Date())
      .year(this.state.dropdownSelectedYear)
      .week(this.state.dropdownSelectedWeek);

    this.setState(
      {
        showDateSelectModal: false
      },
      () => {
        this.changeweek(
          newDate
        );
      }
    );
  }

  sortElementDisplay = (array) : any[]=>{    
    const order = {"News": 1, default: Infinity}
    return array.sort(function (a, b) {
      console.log('a',a);
      if(!a.CourseCategory)
      {
        a.CourseCategory ='z';
      }
      return (order[a.CourseCategory] || order.default) - (order[b.CourseCategory] || order.default) || a.CourseCategory.localeCompare(b.CourseCategory);
  });
  }

  renderDateSelectorModal() {
    return (
      <Dialog
        hidden={false}
        onDismiss={() => this.setState({ showDateSelectModal: false })}
        dialogContentProps={{
          type: DialogType.normal,
          title: "Angi uke og år",
          subText: ""
        }}
        styles={{
          main: [
            {
              selectors: {
                ["@media (min-width: 480px)"]: {
                  maxWidth: "600px",
                  minWidth: "480px"
                }
              }
            }
          ]
        }}
        modalProps={{
          titleAriaId: "myLabelId",
          subtitleAriaId: "mySubTextId",
          isBlocking: true,
          containerClassName: "ms-dialogMainOverride"
        }}
      >
        <div className="ms-Grid">
          <div className="ms-Grid-col ms-sm6">
            <Dropdown
              selectedKey={this.state.dropdownSelectedWeek}
              placeHolder="Velg uke"
              label="Velg uke"
              id="selScheduleWeek"
              onChanged={value => {
                this.setState({ dropdownSelectedWeek: value.key });
              }}
              options={this.state.dropdownWeekOptions}
            />
          </div>
          <div className="ms-Grid-col ms-sm6">
            <Dropdown
              selectedKey={this.state.dropdownSelectedYear}
              placeHolder="Velg år"
              label="Velg år"
              id="selScheduleYear"
              onChanged={value => {
                this.setState({ dropdownSelectedYear: value.key });
              }}
              options={this.state.dropdownYearOptions}
            />
          </div>
        </div>
        <DialogFooter>
          <DefaultButton
            onClick={() => {
              this.setState({ showDateSelectModal: false });
            }}
            text="Avbryt"
          />
          <PrimaryButton
            onClick={() => {
              this.onCalendarDateSet();
            }}
            text="Gå direkte til uke"
          />
        </DialogFooter>
      </Dialog>
    );
  }

  render() {
    if (_.isEmpty(this.state)) {
      return <div>Loading...</div>;
    }

    return (
      <div>
        {this.state &&
          this.state.showDateSelectModal &&
          this.renderDateSelectorModal()}
          
        <SidebarActionButtonList>
          <CommandBarButton
              onClick={() => this.setState({ showDateSelectModal: true })}
              className="sidenav-buttons"
              iconProps={{ iconName: "PublicCalendar" }}
              text="Gå direkte til uke"
            ></CommandBarButton>
        </SidebarActionButtonList>

        <div className="ms-Grid" id="ukeplan-view">
          <div className="main">
            <div className="ms-Grid-row">
              <div className="ms-Grid-col ms-sm12 ms-lg7 ms-xl5">
                <div className="ms-Grid-col ms-sm12">
                  <HeaderView
                    title="Ukeplan"
                    description="Planer og innleveringer"
                    iconName="Calendar"
                  />
                </div>
                <div className="ms-Grid-col ms-sm12">
                  <div className="weekButtons">
                    <ActionButton
                      id="btnNormal"
                      className="ms-font-l"
                      onClick={() =>
                        this.setState({ courseView: true, dayView: false })
                      }
                      iconProps={{ iconName: "Switch" }}
                    >
                      Uke
                    </ActionButton>
                    <ActionButton
                      className="ms-font-l"
                      id="btnWeek"
                      onClick={() =>
                        this.setState({ courseView: false, dayView: true })
                      }
                      iconProps={{ iconName: "Table" }}
                    >
                      Dag
                    </ActionButton>
                    <ActionButton
                      className="ms-font-l"
                      id="btnPrint"
                      iconProps={{ iconName: "Print" }}
                      onClick={() => {
                        window.open(
                          `${this.props.teacherstate.user.tenanturl}/v2Elev/GutenbergStudent?SPHostUrl=${this.props.teacherstate.user.url}&id=${this.state.selectedWeek}-${this.state.selectedYear}`
                        );
                      }}
                    >
                      Skriv ut
                    </ActionButton> 
                    <ActionButton 
                      iconProps={{ iconName: this.props.compactCardView ? "RedEye" : "Hide" }}
                      onClick={() => {
                        this.props.setCompactCardViewToggle(!this.props.compactCardView)
                      }}>{this.props.compactCardView ? "Vis full oppgave tekst" : "Begrens oppgave tekst"}</ActionButton>
                  </div>
                </div>
              </div>
              <div className="ms-Grid-col ms-sm12 ms-lg5 ms-xl7">
                {/* <div id="fagselector"></div> */}
                {console.log('checks', this.props.planelements)}
                {this.props.planelements && this.props.planelements.All && (
                   <div>
                    {this.props.planelements.All.map(c => {
                      return (
                        <div>
                          <div>
                            <Checkbox
                              checked={
                                this.state.selectedcourses[c.CourseID]
                              }
                              className="fag-checkbox"
                              label={c.Title}
                              value={c.CourseID}
                              onChange={(a, b) => {
                                console.log(b);
                                let tmp = this.state.selectedcourses;
                                console.log(tmp);
                                tmp[c.CourseID] = b;
                                let showall = true;
                                if (b) {
                                  showall = false;
                                } else {
                                  Object.keys(
                                    this.state.selectedcourses
                                  ).forEach(k => {
                                    if (
                                      k !== c.CourseID &&
                                      this.state.selectedcourses[k]
                                    ) {
                                      showall = false;
                                    }
                                  });
                                }

                                this.setState({
                                  selectedcourses: tmp,
                                  showAll: showall
                                });
                              }}
                            ></Checkbox>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                )} 
              </div>
            </div>
            <div className="ms-Grid-row ms-bgColor-neutralLight topTool">
              <div className="ms-Grid-col hiddenLgDown ms-xxl4">
                <div className="ms-Grid-col ms-sm12 ms-md6"></div>
              </div>
              <div className="ms-Grid-col ms-sm12 ms-xxl4">
                {this.state && (
                  <div className="ms-Grid-col ms-sm12">
                    <div className="ms-Grid-col ms-sm2 ms-md3 ms-lg4 prevWeek">
                      {/* <ActionButton
                                                onClick={
                                                    this.onPrevWeekClicked}
                                                className="ms-font-xl">

                                                <i
                                                    className="ms-Icon ms-Icon--PageLeft"
                                                    aria-hidden="true"
                                                />
                                                <span> Forrige </span>
                                            </ActionButton> */}
                      <ActionButton
                        onClick={() => {
                          let newDate = moment(this.state.thedate).add(-1, "weeks");
                          this.changeweek(newDate);
                        }}
                        style={{height: "inherit"}}
                        className="ms-font-xl"
                      >
                        <i
                          className="ms-Icon ms-Icon--PageLeft"
                          aria-hidden="true"
                        ></i>{" "}
                        <span> Forrige </span>
                      </ActionButton>
                    </div>
                    <div className="ms-Grid-col ms-sm8 ms-md6 ms-lg4 text-center">
                      <span
                        className="ms-font-l ms-fontWeight-semibold week-number"
                        id="weekText"
                      >
                        Uke nr. {this.state.selectedWeek}
                      </span>
                    </div>
                    <div className="ms-Grid-col ms-sm2 ms-md3 ms-lg4 nextWeek">
                      {/* <ActionButton
                        onClick={this.onNextWeekClicked}
                        className="ms-font-xl nextWeek"
                      >
                        <span>Neste</span>
                        <i
                          className="ms-Icon ms-Icon--PageRight"
                          aria-hidden="true"
                        />
                      </ActionButton> */}
                      <ActionButton
                      onClick={() => {
                        let newDate = moment(this.state.thedate).add(7, "days");
                        this.changeweek(newDate);
                      }}
                      style={{height: "inherit"}}
                      className="ms-font-xl nextWeek"
                    >
                      <span>Neste</span>{" "}
                      <i
                        className="ms-Icon ms-Icon--PageRight"
                        aria-hidden="true"
                      >
                        {" "}
                      </i>
                    </ActionButton>
                    </div>
                  </div>
                )}
              </div>
              <div className="ms-Grid-col hiddenLgDown ms-xxl4" />
            </div>
            <div className="ms-Grid-row" id="mainWeekPart">
              {this.state.courseView && (
                <div className="row1" id="list">
                  <Masonry>
                    {this.props.planelements &&
                      this.props.planelements.All &&
                      this.props.planelements.All.map((ta) => {
                        if (
                          this.state.selectedcourses[ta.CourseID] ||
                          this.state.showAll
                        ){
                          return (
                            <StudentCourseElementView
                              history={this.props.history}
                              teacherassignment={ta}
                            />
                          );
                        }
                        
                      })}
                  </Masonry>
                </div>
              )}

              {this.props.planelements && this.state.dayView && (
                <div className="row1" id="list2">
                  <Masonry>
                    <div className="ms-Grid-col ms-u-md4 ms-u-xl2 weekViewCol">
                      <h3 className="day-name">Mandag</h3>
                      {this.sortElementDisplay(this.props.planelements.Monday).map((ta) => {
                         if (
                           this.state.selectedcourses[ta.CourseID] ||
                           this.state.showAll
                         ){
                           return (
                             <CourseDayViewStudent
                               teacherassignment={ta}
                               history={this.props.history}
                             />
                           );
                         }
                        })}
                    </div>
                    <div className="ms-Grid-col ms-u-md4 ms-u-xl2 weekViewCol">
                      <h3 className="day-name">Tirsdag</h3>
                      {this.sortElementDisplay(this.props.planelements.Tuesday).map((ta) => {
                        if (
                          this.state.selectedcourses[ta.CourseID] ||
                          this.state.showAll
                        ){
                        return (
                          <CourseDayViewStudent
                            teacherassignment={ta}
                            history={this.props.history}
                          />
                        );
                        }
                      })}
                    </div>
                    <div className="ms-Grid-col ms-u-md4 ms-u-xl2 weekViewCol">
                      <h3 className="day-name">Onsdag</h3>
                      {this.sortElementDisplay(this.props.planelements.Wednesday).map((ta) => {
                        if (
                          this.state.selectedcourses[ta.CourseID] ||
                          this.state.showAll
                        ){
                        return (
                          <CourseDayViewStudent
                            teacherassignment={ta}
                            history={this.props.history}
                          />
                        );
                        }
                      })}
                    </div>
                    <div className="ms-Grid-col ms-u-md4 ms-u-xl2 weekViewCol">
                      <h3 className="day-name">Torsdag</h3>
                      {this.sortElementDisplay(this.props.planelements.Thursday).map((ta) => {
                        if (
                          this.state.selectedcourses[ta.CourseID] ||
                          this.state.showAll
                        ){
                          return (
                            <CourseDayViewStudent
                              teacherassignment={ta}
                              history={this.props.history}
                            />
                          );
                        }
                      })}
                    </div>

                    <div className="ms-Grid-col ms-u-md4 ms-u-xl2 weekViewCol">
                      <h3 className="day-name">Fredag</h3>
                      {this.sortElementDisplay(this.props.planelements.Friday).map((ta) => {
                         if (
                          this.state.selectedcourses[ta.CourseID] ||
                          this.state.showAll
                        ){
                          return (
                            <CourseDayViewStudent
                              teacherassignment={ta}
                              history={this.props.history}
                            />
                          );
                        }                       
                      })}
                    </div>
                    <div className="ms-Grid-col ms-u-md4 ms-u-xl2 weekViewCol">
                      <h3 className="day-name">Fremtidige planer</h3>
                      {this.sortElementDisplay(this.props.planelements.Future).map((ta) => {
                          if (
                            this.state.selectedcourses[ta.CourseID] ||
                            this.state.showAll
                          ){
                            return (
                              <CourseDayViewStudent
                                teacherassignment={ta}
                                history={this.props.history}
                              />
                            );
                          }
                      })}
                    </div>
                  </Masonry>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    teacherstate: state.teacher,
    student: state.StudentReducer.student,
    planelements: state.StudentReducer.ukeplan,
    ukeplanelements: state.ukeplanelements,
    initialDate: state.ukeplanelements.SelectedDate,
    compactCardView: state.ukeplanelements.CompactCardView
  };
}

export default connect(mapStateToProps, {
  getUkeplanElements,
  getStudentState,
  getUkeplanChecks,
  setUkeplanSelectedDate,
  setCompactCardViewToggle
})(PreviewUkeplanView);
