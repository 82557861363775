import * as React from 'react';
import {Icon} from "office-ui-fabric-react";

export interface ICourseCheckboxProps{
    Id:string;
    Name:string;
    Checked:boolean;
}

class CheckboxContainer extends React.Component<ICourseCheckboxProps>{

    constructor(props){
        super(props);
    }

    render(){

        const iconName = (this.props.Checked) ? 'SkypeCircleCheck' : 'CircleRing';
        const className = (this.props.Checked) ? 'icon-container checked':'icon-container';

        return(
            <div className="ms-Grid-col ms-sm12 ms-md6 ms-lg4 ms-xl3 ms-xxl2">
                <div className="course-checkbox ms-Grid-row">
                    <div className="ms-Grid-col">
                        {this.props.Name}
                        {/* <div>{this.props.Id}</div> */}
                    </div>
                    <div className={className}>
                        <Icon iconName={iconName} />
                    </div>
                </div>
            </div>
        )
    }
}

export default CheckboxContainer;