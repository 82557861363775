import * as React from "react";
import { connect } from "react-redux";
import { loadsubmission, getAssignmentPlagInfo, submitResource, loadfulltask } from "../../actions/Action_Graph";
import { getTeacherState } from "../../actions/Action_Teacher";
import {
    Panel,
    PanelType,
    ActionButton,
    PrimaryButton,
    Icon,
    SearchBox,
    ActivityItem,
    Link,
    mergeStyleSets,
    Stack
} from "office-ui-fabric-react";
import {
    DetailsList,
    DetailsListLayoutMode,
    Selection,
    IColumn,
    SelectionMode,
} from "office-ui-fabric-react/lib/DetailsList";
import { MarqueeSelection } from "office-ui-fabric-react/lib/MarqueeSelection";
import { IconButton } from "@material-ui/core";
import PlagStatus from "../innleveringer/PlagStatus";
import EvalFormGraph from "./EvalFormGraph";

export interface IRubricDisplayProps {
    rubric: any;
    outcome: any;
}
export interface IRubricDisplayState {

}
function mapStateToProps(state) {


    return {

    };
}

class RubricDisplay extends React.Component<IRubricDisplayProps, IRubricDisplayState> {

    constructor(props) {
        super(props);
        this.state = {


        };

    }
    componentDidMount() {


    }



    render() {
        console.log('evalformgraph4', this.props);
        let sellevels = {};
        this.props.outcome.rubricQualitySelectedLevels.forEach(element => {
            sellevels[element.qualityId] = element.columnId;
        });
        let levels = {};
        this.props.rubric.levels.forEach(element => {
            levels[element.levelId] = element.displayName;
        });
        return (
            <>
                {this.props.rubric &&
                    <Stack>
                        {
                            this.props.rubric.qualities.map((q) => {

                                let MyIcon;

                                switch (levels[sellevels[q.qualityId]]) {
                                    case 'Lav':
                                        MyIcon = () => <Icon iconName="TestBeaker" style={{ color: '#F0AD4E' }} />;
                                        break;
                                    case 'Middels':
                                        MyIcon = () => <Icon iconName="TestBeakerSolid" style={{ color: '#1D4E90' }} />;
                                        break;
                                    case 'Høy':
                                        MyIcon = () => <Icon iconName="TestCase" style={{ color: '#5CB85C' }} />;
                                        break;

                                    default:
                                        MyIcon = () => <span></span>;
                                        break;
                                }

                                return (
                                        <Stack horizontal>
                                          

                                            {/* <span className="form-@ev kmeval"> */}
                                            <span>
                                            {q.description.content}
                                                <MyIcon />
                                            </span>
                                        </Stack>
                                    // <li>

                                    // </li>

                                );


                            })
                        }
                    </Stack>

                }
            </>
        );
    }

}

export default connect(mapStateToProps, { loadsubmission, getTeacherState, getAssignmentPlagInfo, submitResource, loadfulltask })(
    RubricDisplay
);
