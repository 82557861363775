import * as React from 'react';
import * as _ from 'lodash';
import {connect} from 'react-redux';
import HeaderView from "../../view_containers/HeaderView";
import HeaderTaskBar from "../../view_containers/HeaderTaskBar";
import {DefaultButton, Dropdown, IDropdownOption, TextField, Label} from "office-ui-fabric-react";
import {School} from "../../../models/School";
import {getAvailableSchoolClasses, saveTeacherClasses} from "../../../actions/Action_Teacher";
import {SchoolClass} from "../../../models/SchoolClass";
import {SchoolClassCheckbox} from "../../../models/SchoolClassCheckbox";
import MainContent from "../../view_containers/MainContent";
import NoContent from "../../view_containers/NoContent";
import SpinLoader from "../../loading/SpinLoader";
import CheckboxContainer from "../CheckboxContainer";
import ButtonRow from "../../view_containers/ButtonRow";
import {TeacherClass} from "../../../models/TeacherClass";
import {LoadingDialog} from "../../loading/LoadingDialog";
import {User} from "../../../models/User";

export interface IAdminSchoolClassesViewProps {
    subscribedSchoolClasses:SchoolClassCheckbox[];
    availableSchoolClasses:SchoolClassCheckbox[];
    schools:IDropdownOption[];
    user:User;
    teacherState: any;
    getAvailableSchoolClasses(teacherState: any);
    saveTeacherClasses(classes:any[], callback:(classes:SchoolClass[])=>void);
}

export interface IAdminSchoolClassesViewState {
    // Request status
    awaitingResponse:boolean;       // True while awaiting a response from requests
    loadingSchoolClasses:boolean;   // True if available SchoolClasses are not loaded on render

    // Filter Options
    selectedSchoolKey:number | string;
    searchInput:string;

    // School Classes
    subscribedSchoolClasses:SchoolClassCheckbox[];
    availableSchoolClasses:SchoolClassCheckbox[];
}

class AdminSchoolClassesView extends React.Component<IAdminSchoolClassesViewProps, IAdminSchoolClassesViewState>{

    constructor(props){
        super(props);

        const {availableSchoolClasses, subscribedSchoolClasses} = this.props;

        this.state = {
            awaitingResponse: false,
            loadingSchoolClasses: !this.props.availableSchoolClasses.length,
            searchInput: '',
            selectedSchoolKey: 0,
            subscribedSchoolClasses,
            availableSchoolClasses,
        };

        this.onSearchInputChanged = this.onSearchInputChanged.bind(this);
        this.onSelectedSchoolChanged = this.onSelectedSchoolChanged.bind(this);
    }

    /****************************
            Lifecycle hooks
     ****************************/

    componentWillMount(){
        this.props.getAvailableSchoolClasses(this.props.teacherState).then(response =>{
            this.setState({loadingSchoolClasses: false})
        });
        
    }

    componentWillReceiveProps(nextProps:IAdminSchoolClassesViewProps){

        // If state for available classes has changed
        if (!_.isEqual(this.props.availableSchoolClasses, nextProps.availableSchoolClasses)){
            this.setState({
                availableSchoolClasses: nextProps.availableSchoolClasses.map(a => ({...a, Selected: false}))
            })
        }

        // If state for subscribed classes has changed
        if (!_.isEqual(this.props.subscribedSchoolClasses, nextProps.subscribedSchoolClasses)){
            this.setState({
                subscribedSchoolClasses:  nextProps.subscribedSchoolClasses.map(a => ({...a, Selected: true}))
            })
        }
    }

    /****************************
            Event handlers
     ****************************/

    onSearchInputChanged(event){
        this.setState({searchInput: event.target.value});
    }

    onSelectedSchoolChanged(selectedSchool : IDropdownOption){
        this.setState({selectedSchoolKey: selectedSchool.key});
    }

    onSubscribedCheckboxClicked(schoolClass:SchoolClassCheckbox) {

        // Toggles 'Selected' property for clicked schoolClass
        const subscribedSchoolClasses = this.state.subscribedSchoolClasses.map(sc =>(
            sc.ClassID === schoolClass.ClassID
                ? {...schoolClass, Selected: !schoolClass.Selected}
                : sc
        ));

        this.setState({subscribedSchoolClasses});
    }

    onAvailableCheckboxClicked(schoolClass:SchoolClassCheckbox) {

        // Toggles 'Selected' property for clicked schoolClass
        const availableSchoolClasses = this.state.availableSchoolClasses.map(sc =>(
            sc.ClassID === schoolClass.ClassID
                ? {...schoolClass, Selected: !schoolClass.Selected}
                : sc
        ));

        this.setState({availableSchoolClasses});
    }

    onSubmit(){

        const {login} = this.props.user;
        const {availableSchoolClasses, subscribedSchoolClasses} = this.state;

        // We merge both the selected courses and available courses
        const allSchoolClasses = subscribedSchoolClasses.concat(availableSchoolClasses);

        // We only send the selected courses in the request
        const selectedSchoolClasses = allSchoolClasses.filter(s => s.Selected);

        const requestData = selectedSchoolClasses.map(s => new TeacherClass(login, s.ClassID));

        this.setState({awaitingResponse: true});

        this.props.saveTeacherClasses(requestData, (savedClasses:SchoolClass[])=>{
            this.setState({awaitingResponse: false})
        });
    }

    /****************************
            Filter methods
     ****************************/

    filterSchoolClassCheckBoxBySearchInput = (checkbox : SchoolClassCheckbox) : boolean =>{

        const searchInput = this.state.searchInput.toLowerCase();

        return checkbox.ClassName.toLowerCase().includes(searchInput) || checkbox.ClassID.toLowerCase().includes(searchInput);
    };

    filterSchoolClassCheckboxBySelectedSchool = (checkbox : SchoolClassCheckbox) : boolean =>{

        const {schools} = this.props;
        const {selectedSchoolKey} = this.state;

        // If all schools are selected
        if (selectedSchoolKey === 0) {
            return true;
        }

        const selectedSchool: IDropdownOption | undefined = schools.find(s => s.key === selectedSchoolKey);

        if (!selectedSchool) {
            return false;
        }

        return selectedSchool.title === checkbox.SchoolID;
    };

    getFilteredSchoolClassCheckboxes = (checkboxes:SchoolClassCheckbox[]) : SchoolClassCheckbox[] =>(
        checkboxes
            .filter(c => this.filterSchoolClassCheckboxBySelectedSchool(c))
            .filter(c => this.filterSchoolClassCheckBoxBySearchInput(c))
            .sort((a, b) => a.ClassName.localeCompare(b.ClassName))
    );

    hasAppliedAnyFilters = () : boolean =>{

        const {selectedSchoolKey, searchInput} = this.state;

        return selectedSchoolKey !== 0 || !!searchInput;
    };

    /****************************
            Render methods
     ****************************/

    renderHeader = () =>(
        <HeaderView
            title="Velg dine klasser"
            description="Her velger du fag som du skal ha ansvar for, eller ønsker å være knyttet til."
            iconName="PeopleAdd"
        >
            <HeaderTaskBar>
                <div className="ms-Grid-col ms-sm12 ms-md6 ms-lg3">
                    <Label>Filtrer klasser etter skole</Label>
                    <Dropdown
                        id="selSchool"
                        //label="Filtrer klasser etter skole"
                        selectedKey={this.state.selectedSchoolKey}
                        onChanged={this.onSelectedSchoolChanged}
                        options={this.props.schools}
                    />
                </div>
                <div className="ms-Grid-col ms-sm12 ms-md6 ms-lg3">
                    <Label>Søk etter klasser</Label>
                    <TextField
                        //label="Søk etter klasser"
                        placeholder="Søk etter klasser"
                        ariaLabel="Søk etter klasser"
                        value={this.state.searchInput}
                        onChange={(value)=> this.onSearchInputChanged(value)}
                    />
                </div>
            </HeaderTaskBar>
        </HeaderView>
    );

    renderSubscribedSchoolClasses = () =>{

        const filteredSubscribedSchoolClasses : SchoolClassCheckbox[] = this.getFilteredSchoolClassCheckboxes(this.state.subscribedSchoolClasses);
        const isLoadingSubscribedSchoolClasses : boolean = this.state.loadingSchoolClasses && !this.state.subscribedSchoolClasses.length;
        const hasNoSubscribedSchoolClasses : boolean = !this.state.loadingSchoolClasses && !this.state.subscribedSchoolClasses.length;

        return(
            <>
                <h3 className="mt-10">Mine klasser</h3>

                {isLoadingSubscribedSchoolClasses &&
                    <SpinLoader text="Laster dine klasser"/>
                }

                {hasNoSubscribedSchoolClasses &&
                    <NoContent text="Du har ingen klasser"/>
                }

                {this.hasAppliedAnyFilters() && !filteredSubscribedSchoolClasses.length &&
                    <NoContent text="Ingen klasser for valgte filter"/>
                }

                <div className="ms-Grid-row">
                    {this.getFilteredSchoolClassCheckboxes(this.state.subscribedSchoolClasses).map((schoolClass:SchoolClassCheckbox) => (
                        <div onClick={()=> this.onSubscribedCheckboxClicked(schoolClass)}>
                            <CheckboxContainer
                                Id={schoolClass.ClassID}
                                Name={schoolClass.ClassName}
                                Checked={schoolClass.Selected}
                            />
                        </div>
                    ))}
                </div>
            </>
        );
    };

    renderAvailableSchoolClasses = () =>{

        const filteredAvailableSchoolClasses : SchoolClassCheckbox[] = this.getFilteredSchoolClassCheckboxes(this.state.availableSchoolClasses);
        const isLoadingAvailableSchoolClasses : boolean = this.state.loadingSchoolClasses && !this.state.availableSchoolClasses.length;
        const hasNoAvailableSchoolClasses : boolean = !this.state.loadingSchoolClasses && !this.state.availableSchoolClasses.length;

        return(
            <>
                <h3 className="mt-10">Tilgjengelige klasser</h3>

                {isLoadingAvailableSchoolClasses &&
                    <SpinLoader text="Laster tilgjengelige klasser"/>
                }

                {hasNoAvailableSchoolClasses &&
                    <NoContent text="Ingen tilgjengelige klasser"/>
                }

                {this.hasAppliedAnyFilters() && !filteredAvailableSchoolClasses.length &&
                    <NoContent text="Ingen tilgjengelige klasser for valgte filter"/>
                }

                <div className="ms-Grid-row">
                    {filteredAvailableSchoolClasses.map((schoolClass:SchoolClassCheckbox) => {
                        return (
                            <div onClick={()=> this.onAvailableCheckboxClicked(schoolClass)}>
                                <CheckboxContainer
                                    Id={schoolClass.ClassID}
                                    Name={schoolClass.ClassName}
                                    Checked={schoolClass.Selected}
                                />
                            </div>
                        )
                    })}
                </div>
            </>
        );
    };


    render(){
        return(
            <div className="ms-Grid main">
                <LoadingDialog
                    title="Oppdaterer dine klasser"
                    description="Vennligst vent"
                    hide={!this.state.awaitingResponse}
                />

                {this.renderHeader()}

                <MainContent>
                <ButtonRow>
                        <DefaultButton
                            primary={true}
                            text="Lagre"
                            onClick={this.onSubmit.bind(this)}
                            style={{float: 'right'}}
                        />
                    </ButtonRow>
                    {this.renderSubscribedSchoolClasses()}
                    {this.renderAvailableSchoolClasses()}

                    <ButtonRow>
                        <DefaultButton
                            primary={true}
                            text="Lagre"
                            onClick={this.onSubmit.bind(this)}
                            style={{float: 'right'}}
                        />
                    </ButtonRow>
                </MainContent>
            </div>
        )
    }
}

const mapSchoolClassesToSchoolClassCheckboxes = (schoolClasses : SchoolClass[], isSelected : boolean) : SchoolClassCheckbox[] =>(
    schoolClasses.map(schoolClass => new SchoolClassCheckbox(schoolClass.ClassName, schoolClass.ClassID, schoolClass.SchoolID, isSelected))
);

const mapSchoolsToSelectOptions = (schools : School[]) : IDropdownOption[] => (
    [{key: 0, title: "Alle", text: 'Alle'}].concat(schools.map((s, i) =>({key: i+1, title: s.SchoolId, text: s.Name})))
);

const mapStateToProps = (state, ownProps) => {

    const {availableSchoolClasses, subscribedSchoolClasses, schools, user} = state.teacher;

    return{
        teacherState: state.teacher,
        user,
        schools: mapSchoolsToSelectOptions(_.values(schools)),
        availableSchoolClasses: mapSchoolClassesToSchoolClassCheckboxes(_.values(availableSchoolClasses), false),
        subscribedSchoolClasses: mapSchoolClassesToSchoolClassCheckboxes(_.values(subscribedSchoolClasses), true),
    }
};

export default connect(mapStateToProps, {getAvailableSchoolClasses, saveTeacherClasses})(AdminSchoolClassesView);