import * as React from 'react';
import ReactQuill from 'react-quill';
import { ActionButton, PrimaryButton, DefaultButton, Icon} from 'office-ui-fabric-react';
import { connect } from 'react-redux';
export interface IIupClassReportItemViewStudentProps {
    item: any;

}
export interface IIupClassReportItemViewStudentState {
    activecourse: string;
    BackEval:string;
    FutureEval:string;
    BackComment:string;
    FutureComment:string;
    allTabs: any[];
}
let t='';
let f='';
let el='';
let k='';
export class IupClassReportItemViewStudent extends React.Component<IIupClassReportItemViewStudentProps, IIupClassReportItemViewStudentState> {
    constructor(props) {
        super(props);

        this.state = { activecourse: '0',
        BackEval:this.props.item.Report.BackEval,
        FutureEval:this.props.item.Report.FutureEval,
        BackComment:this.props.item.Report.BackComment,
        FutureComment:this.props.item.Report.FutureComment,
        allTabs: [true]
         };
        
    }
    handleChangeBackEval= (value) => {
        this.setState({ BackEval: value })
      }
      handleChangeFutureEval= (value) => {
        this.setState({ FutureEval: value })
      }
      handleChangeBackComment= (value) => {
        this.setState({ BackComment: value })
      }
      handleChangeFutureComment= (value) => {
        this.setState({ FutureComment: value })
      }
      enableChangeTabs = (tabIndex: number) =>{
            let newArr = [];
        for(let i = 0; i<= this.state.allTabs.length; i++){
            if(i === tabIndex ){
                newArr[tabIndex] = true
            }else if(i< this.state.allTabs.length){
                newArr[i] = false
            }
            this.setState({
                allTabs: newArr
            })
        }
      }
    public render() {
        let {allTabs} = this.state;
        return (
            <div className="reports" id="rapporter-@item.LoginName.Split('@')[0]">
              
               
                    <h4 style={{fontWeight: 'bolder'}} className="mt-10 mb-10">
                        {this.props.item.Name}
                    </h4>


                <ul className="nav nav-tabs " id="myTab" role="tablist">
                    <li style={{cursor:'pointer'}} className={`${allTabs[0] ? 'active' : ''} nav-item nav-link show`}  onClick={() => {
                                    this.enableChangeTabs(0)
                                    this.setState({ activecourse: '0' })
                                }}>
                      Oppsummering
                    </li>
                    {
                        this.props.item.Reports.map((rep,i) => {
                            return (
                                <li style={{cursor:'pointer'}} className={`${allTabs[i + 1] ? 'active' : ''} nav-item nav-link show`} key={i + 1} onClick={() => {
                                    
                                    this.enableChangeTabs(i + 1);
                                    this.setState({ activecourse: rep.Courseid })
                                }}>
                                    {rep.Title}
                                </li>
                            )
                        })
                        
                    }
                </ul>
               
                {
                    this.state.activecourse == '0' &&
                        <div className="tab-pane active show" id="summary-@item.LoginName.Split('@')[0]" role="tabpanel" aria-labelledby="home-tab">
                            <div id="summarycontent-@item.LoginName.Split('@')[0]">
                                <h6>Generell tilbakemelding</h6>
                            
                                <div id="quillBackEval-@item.LoginName.Split('@')[0]">
                                <span className="remove-para-margin ql-editor" style={{backgroundColor: "transparent"}}
                                    dangerouslySetInnerHTML={{__html:this.props.item.Report.BackEval}}>
                                    </span>
                                {/* <ReactQuill value={this.state.BackEval}
                                    onChange={this.handleChangeBackEval} /> */}
                                   
                                </div>
                                <h6>Generell framovermelding</h6>
                                <div id="quillFutureEval-@item.LoginName.Split('@')[0]">
                                <span className="remove-para-margin ql-editor" style={{backgroundColor: "transparent"}}
                                    dangerouslySetInnerHTML={{__html:this.props.item.Report.FutureEval}}>
                                    </span>
                                    {/* <ReactQuill value={this.state.FutureEval} onChange={this.handleChangeFutureEval}></ReactQuill> */}
                                </div>
                                <h6>Kommentar fra elevsamtale</h6>
                                <div id="quillBackComment-@item.LoginName.Split('@')[0]">
                                <span className="remove-para-margin ql-editor" style={{backgroundColor: "transparent"}}
                                    dangerouslySetInnerHTML={{__html:this.props.item.Report.BackComment}}>
                                    </span>
                                    {/* <ReactQuill value={this.state.BackComment}  onChange={this.handleChangeBackComment}></ReactQuill> */}
                                </div>
                                <h6>Kommentar fra kontaktsamtale</h6>
                                <div id="quillFutureComment-@item.LoginName.Split('@')[0]">
                                <span className="remove-para-margin ql-editor" style={{backgroundColor: "transparent"}}
                                    dangerouslySetInnerHTML={{__html:this.props.item.Report.FutureComment}}>
                                    </span>
                                    {/* <ReactQuill value={this.state.FutureComment} onChange={this.handleChangeFutureComment}></ReactQuill> */}
                                </div>

                            </div>
                            <div className="iup-buttons-footer">
                              
                            </div>
                        </div>
                }


                {
                    this.props.item.Reports.map((rep) => {
                        return (
                            this.state.activecourse == rep.Courseid &&
                            <div className="tab-pane" id="@rep.Courseid-@item.LoginName.Split('@')[0]" role="tabpanel" aria-labelledby="home-tab">
                                <h5 style={{fontWeight: 'bolder'}} className="mt-10 mb-10">
                                    {rep.Title}
                                </h5>
                                <div className="table-responsive">
                                    <h6>Innleveringer</h6>
                                    <table className="table table-bordered">
                                        {
                                            rep.Tasks.map((task) => {
                                                return (
                                                    <tr className="taskrow">
                                                        <td>
                                                            <span className="taskname">{task.WsTask.Name}</span>
                                                        </td>
                                                        <td>
                                                            <span className="taskcomment remove-para-margin ql-editor" style={{backgroundColor: "transparent"}}
                                                                dangerouslySetInnerHTML={{__html:task.GradingComment}}></span>
                                                        </td>
                                                        <td>
                                                            <span className="taskgrade">{task.Grade}</span>
                                                        </td>
                                                    </tr>
                                                )
                                            })


                                        }
                                    </table>
                                </div>
                                <div className="table-responsive">
                                    <h6>Prøver</h6>
                                    <table className="table table-bordered">
                                        <thead>
                                            <th >Tittel</th>
                                            <th >Kommentar</th>
                                            <th >Karakter</th>
                                        </thead>
                                        {
                                            rep.Quis.map((qa) => {
                                                return (
                                                    <tr className="taskrow">
                                                        <td>
                                                            <span className="quizname">{qa.Quiz.Title}</span>
                                                        </td>
                                                        <td>
                                                            <span className="quizcomment remove-para-margin ql-editor" style={{backgroundColor: "transparent"}}
                                                                dangerouslySetInnerHTML={{__html:qa.GradingComment}}></span>
                                                        </td>
                                                        <td>
                                                            <span className="quizgrade">{qa.Grade}</span>
                                                        </td>
                                                    </tr>
                                                )
                                            })


                                        }
                                    </table>
                                </div>
                                <div className="table-responsive">
                                    <h6>Kompetansemål</h6>
                                    <table className="table table-bordered">
                                        <thead>
                                            <th>Kompetansemål</th>
                                            <th>Måloppnåelse</th>
                                        </thead>
                                        {
                                            rep.Kmevals.map((km) => {
                                                return (
                                                    <tr className="kmrow">
                                                        <td className="kmtext">{km.Kmtext}</td>
                                                        <td>
                                                            {
                                                                km.Evals.map((ev) => {
                                                                    console.log(ev);
                                                                    let MyIcon;
                                                                    if(ev==='1')
                                                                    {
                                                                       MyIcon = () => <Icon iconName="TestBeaker" style={{color: '#F0AD4E'}} />;
                                                                    }
                                                                   else if(ev ==='2')
                                                                    {
                                                                       MyIcon = () => <Icon iconName="TestBeakerSolid" style={{color: '#1D4E90'}} />;
                                                                    }
                                                                    else if(ev ==='3')
                                                                    {
                                                                       MyIcon = () => <Icon iconName="TestCase" style={{color: '#5CB85C'}} />;
                                                                    }
                                                                    else
                                                                    { MyIcon = () => <Icon iconName="Error" className="ms-IconExample" />;
                                           
                                                                    }
                                                                    return (
                                                                        <span  className="form-@ev kmeval"><MyIcon></MyIcon></span>
                                                                    )
                                                                })

                                                            }
                                                        </td>
                                                    </tr>
                                                )
                                            })

                                        }
                                    </table>
                                </div>
                                <h6>
                                    Tilbakemelding
                                        </h6>
                                <span className="remove-para-margin ql-editor" style={{backgroundColor: "transparent"}}
                                    dangerouslySetInnerHTML={{__html:rep.ReportText}}>
                                    
                                </span>
                                <h6>
                                    Framovermelding
                                        </h6>
                                <span className="remove-para-margin ql-editor" style={{backgroundColor: "transparent"}}
                                    dangerouslySetInnerHTML={{__html:rep.Future}}>
                                 
                                </span>
                                </div>
                        
                        )
                    })



                }
            </div>
        )
    }


}
function mapStateToProps(state) {
   
    
        return {
            
    
        };
    
    }
export default connect(mapStateToProps, {  })(IupClassReportItemViewStudent)