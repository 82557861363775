import * as React from "react";
import * as _ from "lodash";
import { connect } from "react-redux";
import {
  DefaultButton,
  Icon,
  Dropdown,
  Panel,
  PanelType,
  PrimaryButton,
  Label,
  Stack,
  DropdownMenuItemType,
  IDropdownStyles
} from "office-ui-fabric-react";
import { distributeTemplate } from "../../actions/Action_ScheduleElement";
import ReactMultiSelectCheckboxes from "react-multiselect-checkboxes";
import { Calendar, momentLocalizer } from "react-big-calendar";
import { ScheduleElement } from "../../models/ScheduleElement";
import * as moment from "moment";
import { getInitialDate } from "./TimeplanView";
moment.locale("nb");

export interface ITemplatePanelProps {
  currentElements: any[];
  classid: string;
  weekno: number;
  distributeTemplate(data: any);
  ondone();
  selectedYear: number;
  subscribedSchoolClasses: any;
  selectedclass: string;
  bigCalendarProps: any;
  scheduleElements: ScheduleElement[];
  selectedDate: Date;
}
export interface ITemplatePanelState {
  selectedweeks: any[];
  disable?: boolean;
  selectedclass:any;
  copyMode: string;
  bigCalendarProps: any;
  grayEvents: any;
  selectedDate: Date;
}
class TemplatePanel extends React.Component<
  ITemplatePanelProps,
  ITemplatePanelState
> {
  constructor(props) {
    super(props);
    let tmp = {};
    for (let index = 1; index < 53; index++) {
      tmp[index] = false;
    }
    this.state = {
      selectedweeks: [],
      disable: false,
      selectedclass: this.props.selectedclass,
      copyMode: "",
      bigCalendarProps: {},
      grayEvents: [],
      selectedDate: this.props.selectedDate
    };
  }
  componentDidMount(){
    if(this.props.bigCalendarProps){
      let cloned = JSON.parse(JSON.stringify(this.props.bigCalendarProps.events));
        let grayEvents = JSON.parse(JSON.stringify(this.props.currentElements));
        console.log(grayEvents)
        for(let i = 0; i < grayEvents.length; i++){
          grayEvents[i]["Color"] = "zcopyEventColor";
          grayEvents[i]["Start"] = new Date(grayEvents[i]["Start"]);
          grayEvents[i]["End"] = new Date(grayEvents[i]["End"]);
        }
        console.log(grayEvents, cloned)
          this.setState({
            grayEvents
          })
      }
    this.setState({
      bigCalendarProps : this.props.bigCalendarProps
    }, () =>{
      console.log(this.state.bigCalendarProps)
    })
  }

  getSchoolYear = (year: number, week: number) => {
    if (week >= 30) {
      return `${year}/${year+1}`;
    }
    else {
      return `${year-1}/${year}`;
    }
  }
  onSelectEvent = (e : ScheduleElement) =>{
    if(e.Color === "zcopyEventColor"){
          e.Color = this.props.scheduleElements[`${e.Id}`]["Color"];
        }else{
            e.Color = "zcopyEventColor"
        }
      }

  onDistributePartsClick = () =>{
    let sel = ""
    this.state.selectedweeks.forEach((element) => {
      if (sel.length > 0) {
        sel += "|";
      }
      sel += element.toString();
    });
    let template = {} as any;
        //template.Classid = this.props.classid;
        template.Classid = this.state.selectedclass;
        template.Targets = sel;
        template.Year = this.getSchoolYear(this.props.selectedYear, this.props.weekno);
        
        let eventsToSend =  JSON.parse( JSON.stringify(this.state.grayEvents));
        eventsToSend = this.state.grayEvents.filter((grayEvent) =>{
          return grayEvent.Color !== "zcopyEventColor"
        })
        eventsToSend.forEach(element => {
          element.ClassID = this.state.selectedclass;
          element.ClassName = this.props.subscribedSchoolClasses[this.state.selectedclass].ClassName;
        });
        template.Contents = JSON.stringify(eventsToSend);
        console.log(template, eventsToSend)
        this.props.distributeTemplate(template).then(() => {
          this.props.ondone();
        });
  }
  onDistributeClick = () => {
    console.log(this.props);
    let sel = "";
    this.state.selectedweeks.forEach((element) => {
      if (sel.length > 0) {
        sel += "|";
      }
      sel += element.toString();
    });
    let template = {} as any;
    //template.Classid = this.props.classid;
    template.Classid = this.state.selectedclass;
    template.Targets = sel;
    template.Year = this.getSchoolYear(this.props.selectedYear, this.props.weekno);
    
    let tmpcontents =  JSON.parse( JSON.stringify(this.props.currentElements));
    tmpcontents.forEach(element => {
      element.ClassID = this.state.selectedclass;
      element.ClassName = this.props.subscribedSchoolClasses[this.state.selectedclass].ClassName;
    });
    //template.Contents = JSON.stringify(this.props.currentElements);
    template.Contents = JSON.stringify(tmpcontents);
    console.log(tmpcontents)
    this.props.distributeTemplate(template).then(() => {
      this.props.ondone();
    });
  };
  public render() {
    let localizer = momentLocalizer(moment); 

    // console.log(this.state);
    const options = [];

    if (this.props.weekno >= 30) {
      for (let i = this.props.weekno; i <= 53; i++) {
        options.push({ text: `${i}`, key: i });
      }
      for (let i = 1; i <= 29; i++) {
        options.push({ text: `${i}`, key: i });
      }
    } else if (this.props.weekno <= 29) {
      for (let i = this.props.weekno; i <= 29; i++) {
        options.push({ text: `${i}`, key: i });
      }
    }
    const styles = {
      enabled: { zIndex: 20 },
      disabled: {
        backgroundColor: "gray",
        opacity: "0.8",
        cursor: "not-allowed",
        disabled: "true",
      },
    };

    const dropdownStyles: Partial<IDropdownStyles> = {
      dropdown: { width: 130, zIndex: 11000 },

    };

    let optionKeyList = [];
    _.values(this.props.subscribedSchoolClasses).forEach((se) => {
      optionKeyList.push({ key: se.ClassID, text: se.ClassName });
    });

    return (
      <>
        <Panel
          isLightDismiss
          isOpen={true}
          isBlocking={true}
          onDismiss={() => this.props.ondone()}
          closeButtonAriaLabel="Lukk Panel"
          type={this.state.copyMode == "deleUken" ? PanelType.extraLarge : PanelType.medium}
          headerText="Timeplanmal"
          // style={{ padding: "5px", zIndex: 999 }}
          layerProps={{ styles: { root: { zIndex: 10000 }}}}
        >
          <div style={{ marginBottom: "10px" }}>
            <h2>
              {this.state.copyMode !== "" && 
              <span style={{fontWeight: 900}}>
                   <Icon style={{border: "2px solid green", borderRadius: "50%", marginRight: "15px", padding: "5px", cursor: "pointer"}}iconName="Back" onClick={()=>{this.setState({copyMode: ""})}}/>
              </span>
              }
              <span style={{ fontSize: "bold", color: "red" }}>OBS!</span> Når
              du kopierer timeplan, vil du overskrive det som eventuelt ligger
              av innslag i de ukene du kopierer til.
            </h2>
            {this.state.copyMode === "deleUken" && <h2 className="text-center"><div className="d-flex">
              <span><b>Velg timene du vil kopiere</b></span>
          </div></h2>}

          </div>
          {this.state.copyMode === "heleUken" ? <><hr />
          <h3 style={{ marginBottom: "25px" }}>Velg uker og klasse</h3>
          <div style={{ minHeight: "500px" }}>
            <span style={{ display: "flex", justifyContent: "space-between" }}>
              <Dropdown
                  placeholder="Velg uker"
                  selectedKeys={this.state.selectedweeks}
                  
                  onChange={(a, b) => {
                    if(b.selected) {
                      let newArray = this.state.selectedweeks.concat(b.key); 
                      this.setState({selectedweeks: newArray})
                    }
                    else {
                      let newArray = this.state.selectedweeks.filter(key => key !== b.key)
                      this.setState({selectedweeks: newArray});
                    }
                  }}
                  multiSelect
                  options={options}
                  styles={dropdownStyles}
                  
                />
              <Dropdown  placeHolder="Velg klasse"
              selectedKey={this.state.selectedclass} options={optionKeyList} onChange={(a,b)=>{console.log('a',a);console.log('b',b);this.setState({selectedclass:b.key});}}></Dropdown>
              <PrimaryButton
                onClick={() => this.onDistributeClick()}
                disabled={this.state.selectedweeks.length <= 0}
                style={
                  this.state.selectedweeks.length > 0
                    ? styles.enabled
                    : styles.disabled
                }
              >
                Kopier til uke(r)
              </PrimaryButton>
            </span>
          </div></> : this.state.copyMode === "deleUken" ? <>
          <hr/>
          <div>
          <span style={{ display: "flex", justifyContent: "space-between"}}>
              <Dropdown   placeHolder="Velg klasse"
                selectedKey={this.state.selectedclass} options={optionKeyList} onChange={(a,b)=>{console.log('a',a);console.log('b',b);this.setState({selectedclass:b.key});}}> 
              </Dropdown>
              <Stack horizontal>
                <Dropdown
                  placeholder="Velg uker"
                  selectedKeys={this.state.selectedweeks}
                  
                  onChange={(a, b) => {
                    if(b.selected) {
                      let newArray = this.state.selectedweeks.concat(b.key); 
                      this.setState({selectedweeks: newArray})
                    }
                    else {
                      let newArray = this.state.selectedweeks.filter(key => key !== b.key)
                      this.setState({selectedweeks: newArray});
                    }

                  }}
                  multiSelect
                  options={options}
                  styles={dropdownStyles}
                  
                />
                <PrimaryButton
                  onClick={() => this.onDistributePartsClick()}
                  disabled={this.state.selectedweeks.length <= 0}
                  style={
                    this.state.selectedweeks.length > 0
                      ? styles.enabled
                      : styles.disabled
                  }
                >
                  Kopier til uke(r)
                </PrimaryButton>
              </Stack>
            </span>
              <hr></hr>
          <Calendar
              localizer={localizer}
              events={this.state.grayEvents}
              min={moment(this.state.selectedDate).set("hour", 8).set("minutes", 0).toDate()}
              max={moment(this.state.selectedDate).set("hour", 17).set("minutes", 0).toDate()}
              //color={this.state.bigCalendarProps.color}
              defaultView={"work_week"}
              eventPropGetter={this.state.bigCalendarProps.eventPropGetter}
              // views={['work_week']}
              views={this.state.bigCalendarProps.views}
              onSelectEvent={(e) => {this.onSelectEvent(e as ScheduleElement)}}
              toolbar={false}
              // onSelectEvent={this.onSelectEvent}
              dayLayoutAlgorithm="no-overlap"
              titleAccessor="Title"
              startAccessor="Start"
              endAccessor="End"
              //date={new Date(this.state.selectedDate.setHours(8)).setMinutes(0, 0, 0)}
              date={new Date(new Date(this.state.selectedDate.setHours(8)).setMinutes(0, 0, 0))}
            />
            </div></>: 
          <><hr/>
          <PrimaryButton onClick={() =>{this.setState({copyMode: "heleUken"})}}>Kopier hele uken</PrimaryButton>
          <PrimaryButton onClick={() =>{this.setState({copyMode: "deleUken"})}}>Kopier deler av uken</PrimaryButton>
          </>}
        </Panel>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  const { selectedclass, selectedWeek } = state.FilterReducer;
  return {
    subscribedSchoolClasses: state.teacher.subscribedSchoolClasses,
    selectedclass: state.scheduleElements.selectedclass,
    scheduleElements: state.scheduleElements,
    selectedWeek,
  };
};

export default connect(mapStateToProps, { distributeTemplate })(TemplatePanel);
