import * as React from "react";
import { connect } from "react-redux";
import HeaderView from "../view_containers/HeaderView";
import CourseContainer from "./CourseContainer";
import CourseContainerTeams from '../teams/CourseContainerTeams';
import { Dropdown, Spinner, SpinnerSize } from "office-ui-fabric-react";
import { getTeacherState } from "../../actions/Action_Teacher";
import {
  getCurrentTaskElements,
  getLastTaskElements,
  getSavedTaskElements,
  getTasksReadyForGrading
} from "../../actions/Action_Innleveringer";
import { setFilterTasks } from "../../actions/Action_Filtervalues";
import {
  getAllClasses,
  getAllClassAssignments,
} from "../../actions/Action_Graph";
import { getAssignmentGroup } from "../../actions/Action_Ukeplan";
import "./innlevering.scss";
import { CommandBarButton } from "office-ui-fabric-react";
import SidebarActionButtonList from "../sidebar/SidebarActionButtonList";

export interface IInnleveringerViewProps {
  events: any;
  schoolClasses: any;
  teacherstate: any;
  tasks: any;
  getCurrentTaskElements(teacherState: any, callback?, errorCallback?);
  getLastTaskElements(teacherState: any, callback?, errorCallback?);
  getSavedTaskElements(teacherState: any, callback?, errorCallback?);
  getTasksReadyForGrading(teacherState: any, callback?, errorCallback?);
  getTasks
  getAssignmentGroup(course: any);
  setFilterTasks(filtervalues: any);
  getAllClasses();
  getAllClassAssignments(classid: string);
  history: any;
  groups: any[];
  defaultkey: number;
  coursefilter: any;
  graphClasses: any[];
}
export interface IInnleveringerViewState {
  coursefilter: string;
  groupfilter: number;
  loadingTasks: boolean;
}

class InnleveringerView extends React.Component<
  IInnleveringerViewProps,
  IInnleveringerViewState
> {
  componentDidMount() {
    // switch (this.props.defaultkey) {
    //   case 1:
    //     this.props.getCurrentTaskElements(this.props.teacherstate);
    //     break;
    //   case 2:
    //     this.props.getLastTaskElements(this.props.teacherstate);
    //     break;
    //   case 3:
    //     this.props.getSavedTaskElements(this.props.teacherstate);
    //     break;
    //   case 4:
    //     this.props.getTasksReadyForGrading(this.props.teacherstate);
    //     break;
    //   default:
    // }
    this.onSelectStatus(this.props.defaultkey);
    if (this.props.coursefilter.length > 0) {
      this.setState({ coursefilter: this.props.coursefilter });
      this.props.getAssignmentGroup({ CourseID: this.props.coursefilter });
    }

    // this.props.getAllClasses().then(()=>{
    //   console.log('theclasses',this.props.graphClasses);
    //   this.props.graphClasses.forEach(element => {
    //     this.props.getAllClassAssignments(element.id);
    //   });
    // });
    // this.props.getCurrentTaskElements(this.props.teacherstate);
  }
  constructor(props) {
    super(props);
    this.state = { coursefilter: "0", groupfilter: 0, loadingTasks: false };
  }

  onSelectStatus(key) {
    // const { key } = status;
    this.setState({loadingTasks: true});
    switch (key) {
      case 1:
        this.props.getCurrentTaskElements(this.props.teacherstate, this.onTasksReturned, this.onGetTasksError);
        break;
      case 2:
        this.props.getLastTaskElements(this.props.teacherstate, this.onTasksReturned, this.onGetTasksError);
        break;
      case 3:
        this.props.getSavedTaskElements(this.props.teacherstate, this.onTasksReturned, this.onGetTasksError);
        break;
      case 4:
        this.props.getTasksReadyForGrading(this.props.teacherstate, this.onTasksReturned, this.onGetTasksError);
        break;
      default:
        this.setState({loadingTasks: false});
        break;
    }
  }

  onTasksReturned = () => {
    this.setState({loadingTasks: false});
  }

  onGetTasksError = () => {
    this.setState({loadingTasks: false});
  }

  onSelectCourse = (course, val) => {
    const { key } = course;
    this.setState({ coursefilter: course.key });
    this.props.getAssignmentGroup({ CourseID: course.key });
    this.props.setFilterTasks({ selectedcourse: course.key });
  };

  onSelectFilter = (filter, val) => {
    const { key } = filter;
    this.setState({ groupfilter: filter.key });
  };

  render() {
    let fagoptions = [{ key: "0", text: "Alle" }];
    let dict = {};
    this.props.tasks.forEach((element) => {
      if (!dict[element.CourseID]) {
        dict[element.CourseID] = element;
        fagoptions.push({ key: element.CourseID, text: element.CourseName });
      }
    });
    fagoptions.sort((a, b) => {
      return a.text > b.text ? 1 : b.text > a.text ? -1 : 0;
    })
    let groupoptions = this.props.groups;

    groupoptions.sort((a, b) => {
      return a.text > b.text ? 1 : b.text > a.text ? -1 : 0;
    })
    return (
      <div>
        <SidebarActionButtonList>
          <CommandBarButton
            onClick={() => this.props.history.push("/task/ny_innlevering/n")}
            className="sidenav-buttons"
            iconProps={{ iconName: "Add" }}
            text="Lag ny innlevering"
          ></CommandBarButton>
        </SidebarActionButtonList>
        <div className="ms-Grid" id="TeacherTasks">
          <div className="container main">
            <HeaderView
              title="Innleveringer"
              description="Her finner du en oversikt over innleveringer du har delt ut"
              iconName="CompletedSolid"
            />
            <div className="ms-Grid-row topTool ms-bgColor-neutralLight">
              <div className="ms-Grid-col ms-sm12 ms-md4">
                <Dropdown
                  defaultSelectedKey={this.props.defaultkey}
                  placeHolder="Velg status"
                  label="Status"
                  id="selCourseStatus"
                  onChanged={(status) => {
                    const { key } = status;
                    // switch (key) {
                    //   case 1:
                    //     this.props.getCurrentTaskElements(
                    //       this.props.teacherstate
                    //     );
                    //     break;
                    //   case 2:
                    //     this.props.getLastTaskElements(this.props.teacherstate);
                    //     break;
                    //   case 3:
                    //     this.props.getSavedTaskElements(
                    //       this.props.teacherstate
                    //     );
                    //     break;
                    //   case 4:
                    //     this.props.getTasksReadyForGrading(this.props.teacherstate);
                    //     break;
                    //   default:
                    // }
                    this.onSelectStatus(key);
                  }}
                  options={[
                    { key: 1, text: "Frist fram i tid" },
                    { key: 4, text: "Klar for vurdering"},
                    { key: 2, text: "Frist siste 30 dager" },
                    { key: 3, text: "Mine lagrede" },
                  ]}
                />
              </div>
              <div className="ms-Grid-col ms-sm12 ms-md4">
                <Dropdown
                  placeHolder="Alle"
                  defaultSelectedKey={this.props.coursefilter}
                  label="Fag"
                  id="selCourseSubject"
                  onChanged={this.onSelectCourse}
                  options={fagoptions}
                />
              </div>
              <div className="ms-Grid-col ms-sm12 ms-md4">
                <Dropdown
                  placeHolder="Alle"
                  label="Tilretteleggingsgruppe"
                  id="selCourseFilter"
                  onChanged={this.onSelectFilter}
                  options={groupoptions}
                />
              </div>
            </div>

            <div className="ms-Grid-row">
              <div className="ms-Grid-col ms-sm12">

                {!this.state.loadingTasks ? this.props.tasks.map((t) => {
                  //console.log("t", t);
                  if (
                    (this.state.coursefilter === "0" ||
                      this.state.coursefilter === t.CourseID) &&
                    (this.state.groupfilter === 0 ||
                      this.state.groupfilter === t.GroupID)
                  ) {
                    if (t.TeamsTask) {
                      return (
                        <CourseContainerTeams
                          history={this.props.history}
                          task={t}
                        ></CourseContainerTeams>
                        // <CourseContainer
                        //   history={this.props.history}
                        //   task={t}
                        // ></CourseContainer>
                      );
                    }
                    return (
                      <CourseContainer
                        history={this.props.history}
                        task={t}
                      ></CourseContainer>
                    );
                  }
                }) : <Spinner size={SpinnerSize.large}></Spinner>}

                
                {/* <CourseContainer/>
                            <CourseContainer/>
                            <CourseContainer/> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  console.log('state', state);
  let t = [];
  let g = [];
  g.push({ key: 0, text: "Alle" });
  if (state.ukeplanelements.ag) {
    state.ukeplanelements.ag.forEach((element) => {
      g.push({ key: element.Id, text: element.Name });
    });
  }
  if (state.tasks.Tasks) {
    if (state.tasks.Tasks.length) {
      state.tasks.Tasks.forEach((element) => {
        t.push(element);
      });
    }
  }
  let defaultmode = 1;
  if (state.tasks.mode) {
    defaultmode = state.tasks.mode;
  }
  let selectedcourse = "";
  if (
    state.FilterReducer.TaskFilters &&
    state.FilterReducer.TaskFilters.selectedcourse
  ) {
    selectedcourse = state.FilterReducer.TaskFilters.selectedcourse;
  } else {
  }
  return {
    teacherstate: state.teacher,
    tasks: t,
    groups: g,
    defaultkey: defaultmode,
    coursefilter: selectedcourse,
    graphClasses: state.GraphReducer.allclasses,
  };
}

export default connect(mapStateToProps, {
  getCurrentTaskElements,
  getLastTaskElements,
  getSavedTaskElements,
  getTasksReadyForGrading,
  getAssignmentGroup,
  setFilterTasks,
  getAllClasses,
  getAllClassAssignments,
})(InnleveringerView);
