import * as React from "react";
import {Field, reduxForm, change, getFormValues} from "redux-form";
import {withRouter, Link} from "react-router-dom";
import {connect} from "react-redux";
import {Label, ActionButton, PrimaryButton, IconButton, DefaultButton, Dialog, DialogFooter, Spinner, SpinnerSize, DialogType} from "office-ui-fabric-react";
import {planUtils} from "../ukeplan/PlanElementEditView";
import KmpgoalPicker from "../shared/KmpgoalPicker";
import {Panel, PanelType} from "office-ui-fabric-react/lib/Panel";
import {
    getQuestions,
    getAssignmentGroup,
    saveQuiz,
    deleteQuiz,
    deleteQuizQuestion,
    copyQuestions
} from "../../actions/Action_Quiz";
import { setstatus, getStudentsFromCourse } from "../../actions/Action_Teacher";
import QuestionForm from "./QuestionForm";
import "../../../../src/app/formelements.scss";
import InputField from "../form_fields/InputField";
import SelectField from "../form_fields/SelectField";
import DateTimeField from "../form_fields/DateTimeField";
import CheckboxField from "../form_fields/CheckboxField";
import {
    dateStringToMoment,
    isDateStringBeforeToday
} from "../../utils/DateUtils";
import {Question} from "../../models/Question";
import { isSameGroupAsBefore } from "../../guards/CopyPlanGuard";
import MultiSelectField from "../form_fields/MultiSelectField";
import axios from "axios";
import { ROOT_URL } from "../../actions/constants";

export interface IQuizFormProps {
    formValues: any;
    history: any;
    courses: any;
    assignmentGroups: any;
    students: any[];
    setstatus(statedata:any);
    getQuestions(Id: number);

    getAssignmentGroup(course: any);
    getStudentsFromCourse(courseID: string);
    saveQuiz(quiz: any);

    deleteQuiz(quiz: any);
    deleteQuizQuestion(question:any);
    questions: any[];
    initialvalues: any;
    handleSubmit: any;
    change: (key: string, value) => void;

    onsave();

    oncopy();

    ondelete();

    hasattempts: boolean;
    match: any;
    mode: string;
}

export interface IQuizFormState {
    isNewQuiz: boolean;
    showkmp: boolean;
    selected: any[];
    questions: any[];
    showquestionform: boolean;
    editquestion: any;
    saving: boolean;
    showCopyPlanDialog: boolean;
    loadingStudents: boolean;
    assignmentgroupStudents: any[];
}

class QuizForm extends React.Component<IQuizFormProps, IQuizFormState> {
    constructor(props) {
        super(props);
        let seltmp = this.props.initialvalues.GrepCodeList;
        if (!seltmp) {
            seltmp = [];
        }

        const {id} = this.props.match.params;

        this.state = {
            isNewQuiz: id === "nytt_innslag",
            showkmp: false,
            selected: seltmp,//this.props.initialvalues.GrepCodeList,
            questions: [],
            showquestionform: false,
            editquestion: {},
            saving: false,
            showCopyPlanDialog: false,
            loadingStudents: false,
            assignmentgroupStudents: []
        };
        this.onFormSubmit = this.onFormSubmit.bind(this);
    }

    /*****************************
     Lifecycle hooks
     *****************************/

    async componentWillReceiveProps(nextProps: IQuizFormProps) {
        
        // Setter tilretteleggingsgruppe til initiell verdi dersom man bytter modus
        if (nextProps.mode != this.props.mode) {
            this.props.getAssignmentGroup({CourseID: this.props.initialvalues.CourseId});
            await this.props.getStudentsFromCourse(this.props.initialvalues.CourseID)
            this.props.change("CourseId", this.props.initialvalues.CourseId);
            this.props.change("AssignmentGroupID", this.props.initialvalues.AssignmentGroupID);
            this.props.change("Elever", this.props.initialvalues.Elever);
        }
    }
    

    componentWillMount() {
        if (this.props.initialvalues.Id != 0) {
            this.props.getQuestions(this.props.initialvalues.Id);
        } else {
            this.props.getQuestions(303);
        }
    }
    
    async componentDidMount() {
        if (this.props.formValues.AssignmentGroupID) {
            await this.getAssignmentGroupStudents(this.props.formValues.AssignmentGroupID);
        }
        if (this.props.initialvalues.CourseId && this.props.initialvalues.CourseID != "") {
            this.props.getAssignmentGroup({CourseID: this.props.formValues.CourseId});
            await this.props.getStudentsFromCourse(this.props.initialvalues.CourseId);
        }
    }

    /*****************************
     Mapping methods
     *****************************/

    public mapGroupsToKeys(options: any[]) {
        const optionKeyList = [];
        optionKeyList.push({key: 0, text: "Alle"});
        if (options) {
            options.forEach(se => {
                optionKeyList.push({key: se.Id, text: se.Name});
            });
        }

        return optionKeyList;
    }

    public getNumQuestionsOptions = () => {
        const optionKeyList = [];
        optionKeyList.push({key: 0, text: "Alle"});

        this.props.questions.forEach((question, index) => {
            const key = index + 1;
            optionKeyList.push({key, text: key});
        });

        return optionKeyList;
    };

    /*****************************
     Helper methods
     *****************************/

    getAssignmentGroupStudents = async (GroupID: string) => {
        try {
            this.setState({loadingStudents: true})
            const result = await axios.post(`${ROOT_URL}/GetStudentsGroup`, { Id: GroupID }, {
                headers: {
                    Authorization: localStorage.getItem("id_token"),
                },
            });
                
            this.setState({ assignmentgroupStudents: result.data });
        }
        catch (error) {
      
        }
        finally {
            this.setState({loadingStudents: false})
        }
    };

    /*****************************
     Event handlers
     *****************************/

    onCourseSelected = async (CourseID: string) => {
        this.props.getAssignmentGroup({CourseID});
        this.props.change("AssignmentGroupID", 0);
        this.props.change("Elever", "");
        await this.props.getStudentsFromCourse(CourseID);
    };

    onAssignmentGroupSelected = async (GroupID: string) => {
        this.props.change("AssignmentGroupID", GroupID);


        try {
            this.setState({loadingStudents: true})
            const result = await axios.post(`${ROOT_URL}/GetStudentsGroup`, { Id: GroupID }, {
              headers: {
                Authorization: localStorage.getItem("id_token"),
              },
            });
              
            this.setState({ assignmentgroupStudents: result.data });
            this.props.change("Elever", "");
              
          }
          catch (error) {
      
          }
          finally {
            this.setState({loadingStudents: false})
          }
    };

    onNumRandomQuestionsSelected = value => {
   
        this.props.change("RandomSelection", value);
    };

    onFormSubmit = values => {

        values.GrepCodeList = this.state.selected;

        this.props.saveQuiz(values).then(() => {
            this.props.onsave();
        }).catch(e => {
            this.setState({
				saving: false,
				showCopyPlanDialog: false
			});
        });
    };

    onDeleteClick() {

        const id = this.props.initialvalues.Id;
        if (isNaN(id)) {
            return;
        }

        if (confirm('Vil du slette prøven? Merk at elevbesvarelser også vil bli slettet')) {
            this.props.deleteQuiz({Id: id}).then(() => {
                this.props.ondelete();
            })
        }
    }

    /*****************************
     Render methods
     *****************************/

    renderKompetansemaal = () => (
        <div className="ms-Grid-row">
            <div className="z-form-group">
                <div className="ms-Grid-col ms-sm12 ms-md4">
                    <Label className="z-form-label">Kompetansemål</Label>
                </div>
                <div className="ms-Grid-col ms-sm12 ms-md8">
                    {this.state.selected && (
                        <KmpgoalPicker
                            selected={this.state.selected}
                            onClose={selected => {
                                this.setState({selected: selected});
                            }}
                        />
                    )}

                    <ul className="no-type-list">
                        {this.state.selected &&
                        this.state.selected.map(grp => {
                            return (
                                <li className="ms-Grid-row">
                                    <div className="ms-Grid-col ms-sm1">
                                        <IconButton
                                            className="vertical-align-middle green"
                                            iconProps={{iconName: "BoxSubtractSolid"}}
                                            title="Fjern kompetansemål"
                                            onClick={() => {
                                                let tmp = [];

                                                this.state.selected.forEach(element => {
                                                    if (grp.KMID != element.KMID) {
                                                        tmp.push(element);
                                                    }
                                                });
                                                this.setState({selected: tmp});
                                            }}
                                        />
                                    </div>
                                    <div className="ms-Grid-col ms-sm11 pt-5">
                                        {grp.KMID} | {grp.LPID} | {grp.GrepTekst}
                                    </div>

                                </li>
                            );
                        })}
                    </ul>
                </div>
            </div>
        </div>
    );

    
    renderQuestionsField = () => {
        return (
            <div className="ms-Grid-row">
                <div className="z-form-group" style={{marginBottom: 30, overflow: 'auto'}}>
                    <div className="ms-Grid-col ms-sm12 ms-md4">
                        <Label className="z-form-label">Spørsmål</Label>
                    </div>
                    <div className="ms-Grid-col ms-sm12 ms-md8">
                        {!this.props.questions.length && <p style={{marginTop: 5}}>Ingen spørsmål</p>}
                        <ul
                            className="no-type-list"
                            style={{display: "flex", flexDirection: "column", flexGrow: 1}}
                        >
                            {this.props.questions.map((question, index) =>
                                this.renderQuestion(question)
                            )}
                        </ul>
                    </div>
                </div>
            </div>
        );
    };

    renderQuestion = (question: Question) => {
        return (
            <li style={{display: "flex", flexDirection: "row", flexGrow: 1, marginBottom: 10}}>
                <div style={{flexGrow: 1, fontSize: "18px"}}>
                    {`${question.Numbering}. ${question.QText}`}
                </div>
                {
                    !this.props.hasattempts &&
                    <div style={{justifyContent: "flexEnd"}}>
                        <i
                            style={{
                                cursor: "pointer",
                                fontSize: "18px",
                                color: "green",
                                marginRight: 10,
                                marginLeft: 10
                            }}
                            className="ms-Icon ms-Icon--EditMirrored editQuestion"
                            aria-hidden="true"
                            title="Rediger spørsmål"
                            onClick={() =>
                                this.setState({showquestionform: true, editquestion: question})
                            }
                        />
                        <i
                            style={{
                                cursor: "pointer",
                                fontSize: "18px",
                                color: "red",
                                marginRight: 10,
                                marginLeft: 10
                            }}
                            className="ms-Icon ms-Icon--Delete deleteQuestion"
                            aria-hidden="true"
                            title="Slett spørsmål"
                            onClick={() =>
                                this.props.deleteQuizQuestion({Id:question.Id}).then(()=>{
                                    this.props.getQuestions(this.props.initialvalues.Id);
                                })
                            }
                        />
                    </div>
                }

            </li>
        );
    };

    public render() {
        const {handleSubmit} = this.props;
        return (
            <>
                <form>
                    <div className="form-content">
                        {
                            this.props.hasattempts &&
                            <div className="alert alert-warning mb-3">
                                <i className="ms-Icon ms-Icon--InfoSolid" aria-hidden="true"></i>{" "}
                                Denne testen har besvarelser og kan derfor ikke redigeres. Du kan
                                fortsatt kopiere den og dele ut på nytt.
                            </div>
                        }

                        <Field
                            name="Title"
                            label="Prøvetittel"
                            required={true}
                            component={InputField}
                        />

                        <Field
                            name="CourseId"
                            label="Fag"
                            required={true}
                            component={SelectField}
                            onSelect={this.onCourseSelected}
                        >
                            <option>
                                {planUtils.mapPlanElementsToKeys(this.props.courses)}
                            </option>
                        </Field>

                        <Field
                            name="AssignmentGroupID"
                            label="Tilretteleggingsgruppe"
                            required={true}
                            disabled={!this.props.formValues.CourseId}
                            component={SelectField}
                            onSelect={this.onAssignmentGroupSelected}
                        >
                            <option>
                                {/* {this.mapGroupsToKeys(this.props.assignmentGroups)} */}
                                {planUtils.mapGroupElementsToKeys(this.props.assignmentGroups, true)}
                            </option>
                        </Field>

                        <Field
                            name="Elever"
                            label="Elever"
                            component={(props) => 
                            <MultiSelectField
                                label = {props.label}
                                input = {props.input}
                                required = {props.required}
                                disabled = {this.props.formValues.CourseId == null || this.props.formValues.AssignmentGroupID == 0}
                                onSelect = {props.onSelect}
                                defaultValue = {props.defaultValue}
                                placeholder = {props.placeHolder}
                                options = {this.props && this.props.students && this.props.students.length > 0 ? planUtils.mapStudentsToKeys(this.props.students) : []}
                                lockedDefaultOptions = {this.state && this.state.assignmentgroupStudents && this.state.assignmentgroupStudents.length > 0 ? planUtils.mapStudentsToKeys(this.state.assignmentgroupStudents) : []}
                                meta = {props.meta}
                                children = {props.children}
                                isLoading = {this.state.loadingStudents}
                                // attemptedToSave={this.state.attemptedToSave}
                            ></MultiSelectField>}
                            required={false}
                            disabled={!this.props.formValues.CourseID}
                            onSelect={() => {}}

                            >
                            <option>
                                {this.props && this.props.students && this.props.students.length > 0 ? planUtils.mapStudentsToKeys(this.props.students) : []}
                            </option>
                        </Field>

                        {this.renderKompetansemaal()}
                        <Field
                            name="ValidFrom"
                            label="Gyldig fra"
                            dateFormat="DD.MM.YYYY"
                            timeFormat="HH:mm"
                            showTime={true}
                            required={true}
                            component={DateTimeField}
                        />

                        <Field
                            name="ValidTo"
                            label="Gyldig til"
                            dateFormat="DD.MM.YYYY"
                            timeFormat="HH:mm"
                            showTime={true}
                            required={true}
                            component={DateTimeField}
                        />

                        <Field
                            name="Duration"
                            label="Varighet (minutter)"
                            required={true}
                            isNumeric={true}
                            component={InputField}
                        />

                        <Field
                            name="RandomSelection"
                            label="Antall tilfeldige spørsmål per elev"
                            disabled={!this.props.questions || !this.props.questions.length}
                            component={SelectField}
                            onSelect={this.onNumRandomQuestionsSelected}
                        >
                            <option>{this.getNumQuestionsOptions()}</option>
                        </Field>
                        <br/><br/>
                        <Field
                            name="RandomSort"
                            label="Tilfeldig Sortering"
                            component={CheckboxField}
                        />
                        <Field
                            name="ShowPortfolio"
                            label="Vise i portefølje"
                            component={CheckboxField}
                        />
                        <Field
                            name="ShowScore"
                            label="Vise poengscore"
                            component={CheckboxField}
                        />
                        <Field
                            name="ShowFasit"
                            label="Vis fasit når tiden er ute"
                            component={CheckboxField}
                        />
                        
                        {this.renderQuestionsField()}
                        {!this.props.hasattempts && (
                            <div className="form-footer-left">
                                <ActionButton
                                    iconProps={{iconName: "Add"}}
                                    data-sourcediv="newmessagepreload"
                                    data-sourceurl="/v2Quiz/V2QuizForm"
                                    onClick={() =>
                                        this.setState({
                                            editquestion: {
                                                Id: 0,
                                                QuizID: this.props.initialvalues.Id,
                                                QMinPoints: 0,
                                                QMaxPoints: 0
                                            },
                                            showquestionform: true
                                        })
                                    }
                                >
                                    Legg til spørsmål
                                </ActionButton>
                                {/* <ActionButton iconProps={{iconName: "Add"}}>
                                    Hent spørsmål knyttet til prøvens kompetansemål
                                </ActionButton> */}

                            </div>
                        )}
                    </div>
                    <div className="form-footer">
                        <div className="form-footer-left">
                            {(!this.state.isNewQuiz && this.props.mode != "kopier") &&
                                <span>
                                    <ActionButton
                                    style={{float: "left"}}
                                    iconProps={{iconName: 'Delete'}}
                                    onClick={this.onDeleteClick.bind(this)}>
                                        Slett
                                    </ActionButton>
                                    <ActionButton
                                        style={{float: "left"}}
                                        text='Kopier til ny gruppe'
                                        iconProps={{iconName: 'Copy'}}
                                        onClick={() => {
                                            this.props.oncopy();
                                        }}
                                    />
                                </span>
                            }
                        </div>
                        <div className="form-footer-right">
                            <DefaultButton
                                onClick={() => {
                                    this.props.history.goBack();
                                }}
                                style={{ marginRight: 5 }}
                                >
                                Avbryt
                            </DefaultButton>
                            <PrimaryButton
                                //   onClick={handleSubmit(this.onFormSubmit.bind(this))}
                                onClick={() => {
                                    if (!this.state.saving && this.props.mode == "kopier" && isSameGroupAsBefore({courseId: this.props.formValues.CourseID, groupId: this.props.formValues.AssignmentGroupID}, {courseId: this.props.initialvalues.CourseID, groupId: this.props.initialvalues.AssignmentGroupID}))
                                    {
                                        this.setState({showCopyPlanDialog: true});
                                    }
                                    else {
                                        if (!this.state.saving && Object.keys(validate(this.props.formValues)).length == 0) {
                                            this.setState({
                                                    saving: true
                                                }, handleSubmit(this.onFormSubmit.bind(this)));
                                            }
                                        }
                                    }
                                }
                            >
                                {!this.state.saving ? "Lagre" : 
                                <Spinner
                                    size={SpinnerSize.small}
                                ></Spinner>}
                            </PrimaryButton>    
                        </div>   
                    </div>
                    <Dialog
					    hidden={!this.state.showCopyPlanDialog}
					    onDismiss={() => {
					    	this.setState({saving: false, showCopyPlanDialog: false})
					    }}
					    dialogContentProps = {{title: "Obs!", styles: {subText: {fontSize: "15px"}}, subText: "Du er ferd med å lagre prøven uten å endre gruppe. Er du sikker på at du vil lagre?", type: DialogType.normal}}
				    >
					    <DialogFooter>
					    	<PrimaryButton onClick={() => {
					    			this.setState({showCopyPlanDialog: false, saving: true});
					    			handleSubmit(this.onFormSubmit.bind(this));
					    			this.onFormSubmit(this.props.formValues);
					    		}}
					    		text="Lagre" />
					    	<DefaultButton onClick={() => {
					    		this.setState({saving: false, showCopyPlanDialog: false})
					    	}} text="Avbryt" />
					    </DialogFooter>
				    </Dialog>
                </form>

                <Panel
                    isOpen={this.state.showkmp}
                    type={PanelType.medium}
                    //   onDismiss={this._hidePanel}
                    headerText="Velg kompetansemål"
                    closeButtonAriaLabel="Lukk"
                >
                    <div style={{height: "80vh"}}>
                        <KmpgoalPicker
                            selected={this.state.selected}
                            onClose={selected => {
                                this.setState({showkmp: false, selected: selected});
                            }}
                        />
                    </div>
                </Panel>

                <Panel
                    isOpen={this.state.showquestionform}
                    type={PanelType.medium}
                    onDismiss={() => this.setState({showquestionform: false})}
                    headerText="Rediger spørsmål"
                    closeButtonAriaLabel="Lukk"
                >
                    <div style={{height: "80vh"}}>
                        <QuestionForm
                            initialValues={this.state.editquestion}
                            question={this.state.editquestion}
                            onClose={() => {
                                this.setState({showquestionform: false});
                                this.props.getQuestions(this.props.initialvalues.Id);
                            }}
                            onContinue={() => {
                                this.setState({editquestion: {Id: -1}})
                                this.setState({
                                    editquestion: {
                                        Id: 0,
                                        QuizID: this.props.initialvalues.Id
                                    },
                                    showquestionform: true
                                })
                                this.props.getQuestions(this.props.initialvalues.Id);
                            }}
                        />
                    </div>
                </Panel>
            </>
        );
    }
}

function validate(values) {
    const errors: any = {};

    // Hvis tittel ikke er oppgitt
    if (!values.Title) {
        errors.Title = "Dette feltet er påkrevd.";
    }

    // Hvis ikke fag er valgt
    if (!values.CourseId) {
        errors.CourseId = "Dette feltet er påkrevd.";
    }

    // Hvis ikke tilretteleggingsgruppe er valgt
    // if (!values.AssignmentGroupID && values.AssignmentGroupID != 0) {
    //     errors.AssignmentGroupID = "Dette feltet er påkrevd.";
    // }

    if (values.CourseId && ((values.AssignmentGroupID === null || values.AssignmentGroupID === "") && (values.Elever == null || values.Elever == ""))) {
        errors.AssignmentGroupID = "Innleveringen må ha gyldige mottakere - velg inn elever i feltet under.";
        errors.Elever = "Innleveringen må ha gyldige mottakere.";
      }

    // Hvis ikke 'Gyldig Fra' er oppgitt
    if (!values.ValidFrom) {
        errors.ValidFrom = "Dette feltet er påkrevd.";
    }

    // Hvis ikke 'Gyldig til' er oppgitt
    if (!values.ValidTo) {
        errors.ValidTo = "Dette feltet er påkrevd.";
    }

    // Hvis 'Gyldig til' er tidligere enn i dag
    if (isDateStringBeforeToday(values.ValidTo)) {
        errors.ValidTo = "Kan ikke være tidligere enn i dag.";
    }

    // Hvis begge datoene er satt, men 'Gydlig til' er tidligere enn 'Gyldig fra'
    if (
        values.ValidFrom &&
        values.ValidTo &&
        dateStringToMoment(values.ValidTo) < dateStringToMoment(values.ValidFrom)
    ) {
        errors.ValidTo = 'Kan ikke være tidligere enn "Gyldig Fra".';
    }

    // Hvis varighet ikke er satt
    if (values.Duration === undefined) {
        errors.Duration = "Dette feltet er påkrevd.";
    }

    // Hvis variget er satt, men ikke er et positivt heltall
    if (
        values.Duration &&
        (values.Duration < 1 || !Number.isInteger(parseFloat(values.Duration)))
    ) {
        errors.Duration = "Varighet må være et positivt heltall.";
    }

    return errors;
}

const mapStateToProps = (state, ownProps) => {
    let assign = [];
    if (state.Quiz.ag && state.Quiz.ag.length > 0) {
        assign = state.Quiz.ag;
    }

    if (state.Quiz.questions && state.Quiz.questions.length > 0) {

        for (let counter = 0; counter < state.Quiz.questions.length; counter++) {
            if (state.Quiz.questions[counter]) {
                state.Quiz.questions[counter].Numbering = counter + 1;
            }

        }
    }

    let studentsFromCourse = [];

    if (state.teacher.studentsFromCourse) {
        studentsFromCourse = state.teacher.studentsFromCourse;
    }

    return {
        assignmentGroups: assign || [],
        courses: state.teacher.subscribedCourses,
        planElements: state.ukeplanelements.planelements,
        questions: state.Quiz.questions || [],
        initialvalues: ownProps.initialValues,
        formValues: getFormValues("QuizForm")(state),
        hasattempts: state.Quiz.quiz.Attempts.length > 0,
        students: studentsFromCourse
    };
};

export default reduxForm({
    form: "QuizForm",
    validate,
    enableReinitialize: true,
    onSubmitFail: () => {
        document.getElementById("content-view").scrollIntoView();
    }
})(
    connect(mapStateToProps, 
        {
            getQuestions, 
            getAssignmentGroup, 
            saveQuiz, 
            deleteQuiz, 
            copyQuestions,
            deleteQuizQuestion,
            setstatus,
            getStudentsFromCourse
        })(
        withRouter(QuizForm)
    )
);
