import * as React from 'react';
import * as moment from 'moment';
import { connect } from 'react-redux';
moment.locale('nb');
import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from 'react-html-parser';

import StudentNewsElementView from '../student/StudentNewsElementView';
import StudentPlanElementView from '../student/StudentPlanElementView';
import StudentTaskelementView from '../student/StudentTaskElementView'; 
export interface IStudentCourselementViewViewProps {
    teacherassignment: any;
}
export interface IStudentCourselementViewViewState {

}


class StudentCourseElementView extends React.Component<IStudentCourselementViewViewProps, IStudentCourselementViewViewState>{
    constructor(props) {
        super(props);
    }
    render() {
        return (
            <div className="item courseitem">
                <div className="panel panel-default" id={this.props.teacherassignment.CourseCategory}>
                    <div className="panel-heading">
                        <h2>{this.props.teacherassignment.Title}</h2>

                    </div>
                    <div className="panel-body">
                        <ul className="list-group">
                            {this.props.teacherassignment.News.map((news) => {
                                return (
                                    <StudentNewsElementView newselement={news}/>
                                        
                                )
                            })
                            }
                            {this.props.teacherassignment.StudentTexts.map((text) => {
                                return (

                                    <StudentPlanElementView planelement={text} />

                                )
                            }
                            )}
                            {this.props.teacherassignment.StudentTasks.map((task) => {
                                return (
                                    <StudentTaskelementView taskelement={task} />
                                )
                            })}
                        </ul>
                    </div>
                </div>

            </div>

        )
    }

}
function mapStateToProps(state) {

    return {}
}
export default connect(mapStateToProps, {})(StudentCourseElementView);