
import * as _ from 'lodash';
import * as moment from 'moment';
import {connect} from 'react-redux';
import {ScheduleElement} from "../models/ScheduleElement";
import {DATE_FORMAT, LOCALE, TIME_FORMAT} from "../components/timeplan/ScheduleElementDetailView";
import {updateScheduleElement, deleteScheduleElement, getScheduleElement} from "../actions/Action_ScheduleElement";

export const SCHEDULE_ELEMENT_KEY_MAP = [
    {key: "zSheduleColorBlue",          text: "Blå-mørk",       schoolClass: "Norsk"},
    {key: "zSheduleColorGreen",         text: "Grønn",          schoolClass: "Matematikk"},
    {key: "zSheduleColorLightBlue",     text: "Zokrates-blå",   schoolClass: "Valgfag/Tilvalgsfag"},
    {key: "zSheduleColorOrange",        text: "Oransje",        schoolClass: "Musikk"},
    {key: "zSheduleColorPurple",        text: "Lilla",          schoolClass: "Kunst og Håndtverk"},
    {key: "zSheduleColorGrey",          text: "Grå-lys",        schoolClass: "Mat og Helse"},
    {key: "zSheduleColorBlack",         text: "Svart",          schoolClass: "Kroppsøving"},
    {key: "zSheduleColorGreyMedium",    text: "Grå-mellom",     schoolClass: "KRLE"},
    {key: "zSheduleColorDarkGrey",      text: "Grå-mørk",       schoolClass: ""},
    {key: "zSheduleColorRed",           text: "Rød",            schoolClass: "Engelsk"},
    {key: "zSheduleColorYellow",        text: "Gul",            schoolClass: "Samfunnsfag"},
    {key: "zSheduleColorLightGreen",    text: "Grønn-lys",      schoolClass: "Naturfag"},
]

export default class ScheduleUtils {

    public mapScheduleElementsToKeys(se:ScheduleElement[]){
        const options = _.values(se);
        const optionKeyList = [];

        options.forEach(se => {
            optionKeyList.push({key: se.ClassID, text: se.ClassName});
        });

        return optionKeyList;
    }

    public getScheduleColorKeys(){
        return _.values(SCHEDULE_ELEMENT_KEY_MAP);
    }
}