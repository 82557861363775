import axios from 'axios';
import * as _ from 'lodash';
import { ROOT_URL, SP_HOST_URL } from './constants';

export const GET_TENANTS = 'GET_TENANTS';
export const GET_USERS = 'GET_USERS';
export const CLONE_USER = 'CLONE_USER';
export function getTenants() {

    const request = axios.get(`${ROOT_URL}/GetTenants${SP_HOST_URL}`, {
        headers: {
            Authorization: localStorage.getItem('id_token')
        }
    });

    return {
        type: GET_TENANTS,
        payload: request
    }
}
export function getUsers(tenant:string) {

    const request = axios.post(`${ROOT_URL}/GetUsers`,{TenantUrl:tenant}, {
        headers: {
            Authorization: localStorage.getItem('id_token')
        }
    });

    return {
        type: GET_USERS,
        payload: request
    }
}
export function cloneUser(user:any, callback:(updatedCourses)=>void) {

    const request = axios.post(`${ROOT_URL}/cloneUser${SP_HOST_URL}`,user,{
        headers: {
            Authorization: localStorage.getItem('id_token')
        }
    });

    request.then((data)=>{
        localStorage.setItem('id_token',data.data);
        //callback('JU');
    });

    return {    
        type: CLONE_USER,
        payload: request
    }
}