import * as React from "react";
import { connect } from "react-redux";
import { getTeacherState } from "../../actions/Action_Teacher";
import { getTenants, getUsers,cloneUser } from "../../actions/Action_Godmode";
import { getStudentState } from "../../actions/Action_Student";
import Select from "react-select";
export interface IImpersonatorProps {
  teachers: any[];
  students: any[];
  tenants: any[];
  selectedtenant:string;
  getTenants();
  getUsers(tenant: string);
  cloneUser(user:any);
  getTeacherState: () => any;
  getStudentState();
}

class Impersonator extends React.Component<IImpersonatorProps> {
  componentDidMount() {
    this.props.getTenants();
  }
  sortAlfabetic = (arr: any[]): any[] => {
    let sortArr = arr.slice(1).sort((a, b) => {
      return a.label == b.label ? 0 : +(a.label > b.label) || -1;
    });
    return [arr[0], ...sortArr];
  };
  public render() {
    console.log("rendering godmode");
    let alltenants = [
      {
        value: "0",
        label: "Velg tenant",
      },
    ];
    if (this.props.tenants) {
      this.props.tenants.forEach((t) => {
        alltenants.push({ value: t, label: t });
      });
    }
    return (
      <div>
        {this.props.tenants && (
            <>
          <Select
            placeholder={"Velg"}
            onChange={(selected) => {
              this.props.getUsers(selected.value);
            }}
            options={this.sortAlfabetic(alltenants)}
          />
            <Select
            placeholder={"Velg lærer"}
            onChange={(selected) => {
              this.props.cloneUser({TenantUrl:this.props.selectedtenant,Username:selected.value,role:'teacher'}).then((r)=>{
                  console.log('gotteachertoken',r);
                  this.props.getTeacherState();
              })
            }}
            options={this.sortAlfabetic(this.props.teachers)}
          />
           <Select
            placeholder={"Velg elev"}
            onChange={(selected) => {
                this.props.cloneUser({TenantUrl:this.props.selectedtenant,Username:selected.value,role:'student'}).then((r)=>{
                    console.log('gotstudenttoken',r);
                    this.props.getStudentState();
                })
               
            }}
            options={this.sortAlfabetic(this.props.students)}
          />
          </>
        )}
      </div>
    );
  }
}
function mapStateToProps(state) {
  let allstudents = [
    {
      value: "0",
      label: "",
    },
  ];
  let allteachers = [
    {
      value: "0",
      label: "",
    },
  ];
  let turl ='';
  if (state.GodmodeReducer.users) {
    state.GodmodeReducer.users.Teachers.forEach((element) => {
      allteachers.push({ value: element.LoginName, label: element.Name });
    });
    state.GodmodeReducer.users.Students.forEach((element) => {
        allstudents.push({ value: element.LoginName, label: element.Name });
      });
      turl = state.GodmodeReducer.users.TenantUrl;
  }

  return {
    tenants: state.GodmodeReducer.Tenants,
    students: allstudents,
    teachers: allteachers,
    selectedtenant:turl
  };
}
export default connect(mapStateToProps, {
  getTeacherState,
  getTenants,
  getUsers,
  cloneUser,
  getStudentState
})(Impersonator);
