import * as React from 'react';
import {IconButton, TextField} from "office-ui-fabric-react";
import { ClassAssignmentGroup } from '../../../models/ClassAssignmentGroup';


export interface IClassAssignmentGroupHeaderProps{
    group?: ClassAssignmentGroup;
    title?: string;
    onNameChanged?:(groupId: number, event)=>void;
    onDeleteGroup?:(group: ClassAssignmentGroup) => void;
}

export default class ClassAssignmentGroupHeader extends React.Component<IClassAssignmentGroupHeaderProps>{

    constructor(props){
        super(props);
    }

    render(){

        if (this.props.group){
            return(
                <div className="group-header">
                    <TextField
                        value={this.props.title}
                        onChange={(event) => this.props.onNameChanged(this.props.group.Id, event)}
                    />
                    <IconButton
                        iconProps={{iconName: 'Delete'}}
                        onClick={() => this.props.onDeleteGroup(this.props.group)}
                    />
                </div>
            )
        } else {
            return(
                <div className="group-header">
                    <span>{this.props.title}</span>
                </div>
            )
        }
    }
}