import * as React from 'react';
import {AssignmentGroup} from "../../../models/AssignmentGroup";
import {IconButton, TextField} from "office-ui-fabric-react";


export interface IGroupHeaderProps{
    group?:AssignmentGroup;
    title?:string;
    onNameChanged?:(groupId:number, event)=>void;
    onDeleteGroup?:(group:AssignmentGroup)=>void;
}

export default class GroupHeader extends React.Component<IGroupHeaderProps>{

    constructor(props){
        super(props);
    }

    render(){

        if (this.props.group){
            return(
                <div className="group-header">
                    <TextField
                        value={this.props.title}
                        onChange={(event) => this.props.onNameChanged(this.props.group.Id, event)}
                    />
                    <IconButton
                        iconProps={{iconName: 'Delete'}}
                        onClick={() => this.props.onDeleteGroup(this.props.group)}
                    />
                </div>
            )
        } else {
            return(
                <div className="group-header">
                    <span>{this.props.title}</span>
                </div>
            )
        }
    }
}