import * as React from "react";
import { connect } from "react-redux";
import { getActorElements } from "../../actions/Action_Portfolio";
import {
  getStudentIUPlist,
  getStudentIUPDiscovery,
} from "../../actions/Action_Discovery";
import HeaderView from "../view_containers/HeaderView";
import "./discovery.scss";
import { getStudentElementsDiscovery } from "../../actions/Action_Discovery";
import { createZippedPDF } from "../../actions/Action_Iup";
import {
  ActionButton,
  DefaultButton,
  PrimaryButton,
  Panel,
  PanelType,
  TextField,
  Label,
  Spinner,
  SpinnerSize,
  Pivot,
  PivotItem,
  PivotLinkFormat,
} from "office-ui-fabric-react";

import Select from "react-select";
import * as moment from "moment";
import DatePicker from "react-datepicker";
import { PortfolioListView } from "../portfolio/PortfolioListView";
import IupStudent from "./IupStudent";
import { LoadingDialog } from "../loading/LoadingDialog";
import MyElementsView from "../my_elements/MyElementsView";
export const DATE_FORMAT = "DD.MM.YYYY";
export interface IDiscoveryProps {
  getActorElements(teacherState: any);
  getStudentIUPlist(student: any);
  getStudentIUPDiscovery(iup: any);
  getStudentElementsDiscovery(student: string, lp: string, from: any, to: any);
  teacherstate: any;
  options: any[];
  optionsiup: any[];
  activeiup: any;
  items: any[];
  history: any;
  createZippedPDF(iups: any);
}
export interface IDiscoveryState {
  searchstring: string;
  loading: boolean;
  fromdate: any;
  todate: any;
  student: any;
  selectedstudent: string;
  zipurl: string;
}
let text = "";
let sstring = "";
class Discovery extends React.Component<IDiscoveryProps, IDiscoveryState> {
  constructor(props) {
    super(props);
    this.state = {
      fromdate: moment().add("years", -1),
      todate: moment(),
      searchstring: "",
      loading: false,
      student: null,
      selectedstudent: null,
      zipurl: "",
    };
  }

  componentDidMount() {
    this.props.getActorElements(this.props.teacherstate);
  }

  public render() {
    console.log("render", this.state);
    return (
      <div className="ms-Grid container main">
        <HeaderView
          title="Søk"
          description="Hent elementer"
          iconName="Search"
        />
        {/* <div className="ms-Grid-row ms-bgColor-neutralLight topTool">
            </div> */}
        <Pivot linkFormat={PivotLinkFormat.tabs}>
          <PivotItem headerText="IUP">
            <div className="ms-Grid-row ms-bgColor-neutralLight topTool">
              <div className="ms-Grid-col ms-sm12 ms-lg6">
                <Select
                  placeholder="Finn elev"
                  onChange={(item) => {
                    console.log(item.key);
                    this.props
                      .getStudentIUPlist({ LoginName: item.key })
                      .then(() => {
                        this.setState({ selectedstudent: item.key });
                      });
                  }}
                  options={this.props.options}
                  isClearable={true}
                />
                {this.props.optionsiup.length > 0 && (
                  <Select
                    placeholder="Velg IUP"
                    onChange={(item) => {
                      console.log(item.key);
                      this.props
                        .getStudentIUPDiscovery({ Id: item.key })
                        .then((ret) => {
                          console.log("svar", ret);
                          this.setState({ student: ret.payload.data });
                        });
                    }}
                    options={this.props.optionsiup}
                    isClearable={true}
                  />
                )}
                {this.props.optionsiup.length == 0 &&
                  this.state.selectedstudent && (
                    <div>Det er ikke laget IUP for denne eleven ennå</div>
                  )}
              </div>
            </div>
            <div className="ms-Grid-row main" id="iup-page">
              <div className="ms-Grid-col ms-sm12">
                {this.state.student && (
                  <IupStudent student={this.state.student} createZippedPDF={this.props.createZippedPDF}></IupStudent>
                )}
              </div>
            </div>
          </PivotItem>
          <PivotItem headerText="Portefølje">
            <div className="ms-Grid-row ms-bgColor-neutralLight topTool">
              <div className="ms-Grid-row">
                <div className="ms-Grid-col ms-sm12 ms-lg6">
                  <TextField
                    placeholder={
                      "Skriv inn brukernavn på tidligere elev og trykk Hent"
                    }
                    onChange={(e, val) => {
                      console.log(val);
                      sstring = val;
                    }}
                  />
                </div>
              </div>
              <div className="ms-Grid-row">
                <div className="mt-10">
                  <span className="ms-Grid-col ms-sm4 ms-lg2 ms-xl1 ms-xxl1">
                    <Label className="date-label">Fra:</Label>
                  </span>
                  <div className="ms-Grid-col ms-sm8 ms-lg4 ms-xl3 ms-xxl2">
                    <DatePicker
                      selected={this.state.fromdate}
                      locale="nb-NO"
                      autocomplete="off"
                      onChange={(date) => {
                        this.setState({
                          fromdate: date,
                        });
                      }}
                      showWeekNumbers
                    />
                  </div>
                  <span className="ms-Grid-col ms-sm4 ms-lg2 ms-xl1 ms-xxl1">
                    <Label className="date-label">Til:</Label>
                  </span>
                  <div className="ms-Grid-col ms-sm8 ms-lg4 ms-xl3 ms-xxl2">
                    <DatePicker
                      selected={this.state.todate}
                      autoComplete="off"
                      locale="nb-NO"
                      onChange={(date) => {
                        this.setState({
                          todate: date,
                        });
                      }}
                      showWeekNumbers
                    />
                  </div>
                  <div className="ms-Grid-col ms-sm12 ms-lg12 ms-xl4 ms-xxl6">
                    <PrimaryButton
                      onClick={() => {
                        this.setState({ loading: true });
                        console.log("søker etter " + sstring);
                        this.props
                          .getStudentElementsDiscovery(
                            sstring,
                            "",
                            this.state.fromdate.format(),
                            this.state.todate.format()
                          )
                          .then(() => {
                            this.setState({ loading: false });
                          });
                      }}
                    >
                      Hent
                    </PrimaryButton>
                  </div>
                </div>
              </div>
            </div>
            <div className="ms-Grid-row main" id="iup-page">
              <div className="ms-Grid-col ms-sm12">
                {this.state.loading && (
                  <LoadingDialog
                    title="Henter portefølje"
                    description="Vennligst vent"
                  ></LoadingDialog>
                )}
                {!this.state.loading && (
                  <>
                    <ActionButton
                      iconProps={{ iconName: "pdf" }}
                      styles={{
                        root: {
                          float: "right",
                        },
                      }}
                      text="Opprett PDF"
                      onClick={() => {
                        let contents =
                          document.getElementsByClassName("tab-pane");

                        let allstudents =
                          document.getElementsByClassName("studentpdf");
                        let postdata = [];
                        for (
                          let index = 0;
                          index < allstudents.length;
                          index++
                        ) {}
                        console.log(postdata);

                        let promises = [];
                        let html =
                          document.getElementById("portfoliotext").innerHTML;
                        postdata.push({
                          astudent: html,
                          studentname: "b",
                          iupname: "a",
                        });

                        console.log("postdata", postdata);
                        this.props.createZippedPDF(postdata).then((resp) => {
                          console.log("zip", resp);
                          this.setState({ zipurl: resp.payload.data.Name });
                        });
                      }}
                    />
                    {this.state.zipurl && this.state.zipurl.length > 0 && (
                      <a target='_blank' href={this.state.zipurl}>Åpne Zip</a>
                    )}
                    <PortfolioListView
                      items={this.props.items}
                      history={this.props.history}
                    ></PortfolioListView>
                  </>
                )}
              </div>
            </div>
          </PivotItem>
          <PivotItem headerText="Mine Elementer">
            <MyElementsView history={this.props.history}></MyElementsView>
          </PivotItem>
        </Pivot>
      </div>
    );
  }
}

function mapStateToProps(state) {
  let drp = [];
  let studlist = [];
  if (state.actorelements.Students) {
    state.actorelements.Students.forEach((element) => {
      drp.push({
        key: element.LoginName,
        text: element.Name,
        label: element.Name,
        value: element.LoginName,
      });
      studlist.push({ text: element.Name, id: element.LoginName });
    });
  }
  drp.sort((a, b) => {
    return a.label > b.label ? 1 : b.label > a.label ? -1 : 0;
  });
  let drpiup = [];
  if (state.DiscoveryReducer.StudentIUPS) {
    state.DiscoveryReducer.StudentIUPS.forEach((element) => {
      drpiup.push({
        key: element.Id,
        text: element.Iup.Name,
        label: element.Iup.Name,
        value: element.Id,
      });
    });
  }
  let items = [];
  if (state.DiscoveryReducer.portfolio) {
    items = state.DiscoveryReducer.portfolio.PortItems;
  }
  return {
    options: drp,
    optionsiup: drpiup,
    teacherstate: state.teacher,
    items: items,
  };
}

export default connect(mapStateToProps, {
  getActorElements,
  getStudentIUPlist,
  getStudentIUPDiscovery,
  getStudentElementsDiscovery,
  createZippedPDF,
})(Discovery);
