import { assign } from "office-ui-fabric-react";
import { IAssignment } from "./IAssignment";
import { IIdentitySet } from "./IIdentitySet";
import { IResource } from "./IResource";
import { ISubmission } from "./ISubmission";
import { TeamSubmission } from "./TeamSubmission";
import { IRubic } from "./IRubric";
import { IEducationUser } from "./IEducationUser";

export class TeamAssignment {
    graphAssignment: IAssignment;
    submissions: TeamSubmission[];
    graphRubic: IRubic; 
    members: IEducationUser[];
    constructor(assignment: IAssignment) {
        this.graphAssignment = assignment;
    }
}