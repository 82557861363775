import * as React from "react";
import { Panel, PanelType } from "office-ui-fabric-react/lib/Panel";
import axios from "axios";
import { GraphFileBrowser } from "@microsoft/file-browser";
import { ActionButton } from "office-ui-fabric-react/lib/Button";
import {
  getTokenSilent,
  MSAL_CONFIG,
  getTokenSilentmsal,
} from "../../../msalConfig";
// import { MSAL_INSTANCE, MSAL_INSTANCEOD, myMSALObj } from "../../../index";
import * as moment from "moment";
import { IOneDriveFileData } from "./IOneDrivePickerData";
import {
  Providers,
  TeamsHelper,
  TeamsProvider,
  MsalProvider,
} from "@microsoft/mgt";
import "./GraphFileBrowser.scss";



export interface SkolonPickerProps {
  onClose: (selecteditems: any) => void;
}

export interface ISkolonPickerrState {
picker:any;
}

export default class SkolonPicker extends React.Component<
SkolonPickerProps,
  ISkolonPickerrState
> {
  constructor(props) {
    super(props); 
    this.state = { picker:null};
  }
  onModalOpen = () => {
   this.state.picker.open();
  };

   onModalCancel = () => {
    this.state.picker.close();
   };
componentDidMount()
{
     let tmp = new (window as any).skolon.plugin.AppPicker({
        pickedAppCallback: (app)=>{
            this.props.onClose(app);
            this.state.picker.close()
        },
        cancelCallback: ()=>{
            this.state.picker.close()
        },
        hideCloseButton: false
        });
        this.setState({picker:tmp});
}

  public render() {
    // console.log('expiry2',this.getAuthenticationToken());
    
    return (
      <>
    
        {/* <ActionButton
          iconProps={{ iconName: "Education" }}
        
          onClick={this.onModalOpen}
        >
          Legg til læringsressurs
        </ActionButton> */}

        <ActionButton
          iconProps={{ iconName: "Add" }}
        
          onClick={this.onModalOpen}
        >
          Legg til læringsressurs fra Skolon
        </ActionButton>

      </>
    );
  }
}
