import * as React from "react";
import * as moment from "moment";

import { store } from 'react-notifications-component';

import { connect } from "react-redux";
import HeaderView from "../view_containers/HeaderView";
import { Link } from "react-router-dom";
import { Field, reduxForm, change } from "redux-form";
import { getQuizElement,copyQuestions, getAssignmentGroup } from "../../actions/Action_Quiz";
import QuizForm from "./QuizForm";
import {DATE_TIME_FORMAT} from "../../utils/DateUtils";
export const LOCALE = "nb";
export const DATE_FORMAT = "DD.MM.YYYY";
export const TIME_FORMAT = "LT";
export interface IQuizEditViewProps {
    quiz: any;
    mode: string;
    quizElements: {};
    teacherState: {
        courses: {};
        schoolClasses: {};
    };
    initialValues: any;
    initialize: any;
    match: any;
    history: any;

    handleSubmit: any;
    getQuizElement(id: number);
    copyQuestions(question:any);
    getAssignmentGroup(course: any);
}


class QuizEditView extends React.Component<IQuizEditViewProps, any> {
    getInitialValuesFromObject(se: any) {
        se.ValidFrom = moment(se.ValidFrom)
            .locale(LOCALE)
            .format(DATE_FORMAT);
        se.ValidTo = moment(se.ValidTo)
            .locale(LOCALE)
            .format(DATE_FORMAT);

        return se;
    }

    getDefaultInitialValues(id: number) {

        const ValidFrom = moment().set("hours", 8).set("minutes", 0).locale(LOCALE).format(DATE_TIME_FORMAT);
        const ValidTo = moment().add( 1,"week",).set("hours", 23).set("minutes", 55).locale(LOCALE).format(DATE_TIME_FORMAT);

        return {
            Id: id,
            EvalElements: [],
            RandomQuestions: 0,
            Duration: 0,
            ValidFrom,
            ValidTo
        };
    }

    getMode = (): string => {
        return this.props && this.props.match && this.props.match.params && this.props.match.params.mode ? this.props.match.params.mode : "rediger";
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const { id, mode } = this.props.match.params;
       // console.log(id, mode);
        if (prevProps.match.params.mode != "kopier" && mode === "kopier") {
            this.props.getQuizElement(id)
                .then(response => {
                    if (mode && mode === "kopier") {
                        if(response.payload.data.CourseId) {
                            this.props.getAssignmentGroup({CourseID: response.payload.data.CourseId});
                        }

                        let copy = this.getInitialValuesFromObject(response.payload.data);
                        this.props.getQuizElement(mode)
                            .then(response => {
                                copy.Id = response.payload.data.Id;
                                this.setState({ quizElementForm: copy, attempts: [] });
                                this.props.copyQuestions({Id:parseInt(id),Destid:copy.Id});
                            })
                            .catch(error => {});
                    }
                })
                .catch(error => console.error(error));
        }
        else if(prevProps.match.params.mode === "kopier" && this.getMode() === "rediger") {
            this.props
                .getQuizElement(id)
                .then(response => {
                    if(response.payload.data.CourseId) {
                        this.props.getAssignmentGroup({CourseID: response.payload.data.CourseId});
                    }
                    this.setState({
                        quizElementForm: this.getInitialValuesFromObject(
                            response.payload.data
                        ),
                        attempts: response.payload.data.Attempts
                    });
                })
                .catch(error => console.error(error));
        }

        
    }
    componentWillMount() {
        const { id, mode } = this.props.match.params;
        if (!isNaN(id)) {
            this.props
                .getQuizElement(id)
                .then(response => {
                    if (mode && mode === "kopier") {
                        let copy = this.getInitialValuesFromObject(response.payload.data);
                        this.props
                            .getQuizElement(mode)
                            .then(response => {
                                copy.Id = response.payload.data.Id;
                                if(response.payload.data.CourseId) {
                                    this.props.getAssignmentGroup({CourseID: response.payload.data.CourseId});
                                }
                                this.setState({ quizElementForm: copy, attempts: [] });
                                this.props.copyQuestions({Id:parseInt(id),Destid:copy.Id});
                            })
                            .catch(error => {});
                    } else {
                        this.setState({
                            quizElementForm: this.getInitialValuesFromObject(
                                response.payload.data
                            ),
                            attempts: response.payload.data.Attempts
                        });
                    }
                })
                .catch(error => console.error(error));
        } else {
            this.props
                .getQuizElement(id)
                .then(response => {
                    if(response.payload.data.CourseId) {
                        this.props.getAssignmentGroup({CourseID: response.payload.data.CourseId});
                    }
                    this.setState({
                        quizElementForm: this.getDefaultInitialValues(
                            response.payload.data.Id
                        ),
                        attempts: response.payload.data.Attempts
                    });
                })
                .catch(error => console.error(error));
        }
    }

    onCopyClicked = () =>{
        store.addNotification({
            title: "Zokrates sier!",
            message: 'Du jobber nå med en kopi av prøven.',
            type: "success",
            insert: "top",
            container: "top-right",
            animationIn: ["animated", "fadeIn"],
            animationOut: ["animated", "fadeOut"],
            dismiss: {
                duration: 5000,
                onScreen: true
            }
        });

        this.props.history.push(`/quiz/${this.props.quiz.Id}/kopier`);

    };

    public render() {
        return (
            <div id="NewTextTest" className="ms-Grid container main">
                {this.state && (
                    <div className="ms-Grid-row">
                        {this.state.attempts > 0 && (
                            <div className="alert alert-warning mb-3">
                                Denne testen har besvarelser og kan derfor ikke redigeres. Du
                                kan fortsatt kopiere den og dele ut på nytt.
                            </div>
                        )}
                    </div>
                )}

                <div className="ms-Grid-row">
                    <HeaderView title="Prøve" iconName="PageListSolid" />
                    {this.state && (
                        <QuizForm
                            oncopy={() => this.onCopyClicked()}
                            onsave={() => {
                                this.props.history.push("/quiz");
                            }}
                            ondelete={() => {
                                this.props.history.push("/quiz");
                            }}
                            initialValues={this.state.quizElementForm}
                            mode={this.getMode()}
                        />
                    )}
                </div>
            </div>
        );
    }
}
const mapStateToProps = (state, ownProps) => {
    return {
        quiz: state.Quiz.quiz
    };
};

export default connect(mapStateToProps, { getQuizElement,copyQuestions, getAssignmentGroup })(QuizEditView);
