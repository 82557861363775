import axios from 'axios';
import { CopySchedule } from '../models/CopySchedule';
import { ScheduleElement } from '../models/ScheduleElement';
import { SP_HOST_URL, ROOT_URL } from './constants';

export const GET_SCHEDULE_ELEMENT = 'GET_SCHEDULE_ELEMENT';
export const GET_SCHEDULE_ELEMENTS = 'GET_SCHEDULE_ELEMENTS';
export const CLEAR_SCHEDULE_ELEMENTS = 'CLEAR_SCHEDULE_ELEMENTS';
export const UPDATE_SCHEDULE_ELEMENT = 'UPDATE_SCHEDULE_ELEMENT';
export const DELETE_SCHEDULE_ELEMENT = 'DELETE_SCHEDULE_ELEMENT';
export const COPY_SCHEDULE_ELEMENT = 'COPY_SCHEDULE_ELEMENT';
export const SET_SCHEDULE_CLASS = 'SET_SCHEDULE_CLASS';
export const REMEMBER_TIME = 'REMEMBER_TIME';
export const DISTRIBUTE_TEMPLATE = 'DISTRIBUTE_TEMPLATE';
export const DELETE_MULTIPLE_SCHEDULE_ELEMENT = 'DELETE_MULTIPLE_SCHEDULE_ELEMENT';

export function getScheduleElements(schoolClass: string, week: number, year: number, tilretteleggingsgruppeId?: number) {
	const data: FormData = new FormData();
	data.set('schoolClass', schoolClass);
	data.set('week', week.toString());
	data.set('year', year.toString());
	
	let classAssignmentGroupId = tilretteleggingsgruppeId;

	if (classAssignmentGroupId == -1 || tilretteleggingsgruppeId == undefined) {
		classAssignmentGroupId = null;
	}

	data.set('tilretteleggingsgruppeId', classAssignmentGroupId != null ? classAssignmentGroupId.toString() : null );

	const request = axios.post(`${ROOT_URL}/GetClassWeek${SP_HOST_URL}`, data, {
		headers: {
			Authorization: localStorage.getItem('id_token')
		}
	});

	return {
		type: GET_SCHEDULE_ELEMENTS,
		payload: request
	};
}

export function clearScheduleElements(classID: string) {
	return {
		type: CLEAR_SCHEDULE_ELEMENTS,
		payload: classID
	};
}

export function getScheduleElement(Id: number) {
	const request = axios.get(`${ROOT_URL}/GetScheduleElement/${Id}${SP_HOST_URL}`, {
		headers: {
			Authorization: localStorage.getItem('id_token')
		}
	});

	return {
		type: GET_SCHEDULE_ELEMENT,
		payload: request
	};
}

export function updateScheduleElement(se, prevWeek, callback: any) {
	const data = {
		ScheduleElementToUpdate: se, 
		PreviousWeek: prevWeek
	}
	
	const request = axios
		.post(`${ROOT_URL}/UpdateScheduleElement${SP_HOST_URL}`, data, {
			headers: {
				Authorization: localStorage.getItem('id_token')
			}
		})
		.then(() => callback());

	return {
		type: UPDATE_SCHEDULE_ELEMENT,
		payload: request
	};
}

export function deleteScheduleElement(object, callback: () => void) {
	const request = axios
		.post(`${ROOT_URL}/DeleteScheduleElement/${SP_HOST_URL}`, object, {
			headers: {
				Authorization: localStorage.getItem('id_token')
			}
		})
		.then(() => callback());

	// Returns id of deleted post
	return {
		type: DELETE_SCHEDULE_ELEMENT,
		payload: object.Id
	};
}

export function deleteMultipleScheduleElement(list: ScheduleElement[]) {
	const request = axios.post(`${ROOT_URL}/DeleteMultipleScheduleElements/${SP_HOST_URL}`, list ? list : [], {
		headers: {
			Authorization: localStorage.getItem('id_token')
		}
	});

	return {
		type: DELETE_MULTIPLE_SCHEDULE_ELEMENT,
		payload: request
	}
}

export function copyScheduleElements(data: CopySchedule, callback: (success: boolean) => void) {
	const request = axios
		.post(`${ROOT_URL}/V2CopySchedulePost${SP_HOST_URL}`, data, {
			headers: {
				Authorization: localStorage.getItem('id_token')
			}
		})
		.then(() => callback(true))
		.catch(() => callback(false));

	return {
		type: COPY_SCHEDULE_ELEMENT,
		payload: data
	};
}
export function rememberTimeForNextElement(date: any) {
	return {
		type: REMEMBER_TIME,
		payload: date
	};
}
export async function distributeTemplate(data: any, callback?: (success: boolean) => void) {
	const request = axios
		.post(`${ROOT_URL}/V2DistributeTemplate${SP_HOST_URL}`, data, {
			headers: {
				Authorization: localStorage.getItem('id_token')
			}
		})
		.then(() => {
			if (callback) {
				callback(true)
			}
		})
		.catch(() => {
			if (callback) {
				callback(false)
			}
		});

	return {
		type: DISTRIBUTE_TEMPLATE,
		payload: data
	};
}

// export function setSelectedClass(key:string) {

//     return{
//         type: SET_SCHEDULE_CLASS,
//         payload: key
//     }
// }
