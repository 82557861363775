import * as React from "react";

export interface IPlagStatusProps {
  significance: string;
  reporturl:string;
}

export default class PlagStatus extends React.Component<IPlagStatusProps> {
  public render() {
    if (this.props && this.props.significance) {
     
      return (
          <div>
            {
                this.props.significance.indexOf('0.gif') >0  &&
                <img src={require('../../images/0.gif')} />
            }
             {
                this.props.significance.indexOf('1.gif')>0 &&
                <img src={require('../../images/1.gif')} />
            }
             {
                this.props.significance.indexOf('2.gif')>0 &&
                <img src={require('../../images/2.gif')} />
            }
             {
                this.props.significance.indexOf('3.gif')>0 &&
                <img src={require('../../images/3.gif')} />
            }
             {
                this.props.significance.indexOf('4.gif')>0 &&
                <img src={require('../../images/4.gif')} />
            }
             {
                this.props.significance.indexOf('5.gif')>0 &&
                <img src={require('../../images/5.gif')} />
            }
             {
                this.props.significance.indexOf('6.gif')>0 &&
                <img src={require('../../images/6.gif')} />
            }
            {
                this.props.significance.indexOf('7.gif')>0 &&
                <img src={require('../../images/7.gif')} />
            }
            {
                this.props.significance.indexOf('8.gif')>0 &&
                <img src={require('../../images/8.gif')} />
            }

            
            {
               parseFloat(this.props.significance)>0&& parseFloat(this.props.significance)<10  &&
                <img src={require('../../images/0.gif')} />
            }
             {
                parseFloat(this.props.significance)>10&& parseFloat(this.props.significance)<20  &&
                <img src={require('../../images/1.gif')} />
            }
             {
                 parseFloat(this.props.significance)>20&& parseFloat(this.props.significance)<30  &&
                <img src={require('../../images/2.gif')} />
            }
             {
                 parseFloat(this.props.significance)>30&& parseFloat(this.props.significance)<40  &&
                <img src={require('../../images/3.gif')} />
            }
             {
                parseFloat(this.props.significance)>40&& parseFloat(this.props.significance)<50  &&
                <img src={require('../../images/4.gif')} />
            }
             {
                 parseFloat(this.props.significance)>50&& parseFloat(this.props.significance)<60  &&
                <img src={require('../../images/5.gif')} />
            }
             {
                parseFloat(this.props.significance)>70&& parseFloat(this.props.significance)<80  &&
                <img src={require('../../images/6.gif')} />
            }
            {
                 parseFloat(this.props.significance)>80  &&
                <img src={require('../../images/7.gif')} />
            }
            {/* {
                parseFloat(this.props.significance)>90 &&
                <img src={require('../../images/8.gif')} />
            } */}
            {
                this.props.reporturl.length>0 &&
                <a
                className="seeMsg"
                target="_blank"
                href={this.props.reporturl}
              > <img src={require('../../images/analysmini.gif')} />
              </a>
            }
          </div>
         
        );
     
    } else {
      return <div></div>;
    }
  }
}
