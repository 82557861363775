import * as React from 'react';
import {
    Accordion,
    AccordionItem,
    AccordionItemTitle,
    AccordionItemBody
  } from "react-accessible-accordion";
import { Icon, Stack } from 'office-ui-fabric-react';
import "./MyElements.scss";
import { WsElement } from '../../models/WsElement';
import ReactTooltip from "react-tooltip";


interface MyElementsListItemProps {
    teacherElement: WsElement;
}

class MyElementsListItem extends React.Component<MyElementsListItemProps> {

    getElementIconType = (elementType: string): string => {
        switch (elementType) {
            case "task":
                return "Completed";
            case "text":
                return "CalendarWeek";
            case "quiz":
                return "PageList";
            default:
                return "";
        }
    }

    getFriendlyElementTypeName = (elementType: string): string => {
        switch (elementType) {
            case "task":
                return "Innlevering";
            case "text":
                return "Ukeplanoppgave"
            case "quiz":
                return "Prøve";
            default:
                return "";
        }
    }

    render() {
        return (
            <div className="teacher-element-card long-text">
                <Stack >
                    <Stack horizontal horizontalAlign="space-between">
                        <h5 style={{minHeight: "18px"}}>{this.props.teacherElement.Name}</h5>
                        <Icon data-tip data-for={`elementIcon-${this.props.teacherElement.Id}`} style={{paddingLeft: 10, color: "#2baf2b", cursor: "default", fontSize: 18}} iconName={this.getElementIconType(this.props.teacherElement.ElementType)}/>
                        <ReactTooltip id={`elementIcon-${this.props.teacherElement.Id}`}>
                          <p style={{fontSize: 15}}>{this.getFriendlyElementTypeName(this.props.teacherElement.ElementType)}</p>
                        </ReactTooltip>
                    </Stack>
                    <Stack horizontal horizontalAlign="space-between">
                        <p>{this.props.teacherElement.CourseName ? this.props.teacherElement.CourseName : ""}</p>
                        <p>{this.props.teacherElement.Year}</p>
                    </Stack>
                    
                </Stack>
            </div>
        );
    }
}

export default MyElementsListItem;