import * as React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import ReactQuill from "react-quill"; // Typescript
import EvalForm from "./EvalForm";
import { Check } from "office-ui-fabric-react/lib/Check";
import { getStudentFiles,getTaskElement } from "../../actions/Action_Innleveringer";
import {
  submitResource,
  getAssignmentOutcomes,
} from "../../actions/Action_Graph";
import {
  DefaultButton,
  Dialog,
  DialogFooter,
  DialogType,
  ActionButton, Icon,IconButton
} from "office-ui-fabric-react";
import ReactTooltip from 'react-tooltip';
import PlagStatus from "./PlagStatus";
import EvalFormRebuild from "./EvalFormRebuild";
import EvalFormTeams from "./EvalFormTeams";
import moment = require("moment");

export interface IInnleveringStatusViewItemProps {
  studenttask: any;
  history: any;
  task: any;
  key: any;
  itemIndex: any;
  selection: any;
  studentfiles: any;
  savestatus: string;
  showeval: boolean;
  sign: string;
  getStudentFiles(studenttask: any);
  getTaskElement(teacherState: any);
  submitResource(
    classid: string,
    assignmentid: string,
    resource: any,
    resheader: any
  );
  plags:any;
  onSaveFinished?: () => void;
}
export interface IInnleveringStatusViewItemState {
  ischecked: boolean;
  showCopyScheduleModal: boolean;
  show:boolean;
}

class InnleveringStatusViewItem extends React.Component<
  IInnleveringStatusViewItemProps,
  IInnleveringStatusViewItemState
> {
  constructor(props) {
    super(props);
    this.state = {
      ischecked: this.props.studenttask.ShowEval,
      showCopyScheduleModal: false,
      show:false,
    };
  }

  componentWillReceiveProps(props) {
    console.log('teamsfeilprops',props);
    console.log('hotfixitemprops',props);
    this.setState({ ischecked: props.showeval,show:true });
    console.log(`RENDER ${this.props.studenttask.LoginName}:`, this.props.studenttask.DeliveryStatus);
  }

  wasDeliveredOnTime = () => {
    try {
      
      if (this.props.studenttask.DateDelivered) {
        if (moment(this.props.studenttask.Deadline).isAfter(moment(this.props.studenttask.DateDelivered))) {
          return true;
        }
        else {
          return false;
        }
      }
      return true;
    }
    catch (e) {
      return true;
    }
  }

  render() {
    let ur = this.props.studenttask.UrkundSignificance;
    if(this.props.sign && this.props.sign.length > 0)
    {
        ur =this.props.sign;
    }
    console.log('hotfixrenderitem',this.props.studenttask);
   // let tmpobj = JSON.parse(JSON.stringify(this.props.studenttask));

    return (
      <li
        data-is-focusable={true}
        data-selection-index={this.props.itemIndex}
        className="ms-ListItem innleveringItem @cc is-selectable @dd"
      >
        <div className="ms-Grid-row">
          <div className="ms-Grid-col ms-sm12 ms-lg8">
            <div className="plag">
       
                <PlagStatus reporturl="" significance={ur}></PlagStatus>
            </div>
            <span className="ms-ListItem-primaryText">
              <Link to={"/innleveringer/student/" + this.props.studenttask.Id}>
                {" "}
                {this.props.studenttask.StudentName}
              </Link>
            </span>
            {/* <span className="ms-ListItem-secondaryText"></span> */}
            {/* <span className="ms-ListItem-metaText">...</span> */}

            {!this.wasDeliveredOnTime() && <span>
              <Icon iconName="Clock" style={{fontSize: 16, marginRight: 3, marginLeft: 10, color: "red"}}/> Denne besvarelsen ble levert etter fristen, {moment(this.props.studenttask.DateDelivered).format("HH:mm DD.MM.yyyy")}
            </span>
            }
            <div className="plag"> </div>
          </div>
          <div className="ms-Grid-col ms-sm12 ms-lg4">
            <div className="ms-ListItem-actions">
              <div className="ms-ListItem-action tooltipContainer">
                <i
                  key={this.props.studenttask.DeliveryStatus}
                  className={
                    "ms-Icon ms-Icon--InboxCheck " +
                    (this.props.studenttask.DeliveryStatus != "1"
                      ? "ms-fontColor-green"
                      :  ((this.props.studenttask.submission && this.props.studenttask.submission.status=='submitted')
                      ? "ms-fontColor-green"
                      : this.props.studenttask.DeliveryStatus))
                                           
                  }
                ></i>
                {/* <span className="tooltiptext">
                   Innlevert av elev {this.props.studenttask.DeliveryStatus}
                </span> */}
              </div>

              <div className="ms-ListItem-action tooltipContainer">
                <i
                  id="graded-this.props.studenttask.Id"
                  key={this.props.studenttask.DeliveryStatus}
                  className={
                    "ms-Icon ms-Icon--RedEye " +
                    (this.props.studenttask.DeliveryStatus == "2"
                      ? "ms-fontColor-green"
                      : "")
                  }
                ></i>
                {/* <span className="tooltiptext">Sett av lærer</span> */}
              </div>
              <div className="ms-ListItem-action tooltipContainer">
                <i
                  key={this.props.studenttask.ShowEval}
                  className={
                    "ms-Icon ms-Icon--CheckMark " 
                    // (this.props.studenttask.ShowEval
                    //   ? "ms-fontColor-green"
                    //   : "ms-fontColor-black")
                  }
                  style={{color:`${this.props.studenttask.ShowEval?'black!important':'inherit'}`}}
                ></i>
                {/* <span className="tooltiptext">Ferdig vurdert</span> */}
              </div>
            </div>
          </div>
        </div>
        <div className="ms-Grid-row">
          <div className="ms-Grid-col ms-sm12 text-right">
            {this.props.studenttask.HasFiles && (
              <ActionButton
                onClick={() => {
                  this.props.getStudentFiles(this.props.studenttask).then(()=>{
                    this.setState({ showCopyScheduleModal: true });
                  })
                 
                }}
                iconProps={{ iconName: "Attach" }}
                text="Se filer"
              />
            )}
            {/* <ActionButton
                onClick={() => {
                  this.props.getStudentFiles(this.props.studenttask);
                  this.setState({ showCopyScheduleModal: true });
                }}
                iconProps={{ iconName: "Attach" }}
                text="Se filer"
              /> */}
          </div>
        </div>
         <div className="ms-Grid-row">
         <div className="ms-Grid-col ms-sm12 text-right">
           {this.props.studenttask.submission && this.props.studenttask.submission.resources &&
             this.props.studenttask.submission.resources.length > 0 && (
               <ActionButton
                 onClick={() => {
                   //this.props.getStudentFiles(this.props.studenttask);
                   this.setState({
                     showCopyScheduleModal: true,
                   });
                 }}
                 iconProps={{ iconName: "Attach" }}
                 text="Se filer"
               />
             )}
         
         </div>
       </div>
        {
          this.props.studenttask.submission &&
          <>
           <EvalFormRebuild
            form={"taskform-" + this.props.studenttask.Id}
            studenttask={this.props.studenttask}
            fromprops={this.props.studenttask}
            showeval={this.state.ischecked}
            singleview={false}
            initialValues={this.props.studenttask}
            submission={this.props.studenttask.submission}
            onSaveFinished={this.props.onSaveFinished ? this.props.onSaveFinished : null}
            
            //initialValues={tmpobj}
          ></EvalFormRebuild>
          {/* <EvalFormTeams  form={"taskform-" + this.props.studenttask.Id}
          studenttask={this.props.studenttask}
          fromprops={this.props.studenttask}
          showeval={this.state.ischecked}
          singleview={false}
          initialValues={this.props.studenttask}
          submission={this.props.studenttask.submission}>

          </EvalFormTeams> */}
          </>
        }
        {
          !this.props.studenttask.submission &&
          <>
          <EvalFormRebuild
            form={"taskform-" + this.props.studenttask.Id}
            studenttask={this.props.studenttask}
            fromprops={this.props.studenttask}
            showeval={this.state.ischecked}
            singleview={false}
            initialValues={this.props.studenttask}
            submission={this.props.studenttask.submission}
            onSaveFinished={this.props.onSaveFinished ? this.props.onSaveFinished : null}
            
            //initialValues={tmpobj}
          ></EvalFormRebuild>
          
        </>
        }
       
        
       
        {this.state.showCopyScheduleModal && (
          <Dialog
            hidden={false}
            onDismiss={() => this.setState({ showCopyScheduleModal: false })}
            dialogContentProps={{
              type: DialogType.normal,
              title: "Filer som hører til innleveringen",
              subText: ""
            }}
            styles={{
              main: [
                {
                  selectors: {
                    ["@media (min-width: 480px)"]: {
                      maxWidth: "600px",
                      minWidth: "480px"
                    }
                  }
                }
              ]
            }}
            modalProps={{
              titleAriaId: "myLabelId",
              subtitleAriaId: "mySubTextId",
              isBlocking: true,
              containerClassName: "ms-dialogMainOverride"
            }}
          >
            <div>
              {this.props.studentfiles && (
                <ul className="no-type-list">
                  {this.props.studentfiles.studentfiles.DeliveryFiles.map(t => {
                    console.log('123',t);
                    return (
                      <li style={{marginBottom: "8px"}}>
                        <Icon iconName={"OpenInNewTab"} style={{ verticalAlign: "top", marginRight: 5 }}></Icon>
                        <a
                          target="_blank"
                          href={t.WopiViewUrl ? t.WopiViewUrl : t.FileUrlDelivery}
                          title="Åpne fil"
                        >
                          {t.FileNameDraft} 
                        </a>
                        <Icon iconName={"DownloadDocument"} style={{ verticalAlign: "top", marginLeft: 20, marginRight: 5  }}></Icon>
                        <a
                          target="_blank"
                          href={t.FileUrlDelivery}
                          title="Last ned fil"
                        >
                          Last ned
                        </a>
                      </li>
                    );
                  })}
                </ul>
              )}
            </div>
            <div>
              {this.props.studenttask.submission&& this.props.studenttask.submission.resources && (
                <ul className="no-type-list">
                  {this.props.studenttask.submission.resources.map((res) => {
                    console.log('123res',res);

                    // console.log('resource',res);
                    if (this.props.plags && this.props.plags[res.id]) {
                      console.log('plagmatch',this.props.plags[res.id]);
                      //  console.log('resource2',res.plaginfo);
                      // console.log('resplaginfo',res.plaginfo);
                      return (
                        <li>
                          {res.resource.displayName}
                          <div className="plgImg" data-plagblock="@item.Id">
                            {this.props.plags[res.id].urkundState == "Analyzed" && (
                              <div>
                                <PlagStatus
                                  reporturl={this.props.plags[res.id].urkundReportUrl}
                                  significance={this.props.plags[res.id].urkundSignificance}
                                ></PlagStatus>
                              </div>
                            )}
                            {this.props.plags[res.id].urkundState != "Analyzed" && (
                              <span>{this.props.plags[res.id].urkundState}</span>
                            )}
                          </div>
                        </li>
                      );
                    }
                    else{
                      console.log('nomatch',this.props.plags);
                      console.log('nomatch',res.id);
                    }
                    if (!this.props.plags || !this.props.plags[res.id]) {
                      return (
                        <li>
                          {res.resource.displayName}
                          {!res.plaginfo && (
                            <IconButton
                              iconProps={{ iconName: "RevToggleKey" }}
                              className="remove-icon"
                              onClick={() =>{
                                console.log('submitting',res);
                                this.props.submitResource(
                                  this.props.studenttask.submission.classid,
                                  this.props.studenttask.submission.assignmentid,
                                  res.resource,
                                  res
                                )
                              }
                              }
                            />
                          )}
                        </li>
                      );
                    }
                  })}
                </ul>
              )}
            </div>
            <DialogFooter>
              <DefaultButton
                onClick={() => {
                  this.setState({ showCopyScheduleModal: false });
                }}
                text="Lukk"
              />
            </DialogFooter>
          </Dialog>
        )}
      </li>
    );
  }
}
function mapStateToProps(state) {
  console.log('matching',state);
  //let u = parseInt('a');
 // console.log(state.tingeling.aha);
  return {
    studentfiles: state.tasks.newStatestudentfiles,
    plags:state.GraphReducer.assignmentplags,
  };
}
export default connect(mapStateToProps, { getStudentFiles,submitResource })(
  InnleveringStatusViewItem
);
