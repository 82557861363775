import {  Icon, IconButton, Label, Modal, Shimmer } from 'office-ui-fabric-react'
import * as React from 'react'
import { WsTask } from '../../models/WsTask';
import { connect } from "react-redux";
import moment = require('moment');
import { getPlanElementFiles, getTaskElementFiles } from '../../actions/Action_Ukeplan';
import { PlanElement } from '../../models/PlanElement';
import { Course } from '../../models/Course';


const LOCALE = "nb";
const DATE_FORMAT = "DD.MM.YYYY";
const DATE_FORMAT_TIME = "DD.MM.YYYY HH:mm";

export interface IUkeplanElementModalProps {
    showModal: boolean;
    onDismiss(): void;
    getPlanElementFiles(id: number);
    getTaskElementFiles(Id: number);
    course: Course;
    task: WsTask;
    planElement: PlanElement;
    files: any[];
}

export interface IUkeplanElementModalState {

}

class UkeplanElementModal extends React.Component<IUkeplanElementModalProps, IUkeplanElementModalState> {
    constructor(props) {
        super(props);
        
    }

    componentDidMount() {
      
    }

  render() {
    return (
      <div>
        <Modal 
            isOpen={this.props.showModal}
            onDismiss={this.props.onDismiss}
            styles={{
                main: {
                    height: "95vh", 
                    width: "60vw", 
                    backgroundColor: "#faffff",
                    selectors: {
                        ["@media (min-width: 781px) and (max-width: 1200px)"]: {
                            height: "95vh",
                            minWidth: "70vw"
                        },
                        ["@media (max-width: 780px)"]: {
                          height: "99vh",
                          minWidth: "98vw"
                        }
                      }
                },
                scrollableContent: {
                    overflowY: "visible"
                }
            }}
            layerProps={{onLayerDidMount: () => {
                if (this.props.planElement && this.props.planElement.HasFiles) {
                    this.props.getPlanElementFiles(this.props.planElement.Id);
                  }
                  if(this.props.task && this.props.task.TaskDocument)
                  {
                    this.props.getTaskElementFiles(this.props.task.Id);
                  }
            }}}
        >
            <div style={{padding: 30}}>
                <div style={{display: "flex", justifyContent: "end", width: "100%"}}>
                    <IconButton iconProps={{ iconName: "ChromeClose", styles: {root: {fontSize: 14}}}} onClick={this.props.onDismiss}/>
                </div>
                {this.props.task && <div>
                    <h2 className='ms-fontColor-orangeLight'>
                    {this.props.task.CourseName} - {this.props.task.Name}
                    </h2>
                    {/* <p>{this.props.task.Goal}</p>
                    <p>{this.props.task.Text}</p> */}
                    {/* <Label className="list-group-item--label">{this.props.task.CourseName}</Label> */}
                    {this.props.task.GrepCodeList && this.props.task.GrepCodeList.length > 0 && (
                        <div>
                        <Label className="list-group-item--label">Kompetansemål</Label>
                        <dd>
                            {/* <ul id="kmp-2-@this.props.taskelement.Id" className={`grep-list ${this.props.compactCardView ? "hideOverflow" : ""}`}> */}
                            {this.props.task.GrepCodeList.length > 10 ? <span>{this.props.task.GrepCodeList.length} Kompetansemål</span> : 
                            <ul id="kmp-2-@this.props.taskelement.Id" className="grep-list">
                            {this.props.task.GrepCodeList.map((element) => {
                                return (
                                <li>
                                    <a href={element.DetailUrl} target="_blank">
                                    {element.GrepTekst}
                                    </a>
                                </li>
                                );
                            })}
                            </ul>}
                        </dd>
                        </div>
                    )}

                    {this.props.task.SkolonResources && this.props.task.SkolonResources.length > 0 && (
                        <>
                        <div>
                        <Label className="list-group-item--label"> <img width='40px' src={require('../../images/SkolonIcon.svg')} /></Label>
                        </div>
                        <dd>
                        <ul className="grep-list">
                        {this.props.task.SkolonResources.map((element) => {
                            return (
                            <li>
                                <a href={element.url} target="_blank">
                                {element.name}
                                </a>
                            </li>
                            );
                        })}
                        </ul>
                    </dd>
                    </>
                    )}
    
                    
                    {this.props.task.Goal && (
                        <div>
                        <Label className="list-group-item--label">
                            Egendefinerte mål:
                        </Label>
                        <dd>
                            <span
                            className={`remove-para-margin list-group-unordered`}
                            style={{ backgroundColor: "transparent" }}
                            dangerouslySetInnerHTML={{
                                __html: this.props.task.Goal,
                            }}
                            ></span>
                            {/* {this.props.taskelement.Goal} */}
                        </dd>
                        </div>
                    )}
                    {/* {this.props.task.EvaluationText.length > 0 ||
                        (this.props.task.EvaluationTextOther.length > 0 && (
                        <div>
                            <Label className="list-group-item--label">Vurdering:</Label>
                            <dd>
                            <ul>
                                {this.props.task.EvalElements.map((element) => {
                                <li>element</li>;
                                })}
                            </ul>
                            </dd>
                        </div>
                        ))} */}
    
                    {this.props.task.Text && (
                        <div>
                        <Label className="list-group-item--label">Beskrivelse:</Label>
                        <dd>
                            <span
                            className={`remove-para-margin list-group-unordered`}
                            style={{ backgroundColor: "transparent" }}
                            dangerouslySetInnerHTML={{
                                __html: this.props.task.Text,
                            }}
                            ></span>
                            {/* {this.props.taskelement.Text} */}
                        </dd>
                        </div>
                    )}
    
                    {this.props.task.Link && (
                        <div>
                        <Label className="list-group-item--label">Lenke</Label>
                        <dd className="planLenke">
                            <a target="_blank" href={this.props.task.Link}>
                            {this.props.task.Link}
                            </a>
                        </dd>
                        </div>
                    )}
                    <span>
                        {
                            this.props.task.Starttime && <div>
                                <Label className="list-group-item--label">Starttid</Label>
                                {moment(this.props.task.Starttime).locale(LOCALE).format(DATE_FORMAT_TIME)}
                            </div>
                        }
                    </span>
                    <span>
                        <Label className='list-group-item--label'>Frist</Label>
                        {this.props.task.Deadlinetekst}
                    </span>
    
                    <div>
                        
                        {/* {this.props.task.DeliverAfterDeadline && <p>Levering etter frist</p>} */}
                        {this.props.task.KeepOpen && <div><Label className='list-group-item--label'>Flere forsøk:</Label> Ja</div>}
                    </div>

                    {this.props.task.TaskDocument &&
                        <div>
                            <Label className='list-group-item--label'> Filer</Label>
                            <ul>
                                {this.props.task.TaskDocument.split(";").map((fileString: string) => {
                                
                                let documentId = fileString.split("|")[0];
                                let file = this.props.files && this.props.files.find(f => f.OriginalDocumentID == documentId && f.TaskID == this.props.task.Id);

                                return <li><Shimmer isDataLoaded={!!file} style={{margin: "4px 0"}}>
                                    {file && 
                                        <a href={file.FileUrl} target="_blank">
                                            {file.FileName}
                                        </a>
                                    }
                                    </Shimmer></li>
                                })}
                            </ul>
                        </div>
                    }
                </div>
                }

                {this.props.planElement && <div>
                    <h2 className='ms-fontColor-orangeLight'>
                        {this.props.course.Title} - {this.props.planElement.Theme}
                    </h2>

                    {/* <Label className="list-group-item--label">{this.props.course.Title}</Label> */}

                    {this.props.planElement.GrepCodeList && this.props.planElement.GrepCodeList.length > 0 && (
                        <div>
                            <Label className="list-group-item--label">Kompetansemål</Label>
                            <dd>
                                {/* <ul id="kmp-2-@this.props.taskelement.Id" className={`grep-list ${this.props.compactCardView ? "hideOverflow" : ""}`}> */}
                                {this.props.planElement.GrepCodeList.length > 10 ? <span>{this.props.planElement.GrepCodeList.length} Kompetansemål</span> : 
                                <ul id="kmp-2-@this.props.taskelement.Id" className="grep-list">
                                {this.props.planElement.GrepCodeList.map((element) => {
                                    return (
                                    <li>
                                        <a href={element.DetailUrl} target="_blank">
                                        {element.GrepTekst}
                                        </a>
                                    </li>
                                    );
                                })}
                                </ul>}
                            </dd>
                        </div>
                    )}

                    {this.props.planElement.Goal && this.props.planElement.Goal.length > 0 && (
                        <div>
                            <Label className="list-group-item--label">
                                Egendefinerte mål:
                            </Label>
                            <dd>
                                <span
                                className={`remove-para-margin list-group-unordered`}
                                style={{ backgroundColor: "transparent" }}
                                dangerouslySetInnerHTML={{
                                    __html: this.props.planElement.Goal,
                                }}
                                ></span>
                            </dd>
                        </div>
                    )}


                    {this.props.planElement.EvaluationText && (
                        <div>
                            <Label className="list-group-item--label">Vurdering:</Label>
                            <dd>
                                {
                                    this.props.planElement.EvaluationText.split(";").map(text => {
                                        return <div>{text}</div>
                                    })
                                }
                            </dd>
                        </div>
                    )}

                    {this.props.planElement.EvaluationTextOther && (
                        <div>
                        <Label className="list-group-item--label">Annen Vurdering:</Label>
                        <dd>
                            <span
                            className={`remove-para-margin list-group-unordered`}
                            style={{ backgroundColor: "transparent" }}
                            dangerouslySetInnerHTML={{
                                __html: this.props.planElement.EvaluationTextOther
                            }}
                            ></span>
                        </dd>
                        </div>
                    )}

                    {this.props.planElement.TextOnPrem && (
                        <div>
                        <Label className="list-group-item--label">Arbeid Skolen:</Label>
                        <dd>
                            <span
                            className={`remove-para-margin list-group-unordered`}
                            style={{ backgroundColor: "transparent" }}
                            dangerouslySetInnerHTML={{
                                __html: this.props.planElement.TextOnPrem
                            }}
                            ></span>
                        </dd>
                        </div>
                    )}

                    {this.props.planElement.Text && (
                        <div>
                            <Label className="list-group-item--label">Arbeid Hjemme:</Label>
                            <dd>
                                <span
                                className={`remove-para-margin list-group-unordered`}
                                style={{ backgroundColor: "transparent" }}
                                dangerouslySetInnerHTML={{
                                    __html: this.props.planElement.Text
                                }}
                                ></span>
                            </dd>
                        </div>
                    )}

                    {this.props.planElement.Link && (
                        <div>
                            <Label className="list-group-item--label">Lenke</Label>
                            <dd className="planLenke">
                                <a target="_blank" href={this.props.planElement.Link}>
                                {this.props.planElement.Link}
                                </a>
                            </dd>
                        </div>
                    )}

                    <span>
                        <Label className="list-group-item--label">Starttid</Label>
                        {moment(this.props.planElement.DateFrom).locale(LOCALE).format(DATE_FORMAT)}
                    </span>
                    <span>
                        <Label className='list-group-item--label'>Frist</Label>
                        {this.props.planElement.DateText}
                    </span>

                    {this.props.files &&
                        <div>
                            <Label className='list-group-item--label'> Filer</Label>
                            <ul>
                                {this.props.files.map((file) => {

                                    if (file.TaskID !== this.props.planElement.Id) {
                                        return;
                                    }
                                    return <li>
                                    <a target='_blank' href={file.FileUrl}>
                                        {file.FileName}
                                    </a></li>;
                                })}
                            </ul>
                        </div>
                    }
                </div>}
            </div>
        </Modal>
    </div>
    )
  }
}

function mapStateToProps(state) {
    return {
      files: state.ukeplanelements.Filelist
    };
}
export default connect(mapStateToProps, { getPlanElementFiles, getTaskElementFiles })(UkeplanElementModal);
