import * as React from "react";
import { connect } from "react-redux";
import {
  checkStatusTask,
  initStatusTask,
} from "../../../actions/Action_Student";
import { checkStatusTaskTeacher } from "../../../actions/Action_Innleveringer"
import {
  Dialog,
  ProgressIndicator,
  PrimaryButton,
  Spinner,
  SpinnerSize,
  Stack,
  IStackProps
} from "office-ui-fabric-react";
export interface IProgressListProps {
  fileslist: any[];
  isinit: boolean;
  checkStatusTask(files: any[]);
  checkStatusTaskTeacher(files: any[]);
  initStatusTask(files: any[]);
  finished();
  currenttask:any;
  isTeacher: boolean;
}

export interface IProgressListState {
  showfilesmodal: boolean;
  ischecked: boolean;
}
class ProgressList extends React.Component<
  IProgressListProps,
  IProgressListState
> {
  componentWillReceiveProps(props) {
    console.log("progressprops", props);
    if (props.fileslist && props.fileslist.length > 0 && !props.isinit) {
      console.log("ikke tom");
      setTimeout(() => {
        if(!this.props.isTeacher){
          this.props.checkStatusTask(props.fileslist);
        }
        else{
          this.props.checkStatusTaskTeacher(props.fileslist);
        }
      }, 5000);
    }
    else if(props.fileslist && props.fileslist.length == 0){
      console.log('progresspropstom',props);
      this.props.finished();
    }
    // if(this.hasFailedDeliveries()){
    //   console.log("Failed delivering one or more files");
    //   setTimeout(() => {
    //     this.props.finished();
    //   }, 2000)
    // }
  }

  hasFailedDeliveries(): boolean{
    if(this.props.fileslist){
      let hasFailure = false;
      this.props.fileslist.forEach(file => {
        if((file.UrkundErrorMessage && file.UrkundErrorMessage.length > 0) || (file.DeliveryStatus && file.DeliveryStatus === 5)){
          hasFailure = true;
        }
      })

      return hasFailure
    }
    return false
  }

  public render() {
    console.log('progress',this.props);
    if (this.props.fileslist && this.props.fileslist.length > 0) {
      const rowProps: IStackProps = { horizontal: true,  horizontalAlign: "space-between"};
      return (
        <Dialog
          isDarkOverlay= {true}
          hidden={false}
          styles={{
            main: [
              {
                selectors: {
                  ["@media (min-width: 480px)"]: {
                    maxWidth: "600px",
                    minWidth: "480px",
                  },
                },
              },
            ],
          }}
          modalProps={{
            isBlocking: true,
            containerClassName: "ms-dialogMainOverride",
          }}
        >
          {" "}
          <div>
            {this.props.fileslist && (
              <ul style={{marginLeft:"0", padding:"0 0 0 17px"}}>
                {this.props.fileslist.map((item) => {
                  let status = item.UrkundReportUrl
                    ? item.UrkundReportUrl
                    : <Spinner size={SpinnerSize.xSmall}></Spinner>
                  return (
                    <li style={{margin:"5px 0 14px 0", padding:"0"}}>
                      <Stack {...rowProps}>
                        <div>{item.FileNameDraft}</div>
                        <div>{item.UrkundErrorMessage === null || item.UrkundReportUrl ? status : item.UrkundErrorMessage}</div>
                      </Stack>
                      <hr style={{margin:"3px 0 0 0"}}/>
                    </li>
                  );
                })}
              </ul>
            )}
          </div>
          <ProgressIndicator label="Sender inn"/>
          
        </Dialog>
      );
    } else {
      return <div></div>;
    }
  }
}
function mapStateToProps(state) {
  console.log("progresstate", state);
  var isinit = true;
  let theprogress =[];
  if (state.StudentReducer && state.StudentReducer.progress) {
    theprogress=state.StudentReducer.progress;
    state.StudentReducer.progress.forEach((element) => {
      if (element.UrkundOptOutMessage) {
        isinit = false;
      }
    });
  }
  if (state.tasks && state.tasks.progress) {
    theprogress=state.tasks.progress;
    state.tasks.progress.forEach((element) => {
      if (element.UrkundOptOutMessage) {
        isinit = false;
      }
    });
  }

  let isTeacher = false;

  if(state.teacher && state.teacher.user && state.teacher.user.role === "teacher"){
    isTeacher = true;
  }

  if(isTeacher && state.TaskReducer && state.TaskReducer.progress){
    theprogress=state.TaskReducer.progress;
    state.TaskReducer.progress.forEach((element) => {
      if (element.UrkundOptOutMessage) {
        isinit = false;
      }
    });
  }

  return {
    fileslist: theprogress,
    isinit: isinit,
    isTeacher: isTeacher,
  };
}
export default connect(mapStateToProps, { checkStatusTask, initStatusTask, checkStatusTaskTeacher })(
  ProgressList
);
