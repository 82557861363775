import * as React from 'react';
import { Link } from 'react-router-dom';
import { PrimaryButton, ActionButton } from 'office-ui-fabric-react';
import CourseContainerItem from './CourseContainerItem';
import { getAssignment } from '../../utils/GraphEduService';

export interface ICourseContainerProps {
	task: any;
	history: any;
}
export interface ICourseContainerState { }
class CourseContainer extends React.Component<ICourseContainerProps, ICourseContainerState> {
	constructor(props) {
		super(props);
	}
	render() {
		let url = 'innleveringer/' + this.props.task.Id;
		console.log('atask', this.props.task);
		console.log('yyyy', this.props.task);
		return (
			<div className={'course-container panel-default'} id={this.props.task.Course.CourseCategory}>
				<div className="panel-heading">
					{' '}
					{!this.props.task.TeamsTask &&
						<Link to={url}>
							<h3>
								{this.props.task.CourseName}: {this.props.task.Name} {this.props.task.Deadlinetekst}
							</h3>
						</Link> 
					}

				</div>
				{!this.props.task.TeamsTask &&
					<div className="panel-body">
						<div className="ms-Grid-row">
							<CourseContainerItem
								history={this.props.history}
								color="#D9534F"
								statusText="Ikke levert"
								iconName="IncidentTriangle"
								students={this.props.task.StudentTaskOpen}
								task={this.props.task}
							/>
							<CourseContainerItem
								history={this.props.history}
								color="#F0AD4E"
								statusText="Klar for vurdering"
								iconName="InboxCheck"
								students={this.props.task.StudentTasksDelivered}
								task={this.props.task}
							/>
							<CourseContainerItem
								history={this.props.history}
								color="#5CB85C"
								statusText="Vurdert"
								iconName="CheckMark"
								students={this.props.task.StudentTaskGraded}
								task={this.props.task}
							/>
						</div>
						<div className="course-container-footer ms-Grid-row ms-sm12">
							<span></span>
							{
								this.props.task.Id != 0 &&
									<ActionButton
										iconProps={{ iconName: 'Edit' }}
										text="Rediger"
										onClick={() => {
											// this.props.history.push('/task/'+this.props.taskelement.Id+'/e');
											this.onEditPlan(this.props.task);
										}}
									/>
							}
						</div>
					</div>
				}
			</div>
		);
	}
	onEditPlan(pe: any) {
		this.props.history.push('/task/' + pe.Id + '/e');
	}
	onViewStatusTask(pe: any) {
		this.props.history.push('/innleveringer/' + pe);
	}
}

export default CourseContainer;
