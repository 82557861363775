import * as React from 'react';
import {Student} from "../../../models/Student";
import {IconButton} from "office-ui-fabric-react";
import {AssignmentGroup} from "../../../models/AssignmentGroup";
import { ClassAssignmentGroup } from '../../../models/ClassAssignmentGroup';

export interface IStudentDragCardProps{
    student:Student;
    group?: AssignmentGroup | ClassAssignmentGroup;
    buttonClick:(StudentID:number, GroupID:number)=>void;
}

export default class StudentDragCard extends React.Component<IStudentDragCardProps>{

    constructor(props){
        super(props);
    }

    render(){
        return(
            <div className="student-drag-card">
                {this.props.student.Name}
                {this.props.group &&
                <IconButton
                    iconProps={{iconName: 'RevToggleKey'}}
                    className="remove-icon"
                    onClick={() => this.props.buttonClick(this.props.student.Id, this.props.group.Id)}
                />
                }
            </div>
        )
    }
}