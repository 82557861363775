import * as React from 'react'
import { GroupedList } from 'office-ui-fabric-react/lib/GroupedList';
import { IColumn, DetailsRow, DetailsList } from 'office-ui-fabric-react/lib/DetailsList';
import { Selection, SelectionMode, SelectionZone } from 'office-ui-fabric-react/lib/Selection';
import { Toggle, IToggleStyles } from 'office-ui-fabric-react/lib/Toggle';
import { PivotItem, Pivot } from 'office-ui-fabric-react';


export default function KvitteringListe(props) {
    const columns: IColumn[]= [
        {
            key: 'column1',
            name: 'Navn',
            fieldName: "Name",
            isResizable: true,
            minWidth: 60,
            maxWidth: 200,
        },
        {
            key: 'column2',
            name: 'Email',
            fieldName: "LoginName",
            isResizable: true,
            minWidth: 60,
            maxWidth: 200,
        },
        {
            key: 'column3',
            name: 'Klasse',
            fieldName: "ClassID",
            isResizable: true,
            minWidth: 60,
            maxWidth: 2000,
            onRender: (recipient) => {
                if(recipient.ClassName != null){
                    return <div>{recipient.ClassName}</div>
                }
                else{
                    return <div>{recipient.ClassID}</div>
                }
            }
        }
    ]
    return (
        <div>
            <Pivot>
                <PivotItem headerText="Har kvittert">
                    { props.kvitteringsObject.SeenRecipientList.length > 0 ?
                        <DetailsList
                            items={props.kvitteringsObject.SeenRecipientList}
                            columns={columns}
                            selectionMode={SelectionMode.none}
                        >
                        </DetailsList>
                        :
                        <div><br/> Ingen har levert kvittering på denne meldingen</div>
                    }
                </PivotItem>
                <PivotItem headerText="Har ikke kvittert">
                    {
                        props.kvitteringsObject.UnSeenRecipientList.length > 0 ? 
                        <DetailsList
                            items={props.kvitteringsObject.UnSeenRecipientList}
                            columns={columns}
                            selectionMode={SelectionMode.none}
                        >
                        </DetailsList>
                        : <div><br/> Alle mottakere har sett meldingen</div>
                    } 
                </PivotItem>
            </Pivot>
        </div>
    )
}
