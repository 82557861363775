import * as React from 'react';

import { withRouter, Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { Field, reduxForm, change } from 'redux-form';
import { saveGradingQuiz } from '../../actions/Action_Quiz';
import { setstatus } from '../../actions/Action_Teacher';
import {
	DefaultButton,
	Dropdown,
	TextField,
	Label,
	ActionButton,
	Spinner,
	SpinnerSize,
	Toggle
} from 'office-ui-fabric-react';
import DatePicker from 'react-datepicker';
import * as moment from 'moment';
import { DATE_FORMAT, LOCALE, scheduleUtils, TIME_FORMAT } from '../timeplan/ScheduleElementDetailView';
import ReactQuill from 'react-quill'; // Typescript
import '../../../../src/app/formelements.scss';
import QuizGraderPoints from './QuizGraderPoints';
import RadioButtonList from '../form_fields/RadioButtonList';
import * as _ from 'lodash';
export interface IQuizGraderFormProps {
	onemitform: (formvalues: any) => void;
	quiz: any;
	initialValues: any;
	handleSubmit: any;
	history: any;
	match: any;
	student: any;
	saveGradingQuiz(quizattempt: any);
	setstatus(statedata: any);
	points: any;
}
export interface IQuizGraderFormState {
	working: boolean;
	workingstatus: string;
	attempt: any;
	showkmp: boolean;
}

export const GRADE_KEY_MAP = [
	{ key: '', text: 'Sett karakter' },
	{ key: '1', text: '1' },
	{ key: '2', text: '2' },
	{ key: '3', text: '3' },
	{ key: '4', text: '4' },
	{ key: '5', text: '5' },
	{ key: '6', text: '6' },
	{ key: 'Godkjent', text: 'Godkjent' },
	{ key: 'Ikke godkjent', text: 'Ikke godkjent' }
];
export const GRADE_MOD_MAP = [
	{ key: '', text: '' },
	{ key: 'Sterk', text: 'Sterk' },
	{ key: 'Svak', text: 'Svak' },
	{ key: '+', text: '+' },
	{ key: '-', text: '-' }
];
class QuizGraderForm extends React.Component<IQuizGraderFormProps, IQuizGraderFormState> {
	constructor(props) {
		super(props);
		this.state = {
			working: false,
			workingstatus: '',
			attempt: this.props.initialValues,
			showkmp: false
		};
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		// if (this.props.initialValues.Id != prevProps.initialValues.Id) {
		// 	this.setState({ attempt: this.props.initialValues });
		// }
	}
	/****************************
            Event handlers
     ****************************/

	onFormSubmit(values) {
		this.props.setstatus({ working: true, text: 'saving ' });
		//values.Uipkms = this.state.attempt.Uipkms;
		this.setState({ working: true, workingstatus: 'lagrer' });
		console.log(values, 'VALUES VALUES VALUES');
		this.props.saveGradingQuiz(values).then(() => {
			this.setState({ working: false, workingstatus: 'lagret' });
			this.props.setstatus({ working: false, text: 'lagret ' });
		});
	}

	/****************************
            Helper methods
     ****************************/

	formatFormValueDatesToDateString(values) {
		values.End = `${values.Start.substring(0, 11)}${values.End}`;
		return values;
	}

	/****************************
            Render methods
     ****************************/

	renderTimeField(field) {
		const {
			input,
			meta: { touched, error }
		} = field;
		const selectedDate = input.value ? moment(input.value, TIME_FORMAT) : null;

		return (
			<div className="ms-Grid-row">
				<div className="z-form-group">
					<div className="ms-Grid-col ms-sm12 ms-md4">
						<Label>
							{field.label}
							<span>{error ? '*' : ''}</span>
						</Label>
					</div>
					<div className="ms-Grid-col ms-sm12 ms-md8">
						<DatePicker
							{...input}
							selected={selectedDate}
							onChange={(date) => {
								input.onChange(date.locale(LOCALE).format(TIME_FORMAT));
							}}
							autocomplete="off"
							timeFormat="HH:mm"
							timeIntervals={5}
							minTime={moment().hours(8).minutes(0)} // ScheduleElements cannot start before 8:00AM
							maxTime={moment().hours(16).minutes(0)} // ScheduleElements cannot end after 16:00AM
							filterDate={(date) => {
								return date.day() !== 0 && date.day() !== 6;
							}} // Weekends are disabled
							locale="nb-NO"
							dateFormat="LT"
							timeCaption="Tid"
							showTimeSelect
							showTimeSelectOnly
						/>
						{touched && error && <span className="ms-TextField-errorMessage errorMessage-88">{error}</span>}
					</div>
				</div>
			</div>
		);
	}

	renderDateTimeField(field) {
		const {
			input,
			meta: { touched, error }
		} = field;
		const selectedDate = input.value ? moment(input.value, DATE_FORMAT) : null;

		return (
			<div className="ms-Grid-row">
				<div className="z-form-group">
					<div className="ms-Grid-col ms-sm12 ms-md4">
						<Label>
							{field.label}
							<span>{error ? '*' : ''}</span>
						</Label>
					</div>
					<div className="ms-Grid-col ms-sm12 ms-md8">
						<DatePicker
							autocomplete="off"
							{...input}
							selected={selectedDate}
							onChange={(date) => {
								input.onChange(date.locale(LOCALE).format(DATE_FORMAT));
							}}
							timeFormat="HH:mm"
							timeIntervals={5}
							minTime={moment().hours(8).minutes(0)} // ScheduleElements cannot start before 8:00AM
							maxTime={moment().hours(16).minutes(0)} // ScheduleElements cannot end after 16:00AM
							filterDate={(date) => {
								return date.day() !== 0 && date.day() !== 6;
							}} // Weekends are disabled
							locale="nb-NO"
							dateFormat={DATE_FORMAT}
							timeCaption="Tid"
							showTimeSelect
							showWeekNumbers
						/>
						{touched && error && <span className="ms-TextField-errorMessage errorMessage-88">{error}</span>}
					</div>
				</div>
			</div>
		);
	}

	renderTextField(field) {
		const {
			meta: { touched, error }
		} = field;

		return (
			<div className="ms-Grid-row">
				<div className="z-form-group">
					<div className="ms-Grid-col ms-sm12 ms-md4">
						<Label>
							{field.label}
							<span>{error ? '*' : ''}</span>
						</Label>
					</div>
					<div className="ms-Grid-col ms-sm12 ms-md8">
						<TextField errorMessage={touched ? error : ''} {...field.input} />
					</div>
				</div>
			</div>
		);
	}
	renderToggle(field) {
		const {
			input,
			meta: { touched, error },
			children
		} = field;

		return (
			<div className="ms-Grid-row">
				<div className="z-form-group">
					<div className="ms-Grid-col ms-sm12 ms-md4">
						<Label>
							{field.label}
							<span>{error ? '*' : ''}</span>
						</Label>
					</div>
					<div className="ms-Grid-col ms-sm12 ms-md8">
						<Toggle
							{...field.input}
							onChanged={(html) => {
								input.onChange(html);
							}}
							defaultChecked={input.value}
						></Toggle>
					</div>
				</div>
			</div>
		);
	}
	renderSelectField(field) {
		const {
			input,
			meta: { touched, error },
			children
		} = field;

		const options = children.props.children;

		return (
			<div className="ms-Grid-row">
				<div className="z-form-group">
					<div className="ms-Grid-col ms-sm12 ms-md4">
						<Label>
							{field.label}
							<span>{error ? '*' : ''}</span>
						</Label>
					</div>
					<div className="ms-Grid-col ms-sm12 ms-md8">
						<Dropdown
							{...input}
							options={options}
							selectedKey={input.value}
							errorMessage={touched && error}
							onChanged={(item) => input.onChange(item.key)}
						/>
					</div>
				</div>
			</div>
		);
	}
	renderRichTextField(field) {
		const {
			input,
			meta: { touched, error },
			children
		} = field;

		return (
			<div className="ms-Grid-row">
				<div className="z-form-group">
					<div className="ms-Grid-col ms-sm12 ms-md4">
						<Label>
							{field.label}
							<span>{error ? '*' : ''}</span>
						</Label>
					</div>
					<div className="ms-Grid-col ms-sm12 ms-md8">
						<ReactQuill
							value={input.value}
							onChange={(html) => {
								input.onChange(html);
							}}
						/>
					</div>
				</div>
			</div>
		);
	}
	getGradeoptions() {
		return _.values(GRADE_KEY_MAP);
	}
	getGradeModoptions() {
		return _.values(GRADE_MOD_MAP);
	}
	render() {
		const { handleSubmit } = this.props;
		console.log('QuizGraderForm', this.props.initialValues);
		let kmpclass = 'collapsed';
		if (this.state.showkmp) {
			kmpclass = '';
		}

		const reducer = (accumulator, item) => {
			return accumulator + parseInt(item.Points);
		  };
		let points;
		if(this.props.initialValues.Answers){
			points = Object.values(this.props.initialValues.Answers).reduce( reducer,
			  0);
		}

		return (
			<form
				style={{
					width: '100%',
					maxWidth: '100%',
					backgroundColor: 'white',
					borderTop: '3px solid #909090',
					marginTop: 20,
					paddingBottom: 20,
					borderRadius: 8
				}}
				onSubmit={handleSubmit(this.onFormSubmit.bind(this))}
			>
				<div>
					<div className="ms-Grid-row studentrow">
						<div className="ms-Grid-col ms-sm12" style={{marginTop: 40}}>
							<div className="panel-group" id="accordian">
								<div className="panel panel-default" style={{ backgroundColor: 'white' }}>
									<div className="panel-title">
										<a
											className={`ms-Label ${kmpclass}`}
											style={{ marginLeft: 15, color: '#333' }}
											role="button"
											data-toggle="collapse"
											data-target="#iupGreps"
											aria-expanded="true"
											aria-controls="iupGreps"
											onClick={() => {
												this.setState({ showkmp: !this.state.showkmp });
											}}
										>
											Angi grad av måloppnåelse
										</a>
									</div>
									{this.state.showkmp && (
									<Field name="Uipkms" label="" component={RadioButtonList}>
										
									</Field>
									)}
								</div>
							</div>
						</div>
						<div className="ms-Grid-col ms-sm12" style={{marginTop: 20}}>
								<h6 style={{marginLeft: 15}}>
									{this.props.initialValues.StudentName}: {this.props.points} / {this.props.initialValues.MaxPoints ? this.props.initialValues.MaxPoints : this.props.quiz.TotalMaxPoints} Poeng
								</h6>
						</div>
						<div className="ms-Grid-col ms-sm12 ms-lg8 ms-xl9 text-left">
							<div className="ms-TextField ms-TextField--multiline">
								<div id="quill-@qa.Id">
									<Field
										name="GradingComment"
										label="Kommentar" // Custom fields variables can be fetched in component (i.e. field.label)
										component={this.renderRichTextField}
									/>
								</div>
							</div>

							<div className="ms-Grid-row">
								<div className="ms-Grid-col ms-sm12 ms-xl6">
									<div className="ms-Dropdown">
										<Field name="Grade" label="Karakter" component={this.renderSelectField}>
											<option>{this.getGradeoptions()}</option>
										</Field>
									</div>
								</div>
								<div className="ms-Grid-col ms-sm12 ms-xl6">
									<div className="ms-Dropdown">
										<Field
											name="GradeModifier"
											label="Angi styrke"
											component={this.renderSelectField}
										>
											<option>{this.getGradeModoptions()}</option>
										</Field>
									</div>
								</div>
							</div>
						</div>
						<div className="ms-Grid-col ms-sm12 ms-lg4 ms-xl3 text-right">
							<div className="ms-Toggle">
								<span className="ms-Toggle-description" style={{ marginRight: 5 }}>
									Publisere vurdering til elev
								</span>
								<Field name="ShowGrading" component={this.renderToggle}></Field>
							</div>
							<DefaultButton
								primary={true}
								text="Lagre"
								onClick={handleSubmit(this.onFormSubmit.bind(this))}
							/>
							<div>{this.state.workingstatus}</div>
							{this.state.working && <div></div>}
						</div>
					</div>
				</div>
			</form>
		);
	}
}

function validate(values) {
	const errors: any = {};

	return errors;
}

const mapStateToProps = (state, ownProps) => {
	return {};
};

export default reduxForm({
	form: 'QuizGraderForm',
	validate,
	enableReinitialize: true
})(connect(mapStateToProps, { saveGradingQuiz, setstatus })(withRouter(QuizGraderForm)));
