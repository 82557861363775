import * as React from 'react';
import * as moment from 'moment';
import * as _ from 'lodash';

import { getScheduleElement } from '../../actions/Action_ScheduleElement';
import { setSelectedClass } from '../../actions/Action_Filtervalues';
import { connect } from 'react-redux';
import HeaderView from '../view_containers/HeaderView';
import { Link } from 'react-router-dom';
import { Field, reduxForm, change, getFormValues } from 'redux-form';
import { ScheduleElement } from '../../models/ScheduleElement';

import 'react-datepicker/dist/react-datepicker.css';
import ScheduleUtils from '../../utils/ScheduleUtils';
import ScheduleElementForm from './ScheduleElementForm';

export const scheduleUtils = new ScheduleUtils();

export const LOCALE = 'nb';
export const TIME_FORMAT = 'LT';
export const DATE_FORMAT = 'DD.MM.YYYY HH:mm';

export interface IScheduleElementDetailViewProps {
	scheduleElements: {};
	teacher: {
		subscribedCourses: {};
		subscribedSchoolClasses: {};
	};
	initialValues: any;
	initialize: any;
	match: any;
	history: any;

	formValues: IScheduleElementForm;

	handleSubmit: any;
	getScheduleElement(id: number);
	setSelectedClass(key: string);
	selectedclass: string;
	rememberedTime: any;
}

interface IScheduleElementForm {
	Id: string | number;
	ClassID: string;
	Title: string;
	Color: string;
	Start: string;
	End: string;
	Links: string;
	DisplayLength: string;
	TilretteleggingsgruppeId?: number;
}

export interface IScheduleElementDetailViewState {
	scheduleElementForm: IScheduleElementForm;
}

class ScheduleElementDetailView extends React.Component<
	IScheduleElementDetailViewProps,
	IScheduleElementDetailViewState
> {
	constructor(props) {
		super(props);
	}

	/****************************
            Lifecycle hooks
     ****************************/

    componentWillMount(){

        const { id } = this.props.match.params;

        if (!isNaN(id)){
            // If the params are a number, this component assumes it's an ID reference to a ScheduleElement
            if (this.props.scheduleElements[id]){
                this.setState({scheduleElementForm: this.getInitialValuesFromObject(this.props.scheduleElements[id])});
            } else {
                this.props.getScheduleElement(id)
                    .then(response => {
                        this.setState({scheduleElementForm: this.getInitialValuesFromObject(this.props.scheduleElements[id])});
                    }).catch(error => {
                    // Returns user to TimeplanView if user lacks permission or element for id doesn't exist
                    this.props.history.push('/timeplan');
                });
            }
        } else {
            // If not, we initialize an empty form
            this.setState({scheduleElementForm: this.getDefaultInitialValues()});
        }
    }

    resetForm() {

        let {Start, End, DisplayLength} = this.props.formValues;

        Start = moment(`${Start.split(" ")[0]} ${End}`, "DD.MM-YYYY hh:mm").format(DATE_FORMAT);
        End = moment(End, "hh:mm").add(15, "m").format(TIME_FORMAT);

        const scheduleElementForm = {
            ...this.getDefaultInitialValues(),
            Start,
            End,
            DisplayLength
        };

        this.setState({scheduleElementForm});
    }

    /****************************
            helper methods
     ****************************/

	getInitialValuesFromObject(se: ScheduleElement) {
		return {
			Id: se.Id,
			ClassID: se.ClassID,
			Title: se.Title,
			Color: se.Color,
			Start: moment(se.Start).locale(LOCALE).format(DATE_FORMAT),
			End: moment(se.End).locale(LOCALE).format(TIME_FORMAT),
			DisplayLength: 'one-hour',
			Templateid:se.Templateid,
			Links: se.Links,
			TilretteleggingsgruppeId: se.TilretteleggingsgruppeId
		};
	}

	getDefaultInitialValues() {
		return {
			Id: '0',
			ClassID: !_.isEmpty(this.props.teacher.subscribedSchoolClasses) ? this.props.selectedclass : '',
			Title: '',
			Color: 'zSheduleColorBlue',
			Start: moment().hours(8).minutes(0).locale(LOCALE).format(DATE_FORMAT),
			End: moment().hours(9).minutes(0).locale(LOCALE).format(TIME_FORMAT),
			DisplayLength: 'one-hour',
			Links: "",
			TilretteleggingsgruppeId: 0
		};
	}

	/****************************
            Render methods
     ****************************/

	render() {
		const { id } = this.props.match.params;

		if (!this.state || !this.state.scheduleElementForm) {
			return <div>Laster...</div>;
		}

		const title = `${isNaN(id) ? 'Nytt' : 'Endre'} timeplanelement`;

		return (
			<div className="ms-Grid">
				<div className="container main">
					<HeaderView title={title} iconName="ViewAll" />
					<ScheduleElementForm
						initialValues={this.state.scheduleElementForm}
						currentValues={this.props.formValues ? this.props.formValues : null}
						onemitform={(values) => this.resetForm()}
						currentCharacters={this.props.formValues ? this.props.formValues.Title.length : this.state.scheduleElementForm.Title.length}
						maxTextCharacters={this.props.formValues ? getMaxTextCharacterAmount(this.props.formValues.Start, this.props.formValues.End) : getMaxTextCharacterAmount(this.state.scheduleElementForm.Start, this.state.scheduleElementForm.End)}
					/>
				</div>
			</div>
		);
	}
}
  
export function getMaxTextCharacterAmount(startTime: string, endTime: string) {
	try {
		let timeStart = moment(startTime, "DDMMYYY hh:mm");
		let timeEnd = moment(startTime, "DDMMYYY hh:mm");
		timeEnd.hour(parseInt(endTime.split(":")[0])).minute(parseInt(endTime.split(":")[1]));
	
		let duration = moment.duration(timeEnd.diff(timeStart));
	
		let maxCharacterAmountSimple = duration.asHours() >= 3 ? 450 : 90;
	
		let maxCharacterAmount = Math.floor(duration.asMinutes() / 15) * 30 - 40;
	
		if(maxCharacterAmount < 60) {
			maxCharacterAmount = 60;
		}
		
		// Denne er her pga TimplanElementer sin 255 tegn begrensning på Tittel
		if (maxCharacterAmount > 255) {
			maxCharacterAmount = 255;
		}
	
		return maxCharacterAmount;
	}
	catch (e) {
		return 90;
	}
}

const mapStateToProps = (state, ownProps) => {
	return {
		scheduleElements: state.scheduleElements,
		teacher: state.teacher,
		selectedclass: state.FilterReducer.selectedclass,
		rememberedTime: state.scheduleElements.rememberedTime,
		formValues: getFormValues('ScheduleElementForm')(state)
	};
};

export default connect(mapStateToProps, { getScheduleElement, setSelectedClass })(ScheduleElementDetailView);
