import axios from 'axios';
import {TeacherCourse} from "../models/TeacherCourse";
import {Course} from "../models/Course";
import {AssignmentGroup} from "../models/AssignmentGroup";
import * as _ from 'lodash';
import { ROOT_URL, SP_HOST_URL } from './constants';
import { ClassAssignmentGroup } from '../models/ClassAssignmentGroup';

// Authentication
export const GET_AUTH = 'GET_AUTH';
export const RENEW_AUTH = 'RENEW_AUTH';
export const GET_TEACHER_STATE = 'GET_TEACHER_STATE';

// Courses
export const GET_AVAILABLE_COURSES = 'GET_AVAILABLE_COURSES';
export const SAVE_TEACHER_COURSES = 'SAVE_TEACHER_COURSES';
export const UPDATE_COURSE_STUDENTS = 'UPDATE_COURSE_STUDENTS';

// Groups
export const GET_GROUPS = 'GET_GROUPS';
export const SAVE_GROUPS = 'SAVE_GROUPS';
export const DELETE_GROUP = 'DELETE_GROUP';
export const GET_CLASS_ASSIGNMENT_GROUPS = "GET_CLASS_ASSIGNMENT_GROUPS"; 

// SchoolClasses
export const GET_AVAILABLE_SCHOOL_CLASSES = 'GET_AVAILABLE_SCHOOL_CLASSES';
export const SAVE_TEACHER_CLASSES = 'SAVE_TEACHER_CLASSES';

export const GET_AVAILABLE_RECIPIENTS = 'GET_AVAILABLE_RECIPIENTS';
export const SET_STATUS = 'SET_STATUS';
export const GET_STUDENTS_FROM_COURSE = "GET_STUDENTS_FROM_COURSE"; 

/****************************
        Authentication
 ****************************/

export function getAuth(dataen:any) {

    const request = axios.post(`${ROOT_URL}/GetAuth${SP_HOST_URL}`,dataen);

    request.then((data)=>{
        localStorage.setItem('id_token',data.data);
    });

    return {    
        type: GET_AUTH,
        payload: request
    }
}
export function renewAuth() {

    const request = axios.get(`${ROOT_URL}/renewAuth${SP_HOST_URL}`,{
        headers: {
            Authorization: localStorage.getItem('id_token')
        }
    });

    request.then((data)=>{
        localStorage.setItem('id_token',data.data);
    });

    return {    
        type: RENEW_AUTH,
        payload: request
    }
}

/****************************
        Initialization
 ****************************/

export function getTeacherState() {

    // Returns arrays for SchoolClasses, Courses and Schools.
    // This is requested when teacher has been authenticated when webapp starts
    const request = axios.get(`${ROOT_URL}/GetTeacherState/${SP_HOST_URL}`,{
        headers: {
            Authorization: localStorage.getItem('id_token')
        }
    });

    return {
        type: GET_TEACHER_STATE,
        payload: request
    }
}

/****************************
            Courses
 ****************************/

export function getAvailableCourses(statedata:any) {

    const data:FormData = new FormData();
    data.set('classes', JSON.stringify(_.values(statedata.subscribedSchoolClasses)));
    data.set('courses', JSON.stringify(_.values(statedata.subscribedCourses)));
    data.set('schools', JSON.stringify(_.values(statedata.schools)));

    const request = axios.post(`${ROOT_URL}/GetCoursesB/${SP_HOST_URL}`,data,{
        headers: {
            Authorization: localStorage.getItem('id_token')
        }
    });

    return {
        type: GET_AVAILABLE_COURSES,
        payload: request
    }
}

export function saveTeacherCourses(courses:TeacherCourse[], callback:(updatedCourses)=>void) {

    //TODO: update url when endpoint is on staging
    const request = axios.post(`${ROOT_URL}/SaveTeacherCourse/${SP_HOST_URL}`, courses,{
        headers: {
            Authorization: localStorage.getItem('id_token')
        }
    });

    request.then((response)=>callback(response.data));

    return{
        type: SAVE_TEACHER_COURSES,
        payload: request
    }
}

// Helper method to set updated state for course.Students. Does not fire a request, but updates state locally
export function updateCourseStudents(course:Course) {

    return{
        type: UPDATE_COURSE_STUDENTS,
        payload: course
    }
}


/****************************
            Groups
 ****************************/

export function getGroups(course:Course) {

    //TODO: update url when endpoint is on staging
    const request = axios.post(`${ROOT_URL}/GetGroups/${SP_HOST_URL}`, course,{
        headers: {
            Authorization: localStorage.getItem('id_token')
        }
    });

    return{
        type: GET_GROUPS,
        payload: request
    }
}

export function saveGroups(groups:AssignmentGroup[] | ClassAssignmentGroup[], callback:(data)=>void) {

    const request = axios.post(`${ROOT_URL}/SaveGroupsAsyncPost/${SP_HOST_URL}`, groups, {
        headers: {
            Authorization: localStorage.getItem('id_token')
        }
    });

    request.then((data) => callback(data));

    return{
        type: SAVE_GROUPS,
        payload: request
    }
}

export function deleteGroup(group:AssignmentGroup | ClassAssignmentGroup, callback:(group:AssignmentGroup | ClassAssignmentGroup)=>void) {

    const request = axios.post(`${ROOT_URL}/DeleteGroup/${SP_HOST_URL}`, group, {
        headers: {
            Authorization: localStorage.getItem('id_token')
        }
    }).then(() => callback(group));

    return{
        type: DELETE_GROUP,
        payload: group
    }}

/****************************
        SchoolClasses
 ****************************/

export function getAvailableSchoolClasses(statedata:any) {

    const data:FormData = new FormData();
    data.set('classes', JSON.stringify(_.values(statedata.subscribedSchoolClasses)));
    data.set('courses', JSON.stringify(_.values(statedata.subscribedCourses)));
    data.set('schools', JSON.stringify(_.values(statedata.schools)));
    const request = axios.post(`${ROOT_URL}/GetClassesB/${SP_HOST_URL}`,data,{
        headers: {
            Authorization: localStorage.getItem('id_token')
        }
    });

    return {
        type: GET_AVAILABLE_SCHOOL_CLASSES,
        payload: request
    }
}

export function saveTeacherClasses(courses:TeacherCourse[], callback:(updatedCourses)=>void) {

    //TODO: update url when endpoint is on staging
    const request = axios.post(`${ROOT_URL}/SaveTeacherClass/${SP_HOST_URL}`, courses,{
        headers: {
            Authorization: localStorage.getItem('id_token')
        }
    });

    request.then((response)=>callback(response.data));

    return{
        type: SAVE_TEACHER_CLASSES,
        payload: request
    }
}
export function getRecipients(statedata:any) {
    console.log("GET RCPS:", statedata)
    const data:FormData = new FormData();
    data.set('classes', JSON.stringify(_.values(statedata.subscribedSchoolClasses)));
    data.set('schools', JSON.stringify(_.values(statedata.schools)));
    data.set('courses', JSON.stringify(_.values(statedata.subscribedCourses)));
    //TODO: change API url
    const request = axios.post(`${ROOT_URL}/GetRecipients${SP_HOST_URL}`, data, {
        headers: {
            Authorization: localStorage.getItem('id_token')
        }
    });

    return {
        type: GET_AVAILABLE_RECIPIENTS,
        payload: request
    }
}

export async function getStudentsFromCourse(courseID: string) {
    const res = await axios.get(`${ROOT_URL}/GetStudentsFromCourse/${SP_HOST_URL}&courseID=${courseID}`, {
        headers: {
            Authorization: localStorage.getItem('id_token')
        }
    });

    return {
        type: GET_STUDENTS_FROM_COURSE,
        payload: res
    }
}
export function setstatus(statedata:any) {
  

    return {
        type: SET_STATUS,
        payload: statedata
    }
}