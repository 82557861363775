import * as React from "react";

export interface IGradeReportProps {
  gradereport: any;
  history: any;
}
export interface IGradeReportState {}

export default class GradeReport extends React.Component<
  IGradeReportProps,
  IGradeReportState
> {
  public render() {
    return (
      <div>
        <h2>Innleveringer</h2>
        <div className="table-scroll" id="table-scroll">
          <div className="table-wrap">
            <table className="table-striped main-table">
              <thead>
                <tr>
                  <th className="fixed-side" scope="col">
                    <span>Elev</span>
                  </th>
                  {this.props.gradereport.Alltasksv3.map((item) => {
                    return (
                      <th scope="col">
                        {
                          item.Id!=0 &&
                          <a
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            this.props.history.push(
                              `/innleveringer/${item.Id}`
                            );
                          }}
                        >
                          {item.Name}
                        </a>
                        }
                        {
                          item.Id==0 &&
                          <a
                          style={{ cursor: "pointer" }}
                          href={item.TeamsDeepLink}
                          target={'blank'}
                        >
                          {item.Name}
                        </a>
                        }
                        {" "}
                        <span title="@item.Text">{`${
                          item.Deadlinetekst.split(" ")[0]
                        }`}</span>
                      </th>
                    );
                  })}
                </tr>
              </thead>
              {this.props.gradereport.AllStudents.map((item) => {
                return (
                  <tr>
                    <th className="fixed-side">{item.Name}</th>
                    {item.ReportTasksV3.map((st) => {
                      return (
                        <td>
                          <span title={st.GradingComment}>
                            {st.Id > 0 && st.Grade}{" "}
                            {st.Id > 0 && st.GradeModifier}
                          </span>
                        </td>
                      );
                    })}
                  </tr>
                );
              })}
            </table>
          </div>
        </div>
        <h2>Prøver</h2>
        <div className="table-scroll" id="table-scroll">
          <div className="table-wrap">
            <table className="table-striped main-table">
              <thead>
                <tr>
                  <th className="fixed-side" scope="col">
                    <span>Elev</span>
                  </th>
                  {this.props.gradereport.Allitemsv3.map((item) => {
                    if (item.QuizItem) {
                      console.log("quizitem", item);
                      return (
                        <th scope="col">
                          <a
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                              this.props.history.push(
                                `/quiz/grade/${item.QuizItem.Id}`
                              );
                            }}
                          >
                            {" "}
                            {item.QuizItem.Title}
                          </a>
                          <span title="@item.Text">{` ${
                            item.QuizItem.ValidToText.split(" ")[0]
                          }`}</span>
                        </th>
                      );
                    }
                  })}
                </tr>
              </thead>
              {this.props.gradereport.AllStudents.map((item) => {
                return (
                  <tr>
                    <th className="fixed-side">{item.Name}</th>
                    {item.ReportItemsV3.map((p) => {
                      if (p.Qa) {
                        return (
                          <td>
                            <span title={p.Qa.GradingComment}>
                              {p.Qa.Id > 0 && p.Qa.Grade} {" "}
                              {p.Qa.Id > 0 && p.Qa.GradeModifier}
                            </span>
                          </td>
                        );
                      }
                    })}
                  </tr>
                );
              })}
            </table>
          </div>
        </div>
      </div>
    );
  }
}
