import * as React from "react";
import * as moment from "moment";
import * as _ from "lodash";
import { getTaskElement } from "../../actions/Action_Ukeplan";
import { connect } from "react-redux";
import HeaderView from "../view_containers/HeaderView";
import TaskElementForm from "./TaskElementForm";
export const LOCALE = "nb";
export const DATE_FORMAT = "DD.MM.YYYY";
export const DATE_FORMAT_TIME = "DD.MM.YYYY HH:mm";
export const TIME_FORMAT = "LT";
export interface ITaskElementEditViewProps {
  taskelement: any;
  getTaskElement(id: number);
  match: any;
  history: any;
  onsave();
}
export interface ITaskElementEditViewState {
  taskElementForm: any;
}

class TaskElementEditView extends React.Component<
  ITaskElementEditViewProps,
  ITaskElementEditViewState
> {
  getInitialValuesFromObject(se: any) {
    let ev = {
      Innlevering: false,
      Presentasjon: false,
      Prøve: false,
      Test: false,
      Leksesjekk: false
    };
    if (se.EvalElements) {
      se.EvalElements.forEach(element => {
        ev[element] = true;
      });
    }

    return {
      Id: se.Id,
      CourseID: se.CourseID,
      GroupID: se.GroupID,
      Name: se.Name,
      ShowKmpGoals: se.ShowKmpGoals,
      Goal: se.Goal,
      Elever: se.Elever ? se.Elever : "",
      EvalElements: se.EvalElements,
      EvaluationTextOther: se.EvaluationTextOther,
      TextOnPrem: se.TextOnPrem,
      Text: se.Text,
      Link: se.Link,
      KeepOpen: se.KeepOpen,
      HideDeadline: se.HideDeadline ? se.HideDeadline : false,
      DeliverAfterDeadline: se.DeliverAfterDeadline ? se.DeliverAfterDeadline : false,
      TaskFiles: se.TaskFiles,
      Deadline: moment(se.Deadline)
        .locale(LOCALE)
        .format(DATE_FORMAT_TIME),
      ShowFrom: moment(se.ShowFrom)
        .locale(LOCALE)
        .format(DATE_FORMAT),
      // Starttime: moment(se.Starttime).locale(LOCALE).format(DATE_FORMAT_TIME),
      Starttime: se.Starttime ? moment(se.Starttime).locale(LOCALE).format(DATE_FORMAT_TIME) : "",
      Course: se.Course,
      CheckInnlevering: ev["Innlevering"],
      CheckPresentasjon: ev["Presentasjon"],
      CheckProve: ev["Prøve"],
      CheckTest: ev["Test"],
      CheckLeksesjekk: ev["Leksesjekk"],
      Grep: se.Grep,
      Plagiatkontroll: se.Plagiatkontroll ? se.Plagiatkontroll : false,
      TaskDocument: se.TaskDocument
      // ClassID: se.ClassID,
      // Title: se.Title,
      // Color: se.Color,
      // Start: moment(se.Start).locale(LOCALE).format(DATE_FORMAT),
      // End: moment(se.End).locale(LOCALE).format(TIME_FORMAT),
      // DisplayLength: 'one-hour'
    };
    // se.Date =  moment(se.Date).locale(LOCALE).format(DATE_FORMAT);
    // return se;
  }

  getDefaultInitialValues() {
    return {
      Id: "0",
      EvalElements: []
    };
  }
  componentWillMount() {
    console.log('taskform1');
 //  console.log('history',this.props.history);
      const { id, mode } = this.props.match.params;
  
    if (!isNaN(id)) {
      console.log('taskform2',id);
      // If the params are a number, this component assumes it's an ID reference to a ScheduleElement
      if (this.props.taskelement && this.props.taskelement.Id==id) {
        console.log('taskform3',this.props.taskelement);
        if(mode === "c")
        {
            this.props
            .getTaskElement(id)
            .then(response => {
              let p = this.getInitialValuesFromObject(this.props.taskelement);
              if (mode === "c") {
                p.Id = 0;
              }
              this.setState({ taskElementForm: p });
             
            })
            .catch(error => {
            
            });
        }
        else
        {
          console.log('taskform4');
            this.setState({
                taskElementForm: this.getInitialValuesFromObject(
                  this.props.taskelement
                )
              });
        }
        
      } else {
        console.log('taskform5');
        this.props
          .getTaskElement(id)
          .then(response => {
            let p = this.getInitialValuesFromObject(this.props.taskelement);
            if (mode === "c") {
              p.Id = 0;
            }
            this.setState({ taskElementForm: p });
            //this.setState({ planElementForm: response.payload.data });
            //this.setState({ planElementForm: this.getDefaultInitialValues() });
          })
          .catch(error => {
            // Returns user to TimeplanView if user lacks permission or element for id doesn't exist
            //this.props.history.push('/ukeplan');
          });
      }
    } else {
      // If not, we initialize an empty form
      this.setState({ taskElementForm: this.getDefaultInitialValues() });
    }
  }
  getrenderinf() {
    const { id, mode } = this.props.match.params;
    if (!isNaN(id)) {
      // If the params are a number, this component assumes it's an ID reference to a ScheduleElement
      if (this.props.taskelement) {
        this.setState({
          taskElementForm: this.getInitialValuesFromObject(
            this.props.taskelement
          )
        });
      } else {
        this.props
          .getTaskElement(id)
          .then(response => {
            let p = this.getInitialValuesFromObject(this.props.taskelement);
            if (mode === "c") {
              p.Id = 0;
            }

            this.setState({ taskElementForm: p });
            //this.setState({ planElementForm: response.payload.data });
            //this.setState({ planElementForm: this.getDefaultInitialValues() });
          })
          .catch(error => {
            // Returns user to TimeplanView if user lacks permission or element for id doesn't exist
            //this.props.history.push('/ukeplan');
          });
      }
    } else {
      // If not, we initialize an empty form
      this.setState({ taskElementForm: this.getDefaultInitialValues() });
    }
  }
  public render() {
    if (!this.state || !this.state.taskElementForm) {
      return <div>Laster...</div>;
    }

    const { taskElementForm } = this.state;

    const title =
      taskElementForm.Id != "0" ? "Endre innlevering" : "Lag ny innlevering";

    const { id, mode } = this.props.match.params;

    if (mode == "c") {
      const headertekst = "Kopier planelement";

      let tmp = this.getInitialValuesFromObject(this.props.taskelement);
      tmp.Id = 0;
      return (
        <div>
          <div className="ms-Grid main container">
            <HeaderView title={headertekst} iconName="ViewAll" />
            {/* <TaskElementForm initialValues={taskElementForm} /> */}
            <TaskElementForm initialValues={tmp} mode={mode} />
          </div>
        </div>
      );
    }
    return (
      <div>
        <div className="ms-Grid main container">
          <HeaderView title={title} iconName="ViewAll" />
          <TaskElementForm initialValues={this.state.taskElementForm} mode={mode} onsave={()=>{
            //console.log('1');
            this.props.onsave();
          }}/>
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state, ownProps) => {
  return {
    taskelement: state.ukeplanelements.taskElement
  };
};

export default connect(mapStateToProps, { getTaskElement })(
  TaskElementEditView
);
